import { ITranslatedError, handleError } from '../lib/errors';
import { commit, val } from '../lib/redux_helpers';
import { IState, IThunkMethod } from '../lib/store';
import { getSystemTimeDrift, getSystemTimeError } from '../selectors/app';
import nightXcalibraTheme from '../styles/theme_night';
import { IApiAppData, IApiSystemTime } from '../types/backend_definitions';
import { TimeUnits } from '../types/constants';
import { ITheme } from '../types/theme';
import { setKYC1Policy, setRegistrationPolicy, submitLoggedInPrincipal } from './auth/auth';
import { setCustomer } from './auth/customer';
import { updateBalances } from './balances';
import {
  initializeExchangeStateBasedOnEnv,
  setInstrumentsConfig,
  setPairsConfig,
  setPriceSummary24hFromApiData,
} from './exchange';
import { commitActiveOrdersForAllInstruments } from './orders/active_instruments_orders';
import { setRates } from './rates';
import { loadingWrapper } from './request_active';
import {
  setCardPaymentInstrumentConfig,
  setCardPaymentUserConfig,
} from './transactions/card_payments';
import { setManagedUserConfig } from './transactions/managed';

export type IToastNotificationType = 'info' | 'success' | 'warning' | 'error' | 'buy' | 'sell';

type IToastNotificationMessage = string | React.ReactNode;

export interface IToastNotification {
  /**
   * Unique toast id. Only one toast with given ID can be shown at one time.
   * This can be used to dedupe toasts.
   */
  toastId?: string | number;

  /**
   * String messages to show
   */
  messages: IToastNotificationMessage[];

  /**
   * Notification data type. Defaults to success
   */
  type?: IToastNotificationType;

  /**
   * The date notification was shown. Auto-generated.
   */
  date?: Date;
}

export const getErrorNotificationData = (
  error: ITranslatedError,
  toastId?: string | number
): IToastNotification => {
  const messages: IToastNotificationMessage[] = error.singleMessage
    ? [error.singleMessage]
    : [...Object.values(error.pathMessages)];

  return {
    messages,
    toastId,
  };
};

export const getSimpleNotificationData = (
  message: IToastNotificationMessage,
  type?,
  toastId?
): IToastNotification => {
  return {
    type,
    messages: [message],
    toastId,
  };
};

export interface IAppState {
  app: {
    initialLoading: boolean;
    toastNotifications: IToastNotification[];
    theme: ITheme;
    systemTimeDrift: number;
    systemTimeError: Error | string;
    unmounting: boolean;
  };
}

export const APP_STATE: IAppState = {
  app: {
    initialLoading: true,
    toastNotifications: [],
    theme: nightXcalibraTheme,
    systemTimeDrift: 0,
    systemTimeError: null,
    unmounting: null,
  },
};

export const setPageUnmounting = () => {
  return commit('Page is unmounting', {
    app: {
      unmounting: val<IState['app']['unmounting']>(true),
    },
  });
};

export const commitFinishedInitialLoading = () => {
  return commit(`Initial page loading has finished`, {
    app: {
      initialLoading: val<IState['app']['initialLoading']>(false),
    },
  });
};

export const setTheme = (themeVariant: 'day' | 'night') => (dispatch) => {
  // TODO: Enable theme switcher
  const theme = nightXcalibraTheme;
  dispatch(
    commit(`Theme variant is set to '${themeVariant}'`, {
      app: { theme: val<IState['app']['theme']>(theme) },
    })
  );
};

export const addNotification = (notification: IToastNotification): IThunkMethod => (
  dispatch,
  _,
  { localStorage }
) => {
  // Get last 10 notifications
  const toastNotifications = [
    ...localStorage.toastNotifications,
    { ...notification, date: new Date() },
  ].slice(-10);

  // Save toastNotifications in LocalStorage
  localStorage.toastNotifications = toastNotifications as IToastNotification[];

  dispatch(
    commit(`New toast notification`, {
      app: {
        toastNotifications: val<IState['app']['toastNotifications']>(toastNotifications),
      },
    })
  );
};

export const clearNotifications = (): IThunkMethod => (dispatch, _, { localStorage }) => {
  // Clear notifications in LocalStorage
  localStorage.clearNotifications();
  dispatch(
    commit(`Clear notifications`, {
      app: {
        toastNotifications: val<IState['app']['toastNotifications']>([]),
      },
    })
  );
};

export const notifySuccess = ({ type, ...rest }: IToastNotification) => (dispatch) =>
  dispatch(addNotification({ type: type ? type : 'success', ...rest }));
export const notifyInfo = ({ type, ...rest }: IToastNotification) => (dispatch) =>
  dispatch(addNotification({ type: type ? type : 'info', ...rest }));
export const notifyError = ({ type, ...rest }: IToastNotification) => (dispatch) =>
  dispatch(addNotification({ type: type ? type : 'error', ...rest }));
export const notifyWarning = ({ type, ...rest }: IToastNotification) => (dispatch) =>
  dispatch(addNotification({ type: type ? type : 'warning', ...rest }));

export const submitSystemTime = (systemTimeDrift: number, systemTimeError: Error | string) => (
  dispatch
) => {
  return dispatch(
    commit('Submit system time', {
      app: {
        systemTimeDrift: val<IState['app']['systemTimeDrift']>(systemTimeDrift),
        systemTimeError: val<IState['app']['systemTimeError']>(systemTimeError),
      },
    })
  );
};

export const loadSystemTime = (): IThunkMethod => (dispatch, getState, { api }) => {
  const before = Date.now();

  return dispatch(
    loadingWrapper(
      'getWebuiSystemTime',
      api
        .getWebuiSystemTime()
        .then((systemTime) => {
          dispatch(setSystemTimeDrift(before, systemTime));
        })
        .catch((error) => {
          if (!getSystemTimeError(getState())) {
            dispatch(handleError(error));
          } else {
            // Otherwise, swallow the error. Don't show needless popups for persistent server failure
          }

          dispatch(setSystemTimeError(error));
        })
    )
  );
};

export const scheduleSystemTimeSync = (syncCountdown: number) => (dispatch) =>
  setTimeout(() => dispatch(loadSystemTime()), syncCountdown);

export const setSystemTimeDrift = (before: number, systemTime: IApiSystemTime): IThunkMethod => (
  dispatch
) => {
  const requestDurationAdjustment = (Date.now() - before) / 2;

  const adjustedServerTime = new Date(systemTime.date).valueOf() + requestDurationAdjustment;

  // If systemTimeDrift is positive, server time is AHEAD of local time
  const systemTimeDrift = adjustedServerTime - Date.now();

  dispatch(submitSystemTime(systemTimeDrift, null));

  dispatch(scheduleSystemTimeSync(TimeUnits.hour));
};

export const setSystemTimeError = (error: Error | string): IThunkMethod => (dispatch, getState) => {
  dispatch(submitSystemTime(getSystemTimeDrift(getState()), error));
  dispatch(scheduleSystemTimeSync(TimeUnits.minute));
};

export const loadUserSessionData = (): IThunkMethod => (dispatch, _, { api }) => {
  return dispatch(
    loadingWrapper(
      'getWebuiUserSessionData',
      api
        .getWebuiUserSessionData()
        .then((userSessionData) => {
          // User Instrument Policy
          dispatch(setInstrumentsConfig(userSessionData.user_instrument_policy));

          // User Pair Policy
          dispatch(setPairsConfig(userSessionData.user_pair_policy));

          // Card Payment User Config
          dispatch(
            setCardPaymentUserConfig({
              ...userSessionData.card_payment_user_config,
              loaded: true,
            })
          );

          // Managed User Config
          dispatch(setManagedUserConfig(userSessionData.managed_user_config));

          // Set balances for current user
          dispatch(updateBalances(userSessionData.wallet));

          // Set orders for all instrument pairs
          dispatch(
            commitActiveOrdersForAllInstruments(userSessionData.active_instruments_orders.pairs)
          );

          // Customer
          dispatch(setCustomer(userSessionData.customer));

          // Card Payment Instrument Config
          dispatch(setCardPaymentInstrumentConfig(userSessionData.payment_instrument_config));
        })
        .catch((error) => {
          dispatch(handleError(error));
        })
    )
  );
};

const loadAppDataUsingAccessToken = (accessToken: string | null): IThunkMethod<Promise<void>> => (
  dispatch,
  _,
  { http, api, matomo }
) => {
  // required for system time calculations
  const before = Date.now();

  return dispatch(
    loadingWrapper(
      'loadAppDataUsingAccessToken',
      Promise.resolve()
        .then(() => {
          if (!accessToken) {
            return api.getWebuiGlobalAppData();
          }

          return http.customAuthRequest<IApiAppData>(accessToken, api.getWebuiUserAppDataSpec());
        })
        .then((appData) => {
          // System Time
          dispatch(setSystemTimeDrift(before, appData.system_time));

          // Registration Policy
          dispatch(setRegistrationPolicy(appData.registration_policy));

          // Card Payment Instrument Config
          dispatch(setCardPaymentInstrumentConfig(appData.payment_instrument_config));

          dispatch(setRates(appData.rates));

          // set 24h summary for all instruments
          dispatch(setPriceSummary24hFromApiData(appData.price_summary_24h));

          // Principal
          if (appData.principal) {
            dispatch(submitLoggedInPrincipal(appData.principal));
            matomo.sendEvent('Session', 'resume_session');
          }

          // Customer
          if (appData.customer) {
            dispatch(setCustomer(appData.customer));
          }

          // KYC1 policy
          if (appData.kyc1_policy) {
            dispatch(setKYC1Policy(appData.kyc1_policy))
          }
        })
        .catch((err) => {
          // if the session was terminated (token expired) while the user was away swallow the error,
          // don't show toast message and just load the global app data
          if (err.name === 'SessionTerminatedError') {
            dispatch(loadAppDataUsingAccessToken(null));
          } else {
            dispatch(handleError(err));
            dispatch(setSystemTimeError(err));
          }
        })
    )
  );
};

/**
 * Initial app loading. Should only be called once!
 */
export const initializeAppForTheFirstTime = (): IThunkMethod => (dispatch) => {
  dispatch(initializeExchangeStateBasedOnEnv());

  return dispatch(loadAppData()).then(() => {
    dispatch(commitFinishedInitialLoading());
  });
};

export const loadAppData = (): IThunkMethod<Promise<void>> => (dispatch, _, { localStorage }) => {
  return dispatch(loadAppDataUsingAccessToken(localStorage.accessToken));
};
