import Container from '../lib/container';

export type ICsvDownloadFunctions =
  | 'getAuthHistoryCsv'
  | 'getExchangeTradesCsv'
  | 'getDepositsCsv'
  | 'getWithdrawalsCsv'
  | 'getCardPaymentsCsv'
  | 'getExchangeOrdersCsv'
  | 'getSecurityEventsInfosCsv';

export const downloadCSV = (csvDownloadFunction: ICsvDownloadFunctions, criteria = {}) => (
  _,
  __,
  { api, http }: Container
) => {
  return api[csvDownloadFunction](criteria).then((res) => {
    window.location.href = http.generateUrl(api.getCsvDownloadSpec(res.id));
  });
};
