import * as React from 'react';

import styled from '../../lib/styled_components';

export const $Bottom = styled.div`
  display: flex;
  padding-top: 1rem;
  text-align: right;
  button {
    margin: 0 auto;
    margin-top: 2px;
  }
`;

export const $KycPanel = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 4rem;

  @media screen and ${(p) => p.theme.device.mobile} {
    padding: 1rem;
  }
`;
export const $KycPanelEmphasized = styled($KycPanel)`
  border: ${(p) => p.theme.components.kycPanel.border};
  background: ${(p) => p.theme.components.kycPanel.background};
`;
export const $KycPanelTitle = styled.h2`
  margin: 0;
  margin-bottom: 2rem;
  color: white;
  text-transform: uppercase;
  text-align: center;
`;
export const $KycPanelSubtitle = styled.h3`
  margin: 0;
  margin-bottom: 1rem;
  color: white;
  text-transform: uppercase;
  text-align: center;
`;
export const $KycPanelText = styled.h4`
  margin: 0;
  margin-bottom: 2rem;
  text-align: center;
`;

export const $AlertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 390px;
`;
