import * as React from 'react';

import { I18n } from '../../../lib/i18n';
import Deposit from '../../../views/components/transactions/Deposit';
import PageSeoWrapper from '../../widgets/PageSeoWrapper';
import TransactionPagesLayout from './TransactionPagesLayout';

const DepositPage: React.FunctionComponent<{}> = () => (
  <I18n>
    {(t) => (
      <PageSeoWrapper pageTitle={t('pages.deposit')}>
        <TransactionPagesLayout type="deposit">
          <Deposit />
        </TransactionPagesLayout>
      </PageSeoWrapper>
    )}
  </I18n>
);

export default DepositPage;
