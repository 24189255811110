import * as React from 'react';

// TODO: This should probably be utility functions that plaster "show-on-mobile" class name into styled components?
//       Either way, I wouldn't add anything else into this until we rethink.

export const ShowMobile: React.FunctionComponent = (props) => (
  <div className="show-on-mobile">{props.children}</div>
);
export const HideMobile: React.FunctionComponent = (props) => (
  <div className="hide-on-mobile">{props.children}</div>
);
