import { renewSession } from '../bl/session_renewal';
import { handleError, rethrowTranslated } from '../lib/errors';
import { IThunkMethod } from '../lib/store';
import {
  IApiTFAEnableResponse,
  IApiUser,
  IApiUserProfilePayload,
  IGeoLocationPinning,
  ILanguage,
} from '../types/backend_definitions';
import { getSimpleNotificationData, notifyInfo, notifySuccess } from './app';
import { submitUser } from './auth/auth';
import { logout } from './auth/logout';
import { IActiveRequestsKeys, loadingWrapper } from './request_active';

export const changeEmailOrThrow = (
  new_email: string,
  password: string,
  tfa_token: string | null = undefined
): IThunkMethod<Promise<void>> => (dispatch, _, { api, i18n }) => {
  return dispatch(
    loadingWrapper(
      'changeEmail',
      (api.putEmailChange({ new_email, password, tfa_token }) as Promise<IApiUser>)
        .then((user) => {
          dispatch(submitUser(user));

          dispatch(
            notifyInfo(
              getSimpleNotificationData(i18n.t('settings:account.emailUpdatedNotification'))
            )
          );
        })
        .catch((err) => dispatch(rethrowTranslated(err)))
    )
  );
};

export const changePasswordOrThrow = (
  new_password: string,
  existing_password: string,
  tfa_token: string | null = null
): IThunkMethod<Promise<void>> => (dispatch, _, { api, i18n }) => {
  return dispatch(
    loadingWrapper(
      'changePassword',
      api.putPasswordChange({ new_password, existing_password, tfa_token }).then(
        () => {
          dispatch(
            notifySuccess(
              getSimpleNotificationData(i18n.t('settings:account.passwordUpdatedNotification'))
            )
          );
          dispatch(logout());
        },
        (err) => dispatch(rethrowTranslated(err))
      )
    )
  );
};

export const enableTFAOrThrow = (): IThunkMethod => (
  dispatch,
  _,
  { api }
): Promise<IApiTFAEnableResponse> => {
  return dispatch(
    loadingWrapper(
      'enableTFA',
      api.put2faEnable().catch((err) => dispatch(rethrowTranslated(err)))
    )
  );
};

export const confirmTFAOrThrow = (token: string): IThunkMethod<Promise<void>> => (
  dispatch,
  _,
  { api }
) => {
  return dispatch(
    loadingWrapper(
      'confirmTFA',
      api
        .put2faConfirm({ token })
        .then((user) => dispatch(submitUser(user)))
        .catch((err) => dispatch(rethrowTranslated(err)))
    )
  );
};
export const disableTFAOrThrow = (token: string): IThunkMethod<Promise<IApiUser>> => (
  dispatch,
  _,
  { api }
) => {
  return dispatch(
    loadingWrapper(
      'disableTFA',
      api
        .put2faDisable({ token })
        .then((user) => dispatch(submitUser(user)))
        .catch((err) => dispatch(rethrowTranslated(err)))
    )
  );
};

export const updateProfile = (
  profileData: Partial<IApiUserProfilePayload>,
  loadingProperty: IActiveRequestsKeys,
  notificationMessage: string = '',
  shouldHandleError: boolean = false
): IThunkMethod<Promise<void>> => (dispatch, _, { api }) => {
  return dispatch(
    loadingWrapper(
      loadingProperty,
      api
        .putUsersUpdateProfile(profileData)
        .then((user) => {
          dispatch(submitUser(user));
          dispatch(notifySuccess(getSimpleNotificationData(notificationMessage)));
        })
        .catch((err) => {
          if (shouldHandleError) {
            return dispatch(handleError(err));
          }
          return dispatch(rethrowTranslated(err));
        })
    )
  );
};

export const updateLanguageOrThrow = (language: ILanguage): IThunkMethod<Promise<void>> => (
  dispatch,
  _,
  { i18n }
) =>
  dispatch(
    updateProfile(
      { language },
      'language',
      i18n.t('settings:account.interfaceLanguageUpdatedNotification')
    )
  );

export const updateNicknameOrThrow = (nickname: string): IThunkMethod<Promise<void>> => (
  dispatch,
  _,
  { api, i18n }
) =>
  dispatch(
    loadingWrapper(
      'nickname',
      api
        .putUsersUpdateProfile({ nickname })
        .then((user) => {
          dispatch(submitUser(user));
          const notificationMessage = user.requested_nickname
            ? i18n.t('settings:account.trollboxNicknameUpdateRequestedNotification')
            : i18n.t('settings:account.trollboxNicknameUpdatedNotification');
          dispatch(notifySuccess(getSimpleNotificationData(notificationMessage)));
        })
        .catch((err) => dispatch(rethrowTranslated(err)))
    )
  );


export const clearNicknameChangeRequest = (canceled: boolean = false): IThunkMethod<Promise<void>> => (dispatch, _, { api, i18n }) =>
  dispatch(
    loadingWrapper(
      'nicknameDeclineReason',
      api
        .deleteNicknameChangeRequest()
        .then((user) => {
          dispatch(submitUser(user));
          if (canceled) {
            dispatch(notifySuccess(getSimpleNotificationData(i18n.t('settings:account.trollboxNicknameCancelRequestedNotification'))));
          }
        })
        .catch((err) => dispatch(rethrowTranslated(err)))
    )
  );

export const updateGeoLocationPinning = (geo_location_pinning: IGeoLocationPinning): IThunkMethod => (
  dispatch,
  _,
  { i18n }
) =>
  dispatch(
    updateProfile(
      { geo_location_pinning },
      'geoLocationPinning',
      i18n.t('settings:security.geoLocationPinningUpdatedNotification'),
      true
    )
  );

export const updateKeepSessionAlive = (enable_keep_alive: boolean): IThunkMethod => (
  dispatch,
  getState,
  { i18n }
) => {
  return dispatch(
    updateProfile(
      { enable_keep_alive },
      'enableKeepAlive',
      i18n.t('settings:security.keepSessionAliveNotification'),
      true
    )
  ).then(() => {
    // Now that we changed "keep_session_alive", let's get a new session with updated idle_timeout.
    return dispatch(renewSession());
  });
};

export const updateWithdrawalConfirmations = (
  enable_withdrawal_confirmations: boolean
): IThunkMethod => (dispatch, getState, { i18n }) =>
  dispatch(
    updateProfile(
      { enable_withdrawal_confirmations },
      'enableWithdrawalConfirmations',
      i18n.t('settings:security.withdrawalConfirmationsNotification'),
      true
    )
  );

export const updateOrderConfirmations = (enable_order_confirmations: boolean): IThunkMethod => (
  dispatch,
  _,
  { i18n }
) =>
  dispatch(
    updateProfile(
      { enable_order_confirmations },
      'enableOrderConfirmations',
      i18n.t('settings:notifications.orderConfirmationPopupNotification'),
      true
    )
  );

export const updateOrderNotifications = (enable_order_notifications: boolean): IThunkMethod => (
  dispatch,
  _,
  { i18n }
) =>
  dispatch(
    updateProfile(
      { enable_order_notifications },
      'enableOrderNotifications',
      i18n.t('settings:notifications.orderNotificationUpdatedNotification'),
      true
    )
  );
