import { createSelector } from 'reselect';

import { IPriceSummary24h, IPriceSummary24hPair } from '../actions/exchange';
import { IState } from '../lib/store';
import { IThemeColorsUnion } from '../lib/styled_components';
import {
  IApiMarketDepth,
  IApiMarketDepthSlice,
  IInstrument,
  IOrderSide,
  IPair,
} from '../types/backend_definitions';
// types
import { IInstrumentPair, makePair, pairInfo } from '../types/instruments';
import { IBestPrices } from '../types/orders';
import {
  IInstrumentConfig,
  IInstrumentsConfig,
  IPairConfig,
  IPairsConfig,
} from '../types/pairs_instruments_config';

// utils

export const isExchangeInitiated = (state: IState): boolean => state.exchangeInitiated;

// CURRENT PAIR

export const getCurrentPair = (state: IState): IInstrumentPair => state.currentPair;

export const getCurrentPairPath = (state: IState): IPair => {
  return getCurrentPair(state) ? getCurrentPair(state).path : null;
};

export const getNextPair = (state: IState): IPair => state.nextPair;

// PAIR CONFIGS

export const getPairsConfig = (state: IState): IPairsConfig => state.pairsConfig;

export const getCurrentPairConfig = (state: IState): IPairConfig =>
  getPairsConfig(state)[getCurrentPairPath(state)];

// INSTRUMENT CONFIGS

export const getInstrumentsConfig = (state: IState): IInstrumentsConfig => state.instrumentsConfig;

export const getInstrumentConfig = (state: IState, instrument: IInstrument): IInstrumentConfig =>
  getInstrumentsConfig(state)[instrument];

// MARKET, MARKETS, CURRENT PAIR 24H SUMMARY

export const getChangePercentForPriceSummaryPair = (summary: IPriceSummary24hPair) => {
  return summary.open ? ((summary.close - summary.open) / summary.open) * 100 : 0;
};

export const getPriceSummaryForPair = (state: IState, pair: IPair) => {
  return state.priceSummary24h[pair] || null;
};

export const getPriceSummary24hSortedByVolumeList = (
  state: IState,
  referenceInstrument: IInstrument = 'BTC'
): IPriceSummary24hPair[] => {
  const summaryByPair = state.priceSummary24h;

  const result = {};

  for (const pair in summaryByPair) {
    if (summaryByPair.hasOwnProperty(pair)) {
      const pi = pairInfo(pair as IPair);
      const { close, volume } = summaryByPair[pair];

      let refVolume = parseFloat(volume) === 0 ? volume : null;

      if (!refVolume) {
        if (pi.base.symbol === referenceInstrument) {
          // case: ***/BTC
          refVolume = volume;
        } else if (pi.quote.symbol === referenceInstrument) {
          // case: BTC/***
          refVolume = volume / close;
        } else {
          // ex: SFT/RSD

          // check RSD/BTC (base => reference instrument)
          const baseTargetPair = makePair(pi.base.symbol, referenceInstrument);
          // fallback to BTC/RSD (reference instrument => base)
          const targetQuotePair = makePair(referenceInstrument, pi.base.symbol);

          if (summaryByPair[baseTargetPair]) {
            const lastPrice = summaryByPair[baseTargetPair].close;
            refVolume = volume * lastPrice;
          } else if (summaryByPair[targetQuotePair]) {
            const lastPrice = summaryByPair[targetQuotePair].close;
            refVolume = lastPrice === 0 ? 0 : volume / lastPrice;
          }
        }
      }

      result[pair] = { ...summaryByPair[pair], ref_volume: refVolume };
    }
  }

  return Object.values(result).sort(
    (pair, nextPair) =>
      // sort by volume in descending order
      // @ts-ignore
      parseFloat(nextPair.ref_volume) - parseFloat(pair.ref_volume)
  ) as IPriceSummary24hPair[];
};

export const getPriceSummary24hForCurrentPair = (state: IState): IPriceSummary24hPair => {
  const pair = getCurrentPair(state);
  return pair ? getPriceSummaryForPair(state, pair.path) : null;
};

export const getLastPriceForCurrentPair = (state: IState): number => {
  const summary = getPriceSummary24hForCurrentPair(state);
  return summary ? summary.close : null;
};

export type IMarketsSummary = { [baseInstrument in IInstrument]?: IPriceSummary24hPair[] };

export const getMarketsSummary = createSelector<IState, IPriceSummary24h, IMarketsSummary>(
  (state) => state.priceSummary24h,
  (summary) => {
    const result = {};
    for (const pair in summary) {
      if (summary.hasOwnProperty(pair)) {
        const pi = pairInfo(pair as IPair);
        // noinspection JSMismatchedCollectionQueryUpdate
        const forBase: IPriceSummary24hPair[] =
          result[pi.base.symbol] || (result[pi.base.symbol] = []);
        forBase.push(summary[pair]);
      }
    }
    return result;
  }
);

// ORDER BOOK

const getMarketDepth = (state: IState) => state.marketDepth;

export const getMarketDepthSide = (state: IState, side: IOrderSide): IApiMarketDepthSlice =>
  state.marketDepth[side as string];

export const getBestPrices = createSelector(
  getMarketDepth,
  (marketDepth): IBestPrices => {
    const bestPrices: IBestPrices = { buy: 0, sell: 0 };

    if (marketDepth.buy.levels.length) {
      bestPrices.sell = marketDepth.buy.levels[0].price;
    }

    if (marketDepth.sell.levels.length) {
      bestPrices.buy = marketDepth.sell.levels[0].price;
    }

    return bestPrices;
  }
);

// ACTIVE INSTRUMENT

export const calculateCandleRect = (summary: IPriceSummary24hPair): [number, number] => {
  const max = Math.max(summary.open, summary.close);
  let min = Math.min(summary.open, summary.close);

  if (min === 0 && summary.low > 0) {
    // NOTE: This happens when backend doesn't have data for previous period, so it fills in 0.
    //       This is probably bad and should be changed on BE. But for now, tune the min value to be equal to low.
    min = summary.low;
  }

  /*
    count: 589
    low: 0.14728836
    high: 1.05010157
    open: 0
    close: 1.05010157
   */

  /*
    close: 0.63862603
    count: 589
    high: 1.05010157
    low: 0.14728836
    open: 0
   */

  /*
    HEIGHT:
      (high - low) / (max - min) = 100 / x
      x = (100 * (max - min)) / (high - low)
      (guard for division by 0)
   */

  let height =
    summary.high === summary.low ? 1 : (100 * (max - min)) / (summary.high - summary.low);

  /*
    TOP:
      Top will correspond with max value of the bar (open or close).

      Lowest px value that max can go to is low incremented by the height of the bar (max - min):
        bottom = low + (max - min)

      Result percentage can walk between 0% (when it's equal to "high") and some percentage which corresponds with 100% - height
        value:     high ... max ... bottom
        percent:    0%      x       100% - height

      Based on that:
        x / (100 - height) = (max - bottom) / (high - bottom)

      Except, the proportion must be reversed: when max goes up, percent should go down:
        x / (100 - height) = ((high - bottom) - (max - bottom)) / (high - bottom)

      So the final equation:
        x / (100 - height) = (high - max) / (high - bottom)
        x = ((high - max) * (100 - height)) / (high - bottom)
   */

  const bottom = summary.low + max - min;
  let top =
    summary.high === bottom ? 0 : ((summary.high - max) * (100 - height)) / (summary.high - bottom);

  // NOTE: There were some hacks, we want to veer on the side of reporting problems, but also not showing nonsense to users
  if (height < 0 || height > 100) {
    window.consoleError(
      `Candle rect height invalid. low: ${summary.low}, high: ${summary.high}, max: ${max}, min: ${min}, height: ${height}`
    );
    height = Math.min(Math.max(height, 0), 100);
  }
  if (top + height > 100) {
    window.consoleError(
      `Candle rect out of bounds. low: ${summary.low}, high: ${summary.high}, max: ${max}, min: ${min}, height: ${height}`
    );
    top = 100 - height;
  }

  return [top, height];
};

interface ICandleRectVertical {
  top: string;
  height: string;
}

export const calculateVerticalCandleRect = (summary: IPriceSummary24hPair): ICandleRectVertical => {
  const [top, height] = calculateCandleRect(summary);
  return {
    top: `${top.toFixed(4)}%`,
    height: `${height.toFixed(4)}%`,
  };
};

interface ICandleRectHorizontal {
  left: string;
  width: string;
}

export const calculateHorizontalCandleRect = (
  summary: IPriceSummary24hPair
): ICandleRectHorizontal => {
  const [top, height] = calculateCandleRect(summary);
  // When converting vertical to horizontal candle, we need to rotate then mirror the bar
  // That's because our coordinate system origin is in the top left. I think. Whatever.
  const left = 100 - top - height;
  return {
    left: `${left.toFixed(4)}%`,
    width: `${height.toFixed(4)}%`,
  };
};

export const getDirectionColor = (summary24h: IPriceSummary24hPair): IThemeColorsUnion => {
  return summary24h.close > summary24h.open
    ? 'buy'
    : summary24h.close < summary24h.open
    ? 'sell'
    : 'white';
};
