import { requestVerificationEmail } from '../../bl/email_verification';
import Container from '../../lib/container';
import { rethrowTranslated } from '../../lib/errors';
import { ICountryCode, ILanguage } from '../../types/backend_definitions';
import { loadingWrapper } from '../request_active';
import { goToExchange } from '../routing';
import { submitLoggedInPrincipal } from './auth';

export const register = (
  first_name: string,
  last_name: string,
  nationality: ICountryCode,
  email: string,
  password: string,
  captcha_token: string,
  language?: ILanguage
) => (dispatch, _, { api, i18n, matomo }: Container) => {
  return dispatch(
    loadingWrapper(
      'postCustomersRegister',
      api.postCustomersRegister({
        first_name,
        last_name,
        nationality,
        email,
        password,
        captcha_token,
        language,
      })
    )
  )
    .then((principal) => {
      dispatch(submitLoggedInPrincipal(principal));
      dispatch(requestVerificationEmail(false));
      dispatch(goToExchange());
      matomo.sendEvent('Onboarding', 'register', principal.user.id);
    })
    .catch((err) => dispatch(rethrowTranslated(err)));
};
