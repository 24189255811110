import moment, { Moment } from 'moment';
import { createSelector } from 'reselect';

import { IToastNotification } from '../actions/app';
import { IState } from '../lib/store';
import { DeepMutable } from '../types/typescript_helpers';

export const getLastNotification = createSelector(
  (state: IState) => state.app.toastNotifications,
  (toastNotifications) => toastNotifications[toastNotifications.length - 1] as IToastNotification
);

export const getSystemTimeDrift = (state: IState): number => state.app.systemTimeDrift;

export const getSystemTimeError = (state: IState) => state.app.systemTimeError;

export const systemTimeDriftToUTCMoment = (drift: number): Moment => moment.utc(Date.now() + drift);
