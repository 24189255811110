import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  SELL_ORDER_STATUS,
  cancelManagedSellOrder,
  downloadReport,
  getManagedSellOrderStatus,
  loadManagedUserSellOrders,
} from '../../../actions/transactions/managed';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { formatFullDateRegular } from '../../../lib/util';
import { OppositeArrowsIcon } from '../../../media/svg_icons';
import { getManagedConfig, getManagedSellOrders } from '../../../selectors/transactions';
import { IApiCensoredManagedSellOrder } from '../../../types/backend_definitions';
import { CURRENCY_TO_COUNTRY_ISO } from '../../../types/constants';
import { II18nextT } from '../../../types/i18n';
import $ColorText from '../../widgets/ColorText';
import CountryFlag from '../../widgets/CountryFlag';
import Header from '../../widgets/Header';
import InstrumentIcon from '../../widgets/InstrumentIcon';
import { $ToolbarLabel } from '../../widgets/Label';
import {
  $ActionsInfo,
  $AdditionalInfo,
  $CardActionsBlock,
  $CardColumnBlock,
  $CardColumnFixedBlock,
  $InfoLabel,
  $InnerWrapper,
  $ManagedGreenButton,
  $ManagedRedButton,
  $OrderCard,
  $Wrapper,
} from '../../widgets/Managed';
import { $HeaderWrapperJustified } from '../../widgets/Managed';
import SellOrderForm from './SellOrderForm';

const $ForInfo = styled.div`
  font-size: ${(p) => p.theme.fontSize.larger};
  font-weight: bold;
  color: ${(p) => p.theme.colors.white};
`;

const $StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const $NoDataMessage = styled.h3`
  text-align: center;
`;

const $BankAccNumColumn = styled($CardColumnFixedBlock)`
  min-width: 200px;
`;

const $SellLabelWrapper = styled.div`
  display: flex;
`;

const $InstrumentLabel = styled.div`
  position: relative;
  margin-left: 3px;
  bottom: 1px;

  > img {
    vertical-align: middle;
    position: relative;
    bottom: 1px;
    left: 2px;
  }
`;

class OrderRow extends React.PureComponent<
  {
    order: IApiCensoredManagedSellOrder;
    cancelManagedSellOrder: (orderId: string) => Promise<void>;
    downloadReport: (orderId: string) => Promise<void>;
    sellOrderFormRef: React.RefObject<any>;
  },
  { cancelling: boolean }
> {
  static contextType: any = I18nContext;

  constructor(props) {
    super(props);

    this.state = {
      cancelling: false,
    };
  }

  cancelOrder(orderId) {
    this.setState({ cancelling: true }, () => {
      return this.props.cancelManagedSellOrder(orderId);
    });
  }

  renderStatus(order: IApiCensoredManagedSellOrder) {
    const { t }: II18nextT = this.context;
    const status = getManagedSellOrderStatus(order);

    if (status === SELL_ORDER_STATUS.cancelled) {
      return <$ColorText color="warning">{t('managed.order.status.cancelled')}</$ColorText>;
    }

    if (status === SELL_ORDER_STATUS.fulfilled) {
      return <$ColorText color="success">{t('managed.order.status.fulfilled')}</$ColorText>;
    }

    if (status === SELL_ORDER_STATUS.failed) {
      return (
        <$StatusWrapper>
          <$ColorText color="warning">{t('managed.order.status.failed')}</$ColorText>
          <span>{order.failure_reason}</span>
        </$StatusWrapper>
      );
    }

    if (status === SELL_ORDER_STATUS.refunded) {
      return <$ColorText color="warning">{t('managed.order.status.refunded')}</$ColorText>;
    }

    if (status === SELL_ORDER_STATUS.executingOrder) {
      return <$ColorText color="info">{t('managed.order.status.executingOrder')}</$ColorText>;
    }

    if (status === SELL_ORDER_STATUS.suspended) {
      return (
        <$StatusWrapper>
          <$ColorText color="info">{t('managed.order.status.suspended')}</$ColorText>
          <span>{order.suspension_reason}</span>
        </$StatusWrapper>
      );
    }

    return <$ColorText color="info">{t('managed.order.status.pending')}</$ColorText>;
  }

  renderSellOrderContent(order: IApiCensoredManagedSellOrder) {
    const { t }: II18nextT = this.context;
    return (
      <div>
        <$CardColumnFixedBlock>
          <$SellLabelWrapper>
            <$InfoLabel>{t('sell')}</$InfoLabel>
            <$InstrumentLabel>
              {order.instrument}
              <InstrumentIcon name={order.instrument} size={15} />
            </$InstrumentLabel>
          </$SellLabelWrapper>
          <$ForInfo>{order.quantity}</$ForInfo>
        </$CardColumnFixedBlock>

        <$CardColumnBlock>
          <$InfoLabel>{t('managed.order.payoutCurrency')}</$InfoLabel>
          <$AdditionalInfo>
            {order.payout_currency}{' '}
            <CountryFlag isoCode={CURRENCY_TO_COUNTRY_ISO[order.payout_currency]} />
          </$AdditionalInfo>
        </$CardColumnBlock>

        <$BankAccNumColumn>
          <$InfoLabel>{t('managed.order.bankAccountNumber')}</$InfoLabel>
          <$AdditionalInfo>{order.bank_account_number}</$AdditionalInfo>
        </$BankAccNumColumn>

        <$CardColumnFixedBlock>
          <$InfoLabel>{t('managed.order.created')}</$InfoLabel>
          <$AdditionalInfo>{formatFullDateRegular(order.created_at)}</$AdditionalInfo>
        </$CardColumnFixedBlock>

        {order.taken_at && (
          <$CardColumnFixedBlock>
            <$InfoLabel>{t('managed.order.processingStarted')}</$InfoLabel>
            <$AdditionalInfo>{formatFullDateRegular(order.taken_at)}</$AdditionalInfo>
          </$CardColumnFixedBlock>
        )}

        {order.refunded_at && (
          <$CardColumnFixedBlock>
            <$InfoLabel>{t('managed.order.refunded')}</$InfoLabel>
            <$AdditionalInfo>{formatFullDateRegular(order.refunded_at)}</$AdditionalInfo>
          </$CardColumnFixedBlock>
        )}

        {order.completed_at && (
          <$CardColumnFixedBlock>
            <$InfoLabel>{t('managed.order.completed')}</$InfoLabel>
            <$AdditionalInfo>{formatFullDateRegular(order.completed_at)}</$AdditionalInfo>
          </$CardColumnFixedBlock>
        )}

        <$CardColumnBlock>
          <$InfoLabel>{t('managed.order.status.label')}</$InfoLabel>
          {this.renderStatus(order)}
        </$CardColumnBlock>
      </div>
    );
  }

  downloadReport(orderId) {
    return this.props.downloadReport(orderId);
  }

  renderActions(order: IApiCensoredManagedSellOrder) {
    const { t }: II18nextT = this.context;
    const status = getManagedSellOrderStatus(order);

    if (status === SELL_ORDER_STATUS.pending) {
      return (
        <$CardActionsBlock>
          <$InfoLabel>{t('managed.order.actions.label')}</$InfoLabel>
          <$ActionsInfo>
            <$ManagedGreenButton
              onClick={() => this.props.sellOrderFormRef.current.showForOrder(order)}
            >
              {t('managed.order.actions.editOrder')}
            </$ManagedGreenButton>
            <$ManagedRedButton
              disabled={this.state.cancelling}
              onClick={() => this.cancelOrder(order.id)}
            >
              {t('managed.order.actions.cancelOrder')}
            </$ManagedRedButton>
          </$ActionsInfo>
        </$CardActionsBlock>
      );
    }

    if (status === SELL_ORDER_STATUS.fulfilled) {
      return (
        <$CardActionsBlock>
          <$InfoLabel>{t('managed.order.actions.label')}</$InfoLabel>
          <$ActionsInfo>
            <$ManagedGreenButton onClick={() => this.downloadReport(order.id)}>
              {t('managed.order.actions.downloadReport')}
            </$ManagedGreenButton>
          </$ActionsInfo>
        </$CardActionsBlock>
      );
    }

    return null;
  }

  render() {
    return (
      <$OrderCard>
        {this.renderSellOrderContent(this.props.order)}
        {this.renderActions(this.props.order)}
      </$OrderCard>
    );
  }
}

interface ISellOrdersProps extends RouteComponentProps, ConnectedProps<typeof connector> {}
interface IOrdersState {}

class SellOrders extends React.PureComponent<ISellOrdersProps, IOrdersState> {
  static contextType: any = I18nContext;
  private sellOrderFormRef: React.RefObject<any> = React.createRef();
  private reportFormRef: React.RefObject<any> = React.createRef();

  componentDidMount() {
    this.props.loadManagedUserSellOrders().then(() => {
      const { location } = this.props;
      if (
        location.state.routeProps &&
        location.state.routeProps.instrument &&
        this.sellOrderFormRef.current
      ) {
        this.sellOrderFormRef.current.showForInstrument(location.state.routeProps.instrument);
      }
    });
  }

  renderOrders() {
    const { t }: II18nextT = this.context;

    return (
      <$Wrapper>
        <$HeaderWrapperJustified>
          <Header
            title={t('managed.pages.sellOrders')}
            icon={OppositeArrowsIcon}
            loadingKeys={['getUserManagedSellOrders']}
            hideOnMobile={true}
          />
          <div>
            <$ToolbarLabel htmlFor="newOrder">{t('managed.newOrder')}:</$ToolbarLabel>
            <$ManagedRedButton onClick={() => this.sellOrderFormRef.current.showForInstrument()}>
              {t('sell')}
            </$ManagedRedButton>
          </div>
        </$HeaderWrapperJustified>

        <$InnerWrapper>
          {this.props.orders.items.length ? (
            this.props.orders.items.map((order) => {
              return (
                <OrderRow
                  key={order.id}
                  order={order}
                  cancelManagedSellOrder={this.props.cancelManagedSellOrder}
                  downloadReport={this.props.downloadReport}
                  sellOrderFormRef={this.sellOrderFormRef}
                />
              );
            })
          ) : (
            <$NoDataMessage>{t('managed.noOrdersMessage')}</$NoDataMessage>
          )}
        </$InnerWrapper>
      </$Wrapper>
    );
  }

  render() {
    return (
      <div>
        {this.renderOrders()}
        <SellOrderForm ref={this.sellOrderFormRef} />
      </div>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    orders: getManagedSellOrders(state),
    config: getManagedConfig(state),
  }),
  {
    loadManagedUserSellOrders,
    cancelManagedSellOrder,
    downloadReport,
  }
);

export default withRouter(connector(SellOrders));
