import React from 'react';

import styled from '../../lib/styled_components';
import bgDarkGreenError from '../../media/dark-green-check-err.png';
import bgDarkGreen from '../../media/dark-green-check.png';
import bgLiteGreen from '../../media/lite-green-check.png';
import { IThemeVariant } from '../../styles/theme_night';

// image/css source: http://www.csscheckbox.com

// http://csscheckbox.com/checkboxes/lite-blue-check.png
// http://csscheckbox.com/checkboxes/lite-gray-check.png
// http://csscheckbox.com/checkboxes/lite-cyan-check.png
// http://csscheckbox.com/checkboxes/lite-orange-check.png
// http://csscheckbox.com/checkboxes/lite-red-check.png
// http://csscheckbox.com/checkboxes/lite-x-cyan.png
// http://csscheckbox.com/checkboxes/lite-x-gray.png
// http://csscheckbox.com/checkboxes/lite-x-blue.png
// http://csscheckbox.com/checkboxes/lite-x-orange.png
// http://csscheckbox.com/checkboxes/lite-x-red.png
// http://csscheckbox.com/checkboxes/lite-x-green.png
// http://csscheckbox.com/checkboxes/mac-style.png
// http://csscheckbox.com/checkboxes/mario-style.png
// http://csscheckbox.com/checkboxes/alert-style.png
// http://csscheckbox.com/checkboxes/lite-plus.png
// http://csscheckbox.com/checkboxes/dark-plus.png
// http://csscheckbox.com/checkboxes/dark-plus-cyan.png
// http://csscheckbox.com/checkboxes/dark-plus-orange.png
// http://csscheckbox.com/checkboxes/dark-check-cyan.png
// http://csscheckbox.com/checkboxes/dark-check-green.png
// http://csscheckbox.com/checkboxes/dark-check-orange.png
// http://csscheckbox.com/checkboxes/depressed-lite-small.png
// http://csscheckbox.com/checkboxes/elegant.png
// http://csscheckbox.com/checkboxes/depressed.png
// http://csscheckbox.com/checkboxes/chrome-style.png
// http://csscheckbox.com/checkboxes/web-two-style.png
// http://csscheckbox.com/checkboxes/vlad.png
// http://csscheckbox.com/checkboxes/klaus.png

const $Input = styled.input`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  &:checked + label {
    background-position: 0 -15px;
  }
`;
const $Label = styled.label<{ variant?: IThemeVariant; hasError?: boolean; uppercase?: boolean }>`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-position-y 0.15s ease-in;

  padding-left: 20px;
  height: 15px;
  display: inline-block;
  line-height: 15px;
  background-repeat: no-repeat;
  background-position: 0 0;
  vertical-align: middle;
  cursor: pointer;

  color: ${(p) => (p.hasError ? p.theme.colors.error : 'inherit')};

  background-image: url(${(p) =>
    p.variant === 'light' ? bgLiteGreen : p.hasError ? bgDarkGreenError : bgDarkGreen});
`;

const $Checkbox: React.FunctionComponent<
  { variant?: IThemeVariant; hasError?: boolean; uppercase?: boolean } & React.InputHTMLAttributes<
    HTMLInputElement
  >
> = ({
  name,
  onChange,
  hasError,
  variant = 'dark' as IThemeVariant,
  children = null,
  uppercase = false,
  ...props
}) => (
  <>
    <$Input
      type="checkbox"
      id={name}
      name={name}
      // className="css-checkbox"
      onChange={onChange}
      {...props}
    />
    <$Label htmlFor={name} variant={variant} hasError={hasError} uppercase={uppercase}>
      {children ? children : ''}
    </$Label>
  </>
);

export default $Checkbox;
