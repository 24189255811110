import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { I18n } from '../../../lib/i18n';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import chyngexUrlBoxImage from '../../../media/chyngex-box.jpg';
import { WarningIcon } from '../../../media/svg_icons';
import xcalibraUrlBoxImage from '../../../media/xcalibra-box.jpg';
import { IWhitelabel } from '../../../types/constants';
import { $Icon } from '../../widgets/Icon';
import LoginForm from './LoginForm';

const $UrlNotice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 5px;
  }

  p {
    text-align: center;
  }
`;

const $UrlImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${(p) => p.theme.widgets.loginForm.topDistance};
`;

interface ILoginScreenProps extends ConnectedProps<typeof connector> {}

const URL_BOX_IMAGES: { [key in IWhitelabel]: string } = {
  xcalibra: xcalibraUrlBoxImage,
  chyngex: chyngexUrlBoxImage,
};

const LoginScreen: React.FunctionComponent<ILoginScreenProps> = ({
  whitelabel,
  exchangeLink,
  exchangeTitle,
}) => {
  return (
    <I18n>
      {(t) => (
        <div>
          <$UrlNotice>
            <$Icon src={WarningIcon} size={16} />
            <p>
              {t('login.correctUrlWarning')} <strong>{exchangeLink}</strong>
            </p>
          </$UrlNotice>

          <$UrlImage>
            <img src={URL_BOX_IMAGES[whitelabel]} alt={`${exchangeTitle}`} />
          </$UrlImage>

          <LoginForm />
        </div>
      )}
    </I18n>
  );
};

const connector = connect((state: IState) => ({
  whitelabel: state.env.whitelabel.name,
  exchangeTitle: state.env.whitelabel.exchangeTitle,
  exchangeLink: state.env.links.exchange,
}));

export default connector(LoginScreen);
