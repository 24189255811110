import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { setDocumentTitle } from '../../actions/document';
import { IState } from '../../lib/store';

const fallbackImage = require('../../media/og_images/xc-black.png');
export interface IDocumentSEOProps {
  title?: string;
  description?: string;
  pageTitle?: string;
  titlePrefix?: string;
  imageUrl?: string;
}

interface IPageSeoWrapperProps
  extends ConnectedProps<typeof connector>,
    IDocumentSEOProps,
    RouteComponentProps {
  children: any;
}
interface IPageSeoWrapperState {}

class PageSeoWrapper extends React.PureComponent<IPageSeoWrapperProps, IPageSeoWrapperState> {
  componentDidMount(): void {
    const documentTitle = this.props.title ? this.props.title : this.buildPageTitle();
    this.props.setDocumentTitle(documentTitle);
  }
  componentDidUpdate(prevProps: IPageSeoWrapperProps): void {
    if (prevProps.title !== this.props.title || prevProps.pageTitle !== this.props.pageTitle) {
      const documentTitle = this.props.title ? this.props.title : this.buildPageTitle();
      this.props.setDocumentTitle(documentTitle);
    }
  }

  buildPageTitle() {
    // Build page title with product as suffix => used on secured pages
    const product = this.props.env.whitelabel.name;
    return `${this.props.pageTitle} - ${product.charAt(0).toUpperCase() + product.slice(1)}`;
  }

  buildTitleWithPrefix() {
    const { titlePrefix, title } = this.props;
    return `${titlePrefix} | ${title}`;
  }

  render() {
    const { titlePrefix, pageTitle, title, description, imageUrl, children } = this.props;
    // We can show prefixed title to user, eg. 0.44879995 - SFT_SFX | Trade Safex Token and Safex Cash - Xcalibra,
    // but send to matomo analytics title without prefix, eg. SFT_SFX | Trade Safex Token and Safex Cash - Xcalibra
    const documentTitle = pageTitle
      ? this.buildPageTitle()
      : titlePrefix
      ? this.buildTitleWithPrefix()
      : title;

    const exchangeLink = this.props.env.links.exchange;
    let metaImageUrl = imageUrl || fallbackImage;
    if (metaImageUrl && metaImageUrl.indexOf('/') === 0) {
      metaImageUrl = exchangeLink + metaImageUrl;
    }

    return (
      <>
        <Helmet>
          <title>{documentTitle}</title>
          <meta name="description" content={description} />
          <meta name="og:title" content={documentTitle} />
          <meta name="og:description" content={description} />
          <meta property="og:url" content={exchangeLink + this.props.location.pathname} />
          <meta property="og:image" content={metaImageUrl} />
          <meta property="og:image:url" content={metaImageUrl} />
          <meta property="twitter:image" content={metaImageUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
        {children}
      </>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    env: state.env,
  }),
  {
    setDocumentTitle,
  }
);

export default withRouter(connector(PageSeoWrapper));
