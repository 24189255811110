import * as React from 'react';

import styled from '../../../lib/styled_components';
import { $Button, IButtonProps } from '../../widgets/buttons';
import GenericTooltip, { IGenericTooltipVariant } from '../../widgets/tooltips/GenericTooltip';

const $UserMenuButton = styled($Button).attrs((p) => ({
  backgroundColor: p.theme.colors.transparent,
  hoverBackground: p.theme.colors.transparent,
  textTransform: 'normal',
}))<{ active?: boolean }>`
  border-radius: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: calc((${(p) => p.theme.layout.topWidgets.icons.defaultSize} - 2px));
    height: calc((${(p) => p.theme.layout.topWidgets.icons.defaultSize} - 2px));
  }

  background: ${(p) => (p.active ? p.theme.colors.sidebarColor : null)};

  &:hover {
    background: ${(p) => (p.active ? p.theme.colors.sidebarColor : null)};

    color: ${(p) => (p.active ? null : p.theme.components.topMenu.hover.text)};

    .svg-icon-color-stroke {
      stroke: ${(p) => p.theme.components.topMenu.hover.icon} !important;
    }
    .svg-icon-color-fill {
      fill: ${(p) => p.theme.components.topMenu.hover.icon} !important;
    }
  }

  @media screen and ${(p) => p.theme.device.laptop} {
    svg {
      width: calc((${(p) => p.theme.layout.topWidgets.icons.laptopSize} - 2px));
      height: calc((${(p) => p.theme.layout.topWidgets.icons.laptopSize} - 2px));
    }
  }
`;

const $UserMenuButtonAsPullDown = styled($UserMenuButton)`
  color: ${(p) => (p.active ? p.theme.components.topMenu.hover.text : null)};
  background: ${(p) => (p.active ? p.theme.colors.brandPrimary : null)};

  &:hover {
    color: ${(p) => p.theme.components.topMenu.hover.text};
    background: ${(p) => (p.active ? p.theme.colors.brandPrimary : null)};
  }

  .svg-icon-color-stroke {
    stroke: ${(p) => (p.active ? p.theme.components.topMenu.hover.icon + ' !important' : null)};
  }
  .svg-icon-color-fill {
    fill: ${(p) => (p.active ? p.theme.components.topMenu.hover.icon + ' !important' : null)};
  }
`;

interface IProps extends IButtonProps {
  tooltipVariant?: IGenericTooltipVariant;
  tooltip?: React.ReactNode;
  active?: boolean;
  pullDown?: boolean;
}

export const UserMenuButton: React.FunctionComponent<IProps> = ({
  children,
  tooltipVariant,
  tooltip,
  pullDown,
  ...buttonProps
}) => {
  const ButtonComponent = pullDown ? $UserMenuButtonAsPullDown : $UserMenuButton;
  return (
    <GenericTooltip overlay={buttonProps.active === true ? null : tooltip} variant={tooltipVariant}>
      <ButtonComponent {...(buttonProps as any)}>{children}</ButtonComponent>
    </GenericTooltip>
  );
};
