import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import styled, { zIndex } from '../../../lib/styled_components';
import FooterLinks from '../../components/exchange/FooterLinks';
import { ShowMobile } from '../../utility_components';
import LeftWidgets from './LeftWidgets';
import TopWidgets from './TopWidgets';

const $Layout = styled.div`
  margin: 0 auto;
  max-width: ${(p) => p.theme.layout.maxWidth}px;
  min-height: 100vh;
  position: relative;

  @media screen and ${(p) => p.theme.device.mobile} {
    padding-bottom: 20px;
  }
`;

const $Top = styled.div`
  position: fixed;
  z-index: ${zIndex.topBar};

  height: ${(p) => p.theme.layout.headerHeight.default}px;

  left: calc(
    (100vw - (100vw - 100%) - ${(p) => p.theme.layout.maxWidth}px) / 2 +
      ${(p) => p.theme.layout.sidebarWidth}px
  );
  right: 0;

  min-width: calc(${(p) => p.theme.layout.maxWidth}px - ${(p) => p.theme.layout.sidebarWidth}px);

  @media only screen and (max-width: ${(p) => p.theme.layout.maxWidth}px) {
    min-width: calc(100vw - (${(p) => p.theme.layout.sidebarWidth}px));
    left: calc(${(p) => p.theme.layout.sidebarWidth}px);
  }

  @media only screen and ${(p) => p.theme.device.mobile} {
    position: static;
    height: ${(p) => p.theme.layout.headerHeight.mobile}px;
  }
`;

const $Left = styled.div`
  background: ${(p) => p.theme.layout.sidebar.background};
  position: fixed;
  left: 0;
  display: flex;
  justify-content: flex-end;

  width: calc(
    (100% - ${(p) => p.theme.layout.maxWidth}px) / 2 + ${(p) => p.theme.layout.sidebarWidth}px
  );
  min-width: ${(p) => p.theme.layout.sidebarWidth}px;
  height: 100vh;

  @media only screen and ${(p) => p.theme.device.mobile} {
    display: none;

    background: transparent;
    position: static;
    height: auto;
    width: 100%;
  }
`;
const $Central = styled.div`
  padding-left: ${(p) => p.theme.layout.sidebarWidth}px;
  padding-top: ${(p) => p.theme.layout.headerHeight.default}px;

  @media screen and ${(p) => p.theme.device.mobile} {
    padding: 0;
  }
`;

const $PolicyLinksWrapper = styled.div``;

interface IMainLayoutProps extends ConnectedProps<typeof connector> {
  children?: any;
}

interface IMainLayoutState {
  key: any;
}

class MainLayout extends React.PureComponent<IMainLayoutProps, IMainLayoutState> {
  state = {
    key: '',
  };

  render() {
    const { children } = this.props;
    return (
      <$Layout>
        <$Top>
          <TopWidgets />
        </$Top>
        <$Left>
          <LeftWidgets />
        </$Left>
        <$Central>{children}</$Central>
        <ShowMobile>
          <$PolicyLinksWrapper>
            <FooterLinks />
          </$PolicyLinksWrapper>
        </ShowMobile>
      </$Layout>
    );
  }
}

const connector = connect(null, null);

export default connector(MainLayout);
