import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { logout } from '../../../actions/auth/logout';
import R, { IRouteSpecWithoutParams, getRouteEnablementProps } from '../../../lib/routes';
import { IState } from '../../../lib/store';
import styled, { zIndex } from '../../../lib/styled_components';
import {
  BanknoteWithArrowsIcon,
  CardIcon,
  CheckedPaperIcon,
  Cog2Icon,
  LaptopWithChartIcon,
  LoginIcon,
  LogoutIcon,
  OppositeArrowsIcon,
  SpinningCircleIcon,
  WalletIcon,
} from '../../../media/svg_icons';
import { isUserLoggedIn } from '../../../selectors/auth';
import { II18nextT } from '../../../types/i18n';
import { ITranslations } from '../../../types/translations';
import { IDropdownMenuItem } from '../../widgets/DropdownMenu';
import { $Icon } from '../../widgets/Icon';
import $Link from '../../widgets/Link';
import AccountGadget from '../menu_gadgets/AccountGadget';
import { $MenuGadgetFooter, $MenuGadgetHeader } from '../menu_gadgets/menu_gadgets_common';
import NotificationsGadget from '../menu_gadgets/NotificationsGadget';

// SOURCE:  https://codepen.io/anon/pen/NEyNqP
const $MobileMenu = styled.div`
  position: relative;
  padding: 10px;
  // Put it over stuff like "Trading disabled" boxes
  z-index: ${zIndex.mobileMenu};

  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${(p) => p.theme.device.biggerThanMobile} {
    display: none;
  }
`;

const $MenuToggle = styled.input.attrs({ type: 'checkbox' })`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;

  &:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(2px, 1px);
    background: ${(p) => p.theme.colors.info};
  }
  &:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  &:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0px, 2px);
  }
  &:checked ~ div {
    transform: none;
  }
`;

const $Bar = styled.span`
  display: block;
  width: 28px;
  height: 2px;
  position: relative;
  &:nth-child(-n + 3) {
    margin-bottom: 6px;
  }

  background: ${(p) => p.theme.colors.baseLight};
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

  &:first-child {
    transform-origin: 0 0;
  }
  &:nth-last-child(2) {
    transform-origin: 0 100%;
  }
`;

const $Links = styled.ul`
  list-style-type: none;
  padding: 0;

  li {
    a {
      display: flex;
      justify-content: flex-end;
      font-size: ${(p) => p.theme.fontSize.largest};
      padding: 10px 27px;
      width: 100%;
      text-align: right;
      span {
        display: inline-block;
        padding-right: 1rem;
      }
      color: ${(p) => p.theme.components.topMenu.normal.text} !important;

      &:hover {
        color: ${(p) => p.theme.components.topMenu.hover.text} !important;
        .svg-icon-color-stroke {
          stroke: ${(p) => p.theme.components.topMenu.hover.icon} !important;
        }
        .svg-icon-color-fill {
          fill: ${(p) => p.theme.components.topMenu.hover.icon} !important;
        }
      }
    }
  }

  .svg-icon-color-stroke {
    stroke: ${(p) => p.theme.components.topMenu.normal.icon} !important;
  }
  .svg-icon-color-fill {
    fill: ${(p) => p.theme.components.topMenu.normal.icon} !important;
  }

  .active {
    background: ${(p) => p.theme.components.topMenu.active.background};
    color: ${(p) => p.theme.components.topMenu.active.text} !important;
    .svg-icon-color-stroke {
      stroke: ${(p) => p.theme.components.topMenu.active.icon} !important;
    }
  }
`;

const $MenuBackdrop = styled.div`
  overflow-y: auto;
  position: absolute;
  width: 100vw;
  right: -10px;
  margin: 0;
  height: calc(100vh - 47px);
  top: 47px;

  background: ${(p) => p.theme.layout.header.background};
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0 0;
  transform: translate(-100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
`;

const $GadgetHolder = styled.div`
  > div {
    width: 100%;
    margin: 0 auto;
  }

  ${$MenuGadgetHeader} {
    background: none;
  }

  ${$MenuGadgetFooter} {
    background: none;
  }

  @media screen and ${(p) => p.theme.device.mobile_SamsungGalaxyTab2} {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
`;

const $MenuContent = styled.div<{ isLoggedIn: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-column-gap: 2rem;
  margin: 2rem;

  ${$Links} {
    grid-row: 1/3;
    grid-column: 2;
  }

  @media screen and ${(p) => p.theme.device.mobile_SamsungGalaxyTab2} {
    display: block;
    margin: 0;
  }
`;
interface IMobileMenuItem extends IDropdownMenuItem {
  label: ITranslations;
  show: boolean;
  route: IRouteSpecWithoutParams;
  className?: string;
}

interface IMobileMenuProps extends RouteComponentProps, ConnectedProps<typeof connector> {}
interface IMobileMenuState {
  open: boolean;
}

class MobileMenu extends React.PureComponent<IMobileMenuProps, IMobileMenuState> {
  static contextType: any = I18nContext;
  private stopListeningHistory: () => void = null;

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  setMenuOpen(open: boolean) {
    this.setState({
      open,
    });
    document.body.style.overflow = open ? 'hidden' : 'auto';
  }

  handleMenuChange = (e) => {
    this.setMenuOpen(e.target.checked);
  };

  componentDidMount() {
    this.stopListeningHistory = this.props.history.listen(() => {
      if (this.stopListeningHistory) {
        // If route changes (eg. because user clicked a link inside), close the menu
        this.setMenuOpen(false);
      }
    });
  }

  componentWillUnmount = () => {
    document.body.style.overflow = 'auto';

    if (this.stopListeningHistory) {
      this.stopListeningHistory();
      this.stopListeningHistory = null;
    }
  };

  componentDidUpdate() {
    this.state.open && this.props.windowSize !== 'mobile' && this.setMenuOpen(false);
  }

  isActiveClass = (condition) => (condition ? 'active' : '');

  renderMobileMenu(): IMobileMenuItem[] {
    return [
      {
        label: 'pages.exchange',
        route: R.EXCHANGE_HOME,
        show: true,
        icon: BanknoteWithArrowsIcon,
        className: this.isActiveClass(R.EXCHANGE.isActive(this.props.location)),
      },
      {
        label: 'pages.markets',
        route: R.MARKETS,
        show: true,
        icon: SpinningCircleIcon,
        className: this.isActiveClass(R.MARKETS.isActive(this.props.location)),
      },
      {
        label: 'pages.balances',
        route: R.BALANCES,
        show: this.props.loggedIn,
        icon: WalletIcon,
        className: this.isActiveClass(R.BALANCES.isActive(this.props.location)),
      },
      {
        label: 'pages.reports',
        route: R.REPORTS_HOME,
        show: this.props.loggedIn,
        icon: OppositeArrowsIcon,
        className: this.isActiveClass(R.REPORTS.isActive(this.props.location)),
      },
      {
        label: 'pages.buyWithCard',
        route: R.BUY_WITH_CARD,
        show: true,
        icon: CardIcon,
        className: this.isActiveClass(R.BUY_WITH_CARD.isActive(this.props.location)),
      },
      {
        label: 'pages.managed',
        route: R.MANAGED_HOME,
        show: this.props.loggedIn,
        icon: LaptopWithChartIcon,
        className: this.isActiveClass(
          R.MANAGED_HOME.isActive(this.props.location) || R.MANAGED.isActive(this.props.location)
        ),
      },
      {
        label: 'pages.settings',
        route: R.SETTINGS_HOME,
        show: this.props.loggedIn,
        icon: Cog2Icon,
        className: this.isActiveClass(
          R.SETTINGS_HOME.isActive(this.props.location) || R.SETTINGS.isActive(this.props.location)
        ),
      },
      {
        label: 'pages.login',
        route: R.LOGIN,
        show: !this.props.loggedIn,
        icon: LoginIcon,
      },
      {
        label: 'pages.register',
        route: R.REGISTER,
        show: !this.props.loggedIn,
        icon: CheckedPaperIcon,
      },
    ];
  }

  render() {
    const { t }: II18nextT = this.context;

    return (
      <$MobileMenu role="navigation">
        <$MenuToggle onChange={this.handleMenuChange} checked={this.state.open} />

        <$Bar />
        <$Bar />
        <$Bar />

        <$MenuBackdrop>
          <$MenuContent isLoggedIn={this.props.loggedIn}>
            {this.props.loggedIn && (
              <$GadgetHolder>
                <AccountGadget />
              </$GadgetHolder>
            )}
            <$Links>
              {this.renderMobileMenu()
                .filter((item) => {
                  return item.route.isEnabled(this.props.routeEnablementProps);
                })
                .map(
                  (item) =>
                    item.show && (
                      <li key={item.label} className={item.className}>
                        <$Link as={Link} to={item.route}>
                          <span>{t(item.label)}</span>
                          {item.icon && <$Icon src={item.icon} size={30} />}
                        </$Link>
                      </li>
                    )
                )}
              {/* Logout link */}
              {this.props.loggedIn && (
                <li>
                  <$Link
                    as={Link}
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setMenuOpen(false);
                      this.props.logout();
                    }}
                  >
                    <span>{t('pages.logout')}</span>
                    <$Icon src={LogoutIcon} size={30} />
                  </$Link>
                </li>
              )}
            </$Links>
            {this.props.loggedIn && (
              <$GadgetHolder>
                <NotificationsGadget />
              </$GadgetHolder>
            )}
          </$MenuContent>
        </$MenuBackdrop>
      </$MobileMenu>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    routeEnablementProps: getRouteEnablementProps(state),
    loggedIn: isUserLoggedIn(state),
    windowSize: state.document.windowSize,
  }),
  {
    logout,
  }
);

export default withRouter(connector(MobileMenu));
