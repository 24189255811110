import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { ICustomHref } from '../../lib/history';
import { I18n } from '../../lib/i18n';
import { IState } from '../../lib/store';
import styled from '../../lib/styled_components';
import { ArrowDownIcon, ArrowUpIcon } from '../../media/svg_icons';
import { ITranslations } from '../../types/translations';
import { HideMobile, ShowMobile } from '../utility_components';
import { DropdownMenu, IDropdownMenuItem } from '../widgets/DropdownMenu';
import Header from '../widgets/Header';
import { $Icon } from '../widgets/Icon';
import { $HeaderWrapper, $LeftHeaderWrapper } from '../widgets/PageHeaderWrappers';

const $Wrapper = styled.div`
  min-height: calc(100vh - ${(p) => p.theme.layout.headerHeight.default}px);
  @media screen and ${(p) => p.theme.device.mobile} {
    flex-direction: column;
    position: relative;
  }
`;

const $Left = styled.div`
  transition: width
    ${(p) =>
      p.theme.layout.sideMenu.transitionDuration + ' ' + p.theme.layout.sideMenu.transitionFunc};
  width: ${(p) => p.theme.layout.sideMenu.defaultGap};
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  @media screen and ${(p) => p.theme.device.laptop} {
    width: ${(p) => p.theme.layout.sideMenu.laptopGap};
  }

  @media screen and ${(p) => p.theme.device.mobile} {
    transition: none;

    padding: 0;
    width: auto;
  }
`;

const $Right = styled.div<{ sideMenuRightMaxWidth?: string }>`
  transition: margin
    ${(p) =>
      p.theme.layout.sideMenu.transitionDuration + ' ' + p.theme.layout.sideMenu.transitionFunc};
  margin-left: ${(p) => p.theme.layout.sideMenu.defaultGap};

  @media screen and ${(p) => p.theme.device.laptop} {
    margin-left: ${(p) => p.theme.layout.sideMenu.laptopGap};
  }

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: ${(p) => p.sideMenuRightMaxWidth && p.sideMenuRightMaxWidth};

  @media (max-width: ${(p) => p.theme.base.mobileBreakpoint + 100 + 'px'}) {
    transition: none;
  }
  @media screen and ${(p) => p.theme.device.mobile} {
    transition: none;
    padding: 0;
    margin-left: 0;
    max-width: initial;
  }
`;

const $StickyWrapper = styled.div`
  position: fixed;
  width: inherit;

  @media screen and ${(p) => p.theme.device.mobile} {
    position: initial;
  }
`;

export interface ISideMenuItem extends IDropdownMenuItem {
  subtitle?: ITranslations;
}

// TODO: Refactor these pages, selectedRoute and currentPage, it's a tangled mess

export interface ISideMenuLayoutProps extends ConnectedProps<typeof connector> {
  selectedRoute: ICustomHref;
  pages: { [key: string]: ISideMenuItem };
  headerIcon: (props: any) => JSX.Element;
  headerText: ITranslations;
  currentPage: ISideMenuItem;
  sideMenuRightMaxWidth?: string;
  children?: any;
}

interface ISideMenuLayoutState {
  showMobileMenu: boolean;
  stickHeader: boolean;
}

class SideMenuLayout extends React.PureComponent<ISideMenuLayoutProps, ISideMenuLayoutState> {
  STICKY_HEADER_TOP_OFFSET: number;

  constructor(props) {
    super(props);

    this.state = {
      showMobileMenu: false,
      stickHeader: false,
    };
  }

  getTableHeaderTopOffset = (topOffset: number) => (this.STICKY_HEADER_TOP_OFFSET = topOffset);

  onVerticalScroll = () => this.shouldMakeHeaderSticky();

  toggleMobileMenu = () =>
    this.setState((prevState) => ({ showMobileMenu: !prevState.showMobileMenu }));

  shouldMakeHeaderSticky() {
    const verticalOffset =
      this.STICKY_HEADER_TOP_OFFSET -
      (this.props.theme.layout.headerHeight.default +
        this.props.theme.widgets.header.wrappers.left.height);

    if (
      window.innerWidth >= this.props.theme.base.mobileBreakpoint &&
      window.scrollY > verticalOffset
    ) {
      !this.state.stickHeader && this.setState({ stickHeader: true });
    } else {
      this.state.stickHeader && this.setState({ stickHeader: false });
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onVerticalScroll);
    this.shouldMakeHeaderSticky();
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.onVerticalScroll);
  }

  render() {
    const { headerText, headerIcon, currentPage, selectedRoute, pages, children } = this.props;
    return (
      <I18n>
        {(t) => (
          <$Wrapper>
            <$Left>
              <$StickyWrapper>
                <$LeftHeaderWrapper className="hide-on-mobile">
                  <Header title={t(headerText)} variant="dark" icon={headerIcon} capitalized />
                </$LeftHeaderWrapper>
                <ShowMobile>
                  <$HeaderWrapper onClick={() => this.toggleMobileMenu()}>
                    <Header
                      title={currentPage ? t(currentPage.label as ITranslations) : null}
                      subtitle={currentPage ? t(currentPage.subtitle) : null}
                      icon={currentPage ? currentPage.icon : ''}
                    />
                    <$Icon
                      src={this.state.showMobileMenu ? ArrowUpIcon : ArrowDownIcon}
                      size={16}
                    />
                  </$HeaderWrapper>
                  <DropdownMenu
                    isOpen={this.state.showMobileMenu}
                    toggleMenu={this.toggleMobileMenu}
                    sections={[{ items: Object.values(pages) }]}
                    selectedRoute={selectedRoute}
                  />
                </ShowMobile>
                <HideMobile>
                  <DropdownMenu
                    isOpen={true}
                    sections={[{ items: Object.values(pages) }]}
                    selectedRoute={selectedRoute}
                  />
                </HideMobile>
              </$StickyWrapper>
            </$Left>
            <$Right sideMenuRightMaxWidth={this.props.sideMenuRightMaxWidth}>
              {React.Children.map(children, (child) =>
                React.cloneElement(child as any, {
                  stickHeader: this.state.stickHeader,
                  getTableHeaderTopOffset: this.getTableHeaderTopOffset,
                })
              )}
            </$Right>
          </$Wrapper>
        )}
      </I18n>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    theme: state.app.theme,
  }),
  {}
);

export default connector(SideMenuLayout);
