import { loadAuthPrincipal, setUserKYCLevel, submitSuspendedUser } from '../actions/auth/auth';
import { notifyResolvedKYCRequest, setCustomer } from '../actions/auth/customer';
import { updateBalances } from '../actions/balances';
import {
  setPriceSummary24hFromApiData,
  updatePriceSummary24hFromMarketTrade,
} from '../actions/exchange';
import { updateMarketDepth } from '../actions/orderbook';
import { removeOrder } from '../actions/orders/cancel_order';
import { processNewMarketTrade } from '../actions/orders/market_trades';
import { notifyCompletedOrder, updateOrder } from '../actions/orders/orders';
import { addOrder } from '../actions/orders/submit_order';
import { addUserTrade } from '../actions/orders/user_trades';
import { processMarketRates, setExternalRates } from '../actions/rates';
import { updateCardPayment } from '../actions/transactions/card_payments';
import {
  loadPendingDepositsForAllInstruments,
  updateDeposit,
} from '../actions/transactions/deposits';
import {
  updateManagedBuyOrderInState,
  updateManagedSellOrderInState,
} from '../actions/transactions/managed';
import {
  updateCompletedWithdrawal,
  updateConfirmedWithdrawal,
} from '../actions/transactions/withdrawals';
import { setEmailVerfiedStatus } from '../bl/email_verification';
import {
  IApiCensoredDeposit,
  IApiCensoredKYCRequestResolvedEventData,
  IApiCensoredOrder,
  IApiCensoredOrderFilledEventData,
  IApiCensoredOrderPartiallyFilledEventData,
  IApiCensoredWithdrawal,
  IApiExternalRates,
  IApiMarketDepth,
  IApiMarketRates,
  IApiPriceHistorySummary,
  IApiPublicCustomerInfo,
  IApiPublicTradeInfo,
  IApiUser,
  IApiUserEmailVerifiedEventData,
  IApiWallet,
  IOrderSide,
  IPair,
} from '../types/backend_definitions';
import {
  orderResponseTransformation,
  tradeResponseTransformation,
} from './api_response_transformations';
import { SocketManager } from './socket_manager';

export class SocketListener {
  constructor(private socketManager: SocketManager, private dispatch) {
    this.socketManager.onSectorEvent('pair', 'market_depth', this.onMarketDepth);
    this.socketManager.onEvent('price_summary_24h', this.priceSummary24h);
    this.socketManager.onEvent('public_trade', this.onPublicTrade);
    this.socketManager.onEvent('order_cancelled', this.onOrderCancelled);
    this.socketManager.onEvent('order_added', this.onOrderAdded);
    this.socketManager.onEvent('user_trade', this.onUserTrade);
    this.socketManager.onEvent('order_filled', this.onOrderFilled);
    this.socketManager.onEvent('order_partially_filled', this.onOrderPartiallyFilled);
    this.socketManager.onEvent('order_triggered', this.onOrderTriggered);
    this.socketManager.onEvent('wallet', this.onWallet);
    this.socketManager.onEvent('deposit_incoming', this.depositIncoming);
    this.socketManager.onEvent('deposit_completed', this.depositCompleted);
    this.socketManager.onEvent('withdrawal_confirmed', this.withdrawalConfirmed);
    this.socketManager.onEvent('withdrawal_completed', this.withdrawalCompleted);
    this.socketManager.onEvent('card_payment_approved', this.onApprovedCardPayment);
    this.socketManager.onEvent('card_payment_declined', this.onDeclinedCardPayment);
    this.socketManager.onEvent('kyc_request_resolved', this.onKycRequestResolved);
    this.socketManager.onEvent('kyc_request_stalled', this.onKycRequestStalled);
    this.socketManager.onEvent('user_suspended', this.onUserSuspended);
    this.socketManager.onEvent('external_rates_updated', this.onExternalRatesUpdated);
    this.socketManager.onEvent('market_rates_updated', this.onMarketRatesUpdated);
    this.socketManager.onEvent('managed_buy_order_updated', this.onManagedBuyOrderUpdated);
    this.socketManager.onEvent('managed_sell_order_updated', this.onManagedSellOrderUpdated);
    this.socketManager.onEvent('execute_email_verification', this.onEmailVerified);
  }

  onManagedBuyOrderUpdated = (order) => {
    this.dispatch(updateManagedBuyOrderInState(order));
  };

  onManagedSellOrderUpdated = (order) => {
    this.dispatch(updateManagedSellOrderInState(order));
  };

  onApprovedCardPayment = (cardPayment) => {
    this.dispatch(updateCardPayment(cardPayment));
  };

  onDeclinedCardPayment = (cardPayment) => {
    this.dispatch(updateCardPayment(cardPayment));
  };

  priceSummary24h = (summary: IApiPriceHistorySummary) => {
    this.dispatch(setPriceSummary24hFromApiData(summary));
  };

  onOrderCancelled = (cancelledOrder: IApiCensoredOrder) => {
    this.dispatch(removeOrder(cancelledOrder.id));
  };

  onMarketDepth = (marketDepth: IApiMarketDepth) => {
    this.dispatch(updateMarketDepth(marketDepth));
  };

  onOrderAdded = (placedOrder: IApiCensoredOrder) => {
    const newOrder = orderResponseTransformation(placedOrder);

    this.dispatch(addOrder(newOrder));
  };

  onPublicTrade = (marketTradeResponse: IApiPublicTradeInfo) => {
    // add trade to the list of last market trades
    const trade = tradeResponseTransformation(marketTradeResponse);
    this.dispatch(processNewMarketTrade(trade));
    this.dispatch(updatePriceSummary24hFromMarketTrade(trade));
  };

  onUserTrade = (marketTradeResponse: IApiPublicTradeInfo) => {
    const trade = tradeResponseTransformation(marketTradeResponse);
    this.dispatch(addUserTrade(trade));
    this.dispatch(processNewMarketTrade(trade));
  };

  onOrderFilled = (orderFilledResponse: IApiCensoredOrderFilledEventData) => {
    // notify user about successful trade
    this.dispatch(
      notifyCompletedOrder(
        'full',
        orderFilledResponse.order.side as IOrderSide,
        orderFilledResponse.order.pair as IPair
      )
    );

    if (!orderFilledResponse.is_matcher) {
      // We only need to remove filled orders if they are matchees (because otherwise they are not in the order book)
      this.dispatch(removeOrder(orderFilledResponse.order.id));
    }
  };

  onOrderPartiallyFilled = (
    orderPartiallyFilledResponse: IApiCensoredOrderPartiallyFilledEventData
  ) => {
    // ignore this event for new partially filled orders
    if (orderPartiallyFilledResponse.is_matcher) {
      return;
    }

    const order = orderResponseTransformation(orderPartiallyFilledResponse.order);

    // set to reduced order quantity
    order.quantity = Number(orderPartiallyFilledResponse.remaining_quantity);

    // notify user about successful trade
    this.dispatch(notifyCompletedOrder('partial', order.side as IOrderSide, order.pair as IPair));

    this.dispatch(updateOrder(order));
  };

  onOrderTriggered = (orderResponse: IApiCensoredOrder) => {
    const order = orderResponseTransformation(orderResponse);

    this.dispatch(notifyCompletedOrder('stop', order.side as IOrderSide, order.pair as IPair));
    this.dispatch(removeOrder(orderResponse.id));
  };

  onWallet = (walletResponse: IApiWallet) => {
    this.dispatch(updateBalances(walletResponse));
  };

  // Deposits

  depositIncoming = (depositResponse: IApiCensoredDeposit) => {
    this.dispatch(updateDeposit(depositResponse));
    this.dispatch(loadPendingDepositsForAllInstruments());
  };

  depositCompleted = (depositResponse: IApiCensoredDeposit) => {
    this.dispatch(updateDeposit(depositResponse, true));
    this.dispatch(loadPendingDepositsForAllInstruments());
  };

  // Withdrawals

  withdrawalConfirmed = (withdrawalResponse: IApiCensoredWithdrawal) => {
    this.dispatch(updateConfirmedWithdrawal(withdrawalResponse));
  };

  withdrawalCompleted = (withdrawalResponse: IApiCensoredWithdrawal) => {
    this.dispatch(updateCompletedWithdrawal(withdrawalResponse));
  };

  onKycRequestResolved = (eventData: IApiCensoredKYCRequestResolvedEventData) => {
    if (eventData.granted && eventData.level) {
      this.dispatch(setUserKYCLevel(eventData.level));
    }
    this.dispatch(loadAuthPrincipal());
    this.dispatch(setCustomer(eventData.customer));
    this.dispatch(notifyResolvedKYCRequest(eventData.level, eventData.granted));
  };

  onKycRequestStalled = (updatedCustomer: IApiPublicCustomerInfo) => {
    this.dispatch(setCustomer(updatedCustomer));
  };

  onUserSuspended = (updatedUser: IApiUser) => {
    this.dispatch(submitSuspendedUser(updatedUser));
  };

  onExternalRatesUpdated = (rates: IApiExternalRates) => {
    this.dispatch(setExternalRates(rates));
  };

  onMarketRatesUpdated = (rates: IApiMarketRates) => {
    this.dispatch(processMarketRates(rates));
  };

  onEmailVerified = (eventData: IApiUserEmailVerifiedEventData) => {
    this.dispatch(setEmailVerfiedStatus(eventData));
  };
}
