import { LoggerLevel } from '../lib/logger';
import { commit, val } from '../lib/redux_helpers';
import { normalizeUrl } from '../lib/util';
import {
  ICountryCode,
  IInstrument,
  ILanguage,
  IPair,
  ITrollboxRoom,
} from '../types/backend_definitions';
import { IWhitelabel } from '../types/constants';
import { IInstrumentPair, IInstruments, instrumentInfo, pairInfo } from '../types/instruments';

export type INodeEnv = 'development' | 'production';

// *********************************************************************************************************************

class EnvParser {
  constructor(private env: { [name: string]: string }, private isRequired: boolean = null) {
    // Add AOP-style checking for required
    const validateMethods: Array<keyof EnvParser> = [
      'string',
      'integer',
      'url',
      'enum',
      'boolean',
      'commaSeparatedList',
      'comaSeparatedDictionary',
    ];
    for (const method of validateMethods) {
      this[method] = ((parserMethod) => {
        return (key: string, defaultValue?: string): any => {
          const result = parserMethod.call(this, key, defaultValue as any);
          if (this.isRequired && !result && (result as any) !== 0) {
            throw new Error(`Required environmental value is not set: ${key}`);
          }
          return result;
        };
      })(this[method]) as any;
    }
  }

  /**
   * Switch the parser to required mode. The following parsed variable must be set, or we'll error out
   */
  required() {
    if (typeof this.isRequired === 'boolean') {
      // We already have something set, ignore. This is to allow disabling validation.
      return this;
    }
    return new EnvParser(this.env, true);
  }

  /**
   * Get string
   */
  string(key: string, defaultValue = ''): string {
    return this.env[key] || defaultValue;
  }

  /**
   * Get a normalized URL (without tailing slash)
   */
  url(key: string, defaultValue = ''): string {
    return normalizeUrl(this.string(key, defaultValue));
  }

  /**
   * Get an integer number
   */
  integer(key: string, defaultValue = ''): number {
    const raw = this.env[key] || defaultValue;

    if (raw) {
      const num = parseInt(raw, 10);
      if (num === Number(raw)) {
        // A legit number
        return num;
      }

      throw new Error(`Invalid integer value submitted for env ${key}: ${raw}`);
    }

    return null;
  }

  /**
   * The same as string, except cast into an enum (algebraic OR type)
   */
  enum<T>(key: string, defaultValue?: T): T {
    return (this.string(key, (defaultValue as any) || '') as any) || null;
  }

  /**
   * Parse 'true' or 'false' into boolean
   */
  boolean(key: string, defaultValue: 'true' | 'false' | ''): boolean {
    const raw = this.env[key] || defaultValue;
    if (raw) {
      switch (raw.toLowerCase()) {
        case 'true':
        case '1':
          return true;
        case 'false':
        case '0':
          return false;
        default:
          throw new Error(`Invalid boolean value provided for ${key}: "${raw}"`);
      }
    }
    return null;
  }

  /**
   * Parse a comma-separated value into an array of strings (or given types). Eg. a,b,c,d
   */
  commaSeparatedList<T = string>(key: string, defaultValue = ''): T[] {
    const raw = this.env[key] || defaultValue;
    if (!raw) {
      return [];
    }
    return raw.split(/\s*,\s*/) as any;
  }

  /**
   * Parse a dictionary by splicing by comma, then by colon (:). Eg. key1:value1, key2:value2
   */
  comaSeparatedDictionary<TKey extends string | symbol, TValue extends string | symbol>(
    key: string,
    defaultValue = ''
  ): { [key in TKey]: TValue } {
    const raw = this.env[key] || defaultValue;
    if (!raw) {
      return {} as any;
    }

    return raw.split(/\s*,\s*/).reduce((result, keyValuePair, index) => {
      const [key, value] = keyValuePair.split(/\s*:\s*/);
      if (!key || value === undefined) {
        throw new Error(`Invalid dictionary key:value pair fat entry #${index}: "${keyValuePair}"`);
      }
      result[key] = value;
      return result;
    }, {}) as any;
  }
}

// *********************************************************************************************************************

/**
 * Parse environment variables from process.env-like object. Defaults to process.env.
 */
export function loadCanonicalEnv(rawEnv, validate = false) {
  const parser = new EnvParser(rawEnv, validate ? null : false);

  const backendBaseUrl = parser.required().url('REACT_APP_API_SERVER');

  return {
    /** Global production / development switch. Could have effect throughout the codebase */
    nodeEnv: parser.enum<INodeEnv>('NODE_ENV', 'production'),

    /** URL where the app is hosted */
    publicUrl: parser.string('PUBLIC_URL', ''),

    /** Number injected during build. Can be used to uniquely identify this version of the codebase */
    buildNumber: parser.string('REACT_APP_BUILD_NUMBER', '___not_set___'),
    buildDate: parser.string('REACT_APP_BUILD_DATE', '___not_set___'),

    /** Main backend base URL */
    backendBaseUrl,

    /** URL to use for sockets. Defaults to the same URL as API_SERVER */
    socketBaseUrl: parser.required().url('REACT_APP_SOCKET_SERVER', backendBaseUrl),

    /** Key for recaptcha, must match the one on the backend */
    recaptchaSiteKey: parser.required().string('REACT_APP_RECAPTCHA_SITE_KEY'),

    logger: {
      /** At what level to log */
      generalLevel: parser.enum<LoggerLevel>('REACT_APP_LOGGER_LEVEL', 'silent'),

      /** You can provide a dictionary of Prefix:level, to further customize log levels */
      levelByPrefix: parser.comaSeparatedDictionary<string, LoggerLevel>(
        'REACT_APP_LOGGER_LEVEL_BY_PREFIX'
      ),
    },

    /** Used to send errors to sentry */
    sentryDSN: parser.string('REACT_APP_SENTRY_DSN'),

    whitelabel: {
      /** Name of the whitelabel. This maps to the main env which you use to choose the whitelabel */
      name: parser.enum<IWhitelabel>('REACT_APP_WHITELABEL'),

      /** This title will be displayed whenever we need to refer to the whitelabel name */
      exchangeTitle: parser.string('REACT_APP_EXCHANGE_TITLE'),
    },

    links: {
      /** Landing page, marketing site */
      landingPage: parser.url('REACT_APP_LANDING_PAGE_LINK', '#'),
      /** This site, external link */
      exchange: parser.url('REACT_APP_EXCHANGE_LINK', '#'),
      /** Main support page */
      support: parser.url('REACT_APP_SUPPORT_LINK', '#'),
      /** Main knowledge base page */
      knowledgeBase: parser.url('REACT_APP_KNOWLEDGE_BASE_LINK', '#'),
      /** Our exchange at CoinMarketCap */
      cmcPage: parser.url('REACT_APP_CMC_PAGE_LINK', '#'),
      /** API documentation homepage */
      apiDocs: parser.url('REACT_APP_API_DOCS_LINK', '#'),
    },

    /** Settings for RSD deposits */
    rsdDeposits: {
      accountNumber: parser.string('REACT_APP_RSD_DEPOSIT_ACCOUNT', '265-1610310004610-62'),
      businessAddress: parser.string('REACT_APP_RSD_DEPOSIT_ADDRESS', 'Berzex doo, Belgrade'),
      paymentPurpose: parser.string(
        'REACT_APP_RSD_DEPOSIT_PAYMENT_PURPOSE',
        'Uplata po predracunu %s'
      ),
      paymentCode: parser.string('REACT_APP_RSD_DEPOSIT_PAYMENT_CODE', '289'),
      paymentModel: parser.string('REACT_APP_RSD_DEPOSIT_PAYMENT_MODEL'),
      minRsdDeposit: parser.integer('REACT_APP_MIN_RSD_DEPOSIT', '1000'),
    },

    pairs: {
      defaultPair: parser.enum<IPair>('REACT_APP_DEFAULT_PAIR', 'SFX_BTC'),
      list: parser.commaSeparatedList<IPair>(
        'REACT_APP_ENABLED_PAIRS',
        'SFT_SFX,SFX_BTC,SFT_BTC,ETH_BTC,ETH_SFX,WSFX_SFX'
      ),
    },

    maxWithdrawalPerKYCLevel: {
      0: parser.integer('REACT_APP_MAX_WITHDRAWAL_KYC0', '0'),
      1: parser.integer('REACT_APP_MAX_WITHDRAWAL_KYC1', '7000'),
      2: parser.integer('REACT_APP_MAX_WITHDRAWAL_KYC2', '50000'),
      3: parser.integer('REACT_APP_MAX_WITHDRAWAL_KYC3', null),
    },

    trollbox: {
      enabledRooms: parser.commaSeparatedList<ITrollboxRoom>(
        'REACT_APP_ENABLED_TROLLBOX_ROOMS',
        'en'
      ),
      defaultRoom: parser.enum<ITrollboxRoom>('REACT_APP_DEFAULT_TROLLBOX_ROOM', 'en'),
    },
    i18n: {
      /** Lookup of supported languages towards their names. We don't use translations here because these things must be above translations */
      languageNames: parser.comaSeparatedDictionary<ILanguage, string>(
        'REACT_APP_SUPPORTED_LANGUAGES',
        'en_us:English'
      ),
      /** Initial selected language */
      defaultLanguage: parser.enum<ILanguage>('REACT_APP_DEFAULT_LANGUAGE', 'en_us'),
    },

    managed: {
      /** List of instruments to be allowed for managed */
      enabledCurrencies: parser.commaSeparatedList<IInstrument>(
        'REACT_APP_MANAGED_ENABLED_CURRENCIES',
        'EUR,CHF'
      ),
      countryWhitelist: parser.commaSeparatedList<ICountryCode>(
        'REACT_APP_MANAGED_COUNTRY_WHITELIST',
        'AT,BE,BG,HR,CY,CZ,DK,EE,FI,FR,DE,GR,HU,IE,IT,LV,LT,LU,MT,NL,PL,PT,RO,SK,SI,ES,SE,GB'
      ),
      recipientDetails: parser.string('REACT_APP_MANAGED_RECIPIENT_DETAILS'),
      bankName: parser.string('REACT_APP_MANAGED_BANK_NAME'),
      ibanEUR: parser.string('REACT_APP_MANAGED_IBAN_EUR'),
      ibanCHF: parser.string('REACT_APP_MANAGED_IBAN_CHF'),
      swift: parser.string('REACT_APP_MANAGED_SWIFT'),
    },

    kyc3Micropayment: {
      bankName: parser.string('REACT_APP_KYC3_BANK_NAME'),
      recipientDetails: parser.string('REACT_APP_KYC3_RECIPIENT_DETAILS'),
      ibanEUR: parser.string('REACT_APP_KYC3_IBAN_EUR'),
      ibanCHF: parser.string('REACT_APP_KYC3_IBAN_CHF'),
      swift: parser.string('REACT_APP_KYC3_SWIFT'),
      amount: parser.string('REACT_APP_KYC3_AMOUNT', '5'),
    },

    matomo: {
      /**
       * Main URL where matomo will listen
       */
      url: parser.url('REACT_APP_MATOMO_URL'),

      /**
       * Set to appropriate site id to enable matomo analytics
       */
      siteId: parser.string('REACT_APP_MATOMO_SITE_ID'),

      /**
       * Which domains should be covered by generated cookie. This should cover all the domains we want to track the same person through. Eg. *.xcalibra.com
       */
      cookieDomain: parser.string('REACT_APP_MATOMO_COOKIE_DOMAIN'),

      /**
       * Set array of hostnames or domains to be treated as local. All domains that serve this particular app should go here. Eg. trade.xcalibra.com
       */
      domains: parser.commaSeparatedList<string>('REACT_APP_MATOMO_DOMAINS'),
    },

    /**
     * Performance debugging
     * https://github.com/maicki/why-did-you-update
     */
    whyDidYouUpdate: parser.boolean('REACT_APP_WHY_DID_YOU_UPDATE', 'false'),

    /**
     * Override muting, so we can test alert indicators
     */
    accountAlertMuteDurationOverride: parser.integer(
      'REACT_APP_ACCOUNT_ALERT_MUTE_DURATION_OVERRIDE',
      ''
    ),
  };
}

type ICanonicalEnv = ReturnType<typeof loadCanonicalEnv>;

function deriveFinalEnv(envState: ICanonicalEnv) {
  const pairs = {
    ...envState.pairs,
    infos: {} as Partial<{ [key in IPair]: IInstrumentPair }>,
  };
  const instruments: {
    /** List of all supported instrument info objects */
    list: IInstrument[];
    /** Lookup of all supported instrument info objects */
    infos: Partial<IInstruments>;
  } = {
    list: [],
    infos: {},
  };

  for (const pair of envState.pairs.list) {
    const info = pairInfo(pair);
    pairs.infos[pair] = info;

    for (const side of ['base', 'quote']) {
      const symbol = info[side].symbol;
      if (!instruments.infos[symbol]) {
        instruments.infos[symbol] = instrumentInfo(symbol);
        instruments.list.push(symbol);
      }
    }
  }

  return {
    ...envState,

    i18n: {
      ...envState.i18n,
      languageList: Object.keys(envState.i18n.languageNames) as ILanguage[],
    },

    pairs,
    instruments,
  };
}

export type IEnv = ReturnType<typeof deriveFinalEnv>;
export interface IEnvState {
  env: IEnv;
}

export const loadAndValidateEnv = (env): IEnv => {
  return deriveFinalEnv(loadCanonicalEnv(env, true));
};

export const ENV_STATE: IEnvState = {
  env: deriveFinalEnv(loadCanonicalEnv({}, false)),
};

export const setEnvState = (env: IEnv) => {
  return commit('Set env state', {
    env: val(env),
  });
};
