import { commit, val } from '../../lib/redux_helpers';
import { IState, IStateGetter, IThunkMethod } from '../../lib/store';
import { IPair } from '../../types/backend_definitions';
import { updateTotalRecordsOfOrders } from './orders';

export interface IInstrumentOrdersSummedUp {
  pair: IPair;
  buy_count: number;
  sell_count: number;
}

export type IActiveInstrumentOrders = { [pair in IPair]?: IInstrumentOrdersSummedUp };

export interface IActiveInstrumentOrdersState {
  activeInstrumentsOrders: IActiveInstrumentOrders;
}

export const ACTIVE_COIN_ORDERS_STATE: IActiveInstrumentOrdersState = {
  activeInstrumentsOrders: {},
};

export const commitActiveOrdersForAllInstruments = (
  activeInstrumentsOrders: IActiveInstrumentOrders
) => {
  return commit('Set all active instrument orders', {
    activeInstrumentsOrders: val<IState['activeInstrumentsOrders']>(activeInstrumentsOrders),
  });
};

let activeInstrumentsOrdersThrottled = {};

export const updateOrdersForInstrumentPair = (dispatch, getState: IStateGetter, change: 1 | -1) => {
  const activeInstrumentsOrders: IActiveInstrumentOrders = {
    ...getState().activeInstrumentsOrders,
  };

  for (const key of Object.keys(activeInstrumentsOrdersThrottled)) {
    activeInstrumentsOrders[key].buy_count = !activeInstrumentsOrders[key].buy_count
      ? activeInstrumentsOrdersThrottled[key].buy_count || 0
      : (activeInstrumentsOrders[key].buy_count +=
          activeInstrumentsOrdersThrottled[key].buy_count || 0);

    activeInstrumentsOrders[key].sell_count = !activeInstrumentsOrders[key].sell_count
      ? activeInstrumentsOrdersThrottled[key].sell_count || 0
      : (activeInstrumentsOrders[key].sell_count +=
          activeInstrumentsOrdersThrottled[key].sell_count || 0);
  }
  activeInstrumentsOrdersThrottled = {};
  dispatch(updateTotalRecordsOfOrders(change));
  return dispatch(commitActiveOrdersForAllInstruments(activeInstrumentsOrders));
};

export const changeOrdersForInstrumentPair = (
  pair: IPair,
  buyOrSell: 'buy_count' | 'sell_count',
  change: 1 | -1
): IThunkMethod => (dispatch, getState) => {
  if (
    activeInstrumentsOrdersThrottled[pair] === undefined ||
    activeInstrumentsOrdersThrottled[pair][buyOrSell] === undefined
  ) {
    activeInstrumentsOrdersThrottled[pair] = { [buyOrSell]: change };
  } else {
    activeInstrumentsOrdersThrottled[pair][buyOrSell] += change;
  }

  return updateOrdersForInstrumentPair(dispatch, getState, change);
};

export const incrementBuyOrdersForInstrumentPair = (pair: IPair) =>
  changeOrdersForInstrumentPair(pair, 'buy_count', 1);
export const decrementBuyOrdersForInstrumentPair = (pair: IPair) =>
  changeOrdersForInstrumentPair(pair, 'buy_count', -1);
export const incrementSellOrdersForInstrumentPair = (pair: IPair) =>
  changeOrdersForInstrumentPair(pair, 'sell_count', 1);
export const decrementSellOrdersForInstrumentPair = (pair: IPair) =>
  changeOrdersForInstrumentPair(pair, 'sell_count', -1);
