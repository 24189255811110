import * as React from 'react';
import Dropzone from 'react-dropzone';

import styled from '../../lib/styled_components';

const $UploadZone = styled('div')<{
  image: string;
  preview: string;
  isDragActive: boolean;
  isDragAccept;
  hasError: boolean;
}>`
  width: 165px;
  height: 185px;
  position: relative;
  cursor: pointer;
  border-width: 2px;
  border-style: ${(p) => ((p.isDragActive || p.isDragAccept) && 'solid') || 'dashed'};
  border-color: ${(p) =>
    (p.isDragActive && p.theme.colors.info) ||
    (p.isDragAccept && p.theme.colors.brandSecondary) ||
    (p.hasError && p.theme.colors.error) ||
    p.theme.colors.baseDarker};
  background-color: transparent;
  background-image: url(${(p) => (p.preview ? p.preview : p.image)});
  background-size: ${(p) => (p.preview ? 'contain' : 'auto')};
  background-origin: padding-box;
  background-repeat: no-repeat;
  background-position: center;
`;

const $Label = styled('div')<{ isDragActive: boolean; isDragAccept: boolean }>`
  width: 100%;
  height: 30px;
  line-height: 30px;
  position: absolute;
  text-align: center;
  bottom: 0;
  color: white;
  background-color: ${(p) =>
    (p.isDragActive && p.theme.colors.info) ||
    (p.isDragAccept && p.theme.colors.brandSecondary) ||
    p.theme.colors.grayLight};
  text-transform: uppercase;
  font-weight: bold;
`;

interface IImageUploadBoxProps {
  image: string;
  label: string;
  onChange?: (any) => void;
  hasError?: boolean;
}

interface IImageUploadBoxState {
  file: File;
  preview: string;
}

export class ImageUploadBox extends React.PureComponent<
  IImageUploadBoxProps,
  IImageUploadBoxState
> {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      preview: null,
    };
  }

  onDrop = (files) => {
    const file = files[0];
    if (file) {
      this.setState({
        file,
        preview: URL.createObjectURL(file),
      });

      this.props.onChange(file);
    }
  };

  // Make sure to revoke the data uris to avoid memory leaks
  componentWillUnmount() {
    const { preview } = this.state;

    preview && setTimeout(() => URL.revokeObjectURL(preview), 300);
  }

  render() {
    const { image, label, hasError } = this.props;
    const { preview } = this.state;

    return (
      <Dropzone accept="image/jpeg, image/png" multiple={false} onDrop={this.onDrop}>
        {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
          // TODO: temporary fix for isDragAccept
          <$UploadZone
            {...{ image, preview, isDragActive, isDragAccept: !!acceptedFiles.length, hasError }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <$Label {...{ isDragActive, isDragAccept: !!acceptedFiles.length }}>
              <strong>{label}</strong>
            </$Label>
          </$UploadZone>
        )}
      </Dropzone>
    );
  }
}
