import Container from '../lib/container';
import { handleError } from '../lib/errors';
import { IApiPublicTrollboxMessage, ITrollboxRoom } from '../types/backend_definitions';

export const sendTrollboxMessage = (room: ITrollboxRoom, message: string) => (
  dispatch,
  _,
  { api }: Container
) => {
  return api.postTrollbox(room, { message }).catch((err) => dispatch(handleError(err)));
};

export const getTrollboxHistory = (room: ITrollboxRoom, limit: number) => (
  dispatch,
  _,
  { api }: Container
): Promise<IApiPublicTrollboxMessage[]> => {
  return api.getTrollbox({ room, limit }).catch((err) => dispatch(handleError(err)));
};
