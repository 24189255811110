import * as React from 'react';

import styled, { IThemeColorsUnion } from '../../lib/styled_components';
import { shadeColor } from '../../lib/util';
import { IOrderSide } from '../../types/backend_definitions';

export interface IButtonProps extends React.HTMLProps<HTMLButtonElement> {
  display?: 'inline-block' | 'block' | 'flex';
  // for: $BuySellButton
  side?: IOrderSide;

  // If true, button will not appear to be clickable
  inert?: boolean;

  color?: IThemeColorsUnion;
  hoverColor?: IThemeColorsUnion;
  backgroundColor?: IThemeColorsUnion;
  hoverBackground?: IThemeColorsUnion;
  border?: string;
  borderRadius?: string;
  fontSize?: string;
  padding?: string;
  textTransform?: string;
  withoutFocusOutline?: boolean;
}

export const $Button = styled('button')<IButtonProps>`
  color: ${(p) => (p.color ? p.color : p.theme.widgets.button.color)};
  background-color: ${(p) =>
    p.backgroundColor ? p.backgroundColor : p.theme.widgets.button.background};
  border: ${(p) => (p.border ? p.border : p.theme.widgets.button.border)};
  border-radius: ${(p) => (p.borderRadius ? p.borderRadius : p.theme.widgets.button.borderRadius)};
  font-size: ${(p) => (p.fontSize ? p.fontSize : p.theme.widgets.button.fontSize)};
  @media screen and ${(p) => p.theme.device.laptop} {
    font-size: ${(p) => p.theme.base.fontSizeLaptop};
  }
  @media screen and ${(p) => p.theme.device.mobile} {
    font-size: ${(p) => p.theme.base.fontSizeMobile};
  }
  text-transform: ${(p) =>
    p.textTransform ? p.textTransform : p.theme.widgets.button.textTransform};

  display: ${(p) => p.display || 'inline-block'};
  ${(p) => p.display === 'block' && 'width: 100%;'}

  // Used if display is "flex"
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  line-height: 1.5;
  letter-spacing: 0.4px;
  padding: ${(p) => (p.padding ? p.padding : p.theme.widgets.button.padding)};

  cursor: ${(p) => (p.inert ? 'default' : 'pointer')};

  &:hover {
    ${(p) =>
      p.inert
        ? ''
        : `
      background: ${
        p.hoverBackground
          ? p.hoverBackground
          : p.backgroundColor
          ? shadeColor(p.backgroundColor, -0.25)
          : shadeColor(p.theme.widgets.button.background, -0.25)
      }
      color: ${p.hoverColor || p.color || p.theme.widgets.button.color}
    `};
  }

  ${(p) => p.withoutFocusOutline && `&:focus { outline: none !important; }`}

  &:disabled {
    background-color: ${(p) => p.theme.colors.disabled} !important;
    color: ${(p) => p.theme.colors.white};
    cursor: not-allowed;
  }
`;

export const $SmallButton = styled($Button)`
  font-size: ${(p) => (p.fontSize ? p.fontSize : p.theme.widgets.button.small.fontSize)};
  border-radius: ${(p) =>
    p.borderRadius ? p.borderRadius : p.theme.widgets.button.small.borderRadius};
  line-height: 1;
  padding: ${(p) => (p.padding ? p.padding : p.theme.widgets.button.small.padding)};
`;

export const $SuccessButton = styled($Button).attrs((p) => ({
  backgroundColor: p.theme.colors.buy,
}))``;
export const $InfoButton = styled($Button).attrs((p) => ({
  backgroundColor: p.theme.colors.info,
}))``;
export const $ErrorButton = styled($Button).attrs((p) => ({
  backgroundColor: p.theme.colors.sell,
}))``;
export const $RedLightButton = styled($Button).attrs((p) => ({
  backgroundColor: p.theme.colors.sell,
}))``;
export const $RedDarkButton = styled($Button).attrs((p) => ({
  backgroundColor: p.theme.colors.redDark,
}))``;
export const $GreenLightButton = styled($Button).attrs((p) => ({
  backgroundColor: p.theme.colors.buy,
}))``;
export const $GreenDarkButton = styled($Button).attrs((p) => ({
  backgroundColor: p.theme.colors.greenDark,
}))``;
export const $TransparentButton = styled($Button).attrs((p) => ({
  backgroundColor: p.theme.colors.transparent,
  color: p.theme.colors.white,
  hoverBackground: p.theme.colors.brandPrimary,
}))``;

export const $SuccessSmallButton = styled($SmallButton).attrs((p) => ({
  backgroundColor: p.theme.colors.buy,
}))``;
export const $InfoSmallButton = styled($SmallButton).attrs((p) => ({
  backgroundColor: p.theme.colors.info,
}))``;
export const $ErrorSmallButton = styled($SmallButton).attrs((p) => ({
  backgroundColor: p.theme.colors.redLight,
}))``;
export const $RedLightSmallButton = styled($SmallButton).attrs((p) => ({
  backgroundColor: p.theme.colors.sell,
}))``;
export const $RedDarkSmallButton = styled($SmallButton).attrs((p) => ({
  backgroundColor: p.theme.colors.redDark,
}))``;
export const $GreenLightSmallButton = styled($SmallButton).attrs((p) => ({
  backgroundColor: p.theme.colors.buy,
}))``;
export const $GreenDarkSmallButton = styled($SmallButton).attrs((p) => ({
  backgroundColor: p.theme.colors.greenDark,
}))``;
export const $TransparentSmallButton = styled($SmallButton).attrs((p) => ({
  backgroundColor: p.theme.colors.transparent,
  color: p.theme.colors.white,
  hoverBackground: p.theme.colors.brandPrimary,
}))``;
