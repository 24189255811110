import Container from '../../lib/container';
import { handleError } from '../../lib/errors';
import { commit, val } from '../../lib/redux_helpers';
import { IThunkMethod } from '../../lib/store';
import {
  IApiCustomerSourceOfFundsPayload,
  IApiPublicCustomerInfo,
  IApiRequestKYC1Payload,
  IKYCLevel2Method,
} from '../../types/backend_definitions';
import { getSimpleNotificationData, notifyError, notifySuccess } from '../app';
import { loadingWrapper } from '../request_active';

export const setCustomer = (customer: IApiPublicCustomerInfo) =>
  commit('Customer info', {
    customer: val(customer),
  });

export const loadCustomer = () => (dispatch, _, { api }: Container) => {
  return api
    .getCustomers()
    .then((customer) => dispatch(setCustomer(customer)))
    .catch((err) => dispatch(handleError(err)));
};

export const requestKycLevel1Base = (body: IApiRequestKYC1Payload) => (
  dispatch,
  _,
  { api, matomo }: Container
): Promise<string> => {
  matomo.sendEvent('Onboarding', 'request_kyc1');
  return dispatch(
    loadingWrapper(
      'putKycLevel1',
      api
        .putKycLevel1Base(body)
        .then((result) => {
          dispatch(setCustomer(result.customer));

          return result.redirect_url;
        })
        .catch((err) => dispatch(handleError(err)))
    )
  );
};

export const requestKycLevel1VQF = () => (
  dispatch,
  _,
  { api, matomo }: Container
): Promise<string> => {
  return dispatch(
    loadingWrapper(
      'putKycLevel1',
      api
        .putKycLevel1Vqf()
        .then((result) => {
          dispatch(setCustomer(result.customer));

          return result.redirect_url;
        })
        .catch((err) => dispatch(handleError(err)))
    )
  );
};

export const handleVerificationSubmitted = (verificationId) => (
  dispatch,
  _,
  { api }: Container
) => {
  dispatch(
    commit('Set customer verification waiting flag', {
      customer: {
        kyc1_verification_waiting: val(true),
      },
    })
  );
  return api.putKycLevel1Submitted(verificationId).catch((err) => dispatch(handleError(err)));
};

export const requestKycLevel2 = (method: IKYCLevel2Method, image: File) => (
  dispatch,
  _,
  { api, matomo }: Container
) => {
  matomo.sendEvent('Onboarding', 'request_kyc2');

  return dispatch(
    loadingWrapper(
      'putKycLevel2',
      Promise.resolve()
        .then(() => {
          const formData = new FormData();
          formData.append('image1', image);
          return api.postImage(formData as any).then((images) => images[0].id);
        })
        .then((image_id) => {
          return api
            .putKycLevel2({
              image_id,
            })
            .then((customer) => {
              dispatch(setCustomer(customer));
            })
            .catch((err) => dispatch(handleError(err)));
        })
    )
  );
};

export const notifyResolvedKYCRequest = (level: number, granted: boolean): IThunkMethod => (
  dispatch,
  getState,
  { i18n }
) => {
  const message = i18n.t([
    level === 1
      ? granted
        ? 'settings:kyc.toasts.kyc1Granted'
        : 'settings:kyc.toasts.kyc1Refused'
      : level === 2
      ? granted
        ? 'settings:kyc.toasts.kyc2Granted'
        : 'settings:kyc.toasts.kyc2Refused'
      : level === 3
      ? granted
        ? 'settings:kyc.toasts.kyc3Granted'
        : 'settings:kyc.toasts.kyc3Refused'
      : 'settings:kyc.toasts.kycCompleted',
  ]);
  const data = getSimpleNotificationData(message);
  dispatch(granted ? notifySuccess(data) : notifyError(data));
};

export const submitSourceOfFunds = (payload: IApiCustomerSourceOfFundsPayload): IThunkMethod => (
  dispatch,
  getState,
  { api }
) => {
  return dispatch(
    loadingWrapper(
      'submitSourceOfFunds',
      api
        .putCustomersSof(payload)
        .then((result) => {
          dispatch(setCustomer(result));
        })
        .catch((err) => dispatch(handleError(err)))
    )
  );
};
