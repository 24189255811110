import React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import {
  changeEmailOrThrow,
  changePasswordOrThrow,
  clearNicknameChangeRequest,
  updateLanguageOrThrow,
  updateNicknameOrThrow,
} from '../../../actions/settings';
import { ITranslatedError, createFrontendValidationError } from '../../../lib/errors';
import { I18n, I18nService } from '../../../lib/i18n';
import { inject } from '../../../lib/react_container';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import keepSessionAliveIcon from '../../../media/keep-session-alive.png';
import { AccountIcon } from '../../../media/svg_icons';
import { getUser, isNicknamePending } from '../../../selectors/auth';
import { ILanguage } from '../../../types/backend_definitions';
import { II18nextT } from '../../../types/i18n';
import { HideMobile } from '../../utility_components';
import { $CodeInputDark } from '../../widgets/CodeInput';
import Header from '../../widgets/Header';
import { $InputDark } from '../../widgets/Input';
import { $HeaderWrapper } from '../../widgets/PageHeaderWrappers';
import { SimpleSelect } from '../../widgets/Select';
import { $SettingsWrapper, SettingsSectionCollapsible } from './settings_components';

const $AccountWrapper = styled($SettingsWrapper)`
  max-width: 600px;
`;

const $InputWrapper = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 1em 0;
  & > label {
    padding-right: 10px;
    width: 30%;
  }
  & > input {
    width: 40%;
  }
  & > div {
    width: 70%;
  }
`;

const $NicknameRequested = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0;
`;

const $Dismiss = styled('span')<{ onClick: Function }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-left: 15px;
  cursor: pointer;
  color: ${(p) => p.theme.colors.warning};
`;

interface ILanguageSelectOption {
  value: ILanguage;
  label: string;
}
interface IAccountSettingsProps extends ConnectedProps<typeof connector> {
  i18n: I18nService;
}

interface IAccountSettingsState {
  emailChangeInitiated: boolean;
  passwordChangeInitiated: boolean;
  nicknameChangeInitiated: boolean;
  languageChangeInitiated: boolean;
  language: ILanguageSelectOption;
  nickname: string;
  email: string;
  emailPassword: string;
  emailTFA: string;
  password: string;
  existing_password: string;
  passwordTFA: string;
  passwordRepeat: string;
  // errors
  emailServerError: ITranslatedError;
  languageServerError: ITranslatedError;
  passwordServerError: ITranslatedError;
  nicknameServerError: ITranslatedError;
}

class AccountSettings extends React.PureComponent<IAccountSettingsProps, IAccountSettingsState> {
  private mounted: boolean = false;
  static contextType: any = I18nContext;
  private languageOptions: ILanguageSelectOption[] = [];

  constructor(props: IAccountSettingsProps) {
    super(props);

    this.languageOptions = Object.keys(props.languageNames).map((language) => ({
      value: language as ILanguage,
      label: props.languageNames[language],
    }));

    this.state = {
      emailChangeInitiated: false,
      passwordChangeInitiated: false,
      nicknameChangeInitiated: false,
      languageChangeInitiated: false,
      language: props.user
        ? this.getLanguageOptionObject(props.user.language)
        : this.getLanguageOptionObject(props.defaultLanguage),
      languageServerError: null,
      email: '',
      emailPassword: '',
      emailTFA: '',
      emailServerError: null,
      password: '',
      existing_password: '',
      passwordTFA: '',
      passwordRepeat: '',
      passwordServerError: null,
      nickname: '',
      nicknameServerError: null,
    };
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value } as any);

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  static getDerivedStateFromProps(props, state) {
    return props.nicknamePending ? { ...state, nicknameChangeInitiated: true } : state;
  }

  getLanguageOptionObject = (language: ILanguage) =>
    this.languageOptions.find((lng) => language === lng.value);

  changePassword = () => {
    const { password, passwordRepeat, existing_password, passwordTFA } = this.state;
    const { t }: II18nextT = this.context;

    if (password === '' || passwordRepeat === '' || password !== passwordRepeat) {
      this.setState({
        passwordServerError: createFrontendValidationError(
          t('validation.messages.passwordMismatch')
        ),
      });
      return false;
    }

    return this.props
      .changePasswordOrThrow(password, existing_password, passwordTFA)
      .then(() => {
        this.mounted &&
          this.setState({ passwordChangeInitiated: false, passwordServerError: null });
      })
      .catch((errorResponse) => {
        this.mounted &&
          this.setState({
            passwordServerError: errorResponse,
          });
      });
  };

  changeEmail = () => {
    return this.props
      .changeEmailOrThrow(this.state.email, this.state.emailPassword, this.state.emailTFA)
      .then(() => {
        this.mounted && this.setState({ emailChangeInitiated: false, emailServerError: null });
      })
      .catch((errorResponse) => {
        this.mounted &&
          this.setState({
            emailServerError: errorResponse,
          });
      });
  };

  changeLanguage = () => {
    const { value: language } = this.state.language;

    // change locally
    this.props.i18n.changeLanguage(language);

    // update profile with new language
    this.props
      .updateLanguageOrThrow(language)
      .then(() => {
        this.mounted &&
          this.setState({ languageChangeInitiated: false, languageServerError: null });
      })
      .catch(
        (errorResponse: ITranslatedError) =>
          this.mounted &&
          this.setState({
            languageServerError: errorResponse,
          })
      );
  };

  changeNickname = () => {
    const { t }: II18nextT = this.context;
    const min = 3;
    const max = 15;
    const length = this.state.nickname.length;
    if (length < min || length > max) {
      this.setState({
        nicknameServerError: createFrontendValidationError(
          t('validation.messages.nicknameAllowedLength', { min, max })
        ),
      });
      return false;
    }
    // update profile with new nickname
    return this.props
      .updateNicknameOrThrow(this.state.nickname)
      .then(() => {
        this.mounted &&
          this.setState({ nicknameChangeInitiated: false, nicknameServerError: null });
      })
      .catch(
        (errorResponse) =>
          this.mounted &&
          this.setState({
            nicknameServerError: errorResponse,
          })
      );
  };

  clearNicknameChange = () => {
    // remove the nickname declined reason notification forever
    this.props.clearNicknameChangeRequest().catch(
      (errorResponse) =>
        this.mounted &&
        this.setState({
          nicknameServerError: errorResponse,
        })
    );
  };

  cancelNicknameChange = () => {
    // cancel the request to change the nickname
    this.props.clearNicknameChangeRequest(true).catch(
      (errorResponse) =>
        this.mounted &&
        this.setState({
          nicknameServerError: errorResponse,
        })
    );
  };

  onCancelNickname = () => {
    if (this.props.nicknamePending) {
      return this.cancelNicknameChange();
    }

    this.setState({ nicknameChangeInitiated: false });
  };

  renderNicknameInput = (t) => {
    if (this.props.nicknamePending) {
      return (
        <$NicknameRequested>
          {t('settings:account.trollboxNicknameRequestedMessage', {
            nickname: this.props.user.requested_nickname,
          })}
        </$NicknameRequested>
      );
    }

    return (
      <$InputWrapper>
        <label>{t('settings:account.newNickname')}</label>
        <$InputDark
          background="white"
          value={this.state.nickname}
          name="nickname"
          onChange={this.onChange}
          onKeyPress={(e) => e.key === 'Enter' && this.changeNickname()}
          required
          autoFocus
        />
      </$InputWrapper>
    );
  };

  renderNicknameDeclineMessage = (t) => {
    const { user } = this.props;

    if (!user || !user.nickname_decline_reason) {
      return null;
    }

    return (
      <>
        <p>
          {t('settings:account.trollboxNicknameDeclinedMessage', {
            nickname: user.requested_nickname,
            reason: user.nickname_decline_reason,
          })}
        </p>
        <$Dismiss onClick={() => this.clearNicknameChange()}>X</$Dismiss>
      </>
    );
  };

  render() {
    const { user } = this.props;
    const TFAEnabled = Boolean(user && user.tfa_enabled_at);

    return (
      <I18n>
        {(t) => (
          <>
            <HideMobile>
              <$HeaderWrapper>
                <Header
                  subtitle={t('user')}
                  title={t('settings:sections.account')}
                  icon={AccountIcon}
                  variant="dark"
                />
              </$HeaderWrapper>
            </HideMobile>

            <$AccountWrapper>
              <SettingsSectionCollapsible
                label={t('settings:account.email')}
                value={user ? user.email : ''}
                icon={keepSessionAliveIcon}
                description={t('settings:account.emailDescription')}
                changeInitiated={this.state.emailChangeInitiated}
                onChangeClick={() => this.setState({ emailChangeInitiated: true })}
                onCancelClick={() => this.setState({ emailChangeInitiated: false })}
                onSaveClick={this.changeEmail}
                requests={['changeEmail']}
                validationError={this.state.emailServerError}
              >
                <$InputWrapper>
                  <label>{t('settings:account.newEmail')}</label>
                  <$InputDark
                    background="white"
                    value={this.state.email}
                    name="email"
                    onChange={this.onChange}
                    onKeyPress={(e) => e.key === 'Enter' && this.changeEmail()}
                    required
                    autoFocus
                    readOnly
                    onFocus={(event: React.FocusEvent<HTMLInputElement>) =>
                      event.target.removeAttribute('readonly')
                    }
                    onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                      event.target.setAttribute('readonly', 'true')
                    }
                  />
                </$InputWrapper>
                <$InputWrapper>
                  <label>{t('settings:account.password')}</label>
                  <$InputDark
                    type="password"
                    background="white"
                    value={this.state.emailPassword}
                    name="emailPassword"
                    onChange={this.onChange}
                    onKeyPress={(e) => e.key === 'Enter' && this.changeEmail()}
                    required
                    readOnly
                    onFocus={(event: React.FocusEvent<HTMLInputElement>) =>
                      event.target.removeAttribute('readonly')
                    }
                    onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                      event.target.setAttribute('readonly', 'true')
                    }
                  />
                </$InputWrapper>
                {TFAEnabled && (
                  <$InputWrapper>
                    <label>{t('tfa')}</label>
                    <$CodeInputDark
                      value={this.state.emailTFA}
                      name="emailTFA"
                      onChange={this.onChange}
                      onKeyPress={(e) => e.key === 'Enter' && this.changeEmail()}
                    />
                  </$InputWrapper>
                )}
              </SettingsSectionCollapsible>

              <SettingsSectionCollapsible
                label={t('settings:account.password')}
                value="•••••••••••"
                icon={keepSessionAliveIcon}
                description={t('settings:account.passwordDescription')}
                changeInitiated={this.state.passwordChangeInitiated}
                onChangeClick={() => this.setState({ passwordChangeInitiated: true })}
                onCancelClick={() => this.setState({ passwordChangeInitiated: false })}
                onSaveClick={this.changePassword}
                requests={['changePassword']}
                validationError={this.state.passwordServerError}
              >
                <$InputWrapper>
                  <label>{t('settings:account.currentPassword')}</label>
                  <$InputDark
                    type="password"
                    background="white"
                    value={this.state.existing_password}
                    name="existing_password"
                    onChange={this.onChange}
                    onKeyPress={(e) => e.key === 'Enter' && this.changePassword()}
                    autoFocus
                  />
                </$InputWrapper>
                <$InputWrapper>
                  <label>{t('settings:account.newPassword')}</label>
                  <$InputDark
                    type="password"
                    background="white"
                    value={this.state.password}
                    name="password"
                    onChange={this.onChange}
                  />
                </$InputWrapper>
                {/* TODO: validate if passwords are same */}
                <$InputWrapper>
                  <label>{t('settings:account.confirmNewPassword')}</label>
                  <$InputDark
                    type="password"
                    background="white"
                    value={this.state.passwordRepeat}
                    name="passwordRepeat"
                    onChange={this.onChange}
                    onKeyPress={(e) => e.key === 'Enter' && this.changePassword()}
                  />
                </$InputWrapper>
                {TFAEnabled && (
                  <$InputWrapper>
                    <label>{t('tfa')}</label>
                    <$CodeInputDark
                      value={this.state.passwordTFA}
                      name="passwordTFA"
                      onChange={this.onChange}
                      onKeyPress={(e) => e.key === 'Enter' && this.changePassword()}
                    />
                  </$InputWrapper>
                )}
              </SettingsSectionCollapsible>

              <SettingsSectionCollapsible
                label={t('settings:account.trollboxNickname')}
                value={user ? user.nickname : ''}
                icon={keepSessionAliveIcon}
                description={t('settings:account.trollboxNicknameDescription')}
                changeInitiated={this.state.nicknameChangeInitiated}
                onChangeClick={() => this.setState({ nicknameChangeInitiated: true })}
                onCancelClick={() => this.onCancelNickname()}
                onSaveClick={this.props.nicknamePending ? null : this.changeNickname}
                requests={['nickname']}
                validationError={this.state.nicknameServerError}
                dismissableError={this.renderNicknameDeclineMessage(t)}
              >
                {this.renderNicknameInput(t)}
              </SettingsSectionCollapsible>

              {this.languageOptions.length > 1 && (
                <SettingsSectionCollapsible
                  label={t('settings:account.interfaceLanguage')}
                  value={this.state.language ? this.state.language.label : ''}
                  icon={keepSessionAliveIcon}
                  description={t('settings:account.interfaceLanguageDescription')}
                  changeInitiated={this.state.languageChangeInitiated}
                  onChangeClick={() => this.setState({ languageChangeInitiated: true })}
                  onCancelClick={() => this.setState({ languageChangeInitiated: false })}
                  onSaveClick={this.changeLanguage}
                  requests={['language']}
                  validationError={this.state.languageServerError}
                >
                  <$InputWrapper>
                    <SimpleSelect
                      width="auto"
                      variant="dark"
                      value={this.state.language as any}
                      onChange={(language: ILanguageSelectOption) => this.setState({ language })}
                      options={this.languageOptions}
                    />
                  </$InputWrapper>
                </SettingsSectionCollapsible>
              )}
            </$AccountWrapper>
          </>
        )}
      </I18n>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    user: getUser(state),
    nicknamePending: isNicknamePending(state),
    languageNames: state.env.i18n.languageNames,
    defaultLanguage: state.env.i18n.defaultLanguage,
  }),
  {
    changePasswordOrThrow,
    changeEmailOrThrow,
    updateLanguageOrThrow,
    updateNicknameOrThrow,
    clearNicknameChangeRequest,
  }
);

export default connector(inject('i18n')(AccountSettings));
