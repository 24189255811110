import { handleError, rethrowTranslated } from '../../lib/errors';
import {
  commit,
  getNextPageCriteria,
  mergePaginatedResults,
  updatePaginatedResult,
  val,
} from '../../lib/redux_helpers';
import { IState, IThunkMethod } from '../../lib/store';
import { getWithdrawalsForInstrument } from '../../selectors/transactions';
import { IPaginated } from '../../types/api';
import {
  IApiCensoredWithdrawal,
  IApiWithdrawalsCriteria,
  IInstrument,
} from '../../types/backend_definitions';
import {
  IApiWithdrawalRequestPayload,
  IApiWithdrawalWindowReport,
} from '../../types/backend_definitions';
import { IWithdrawal } from '../../types/transactions';
import { getSimpleNotificationData, notifyError, notifySuccess } from '../app';
import { loadBalances } from '../balances';
import { loadingWrapper } from '../request_active';
import { TRANSACTIONS_STATE } from './transactions';

const DEFAULT_PAGE_SIZE = 20;

export const setWithdrawalsStateForInstrument = (
  message: string,
  withdrawals: IPaginated<IWithdrawal>,
  instrument: IInstrument
) => {
  return commit(message, {
    transactions: {
      withdrawals: {
        [instrument]: val<IPaginated<IWithdrawal>>(withdrawals),
      },
    },
  });
};

export const setWithdrawalWindowReportStateForInstrument = (
  message: string,
  instrument: IInstrument,
  report: IApiWithdrawalWindowReport
) =>
  commit(message, {
    transactions: {
      withdrawalReport: {
        [instrument]: val<IApiWithdrawalWindowReport>(report),
      },
    },
  });

export const loadWithdrawalWindowReportForInstrument = (instrument): IThunkMethod => (
  dispatch,
  _,
  { api }
) => {
  return api
    .getWithdrawalsWindow(instrument)
    .then((report) => {
      dispatch(
        setWithdrawalWindowReportStateForInstrument(
          'Update user withdrawal report for instrument',
          report.instrument as IInstrument,
          report
        )
      );
    })
    .catch((err) => dispatch(handleError(err)));
};

export const resetUserWithdrawals = () => (dispatch) => {
  dispatch(
    commit(`Reset user withdrawals state`, {
      transactions: {
        withdrawals: val<IState['transactions']['withdrawals']>(
          TRANSACTIONS_STATE.transactions.withdrawals
        ),
      },
    })
  );
};

export const loadWithdrawalsForInstrument = (
  instrument,
  firstPage = false
): IThunkMethod<Promise<void>> => (dispatch, getState, { api }) => {
  const oldWithdrawals = getWithdrawalsForInstrument(getState(), instrument);
  const nextPageCriteria = getNextPageCriteria(oldWithdrawals, DEFAULT_PAGE_SIZE, firstPage);
  return dispatch(
    loadingWrapper(
      'loadWithdrawalsForInstrument',
      api
        .getWithdrawals({
          // NOTE: If instrument !== 'all' we are loading data for specific instrument, otherwise we load all data,
          instruments: [instrument !== 'all' ? instrument : ''],
          sort_direction: 'desc',
          sort_field: ['created_at', 'id'],
          ...nextPageCriteria,
        } as IApiWithdrawalsCriteria)
        .then((newWithdrawals) => {
          const mergedWithdrawals = mergePaginatedResults<IWithdrawal, IWithdrawal>(
            newWithdrawals,
            oldWithdrawals,
            'id'
          );

          dispatch(
            setWithdrawalsStateForInstrument(
              'Update user withdrawals',
              mergedWithdrawals,
              instrument
            )
          );
        })
        .catch((err) => dispatch(handleError(err)))
    )
  );
};

const removeWithdrawal = (withdrawal: IWithdrawal): IThunkMethod => (dispatch, getState) => {
  const ALL = 'all' as IInstrument;
  const instruments: IInstrument[] = [withdrawal.instrument, ALL];

  instruments.forEach((instrument) => {
    const oldWithdrawalsState = getWithdrawalsForInstrument(getState(), instrument);

    if (oldWithdrawalsState.items.findIndex((wd) => wd.id === withdrawal.id) === -1) {
      return;
    }

    const newWithdawalState = {
      ...oldWithdrawalsState,
      items: oldWithdrawalsState.items.filter((w) => w.id !== withdrawal.id),
      total_records: oldWithdrawalsState.total_records - 1,
    };
    const message = `Removed withdrawal with id ${withdrawal.id} in withdrawals[${instrument}] `;
    dispatch(setWithdrawalsStateForInstrument(message, newWithdawalState, instrument));
  });
};

export const cancelWithdrawal = (withdrawalId: number): IThunkMethod => (
  dispatch,
  _,
  { api, i18n, matomo }
) => {
  return dispatch(
    loadingWrapper(
      'cancelWithdrawal',
      api
        .deleteWithdrawal(withdrawalId)
        .then((cancelledWithdrawal) => {
          dispatch(removeWithdrawal(cancelledWithdrawal));
          dispatch(
            notifySuccess(
              getSimpleNotificationData(i18n.t('transactions:withdrawals.withdrawHasBeenCanceled'))
            )
          );
          matomo.sendEvent(
            'Transfers',
            'cancel_withdrawal',
            cancelledWithdrawal.instrument,
            Number(cancelledWithdrawal.quantity)
          );
        })
        .catch((err) => dispatch(handleError(err)))
    )
  );
};

export const submitWithdrawal = (
  withdrawal: IApiWithdrawalRequestPayload
): IThunkMethod<Promise<void>> => (dispatch, getState, { api, i18n, matomo }) => {
  matomo.sendEvent(
    'Transfers',
    'request_withdrawal',
    withdrawal.instrument,
    Number(withdrawal.quantity)
  );

  return api
    .postWithdrawal(withdrawal)
    .then((withdrawal) => {
      const withdrawals = {
        ...getWithdrawalsForInstrument(getState(), withdrawal.instrument as IInstrument),
      } as IPaginated<IWithdrawal>;
      withdrawals.items = [withdrawal, ...withdrawals.items];
      withdrawals.total_records += 1;

      if (withdrawal.confirmed_at) {
        dispatch(
          notifySuccess(
            getSimpleNotificationData(i18n.t('transactions:withdrawals.newWithdrawalScheduled'))
          )
        );
      } else {
        dispatch(
          notifySuccess(
            getSimpleNotificationData(
              i18n.t('transactions:withdrawals.newWithdrawalWaitingForConfirmation')
            )
          )
        );
      }

      dispatch(
        setWithdrawalsStateForInstrument(
          'Update user withdrawals',
          withdrawals,
          withdrawal.instrument as IInstrument
        )
      );
    })
    .catch((err) => {
      dispatch(handleError(err));
    });
};

export const confirmWithdrawal = (token: string): IThunkMethod<Promise<IApiCensoredWithdrawal>> => (
  dispatch,
  _,
  { api, matomo }
) => {
  return api
    .putWithdrawalsConfirm(token)
    .then((withdrawal) => {
      matomo.sendEvent(
        'Transfers',
        'confirm_withdrawal',
        withdrawal.instrument,
        Number(withdrawal.quantity)
      );

      return withdrawal;
    })
    .catch((err) => dispatch(rethrowTranslated(err)));
};

const updateWithdrawal = (withdrawal: IWithdrawal): IThunkMethod => (dispatch, getState) => {
  const oldWithdrawals = getWithdrawalsForInstrument(
    getState(),
    withdrawal.instrument as IInstrument
  );

  const [updatedWithdrawals, isNewRecord] = updatePaginatedResult<IWithdrawal>(
    oldWithdrawals,
    withdrawal,
    'id'
  );

  if (isNewRecord) {
    // We are not expecting this to be a new record. Something is wrong, reload
    return dispatch(loadWithdrawalsForInstrument(withdrawal.instrument, true));
  }

  const withdrawalState = withdrawal.completed_at
    ? 'completed'
    : withdrawal.confirmed_at
    ? 'confirmed'
    : '';

  // update store
  return dispatch(
    setWithdrawalsStateForInstrument(
      `Update ${withdrawalState} withdrawal ${withdrawal.id}. Failure code: ${withdrawal.failure_code}`,
      updatedWithdrawals,
      withdrawal.instrument as IInstrument
    )
  );
};

export const updateConfirmedWithdrawal = (withdrawal: IWithdrawal): IThunkMethod => (
  dispatch,
  _,
  { i18n }
) => {
  dispatch(updateWithdrawal(withdrawal));

  // send toast notification
  if (withdrawal.failure_code) {
    dispatch(
      notifyError(
        getSimpleNotificationData(
          i18n.t('transactions:withdrawals.notifications.withdrawalFailed', withdrawal)
        )
      )
    );
  } else {
    dispatch(
      notifySuccess(
        getSimpleNotificationData(
          i18n.t(`transactions:withdrawals.notifications.withdrawalConfirmed`, withdrawal)
        )
      )
    );
  }
};

export const updateCompletedWithdrawal = (withdrawal: IWithdrawal): IThunkMethod => (
  dispatch,
  _,
  { i18n }
) => {
  dispatch(updateWithdrawal(withdrawal));

  // send toast notification
  if (withdrawal.failure_code) {
    dispatch(
      notifyError(
        getSimpleNotificationData(
          i18n.t('transactions:withdrawals.notifications.withdrawalFailed', withdrawal)
        )
      )
    );
  } else {
    dispatch(
      notifySuccess(
        getSimpleNotificationData(
          i18n.t('transactions:withdrawals.notifications.withdrawalCompleted', withdrawal)
        )
      )
    );
  }

  // update balances, the wallet might have changed
  dispatch(loadBalances());
};
