import { commit, val } from '../lib/redux_helpers';
import { IOrderSide } from '../types/backend_definitions';

export interface IBuySellWidgetStateValues {
  quantity?: number | '';
  price?: number | '';
  total?: number | '';
}

export type IBuySellWidgetState = { [side in IOrderSide]?: IBuySellWidgetStateValues };

export interface IComponentsState {
  buySellWidget: IBuySellWidgetState;
}

export const COMPONENTS_STATE: IComponentsState = {
  buySellWidget: {
    buy: {
      quantity: '',
      price: '',
      total: '',
    },
    sell: {
      quantity: '',
      price: '',
      total: '',
    },
  },
};

export const setBuySellValues = (payload: IBuySellWidgetState) => {
  return commit(`Set buy/sell widget values`, {
    buySellWidget: {
      buy: payload.buy ? val(payload.buy) : undefined,
      sell: payload.sell ? val(payload.sell) : undefined,
    },
  });
};
