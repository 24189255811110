import * as React from 'react';
import { I18nContext } from 'react-i18next';

import styled from '../../lib/styled_components';
import { TodoList1Icon } from '../../media/svg_icons';
import { ICurrency } from '../../types/backend_definitions';
import { II18nextT } from '../../types/i18n';
import { ITranslations } from '../../types/translations';
import { $Input } from '../widgets/Input';
import $Label from '../widgets/Label';
import Modal from '../widgets/Modal';
import GenericTooltip from '../widgets/tooltips/GenericTooltip';

const $ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

// NOTE: A lot of this is based on Managed.tsx.
// TODO: Reorganize components into one style across Managed, Settings, BuyWithCard and Login/Register components

const $ModalContent = styled.div`
  width: 40rem;
  margin: 0 auto;
  padding: 1rem;
`;

export const $FormBlock = styled.div`
  margin: 1rem 0;
`;

export const $FormLabel = styled($Label)`
  color: ${(p) => p.theme.colors.baseDarker};
  font-size: ${(p) => p.theme.fontSize.base};
  line-height: 1.5;
  font-weight: bold;
  letter-spacing: 1px;
`;

export const $FormInput = styled.div`
  background: ${(p) => p.theme.components.withdraw.inputWrapperBackground};
  border-radius: ${(p) => p.theme.components.withdraw.borderRadius};
  padding: 10px 12px;
  position: relative;
  display: flex;
  align-items: center;
  transition: background-color 0.5s;
`;

const $CurrencySuffix = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 1.2rem;
  position: relative;
  top: 1px;
  right: 10px;
  cursor: default;
`;

export interface IWireTransferFields {
  recipientDetails: string;
  bankName: string;
  iban: string;
  swift: string;
  referenceNumber: string;
  amount: string;
}

interface IProps extends IWireTransferFields {
  currency: ICurrency;
  title: string;
  isOpen: boolean;
  onClose: () => void;
}
interface IState {
  copiedField: keyof IWireTransferFields;
}

class WireTransferInfoPopup extends React.PureComponent<IProps, IState> {
  static contextType: any = I18nContext;

  private fieldRefs: { [field in keyof IWireTransferFields]: React.RefObject<HTMLInputElement> } = {
    recipientDetails: React.createRef(),
    bankName: React.createRef(),
    iban: React.createRef(),
    swift: React.createRef(),
    referenceNumber: React.createRef(),
    amount: React.createRef(),
  };

  constructor(props) {
    super(props);

    this.state = {
      copiedField: undefined,
    };
  }

  makeCopyToClipboardHandler = (field: keyof IWireTransferFields) => () => {
    if (!this.fieldRefs[field].current) {
      return;
    }
    this.fieldRefs[field].current.select();
    document.execCommand('copy');
    this.setState({ copiedField: field });
  };

  renderCopyIcon = (field: keyof IWireTransferFields) => {
    const { t }: II18nextT = this.context;
    return (
      <GenericTooltip
        overlay={
          this.state.copiedField === field
            ? t('wireTransferInfoPopup.copiedToClipboard')
            : t('wireTransferInfoPopup.copyToClipboard')
        }
      >
        <TodoList1Icon
          style={{ cursor: 'pointer', width: '15px', height: '15px' }}
          className="clipboardIcon"
          onClick={this.makeCopyToClipboardHandler(field)}
        />
      </GenericTooltip>
    );
  };

  renderField(field: keyof IWireTransferFields, additional = null) {
    const { t }: II18nextT = this.context;
    const id = `WireTransferInfoPopup_${field}`;
    return (
      <$FormBlock>
        <$FormLabel htmlFor={id}>
          {t(('wireTransferInfoPopup.labels.' + field) as ITranslations)}
        </$FormLabel>
        <$FormInput>
          <$Input
            id={id}
            name={id}
            type="text"
            border="none"
            value={this.props[field]}
            readOnly
            ref={this.fieldRefs[field]}
          />
          {additional}
          {this.renderCopyIcon(field)}
        </$FormInput>
      </$FormBlock>
    );
  }

  render() {
    const { t }: II18nextT = this.context;

    return (
      <Modal
        title={this.props.title}
        titleUppercase={true}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <$ModalContent>
          {this.renderField('recipientDetails')}
          {this.renderField('bankName')}
          {this.renderField('iban')}
          {this.renderField('swift')}
          {this.renderField('referenceNumber')}
          {this.renderField('amount', <$CurrencySuffix>{this.props.currency}</$CurrencySuffix>)}
        </$ModalContent>
      </Modal>
    );
  }
}

export default WireTransferInfoPopup;
