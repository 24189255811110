import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { TodoList2Icon } from '../../../media/svg_icons';
import { getCustomer, isUserEmailVerified, isUserSuspended } from '../../../selectors/auth';
import { II18nextT } from '../../../types/i18n';
import { $SuccessMessage } from '../../layout/KioskLayout';
import { HideMobile } from '../../utility_components';
import { AlertWarning } from '../../widgets/Alert';
import Header from '../../widgets/Header';
import { $AlertWrapper, $KycPanel } from '../../widgets/KycElements';
import { $HeaderWrapper } from '../../widgets/PageHeaderWrappers';
import KYCFailureNotice, { KYCLockedNotice } from './kyc/KYCFailureNotice';
import KycStatus from './kyc/KycStatus';
import ObtainKycLevel1 from './kyc/ObtainKycLevel1';
import ObtainKycLevel2 from './kyc/ObtainKycLevel2';
import { $SettingsWrapper } from './settings_components';

const $KycWrapper = styled.div`
  display: inline-block;
  @media ${(p) => p.theme.components.kyc.mobileSizeMediaQuery} {
    display: block;
  }
`;

const $KycStatusesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem 1.5rem;
  position: relative;
  margin-bottom: 2rem;

  @media screen and ${(p) => p.theme.components.kyc.mobileSizeMediaQuery} {
    grid-template-columns: auto;
    max-width: ${(p) => p.theme.components.kyc.containerWidth};
    margin: auto;
  }
`;

const $KycContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const $Kyc2CompletedNotice = styled.div`
  max-width: 400px;
`;

interface IKycSettingsProps extends ConnectedProps<typeof connector> {}

interface IKycSettingsState {}

class KycSettings extends React.PureComponent<IKycSettingsProps, IKycSettingsState> {
  static contextType: any = I18nContext;

  renderObtainKycLevelContent() {
    const { emailVerified, suspended, customer } = this.props;
    const { t }: II18nextT = this.context;

    // must be set, use it to check if the store is initialized
    if (!customer) {
      return null;
    }

    if (suspended) {
      return (
        <$KycPanel>
          <$AlertWrapper>
            <AlertWarning>{t('settings:kyc.suspended')}</AlertWarning>
          </$AlertWrapper>
        </$KycPanel>
      );
    }

    if (customer.kyc_locked) {
      return <KYCLockedNotice customer={customer} />;
    }

    if (!emailVerified) {
      return (
        <$KycPanel>
          <$AlertWrapper>
            <AlertWarning>{t('settings:kyc.verifiedEmailRequired')}</AlertWarning>
          </$AlertWrapper>
        </$KycPanel>
      );
    }

    if (customer.kyc_level_granted === 0) {
      return <ObtainKycLevel1 kycTrack={'base'} />;
    }

    if (customer.kyc_level_granted === 1) {
      return <ObtainKycLevel2 kycTrack={'base'} />;
    }

    return (
      <$AlertWrapper>
        <$SuccessMessage>{t('settings:kyc.obtained2')}</$SuccessMessage>
      </$AlertWrapper>
    );
  }

  render() {
    const { t }: II18nextT = this.context;
    const { customer } = this.props;

    return (
      <>
        <HideMobile>
          <$HeaderWrapper>
            <Header icon={TodoList2Icon} title={t('settings:sections.kyc')} variant="dark" />
          </$HeaderWrapper>
        </HideMobile>

        <$SettingsWrapper>
          <$KycWrapper>
            <$KycStatusesWrapper>
              <KycStatus level={0} />
              <KycStatus level={1} />
              <KycStatus level={2} />
            </$KycStatusesWrapper>
            <$KycContent>{this.renderObtainKycLevelContent()}</$KycContent>
          </$KycWrapper>
        </$SettingsWrapper>
      </>
    );
  }
}

const connector = connect((state: IState) => ({
  customer: getCustomer(state),
  emailVerified: isUserEmailVerified(state),
  suspended: isUserSuspended(state),
}));

export default connector(KycSettings);
