import * as React from 'react';
import { I18nContext } from 'react-i18next';

import Quantity from '../../lib/quantity';
import styled from '../../lib/styled_components';
import { ILoadingCircleInheritedProps, LoadingCircle, LockIcon } from '../../media/svg_icons';
import { TimeUnits } from '../../types/constants';
import { $Icon } from './Icon';

const $Timer = styled.div<{ beat: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 10rem;
  height: 12rem;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 auto;

  @keyframes beat {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.2);
    }
  }
  text-align: center;
  span {
    display: inline-block;
    transform: scale(1);
    animation-name: ${(p) => (p.beat ? 'beat' : 'none')};
    animation-direction: alternate;
    animation-duration: 0.25s;
    animation-iteration-count: 1;
    color: ${(p) => p.theme.colors.white};
    margin: 0 2px;
    font-weight: bold;
  }
  font-size: 2.5rem;

  > svg:nth-child(2) {
    position: relative;
    top: -5px;
  }
`;

interface ITimerState {
  minutes: any;
  seconds: any;
  beat: boolean;
  strokeDashOffset: number;
}
interface ITimerProps extends ILoadingCircleInheritedProps {
  handleCountdownEnd: () => void;
  time: number;
}

class Timer extends React.PureComponent<ITimerProps, ITimerState> {
  static contextType: any = I18nContext;
  private intervalTimeout: any = null;
  private timerIntervalTimeout: any = null;
  private mounted: boolean = false;
  private timeInSeconds;
  private strokeDashArray;

  static defaultProps: ILoadingCircleInheritedProps = {
    radius: 60,
  };

  constructor(props) {
    super(props);

    this.state = {
      minutes: null,
      seconds: null,
      beat: false,
      strokeDashOffset: 0,
    };
  }

  componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
      this.timeInSeconds = this.props.time / TimeUnits.second;
      this.strokeDashArray = (2 * Math.PI * this.props.radius) / this.timeInSeconds;
      this.startCountdown();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.intervalTimeout);
    clearInterval(this.timerIntervalTimeout);
  }

  startCountdown() {
    const secondsInMinute = TimeUnits.minute / TimeUnits.second;
    const minutesDecimal = this.timeInSeconds / secondsInMinute;
    const minutes = Math.floor(minutesDecimal);
    const seconds = Quantity(minutesDecimal).sub(minutes).multiply(secondsInMinute).toNumber();

    if (minutesDecimal <= 1) {
      this.setState({ minutes: '00', seconds: this.timeInSeconds }, () => {
        this.startTicking();
      });
    } else {
      this.setState(
        {
          minutes: minutes >= 10 ? minutes : '0' + minutes,
          seconds: seconds >= 10 ? seconds : '0' + seconds,
        },
        () => {
          this.startTicking();
        }
      );
    }
  }

  stopCountdown() {
    clearInterval(this.intervalTimeout);
    this.props.handleCountdownEnd();
  }

  startTicking = () => {
    this.calculateDashOffset();
    this.intervalTimeout = setInterval(() => this.mounted && this.tick(), 1000);
  };

  tick = () => {
    const minutes = Quantity(this.state.minutes).toNumber();
    const seconds = this.state.seconds === '00' ? 60 : Quantity(this.state.seconds).toNumber();

    // Decrease minutes and update countdown
    if (this.state.seconds === '00' && minutes > 0) {
      this.activateBeat();
      const decrementedMinutes = minutes - 1;
      return this.setState({
        minutes: decrementedMinutes >= 10 ? decrementedMinutes : '0' + decrementedMinutes,
        seconds: seconds < 10 ? '0' + (seconds - 1) : seconds - 1,
      });
    }

    // Update countdown
    this.setState(
      {
        seconds: seconds <= 10 ? '0' + (seconds - 1) : seconds - 1,
      },
      () => {
        // Stop countdown
        if (minutes === 0 && this.state.seconds === '00') {
          setTimeout(() => {
            return this.mounted && this.stopCountdown();
          }, 200);
        }
        // Activate beat when timer is on 3/4 has elapsed
        if (
          Quantity(this.state.minutes).lt(1) &&
          Quantity(this.state.seconds).lte(Quantity(this.timeInSeconds).multiply(0.25))
        ) {
          this.activateBeat();
        }
      }
    );
  };

  activateBeat() {
    this.setState({ beat: true });
    setTimeout(() => this.mounted && this.setState({ beat: false }), 250);
  }

  calculateDashOffset = () => {
    if (this.timerIntervalTimeout) {
      clearInterval(this.timerIntervalTimeout);
    }
    let innerTimer = 0;
    const interval = 25;

    this.timerIntervalTimeout = setInterval(() => {
      if (innerTimer >= this.timeInSeconds) {
        clearInterval(this.timerIntervalTimeout);
      }
      const strokeDashOffset = this.strokeDashArray * innerTimer;

      this.mounted && this.setState({ strokeDashOffset });
      innerTimer += interval / 1000;
    }, interval);
  };

  render() {
    const { colorCircleOne, containerSize, radius, lineWidth, tickSide } = this.props;
    const { beat, strokeDashOffset, minutes, seconds } = this.state;
    return (
      <$Timer beat={beat}>
        <LoadingCircle
          tickSide={tickSide}
          colorCircleOne={colorCircleOne}
          containerSize={containerSize}
          lineWidth={lineWidth}
          radius={radius}
          strokeDashOffset={strokeDashOffset}
        />
        <$Icon src={LockIcon} size={20} />
        <div>
          {minutes > 0 && (
            <>
              <span>{minutes}</span>:
            </>
          )}
          <span>{seconds}</span>
        </div>
      </$Timer>
    );
  }
}

export default Timer;
