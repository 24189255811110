import * as React from 'react';

import styled from '../../lib/styled_components';
import { $ErrorButton, $InfoButton, $SuccessButton } from './buttons';
import $Label from './Label';
import { $HeaderWrapper } from './PageHeaderWrappers';

export const $Wrapper = styled.div`
  padding: 0 0px 20px 0px;
`;
export const $HeaderWrapperJustified = styled($HeaderWrapper)<{
  portfolio?: boolean;
}>`
  justify-content: space-between;
  margin-left: 1rem;

  @media ${(p) => p.theme.device.tablet} {
    align-items: ${(p) => (p.portfolio ? 'normal' : 'inherit')};
    height: ${(p) => (p.portfolio ? 'auto' : 'inherit')};
    flex-direction: ${(p) => (p.portfolio ? 'column' : 'inherit')};
  }
  @media ${(p) => p.theme.device.mobile} {
    margin-left: 0;
  }
`;

export const $InnerWrapper = styled.div`
  margin-left: 1rem;
  padding: 10px 20px 10px 26px;
  @media ${(p) => p.theme.device.mobile} {
    padding: 10px;
    margin-left: 0;
  }
`;

export const $RectangleCard = styled.div`
  display: flex;
  padding: 1rem 1rem 0.5rem 1rem;
  background: ${(p) => p.theme.components.managed.cardBgActive};
  margin-bottom: 1rem;
  border-radius: 3px;
  box-shadow: ${(p) => p.theme.components.managed.cardBoxShadow};
`;

export const $ManagedRedButton = styled($ErrorButton)`
  height: 25px;
  font-weight: 100;
  margin-right: 0.5rem;
  line-height: 1;

  @media ${(p) => p.theme.device.mobile} {
    margin: 3px 0.5rem 3px 0;
    height: 20px;
  }
`;
export const $ManagedGreenButton = styled($SuccessButton)`
  height: 25px;
  font-weight: 100;
  margin-right: 0.5rem;
  line-height: 1;

  @media ${(p) => p.theme.device.mobile} {
    margin: 3px 0.5rem 3px 0;
    height: 20px;
  }
`;
export const $ManagedInfoButton = styled($InfoButton)`
  height: 25px;
  font-weight: 100;
  margin-right: 0.5rem;
  line-height: 1;

  @media ${(p) => p.theme.device.mobile} {
    margin: 3px 0.5rem 3px 0;
    height: 20px;
  }
`;

export const $OrderCard = styled($RectangleCard)`
  display: flex;
  justify-content: space-between;
  > div:first-child {
    display: flex;
    flex-flow: wrap;
  }

  @media ${(p) => p.theme.device.mobile_SamsungGalaxyTab2} {
    flex-direction: column;
    > div:first-child {
      flex-direction: column;
    }
  }
`;

export const $InfoLabel = styled.div`
  text-transform: lowercase;
  color: ${(p) => p.theme.colors.baseDarker};
  margin-bottom: 0.5rem;
  font-size: ${(p) => p.theme.fontSize.small};

  @media ${(p) => p.theme.device.mobile} {
    margin-bottom: 0rem;
  }
`;

export const $CardColumnBlock = styled.div`
  padding-right: 15px;
  padding-bottom: 10px;
`;

export const $CardColumnFixedBlock = styled($CardColumnBlock)`
  min-width: 150px;
`;

export const $CardActionsBlock = styled($CardColumnBlock)``;

export const $ActionsInfo = styled.div`
  display: flex;
  button {
    margin: 0;
  }
  button:not(:first-child) {
    margin-left: 5px;
  }

  @media ${(p) => p.theme.device.mobile_SamsungGalaxyTab2} {
    flex-direction: row;

    button {
      min-width: 5rem;
      margin: 0 0.3rem 0 0;
    }
  }
`;

export const $AdditionalInfo = styled.div`
  font-size: ${(p) => p.theme.fontSize.large};
  color: ${(p) => p.theme.colors.baseNeutral};
`;

export const $FormWrapper = styled.div`
  width: 45rem;
  margin: 0 auto;
  padding: 1rem;

  @mobile ${(p) => p.theme.device.mobile} {
  }
`;

export const $FormBlock = styled.div`
  margin: 1rem 0;
`;

export const $FormLabel = styled($Label)`
  color: ${(p) => p.theme.colors.baseDarker};
  font-size: ${(p) => p.theme.fontSize.base};
  line-height: 1.5;
  font-weight: bold;
  letter-spacing: 1px;
`;

export const $BaseInput = styled.div`
  background: ${(p) => p.theme.components.withdraw.inputWrapperBackground};
  border-radius: ${(p) => p.theme.components.withdraw.borderRadius};
  padding: 10px 12px;
  position: relative;
`;

export const $AmountInputWrapper = styled($BaseInput)<{ validationError: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${(p) =>
    (p.validationError && p.theme.components.managed.buyOrderFormInvalidInputBackground) || ''};
  transition: background-color 0.5s;
  padding: 10px 20px;
`;

export const $FormTextInfo = styled.p`
  color: ${(p) => p.theme.colors.baseNeutral};

  a {
    text-decoration: underline;
    color: ${(p) => p.theme.colors.white};
  }
`;
