import Container from '../../lib/container';
import { rethrowTranslated } from '../../lib/errors';
import { loadingWrapper } from '../request_active';

export const requestResetPassword = (email: string) => (
  dispatch,
  _,
  { api, matomo }: Container
) => {
  matomo.sendEvent('Session', 'request_reset_password');
  return dispatch(
    loadingWrapper('requestResetPassword', api.postPasswordResetRequest({ email }))
  ).catch((err) => dispatch(rethrowTranslated(err)));
};

export const verifyResetPasswordToken = (token: string) => (dispatch, _, { api }: Container) => {
  return api.getPasswordResetVerify(token).catch((err) => dispatch(rethrowTranslated(err)));
};

export const resetPassword = (new_password: string, token: string) => (
  dispatch,
  _,
  { api, matomo }: Container
) => {
  return api.putPasswordResetExecute({ new_password, token }).then(
    () => {
      matomo.sendEvent('Session', 'complete_reset_password');
    },
    (err) => dispatch(rethrowTranslated(err))
  );
};
