import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { ICustomLocation } from '../../../lib/history';
import { I18n } from '../../../lib/i18n';
import R, {
  IRouteEnablementProps,
  IRouteSpecWithoutParams,
  getRouteEnablementProps,
} from '../../../lib/routes';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import {
  CardIcon,
  LaptopWithChartIcon,
  MonitorIcon,
  OppositeArrowsIcon,
  SpinningCircleIcon,
  WalletIcon,
} from '../../../media/svg_icons';
import { ITranslations } from '../../../types/translations';
import $Link from '../../widgets/Link';
import GenericTooltip from '../../widgets/tooltips/GenericTooltip';

const $TooltipLinkWrapper = styled.div`
  display: none;
  height: 100%;
`;

const $NavigationMenuItem = styled.h1`
  height: 100%;
  font-size: inherit;
  padding: 0;
  margin: 0;
`;

const $Links = styled.ul`
  margin: 0;
  padding-left: 0;
  list-style: none;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;

  li {
    height: 100%;
  }

  svg {
    width: ${(p) => p.theme.layout.topWidgets.icons.defaultSize};
    height: ${(p) => p.theme.layout.topWidgets.icons.defaultSize};
    margin-right: ${(p) => p.theme.layout.topWidgets.icons.defaultMarginRight};
  }

  .svg-icon-color-stroke {
    stroke: ${(p) => p.theme.components.topMenu.normal.icon} !important;
  }
  .svg-icon-color-fill {
    fill: ${(p) => p.theme.components.topMenu.normal.icon} !important;
  }

  a {
    display: flex;
    align-content: center;
    font-weight: bold;
    padding: ${(p) => p.theme.layout.topWidgets.icons.defaultPadding};
    background-repeat: no-repeat;
    background-position: center left;
    height: 100%;

    font-size: ${(p) => p.theme.fontSize.larger};

    background: ${(p) => p.theme.components.topMenu.normal.background};

    span {
      color: ${(p) => p.theme.components.topMenu.normal.text} !important;
    }

    &:hover {
      span {
        color: ${(p) => p.theme.components.topMenu.hover.text} !important;
      }

      /** TODO: These don't work? */
      .svg-icon-color-stroke {
        stroke: ${(p) => p.theme.components.topMenu.hover.icon} !important;
      }
      .svg-icon-color-fill {
        fill: ${(p) => p.theme.components.topMenu.hover.icon} !important;
      }
    }
  }

  .active {
    background: ${(p) => p.theme.components.topMenu.active.background};

    span {
      color: ${(p) => p.theme.components.topMenu.active.text} !important;
    }

    /** TODO: These don't work? */
    .svg-icon-color-stroke {
      stroke: ${(p) => p.theme.components.topMenu.active.icon} !important;
    }
    .svg-icon-color-fill {
      fill: ${(p) => p.theme.components.topMenu.active.icon} !important;
    }
  }

  @media screen and ${(p) => p.theme.device.laptop} {
    a {
      font-size: ${(p) => p.theme.fontSize.large};
      padding: ${(p) => p.theme.layout.topWidgets.icons.laptopPadding};

      svg {
        width: ${(p) => p.theme.layout.topWidgets.icons.laptopSize};
        height: ${(p) => p.theme.layout.topWidgets.icons.laptopSize};
        margin-right: ${(p) => p.theme.layout.topWidgets.icons.laptopMarginRight};
      }
    }

    ${$TooltipLinkWrapper} {
      a {
        padding: ${(p) => p.theme.layout.topWidgets.icons.defaultPadding};
        svg {
          margin-right: 0;
        }
      }
    }
  }

  // Hide icon text
  @media (max-width: ${(p) => p.theme.layout.showTooltipIconMenu}) {
    > li ${$NavigationMenuItem} {
      display: none;
    }
    ${$TooltipLinkWrapper} {
      display: block;
    }
  }
`;

interface INavigationMenuProps extends RouteComponentProps, ConnectedProps<typeof connector> {}

interface INavigationMenuItem {
  key: string;
  className: string;
  route: IRouteSpecWithoutParams;
  label: string;
  icon: any;
}

const isActiveClass = (condition) => (condition ? 'active' : '');

function generateNavigationMenuItems(
  location: ICustomLocation,
  props: IRouteEnablementProps
): INavigationMenuItem[] {
  return [
    {
      key: 'exchange',
      className: isActiveClass(R.EXCHANGE.isActive(location)),
      label: 'pages.exchange',
      route: R.EXCHANGE_HOME,
      icon: MonitorIcon,
    },
    {
      key: 'markets',
      className: isActiveClass(R.MARKETS.isActive(location)),
      label: 'pages.markets',
      route: R.MARKETS,
      icon: SpinningCircleIcon,
    },
    {
      key: 'balances',
      className: isActiveClass(R.BALANCES.isActive(location)),
      label: 'pages.balances',
      route: R.BALANCES,
      icon: WalletIcon,
    },
    {
      key: 'reports',
      className: isActiveClass(R.REPORTS.isActive(location)),
      label: 'pages.reports',
      route: R.REPORTS_HOME,
      icon: OppositeArrowsIcon,
    },
    {
      key: 'buyWithCard',
      className: isActiveClass(R.BUY_WITH_CARD.isActive(location)),
      label: 'pages.buyWithCard',
      route: R.BUY_WITH_CARD,
      icon: CardIcon,
    },
    // NOTE: Don't include managed for now
    // {
    //   key: 'managed',
    //   className: isActiveClass(R.MANAGED_HOME.isActive(location) || R.MANAGED.isActive(location)),
    //   label: 'pages.managed',
    //   route: R.MANAGED_HOME,
    //   icon: LaptopWithChartIcon,
    // },
  ].filter((item) => item.route.isEnabled(props));
}

const NavigationMenu: React.FunctionComponent<INavigationMenuProps> = React.memo((props) => {
  const components = [];
  for (const item of generateNavigationMenuItems(props.location, props.routeEnablementProps)) {
    const Icon = item.icon;

    components.push(
      <I18n key={item.key + '-link'}>
        {(t) => (
          <li>
            <$NavigationMenuItem>
              <$Link as={Link} className={item.className} to={item.route}>
                <Icon />
                <span>{t(`${item.label}` as ITranslations)}</span>
              </$Link>
            </$NavigationMenuItem>
          </li>
        )}
      </I18n>
    );

    components.push(
      <I18n key={item.key + '-tooltip'}>
        {(t) => (
          <$TooltipLinkWrapper>
            <GenericTooltip
              overlay={t(`${item.label}` as ITranslations)}
              overlayStyle={{ zIndex: 1000 }}
            >
              <li>
                <$Link as={Link} key={item.key} className={item.className} to={item.route}>
                  <Icon />
                </$Link>
              </li>
            </GenericTooltip>
          </$TooltipLinkWrapper>
        )}
      </I18n>
    );
  }

  return <$Links className="hide-on-mobile">{components}</$Links>;
});

const connector = connect((state: IState) => ({
  routeEnablementProps: getRouteEnablementProps(state),
}));

export default withRouter(connector(NavigationMenu));
