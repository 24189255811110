import { handleError } from '../../lib/errors';
import lodash from '../../lib/lodash';
import { commit, val } from '../../lib/redux_helpers';
import { IStateGetter, IThunkMethod } from '../../lib/store';
import { getUser } from '../../selectors/auth';
import { getActiveOrdersPaginationState } from '../../selectors/orders';
import { IPair } from '../../types/backend_definitions';
import { IApiOrderPayload } from '../../types/backend_definitions';
import { IOrder } from '../../types/orders';
import { loadingWrapper } from '../request_active';
import {
  incrementBuyOrdersForInstrumentPair,
  incrementSellOrdersForInstrumentPair,
} from './active_instruments_orders';
import { IOrderState, IOrdersStateById } from './orders';

let ordersToSave: IOrder[] = [];

export const addOrderThrottled = lodash.throttle((dispatch, getState: IStateGetter) => {
  if (!ordersToSave.length) {
    return;
  }

  const state = getState();
  const paginationState = getActiveOrdersPaginationState(state);
  const user = getUser(state);

  if (!user) {
    return;
  }

  const ordersById: IOrdersStateById = {};

  for (const order of ordersToSave) {
    if (
      user.id === order.user_id &&
      (order.pair === paginationState.pair || !paginationState.pair)
    ) {
      ordersById[order.id] = val<IOrderState>({
        order,
        forCancellation: false,
      });

      if (order.side === 'buy') {
        dispatch(incrementBuyOrdersForInstrumentPair(order.pair as IPair));
      } else {
        dispatch(incrementSellOrdersForInstrumentPair(order.pair as IPair));
      }
    }
  }

  dispatch(
    commit(`Add order(s)`, {
      ordersById,
    })
  );

  ordersToSave = [];
}, 500);

export const addOrder = (newOrder: IOrder): IThunkMethod => (dispatch, getState) => {
  ordersToSave.push(newOrder);
  return addOrderThrottled(dispatch, getState);
};

export const submitOrder = (data: IApiOrderPayload): IThunkMethod => (
  dispatch,
  _,
  { api, matomo }
) => {
  // Need to be something like "buy_limit"
  const eventName = data.side + '_' + data.type;
  matomo.sendEvent('Trading', eventName as any, data.pair, Number(data.quantity));

  return dispatch(
    loadingWrapper(
      'postExchangeOrder',
      api.postExchangeOrder(data).catch((err) => dispatch(handleError(err)))
    )
  );
};
