import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { goToExchange, goToLogin } from '../../../actions/routing';
import { verifyEmailVerificationToken } from '../../../bl/email_verification';
import { ITranslatedError, ValidationSingleMessage } from '../../../lib/errors';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { isUserLoggedIn } from '../../../selectors/auth';
import { TimeUnits } from '../../../types/constants';
import { II18nextT } from '../../../types/i18n';
import { $SuccessMessage } from '../../layout/KioskLayout';

const $Wrapper = styled.div`
  width: 340px;
  max-width: 100%;
`;

interface IComponentProps
  extends ConnectedProps<typeof connector>,
    RouteComponentProps<{ token: string }> {}
interface IComponentState {
  error: ITranslatedError;
  success: boolean;
}

class EmailVerificationExecuteScreen extends React.PureComponent<IComponentProps, IComponentState> {
  mounted: boolean;
  static contextType: any = I18nContext;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: false,
    };
  }

  componentDidMount() {
    this.mounted = true;

    const { token } = this.props.match.params;

    if (token) {
      this.props
        .verifyEmailVerificationToken(token)
        .then(() => {
          if (this.mounted) {
            this.setState({ success: true });
            setTimeout(() => {
              if (!this.mounted) {
                return;
              }

              return this.props.loggedIn ? this.props.goToExchange() : this.props.goToLogin();
            }, TimeUnits.second * 5);
          }
        })
        .catch((error) => this.mounted && this.setState({ error }));
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { t }: II18nextT = this.context;
    if (this.state.success) {
      return (
        <$Wrapper>
          <$SuccessMessage>
            <p>{t('emailVerification.verifiedMessage')}</p>
            <p>
              {t(
                this.props.loggedIn
                  ? 'emailVerification.youWillBeRedirectedToExchange'
                  : 'emailVerification.youWillBeRedirectedToLogin'
              )}
            </p>
          </$SuccessMessage>
        </$Wrapper>
      );
    }

    return (
      <$Wrapper>
        <ValidationSingleMessage error={this.state.error} />
      </$Wrapper>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    loggedIn: isUserLoggedIn(state),
  }),
  {
    verifyEmailVerificationToken,
    goToExchange,
    goToLogin,
  }
);

export default withRouter(connector(EmailVerificationExecuteScreen));
