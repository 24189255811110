import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { IState } from '../../../lib/store';
import styled, { IThemeColorsUnion } from '../../../lib/styled_components';
import { TodoList1Icon as CopyIcon } from '../../../media/svg_icons';
import $ColorText from '../ColorText';
import GenericTooltip from './GenericTooltip';
import { ITooltipProps } from './tooltip_types';

type IProps = ITooltipProps & {
  color?: IThemeColorsUnion;
};

export const $TooltipText = styled($ColorText)`
  display: inline-block;
  outline: none;
  &:hover:after {
    content: '';
    display: block;
    border-bottom-style: dotted;
    border-bottom-width: 1.5px;
    border-bottom-color: ${(p) => (p.color ? p.theme.colors[p.color] : 'inherit')};
    height: 0;
    margin-top: -2px;
  }
`;

const $TooltipTextHolder = styled.span<{ hovered: boolean; copied: boolean }>`
  width: 100%;
  padding-left: 0.3rem;
  color: ${(p) => (p.copied ? p.theme.colors.buy : p.hovered ? p.theme.colors.warning : 'inherit')};
`;

const $TooltipInput = styled.input`
  opacity: 0;
  position: absolute;
  pointer-events: none;
`;

export const $TooltipWithCopyTextWrapper = styled($ColorText)<{
  copyIconHovered: boolean;
  copySuccess: boolean;
}>`
  outline: initial !important;
  display: flex;
  align-items: center;

  > svg {
    cursor: pointer;
  }
`;

interface IClickableTooltipWithCopyProps extends ConnectedProps<typeof connector> {
  color?: IThemeColorsUnion;
  tooltipText: string;
  children?: any;
}

interface IClickableTooltipWithCopyState extends IProps {
  copySuccess: boolean;
  copyIconHovered: boolean;
}

class ClickableTooltipWithCopy extends React.PureComponent<
  IClickableTooltipWithCopyProps,
  IClickableTooltipWithCopyState
> {
  static contextType: any = I18nContext;
  private inputRef: React.RefObject<any> = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      copySuccess: false,
      copyIconHovered: false,
    };
  }

  renderTooltipText() {
    const windowWidth = window.innerWidth;
    const { samsungGalaxyTab2, mobileIphonePlus, laptopBreakpoint } = this.props.theme.base;

    if (windowWidth >= laptopBreakpoint) {
      return this.props.tooltipText;
    }

    if (windowWidth < laptopBreakpoint) {
      let CHAR_LIMIT = 30;
      if (windowWidth > 1200 && windowWidth < laptopBreakpoint) {
        CHAR_LIMIT = 50;
      }
      if (windowWidth > mobileIphonePlus && windowWidth < samsungGalaxyTab2) {
        CHAR_LIMIT = 40;
      }

      let i = 1;
      const stringCuts = [];

      while (this.props.tooltipText.length >= CHAR_LIMIT * i) {
        const stringCut = this.props.tooltipText.slice(CHAR_LIMIT * (i - 1), CHAR_LIMIT * i);
        stringCuts.push(stringCut);
        i++;
      }
      if (this.props.tooltipText.length < CHAR_LIMIT * i) {
        stringCuts.push(
          this.props.tooltipText.slice(CHAR_LIMIT * (i - 1), this.props.tooltipText.length)
        );
      }

      return stringCuts.map((stringCut, i) => {
        if (stringCuts.length - 1 === i) {
          return stringCut;
        } else {
          return (
            <span key={i}>
              {stringCut}
              <br />
            </span>
          );
        }
      });
    }
    return '';
  }

  copyToClipboard = () => {
    this.inputRef.current.select();
    this.inputRef.current.setSelectionRange(0, 99999);
    document.execCommand('copy');
    this.setState({ copySuccess: true });
  };

  onMouseEnter = () => {
    !this.state.copyIconHovered && this.setState({ copyIconHovered: true });
  };
  onMouseLeave = () => {
    this.state.copyIconHovered && this.setState({ copyIconHovered: false });
  };

  resetState = () => this.setState({ copySuccess: false, copyIconHovered: false });

  render() {
    const { color, tooltipText, children, ...passProps } = this.props;

    if (!this.props.tooltipText) {
      // If we don't have text, just render the children, without tooltip (XCAL-559)
      return children;
    }

    return (
      <GenericTooltip
        placement="top"
        trigger={['click']}
        onClick={this.resetState}
        destroyTooltipOnHide
        overlay={
          <$TooltipWithCopyTextWrapper
            copyIconHovered={this.state.copyIconHovered}
            copySuccess={this.state.copySuccess}
            tabIndex={1}
            color={color}
          >
            <>
              <CopyIcon
                className="clipboardIcon"
                onClick={this.copyToClipboard}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={!this.state.copySuccess ? this.onMouseLeave : () => {}}
              />

              <$TooltipInput
                ref={this.inputRef}
                type="text"
                name="withdrawal_target_address"
                defaultValue={tooltipText}
              />
            </>
            <$TooltipTextHolder
              hovered={this.state.copyIconHovered}
              copied={this.state.copySuccess}
            >
              {this.renderTooltipText()}
            </$TooltipTextHolder>
          </$TooltipWithCopyTextWrapper>
        }
        {...(passProps as any)}
      >
        {children}
      </GenericTooltip>
    );
  }
}

const connector = connect((state: IState) => ({
  theme: state.app.theme,
}));

export default connector(ClickableTooltipWithCopy);
