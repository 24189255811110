import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { setBuySellValues } from '../../../actions/buy_sell';
import { IState } from '../../../lib/store';
import styled, { IThemeColorsUnion, zIndex } from '../../../lib/styled_components';
import { formatNumberToFixed } from '../../../lib/util';
import { getCurrentPair, getMarketDepthSide } from '../../../selectors/exchange';
import { IApiMarketDepthLevel, IOrderSide } from '../../../types/backend_definitions';
import { II18nextT } from '../../../types/i18n';
import { IInstrumentPair, PRICE_DIGITS } from '../../../types/instruments';
import PrettyDecimals, { SidedPrettyDecimals } from '../../widgets/PrettyDecimals';
import ScrollBox from '../../widgets/ScrollBox';
import {
  $Cell,
  $DigitCell,
  $Table,
  $TableHeading,
  $TableHeadingBadge,
  $TableRow,
} from '../../widgets/Table';
import WidgetTitle from './WidgetTitle';

const $OrderBook = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const $SupplyHeader = styled.div<{ side: 'buy' | 'sell' }>`
  font-size: ${(p) => p.theme.fontSize.large};
  padding: 0;
  margin-top: 5px;
  margin-bottom: 5px;

  > div {
    display: none;
  }

  > strong {
    margin-left: 0.4rem;
  }

  > span:first-child {
    font-weight: bold;
    text-transform: uppercase;
    font-size: ${(p) => p.theme.fontSize.smaller};
    margin-right: 0.6rem;
  }
  > span:first-child:after {
    content: ':';
  }

  @media only screen and ${(p) => p.theme.device.mobile_SamsungGalaxyTab2} {
    display: block;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    justify-content: space-between;
    padding-top: 15px;
    margin-top: 0px;
    margin-bottom: 2px;
    margin-left: ${(p) => (p.side === 'buy' ? '1rem' : '0')};
    margin-right: ${(p) => (p.side === 'buy' ? '0' : '1rem')};
    text-align: ${(p) => (p.side === 'buy' ? 'right' : 'left')};
    font-size: ${(p) => p.theme.fontSize.small};
    line-height: ${(p) => p.theme.fontSize.small};

    > div {
      display: flex;
    }

    > span:first-child {
      display: none;
    }

    > span:first-child:after {
      content: '';
    }

    > strong {
      display: none;
    }
  }
`;

const $TotalSupply = styled.div<{ side: 'buy' | 'sell' }>`
  flex-direction: ${(p) => (p.side === 'sell' ? 'row-reverse' : 'row')};
  align-items: baseline;
  position: absolute;
  top: 4px;
  left: ${(p) => (p.side === 'sell' ? '0' : 'initial')};
  right: ${(p) => (p.side === 'buy' ? '0' : 'initial')};

  > span {
    display: block !important;
    margin-left: 0.4em;
    margin-right: 0.4em;
    font-size: ${(p) => p.theme.fontSize.badge};
    text-transform: uppercase;
  }
  > strong {
    display: block !important;
    left: ${(p) => (p.side === 'sell' ? '0' : 'initial')};
  }

  @media only screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    top: 5px;

    > strong {
      font-size: ${(p) => p.theme.fontSize.smaller};
    }
  }
`;

const $MarketDepth = styled('div')<{ side: IOrderSide }>`
  ${(p) => (p.side === 'buy' ? 'left' : 'right')}: 0;
  border-radius: ${(p) => (p.side === 'buy' ? '0 3px' : '3px 0')} 0 0;
  background-color: ${(p) => (p.side === 'buy' ? p.theme.colors.buy : p.theme.colors.sell)};
  top: -1px;
  height: 100%;
  opacity: 0.15;
  position: absolute;
  z-index: ${zIndex.marketDepth};
`;

const $DigitOBCell = styled($DigitCell)<{ hideDepthColumn?: boolean; hideTotalColumn?: boolean }>`
  z-index: ${zIndex.obCell};
  width: 25%;

  @media only screen and ${(p) => p.theme.device.mobile_SamsungGalaxyTab2} {
    display: ${(p) => p.hideDepthColumn && 'none'};
    width: 33% !important;
  }
  @media only screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    display: ${(p) => (p.hideTotalColumn || p.hideDepthColumn ? 'none' : 'block')};
    width: 50% !important;
  }
`;
const $OBCell = styled($Cell)<{ hideDepthColumn?: boolean; hideTotalColumn?: boolean }>`
  z-index: ${zIndex.obCell};
  width: 25%;

  @media only screen and ${(p) => p.theme.device.mobile_SamsungGalaxyTab2} {
    display: ${(p) => p.hideDepthColumn && 'none'};
    width: 33% !important;
  }
  @media only screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    display: ${(p) => (p.hideTotalColumn || p.hideDepthColumn ? 'none' : 'block')};
    width: 50% !important;
  }
`;

const $TableHeadingCell = styled($OBCell)`
  display: flex;
  padding-left: 5px;
  align-items: baseline;

  // Sell OrderBook table heading cell
  &.Sell_depth {
    flex-direction: row;
    text-align: left;
  }
  &.Sell_total {
    flex-direction: row-reverse;
    text-align: right;
  }
  &.Sell_quantity {
    flex-direction: row-reverse;
    text-align: right;
  }
  &.Sell_price {
    flex-direction: row-reverse;
    text-align: right;
  }

  // Buy OrderBook table heading cell
  &.Buy_price {
    flex-direction: row;
    text-align: left;
  }
  &.Buy_quantity {
    flex-direction: row;
    text-align: left;
  }
  &.Buy_total {
    flex-direction: row;
    text-align: left;
  }
  &.Buy_depth {
    flex-direction: row-reverse;
    text-align: right;
  }

  @media only screen and ${(p) => p.theme.device.mobile_SamsungGalaxyTab2} {
    // Sell OrderBook table heading cell
    &.Sell_total {
      flex-direction: row;
      text-align: left;
    }
    &.Sell_quantity {
      flex-direction: row-reverse;
      text-align: right;
    }

    // Buy OrderBook table heading cell
    &.Buy_total {
      flex-direction: row-reverse;
      text-align: right;
    }
  }
  @media only screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    // Sell OrderBook table heading cell
    &.Sell_quantity {
      display: flex;
      flex-direction: row;
      text-align: left;
    }
    &.Sell_price {
      display: flex;
    }

    // Buy OrderBook table heading cell
    &.Buy_quantity {
      display: flex;
      flex-direction: row-reverse;
      text-align: right;
    }
  }
`;
const $TableBodyCell = styled($DigitOBCell)`
  // Sell OrderBook table body cell
  &.Sell_depth {
    text-align: left;
  }
  &.Sell_total {
    text-align: right;
  }
  &.Sell_quantity {
    text-align: right;
  }
  &.Sell_price {
    text-align: right;
  }

  // Buy OrderBook table body cell
  &.Buy_price {
    text-align: left;
  }
  &.Buy_quantity {
    text-align: left;
  }
  &.Buy_total {
    text-align: left;
  }
  &.Buy_depth {
    text-align: right;
  }

  @media only screen and ${(p) => p.theme.device.mobile_SamsungGalaxyTab2} {
    // Sell OrderBook table heading cell
    &.Sell_total {
      text-align: left;
    }
    &.Sell_quantity {
      text-align: right;
    }

    // Buy OrderBook table heading cell
    &.Buy_total {
      text-align: right;
    }
  }
  @media only screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    // Sell OrderBook table heading cell
    &.Sell_quantity {
      text-align: left;
      padding-right: 0.5rem;
    }
    &.Sell_price {
      padding-left: 0.5rem;
    }

    // Buy OrderBook table heading cell
    &.Buy_price {
      padding-right: 0.5rem;
    }
    &.Buy_quantity {
      text-align: right;
      padding-left: 0.5rem;
    }
  }
`;

const $TableLevelRow = styled($TableRow)<{ hideHover: boolean }>`
  position: relative;

  &:hover {
    background: ${(p) => (p.hideHover ? 'none' : p.theme.widgets.table.backgroundHover)};
    font-weight: bold;
  }
`;

const $HighlightedArea = styled('div')<{ side: IOrderSide; height: number }>`
  width: 100%;
  display: ${(p) => (p.height ? '' : 'none')};
  height: ${(p) => p.height}px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${(p) =>
    p.side === 'buy'
      ? p.theme.components.orderBook.highlightedBuyBackground
      : p.theme.components.orderBook.highlightedSellBackground};
  border-bottom: 1px dashed ${(p) => p.theme.components.orderBook.borderColor};
`;

const $OrderBookTable = styled($Table).attrs<{ side: IOrderSide }>((p) => ({
  noPadding: true,
  overlayColor: p.side === 'buy' ? p.theme.colors.buy : p.theme.colors.sell,
}))<{ side: IOrderSide }>``;

interface IOrderBookLevelProps extends IOrderBookOwnProps {
  level: IApiMarketDepthLevel;
  maxDepth: number;
  pair: IInstrumentPair;
  onClick: (any) => any;
  onMouseEnter: (any) => any;
  hideHover: boolean;
}

/************************** OrderBookLevel **************************/
class OrderBookLevel extends React.PureComponent<IOrderBookLevelProps> {
  static contextType: any = I18nContext;

  handleClick = () => this.props.onClick(this.props.level);

  render() {
    const { side, pair, level, maxDepth } = this.props;

    const isBuy = side === 'buy';

    const priceCell = (
      <$TableBodyCell className={isBuy ? 'Buy_price' : 'Sell_price'}>
        <SidedPrettyDecimals value={formatNumberToFixed(level.price, PRICE_DIGITS)} side={side} />
      </$TableBodyCell>
    );

    const quantityCell = (
      <$TableBodyCell className={isBuy ? 'Buy_quantity' : 'Sell_quantity'}>
        <PrettyDecimals value={formatNumberToFixed(level.quantity, pair.quote.digits)} />
      </$TableBodyCell>
    );

    const totalCell = (
      <$TableBodyCell hideTotalColumn={true} className={isBuy ? 'Buy_total' : 'Sell_total'}>
        <PrettyDecimals value={formatNumberToFixed(level.total, pair.base.digits)} />
      </$TableBodyCell>
    );

    const depthCell = (
      <$TableBodyCell hideDepthColumn={true} className={isBuy ? 'Buy_depth' : 'Sell_depth'}>
        <PrettyDecimals value={formatNumberToFixed(level.total_depth, pair.base.digits)} />
      </$TableBodyCell>
    );

    return (
      <$TableLevelRow
        hideHover={this.props.hideHover}
        key={`${level.price}`}
        onClick={this.handleClick}
        onMouseEnter={this.props.onMouseEnter}
        noBottomBorder
      >
        <$MarketDepth
          side={side}
          style={{ width: Math.floor((Number(level.quantity_depth) * 100) / maxDepth) + '%' }}
        />

        {isBuy ? (
          <>
            {priceCell}
            {quantityCell}
            {totalCell}
            {depthCell}
          </>
        ) : (
          <>
            {depthCell}
            {totalCell}
            {quantityCell}
            {priceCell}
          </>
        )}
      </$TableLevelRow>
    );
  }
}

/************************** OrderBook **************************/
interface IOrderBookOwnProps {
  side: IOrderSide;
}

interface IOrderBookProps extends IOrderBookOwnProps, ConnectedProps<typeof connector> {}
interface IOrderBookState {
  highlighterHeight: number;
}

class OrderBook extends React.PureComponent<IOrderBookProps, IOrderBookState> {
  static contextType: any = I18nContext;

  constructor(props) {
    super(props);

    this.state = {
      highlighterHeight: null,
    };
  }

  /**
   * Send summed up orderbook quantities between the picked order price and the current market price
   * to opposite side. Send only price to the same side BuySell component.
   */
  sendToBuySellComponent = (level: IApiMarketDepthLevel) => {
    const oppositeSide: IOrderSide = this.props.side === 'buy' ? 'sell' : 'buy';

    this.props.setBuySellValues({
      [this.props.side]: { price: level.price },
      [oppositeSide]: { price: level.price, quantity: Number(level.quantity_depth) },
    });
  };

  renderSupplyHeader() {
    const { t }: II18nextT = this.context;
    const { side, marketDepthSlice, pair } = this.props;
    return (
      <$SupplyHeader side={side}>
        <span>{t('orderBook.supplyTitle')}</span>
        <PrettyDecimals
          value={side === 'buy' ? marketDepthSlice.base_supply : marketDepthSlice.quote_supply}
        />
        <strong>{side === 'buy' ? pair.base.symbol : pair.quote.symbol}</strong>
        <$TotalSupply side={side}>
          <span>{t('orderBook.supplyTitle')}</span>
          <strong>{side === 'buy' ? pair.base.symbol : pair.quote.symbol}</strong>
        </$TotalSupply>
      </$SupplyHeader>
    );
  }

  handleMouseEnter = (e) => {
    const { offsetTop, offsetHeight } = e.currentTarget;
    const highlighterHeight = offsetTop + offsetHeight;
    this.setState({ highlighterHeight });
  };

  handleLevelClick = (level) => this.sendToBuySellComponent(level);

  render() {
    const { t }: II18nextT = this.context;
    const { pair, side, marketDepthSlice } = this.props;

    if (!pair) {
      return null;
    }

    const maxDepth = marketDepthSlice.levels.length
      ? Number(marketDepthSlice.levels[marketDepthSlice.levels.length - 1].quantity_depth)
      : 0;

    const isBuy = side === 'buy';
    const color: IThemeColorsUnion = isBuy ? 'buy' : 'sell';

    const priceHeader = (
      <$TableHeadingCell noGrow className={isBuy ? 'Buy_price' : 'Sell_price'}>
        {pair.base.symbol}
        <$TableHeadingBadge>{t('orderBook.columns.price')}</$TableHeadingBadge>
      </$TableHeadingCell>
    );

    const quantityHeader = (
      <$TableHeadingCell noGrow className={isBuy ? 'Buy_quantity' : 'Sell_quantity'}>
        {pair.quote.symbol}
        <$TableHeadingBadge>{t('orderBook.columns.amount')}</$TableHeadingBadge>
      </$TableHeadingCell>
    );

    const totalHeader = (
      <$TableHeadingCell
        hideTotalColumn={true}
        noGrow
        className={isBuy ? 'Buy_total' : 'Sell_total'}
      >
        {pair.base.symbol}
        <$TableHeadingBadge>{t('orderBook.columns.total')}</$TableHeadingBadge>
      </$TableHeadingCell>
    );

    const depthHeader = (
      <$TableHeadingCell
        hideDepthColumn={true}
        noGrow
        className={isBuy ? 'Buy_depth' : 'Sell_depth'}
      >
        {pair.base.symbol}
        <$TableHeadingBadge>{t('orderBook.columns.depth')}</$TableHeadingBadge>
      </$TableHeadingCell>
    );

    return (
      <$OrderBook onMouseLeave={() => this.setState({ highlighterHeight: null })}>
        <WidgetTitle
          title={t(side)}
          color={color}
          flipOnLargeScreens={!isBuy}
          opposite={this.renderSupplyHeader()}
          side={side}
        />
        <$OrderBookTable side={side}>
          <$TableHeading noBorder>
            {isBuy ? (
              <>
                {priceHeader}
                {quantityHeader}
                {totalHeader}
                {depthHeader}
              </>
            ) : (
              <>
                {depthHeader}
                {totalHeader}
                {quantityHeader}
                {priceHeader}
              </>
            )}
          </$TableHeading>
          <ScrollBox mobileHeight="250px">
            {this.state.highlighterHeight && (
              <$HighlightedArea side={side} height={this.state.highlighterHeight} />
            )}
            {marketDepthSlice.levels.map((level) => (
              <OrderBookLevel
                key={level.price}
                level={level}
                maxDepth={maxDepth}
                pair={this.props.pair}
                side={this.props.side}
                onClick={this.handleLevelClick}
                hideHover={!!this.state.highlighterHeight}
                onMouseEnter={this.handleMouseEnter}
              />
            ))}
          </ScrollBox>
        </$OrderBookTable>
      </$OrderBook>
    );
  }
}

const connector = connect(
  (state: IState, { side }: IOrderBookOwnProps) => ({
    marketDepthSlice: getMarketDepthSide(state, side),
    pair: getCurrentPair(state),
  }),
  {
    setBuySellValues,
  }
);

export default connector(OrderBook);
