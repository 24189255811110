import React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { IActiveRequestsKeys } from '../../../actions/request_active';
import { updateOrderConfirmations, updateOrderNotifications } from '../../../actions/settings';
import { IState } from '../../../lib/store';
import monitorWithdrawalsByIpIcon from '../../../media/monitor-withdrawals-by-ip.png';
import { NotifyIcon } from '../../../media/svg_icons';
import {
  getUserOrderConfirmationsSettings,
  getUserOrderNotificationsSettings,
} from '../../../selectors/auth';
import { II18nextT } from '../../../types/i18n';
import { HideMobile } from '../../utility_components';
import Header from '../../widgets/Header';
import { $HeaderWrapper } from '../../widgets/PageHeaderWrappers';
import { $SettingsWrapper, SettingsSectionCheckbox } from './settings_components';

interface INotificationObject {
  name: string;
  updateFunction?: (event: boolean) => any;
  requests: IActiveRequestsKeys[];
  label: string;
  icon: string;
  description: string;
}

interface INotificationSettingsProps extends ConnectedProps<typeof connector> {}

interface INotificationSettingsState {
  enableOrderNotifications: boolean;
  withdrawalComplete: boolean;
  depositArrives: boolean;
  depositComplete: boolean;
  notifications: INotificationObject[];
  emailNotifications: INotificationObject[];
}

class NotificationSettings extends React.PureComponent<
  INotificationSettingsProps,
  INotificationSettingsState
> {
  static contextType: any = I18nContext;
  constructor(props) {
    super(props);

    this.state = {
      enableOrderNotifications: true,
      withdrawalComplete: true,
      depositArrives: true,
      depositComplete: true,
      notifications: [],
      emailNotifications: [],
    };
  }

  componentDidMount() {
    const { t }: II18nextT = this.context;

    this.setState({
      notifications: [
        {
          name: 'orderConfirmations',
          updateFunction: this.props.updateOrderConfirmations,
          label: t('settings:notifications.orderConfirmationPopup'),
          requests: ['enableOrderConfirmations'],
          icon: monitorWithdrawalsByIpIcon,
          description: t('settings:notifications.orderConfirmationPopupDescription'),
        },
        {
          name: 'orderNotifications',
          updateFunction: this.props.updateOrderNotifications,
          label: t('settings:notifications.tradeToastNotification'),
          requests: ['enableOrderNotifications'],
          icon: monitorWithdrawalsByIpIcon,
          description: t('settings:notifications.tradeToastNotificationDescription'),
        },
      ],
      // TODO: Add email notifications
      emailNotifications: [],
    });
  }

  render() {
    const { t }: II18nextT = this.context;
    return (
      <>
        <HideMobile>
          <$HeaderWrapper>
            <Header title={t('settings:sections.notifications')} icon={NotifyIcon} variant="dark" />
          </$HeaderWrapper>
        </HideMobile>
        <$SettingsWrapper>
          {this.state.notifications.map((notification) => (
            <SettingsSectionCheckbox
              key={notification.name}
              name={notification.name}
              label={notification.label}
              icon={notification.icon}
              description={notification.description}
              checked={this.props[notification.name]}
              requests={notification.requests}
              onChange={(event) => notification.updateFunction(event.target.checked)}
            />
          ))}

          {this.state.emailNotifications.map((notification) => (
            <SettingsSectionCheckbox
              key={notification.name}
              name={notification.name}
              label={notification.label}
              icon={notification.icon}
              description={notification.description}
              checked={this.state[notification.name]}
              onChange={(event) =>
                this.setState({ [notification.name]: event.target.checked } as any)
              }
            />
          ))}
        </$SettingsWrapper>
      </>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    orderConfirmations: getUserOrderConfirmationsSettings(state),
    orderNotifications: getUserOrderNotificationsSettings(state),
  }),
  {
    updateOrderConfirmations,
    updateOrderNotifications,
  }
);

export default connector(NotificationSettings);
