import { DeepPartial } from 'redux';

import { HttpErrorResponse } from '../../lib/http';
import lodash from '../../lib/lodash';
import { Logger } from '../../lib/logger';
import { commit, val } from '../../lib/redux_helpers';
import R, { IRouteSpec } from '../../lib/routes';
import { IState, IThunkMethod, initialState } from '../../lib/store';
import { getSession, isUserLoggedIn } from '../../selectors/auth';
import { ISession } from '../../types/auth';
import {
  IApiXcalibraClientRequest,
  ISessionTerminationReason,
} from '../../types/backend_definitions';
import { clearNotifications, loadAppData } from '../app';
import { findStaticRedirectRoute, navigateToDynamicRoute } from '../routing';
import { setSessionTerminatedReason } from './auth';

const unauthenticate = (): IThunkMethod<ISession> => (
  dispatch,
  getState,
  { api, http, localStorage }
) => {
  const state = getState();
  // Save current session, if any
  const session = getSession(state);

  // Delete access_token from Local Storage
  localStorage.deleteAccessToken();

  // Clear notifications
  dispatch(clearNotifications());

  // Clear logged in state
  const cleanState: IState = lodash.merge<
    object,
    IState,
    { [key in keyof IState]: DeepPartial<IState[key]> }
  >({}, initialState(), {
    // NOTE: The way types are set here, TS will force you to set every key in this object so that it matches IState.
    //       To preserve values, pick them from the old state. To clear values, set them to "undefined" (which will cause
    //       the defaults to be loaded).
    accountAlertLastObservation: state.accountAlertLastObservation,
    accountAlertMuted: state.accountAlertMuted,
    activeDynamicRoute: state.activeDynamicRoute,
    activeInstrumentsOrders: undefined,
    activeStaticRoute: state.activeStaticRoute,
    apiKeys: undefined,
    app: {
      initialLoading: false,
      theme: state.app.theme,
      systemTimeDrift: state.app.systemTimeDrift,
      systemTimeError: state.app.systemTimeError,
    },
    balances: undefined,
    buySellWidget: state.buySellWidget,
    nextPair: state.nextPair,
    currentPair: state.currentPair,
    customer: undefined,
    document: state.document,
    env: state.env,
    graph: state.graph,
    idleTimeoutCountdownActive: state.idleTimeoutCountdownActive,
    instrumentsConfig: undefined,
    lastUsedEmail: state.lastUsedEmail,
    marketDepth: state.marketDepth,
    marketTradesCurrentInstrumentPair: state.marketTradesCurrentInstrumentPair,
    ordersById: undefined,
    ordersPagination: undefined,
    pairsConfig: undefined,
    phoneVerification: undefined,
    priceSummary24h: state.priceSummary24h,
    rates: state.rates,
    registrationPolicy: state.registrationPolicy,
    kyc1Policy: state.kyc1Policy,
    requestActive: state.requestActive,
    securityEvents: undefined,
    session: undefined,
    sessions: undefined,
    sessionTerminatedReason: state.sessionTerminatedReason,
    transactions: undefined,
    user: undefined,
    userLastSeenTs: state.userLastSeenTs,
    userTrades: undefined,
    userTradesCurrentInstrumentPair: undefined,
    verificationEmailRecentlyRequested: undefined,
    exchangeInitiated: undefined,
  });
  dispatch(commit('Clean up system state from user-based data', val(cleanState)));

  // Load fresh data
  dispatch(loadAppData());

  return session;
};

/**
 * Since unauthenticate requests are running in background, we don't want to show needless toasts to people if something goes wrong.
 */
const unauthenticateErrorHandler = (logger: Logger) => (err: HttpErrorResponse) => {
  if (err.name === 'SessionTerminatedError') {
    logger.verbose(`Unauthenticate request has returned a SessionTerminatedError`, err);
  } else {
    logger.error(`Error while trying to unauthenticate`, err);
  }
};

export const logout = (): IThunkMethod => (
  dispatch,
  getState,
  { matomo, history, http, api, logger }
) => {
  if (isUserLoggedIn(getState())) {
    // Send matomo event
    matomo.sendEvent('Session', 'logout');
  }

  // Clean up logged in data
  const loggedOutSession = dispatch(unauthenticate());

  if (loggedOutSession) {
    // NOTE: Give it some time for other logout handlers to have time to respond. This is a low-priority request
    setTimeout(() => {
      http
        .customAuthRequest(loggedOutSession.access_token, api.putAuthLogoutSpec())
        .catch(unauthenticateErrorHandler(logger));
    }, 500);
  }

  // Go to some anonymous route. We are doing this manually because we don't want to show LogIn modal
  // NOTE: We want to unauthenticate first, so that we load anonymous version of data
  const redirectRoute = dispatch(
    findStaticRedirectRoute((route) => !route.dynamic && !route.secured)
  );
  history.push(redirectRoute);
};

export const terminateSession = (
  sessionTerminatedReason?: ISessionTerminationReason,
  customLogoutMethodSpec?: IApiXcalibraClientRequest
): IThunkMethod => (dispatch, getState, { matomo, http, api, logger }) => {
  if (isUserLoggedIn(getState())) {
    // Send matomo event
    matomo.sendEvent('Session', 'session_terminated');
  }

  if (sessionTerminatedReason) {
    // Set the session terminated reason to show
    dispatch(setSessionTerminatedReason(sessionTerminatedReason));
  }

  // Clean up logged in data
  const unauthenticatedSession = dispatch(unauthenticate());

  // Tell the server
  if (unauthenticatedSession) {
    const unauthenticateMethodSpec = customLogoutMethodSpec || api.putAuthLogoutSpec();
    http
      .customAuthRequest(unauthenticatedSession.access_token, unauthenticateMethodSpec)
      .catch(unauthenticateErrorHandler(logger));
  }
};

export const isActiveDynamicRoute = (state: IState, route: IRouteSpec) => {
  return state.activeDynamicRoute && state.activeDynamicRoute.routeId === route.id;
};

export const showSessionTerminatedScreen = (
  sessionTerminatedReason: ISessionTerminationReason
): IThunkMethod => (dispatch, getState) => {
  // Set the session terminated reason to show
  dispatch(setSessionTerminatedReason(sessionTerminatedReason));

  if (!isActiveDynamicRoute(getState(), R.SESSION_TERMINATED)) {
    // Go to the route which will show the session terminated dialog
    dispatch(navigateToDynamicRoute(R.SESSION_TERMINATED));
  }
};
