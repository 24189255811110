import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import CountryLabel from '../../widgets/CountryLabel';

const $ManagedCountries = styled.div<{ columns?: number }>`
  display: grid;
  max-width: ${(p) => `${p.columns * 12}rem`};
  grid-template-columns: ${(p) =>
    Array.from(new Array(p.columns))
      .map((x) => '1fr')
      .join(' ')};
  grid-gap: 0.2rem;
`;

interface IProps extends ConnectedProps<typeof connector> {
  columns?: number;
}

const ManagedCountries: React.FC<IProps> = ({ managedCountryWhitelist, columns = 3 }) => {
  return (
    <$ManagedCountries columns={columns}>
      {managedCountryWhitelist.map((country) => (
        <CountryLabel isoCode={country} label={true} />
      ))}
    </$ManagedCountries>
  );
};

const connector = connect((state: IState) => ({
  managedCountryWhitelist: state.env.managed.countryWhitelist,
}));

export default connector(ManagedCountries);
