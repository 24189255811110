import * as React from 'react';

import * as svgIconExports from '../../../media/svg_icons';
import { BoxIcon } from '../../../media/svg_icons';
import { HideMobile } from '../../utility_components';
import Header from '../../widgets/Header';
import { $HeaderWrapper } from '../../widgets/PageHeaderWrappers';

interface IDevPageProps {}
interface IDevPageState {}

interface IDevIcon {
  name: string;
  Icon;
}

const ICONS: IDevIcon[] = ((svgIconExports) => {
  const icons: IDevIcon[] = [];
  for (const name in svgIconExports) {
    if (/Icon$/.test(name)) {
      icons.push({ name, Icon: svgIconExports[name] });
    }
  }
  return icons;
})(svgIconExports);

const DevIcon = ({ name, Icon }) => {
  return (
    <div
      key={name.toString()}
      style={{
        margin: '5px 0',
        display: 'flex',
        flexDirection: 'column',
        padding: 5,
        alignItems: 'center',
      }}
    >
      <Icon width="5em" height="5em" />

      <input
        onClick={(e) => e.currentTarget.select()}
        style={{ width: 200, marginTop: 5, textAlign: 'center' }}
        defaultValue={name.toString()}
      />
    </div>
  );
};

class DevIcons extends React.PureComponent<IDevPageProps, IDevPageState> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <HideMobile>
          <$HeaderWrapper>
            <Header icon={BoxIcon} title="Icons" variant="dark" />
          </$HeaderWrapper>
        </HideMobile>

        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {ICONS.map((icon) => (
            <DevIcon {...icon} key={icon.name} />
          ))}
        </div>
      </div>
    );
  }
}

export default DevIcons;
