import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { ICsvDownloadFunctions, downloadCSV } from '../../../actions/download_csv';
import {
  cancelWithdrawal,
  loadWithdrawalWindowReportForInstrument,
  loadWithdrawalsForInstrument,
  resetUserWithdrawals,
} from '../../../actions/transactions/withdrawals';
import { IState } from '../../../lib/store';
import { IThemeColorsUnion } from '../../../lib/styled_components';
import { formatFullDate, formatFullDateRegular } from '../../../lib/util';
import { CSVIcon } from '../../../media/svg_icons';
import { getBalances } from '../../../selectors/balances';
import { getInstrumentsConfig } from '../../../selectors/exchange';
import { isRequestActive } from '../../../selectors/request_active';
import {
  IWithdrawalFixed,
  IWithdrawalsPaginationState,
  createWithdrawalsHorizontalScrollTableData,
  getWithdrawalStatus,
  getWithdrawalTime,
  getWithdrawals,
} from '../../../selectors/transactions';
import { IInstrument } from '../../../types/backend_definitions';
import { II18nextT } from '../../../types/i18n';
import { IInstrumentsConfig } from '../../../types/pairs_instruments_config';
import { IWithdrawal, IWithdrawalStatus } from '../../../types/transactions';
import { ITranslations } from '../../../types/translations';
import { $ErrorSmallButton } from '../../widgets/buttons';
import $ColorText from '../../widgets/ColorText';
import Header from '../../widgets/Header';
import HorizontalScrollTable, {
  $FixedHeightCell,
  $FixedHeightTableRow,
  $HighlightableTableRow,
  IGridColumnProps,
  IScrollableRow,
  ITableParts,
} from '../../widgets/HorizontalScrollTable';
import { $ToolbarLabel } from '../../widgets/Label';
import {
  $ActionElementsWrapper,
  $HeaderWrapperJustified,
  $InnerWrapper,
  $NoDataWrapper,
  $Wrapper,
  DownloadCSVButton,
  IDownloadCSVAction,
} from '../../widgets/Reports';
import { ResponsiveSelectWrapper, SimpleSelect } from '../../widgets/Select';
import { $DottedSpan, $Span } from '../../widgets/Span';
import { $Cell, $EllipsisWrapper, ICellProps } from '../../widgets/Table';
import TableActionIndicator from '../../widgets/TableActionIndicator';
import ClickableTooltipWithCopy from '../../widgets/tooltips/ClickableTooltipWithCopy';
import ClickableTooltipWrapper from '../../widgets/tooltips/ClickableTooltipWrapper';

interface IWithdrawalStatusData {
  color: IThemeColorsUnion;
  message: ITranslations;
}

type ICellNames =
  | 'id'
  | 'transactionId'
  | 'targetAddress'
  | 'status'
  | 'quantity'
  | 'fee'
  | 'date'
  | 'buttons';

const CELLS: { [key in ICellNames]?: Partial<ICellProps> } = {
  date: { align: 'center', minWidth: '9rem' },
  transactionId: { align: 'center', minWidth: '9rem' },
  targetAddress: { align: 'center', minWidth: '9rem' },
  status: { align: 'center', minWidth: '8.5rem' },
  quantity: { align: 'center', minWidth: '7rem' },
  fee: { align: 'center', minWidth: '6rem' },
  id: { align: 'center', minWidth: '4.5rem' },
  buttons: { align: 'center', minWidth: '5rem' },
};

const GRID_COLUMNS: IGridColumnProps<ICellNames> = {
  fixed: { date: { width: '1fr' } },
  scrollable: {
    transactionId: { width: '2fr' },
    targetAddress: { width: '2fr' },
    status: { width: '1fr' },
    quantity: { width: '1fr' },
    fee: { width: '1fr' },
    id: { width: '4.5rem' },
    buttons: { width: '5rem' },
  },
};

const WITHDRAWAL_STATUS_DATA: { [key in IWithdrawalStatus]: IWithdrawalStatusData } = {
  failed: { color: 'sell', message: 'transactions:withdrawals.statuses.failed' },
  cancelled: { color: 'gray', message: 'transactions:withdrawals.statuses.cancelled' },
  completed: { color: 'success', message: 'transactions:withdrawals.statuses.completed' },
  more_info: { color: 'warning', message: 'transactions:withdrawals.statuses.moreInfo' },
  unconfirmed: { color: 'gray', message: 'transactions:withdrawals.statuses.unconfirmed' },
  pending: { color: 'grayLighter', message: 'transactions:withdrawals.statuses.pending' },
  processing: { color: 'info', message: 'transactions:withdrawals.statuses.processing' },
};

interface IWithdrawalsProps extends RouteComponentProps, ConnectedProps<typeof connector> {
  stickHeader: boolean;
  getTableHeaderTopOffset?: (topOffset) => void;
  downloadCSV: IDownloadCSVAction;
  ICsvDownloadFunctions: ICsvDownloadFunctions;
}

type IFilterInstrument = IInstrument & 'all';

interface IWithdrawalsState {
  filterInstrument: IFilterInstrument;
  filterOptions: Object;
  loading: boolean;
}

class Withdrawals extends React.PureComponent<IWithdrawalsProps, IWithdrawalsState> {
  static contextType: any = I18nContext;
  private mounted: boolean = false;
  private recentWithdrawal: boolean = false;
  private recentWithdrawalId: number = 0;

  constructor(props) {
    super(props);
    this.state = {
      filterInstrument: 'all' as IFilterInstrument,
      filterOptions: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.props.resetUserWithdrawals();
    this.setState({ filterOptions: this.deriveFilterOptions(this.props.instrumentsConfig) });
    this.onLoadWithdrawalsForInstrument(this.state.filterInstrument, true);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onLoadWithdrawalsForInstrument = (instrument?: IFilterInstrument, firstPage?: boolean) => {
    this.setState({
      loading: true,
    });
    this.props.loadWithdrawalsForInstrument(instrument, firstPage).finally(() => {
      this.mounted && this.setState({ loading: false });
    });
  };

  componentDidUpdate(
    prevProps: Readonly<IWithdrawalsProps>,
    prevState: Readonly<IWithdrawalsState>,
    snapshot?: any
  ): void {
    if (prevState.filterInstrument !== this.state.filterInstrument) {
      this.onLoadWithdrawalsForInstrument(this.state.filterInstrument, false);
      this.state.filterInstrument !== 'all' &&
        this.props.loadWithdrawalWindowReportForInstrument(this.state.filterInstrument);
    }
  }

  deriveFilterOptions(config: IInstrumentsConfig) {
    return Object.keys(config).reduce(
      (res, key) => {
        res[key] = { value: config[key].instrument, label: config[key].instrument };
        return res;
      },
      { all: { value: 'all', label: this.context.t('all') } }
    );
  }

  renderWithdrawalStatus = (withdrawal: IWithdrawal) => {
    const { t }: II18nextT = this.context;

    const status = getWithdrawalStatus(withdrawal);
    const data = WITHDRAWAL_STATUS_DATA[status];

    if (status === 'more_info') {
      return (
        <ClickableTooltipWrapper color={data.color} overlay={withdrawal.more_info}>
          {t(data.message)}
        </ClickableTooltipWrapper>
      );
    }

    return <$ColorText color={data.color}>{t(data.message)}</$ColorText>;
  };

  handleCancelWithdrawal = (withdrawalId: number, instrument: IInstrument) => {
    return Promise.resolve()
      .then(() => {
        return this.props.cancelWithdrawalInProcess || this.props.cancelWithdrawal(withdrawalId);
      })
      .then(() => this.props.loadWithdrawalWindowReportForInstrument(instrument));
  };

  getWithdrawalsPaginationStateByInstrument(): IWithdrawalsPaginationState {
    const withdrawalsByInstrument = this.props.withdrawals[this.state.filterInstrument];
    return createWithdrawalsHorizontalScrollTableData(withdrawalsByInstrument);
  }

  renderTableParts(): ITableParts {
    const { t }: II18nextT = this.context;
    const withdrawalsPaginationState = this.getWithdrawalsPaginationStateByInstrument();

    return {
      header: {
        fixed: () => <$FixedHeightCell {...CELLS.date}>{t('date')}</$FixedHeightCell>,
        scrollable: () => {
          const instrument =
            this.state.filterInstrument === 'all'
              ? { type: 'crypto' }
              : this.props.balances[this.state.filterInstrument];
          return (
            <>
              <$FixedHeightCell {...CELLS.transactionId}>
                {t('transactions:withdrawals.transactionId')}
              </$FixedHeightCell>
              <$FixedHeightCell {...CELLS.targetAddress}>
                {t(
                  instrument.type === 'crypto'
                    ? 'transactions:withdrawals.crypto.addressTitle'
                    : 'transactions:withdrawals.fiat.addressTitle'
                )}
              </$FixedHeightCell>
              <$FixedHeightCell {...CELLS.status}>{t('transactions:status')}</$FixedHeightCell>
              <$FixedHeightCell {...CELLS.quantity}>{t('amount')}</$FixedHeightCell>
              <$FixedHeightCell {...CELLS.fee}>{t('fee')}</$FixedHeightCell>
              <$FixedHeightCell {...CELLS.id}>{t('transactions:withdrawals.id')}</$FixedHeightCell>
              <$FixedHeightCell {...CELLS.buttons}>&nbsp;</$FixedHeightCell>
            </>
          );
        },
      },
      body: {
        fixed: (highlightedRowIndex, highlightSelectedRow) => {
          return withdrawalsPaginationState.items.fixed.map(
            (withdrawalFixed: IWithdrawalFixed, index) => {
              const withdrawalTime = getWithdrawalTime(withdrawalFixed as IWithdrawal);

              return (
                <$HighlightableTableRow
                  highlighted={index === highlightedRowIndex}
                  key={index}
                  recentWithdrawal={withdrawalFixed.id === this.recentWithdrawalId}
                  className={
                    this.recentWithdrawal && withdrawalFixed.id === this.recentWithdrawalId
                      ? 'Recent_Withdrawal'
                      : ''
                  }
                  onMouseOver={() => highlightSelectedRow(index)}
                >
                  <$Cell {...CELLS.date}>
                    <b title={formatFullDate(withdrawalTime)}>
                      {formatFullDateRegular(withdrawalTime)}
                    </b>
                  </$Cell>
                </$HighlightableTableRow>
              );
            }
          );
        },
        scrollable: (withdrawal: IWithdrawal, index, highlightSelectedRow) =>
          this.renderScrollableRow(withdrawal, index, highlightSelectedRow),
      },
    };
  }

  renderScrollableRow: IScrollableRow = (withdrawal: IWithdrawal, index, highlightSelectedRow) => {
    const { t }: II18nextT = this.context;
    const { cancelWithdrawalInProcess } = this.props;

    return (
      <$FixedHeightTableRow onMouseOver={() => highlightSelectedRow(index)} key={withdrawal.id}>
        <$Cell {...CELLS.transactionId}>
          <ClickableTooltipWithCopy color="white" tooltipText={withdrawal.transaction_id}>
            <$EllipsisWrapper>
              <$DottedSpan color="white" monospace>
                {withdrawal.transaction_id}
              </$DottedSpan>
            </$EllipsisWrapper>
          </ClickableTooltipWithCopy>
        </$Cell>
        <$Cell {...CELLS.targetAddress}>
          <ClickableTooltipWithCopy color="white" tooltipText={withdrawal.target_address}>
            <$EllipsisWrapper>
              <$DottedSpan color="white" monospace>
                {withdrawal.target_address}
              </$DottedSpan>
            </$EllipsisWrapper>
          </ClickableTooltipWithCopy>
        </$Cell>
        <$Cell {...CELLS.status}>
          <strong>{this.renderWithdrawalStatus(withdrawal as IWithdrawal)}</strong>
        </$Cell>
        <$Cell {...CELLS.quantity}>
          <b>{withdrawal.quantity}</b> {withdrawal.instrument}
        </$Cell>
        <$Cell {...CELLS.fee}>
          <b>{withdrawal.fee_total}</b> {withdrawal.instrument}
        </$Cell>

        <$Cell {...CELLS.id}>
          <$Span monospace>{withdrawal.id}</$Span>
        </$Cell>
        <$Cell {...CELLS.buttons}>
          {!withdrawal.cancelled_at && !withdrawal.completed_at && !withdrawal.taken_at && (
            <$ErrorSmallButton
              disabled={cancelWithdrawalInProcess}
              onClick={() => this.handleCancelWithdrawal(withdrawal.id, withdrawal.instrument)}
            >
              {t('button.cancel')}
            </$ErrorSmallButton>
          )}
        </$Cell>
      </$FixedHeightTableRow>
    );
  };

  render() {
    const { t }: II18nextT = this.context;
    const withdrawalsPaginationState = this.getWithdrawalsPaginationStateByInstrument();
    const criteria =
      this.state.filterInstrument !== 'all'
        ? {
            instrument: this.state.filterInstrument,
            sort_direction: ['desc'],
            sort_field: ['created_at', 'id'],
          }
        : { sort_direction: ['desc'], sort_field: ['created_at', 'id'] };

    return (
      <$Wrapper>
        <$HeaderWrapperJustified stickHeader={this.props.stickHeader}>
          <Header
            title={t('reportsPage.withdrawals')}
            icon={CSVIcon}
            hideOnMobile={true}
            loadingKeys={['loadWithdrawalsForInstrument', 'cancelWithdrawal']}
          />
          {this.state.filterOptions && (
            <$ActionElementsWrapper>
              <div>
                <$ToolbarLabel htmlFor="withdrawalsInstrument">{t('instrument')}:</$ToolbarLabel>
                <ResponsiveSelectWrapper>
                  <SimpleSelect
                    inputId="withdrawalsInstrument"
                    width="8rem"
                    options={Object.values(this.state.filterOptions)}
                    value={this.state.filterOptions[this.state.filterInstrument]}
                    onChange={({ value }) => {
                      this.setState({ filterInstrument: value as IFilterInstrument });
                    }}
                    variant="transparent"
                  />
                </ResponsiveSelectWrapper>
              </div>
              <div>
                <DownloadCSVButton
                  downloadCsv={this.props.downloadCSV}
                  csvDownloadFunction={'getWithdrawalsCsv'}
                  criteria={criteria}
                />
              </div>
            </$ActionElementsWrapper>
          )}
        </$HeaderWrapperJustified>

        {this.state.loading ? (
          <div />
        ) : !withdrawalsPaginationState.total_records ? (
          <$NoDataWrapper>{t('reportsPage.noData.withdrawals')}</$NoDataWrapper>
        ) : (
          <$InnerWrapper>
            <HorizontalScrollTable
              data={withdrawalsPaginationState.items.scrollable}
              gridColumns={GRID_COLUMNS}
              stickHeader={this.props.stickHeader}
              getTableHeaderTopOffset={this.props.getTableHeaderTopOffset}
              tableParts={this.renderTableParts()}
            >
              <TableActionIndicator
                paginationState={withdrawalsPaginationState}
                requests={['loadWithdrawalsForInstrument']}
                itemsCount={withdrawalsPaginationState.items.length}
                actionFn={() =>
                  this.props.loadWithdrawalsForInstrument(this.state.filterInstrument)
                }
              />
            </HorizontalScrollTable>
          </$InnerWrapper>
        )}
      </$Wrapper>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    balances: getBalances(state),
    withdrawals: getWithdrawals(state),
    cancelWithdrawalInProcess: isRequestActive('cancelWithdrawal', state),
    instrumentsConfig: getInstrumentsConfig(state),
  }),
  {
    cancelWithdrawal,
    loadWithdrawalsForInstrument,
    resetUserWithdrawals,
    loadWithdrawalWindowReportForInstrument,
    downloadCSV,
  }
);

export default withRouter(connector(Withdrawals));
