import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { goToExchange, goToLogin } from '../../../actions/routing';
import { confirmWithdrawal } from '../../../actions/transactions/withdrawals';
import { ITranslatedError, ValidationSingleMessage } from '../../../lib/errors';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { isUserLoggedIn } from '../../../selectors/auth';
import { IApiCensoredWithdrawal } from '../../../types/backend_definitions';
import { TimeUnits } from '../../../types/constants';
import { II18nextT } from '../../../types/i18n';
import { $SuccessMessage } from '../../layout/KioskLayout';

const $Wrapper = styled.div`
  width: 340px;
  max-width: 100%;
`;

interface IWithdrawalConfirmationProps
  extends RouteComponentProps<{ token: string }>,
    ConnectedProps<typeof connector> {}

interface IWithdrawalConfirmationState {
  error: ITranslatedError;
  withdrawal: IApiCensoredWithdrawal;
}

class WithdrawalConfirmationScreen extends React.PureComponent<
  IWithdrawalConfirmationProps,
  IWithdrawalConfirmationState
> {
  mounted: boolean;
  static contextType: any = I18nContext;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      withdrawal: null,
    };
  }

  componentDidMount() {
    this.mounted = true;

    const { token } = this.props.match.params;

    if (token) {
      this.props
        .confirmWithdrawal(token)
        .then((withdrawal) => {
          if (this.mounted) {
            this.setState({ withdrawal });
            setTimeout(() => {
              if (!this.mounted) {
                return;
              }

              return this.props.loggedIn ? this.props.goToExchange() : this.props.goToLogin();
            }, TimeUnits.second * 5);
          }
        })
        .catch((error) => this.mounted && this.setState({ error }));
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { t }: II18nextT = this.context;
    if (this.state.withdrawal) {
      return (
        <$Wrapper>
          <$SuccessMessage>
            <p>{t('transactions:withdrawals.confirmation.success', this.state.withdrawal)}</p>
            <p>
              {t(
                this.props.loggedIn
                  ? 'transactions:withdrawals.confirmation.youWillBeRedirectedToExchange'
                  : 'transactions:withdrawals.confirmation.youWillBeRedirectedToLogin'
              )}
            </p>
          </$SuccessMessage>
        </$Wrapper>
      );
    }

    return (
      <$Wrapper>
        <ValidationSingleMessage error={this.state.error} />
      </$Wrapper>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    loggedIn: isUserLoggedIn(state),
  }),
  {
    confirmWithdrawal,
    goToExchange,
    goToLogin,
  }
);

export default withRouter(connector(WithdrawalConfirmationScreen));
