import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { IState } from '../../lib/store';
import styled from '../../lib/styled_components';

const $ScrollBox = styled.div`
  position: relative;
  flex-grow: 1;
  overflow-y: overlay;
  overflow-x: hidden;
`;

export interface IOnScrollDetails {
  scrollTop?: number;
  scrollHeight?: number;
  clientHeight?: number;
}

export interface IOnScrollData {
  position?: number;
  target?: IOnScrollDetails;
}

interface IScrollBoxProps extends ConnectedProps<typeof connector> {
  disabled?: boolean;
  height?: string;
  mobileHeight?: string;
  laptopHeight?: string;
  onScroll?: (data: IOnScrollData) => void;
  onReachedBottom?: () => any;
  children?: any;
}
interface IScrollBoxState {
  windowWidth: number;
}

class ScrollBox extends React.PureComponent<IScrollBoxProps, IScrollBoxState> {
  private mounted: boolean;

  constructor(props) {
    super(props);

    this.state = {
      windowWidth: props.windowWidth,
    };
  }

  private element: React.RefObject<any> = React.createRef();

  handleScroll = (e) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight

    // If onScroll is not passed as prop, return
    if (!this.props.onScroll) {
      return;
    }

    const availableScrollHeight = e.target.scrollHeight - e.target.clientHeight;

    if (availableScrollHeight === 0) {
      return this.props.onScroll({ position: 1, target: e.target });
    }
    // Position is on scale 0-1
    const position = Math.ceil(e.target.scrollTop) / availableScrollHeight;
    this.props.onScroll({ position, target: e.target });
  };

  scrollToBottom = () => {
    const el = this.element.current;
    if (!el) {
      return;
    }

    const newScrollTop = Math.ceil(el.scrollHeight - el.clientHeight);
    // TODO: Performance: XCAL-874
    // console.error(`[scrollTop] ${el.scrollTop} -> ${newScrollTop}`);
    el.scrollTop = newScrollTop;
  };

  componentWillMount(): void {
    this.mounted = true;
    this.props.onWindowResize((newWidth) => {
      if (this.mounted) {
        this.setState({
          windowWidth: newWidth,
        });
      }
    });
  }

  componentWillUnmount(): void {
    this.mounted = false;
    this.props.offWindowResize();
  }

  render() {
    const {
      disabled,
      height,
      mobileHeight,
      mobileBreakpoint,
      laptopHeight,
      laptopBreakpoint,
    } = this.props;
    const windowWidth = this.state.windowWidth;

    const fixedHeight =
      mobileHeight !== undefined && windowWidth <= mobileBreakpoint
        ? mobileHeight
        : laptopHeight !== undefined && windowWidth <= laptopBreakpoint
        ? laptopHeight
        : height;

    const style = disabled
      ? fixedHeight !== undefined
        ? { overflow: 'hidden', height: fixedHeight }
        : { overflow: 'hidden' }
      : fixedHeight !== undefined
      ? { height: fixedHeight }
      : undefined;

    return (
      <$ScrollBox style={style} onScroll={this.handleScroll} ref={this.element}>
        {this.props.children}
      </$ScrollBox>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    laptopBreakpoint: state.app.theme.base.laptopBreakpoint,
    mobileBreakpoint: state.app.theme.base.mobileBreakpoint,
    windowWidth: window.innerWidth,
  }),
  () => {
    let handler;

    const offWindowResize = () => {
      if (handler) {
        window.removeEventListener('resize', handler);
        handler = null;
      }
    };

    return {
      onWindowResize: (fn) => {
        offWindowResize();
        handler = () => {
          fn(window.innerWidth);
        };
        window.addEventListener('resize', handler);
      },
      offWindowResize,
    };
  },
  null,
  {
    forwardRef: true,
  }
);

export default connector(ScrollBox);
