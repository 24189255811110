import * as React from 'react';

import styled, { IThemeFontsUnion, css } from '../../lib/styled_components';
import { hex2rgba } from '../../lib/util';

export interface IInputProps {
  height?: string;
  background?: string;
  border?: string;
  borderFocus?: string;
  color?: string;
  fontSize?: string;
  fontFamily?: IThemeFontsUnion;
  placeholderColor?: string;
  padding?: string;
  fontWeight?: string;
  centered?: boolean;
  hasError?: boolean;
}

export const $Input = styled.input.attrs({ spellCheck: false })<IInputProps>`
  width: 100%;
  height: ${(p) => p.height};
  text-align: ${(p) => (p.centered ? 'center' : 'left')};
  color: ${(p) => (p.color ? p.color : p.theme.base.color)};
  background: ${(p) => (p.background ? p.background : p.theme.widgets.input.light.background)};
  font-size: ${(p) => (p.fontSize ? p.fontSize : p.theme.widgets.input.light.fontSize)};
  border: ${(p) => (p.border ? p.border : p.theme.widgets.input.light.border)};
  padding: ${(p) => (p.padding ? p.padding : p.theme.widgets.input.light.padding)};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : p.theme.widgets.input.light.fontWeight)};
  font-family: ${(p) => (p.fontFamily ? p.theme.fontFamily[p.fontFamily] : 'inherit')};

  &:read-only {
    filter: saturate(0.8);
    background: ${(p) =>
      hex2rgba(p.background ? p.background : p.theme.widgets.input.light.background, 0.5)};
    color: ${(p) => hex2rgba(p.color || p.theme.base.color, 0.5)};
    cursor: default;
  }

  ${(p) =>
    p.hasError &&
    css`
      border-color: ${p.theme.colors.error};
    `};

  /* remove default outline when element is in focus */
  &:focus {
    outline: none;
    border: ${(p) => (p.borderFocus ? p.borderFocus : p.theme.widgets.input.light.borderFocus)};
  }
  &::placeholder {
    color: ${(p) =>
      p.placeholderColor ? p.placeholderColor : p.theme.widgets.input.light.placeholderColor};
    opacity: 0.7;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  @media screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    font-size: ${(p) => p.theme.widgets.input.iphonePlus.fontSize} !important;
    line-height: ${(p) => p.theme.widgets.input.iphonePlus.fontSize};
  }
`;

export const $InputDark = styled($Input).attrs((p) => ({
  background: p.theme.widgets.input.dark.background,
  fontSize: p.theme.widgets.input.dark.fontSize,
  placeholderColor: p.theme.widgets.input.dark.placeholderColor,
  border: p.theme.widgets.input.dark.border,
  borderFocus: p.theme.widgets.input.dark.borderFocus,
  padding: p.theme.widgets.input.dark.padding,
  fontWeight: p.theme.widgets.input.dark.fontWeight,
}))``;

export const $ReadOnlyInput: React.FunctionComponent<{ value: string } & IInputProps> = (props) => (
  <$Input
    readOnly
    value={props.value}
    size={props.value ? props.value.length + 10 : 50}
    border="none"
    fontWeight="600"
    fontFamily="monospace"
    padding="0"
    style={{ cursor: 'pointer' }}
    onClick={(e) => e.currentTarget.select()}
    {...props}
  />
);

export const $ReadOnlyInputDark: React.FunctionComponent<
  { value: string } & { style?: React.CSSProperties } & IInputProps
> = (props) => (
  <$InputDark
    readOnly
    value={props.value}
    size={props.value ? props.value.length : 50}
    border="none"
    fontWeight="600"
    fontFamily="monospace"
    style={{ cursor: 'pointer' }}
    onClick={(e) => e.currentTarget.select()}
    {...props}
  />
);
