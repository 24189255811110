import { loadUserSessionData } from '../actions/app';
import { submitUser } from '../actions/auth/auth';
import { handleError, rethrowTranslated } from '../lib/errors';
import { commit, val } from '../lib/redux_helpers';
import { IThunkMethod } from '../lib/store';
import { getUser, isUserLoggedIn } from '../selectors/auth';
import {
  IApiEmailVerificationCodePayload,
  IApiUserEmailVerifiedEventData,
} from '../types/backend_definitions';
import { TimeUnits } from '../types/constants';

const VERIFICATION_EMAIL_REQUEST_COOLDOWN = 1 * TimeUnits.minute;

export interface IEmailVerificationState {
  verificationEmailRecentlyRequested: boolean;
}

export const EMAIL_VERIFICATION_STATE: IEmailVerificationState = {
  verificationEmailRecentlyRequested: false,
};

export const requestVerificationEmail = (
  userInitiated: boolean
): IThunkMethod<Promise<boolean>> => (dispatch, getState, { api, matomo, logger }) => {
  if (getState().verificationEmailRecentlyRequested) {
    logger.error(`User asked for another verification email before timer was out`);
    return Promise.resolve(false);
  }

  matomo.sendEvent('Onboarding', 'request_verification_email');

  return api.putEmailVerificationRequest().then(
    (user) => {
      dispatch(submitUser(user));

      setTimeout(() => {
        dispatch(
          commit(`Allow requesting verification emails again`, {
            verificationEmailRecentlyRequested: val(false),
          })
        );
      }, VERIFICATION_EMAIL_REQUEST_COOLDOWN);

      if (userInitiated) {
        dispatch(
          commit(
            `Disable requesting verification emails for ${VERIFICATION_EMAIL_REQUEST_COOLDOWN}ms`,
            {
              verificationEmailRecentlyRequested: val(true),
            }
          )
        );
      }

      return true;
    },
    (err) => dispatch(handleError(err))
  );
};

export const verifyEmailVerificationToken = (token: string): IThunkMethod<Promise<unknown>> => (
  dispatch,
  _,
  { api }
) => api.putEmailVerificationExecute(token).catch((err) => dispatch(rethrowTranslated(err)));

export const setEmailVerfiedStatus = (eventData: IApiUserEmailVerifiedEventData): IThunkMethod => (
  dispatch,
  getState,
  { matomo }
) => {
  const state = getState();
  if (isUserLoggedIn(state)) {
    const user = getUser(state);
    if (user.id === eventData.user_id) {
      dispatch(
        submitUser({
          ...user,
          email_verified_at: eventData.email_verified_at,
        })
      );

      // Load new user configs
      dispatch(loadUserSessionData());
    }
  }

  matomo.sendEvent('Onboarding', 'verify_email');
};

export const submitEmailVerificationCode = (
  payload: IApiEmailVerificationCodePayload
): IThunkMethod<Promise<unknown>> => (dispatch, _, { api }) =>
  api.putEmailVerificationCode(payload).catch((err) => dispatch(handleError(err)));
