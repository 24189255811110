import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { login, shouldAskCaptchaForLogin } from '../../../actions/auth/login';
import { navigateAwayFromActiveDynamicRoute } from '../../../actions/routing';
import {
  ITranslatedError,
  ValidationSingleMessage,
  ValidationWrapper,
  clientValidator,
} from '../../../lib/errors';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { isRequestActive } from '../../../selectors/request_active';
import { TimeUnits } from '../../../types/constants';
import { II18nextT } from '../../../types/i18n';
import { $CodeInputDark } from '../../widgets/CodeInput';
import { $InputDark } from '../../widgets/Input';
import {
  $AccountPagesButton,
  $AccountPagesInputWrapper,
  $AccountPagesLinks,
  RegisterLink,
  RequestPasswordResetLink,
} from './auth_components';
import Recaptcha from './Recaptcha';

const $ValidationMessageWrapper = styled.div`
  > * {
    margin-bottom: 1.5rem;
  }
`;

const $AuthButton = styled($AccountPagesButton)`
  margin: 1em 0;
`;

const $RecaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5em 0;
`;

const $Form = styled.form`
  width: ${(p) => p.theme.widgets.loginForm.width}
  max-width: 100%;
`;

interface ILoginFormProps extends ConnectedProps<typeof connector> {
  initialEmail?: string;
}

interface ILoginFormState {
  email: string;
  password: string;
  tfaStage: boolean;
  tfaToken: string;
  formErrors: { email: string; password: string };
  error: ITranslatedError;
  emailValid: boolean;
  passwordValid: boolean;
  formValid: boolean;
  disableButton: boolean;
  renderCaptcha: boolean;
  captchaRendered: boolean;
  captchaLoaded: boolean;
  captchaToken: string;
}

class LoginForm extends React.PureComponent<ILoginFormProps, ILoginFormState> {
  private mounted = false;
  static contextType: any = I18nContext;
  captcha: React.RefObject<Recaptcha> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      email: props.initialEmail || '',
      password: '',
      tfaStage: false,
      tfaToken: '',
      formErrors: { email: '', password: '' },
      error: null,
      emailValid: true,
      passwordValid: true,
      formValid: true,
      disableButton: false,
      renderCaptcha: false,
      captchaRendered: false,
      captchaLoaded: false,
      captchaToken: null,
    };
  }

  setCaptcha() {
    return this.props.shouldAskCaptchaForLogin().then((shouldAskCaptcha) => {
      if (this.mounted && shouldAskCaptcha) {
        this.setState({ renderCaptcha: true, disableButton: true });
      }
    });
  }

  componentDidMount() {
    this.mounted = true;
    if (!this.state.renderCaptcha) {
      this.setState({ disableButton: false });
    }

    this.setCaptcha();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleUserInput = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    this.setState({ [name]: value } as any);
  };

  validateForm = () => {
    return clientValidator(this.state, this.context.t, 'body.')
      .required('email')
      .required('password')
      .test(
        'captchaToken',
        !this.state.renderCaptcha || !!this.state.captchaToken,
        'validation.messages.captchaRequired'
      )
      .onFail((error) => {
        if (!this.mounted) {
          return;
        }

        this.setState({
          error,
        });

        // TODO: Would this be better to only include state.captchaToken?
        setTimeout(
          () => this.mounted && !this.state.captchaRendered && this.setState({ error: null }),
          10 * TimeUnits.second
        );
      })
      .isValid();
  };

  submitForm = (e: React.FormEvent) => {
    e.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    this.setState({ error: null }, () => {
      return this.props
        .login(this.state.email, this.state.password, this.state.tfaToken, this.state.captchaToken)
        .then(() => {
          this.props.navigateAwayFromActiveDynamicRoute();
        })
        .catch((errorResponse: ITranslatedError) => {
          if (this.mounted) {
            switch (errorResponse.name) {
              case 'CaptchaRequiredError':
                this.setState({ error: errorResponse, renderCaptcha: true, disableButton: true });
                break;
              case 'InvalidCaptchaTokenError':
                this.setState({ captchaToken: null });
                break;
              case 'SecondFactorRequiredError':
                this.setState({ tfaStage: true });
                break;
              default:
                this.setState({ error: errorResponse, renderCaptcha: false, captchaToken: null });
            }
          }
        });
    });
  };

  handleCaptchaLoaded = () => {
    this.setState({ captchaLoaded: true }, () => {
      if (this.captcha) {
        this.captcha.current.renderExplicitly().then(() => {
          this.setState({ captchaRendered: true });
        });
      }
    });
  };

  renderLoginStage() {
    if (this.state.tfaStage) {
      return null;
    }

    const { t }: II18nextT = this.context;

    return (
      <>
        <$AccountPagesInputWrapper>
          <ValidationWrapper error={this.state.error} path={'body.email'}>
            <$InputDark
              name="email"
              type="email"
              placeholder={t('login.fields.email')}
              value={this.state.email}
              onChange={this.handleUserInput}
              autoComplete="username"
              autoFocus
            />
          </ValidationWrapper>
        </$AccountPagesInputWrapper>

        <$AccountPagesInputWrapper>
          <ValidationWrapper error={this.state.error} path={'body.password'}>
            <$InputDark
              name="password"
              type="password"
              placeholder={t('login.fields.password')}
              value={this.state.password}
              onChange={this.handleUserInput}
              autoComplete="current-password"
            />
          </ValidationWrapper>
        </$AccountPagesInputWrapper>
      </>
    );
  }

  renderTfaStage() {
    if (!this.state.tfaStage) {
      return null;
    }

    return (
      <$CodeInputDark
        name="tfaToken"
        fontSize="2em"
        value={this.state.tfaToken}
        onChange={this.handleUserInput}
        autoFocus
      />
    );
  }

  renderCaptcha() {
    if (!this.state.renderCaptcha) {
      return null;
    }

    return (
      <$RecaptchaWrapper>
        <ValidationWrapper error={this.state.error} path={'body.captchaToken'}>
          <Recaptcha
            ref={this.captcha}
            siteKey={this.props.recaptchaSiteKey}
            type="checkbox"
            onLoad={this.handleCaptchaLoaded}
            onVerify={(token) => {
              this.setState({ captchaToken: token, disableButton: false });
            }}
          />
        </ValidationWrapper>
      </$RecaptchaWrapper>
    );
  }

  render() {
    const { t }: II18nextT = this.context;
    const { error, formValid, disableButton } = this.state;

    return (
      <$Form onSubmit={this.submitForm}>
        <$ValidationMessageWrapper>
          <ValidationSingleMessage error={error} />
        </$ValidationMessageWrapper>

        {this.renderLoginStage()}
        {this.renderTfaStage()}
        {this.renderCaptcha()}

        <$AccountPagesInputWrapper>
          <$AuthButton
            type="submit"
            disabled={!formValid || disableButton || this.props.loggingUser}
          >
            {t('login.submit')}
          </$AuthButton>
        </$AccountPagesInputWrapper>

        <$AccountPagesLinks>
          <RequestPasswordResetLink />
          <RegisterLink />
        </$AccountPagesLinks>
      </$Form>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    recaptchaSiteKey: state.env.recaptchaSiteKey,
    loggingUser: isRequestActive('postAuthLogin', state),
  }),

  { login, shouldAskCaptchaForLogin, navigateAwayFromActiveDynamicRoute }
);

export default connector(LoginForm);
