import * as React from 'react';

import $Link from '../../widgets/Link';

const ChyngexPP: React.FunctionComponent<{}> = () => {
  return (
    <div
      style={{
        maxWidth: 700,
        margin: '100px auto',
        fontSize: '1.2rem',
        textAlign: 'left',
      }}
    >
      <h1>PRIVACY AND PERSONAL DATA PROTECTION</h1>

      <ol>
        <li>
          <strong>
            Chynge complies with the Singapore Personal Data Protection Act 2012 (the Act) when
            dealing with personal information. Personal information is information about an
            identifiable individual (a natural person). The following sections set out how we will
            collect, use, disclose and protect your personal information. If you wish to seek
            further information on the Act, see
            <$Link
              underlined="true"
              as="a"
              href="https://www.pdpc.gov.sg/Overview-of-PDPA/The-Legislation/Personal-Data-Protection-Act"
              target="_blank"
            >
              {' '}
              https://www.pdpc.gov.sg/Overview-of-PDPA/The-Legislation/Personal-Data-Protection-Act
            </$Link>
          </strong>
          . <br />
        </li>

        <li>
          <strong>This policy sets out how we will treat your personal information.</strong> <br />
        </li>

        <li>
          <strong>What information do we collect?</strong> <br />
          We may collect, store and use the following kinds of personal data: <br />
          <ul>
            <li>
              information about your computer and your visits to and use of this website (including
              your IP address, geographical location, browser type, referral source, length of
              visit, number of page views and searches you make);
            </li>{' '}
            <li>
              information relating to any transactions carried out in this website, or in relation
              to this website, including information relating to any purchases or requests you make
              of third party's goods or services using this website;
            </li>
            <li>Other information that you choose to send to us.</li>
          </ul>
        </li>

        <li>
          <strong>Cookies</strong> <br /> A cookie consists of information sent by a web server to a
          web browser, and stored by the browser. The information is then sent back to the server
          each time the browser requests a page from the server. This enables the web server to
          identify and track the web browser. We may use both “session” cookies and “persistent”
          cookies on the website. We will use the session cookies to: keep track of you whilst you
          navigate the website; and keep you logged in. We will use persistent cookies to: enable
          our website to recognize you when you visit; remember your preferred language; store the
          city that you were last interested in. Session cookies will be deleted from your computer
          when you close your browser. Persistent cookies will remain stored on your computer until
          deleted, or until they reach a specified expiry date. We may use Google Analytics to
          analyze the use of this website. Google Analytics generates statistical and other
          information about website use by means of cookies, which are stored on users' computers.
          The information generated relating to our website is used to create reports about the use
          of the website. Google will store this information. Google's privacy policy is available{' '}
          <$Link underlined="true" as="a" href="http://www.google.com/privacypolicy.html">
            here
          </$Link>
          . Most browsers allow you to refuse to accept cookies. (For example, in Internet Explorer
          you can refuse all cookies by clicking “Tools”, “Internet Options”, “Privacy”, and
          selecting “Block all cookies” using the sliding selector.) This will, however, have a
          negative impact upon the usability of many websites.
        </li>

        <li>
          <strong>Using your personal data</strong> <br />
          Personal data submitted on this website will be used for the purposes specified in this
          privacy policy or in relevant parts of the website. We may use your personal information
          to provide services to you and to:
          <ul>
            <li>administer the website;</li>
            <li>improve your browsing experience by personalizing the website;</li>
            <li>enable your use of the services available on the website;</li>
            <li>send you general (non-marketing) communications;</li>
            <li>send you email notifications which you have specifically requested;</li>
            <li>
              provide third parties with statistical information about our users – but this
              information will not be used to identify any individual user;
            </li>
            <li>
              deal with enquiries and complaints made by or about you relating to the website. We
              will not provide your personal information to any third parties for the purpose of
              direct marketing.
            </li>
          </ul>
        </li>
        <li>
          <strong>Disclosures</strong> <br /> We may disclose information about you to any of our
          employees, officers, agents, suppliers, collaborators, distribution partners, customers or
          subcontractors insofar as reasonably necessary for the purposes as set out in this privacy
          policy. In addition, we may disclose information about you:
          <ul>
            <li>to the extent that we are required to do so by law;</li>
            <li>in connection with any legal proceedings or prospective legal proceedings;</li>
            <li>
              in order to establish, exercise or defend our legal rights (including providing
              information to others for the purposes of fraud prevention and reducing credit risk);
            </li>
            <li>
              to the purchaser (or prospective purchaser) of any business or asset which we are (or
              are contemplating) selling. Except as provided in this privacy policy, we will not
              provide your information to third parties.
            </li>
          </ul>
        </li>
        <li>
          <strong>International data transfers</strong> <br /> Information that we collect may be
          stored and processed in and transferred between any of the countries in which we operate
          in order to enable us to use the information in accordance with this privacy policy. If
          you are in the European Economic Area (EEA), information which you provide may be
          transferred to countries (including the United States) which do not have data protection
          laws equivalent to those in force in the EEA. In addition, information that you submit for
          publication on the website will be published on the internet and may be available, via the
          internet, around the world. By using our website you expressly agree to such transfers of
          personal information.
        </li>
        <li>
          <strong>Security of your personal data</strong> <br />
          We take reasonable technical and organizational precautions to prevent the loss, misuse or
          alteration of your personal information. We will store all the personal information you
          provide on our secure (password protected) servers. Of course, data transmission over the
          internet is inherently insecure, and we cannot guarantee the security of data sent over
          the internet.
        </li>
        <li>
          <strong>Policy amendments</strong> <br /> We may update this privacy policy from
          time-to-time by posting a new version on our website. You should check this page
          occasionally to ensure you are happy with any changes.
        </li>
        <li>
          <strong>Your rights</strong> <br /> You may instruct us to provide you with any personal
          information we hold about you. You may instruct us not to process your personal data for
          any purposes by email at any time. (In practice, you will usually either expressly agree
          in advance to our use of your personal data for marketing purposes, or we will provide you
          with an opportunity to opt-out of the use of your personal data for marketing purposes.)
        </li>
        <li>
          <strong>Third party websites</strong> <br /> The website may contain links to other
          websites, and, upon your request, transmit your information to third party websites. We
          are not responsible for the privacy policies or practices of third party websites.
        </li>
        <li>
          <strong>Updating information</strong> <br /> Please let us know if the personal
          information which we hold about you needs to be corrected or updated.
        </li>
        <li>
          <strong>Contact</strong> <br /> If you have any questions about this privacy policy or our
          treatment of your personal data, please write to us by email to{' '}
          <strong>info@chynge.com</strong>
        </li>
      </ol>
    </div>
  );
};

export default ChyngexPP;
