import { createSelector } from 'reselect';

import { IActiveInstrumentOrders } from '../actions/orders/active_instruments_orders';
import { IState } from '../lib/store';

/**
 * Return only instrument pairs with active buy or sell orders
 */
export const getNonEmptyActiveInstrumentOrders = createSelector<
  IState,
  IActiveInstrumentOrders,
  IActiveInstrumentOrders
>(
  (state: IState) => state.activeInstrumentsOrders,
  (activeInstrumentsOrders) =>
    Object.keys(activeInstrumentsOrders).reduce((accumulator, current) => {
      if (
        activeInstrumentsOrders[current].buy_count > 0 ||
        activeInstrumentsOrders[current].sell_count > 0
      ) {
        accumulator[current] = activeInstrumentsOrders[current];
      }
      return accumulator;
    }, {})
);
