import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { intentionallyUntranslated } from '../../../lib/i18n';
import { IState } from '../../../lib/store';
import { II18nextT } from '../../../types/i18n';
import { $SuccessMessage } from '../../layout/KioskLayout';
import { $AlertWrapper } from '../../widgets/KycElements';

interface IManagedAgreementPageProps extends ConnectedProps<typeof connector> {}
interface IManagedAgreementPageState {}

class ManagedAgreementPage extends React.PureComponent<
  IManagedAgreementPageProps,
  IManagedAgreementPageState
> {
  static contextType: any = I18nContext;

  render() {
    const { t }: II18nextT = this.context;

    if (this.props.whitelabel !== 'xcalibra') {
      return (
        <div>
          <$AlertWrapper>
            <$SuccessMessage>
              {t(intentionallyUntranslated('Xcalibra Managed is not supported on this platform.'))}
            </$SuccessMessage>
          </$AlertWrapper>
        </div>
      );
    }

    return (
      <div
        style={{
          maxWidth: 700,
          margin: '100px auto',
          fontSize: '1.2rem',
          textAlign: 'left',
        }}
      >
        <h1>Terms of use</h1>
        TODO
      </div>
    );
  }
}

const connector = connect((state: IState) => ({
  whitelabel: state.env.whitelabel.name,
}));

export default connector(ManagedAgreementPage);
