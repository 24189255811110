import * as React from 'react';

import styled, { IThemeColorsUnion } from '../../../lib/styled_components';
import $ColorText from '../ColorText';
import GenericTooltip from './GenericTooltip';
import { ITooltipProps } from './tooltip_types';

type IProps = ITooltipProps & {
  color?: IThemeColorsUnion;
};

export const $TooltipText = styled($ColorText)`
  display: inline-block;
  outline: none;
  &:hover:after {
    content: '';
    display: block;
    border-bottom-style: dotted;
    border-bottom-width: 1.5px;
    border-bottom-color: ${(p) => (p.color ? p.theme.colors[p.color] : 'inherit')};
    height: 0;
    margin-top: -2px;
  }
`;

const ClickableTooltipWrapper: React.FunctionComponent<IProps> = (props) => {
  const { color, children, ...passProps } = props;
  return (
    <GenericTooltip placement="top" trigger={['click']} {...(passProps as any)}>
      <$TooltipText tabIndex={1} color={color}>
        {children}
      </$TooltipText>
    </GenericTooltip>
  );
};

export default ClickableTooltipWrapper as React.FunctionComponent<IProps>;
