import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { IState } from '../../lib/store';
import styled from '../../lib/styled_components';
import chyngexLogoTransparent from '../../media/chyngex-transparent.png';
import xcalibraLogoDark from '../../media/xc-black.png';
import xcalibraLogoLight from '../../media/xc-white.png';
import { IWhitelabel } from '../../types/constants';
import { IThemeVariant } from '../../types/theme';

// NOTE: We don't really have this theme switching ATM. Need reexamination before activating.

const LOGO_IMAGES: { [key in IWhitelabel]: { [key in IThemeVariant]: string } } = {
  xcalibra: {
    light: xcalibraLogoLight,
    dark: xcalibraLogoDark,
  },
  chyngex: {
    light: chyngexLogoTransparent,
    dark: chyngexLogoTransparent,
  },
};

const defaults = {
  width: 132,
  height: 47,
  margin: '15px auto',
};

const $Logo = styled.img.attrs((p: IProps) => {
  return {
    alt: `${p.exchangeTitle} logo`,
    src: LOGO_IMAGES[p.whitelabel || 'xcalibra'][p.variant || 'light'],
  };
})<IProps>`
  display: block;
  width: ${(p) => (p.width ? `${p.width}px` : `${defaults.width}px`)};
  height: ${(p) => (p.height ? `${p.height}px` : `${defaults.height}px`)};
  margin: ${(p) => (p.margin ? `${p.margin}` : `${defaults.margin}`)};
`;

interface IProps extends ConnectedProps<typeof connector> {
  width?: number;
  height?: number;
  margin?: string;
  variant?: IThemeVariant;
}

const Logo: React.FunctionComponent<IProps> = (props) => {
  return <$Logo {...props} />;
};

const connector = connect((state: IState) => ({
  whitelabel: state.env.whitelabel.name,
  exchangeTitle: state.env.whitelabel.exchangeTitle,
}));

export default connector(Logo);
