import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { logout } from '../../../actions/auth/logout';
import { goToSettings, makeDynamicLocationDescriptor } from '../../../actions/routing';
import { getAccountAlert, submitAccountAlertSeen } from '../../../bl/account_alert';
import { I18n } from '../../../lib/i18n';
import R from '../../../lib/routes';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { findParentElement } from '../../../lib/util';
import {
  AccountAlertIndicatorImage,
  Cog2Icon,
  EmailVerificationIndicatorImage,
  LogoutIcon,
} from '../../../media/svg_icons';
import { getUser } from '../../../selectors/auth';
import { II18nextTfunction } from '../../../types/i18n';
import IndicatorIcon, {
  IIndicatorIconIndication,
} from '../../widgets/indicator_icons/IndicatorIcon';
import KYCLevelIndicatorIcon from '../../widgets/indicator_icons/KYCLevelIndicatorIcon';
import $Link from '../../widgets/Link';
import AccountGadget from '../menu_gadgets/AccountGadget';
import { $MenuGadgetHeader } from '../menu_gadgets/menu_gadgets_common';
import NotificationsGadget from '../menu_gadgets/NotificationsGadget';
import { UserMenuButton } from './UserMenuButton';

const $UserMenu = styled.div`
  display: flex;
  height: 100%;
`;

const $UserMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: right;
  padding: 0.35rem 0.75rem;
`;

const $UserMenuText = styled.strong`
  margin: 0.15rem 0.2rem;
  font-size: ${(p) => p.theme.fontSize.larger};
  font-family: ${(p) => p.theme.fontFamily.monospace};
  > a {
    font-family: ${(p) => p.theme.fontFamily.sansSerif};
  }

  @media screen and ${(p) => p.theme.device.laptop} {
    font-size: ${(p) => p.theme.fontSize.large};
  }
`;

const $AccountSubmenuHolder = styled.div`
  display: inline-block;
  position: relative;
`;

const $AccountStatusIndicator = styled.div`
  margin-left: 0.3rem;
`;

const $AccountSubmenu = styled.div<{ open: boolean }>`
  box-shadow: ${(p) => p.theme.layout.overlayBoxShadow};
  position: absolute;
  top: 67px;
  right: 0;
  width: 300px;
  visibility: ${(p) => (p.open ? 'visible' : 'hidden')};
  opacity: ${(p) => (p.open ? '1' : '0')};
  transform: scale(${(p) => (p.open ? undefined : '0.96')});
  transition: 0.3s transform, 0.3s opacity, 0.3s visibility;

  // NOTE: Sounds silly, but on some laptop screens, menu happened to end up right next to the trading chart's
  //       right-side border and this was annoying the shit out of me.
  margin-right: -1px;

  &:before {
    content: ' ';
    position: absolute;
    bottom: 100%;
    right: 17px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent ${(p) => p.theme.colors.brandPrimary} transparent;
  }

  > :not(:last-child) ${$MenuGadgetHeader} {
    // Make visual separation between gadgets in collapsed form
    border-bottom: 1px solid ${(p) => p.theme.base.bodyBackground};
  }
`;

const IGNORE_BACKDROP_CLICK_CLASSNAME = 'UserMenu__ignore-backdrop-click';

interface IUserMenuProps extends RouteComponentProps, ConnectedProps<typeof connector> {}
interface IUserMenuState {
  emailSent: boolean;
  accountSubmenuOpen: boolean;
}

class UserMenu extends React.PureComponent<IUserMenuProps, IUserMenuState> {
  private mounted: boolean = false;
  private stopListeningHistory: () => void = null;

  constructor(props) {
    super(props);

    this.state = { emailSent: false, accountSubmenuOpen: false };
  }

  componentDidMount() {
    this.mounted = true;
    this.stopListeningHistory = this.props.history.listen(() => {
      if (this.mounted && this.state.accountSubmenuOpen) {
        // If route changes (eg. because user clicked a link inside), close the menu
        this.setState({ accountSubmenuOpen: false });
      }
    });
    document.addEventListener('mousedown', this.onAccountSubmenuBackdropEvent);
  }

  componentWillUnmount() {
    this.mounted = false;
    this.stopListeningHistory && this.stopListeningHistory();
    document.removeEventListener('mousedown', this.onAccountSubmenuBackdropEvent);
  }

  onAccountSubmenuBackdropEvent = (e) => {
    if (!this.mounted || !this.state.accountSubmenuOpen) {
      // Nothing to do
      return;
    }

    if (
      findParentElement(
        e.target,
        (element) =>
          element.classList && element.classList.contains(IGNORE_BACKDROP_CLICK_CLASSNAME)
      )
    ) {
      // The click came through one of the elements we are ignoring (eg. the submenu or button itself). So ignore.
      return;
    }

    this.setState({ accountSubmenuOpen: false });
  };

  toggleAccountSubmenu = () => {
    if (!this.state.accountSubmenuOpen && this.props.accountAlert) {
      // User will see an an account alert
      this.props.submitAccountAlertSeen(this.props.accountAlert);
    }
    this.setState({ accountSubmenuOpen: !this.state.accountSubmenuOpen });
  };

  logout = () => {
    this.props.logout();
  };

  renderAccountStatusIndicator = (t: II18nextTfunction) => {
    if (this.props.accountAlert) {
      const indication: IIndicatorIconIndication =
        this.state.accountSubmenuOpen || this.props.accountAlertMuted ? 'warning' : 'alert';

      if (this.props.accountAlert === 'suspended') {
        return (
          <IndicatorIcon IndicatorImage={AccountAlertIndicatorImage} indication={indication} />
        );
      }

      if (this.props.accountAlert === 'email_verification') {
        return (
          <IndicatorIcon IndicatorImage={EmailVerificationIndicatorImage} indication={indication} />
        );
      }

      if (this.props.accountAlert === 'kyc0') {
        return <KYCLevelIndicatorIcon level={0} indication={indication} />;
      }

      if (this.props.accountAlert === 'add_phone') {
        return (
          <KYCLevelIndicatorIcon
            level={this.props.user.kyc_level_granted}
            indication={indication}
          />
        );
      }

      // Fallback
      return <IndicatorIcon IndicatorImage={AccountAlertIndicatorImage} indication={indication} />;
    }

    // No alert, render the normal KYC level indicator
    return <KYCLevelIndicatorIcon level={this.props.user.kyc_level_granted} />;
  };

  renderAuthorizedItems(t: II18nextTfunction) {
    const username = this.props.user.nickname || this.props.user.email;

    return (
      <>
        <$AccountSubmenuHolder className={IGNORE_BACKDROP_CLICK_CLASSNAME}>
          <UserMenuButton
            pullDown
            active={this.state.accountSubmenuOpen}
            onClick={this.toggleAccountSubmenu}
          >
            <$UserMenuText>{username}</$UserMenuText>
            <$AccountStatusIndicator>
              {this.renderAccountStatusIndicator(t)}
            </$AccountStatusIndicator>
          </UserMenuButton>
          <$AccountSubmenu open={this.state.accountSubmenuOpen}>
            <AccountGadget roundedTop />
            <NotificationsGadget roundedBottom />
          </$AccountSubmenu>
        </$AccountSubmenuHolder>

        <UserMenuButton
          onClick={this.props.goToSettings}
          active={
            R.SETTINGS_HOME.isActive(this.props.location) ||
            R.SETTINGS.isActive(this.props.location)
          }
          tooltip={t('pages.settings')}
        >
          <Cog2Icon />
        </UserMenuButton>

        <UserMenuButton onClick={this.props.logout} tooltip={t('userMenu.logout')}>
          <LogoutIcon />
        </UserMenuButton>
      </>
    );
  }

  renderAnonymousItems(t: II18nextTfunction) {
    return (
      <>
        <$UserMenuItem>
          <$UserMenuText>
            <$Link
              as={Link}
              to={this.props.makeDynamicLocationDescriptor(R.LOGIN, this.props.activeStaticRoute)}
              hovercolor="info"
            >
              {t('userMenu.login')}
            </$Link>
          </$UserMenuText>
        </$UserMenuItem>

        <$UserMenuItem>
          <$UserMenuText>
            <$Link
              as={Link}
              to={this.props.makeDynamicLocationDescriptor(
                R.REGISTER,
                this.props.activeStaticRoute
              )}
              hovercolor="info"
            >
              {t('userMenu.register')}
            </$Link>
          </$UserMenuText>
        </$UserMenuItem>
      </>
    );
  }

  render() {
    return (
      <$UserMenu>
        <I18n>
          {(t) => (this.props.user ? this.renderAuthorizedItems(t) : this.renderAnonymousItems(t))}
        </I18n>
      </$UserMenu>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    user: getUser(state),
    activeStaticRoute: state.activeStaticRoute,
    accountAlert: getAccountAlert(state),
    accountAlertMuted: state.accountAlertMuted,
  }),
  {
    submitAccountAlertSeen,
    logout,
    goToSettings,
    makeDynamicLocationDescriptor,
  }
);

export default withRouter(connector(UserMenu));
