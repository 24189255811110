import * as React from 'react';

import { IThemeColorsUnion } from '../../../lib/styled_components';
import {
  KYCLevel0IndicatorImage,
  KYCLevel1IndicatorImage,
  KYCLevel2IndicatorImage,
  KYCLevel3IndicatorImage,
} from '../../../media/svg_icons';
import { Omit } from '../../../types/typescript_helpers';
import IndicatorIcon, { IIndicatorIconProps } from './IndicatorIcon';

interface IProps extends Omit<IIndicatorIconProps, 'IndicatorImage'> {
  level: number;
}

const KYCLevelIndicatorIcon: React.FunctionComponent<IProps> = ({ level, ...passProps }) => {
  let color: IThemeColorsUnion;
  let IndicatorImage;
  switch (String(level)) {
    case '0':
      IndicatorImage = KYCLevel0IndicatorImage;
      color = 'kycLevel0';
      break;
    case '1':
      IndicatorImage = KYCLevel1IndicatorImage;
      color = 'kycLevel1';
      break;
    case '2':
      IndicatorImage = KYCLevel2IndicatorImage;
      color = 'kycLevel2';
      break;
    case '3':
      IndicatorImage = KYCLevel3IndicatorImage;
      color = 'kycLevel3';
      break;
    default:
      return null;
  }

  return <IndicatorIcon color={color} IndicatorImage={IndicatorImage} {...passProps} />;
};

export default KYCLevelIndicatorIcon;
