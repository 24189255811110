import styledNormalize from 'styled-normalize';

import { createGlobalStyle, zIndex } from '../lib/styled_components';
import { shadeColor } from '../lib/util';

const GlobalStyles = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  
  ${styledNormalize}

  #root {
    margin: 0 auto;
  }

  html {
    @media ${(p) => p.theme.device.mobile} {
      font-size: ${(p) => p.theme.base.fontSizeMobile};
    }
    
    @media ${(p) => p.theme.device.laptop} {
      font-size: ${(p) => p.theme.base.fontSizeLaptop};
    }

    @media (max-width: 1600px) {
      font-size: ${(p) => p.theme.base.fontSizeLaptop};
    }

    font-size: ${(p) => p.theme.base.fontSizeDesktop};
    
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: ${(p) => p.theme.base.font};
    color: ${(p) => p.theme.base.color};
    background: ${(p) => p.theme.base.bodyBackground} url(
      ${(p) => p.theme.base.bodyBackgroundImage}) no-repeat center center fixed;
    background-size: cover;
    min-width: 200px;

    @media ${(p) => p.theme.device.mobile} {
      background-image: none;
      font-size: ${(p) => p.theme.base.fontSizeMobile};
    }
    @media ${(p) => p.theme.device.laptop} {
      font-size: ${(p) => p.theme.base.fontSizeLaptop};
    }
    
  }

  /* animations */
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .fadein {
    animation: fadein 0.5s ease;
  }
  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  .fadeout {
    animation: fadeout 0.5s ease;
  }

  /* helper classes */
  .show-on-laptop {
      display: none !important;
    }
  @media ${(p) => p.theme.device.laptop} {
    .hide-on-laptop {
      display: none !important;
    }
    .show-on-laptop {
      display: block !important;
    }
  }

  .show-on-mobile {
      display: none !important;
    }
  @media ${(p) => p.theme.device.mobile} {
    .hide-on-mobile {
      display: none !important;
    }
    .show-on-mobile {
      display: block !important;
    }
  }

  /* toast notifications */
  .notification-container .Toastify__toast {
    border-radius: 8px;
  }
 
  .notification-container .Toastify__toast--info {
    background-color: ${(p) => p.theme.colors.info};
  }
  .notification-container .Toastify__toast--success {
    background-color: ${(p) => p.theme.colors.success};
  }
  .notification-container .Toastify__toast--warning {
    background-color: ${(p) => p.theme.colors.warning};
  }
  .notification-container .Toastify__toast--error {
    background-color: ${(p) => p.theme.colors.error};
  }
  .notification-container .Toastify__toast--success.buy {
    background-color: ${(p) => p.theme.colors.buy};
  }
  .notification-container .Toastify__toast--error.sell {
    background-color: ${(p) => p.theme.colors.sell};
  }
 
  
  /* SVG */
  .svg-icon-color-stroke {
    stroke: ${(p) => p.theme.colors.white};
  }
  .svg-icon-color-fill {
    fill: ${(p) => p.theme.colors.white};
  }

  /* Tooltip */
  .rc-tooltip {
    z-index: ${zIndex.tooltip} !important;
  }
  .GenericTooltip-normal .rc-tooltip-inner {
    color: ${(p) => p.theme.widgets.tooltip.color} !important;
    background: ${(p) => p.theme.widgets.tooltip.background} !important;
    border: 1px solid ${(p) => p.theme.widgets.tooltip.borderColor} !important;
  }
  .GenericTooltip-normal.rc-tooltip-placement-bottom .rc-tooltip-arrow,
  .GenericTooltip-normal.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  .GenericTooltip-normal.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    border-bottom-color: ${(p) => p.theme.widgets.tooltip.borderColor} !important;
  }
  .GenericTooltip-normal.rc-tooltip-placement-right .rc-tooltip-arrow,
  .GenericTooltip-normal.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
  .GenericTooltip-normal.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    border-right-color: ${(p) => p.theme.widgets.tooltip.borderColor} !important;
  }
  .GenericTooltip-normal.rc-tooltip-placement-top .rc-tooltip-arrow,
  .GenericTooltip-normal.rc-tooltip-placement-topTop .rc-tooltip-arrow,
  .GenericTooltip-normal.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  .GenericTooltip-normal.rc-tooltip-placement-topBottom .rc-tooltip-arrow {
    border-top-color: ${(p) => p.theme.widgets.tooltip.borderColor} !important;
  }
  .GenericTooltip-normal.rc-tooltip-placement-left .rc-tooltip-arrow,
  .GenericTooltip-normal.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
  .GenericTooltip-normal.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    border-left-color: ${(p) => p.theme.widgets.tooltip.borderColor} !important;
  }
  
  .GenericTooltip-error .rc-tooltip-inner {
    color: ${(p) => p.theme.widgets.tooltip.errorColor} !important;
    background: ${(p) => p.theme.widgets.tooltip.errorBackground} !important;
    border: 1px solid ${(p) => p.theme.widgets.tooltip.errorBorderColor} !important;
  }
  .GenericTooltip-error.rc-tooltip-placement-bottom .rc-tooltip-arrow,
  .GenericTooltip-error.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  .GenericTooltip-error.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    border-bottom-color: ${(p) => p.theme.widgets.tooltip.errorBorderColor} !important;
  }
  .GenericTooltip-error.rc-tooltip-placement-right .rc-tooltip-arrow,
  .GenericTooltip-error.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
  .GenericTooltip-error.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    border-right-color: ${(p) => p.theme.widgets.tooltip.errorBorderColor} !important;
  }
  .GenericTooltip-error.rc-tooltip-placement-top .rc-tooltip-arrow,
  .GenericTooltip-error.rc-tooltip-placement-topTop .rc-tooltip-arrow,
  .GenericTooltip-error.rc-tooltip-placement-topBottom .rc-tooltip-arrow {
    border-top-color: ${(p) => p.theme.widgets.tooltip.errorBorderColor} !important;
  }
  .GenericTooltip-error.rc-tooltip-placement-left .rc-tooltip-arrow,
  .GenericTooltip-error.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
  .GenericTooltip-error.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    border-left-color: ${(p) => p.theme.widgets.tooltip.errorBorderColor} !important;
  }
  
  /*
   * Scrollbars
   */
  
  * {
    /* Firefox: https://stackoverflow.com/questions/6165472/custom-css-scrollbar-for-firefox */
    scrollbar-width: thin;
    scrollbar-color: ${(p) => p.theme.scrollbars.backgroundColor} transparent;
    
    /* IE 11 */
    -ms-scrollbar-face-color: ${(p) => p.theme.scrollbars.backgroundColor};
    -ms-scrollbar-arrow-color: transparent;
    -ms-scrollbar-track-color: transparent;
    -ms-scrollbar-shadow-color: transparent;
    -ms-scrollbar-highlight-color: transparent;
    -ms-scrollbar-3dlight-color: transparent;
    -ms-scrollbar-darkshadow-Color: transparent;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    
    /* Edge */
    /* Sorry */
  }
  
  /* Chrome below */
  
  ::-webkit-scrollbar {
    width: ${(p) => p.theme.scrollbars.width};
    transition: all 0.3s;  
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-track {
  }
  ::-webkit-scrollbar-track-piece {
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.scrollbars.backgroundColor};
    border-radius: ${(p) => p.theme.scrollbars.borderRadius};
    transition: all 0.3s;
  }
  ::-webkit-scrollbar:hover {
    background-color: rgba(0,0,0,0.2);
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${(p) => shadeColor(p.theme.scrollbars.backgroundColor, -0.2)};
    box-shadow: none;
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: ${(p) => shadeColor(p.theme.scrollbars.backgroundColor, -0.5)};
    box-shadow: none;
  }
  ::-webkit-scrollbar-corner {
  
  }
  ::-webkit-resizer {
  
  }
  
  /* Focus */
  a:focus, button:focus, label:focus, input[type=button]:focus, input[type=submit]:focus {
    outline: ${(p) => p.theme.base.focusOutline};
    outline-offset: ${(p) => p.theme.base.focusOutlineOffset};
  }
  #root a:focus,
  #root button:focus,
  #root label:focus,
  #root input[type=button]:focus,
  #root input[type=submit]:focus {
    outline: ${(p) => p.theme.base.focusOutline};
    outline-offset: ${(p) => p.theme.base.focusOutlineOffset};
  }
`;

export default GlobalStyles;
