import * as React from 'react';
import { I18nContext } from 'react-i18next';

import errorImage from '../../media/error-red.png';
import { II18nextT } from '../../types/i18n';
import { SimplePageLayout } from '../layout/SimplePageLayout';

interface IAppErrorPageProps {}
interface IAppErrorPageState {}

class AppErrorPage extends React.PureComponent<IAppErrorPageProps, IAppErrorPageState> {
  static contextType: any = I18nContext;

  render() {
    const { t }: II18nextT = this.context;

    return (
      <SimplePageLayout errorImageSrc={errorImage} errorImageAlt={t('errorPage.title')}>
        <span style={{ color: 'white' }}>{t('errorPage.instructionsFirstLine')}</span> <br />
        <span style={{ color: 'white' }}>{t('errorPage.instructionsSecondLine')}</span>
      </SimplePageLayout>
    );
  }
}

export default AppErrorPage;
