import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { I18n } from '../../lib/i18n';
import { IState } from '../../lib/store';
import styled from '../../lib/styled_components';
import { formatNumberToFixed } from '../../lib/util';
import bgTexture from '../../media/texture_stripes.png';
import { ALL_INSTRUMENTS, IInstrumentObject } from '../../types/instruments';
import { ITranslations } from '../../types/translations';

const $LineWrapper = styled.div`
  margin-top: 30px;
  padding-bottom: 5px;
  background-color: ${(p) => p.theme.components.withdrawLimit.backgroundColor};

  @media screen and ${(p) => p.theme.device.mobile} {
    margin: 30px 10px 0 10px;
  }
`;

const $Line = styled('div')<{ width?: number }>`
  display: flex;
  position: relative;
  max-width: ${(p) => (p.width ? p.width : '200')}px;
  height: 18px;
  outline: 2px solid white;
  background-color: ${(p) => p.theme.components.withdrawLimit.remainingColor};
`;

const $LineFiller = styled('div')<{ percentage: number }>`
  width: ${(p) => p.percentage}%;
  height: 100%;
  background-color: ${(p) => p.theme.components.withdrawLimit.filledColor};
  background-image: url(${bgTexture});
`;

const $StatusText = styled.p`
  margin: 0;
  padding: 7px 0;
  text-align: center;
`;

const $PieWrapper = styled.div<{ width: number }>`
  position: relative;
  width: ${(p) => p.width}px;
  height: ${(p) => p.width}px;
`;

const $PieCanvas = styled.canvas`
  position: absolute;
`;

const $PieContentSection = styled.div`
  height: 50%;
  display: flex;
  position: relative;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  div > h3,
  div > h4,
  div > h5,
  div > h6 {
    margin: 0;
  }
`;

const $PieContent = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;

  ${$PieContentSection}:first-child {
    align-items: flex-end;
    margin-bottom: 10px;
  }

  ${$PieContentSection}:last-child {
    align-items: flex-start;
    margin-top: 10px;
    cursor: pointer;
  }
`;
const $PieCanvasContainer = styled('div')<{ width: number }>`
  width: ${(p) => p.width}px;
  height: ${(p) => p.width}px;
`;

interface IWithdrawalLimitProps {
  instrument: IInstrumentObject;
  instrumentLimit: number;
  instrumentRemaining: number;
  width?: number;
}

export const LimitLine: React.SFC<IWithdrawalLimitProps> = ({
  instrument,
  instrumentLimit,
  instrumentRemaining,
  width,
}) => {
  if (!instrumentLimit || !instrumentRemaining) {
    return null;
  }

  const percentageRemaining = (instrumentRemaining / instrumentLimit) * 100;
  const percentageFilled = 100 - percentageRemaining;
  return (
    <I18n>
      {(t) => (
        <$LineWrapper>
          <$Line width={width}>
            <$LineFiller percentage={percentageFilled} />
          </$Line>
          <$StatusText>
            {t('transactions:withdrawals.limitWidget.remainingOf', {
              remaining: formatNumberToFixed(instrumentRemaining, instrument.digits),
              limit: formatNumberToFixed(instrumentLimit, instrument.digits),
              instrument: instrument.symbol,
            })}
          </$StatusText>
        </$LineWrapper>
      )}
    </I18n>
  );
};

interface ILimitGraphProps extends IWithdrawalLimitProps, ConnectedProps<typeof connector> {
  fiatLimit: number;
  fiatRemaining: number;
  instrumentInputAmount?: number;
  onClickRemaining?: () => void;
  budget: number;
}

interface ILimitGraphState {}

class LimitGraph extends React.PureComponent<ILimitGraphProps, ILimitGraphState> {
  private canvasEl: React.RefObject<HTMLCanvasElement> = React.createRef();
  private canvasContext: CanvasRenderingContext2D = null;
  private patternImage;
  static contextType: any = I18nContext;

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const image = new Image();
    image.src = bgTexture;
    this.patternImage = image;
    this.canvasContext = this.canvasEl.current.getContext('2d');
    this.draw();
  }

  componentDidUpdate() {
    this.canvasContext.clearRect(0, 0, this.props.width, this.props.width);
    this.draw();
  }

  calcAngleFromRatio(ratio) {
    return 2 * Math.PI * ratio;
  }

  drawSlice(color, startAngle, endAngle, patterned) {
    const centerCoordinate = this.props.width / 2;
    // leave enough space for border
    const radius = this.props.width / 2 - 3;
    this.canvasContext.fillStyle = color;
    this.canvasContext.beginPath();
    this.canvasContext.moveTo(centerCoordinate, centerCoordinate);
    this.canvasContext.arc(centerCoordinate, centerCoordinate, radius, startAngle, endAngle);
    this.canvasContext.lineTo(centerCoordinate, centerCoordinate);
    this.canvasContext.fill();

    if (patterned) {
      this.canvasContext.fillStyle = this.canvasContext.createPattern(this.patternImage, 'repeat');
      this.canvasContext.beginPath();
      this.canvasContext.moveTo(centerCoordinate, centerCoordinate);
      this.canvasContext.arc(centerCoordinate, centerCoordinate, radius, startAngle, endAngle);
      this.canvasContext.lineTo(centerCoordinate, centerCoordinate);
      this.canvasContext.fill();
    }
  }

  draw() {
    const {
      theme,
      instrumentLimit,
      fiatLimit,
      instrumentRemaining,
      fiatRemaining,
      instrumentInputAmount,
      budget,
    } = this.props;
    const filledPortion = (fiatLimit - fiatRemaining) / fiatLimit;
    const remainingPortion = 1 - filledPortion;
    const toBeFilledPortion = instrumentInputAmount / instrumentLimit;
    const filledAngle = this.calcAngleFromRatio(filledPortion);
    const remainingAngle = this.calcAngleFromRatio(remainingPortion);
    const toFillAngle = this.calcAngleFromRatio(toBeFilledPortion);

    // draw circle with transparent background
    this.drawSlice(
      theme.components.withdrawLimit.backgroundColor,
      0,
      this.calcAngleFromRatio(1),
      false
    );

    // draw border around the circle
    this.canvasContext.strokeStyle = 'white';
    this.canvasContext.lineWidth = 3;
    this.canvasContext.stroke();

    // filled slice
    this.drawSlice(theme.components.withdrawLimit.filledColor, 0, filledAngle, true);

    // remaining slice
    this.drawSlice(
      theme.components.withdrawLimit.remainingColor,
      filledAngle,
      filledAngle + remainingAngle,
      false
    );

    // portion to be filled after withdrawal
    if (instrumentInputAmount) {
      if (instrumentInputAmount < 0) {
        return null;
      }

      // entire circle red if we are over remaining limit
      if (instrumentInputAmount > instrumentRemaining) {
        return this.drawSlice(theme.components.withdrawLimit.warningColor, 0, 360, true);
      }

      const remainingColor =
        budget < instrumentInputAmount
          ? // if not enough money in account, make the slice red
            theme.components.withdrawLimit.warningColor
          : // otherwise, make the slice "success" color
            theme.components.withdrawLimit.toBeFilledColor;

      this.drawSlice(remainingColor, filledAngle, filledAngle + toFillAngle, true);
    }
  }

  renderSection = (
    title: ITranslations,
    amount: number,
    amountFiat: number,
    instrument: IInstrumentObject,
    onClick?: () => any
  ) => (
    <$PieContentSection onClick={onClick && onClick}>
      {
        <div>
          <h4>
            {this.context.t(title)}: <br />
          </h4>
          <h3>
            <b>
              {formatNumberToFixed(amountFiat, ALL_INSTRUMENTS.USD.digits)}{' '}
              {ALL_INSTRUMENTS.USD.symbol}
            </b>
          </h3>
          ({formatNumberToFixed(amount, instrument.digits)} {instrument.symbol})
        </div>
      }
    </$PieContentSection>
  );

  render() {
    const {
      width,
      instrument,
      instrumentLimit,
      fiatLimit,
      instrumentRemaining,
      fiatRemaining,
      onClickRemaining,
    } = this.props;

    return (
      <$PieWrapper width={width}>
        {
          <$PieCanvasContainer width={width}>
            <img src={bgTexture} style={{ display: 'none' }} alt="pattern source" />
            <$PieCanvas ref={this.canvasEl} height={width} width={width} />
          </$PieCanvasContainer>
        }
        <$PieContent>
          {this.renderSection(
            'transactions:withdrawals.limitWidget.dailyLimit',
            instrumentLimit,
            fiatLimit,
            instrument
          )}
          {this.renderSection(
            'transactions:withdrawals.limitWidget.remaining',
            instrumentRemaining,
            fiatRemaining,
            instrument,
            onClickRemaining
          )}
        </$PieContent>
      </$PieWrapper>
    );
  }
}

const connector = connect((state: IState) => ({
  theme: state.app.theme,
}));

export default connector(LimitGraph);
