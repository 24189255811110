import { getSimpleNotificationData, loadUserSessionData, notifySuccess } from '../actions/app';
import { setCustomer } from '../actions/auth/customer';
import { loadingWrapper } from '../actions/request_active';
import { handleError } from '../lib/errors';
import { commit, val } from '../lib/redux_helpers';
import { IState, IThunkMethod } from '../lib/store';
import { IPhoneVerificationPurpose } from '../types/backend_definitions';

export interface IPhoneVerificationModalState {
  phoneVerification: {
    id: number;
    phone: string;
    purpose: IPhoneVerificationPurpose;
    result: boolean | null;
  };
}

export const PHONE_VERIFICATION_MODAL_STATE = {
  phoneVerification: null,
};

export const isPhoneVerificationModalOpen = (state: IState) => {
  return state.phoneVerification
    ? state.phoneVerification.phone && state.phoneVerification.result === null
    : false;
};

export const openPhoneVerificationModal = (
  purpose: IPhoneVerificationPurpose,
  phone: string,
  id: number
) => {
  return commit(`Open phone verification modal for "${phone}"`, {
    phoneVerification: val({
      purpose,
      id,
      phone,
      result: null,
    }),
  });
};

export const submitPhoneVerificationResult = (result: boolean) => {
  return commit(`Submit phone verification result: "${result}"`, {
    phoneVerification: {
      result: val(result),
    },
  });
};

export const clearPhoneVerificationState = () => {
  return commit(`Clear phone verification state`, {
    phoneVerification: val(null),
  });
};

export const setCustomerPhone = (phone: string): IThunkMethod => (dispatch, _, { api, i18n }) => {
  loadingWrapper(
    'setCustomerPhone',
    api
      .putPhoneVerificationSetCustomerPhone({
        phone,
      })
      .then((result) => {
        dispatch(setCustomer(result));

        dispatch(
          notifySuccess(
            getSimpleNotificationData(
              i18n.t('accountGadget.addPhone.phoneVerificationCompletedNotification')
            )
          )
        );

        // Load new user configs
        dispatch(loadUserSessionData());
      })
      .catch((err) => dispatch(handleError(err)))
  );
};
