import styled, { css } from '../../lib/styled_components';
import { $SmallButton } from './buttons';

const $ToggleMenu = styled.div`
  display: flex;
`;

interface IMenuButtonProps {
  active?: boolean;
  activeColor?: string;
  hasTriangle?: boolean;
}
const $ToggleMenuButton = styled($SmallButton).attrs((p: IMenuButtonProps) => ({
  className: p.active ? 'active' : '',
}))<IMenuButtonProps>`
  border-radius: 0;
  border: none;
  background: transparent;
  color: ${(p) => p.theme.colors.grayLighter};
  padding: ${(p) => p.theme.widgets.menu.padding};
  font-size: ${(p) => p.theme.widgets.menu.fontSize};
  border-bottom-style: ${(p) => p.theme.widgets.menu.borderStyle};
  border-bottom-width: 2px;
  border-bottom-color: ${(p) => p.theme.colors.grayLighter};
  position: relative;

  &:hover {
    color: ${(p) => p.theme.colors.white};
    background: ${(p) => p.theme.widgets.menu.backgroundHover};
  }
  &.active {
    color: ${(p) => (p.activeColor ? p.activeColor : p.theme.base.color)};
    ${(p) =>
      p.hasTriangle &&
      css`
        ::after {
          content: ' ';
          width: 0;
          height: 0;
          position: absolute;
          border-style: solid;
          left: calc(50% - 12px);
          bottom: 0%;
          border-width: 0 6px 5px 6px;
          border-color: transparent transparent
            ${p.activeColor ? p.activeColor : p.theme.base.color} transparent;
        }
      `};
  }
`;

export { $ToggleMenu, $ToggleMenuButton };
