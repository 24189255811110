import React from 'react';

import { I18n } from '../../../lib/i18n';
import styled from '../../../lib/styled_components';

const $CardPaymentDeclinedScreen = styled.div`
  max-width: 24rem;
  font-size: ${(p) => p.theme.fontSize.larger};
`;

const CardPaymentDeclinedScreen: React.FunctionComponent = () => {
  return (
    <I18n>
      {(t) => (
        <$CardPaymentDeclinedScreen>
          {t('transactions:cardPayments.paymentDeclinedMessage')}
        </$CardPaymentDeclinedScreen>
      )}
    </I18n>
  );
};

export default CardPaymentDeclinedScreen;
