import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { I18n } from '../../../lib/i18n';
import R, { IRouteSpecWithoutParams } from '../../../lib/routes';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { ITranslations } from '../../../types/translations';
import { $Button } from '../../widgets/buttons';
import $Link from '../../widgets/Link';

export const $AccountPagesInputWrapper = styled.div`
  display: block;
  padding: 0.5em 0;

  label {
    cursor: pointer;
  }

  button,
  input[type='text'],
  input[type='password'],
  input[type='email'] {
    text-align: center;
    width: 100%;
    display: block;
    padding: 0.5em;
  }

  input[type='checkbox'],
  input[type='radio'] {
    width: auto;
    margin: 0 0.5em 0 1em;
  }
`;

export const $AccountPagesLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  line-height: 2em;
  a {
    color: ${(p) => p.theme.layout.kiosk.wrapper.color} !important;
    font-weight: bold;
    display: inline-block;
  }
  & > div {
    display: flex;
    flex-direction: column;
  }
  & > div:first-child {
    text-align: left;
  }
  & > div:last-child {
    text-align: right;
  }
`;

export const $AccountPagesButton = styled($Button).attrs((p) => ({
  backgroundColor: p.theme.layout.kiosk.button.backgroundColor,
  color: p.theme.layout.kiosk.button.color,
}))``;

interface IAuthDialogBottomLinkProps extends RouteComponentProps, ConnectedProps<typeof connector> {
  linkHeader: ITranslations;
  title: ITranslations;
  route: IRouteSpecWithoutParams;
}

const connector = connect((state: IState) => ({
  activeStaticRoute: state.activeStaticRoute,
}));

const AuthDialogBottomLink = withRouter(
  connector((props: IAuthDialogBottomLinkProps) => {
    const to = props.route.prefixed(props.activeStaticRoute);
    return (
      <I18n>
        {(t) => (
          <div>
            {t(props.linkHeader)}
            <$Link as={Link} type="link" to={to}>
              {t(props.title)}
            </$Link>
          </div>
        )}
      </I18n>
    );
  })
);

export const RequestPasswordResetLink = () => (
  <AuthDialogBottomLink
    linkHeader="authLinks.requestPasswordResetHeader"
    title="authLinks.requestPasswordResetLink"
    route={R.PASSWORD_RESET_REQUEST}
  />
);

export const RegisterLink = () => (
  <AuthDialogBottomLink
    linkHeader="authLinks.registerHeader"
    title="authLinks.registerLink"
    route={R.REGISTER}
  />
);

export const LoginLink = () => (
  <AuthDialogBottomLink
    linkHeader="authLinks.loginHeader"
    title="authLinks.loginLink"
    route={R.LOGIN}
  />
);
