import i18next from 'i18next';
import XHR from 'i18next-xhr-backend';
import * as React from 'react';
import {
  Trans as I18NextTrans,
  Namespace,
  NamespacesConsumer,
  ReactI18NextOptions,
  reactI18nextModule,
} from 'react-i18next';

import { ILanguage } from '../types/backend_definitions';
import { II18nextTfunction, INamespacesConsumerProps } from '../types/i18n';
import { ITranslations } from '../types/translations';
import { LocalStorage } from './local_storage';
import { IStateGetter } from './store';

/**
 * Convert our language format to the one used by i18n. They like "en" or "en-us", and we use "en_us".
 */
const toI18NLanguage = (language: ILanguage) => {
  const [name, script] = language.split('_');
  return `${name}-${script.toUpperCase()}`;
};

export class I18nService {
  public i18nextRef: i18next.i18n;
  public t: II18nextTfunction;
  public changeLanguage: (lng: ILanguage, callback?: i18next.Callback) => void;

  constructor(
    private getState: IStateGetter,
    private localStorage: LocalStorage,
    private ajax?: (
      url: string,
      options: any,
      callback: (data: any, xhrStatus: any) => void
    ) => void
  ) {
    this.i18nextRef = i18next.use(XHR).use(reactI18nextModule); // if not using I18nextProvider
  }

  initialize() {
    const env = this.getState().env;

    let loadPath = `${env.publicUrl || ''}/translations/{{lng}}/i18n_{{ns}}.json`;
    if (env.buildNumber) {
      loadPath += `?b=${env.buildNumber}`;
    }

    return new Promise((resolve, reject) => {
      this.i18nextRef.init(
        {
          ns: ['webui', 'common', 'settings', 'transactions', 'backend'],
          defaultNS: 'webui',
          fallbackNS: 'common',
          backend: {
            loadPath,
            ajax: this.ajax,
          },
          // Prevents trying to load both "en" and "en-US"
          load: 'currentOnly',
          fallbackLng: toI18NLanguage(env.i18n.defaultLanguage),
          debug: false,
          // react i18next special options (optional)
          react: {
            wait: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default',
          },
        },
        (errors) => {
          this.t = this.i18nextRef.t.bind(this.i18nextRef);
          this.changeLanguage = (lng: ILanguage, callback?: i18next.Callback) => {
            this.i18nextRef.changeLanguage(toI18NLanguage(lng), callback);
            this.localStorage.language = lng;
          };

          if (errors) {
            const err = new Error(`Error while initializing translations: ${errors[0]}`);
            (err as any).errors = errors;
            return reject(err);
          }

          resolve();
        }
      );
    });
  }
}

interface I18nContextProps extends ReactI18NextOptions {
  ns?: Namespace;
  initialI18nStore?: {};
  initialLanguage?: string;
  children(
    t: II18nextTfunction,
    options: {
      i18n: i18next.i18n;
      lng: string;
      ready: boolean;
    }
  ): React.ReactNode;
}

export const I18n: React.ComponentClass<INamespacesConsumerProps> = NamespacesConsumer as any;

export interface ITransProps {
  i18nKey?: ITranslations;
  count?: number;
  parent?: React.ReactNode;
  i18n?: i18next.i18n;
  t?: II18nextTfunction;
  defaults?: string;
  values?: {};
  components?: React.ReactNode[];
}
export const Trans: React.ComponentClass<ITransProps> = I18NextTrans as any;

/**
 * Use this when you want to add literal untranslated texts during dev.
 * So we can more easily hunt down which parts are untranslated and translate them.
 */
export const untranslated = (...strings: string[]) => {
  window.consoleError(`Untranslated: ${strings.join(' ')}`);
  return strings.join(' ');
};

/**
 * Use this if you want to write an untranslated message that you know is temporary.
 * We will not log anything for these.
 */
export const intentionallyUntranslated = (...strings: string[]): ITranslations => {
  return strings.join(' ').replace(/:/g, '：') as ITranslations;
};
