import { createBrowserHistory } from 'history';
import * as socketIO from 'socket.io-client';

import { AccountAlertMuter } from '../bl/account_alert';
import { loadAndValidateEnv } from '../bl/env';
import { SessionIdlenessTracker } from '../bl/session_idle_timeout';
import { SessionRenewer } from '../bl/session_renewal';
import { UserActivityTracker } from '../bl/user_activity_tracking';
import {
  IApiXcalibraClientRequestHandlerFunction,
  XcalibraClient,
} from '../types/backend_definitions';
import { CandleChartAdapter } from './candle_chart_adapter';
import { ICustomHistory, createCustomHistory } from './history';
import { Http } from './http';
import { I18nService } from './i18n';
import { LocalStorage } from './local_storage';
import { Logger } from './logger';
import { Matomo } from './matomo';
import { SentryIntegration } from './sentry_integration';
import { SocketListener } from './socket_listener';
import { SocketManager } from './socket_manager';
import { Store, createStore } from './store';
import { initWhyDidYouUpdate } from './why_did_you_update';
import { WindowListener } from './window_listener';

export type IServiceName = Exclude<keyof Container, 'start' | 'initialize' | 'ensureService'>;
type IServices = Container[IServiceName];
type IServiceForName<T extends IServiceName> = Container[T];
type IServiceFactory<T extends IServiceName> = (container?: Container) => Container[T];
export type IServiceFactories = Partial<{ [key in IServiceName]: IServiceFactory<key> }>;

export default class Container {
  public store: Store;
  public logger: Logger;
  public sentryIntegration: SentryIntegration;
  public localStorage: LocalStorage;
  public history: ICustomHistory;
  public http: Http;
  public socketManager: SocketManager;
  public socketListener: SocketListener;
  public api: XcalibraClient;
  public userActivityTracker: UserActivityTracker;
  public sessionRenewer: SessionRenewer;
  public sessionIdlenessTracker: SessionIdlenessTracker;
  public i18n: I18nService;
  public candleChartAdapter: CandleChartAdapter;
  public windowListener: WindowListener;
  public matomo: Matomo;
  public accountAlertMuter: AccountAlertMuter;

  private readonly window: Window;
  private readonly rawEnv: object;

  constructor(private factories?: Partial<IServiceFactories>, windowArg?: Window, envArg?: object) {
    this.window = windowArg || window;
    this.rawEnv = envArg || process.env;
  }

  /**
   * If given service name doesn't exist, create it using either the provided factory method
   * or the default factory
   */
  public provide<T extends IServiceName>(name: T, defaultFactory?: IServiceFactory<T>): this {
    if (!this[name]) {
      if (this.factories && this.factories[name]) {
        this[name] = this.factories[name](this) as any;
      } else {
        this[name] = defaultFactory(this) as any;
      }
    }

    return this;
  }

  /**
   * Create all services using either provided factories or falling back to the defaults.
   * This is a synchronous and safe operation (there are no API requests or similar side-effects)
   */
  public initialize(): this {
    // We will use this env to bootstrap services that need to be loaded before state is ready. Others can use state.
    const env = loadAndValidateEnv(this.rawEnv);

    this.provide('sentryIntegration', () => new SentryIntegration(() => this.store.getState()));

    this.provide(
      'logger',
      () =>
        new Logger(
          env.logger.generalLevel,
          env.logger.levelByPrefix,
          '',
          this.sentryIntegration,
          this.window.console
        )
    );
    this.provide('store', () => createStore(env, [this.logger.middleware()], this));
    this.provide('localStorage', () => new LocalStorage(this.window.localStorage, env));
    this.provide('history', () => createCustomHistory(createBrowserHistory(), this.logger));
    this.provide('matomo', () => new Matomo(this.history, this.store, this.logger, this.window));
    this.provide(
      'http',
      () =>
        new Http(
          this.store.getState,
          this.logger,
          this.localStorage,
          this.window.fetch.bind(this.window)
        )
    );
    this.provide('socketManager', () => new SocketManager(this.store, this.logger, socketIO));
    this.provide(
      'socketListener',
      () => new SocketListener(this.socketManager, this.store.dispatch)
    );
    this.provide(
      'api',
      () =>
        new XcalibraClient(
          this.http.request.bind(this.http) as IApiXcalibraClientRequestHandlerFunction,
          false
        )
    );
    this.provide(
      'userActivityTracker',
      () => new UserActivityTracker(this.store, this.logger, this.window.document)
    );
    this.provide('sessionRenewer', () => new SessionRenewer(this.store, this.logger));
    this.provide(
      'sessionIdlenessTracker',
      () => new SessionIdlenessTracker(this.store, this.logger)
    );
    this.provide('i18n', () => new I18nService(this.store.getState, this.localStorage));
    this.provide(
      'candleChartAdapter',
      () => new CandleChartAdapter(this.store, this.api, this.http, this.socketManager, this.logger)
    );
    this.provide('windowListener', () => new WindowListener(this.store, this.window));
    this.provide('accountAlertMuter', () => new AccountAlertMuter(this.store, this.logger));

    this.logger.info(`Container initialized`);

    return this;
  }

  /**
   * Start all the background tasks and execute initialization code
   */
  public start() {
    // Init why-did-you-update
    initWhyDidYouUpdate(this.store.getState);

    // Init error reporting
    this.sentryIntegration.init();

    // Initialize analytics
    this.matomo.initialize();

    // Enable sockets
    this.socketManager.enabled = true;

    // Start background services
    this.userActivityTracker.start();
    this.sessionRenewer.start();
    this.sessionIdlenessTracker.start();
    this.candleChartAdapter.start();

    // Set initial window size
    this.windowListener.start();

    return Promise.all([this.i18n.initialize()]).then(
      () => {
        this.logger.info(`All services started`);
      },
      (err) => {
        this.logger.error(`Failed to start some of the container services`, err);
      }
    );
  }
}
