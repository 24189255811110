import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { IToastNotification, clearNotifications } from '../../../actions/app';
import { I18n } from '../../../lib/i18n';
import { LocalStorage } from '../../../lib/local_storage';
import { inject } from '../../../lib/react_container';
import R from '../../../lib/routes';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import $Link from '../../widgets/Link';
import { NormalNotification } from '../../widgets/Notification';
import {
  $MenuGadgetFooter,
  $MenuGadgetHeader,
  $MenuGadgetHeaderTitle,
} from './menu_gadgets_common';

const $NotificationsGadget = styled.div<{ roundedBottom?: boolean }>`
  ${(p) => p.roundedBottom && 'border-radius: 0 0 6px 6px;'}
`;

const $NotificationList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  background: ${(p) => p.theme.base.bodyBackground};
  max-height: calc(80vh - 275px);
  overflow-y: auto;

  @media screen and ${(p) => p.theme.device.mobile} {
    max-height: none;
  }
`;
const $NoToastNotifications = styled.div<{ roundedBottom?: boolean }>`
  text-align: center;
  padding: 1.5rem 0;
  background: ${(p) => p.theme.base.bodyBackground};
  ${(p) => p.roundedBottom && 'border-radius: 0 0 6px 6px;'}
`;

interface INotificationsGadgetProps extends RouteComponentProps, ConnectedProps<typeof connector> {
  roundedTop?: boolean;
  roundedBottom?: boolean;
  localStorage: LocalStorage;
}
interface INotificationsGadgetState {}

class NotificationsGadget extends React.PureComponent<
  INotificationsGadgetProps,
  INotificationsGadgetState
> {
  render() {
    const toastNotifications = this.props.toastNotifications.length
      ? this.props.toastNotifications
      : this.props.localStorage.toastNotifications;

    return (
      <I18n>
        {(t) => (
          <$NotificationsGadget roundedBottom={this.props.roundedBottom}>
            <$MenuGadgetHeader rounded={this.props.roundedTop}>
              <$MenuGadgetHeaderTitle as={Link} to={R.SETTINGS.to({ page: 'notifications' })}>
                {t('notificationsGadget.title')}
              </$MenuGadgetHeaderTitle>
            </$MenuGadgetHeader>
            {!toastNotifications.length ? (
              <$NoToastNotifications roundedBottom={this.props.roundedBottom}>
                {t('notificationsGadget.noNotifications')}
              </$NoToastNotifications>
            ) : (
              toastNotifications.length && (
                <>
                  <$NotificationList>
                    {toastNotifications
                      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                      .map((notification, i) => {
                        return notification.messages ? (
                          <NormalNotification key={i} notification={notification} />
                        ) : null;
                      })}
                  </$NotificationList>
                  <$MenuGadgetFooter rounded={this.props.roundedBottom}>
                    <$Link type="button" onClick={this.props.clearNotifications}>
                      {t('notificationsGadget.clearAllNotifications')}
                    </$Link>
                  </$MenuGadgetFooter>
                </>
              )
            )}
          </$NotificationsGadget>
        )}
      </I18n>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    toastNotifications: state.app.toastNotifications as IToastNotification[],
  }),
  {
    clearNotifications,
  }
);

export default connector(inject('localStorage')(withRouter(NotificationsGadget)));
