import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { navigateAwayFromActiveDynamicRoute } from '../../actions/routing';
import { I18n } from '../../lib/i18n';
import { IRouteSpec } from '../../lib/routes';
import { ITranslations } from '../../types/translations';
import Modal from '../widgets/Modal';
import KioskLayout from './KioskLayout';

interface IDynamicRouteModalProps extends ConnectedProps<typeof connector> {
  route: IRouteSpec;
}

const DynamicRouteModal: React.FunctionComponent<IDynamicRouteModalProps> = ({
  navigateAwayFromActiveDynamicRoute,
  children,
  route,
}) => {
  if (!route.dynamic) {
    throw new Error(`DynamicRouteModal must be used for dynamic routes`);
  }

  return (
    <I18n>
      {(t) => {
        return (
          <Modal
            isOpen
            onClose={() => {
              navigateAwayFromActiveDynamicRoute();
            }}
            title={t(('dynamicRouteModalTitles.' + route.id) as ITranslations)}
          >
            <KioskLayout>{children}</KioskLayout>
          </Modal>
        );
      }}
    </I18n>
  );
};

const connector = connect(null, {
  navigateAwayFromActiveDynamicRoute,
});

export default connector(DynamicRouteModal);
