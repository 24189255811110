import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { downloadCSV } from '../../../actions/download_csv';
import { cancelOrder } from '../../../actions/orders/cancel_order';
import {
  loadActiveUserOrdersForAllInstrumentPairs,
  loadActiveUserOrdersForInstrumentPair,
  resetUserOrders,
} from '../../../actions/orders/orders';
import Quantity from '../../../lib/quantity_';
import { IState } from '../../../lib/store';
import { formatFullDate, formatFullDateRegular, formatNumberToFixed } from '../../../lib/util';
import { OppositeArrowsIcon } from '../../../media/svg_icons';
import {
  IOrderScrollableTableRow,
  getActiveOrdersPaginationState,
  getSortedActiveOrderList,
} from '../../../selectors/orders';
import { isRequestActive } from '../../../selectors/request_active';
import {
  IApiQueryResultForCensoredOrder,
  IOrderSide,
  IPair,
} from '../../../types/backend_definitions';
import { II18nextT } from '../../../types/i18n';
import { PRICE_DIGITS, pairInfo } from '../../../types/instruments';
import Header from '../../widgets/Header';
import HorizontalScrollTable, {
  $FixedHeightCell,
  $FixedHeightTableRow,
  $HighlightableTableRow,
  IGridColumnProps,
  IScrollableRow,
  ITableParts,
} from '../../widgets/HorizontalScrollTable';
import InstrumentIcon from '../../widgets/InstrumentIcon';
import { $ToolbarLabel } from '../../widgets/Label';
import { SidedPrettyDecimals } from '../../widgets/PrettyDecimals';
import {
  $ActionElementsWrapper,
  $CloseButton,
  $HeaderWrapperJustified,
  $InnerWrapper,
  $NoDataWrapper,
  $Wrapper,
  DownloadCSVButton,
} from '../../widgets/Reports';
import { ResponsiveSelectWrapper, SimpleSelect } from '../../widgets/Select';
import { $Cell, ICellProps } from '../../widgets/Table';
import TableActionIndicator from '../../widgets/TableActionIndicator';

interface IOpenOrdersProps extends ConnectedProps<typeof connector> {
  stickHeader: boolean;
  getTableHeaderTopOffset?: (topOffset) => void;
}
interface IOpenOrdersState {
  filterOrdersByPair: IPair | 'all';
  loading: boolean;
}

type ICellNames =
  | 'pair'
  | 'date'
  | 'buy_sell'
  | 'type'
  | 'price'
  | 'amount'
  | 'fee'
  | 'total'
  | 'cancel';

const CELLS: { [key in ICellNames]?: Partial<ICellProps> } = {
  date: { align: 'center', minWidth: '9rem' },
  pair: { align: 'center', minWidth: '7rem' },
  buy_sell: { align: 'center', minWidth: '6rem' },
  type: { align: 'center', minWidth: '3.5rem' },
  price: { align: 'center', minWidth: '11rem' },
  amount: { align: 'center', minWidth: '11rem' },
  fee: { align: 'center', minWidth: '3rem' },
  total: { align: 'center', minWidth: '11rem' },
  cancel: { align: 'center', minWidth: '3rem' },
};

const GRID_COLUMNS: IGridColumnProps<ICellNames> = {
  fixed: { date: { width: '1fr' } },
  scrollable: {
    pair: { width: '7rem' },
    buy_sell: { width: '6rem' },
    type: { width: '5rem' },
    price: { width: '1fr' },
    amount: { width: '1fr' },
    fee: { width: '1fr' },
    total: { width: '1fr' },
    cancel: { width: '1fr' },
  },
};

class OpenOrders extends React.PureComponent<IOpenOrdersProps, IOpenOrdersState> {
  static contextType: any = I18nContext;
  private filterOptions: Object;
  private mounted: boolean = false;

  constructor(props) {
    super(props);

    this.state = {
      filterOrdersByPair: 'all',
      loading: false,
    };
  }

  renderTableParts(): ITableParts {
    const { t }: II18nextT = this.context;

    return {
      header: {
        fixed: () => <$FixedHeightCell {...CELLS.date}>{t('date')}</$FixedHeightCell>,
        scrollable: () => (
          <>
            <$FixedHeightCell {...CELLS.pair}>{t('pair')}</$FixedHeightCell>
            <$FixedHeightCell {...CELLS.buy_sell}>
              {t('buy')} / {t('sell')}
            </$FixedHeightCell>
            <$FixedHeightCell {...CELLS.type}>{t('type')}</$FixedHeightCell>
            <$FixedHeightCell {...CELLS.price}>{t('price')}</$FixedHeightCell>
            <$FixedHeightCell {...CELLS.amount}>{t('amount')}</$FixedHeightCell>
            <$FixedHeightCell {...CELLS.fee}>{t('fee')}</$FixedHeightCell>
            <$FixedHeightCell {...CELLS.total}>{t('estimatedTotal')}</$FixedHeightCell>
            <$FixedHeightCell {...CELLS.cancel}>&nbsp;</$FixedHeightCell>
          </>
        ),
      },
      body: {
        fixed: (highlightedRowIndex, highlightSelectedRow) => {
          return this.props.orders.fixed.map((timestamp, index) => {
            return (
              <$HighlightableTableRow
                onMouseOver={() => highlightSelectedRow(index)}
                highlighted={index === highlightedRowIndex}
                key={index}
              >
                <$Cell {...CELLS.date} title={formatFullDate(timestamp)}>
                  {formatFullDateRegular(timestamp)}
                </$Cell>
              </$HighlightableTableRow>
            );
          });
        },
        scrollable: (orderRow: IOrderScrollableTableRow, index, highlightSelectedRow) =>
          this.renderScrollableRow(orderRow, index, highlightSelectedRow),
      },
    };
  }

  componentWillMount() {
    this.filterOptions = this.props.pairs.reduce(
      (res, pair) => {
        const formattedPair = pair.replace(/_/g, '/');
        res[pair] = { value: pair, label: formattedPair };
        return res;
      },
      { all: { value: 'all', label: this.context.t('all') } }
    );
  }

  componentDidMount() {
    this.mounted = true;
    this.props.resetUserOrders();
    this.onLoadOrders();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onLoadOrders = (firstPage = false) => {
    this.setState({ loading: true });
    this.loadOrders(firstPage).finally(() => this.mounted && this.setState({ loading: false }));
  };

  loadOrders(firstPage = false): Promise<IApiQueryResultForCensoredOrder> {
    const { filterOrdersByPair: pair } = this.state;

    if (pair === 'all') {
      return this.props.loadActiveUserOrdersForAllInstrumentPairs(firstPage);
    }

    return this.props.loadActiveUserOrdersForInstrumentPair(pair, firstPage);
  }

  renderScrollableRow: IScrollableRow = (
    orderRow: IOrderScrollableTableRow,
    index,
    highlightSelectedRow
  ) => {
    const { t }: II18nextT = this.context;
    const { order, forCancellation } = orderRow;
    const { displayPath, quote, base } = pairInfo(order.pair as IPair);
    const orderMarkedForRemoval = Boolean(forCancellation);

    return (
      <$FixedHeightTableRow onMouseOver={() => highlightSelectedRow(index)} key={order.id}>
        <$Cell {...CELLS.pair}>
          <InstrumentIcon
            size={22}
            name={quote.symbol}
            type="white"
            style={{ verticalAlign: 'middle' }}
          />
          &nbsp;&nbsp;
          <strong>{displayPath}</strong>
        </$Cell>
        <$Cell {...CELLS.buy_sell}>{t(order.side as IOrderSide)}</$Cell>
        <$Cell {...CELLS.type}>{t(`orderType.${order.type}` as any)}</$Cell>
        <$Cell {...CELLS.price}>
          <SidedPrettyDecimals
            value={formatNumberToFixed(order.price, PRICE_DIGITS)}
            side={order.side as IOrderSide}
          />{' '}
          {base.symbol}
        </$Cell>
        <$Cell {...CELLS.amount}>
          <SidedPrettyDecimals
            value={formatNumberToFixed(order.quantity, quote.digits)}
            side={order.side as IOrderSide}
          />{' '}
          {quote.symbol}
        </$Cell>
        <$Cell {...CELLS.fee}>{order.fee * 100}%</$Cell>
        <$Cell {...CELLS.total}>
          <SidedPrettyDecimals
            value={formatNumberToFixed(
              order.side === 'buy'
                ? Quantity(order.quantity).baseLoss(order.price, order.fee, base.digits).toString()
                : Quantity(order.quantity).baseGain(order.price, order.fee, base.digits).toString(),
              base.digits
            )}
            side={order.side as IOrderSide}
          />{' '}
          {base.symbol}
        </$Cell>
        <$Cell {...CELLS.cancel}>
          <$CloseButton
            onClick={() =>
              this.props.cancelingOrder || this.props.cancelOrder(order.pair as IPair, order.id)
            }
            disabled={orderMarkedForRemoval}
          >
            ✕
          </$CloseButton>
        </$Cell>
      </$FixedHeightTableRow>
    );
  };

  renderOpenOrders() {
    const { t }: II18nextT = this.context;
    const loadOrdersBound = this.loadOrders.bind(this);

    const criteria =
      this.state.filterOrdersByPair !== 'all'
        ? {
            pair: this.state.filterOrdersByPair,
            sort_direction: ['desc'],
            sort_field: ['timestamp'],
          }
        : { sort_direction: ['desc'], sort_field: ['timestamp'] };

    return (
      <$Wrapper>
        <$HeaderWrapperJustified stickHeader={this.props.stickHeader}>
          <Header
            title={t('reportsPage.openOrders')}
            icon={OppositeArrowsIcon}
            loadingKeys={[
              'getActiveUserOrdersForAllInstrumentPairs',
              'cancelOrderFromListOfAllOrders',
            ]}
            hideOnMobile={true}
          />
          <$ActionElementsWrapper>
            <div>
              <$ToolbarLabel htmlFor="ordersPair">{t('pair')}:</$ToolbarLabel>
              <ResponsiveSelectWrapper>
                <SimpleSelect
                  inputId="ordersPair"
                  width="8rem"
                  options={Object.values(this.filterOptions)}
                  value={this.filterOptions[this.state.filterOrdersByPair]}
                  onChange={({ value: pair }) =>
                    this.setState({ filterOrdersByPair: pair }, () => {
                      this.onLoadOrders(true);
                    })
                  }
                  variant="transparent"
                />
              </ResponsiveSelectWrapper>
            </div>
            <div>
              <DownloadCSVButton
                downloadCsv={this.props.downloadCsv}
                csvDownloadFunction={'getExchangeOrdersCsv'}
                criteria={criteria}
              />
            </div>
          </$ActionElementsWrapper>
        </$HeaderWrapperJustified>

        {this.state.loading ? (
          <div />
        ) : !this.props.ordersPagination.total_records ? (
          <$NoDataWrapper>{t('reportsPage.noData.openOrders')}</$NoDataWrapper>
        ) : (
          <$InnerWrapper>
            <HorizontalScrollTable
              data={this.props.orders.scrollable}
              gridColumns={GRID_COLUMNS}
              stickHeader={this.props.stickHeader}
              getTableHeaderTopOffset={this.props.getTableHeaderTopOffset}
              tableParts={this.renderTableParts()}
            >
              <TableActionIndicator
                paginationState={this.props.ordersPagination}
                requests={[
                  'getActiveUserOrdersForAllInstrumentPairs',
                  'getActiveUserOrdersForInstrumentPair',
                ]}
                itemsCount={this.props.orders.length}
                actionFn={loadOrdersBound}
                style={{ height: '30px' }}
              />
            </HorizontalScrollTable>
          </$InnerWrapper>
        )}
      </$Wrapper>
    );
  }

  render() {
    return this.renderOpenOrders();
  }
}

const connector = connect(
  (state: IState) => ({
    orders: getSortedActiveOrderList(state),
    ordersPagination: getActiveOrdersPaginationState(state),
    cancelingOrder: isRequestActive('cancelOrderFromListOfAllOrders', state),
    pairs: state.env.pairs.list,
  }),
  {
    cancelOrder,
    loadActiveUserOrdersForAllInstrumentPairs,
    loadActiveUserOrdersForInstrumentPair,
    resetUserOrders,
    downloadCsv: downloadCSV,
  }
);

export default connector(OpenOrders);
