import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { IPriceSummary24hPair } from '../../actions/exchange';
import R from '../../lib/routes';
import { IState } from '../../lib/store';
import styled from '../../lib/styled_components';
import { formatNumberToFixed, formatPercentage } from '../../lib/util';
import {
  getChangePercentForPriceSummaryPair,
  getMarketsSummary,
  getPairsConfig,
} from '../../selectors/exchange';
import { getConversionRate, getRates } from '../../selectors/rates';
import { IInstrument } from '../../types/backend_definitions';
import { II18nextT } from '../../types/i18n';
import { pairInfo } from '../../types/instruments';
import $ColorText from '../widgets/ColorText';
import Header from '../widgets/Header';
import InstrumentIcon from '../widgets/InstrumentIcon';
import $Link from '../widgets/Link';
import { $HeaderWrapper } from '../widgets/PageHeaderWrappers';
import PageSeoWrapper from '../widgets/PageSeoWrapper';
import PrettyDecimals from '../widgets/PrettyDecimals';
import {
  $Cell,
  $SubHeaderCell,
  $Table,
  $TableHeading,
  $TableRow,
  ICellProps,
} from '../widgets/Table';

const $Wrapper = styled.div`
  @media screen and ${(p) => p.theme.device.mobile} {
    padding-top: 2rem;
  }
`;
const $TableWrapper = styled.div`
  padding: 10px 20px;
  @media screen and ${(p) => p.theme.device.mobile} {
    padding: 10px 0;
  }
`;

interface IMarketsPagePageProps extends ConnectedProps<typeof connector> {}
interface IMarketsPagePageState {}

type ICellNames =
  | 'market'
  | 'currency'
  | 'last_price'
  | 'volume_24h'
  | 'change_24h'
  | 'high_24h'
  | 'low_24h';

const CELLS: { [key in ICellNames]: Partial<ICellProps> } = {
  market: { width: '14.28%', align: 'left' },
  currency: { width: '14.28%', align: 'center' },
  last_price: { width: '14.28%', align: 'center' },
  volume_24h: { width: '14.28%', align: 'center' },
  change_24h: { width: '14.28%', align: 'center' },
  high_24h: { width: '14.28%', align: 'center' },
  low_24h: { width: '14.28%', align: 'center' },
};

class MarketsPage extends React.PureComponent<IMarketsPagePageProps, IMarketsPagePageState> {
  static contextType: any = I18nContext;

  renderMarketPairs = (pairSummary: IPriceSummary24hPair, i) => {
    const { quote, base, path, displayPath } = pairInfo(pairSummary.pair);

    const percentageChange = getChangePercentForPriceSummaryPair(pairSummary);

    const percentColor =
      percentageChange > 0 ? 'increase' : percentageChange < 0 ? 'decrease' : 'baseDarker';
    const rate = getConversionRate(this.props.rates, quote.symbol, base.symbol);
    const limit = !percentageChange && !pairSummary.high && !pairSummary.low && rate;

    const row = (
      <$TableRow key={pairSummary.pair}>
        <$SubHeaderCell {...CELLS.market}>
          <InstrumentIcon
            size={22}
            name={quote.symbol}
            type="white"
            style={{ verticalAlign: 'middle' }}
          />
          &nbsp;&nbsp;
          <strong style={{ verticalAlign: 'middle' }}>{displayPath}</strong>
        </$SubHeaderCell>
        <$Cell {...CELLS.currency} className="hide-on-mobile">
          {quote.name}
        </$Cell>
        <$Cell {...CELLS.last_price}>
          <PrettyDecimals value={formatNumberToFixed(rate, base.digits)} />
        </$Cell>
        <$Cell {...CELLS.volume_24h}>
          <PrettyDecimals value={formatNumberToFixed(pairSummary.volume, base.digits)} />
        </$Cell>
        <$Cell {...CELLS.change_24h}>
          <$ColorText color={percentColor}>{formatPercentage(percentageChange, 2)}</$ColorText>
        </$Cell>
        <$Cell {...CELLS.high_24h}>
          <PrettyDecimals value={formatNumberToFixed(limit || pairSummary.high, base.digits)} />
        </$Cell>
        <$Cell {...CELLS.low_24h}>
          <PrettyDecimals value={formatNumberToFixed(limit || pairSummary.low, base.digits)} />
        </$Cell>
      </$TableRow>
    );

    return (
      <$Link as={Link} to={R.EXCHANGE.to({ pair: path })} key={path}>
        {row}
      </$Link>
    );
  };

  render() {
    const { markets } = this.props;
    const { t }: II18nextT = this.context;

    return (
      <PageSeoWrapper title={t('seo.title.markets')} description={t('seo.description.markets')}>
        <$Wrapper>
          {(Object.keys(markets) as IInstrument[]).map((marketSymbol) => (
            <div key={marketSymbol}>
              <$HeaderWrapper>
                <Header title={`${marketSymbol} ${t('marketsPage.markets')}`} icon={marketSymbol} />
              </$HeaderWrapper>

              <$TableWrapper>
                <$Table>
                  <$TableHeading>
                    <$Cell {...CELLS.market}>{t('marketsPage.market')}</$Cell>
                    <$Cell {...CELLS.currency} className="hide-on-mobile">
                      {t('marketsPage.currency')}
                    </$Cell>
                    <$Cell {...CELLS.last_price}>{t('marketsPage.lastPrice')}</$Cell>
                    <$Cell {...CELLS.volume_24h}>{t('marketsPage.24hVolume')}</$Cell>
                    <$Cell {...CELLS.change_24h}>{t('marketsPage.24hPercentChange')}</$Cell>
                    <$Cell {...CELLS.high_24h}>{t('marketsPage.24hHigh')}</$Cell>
                    <$Cell {...CELLS.low_24h}>{t('marketsPage.24hLow')}</$Cell>
                  </$TableHeading>
                  {markets[marketSymbol].map(this.renderMarketPairs)}
                </$Table>
              </$TableWrapper>
            </div>
          ))}
        </$Wrapper>
      </PageSeoWrapper>
    );
  }
}

const connector = connect((state: IState) => ({
  markets: getMarketsSummary(state),
  pairConfigs: getPairsConfig(state),
  rates: getRates(state),
}));

export default connector(MarketsPage);
