import { DeepPartial } from 'redux';

import { IPageCriteria, IPaginated } from '../types/api';
import { DeepReadonly } from '../types/typescript_helpers';
import lodash from './lodash';
import { IState } from './store';

/**
 * Generate payload for store. Payload should be an object matching structure of the store.
 * The terminators of payload should be wrapped in PayloadValue, so that we can extract them and form the result payload
 * @example
 *     commit("Update cart", {
 *      cart: {
 *        items: {
 *          '5': set({id: 5, title: 'Widget', quantity: 5})
 *        }
 *      }
 *     })
 *
 * this will create payload like this:
 *
 *     {
 *       type: "Update cart",
 *       "cart.items.5": {id: 5, title: 'Widget', quantity: 5}
 *     }
 *
 */
export function commit(message: string, payload: DeepPartial<IState>) {
  const result = {
    type: message,
  };

  if (payload instanceof PayloadValue) {
    // The entire payload is wrapped in val. So let's replace the entire state.
    result[''] = payload.value;
    return result;
  }

  walk(payload);
  return result;

  function walk(ob: any, pathPrefix = '') {
    if (!ob) {
      return;
    }
    for (const key in ob) {
      if (!ob.hasOwnProperty(key)) {
        continue;
      }

      const val = ob[key];
      if (val instanceof PayloadValue) {
        // This is where we stop
        result[pathPrefix + key] = val.value;
      } else if (lodash.isObjectLike(val)) {
        // Dig in
        walk(val, pathPrefix + key + '.');
      } else {
        // The user gave us a leaf without wrapping it in set(). Treat it as bug for now
        throw new Error(
          `Trying to set ${pathPrefix + key} without wrapping it into val() ` +
            `(${message} -> ${JSON.stringify(payload, null, '  ')})`
        );
      }
    }
  }
}

class PayloadValue {
  constructor(public value: any) {}
}

export function val<T>(value: T): T {
  return (new PayloadValue(value) as unknown) as T;
}

export function updatePaginatedResult<T>(
  oldResult: DeepReadonly<IPaginated<T>>,
  record: T,
  key: keyof T
): [IPaginated<T>, boolean] {
  const newResult: IPaginated<T> = {
    ...oldResult,
    items: oldResult.items.slice() as T[],
  };

  const recordIndex = newResult.items.findIndex((item) => item[key] === record[key]);

  const isNewRecord = recordIndex === -1;

  if (isNewRecord) {
    // Add to start and update pagination
    newResult.items.unshift(record);
    newResult.total_records += 1;

    const isPageFilled =
      newResult.items.length > 0 && newResult.items.length % newResult.page_size === 0;
    if (isPageFilled) {
      newResult.page += 1;
    }
  } else {
    // Replace existing record with new one
    newResult.items[recordIndex] = record;
  }

  return [newResult, isNewRecord];
}

export function mergePaginatedResults<T, K>(
  newResult: IPaginated<T>,
  oldResult: DeepReadonly<IPaginated<K>>,
  uniqueKey: keyof K,
  transformationFn?: (items: T) => K
): IPaginated<K> {
  const { page, total_pages, page_size, total_records } = newResult;

  // filter out duplicate records
  const oldUniqueKeys = oldResult.items.map((oldResultItem) => (oldResultItem as K)[uniqueKey]);
  const newResultsFiltered = newResult.items.filter(
    (r) => !oldUniqueKeys.includes(r[uniqueKey as any])
  );

  // if there is transformation function apply it on new items
  const result: K[] = transformationFn
    ? newResultsFiltered.map(transformationFn)
    : (newResultsFiltered as K extends T ? K[] : never);

  return {
    items: (result.length ? [...oldResult.items, ...result] : oldResult.items) as K[],
    page,
    total_pages,
    page_size,
    total_records,
  };
}

export function getNextPageCriteria<T>(
  previousCriteria: Partial<DeepReadonly<IPaginated<T>>>,
  defaultPageSize: number,
  firstPage = false
): IPageCriteria {
  if (firstPage) {
    return { page: 1, page_size: defaultPageSize };
  }

  const page = Math.max(
    previousCriteria.total_pages > previousCriteria.page
      ? previousCriteria.page + 1
      : previousCriteria.total_pages,
    1
  );

  return {
    page,
    page_size: previousCriteria.page_size,
  };
}
