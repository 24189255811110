import { IExtendedApiPaymentConfigForUser } from '../actions/transactions/card_payments';
import { IState } from '../lib/store';
import { IPaginated } from '../types/api';
import { IApiCensoredCardPayment, IApiPaymentInstrumentConfig } from '../types/backend_definitions';
import { DeepReadonly, Omit } from '../types/typescript_helpers';
import { IHorizontalScrollTableData } from '../views/widgets/HorizontalScrollTable';

export interface ICardPaymentsFixed extends Pick<IApiCensoredCardPayment, 'created_at'> {}

export interface ICardPaymentsHorizontalScrollTableData
  extends IHorizontalScrollTableData<ICardPaymentsFixed, IApiCensoredCardPayment> {}
export interface ICardPaymentsPaginationState
  extends Omit<IPaginated<IApiCensoredCardPayment>, 'items'> {
  items: ICardPaymentsHorizontalScrollTableData;
}

const createCardPaymentsHorizontalScrollTableData = (
  data: DeepReadonly<IPaginated<IApiCensoredCardPayment>>
): ICardPaymentsPaginationState => {
  const { items, ...rest } = data;
  return items.reduce(
    (
      modifiedContent: ICardPaymentsPaginationState,
      withdrawal: IApiCensoredCardPayment
    ): ICardPaymentsPaginationState => {
      const fixed: ICardPaymentsFixed = {
        created_at: withdrawal.created_at,
      };
      modifiedContent.items.fixed.push(fixed);
      modifiedContent.items.scrollable.push(withdrawal);
      return modifiedContent;
    },
    { ...rest, items: { fixed: [], scrollable: [], length: data.items.length } }
  );
};

export type ICardPaymentStatus = 'pending' | 'submitted' | 'processed' | 'declined';

export const getUserCardPayments = (state: IState) => state.transactions.cardPayments;
export const getCardPaymentsPaginationState = (state: IState): ICardPaymentsPaginationState =>
  createCardPaymentsHorizontalScrollTableData(state.transactions.cardPayments);

export const getCardPaymentStatus = (cardPayment: IApiCensoredCardPayment): ICardPaymentStatus =>
  (cardPayment.declined_at && 'declined') ||
  (cardPayment.completed_at && 'processed') ||
  (cardPayment.submitted_at && 'submitted') ||
  'pending';

export interface ICardPaymentConfig {
  buyWithCardExposed: boolean;
  userConfig: IExtendedApiPaymentConfigForUser;
  instrumentConfig: DeepReadonly<IApiPaymentInstrumentConfig>;
}

export const getCardPaymentConfig = (state: IState): ICardPaymentConfig => ({
  buyWithCardExposed: state.env.whitelabel.name === 'xcalibra',
  userConfig: state.transactions.cardPaymentUserConfig,
  instrumentConfig: state.transactions.cardPaymentInstrumentConfig,
});
