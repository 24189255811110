import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { resetPassword, verifyResetPasswordToken } from '../../../actions/auth/password_reset';
import {
  ITranslatedError,
  ValidationSingleMessage,
  ValidationWrapper,
  clientValidator,
} from '../../../lib/errors';
import styled from '../../../lib/styled_components';
import { promiseGuard } from '../../../lib/util';
import { II18nextT } from '../../../types/i18n';
import { $SuccessMessage } from '../../layout/KioskLayout';
import { $InputDark } from '../../widgets/Input';
import LoadingAnimation from '../../widgets/LoadingAnimation';
import {
  $AccountPagesButton,
  $AccountPagesInputWrapper,
  $AccountPagesLinks,
  LoginLink,
  RegisterLink,
} from './auth_components';

const $Form = styled.form`
  width: 340px;
  max-width: 100%;
  margin: 0 auto;
`;

const $Wrapper = styled.div`
  width: 340px;
  max-width: 100%;
`;

const $ModalBody = styled.div`
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IPasswordResetExecuteScreenProps
  extends RouteComponentProps<{ token: string }>,
    ConnectedProps<typeof connector> {}
interface IPasswordResetExecuteScreenState {
  password: string;
  passwordConfirm: string;
  loading: boolean;
  loadError: ITranslatedError;
  submitting: boolean;
  submitError: ITranslatedError;
  success: boolean;
}

class PasswordResetExecuteScreen extends React.PureComponent<
  IPasswordResetExecuteScreenProps,
  IPasswordResetExecuteScreenState
> {
  private mounted = false;

  static contextType: any = I18nContext;
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordConfirm: '',
      loading: true,
      loadError: null,
      submitting: false,
      submitError: null,
      success: false,
    };
  }

  componentDidMount() {
    this.mounted = true;

    const {
      match: {
        params: { token },
      },
      verifyResetPasswordToken,
    } = this.props;

    if (token) {
      promiseGuard(this, verifyResetPasswordToken(token), 'loading').catch((loadError) =>
        this.setState({ loadError })
      );
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleUserInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    this.setState({ [name]: value } as any);
  };

  submitForm = (e: React.FormEvent) => {
    e.preventDefault();

    const { t }: II18nextT = this.context;

    if (
      !clientValidator(this.state, this.context.t, 'body.')
        .passwordsMatch('passwordConfirm', 'password')
        .onFail((submitError) => {
          this.setState({ submitError });
        })
        .isValid()
    ) {
      return;
    }

    this.setState({ submitError: null });
    promiseGuard(
      this,
      this.props.resetPassword(this.state.password, this.props.match.params.token),
      'submitting'
    ).then(
      () => this.setState({ success: true }),
      (submitError: ITranslatedError) => this.setState({ submitError })
    );
  };

  renderModalContent() {
    const { t }: II18nextT = this.context;

    if (this.state.success) {
      return <$SuccessMessage>{t('executePasswordReset.successMessage')}</$SuccessMessage>;
    }

    if (this.state.loading) {
      return <LoadingAnimation />;
    }

    if (this.state.loadError) {
      return <ValidationSingleMessage error={this.state.loadError} />;
    }

    return (
      <$Form onSubmit={this.submitForm}>
        <ValidationSingleMessage error={this.state.submitError} />
        <$AccountPagesInputWrapper>
          <ValidationWrapper error={this.state.submitError} path={'body.new_password'}>
            <$InputDark
              name="password"
              type="password"
              placeholder="New Password"
              value={this.state.password}
              onChange={this.handleUserInput}
              required
              autoFocus
            />
          </ValidationWrapper>
        </$AccountPagesInputWrapper>

        <$AccountPagesInputWrapper>
          <ValidationWrapper error={this.state.submitError} path={'body.passwordConfirm'}>
            <$InputDark
              name="passwordConfirm"
              type="password"
              placeholder="Confirm New Password"
              value={this.state.passwordConfirm}
              onChange={this.handleUserInput}
              required
            />
          </ValidationWrapper>
        </$AccountPagesInputWrapper>

        <$AccountPagesInputWrapper>
          <$AccountPagesButton type="submit" disabled={this.state.submitting}>
            {t('executePasswordReset.submitButton')}
          </$AccountPagesButton>
        </$AccountPagesInputWrapper>
      </$Form>
    );
  }

  render() {
    return (
      <$Wrapper>
        <$ModalBody>{this.renderModalContent()}</$ModalBody>
        <$AccountPagesLinks>
          <LoginLink />
          <RegisterLink />
        </$AccountPagesLinks>
      </$Wrapper>
    );
  }
}

const connector = connect(null, {
  verifyResetPasswordToken,
  resetPassword,
});

export default withRouter(connector(PasswordResetExecuteScreen));
