import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import {
  createNewApiKey,
  deleteApiKey,
  editApiKeyOrThrow,
  loadApiKeys,
  loadApiSecretKey,
} from '../../../actions/api_keys';
import { ITranslatedError } from '../../../lib/errors';
import { I18n } from '../../../lib/i18n';
import lodash from '../../../lib/lodash';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { formatFullDate } from '../../../lib/util';
import keepSessionAliveIcon from '../../../media/keep-session-alive.png';
import { FortressIcon } from '../../../media/svg_icons';
import { getUserTFAStatus } from '../../../selectors/auth';
import { IApiKey } from '../../../types/api_keys';
import { II18nextT } from '../../../types/i18n';
import { DispatchedFunction } from '../../../types/typescript_helpers';
import { HideMobile } from '../../utility_components';
import {
  $ErrorButton,
  $GreenLightSmallButton,
  $InfoSmallButton,
  $RedLightSmallButton,
  $SuccessButton,
} from '../../widgets/buttons';
import $Checkbox from '../../widgets/Checkbox';
import { $CodeInputDark } from '../../widgets/CodeInput';
import Header from '../../widgets/Header';
import { $InputDark, $ReadOnlyInputDark } from '../../widgets/Input';
import $Link from '../../widgets/Link';
import LoadingAnimation from '../../widgets/LoadingAnimation';
import { $HeaderWrapper } from '../../widgets/PageHeaderWrappers';
import GenericTooltip from '../../widgets/tooltips/GenericTooltip';
import { $SectionButton, $SettingsWrapper, SettingsSectionGeneric } from './settings_components';

const $ApiKey = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid ${(p) => p.theme.colors.brandPrimaryLighter};
  color: ${(p) => p.theme.components.settingsSections.heading.color};
`;
const $ApiKeyRowGroup = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.colors.brandPrimaryLight};
  margin: 7px;
`;
const $ApiKeyRow = styled.div`
  display: flex;
  align-items: center;
  min-height: 35px;
  @media screen and ${(p) => p.theme.device.laptop} {
    flex-direction: column;
    align-items: start;
    margin-bottom: 1rem;
  }
`;
const $ApiKeyLabel = styled.div`
  width: 150px;
  font-weight: bold;
  padding: 5px;
`;
const $ApiKeyInfo = styled.div`
  padding: 5px;
  flex-grow: 1;
`;
const $ApiKeySuspended = styled.span`
  color: white;
  background-color: ${(p) => p.theme.colors.sell};
  font-size: ${(p) => p.theme.fontSize.smaller};
  text-transform: uppercase;
  font-weight: bold;
  padding: 2px 4px 1px;
  cursor: not-allowed;
`;

const $CheckboxWrapper = styled.div`
  padding: 5px 0;
`;
const $ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & button {
    margin-right: 1rem;
  }
`;

const $TextArea = styled($InputDark).attrs({ as: 'textarea' })`
  min-width: 300px;
  min-height: 60px;
  max-height: 150px;
  resize: horizontal;
`;

interface IApiKeyProps {
  apiKey: IApiKey;
  enabledTFA: boolean;
  loadApiSecretKey: DispatchedFunction<typeof loadApiSecretKey>;
  editApiKey: DispatchedFunction<typeof editApiKeyOrThrow>;
  deleteApiKey: DispatchedFunction<typeof deleteApiKey>;
  performingRequest: boolean;
  tfa_token?: string;
}

class ApiKey extends React.PureComponent<IApiKeyProps> {
  static contextType: any = I18nContext;

  state = { tfa_token: '', ...this.props.apiKey };

  checkboxStyle: any = {
    marginRight: '5px',
  };
  tfaStyles: any = { width: 'auto', marginRight: 10 };

  disableApiKey = () => {
    this.setState({ disabled_at: 'disabled' }, () => this.props.editApiKey(this.state));
  };

  enableApiKey = () => {
    this.setState({ disabled_at: null }, () => this.props.editApiKey(this.state));
  };

  componentDidUpdate(): void {
    if (!this.state.secret_key && this.props.apiKey.secret_key) {
      this.setState({ secret_key: this.props.apiKey.secret_key });
    }
  }

  // Disable save button if we are sending a request
  // if api key is suspended by admin
  // or some key options are changed
  isSaveButtonDisabled = () => {
    return !!(
      this.props.performingRequest ||
      this.state.suspended_at ||
      lodash.isEqual(lodash.omit(this.state, ['tfa_token']), this.props.apiKey)
    );
  };

  render() {
    const { t }: II18nextT = this.context;

    const {
      tfa_token,
      api_key,
      cidr,
      created_at,
      disabled_at,
      suspended_at,
      permission_cancel_order,
      permission_place_order,
      permission_read,
      permission_withdraw,
      secret_key,
      updated_at,
      description,
    } = this.state;

    return (
      <$ApiKey>
        <$ApiKeyRowGroup>
          <$ApiKeyRow>
            <$ApiKeyLabel>{t('settings:api.key')}</$ApiKeyLabel>
            <$ApiKeyInfo>
              <$ReadOnlyInputDark style={{ width: '100%' }} value={api_key} />
            </$ApiKeyInfo>
          </$ApiKeyRow>

          <$ApiKeyRow>
            <$ApiKeyLabel>{t('settings:api.secretKey')}</$ApiKeyLabel>
            <$ApiKeyInfo>
              {secret_key ? (
                <$ReadOnlyInputDark value={secret_key} />
              ) : (
                <>
                  {this.props.enabledTFA && (
                    <GenericTooltip overlay="TFA OTP code">
                      <$CodeInputDark
                        value={tfa_token}
                        onChange={(e) => this.setState({ tfa_token: e.currentTarget.value })}
                        style={this.tfaStyles}
                      />
                    </GenericTooltip>
                  )}
                  <$InfoSmallButton
                    disabled={this.props.enabledTFA && tfa_token.length !== 6}
                    onClick={() => this.props.loadApiSecretKey(api_key, tfa_token)}
                  >
                    {t('settings:api.showSecretKey')}
                  </$InfoSmallButton>
                </>
              )}
            </$ApiKeyInfo>
          </$ApiKeyRow>

          <$ApiKeyRow>
            <$ApiKeyLabel>{t('settings:api.status')}</$ApiKeyLabel>
            <$ApiKeyInfo>
              {suspended_at ? (
                <$ApiKeySuspended>{t('settings:api.statusOptions.suspended')}</$ApiKeySuspended>
              ) : disabled_at ? (
                <$RedLightSmallButton onClick={this.enableApiKey}>
                  {t('settings:api.statusOptions.disabled')}
                </$RedLightSmallButton>
              ) : (
                <$GreenLightSmallButton onClick={this.disableApiKey}>
                  {t('settings:api.statusOptions.enabled')}
                </$GreenLightSmallButton>
              )}
            </$ApiKeyInfo>
          </$ApiKeyRow>

          <$ApiKeyRow>
            <$ApiKeyLabel>{t('settings:api.statusOptions.created')}</$ApiKeyLabel>
            <$ApiKeyInfo>{formatFullDate(created_at)}</$ApiKeyInfo>
          </$ApiKeyRow>

          <$ApiKeyRow>
            <$ApiKeyLabel>{t('settings:api.statusOptions.updated')}</$ApiKeyLabel>
            <$ApiKeyInfo>{formatFullDate(updated_at)}</$ApiKeyInfo>
          </$ApiKeyRow>

          <$ApiKeyRow>
            <$ApiKeyLabel>{t('settings:api.description')}</$ApiKeyLabel>
            <$ApiKeyInfo>
              <$TextArea
                value={description || ''}
                size={40}
                style={{ width: 'auto' }}
                onChange={(e) => this.setState({ description: e.currentTarget.value })}
              />
            </$ApiKeyInfo>
          </$ApiKeyRow>
        </$ApiKeyRowGroup>

        <$ApiKeyRowGroup>
          <$ApiKeyRow>
            <$ApiKeyLabel>{t('settings:api.ipAccessRestriction')}</$ApiKeyLabel>
            <$ApiKeyInfo>
              <$InputDark
                value={cidr}
                size={20}
                style={{ width: 'auto' }}
                onChange={(e) => this.setState({ cidr: e.currentTarget.value })}
              />
            </$ApiKeyInfo>
          </$ApiKeyRow>

          <$ApiKeyRow>
            <$ApiKeyLabel>{t('settings:api.permissions')}</$ApiKeyLabel>
            <$ApiKeyInfo>
              <$CheckboxWrapper>
                <$Checkbox
                  variant="dark"
                  name={`read_${api_key}`}
                  checked={permission_read}
                  onChange={(e) => this.setState({ permission_read: e.currentTarget.checked })}
                  style={this.checkboxStyle}
                >
                  {t('settings:api.permissionsOptions.read')}
                </$Checkbox>
              </$CheckboxWrapper>

              <$CheckboxWrapper>
                <$Checkbox
                  name={`place_order_${api_key}`}
                  variant="dark"
                  checked={permission_place_order}
                  onChange={(e) =>
                    this.setState({ permission_place_order: e.currentTarget.checked })
                  }
                  style={this.checkboxStyle}
                >
                  {t('settings:api.permissionsOptions.placeOrder')}
                </$Checkbox>
              </$CheckboxWrapper>

              <$CheckboxWrapper>
                <$Checkbox
                  name={`cancel_order_${api_key}`}
                  variant="dark"
                  checked={permission_cancel_order}
                  onChange={(e) =>
                    this.setState({ permission_cancel_order: e.currentTarget.checked })
                  }
                  style={this.checkboxStyle}
                >
                  {t('settings:api.permissionsOptions.cancelOrder')}
                </$Checkbox>
              </$CheckboxWrapper>

              <$CheckboxWrapper>
                <$Checkbox
                  name={`withdraw_${api_key}`}
                  variant="dark"
                  checked={permission_withdraw}
                  onChange={(e) => this.setState({ permission_withdraw: e.currentTarget.checked })}
                  style={this.checkboxStyle}
                >
                  {t('settings:api.permissionsOptions.withdrawFunds')}
                </$Checkbox>
              </$CheckboxWrapper>
            </$ApiKeyInfo>
          </$ApiKeyRow>
        </$ApiKeyRowGroup>

        <$ApiKeyRowGroup style={{ borderBottom: 'unset' }}>
          <$ApiKeyRow>
            <$ApiKeyLabel>{t('actions')}</$ApiKeyLabel>
            <$ApiKeyInfo>
              <$ActionsWrapper>
                <$SuccessButton
                  disabled={this.isSaveButtonDisabled()}
                  onClick={() => this.props.editApiKey(this.state)}
                >
                  {t('button.saveChanges')}
                </$SuccessButton>
                <$ErrorButton
                  onClick={() => this.props.deleteApiKey(api_key)}
                  disabled={this.props.performingRequest}
                >
                  {t('button.delete')}
                </$ErrorButton>
              </$ActionsWrapper>
            </$ApiKeyInfo>
          </$ApiKeyRow>
        </$ApiKeyRowGroup>
      </$ApiKey>
    );
  }
}

// *********************************************************************************************************************

const $ApiKeys = styled.div`
  & > div:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

interface IApiKeysListProps extends ConnectedProps<typeof connector> {}
interface IApiKeysListState {
  error: ITranslatedError;
  apiKeyRequests: { [key: string]: boolean };
}

class ApiKeySettings extends React.PureComponent<IApiKeysListProps, IApiKeysListState> {
  mounted: boolean;
  constructor(props) {
    super(props);
    this.state = {
      apiKeyRequests: {},
      error: null,
    };
  }

  updateListOfApiKeys = () => {
    if (this.mounted) {
      this.props.loadApiKeys();
    }
  };

  componentDidMount() {
    this.mounted = true;
    this.updateListOfApiKeys();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(
    prevProps: IApiKeysListProps,
    prevState: IApiKeysListState,
    snapshot: any
  ): void {
    // Delete validation error if there are no API keys present
    if (!this.props.apiKeys.length) {
      this.state.error && this.setState({ error: null });
    }
  }

  editApiKey = (payload: IApiKey) => {
    this.setState({
      error: null,
      apiKeyRequests: {
        ...this.state.apiKeyRequests,
        [payload.api_key]: true,
      },
    });
    return this.props
      .editApiKeyOrThrow(payload)
      .catch((error) => {
        if (this.mounted) {
          this.setState({
            error,
          });
        }
      })
      .finally(() => {
        this.setState({
          apiKeyRequests: {
            ...this.state.apiKeyRequests,
            [payload.api_key]: false,
          },
        });
      });
  };

  deleteApiKey = (apiKey: string) => {
    this.setState({
      error: null,
      apiKeyRequests: {
        [apiKey]: true,
      },
    });
    return this.props.deleteApiKey(apiKey).finally(() => {
      this.setState({
        apiKeyRequests: {
          ...this.state.apiKeyRequests,
          [apiKey]: false,
        },
      });
    });
  };

  renderContent() {
    const { apiKeys } = this.props;

    if (!apiKeys) {
      // Show empty until we get initial list of api keys
      return null;
    }

    if (!apiKeys.length) {
      // Show message if user doesn't have any api keys
      return (
        <$ApiKeys>
          <I18n>
            {(t) => (
              <h3>
                <p>{t('settings:api.emptyState.initialMessage')}</p>

                {this.props.apiDocsLink && (
                  <p>
                    {t('settings:api.emptyState.documentationPrefixFollowedByLink')}{' '}
                    <$Link href={this.props.apiDocsLink} target="_blank">
                      {this.props.apiDocsLink}
                    </$Link>
                  </p>
                )}

                <p>{t('settings:api.emptyState.createAnApiKeyCTA')}</p>
              </h3>
            )}
          </I18n>
        </$ApiKeys>
      );
    }

    return (
      <$ApiKeys>
        {apiKeys.map((apiKey) => (
          <ApiKey
            key={apiKey.api_key}
            apiKey={apiKey}
            enabledTFA={this.props.enabledTFA}
            loadApiSecretKey={this.props.loadApiSecretKey}
            editApiKey={this.editApiKey}
            deleteApiKey={this.deleteApiKey}
            performingRequest={this.state.apiKeyRequests[apiKey.api_key] || false}
          />
        ))}
      </$ApiKeys>
    );
  }

  render() {
    return (
      <I18n>
        {(t) => (
          <>
            <HideMobile>
              <$HeaderWrapper>
                <Header
                  icon={FortressIcon}
                  subtitle={t('settings:api.api')}
                  title={t('settings:api.keys')}
                  variant="dark"
                />
              </$HeaderWrapper>
            </HideMobile>
            <$SettingsWrapper>
              <SettingsSectionGeneric
                label={t('settings:api.title')}
                description=""
                icon={keepSessionAliveIcon}
                validationError={this.state.error}
                rightContent={
                  <LoadingAnimation
                    requests={['getKeys', 'createKey', 'editKey', 'deleteKey', 'getSecretKey']}
                  >
                    <$SectionButton onClick={() => this.props.createNewApiKey()}>
                      {t('settings:api.addNewKey')}
                    </$SectionButton>
                  </LoadingAnimation>
                }
              >
                {this.renderContent()}
              </SettingsSectionGeneric>
            </$SettingsWrapper>
          </>
        )}
      </I18n>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    apiKeys: state.apiKeys,
    enabledTFA: getUserTFAStatus(state),
    apiDocsLink: state.env.links.apiDocs,
  }),
  {
    loadApiKeys,
    loadApiSecretKey,
    editApiKeyOrThrow,
    deleteApiKey,
    createNewApiKey,
  }
);

export default connector(ApiKeySettings);
