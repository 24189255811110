import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  loadUserCardPayments,
  markPaymentUserFlowCompleted,
} from '../../../actions/transactions/card_payments';
import { I18n } from '../../../lib/i18n';
import styled from '../../../lib/styled_components';

const $CardPaymentSubmittedScreen = styled.div`
  max-width: 24rem;
  font-size: ${(p) => p.theme.fontSize.larger};
`;

interface ICardPaymentSubmittedScreenProps
  extends RouteComponentProps<{ paymentId: string }>,
    ConnectedProps<typeof connector> {}

class CardPaymentSubmittedScreen extends React.PureComponent<ICardPaymentSubmittedScreenProps> {
  componentDidMount() {
    return this.props.markPaymentUserFlowCompleted(this.props.match.params.paymentId).then(() => {
      // TODO: We are loading unfiltered payments here. Is this OK?
      return this.props.loadUserCardPayments(null, true);
    });
  }

  render() {
    return (
      <I18n>
        {(t) => (
          <$CardPaymentSubmittedScreen>
            {t('transactions:cardPayments.paymentSubmittedMessage')}
          </$CardPaymentSubmittedScreen>
        )}
      </I18n>
    );
  }
}

const connector = connect(null, {
  loadUserCardPayments,
  markPaymentUserFlowCompleted,
});

export default withRouter(connector(CardPaymentSubmittedScreen));
