import { IWindowSize, updateWindowSize } from '../actions/document';
import { Store } from './store';
import { setPageUnmounting } from '../actions/app';

export class WindowListener {
  constructor(private store: Store, private window: Window) {
    this.window.addEventListener('resize', (e) => {
      this.update((e.target as Window).innerWidth);
    });

   this.window.addEventListener('beforeunload', (e) => {
    // Detect when page is unmounting
    this.store.dispatch(setPageUnmounting());
   });
  }

  start() {
    this.update(window.innerWidth);
  }

  private update = (width) => {
    const {
      mobileBreakpoint,
      tabletBreakpoint,
      laptopBreakpoint,
    } = this.store.getState().app.theme.base;

    const currentSize = this.store.getState().document.windowSize;
    let newSize: IWindowSize;
    if (width <= mobileBreakpoint) {
      newSize = 'mobile';
    } else if (width <= tabletBreakpoint) {
      newSize = 'tablet';
    } else if (width <= laptopBreakpoint) {
      newSize = 'laptop';
    } else {
      newSize = 'desktop';
    }
    if (newSize !== currentSize) {
      this.store.dispatch(updateWindowSize(newSize));
    }
  };
}
