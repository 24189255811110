import { createSelector } from 'reselect';

import { IRouteDescriptor, routeDescriptorToLocation } from '../actions/routing';
import { IEnv } from '../bl/env';
import { getSession } from '../selectors/auth';
import { getManagedConfig } from '../selectors/transactions';
import { ISession } from '../types/auth';
import {
  IApiManagedUserConfig,
  IInstrument,
  IMarketInstrument,
  IPair,
} from '../types/backend_definitions';
import { DeepReadonly } from '../types/typescript_helpers';
import { ICustomHref, ICustomLocation, ICustomLocationDescriptor } from './history';
import { IState } from './store';

// *********************************************************************************************************************

type RouteTemplateSegment = { key?: never; literal: string } | { key: string; literal?: never };

export const appendPathname = (prefix: string, suffix: string) => {
  prefix = prefix || '';
  suffix = suffix || '';

  // Strip tailing slash
  while (prefix[prefix.length - 1] === '/') {
    prefix = prefix.substring(0, -1);
  }

  let result = prefix + suffix;
  if (!result) {
    result = '/';
  }

  return result;
};

/**
 * Convert route like "/a/:something/b"
 * into a pattern like [{literal: "a"}, {key: "something"}, {literal: "b"}]
 * which can be used to generate url-s
 */
export const makeRouteTemplate = (route: string): RouteTemplateSegment[] => {
  const template: RouteTemplateSegment[] = [];
  const chunks = route
    .split('/')
    // Remove the empty first chunk
    .slice(1);

  if (route.length > 1 && route[route.length - 1] === '/') {
    // Remove the empty last chunk
    chunks.pop();
  }

  for (const chunk of chunks) {
    if (chunk[0] === ':') {
      // This is a "hole" segment
      const sliceTo = chunk[chunk.length - 1] === '?' ? -1 : Number.MAX_SAFE_INTEGER;
      template.push({ key: chunk.slice(1, sliceTo) });
    } else {
      // This is a normal segment
      template.push({ literal: chunk });
    }
  }
  return template;
};

/**
 * Given a route template and list of arguments, make a url
 */
export const makeUrlFromTemplate = (
  template: RouteTemplateSegment[],
  params: object = undefined
) => {
  const chunks = [];
  for (const segment of template) {
    if (segment.key) {
      // A hole. Fill it
      if (params) {
        if (segment.key in params) {
          chunks.push(params[segment.key]);
        }
      }
    } else {
      // Literal segment
      chunks.push(segment.literal);
    }
  }
  return '/' + chunks.join('/');
};

// *********************************************************************************************************************

interface IRouteSpecData<TParams = object, TRouteParams = object> {
  /** Path template at which the route should be mounted */
  path: string;
  /** Path must be exact. Eg. if you put "/a/b", "/a/b/c" will not trigger it */
  exact: boolean;
  /** This route is only available to logged in users. Just a flag to inform other parts of the system. */
  secured: boolean;
  /** This route is only available to anonymous users. Just a flag to inform other parts of the system. */
  anonymous: boolean;
  /** Route mounts on top of any other (static) route. Used for stuff like Login dialog */
  dynamic: boolean;
  /** When route is generated from a different route, this will be set to the prefix. Eg. /exchange/:pair + /login = /exchange/:pair/login */
  prefixPath?: string;
  /** Dynamic getter to determine whether route is enabled */
  isEnabled?: (props: IRouteEnablementProps, params: TParams) => boolean;
}
const DEFAULT_ROUTE_SPEC: IRouteSpecData = {
  path: null,
  exact: false,
  secured: false,
  anonymous: false,
  dynamic: false,
};

export interface IRouteSpec extends IRouteSpecData {
  /**
   * Unique ID for this route. Eg. "BALANCES". Can be used like R[id] to get the original route object.
   */
  id: IRouteId;

  /**
   * Produce a URL based on route args. Eg: /exchange/SFX_BTC
   */
  url: (params) => string;

  /**
   * Produce a location descriptor object based on route args. Eg: { pathname: /exchange/SFX_BTC, state: ... }
   */
  to: (params) => ICustomLocationDescriptor;

  /**
   * Returns true if given location is currently active
   */
  isActive: (currentLocation: ICustomLocation) => boolean;

  /**
   * Returns true if this route should be enabled based on current settings and route params
   */
  isEnabled: (props: IRouteEnablementProps, params?: any) => boolean;

  /**
   * Produces a new RouteSpec where url-s are prefixed with given url,
   * which can be a string, location or a RouteSpec without arguments.
   */
  prefixed: (prefix: ICustomHref | IRouteDescriptor) => this;

  /**
   * Unique string description of this route
   */
  describe: () => string;
}

export interface IRouteSpecWithoutParams extends IRouteSpec {
  url: (routeProps?) => string;
  to: (routeProps?) => ICustomLocationDescriptor;
  isEnabled: (props: IRouteEnablementProps) => boolean;
}
interface IRouteSpecWithParams<TParams extends object, TRouteParams extends object>
  extends IRouteSpec {
  url: (params: TParams, routeProps?: TRouteParams) => string;
  to: (params: TParams, routeProps?: TRouteParams) => ICustomLocationDescriptor;
  isEnabled: (props: IRouteEnablementProps, params: TParams) => boolean;
}
export type IRouteSpecTyped<TParams, TRouteParams extends object> = [TParams] extends [never]
  ? IRouteSpecWithoutParams
  : TParams extends object
  ? IRouteSpecWithParams<TParams, TRouteParams>
  : IRouteSpecWithoutParams;

const doMakeRoute = <TParams, TRouteParams extends object>(
  specData: IRouteSpecData<TParams, TRouteParams>
): IRouteSpecTyped<TParams, TRouteParams> => {
  const template = makeRouteTemplate(specData.path);

  const isEnabledFn = specData.isEnabled;

  const routeSpec = {
    ...specData,
    // These ID-s will be filled in later
    id: null,
    url: createSelector([(params) => params, (_, routeProps?) => routeProps], (params, _) =>
      makeUrlFromTemplate(template, params)
    ),
    to: createSelector(
      [(params) => params, (_, routeProps?) => routeProps],
      (params, routeProps?): ICustomLocationDescriptor => {
        return {
          pathname: makeUrlFromTemplate(template, params),
          state: {
            id: routeSpec.id,
            prefixPath: routeSpec.prefixPath,
            routeProps,
          },
          search: params ? params.search : undefined,
        };
      }
    ),
    isActive: (currentLocation: ICustomLocation) => {
      let currentPath = currentLocation.pathname;
      if (
        currentLocation.state &&
        currentLocation.state.id &&
        currentLocation.state.id === routeSpec.id
      ) {
        // Try to figure out path from state. This path will be the "raw" path, with placeholders like ":pair"
        // So it will more closely match the route's path
        const routeSpec = R[currentLocation.state.id];
        if (routeSpec) {
          currentPath = (currentLocation.state.prefixPath || '') + routeSpec.path;
        }
      }

      // TODO: Make it work for exchange route and settings route
      const active = routeSpec.exact
        ? currentPath === routeSpec.path
        : currentPath.startsWith(routeSpec.path);

      return active;
    },
    isEnabled: (props: IRouteEnablementProps, params: any): boolean => {
      // Make sure this fn is included
      return isEnabledFn ? isEnabledFn(props, params || {}) : true;
    },
    prefixed: (prefix: ICustomHref | IRouteDescriptor) => {
      if (!prefix) {
        // Without prefix, we just return the same route as it is
        return routeSpec;
      }

      // Try to resolve IRouteDescriptor
      prefix = routeDescriptorToLocation(prefix as any) || prefix;

      const prefixPath: string =
        ((prefix as IRouteSpecWithoutParams).url && (prefix as IRouteSpecWithoutParams).url()) ||
        (prefix as ICustomLocationDescriptor).pathname ||
        (prefix as string);

      const prefixedRoute = doMakeRoute<TParams, TRouteParams>({
        ...specData,
        prefixPath: appendPathname(specData.prefixPath || '', prefixPath),
        path: appendPathname(prefixPath, specData.path),
        // When making a prefixed route, it is no longer dynamic
        dynamic: false,
      });
      // Transplant the id
      prefixedRoute.id = routeSpec.id;
      return prefixedRoute;
    },
    relative: () => {
      return routeSpec.path[0] === '.' ? routeSpec : routeSpec.prefixed('.');
    },
    describe: () => {
      return specData.prefixPath ? `${routeSpec.id}[P: ${specData.prefixPath}]` : routeSpec.id;
    },
  } as IRouteSpec;

  return routeSpec as any;
};

const route = <TParams extends object | never = never, TRouteParams extends object = {}>(
  path: string,
  spec?: Partial<IRouteSpecData<TParams>>
) => doMakeRoute<TParams, TRouteParams>({ ...DEFAULT_ROUTE_SPEC, ...spec, path });

// *********************************************************************************************************************

export interface IRouteEnablementProps {
  env: DeepReadonly<IEnv>;
  session: ISession;
  managedUserConfig: DeepReadonly<IApiManagedUserConfig>;
}

export const getRouteEnablementProps = createSelector(
  [(state: IState) => state.env, getSession, getManagedConfig],
  (env, session, managedUserConfig): IRouteEnablementProps => {
    return {
      env,
      session,
      managedUserConfig,
    };
  }
);

// *********************************************************************************************************************

export type ISettingsPage = 'account' | 'api' | 'kyc' | 'notifications' | 'security' | 'support';
export type IReportsPage =
  | 'open-orders'
  | 'trade-history'
  | 'card-payments'
  | 'deposits'
  | 'withdrawals'
  | 'security-events';
export type IDevPage = 'icons';
export type IManagedPage = 'setup' | 'portfolio' | 'buy-orders' | 'sell-orders';

// Tokens for easier route generation
const exact = true;
const secured = true;
const dynamic = true;
const anonymous = true;

const REPORTS = route<{ page: IReportsPage }>('/reports/:page', {
  secured,
  isEnabled({ env }, { page }) {
    if (env.whitelabel.name === 'chyngex' && page === 'card-payments') {
      return false;
    }
    return true;
  },
});
const BALANCES = route('/balances', { secured });

const R = {
  HOME: route('/', { exact }),

  EXCHANGE_HOME: route('/exchange', { exact }),
  EXCHANGE: route<{ pair: IPair }>('/exchange/:pair'),

  MARKETS: route('/market'),

  DEPOSIT: route<{ instrument: IInstrument }>('/balances/deposit/:instrument', { secured }),
  WITHDRAW: route<{ instrument: IInstrument }>('/balances/withdraw/:instrument', { secured }),

  REPORTS,
  REPORTS_HOME: route('/reports', { exact, secured }),

  BALANCES,

  BUY_WITH_CARD: route('/buy-with-card', {
    isEnabled({ env }) {
      return env.whitelabel.name !== 'chyngex';
    },
  }),

  SETTINGS: route<{ page: ISettingsPage }>('/settings/:page', { secured }),
  SETTINGS_HOME: route('/settings', { exact, secured }),

  TERMS_OF_USE: route('/terms-of-use'),
  PRIVACY_POLICY: route('/privacy-policy'),

  MAINTENANCE: route('/maintenance'),
  DEV: route<{ page: IDevPage }>('/dev/:page?', {
    isEnabled({ env }) {
      return env.nodeEnv === 'development';
    },
  }),

  LOGIN: route('/login', { dynamic, anonymous }),
  REGISTER: route('/register', { dynamic, anonymous }),
  SESSION_TERMINATED: route('/session-terminated', { dynamic, anonymous }),

  PASSWORD_RESET_REQUEST: route('/request-password-reset', { exact, dynamic, anonymous }),
  PASSWORD_RESET_EXECUTE: route<{ token: string }>('/password-reset/:token', { exact, dynamic }),

  MANAGED: route<{ page: IManagedPage }, { instrument?: IMarketInstrument }>('/managed/:page', {
    secured,
  }),
  MANAGED_HOME: route('/managed', {
    exact,
    secured,
    isEnabled({ env, managedUserConfig }, { page }) {
      // Hide when hide_service is null or true
      return env.whitelabel.name !== 'chyngex' && managedUserConfig.hide_service === false;
    },
  }),
  MANAGED_AGREEMENT: route('/managed-agreement'),

  EMAIL_VERIFICATION_EXECUTE: route<{ token: string }>('/email-verification/:token', {
    exact,
    dynamic,
  }),

  WITHDRAWAL_CONFIRMATION: route<{ token: string }>('/withdrawal-confirmation/:token', {
    exact,
    dynamic,
  }),

  CARD_PAYMENT_SUBMITTED: route<{ paymentId: string }>('/payment-submitted/:paymentId', {
    dynamic,
    exact,
  }),
  CARD_PAYMENT_DECLINED: route('/payment-declined', { dynamic, exact }),
};

export type IRouteId = keyof typeof R;

// Define id-s
for (const id in R) {
  if (R.hasOwnProperty(id)) {
    R[id].id = id;
  }
}

export default R;
