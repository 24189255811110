import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { ICsvDownloadFunctions, downloadCSV } from '../../../actions/download_csv';
import {
  loadUserCardPayments,
  markPaymentUserFlowCompleted,
  resetUserCardPayments,
} from '../../../actions/transactions/card_payments';
import { IState } from '../../../lib/store';
import { IThemeColorsUnion } from '../../../lib/styled_components';
import { formatFullDate, formatFullDateRegular, formatNumberToFixed } from '../../../lib/util';
import { BanknoteWithArrowsIcon } from '../../../media/svg_icons';
import {
  ICardPaymentConfig,
  ICardPaymentStatus,
  ICardPaymentsFixed,
  getCardPaymentConfig,
  getCardPaymentStatus,
  getCardPaymentsPaginationState,
} from '../../../selectors/card_payments';
import { IApiCensoredCardPayment, IInstrument } from '../../../types/backend_definitions';
import { II18nextT } from '../../../types/i18n';
import { ALL_INSTRUMENTS } from '../../../types/instruments';
import { ITranslations } from '../../../types/translations';
import $ColorText from '../../widgets/ColorText';
import Header from '../../widgets/Header';
import HorizontalScrollTable, {
  $FixedHeightCell,
  $FixedHeightTableRow,
  $HighlightableTableRow,
  IGridColumnProps,
  IScrollableRow,
  ITableParts,
} from '../../widgets/HorizontalScrollTable';
import InstrumentIcon from '../../widgets/InstrumentIcon';
import { $ToolbarLabel } from '../../widgets/Label';
import PrettyDecimals, { SidedPrettyDecimals } from '../../widgets/PrettyDecimals';
import {
  $ActionElementsWrapper,
  $HeaderWrapperJustified,
  $InnerWrapper,
  $NoDataWrapper,
  $Wrapper,
  DownloadCSVButton,
  IDownloadCSVAction,
} from '../../widgets/Reports';
import { ResponsiveSelectWrapper, SimpleSelect } from '../../widgets/Select';
import { $DottedSpan } from '../../widgets/Span';
import { $Cell, $EllipsisWrapper, ICellProps } from '../../widgets/Table';
import TableActionIndicator from '../../widgets/TableActionIndicator';
import ClickableTooltipWithCopy from '../../widgets/tooltips/ClickableTooltipWithCopy';

interface ICardPaymentStatusData {
  color: IThemeColorsUnion;
  message: ITranslations;
}

const CARD_PAYMENT_STATUS_DATA: { [key in ICardPaymentStatus]: ICardPaymentStatusData } = {
  declined: { color: 'sell', message: 'transactions:cardPayments.statuses.declined' },
  processed: { color: 'success', message: 'transactions:cardPayments.statuses.processed' },
  submitted: { color: 'info', message: 'transactions:cardPayments.statuses.submitted' },
  pending: { color: 'grayLighter', message: 'transactions:cardPayments.statuses.pending' },
};

interface IReportsPageProps extends RouteComponentProps, ConnectedProps<typeof connector> {
  stickHeader: boolean;
  getTableHeaderTopOffset?: (topOffset) => void;
  downloadCsv: IDownloadCSVAction;
  ICsvDownloadFunctions: ICsvDownloadFunctions;
}
interface IReportsPageState {
  filterInstrument: string;
  filterOptions: Object;
  loading: boolean;
}

type ICellNames = 'date' | 'instrument' | 'status' | 'orderId' | 'quantity' | 'price';

const CELLS: { [key in ICellNames]?: Partial<ICellProps> } = {
  date: { align: 'center', minWidth: '9rem' },
  instrument: { align: 'center', minWidth: '7.5rem' },
  status: { align: 'center', minWidth: '5rem' },
  orderId: { align: 'center', minWidth: '9rem' },
  quantity: { align: 'center', minWidth: '7rem' },
  price: { align: 'center', minWidth: '7rem' },
};

const GRID_COLUMNS: IGridColumnProps<ICellNames> = {
  fixed: { date: { width: '1fr' } },
  scrollable: {
    instrument: { width: '7.5rem' },
    status: { width: '5rem' },
    orderId: { width: '3fr' },
    quantity: { width: '1fr' },
    price: { width: '1fr' },
  },
};

class CardPayments extends React.PureComponent<IReportsPageProps, IReportsPageState> {
  static contextType: any = I18nContext;
  private mounted: boolean = false;

  constructor(props) {
    super(props);

    this.state = {
      filterInstrument: 'all',
      filterOptions: null,
      loading: false,
    };
  }

  deriveFilterOptions(config: ICardPaymentConfig) {
    return config.instrumentConfig.supported_crypto.reduce(
      (res, instrument) => {
        res[instrument] = { value: instrument, label: instrument };
        return res;
      },
      { all: { value: 'all', label: this.context.t('all') } }
    );
  }

  componentDidMount() {
    this.mounted = true;
    this.props.resetUserCardPayments();
    this.onLoadUserCardPayments(null, true);
    this.setState({ filterOptions: this.deriveFilterOptions(this.props.cardPaymentConfig) });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onLoadUserCardPayments = (instrument?: IInstrument, firstPage?: boolean) => {
    this.setState({ loading: true });
    return this.props
      .loadUserCardPayments(instrument, firstPage)
      .finally(() => this.mounted && this.setState({ loading: false }));
  };

  renderScrollableRow: IScrollableRow = (
    payment: IApiCensoredCardPayment,
    index,
    highlightSelectedRow
  ) => {
    const { t }: II18nextT = this.context;
    const status = getCardPaymentStatus(payment);
    const statusData = CARD_PAYMENT_STATUS_DATA[status];

    return (
      <$FixedHeightTableRow
        onMouseOver={() => highlightSelectedRow(index)}
        key={payment.payment_id}
      >
        <$Cell {...CELLS.instrument}>
          <InstrumentIcon
            size={22}
            name={payment.instrument}
            type="white"
            style={{ verticalAlign: 'middle' }}
          />
          &nbsp;&nbsp;
          <strong>{payment.instrument}</strong>
        </$Cell>

        <$Cell {...CELLS.status}>
          <$ColorText color={statusData.color}>{t(statusData.message)}</$ColorText>
        </$Cell>

        <$Cell {...CELLS.orderId}>
          <ClickableTooltipWithCopy color="white" tooltipText={payment.order_id}>
            <$EllipsisWrapper>
              <$DottedSpan color="white" monospace>
                {payment.order_id}
              </$DottedSpan>
            </$EllipsisWrapper>
          </ClickableTooltipWithCopy>
        </$Cell>

        <$Cell {...CELLS.quantity}>
          {status === 'declined' ? (
            <PrettyDecimals
              value={formatNumberToFixed(
                payment.quantity,
                ALL_INSTRUMENTS[payment.instrument].digits
              )}
              color="grayLighter"
            />
          ) : (
            <SidedPrettyDecimals
              value={formatNumberToFixed(
                payment.quantity,
                ALL_INSTRUMENTS[payment.instrument].digits
              )}
              side={'buy'}
            />
          )}
        </$Cell>

        <$Cell {...CELLS.price}>
          <PrettyDecimals
            value={formatNumberToFixed(
              payment.fiat_quantity,
              ALL_INSTRUMENTS[payment.fiat_instrument].digits
            )}
            color={status === 'declined' ? 'grayLighter' : 'white'}
          />{' '}
          <$ColorText color={status === 'declined' ? 'grayLighter' : 'white'}>
            {payment.fiat_instrument}
          </$ColorText>
        </$Cell>
      </$FixedHeightTableRow>
    );
  };

  renderTableParts(): ITableParts {
    const { t }: II18nextT = this.context;

    return {
      header: {
        fixed: () => <$FixedHeightCell {...CELLS.date}>{t('date')}</$FixedHeightCell>,
        scrollable: () => {
          return (
            <>
              <$FixedHeightCell {...CELLS.instrument}>{t('instrument')}</$FixedHeightCell>
              <$FixedHeightCell {...CELLS.status}>{t('transactions:status')}</$FixedHeightCell>
              <$FixedHeightCell {...CELLS.orderId}>
                {t('transactions:cardPayments.orderId')}
              </$FixedHeightCell>
              <$FixedHeightCell {...CELLS.quantity}>
                {t('transactions:cardPayments.quantity')}
              </$FixedHeightCell>
              <$FixedHeightCell {...CELLS.price}>{t('price')}</$FixedHeightCell>
            </>
          );
        },
      },
      body: {
        fixed: (highlightedRowIndex, highlightSelectedRow) => {
          return this.props.cardPaymentsPaginationState.items.fixed.map(
            (paymentFixed: ICardPaymentsFixed, index) => {
              return (
                <$HighlightableTableRow
                  onMouseOver={() => highlightSelectedRow(index)}
                  highlighted={index === highlightedRowIndex}
                  key={index}
                >
                  <$Cell {...CELLS.date} title={formatFullDate(paymentFixed.created_at)}>
                    {formatFullDateRegular(paymentFixed.created_at)}
                  </$Cell>
                </$HighlightableTableRow>
              );
            }
          );
        },
        scrollable: (payment: IApiCensoredCardPayment, index, highlightSelectedRow) =>
          this.renderScrollableRow(payment, index, highlightSelectedRow),
      },
    };
  }

  render() {
    const { t }: II18nextT = this.context;
    const criteria =
      this.state.filterInstrument !== 'all'
        ? {
            instrument: this.state.filterInstrument,
            sort_direction: ['desc'],
            sort_field: ['submitted_at', 'payment_id'],
          }
        : { sort_direction: ['desc'], sort_field: ['submitted_at', 'payment_id'] };

    return (
      <$Wrapper>
        <div>
          <$HeaderWrapperJustified stickHeader={this.props.stickHeader}>
            <Header
              title={t('reportsPage.cardPayments')}
              icon={BanknoteWithArrowsIcon}
              loadingKeys={['getUserCardPayments']}
              hideOnMobile={true}
            />
            {this.state.filterOptions && (
              <$ActionElementsWrapper>
                <div>
                  <$ToolbarLabel htmlFor="paymentsInstrument">{t('instrument')}:</$ToolbarLabel>
                  <ResponsiveSelectWrapper>
                    <SimpleSelect
                      inputId="paymentsInstrument"
                      width="8rem"
                      options={Object.values(this.state.filterOptions)}
                      value={this.state.filterOptions[this.state.filterInstrument]}
                      onChange={({ value }) => {
                        this.setState({ filterInstrument: value });
                        this.onLoadUserCardPayments(value === 'all' ? null : value, true);
                      }}
                      variant="transparent"
                    />
                  </ResponsiveSelectWrapper>
                </div>
                <div>
                  <DownloadCSVButton
                    downloadCsv={this.props.downloadCsv}
                    csvDownloadFunction={'getCardPaymentsCsv'}
                    criteria={criteria}
                  />
                </div>
              </$ActionElementsWrapper>
            )}
          </$HeaderWrapperJustified>

          {this.state.loading ? (
            <div />
          ) : !this.props.cardPaymentsPaginationState.total_records ? (
            <$NoDataWrapper>{t('reportsPage.noData.cardPayments')}</$NoDataWrapper>
          ) : (
            <$InnerWrapper>
              <HorizontalScrollTable
                data={this.props.cardPaymentsPaginationState.items.scrollable}
                gridColumns={GRID_COLUMNS}
                stickHeader={this.props.stickHeader}
                getTableHeaderTopOffset={this.props.getTableHeaderTopOffset}
                tableParts={this.renderTableParts()}
              >
                <TableActionIndicator
                  paginationState={this.props.cardPaymentsPaginationState}
                  requests={['getUserCardPayments']}
                  itemsCount={this.props.cardPaymentsPaginationState.items.length}
                  actionFn={() =>
                    this.props.loadUserCardPayments(
                      this.state.filterInstrument === 'all'
                        ? null
                        : (this.state.filterInstrument as IInstrument)
                    )
                  }
                />
              </HorizontalScrollTable>
            </$InnerWrapper>
          )}
        </div>
      </$Wrapper>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    cardPaymentsPaginationState: getCardPaymentsPaginationState(state),
    cardPaymentConfig: getCardPaymentConfig(state),
  }),
  {
    loadUserCardPayments,
    resetUserCardPayments,
    markPaymentUserFlowCompleted,
    downloadCsv: downloadCSV,
  }
);

export default withRouter(connector(CardPayments));
