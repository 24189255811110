import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { connect } from 'react-redux';

import lodash from '../../../lib/lodash';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { formatFullDate, formatHoursOnly, formatNumberToFixed } from '../../../lib/util';
import { isUserLoggedIn } from '../../../selectors/auth';
import { getCurrentPair } from '../../../selectors/exchange';
import {
  IMarketTradesData,
  getMarketTradesForCurrentInstrumentPair,
  getTradeDisplaySide,
  getTradeUserRole,
  getUserTradesForCurrentInstrumentPair,
} from '../../../selectors/trades';
import { LIST_SIZE_LIMIT } from '../../../types/constants';
import { II18nextT } from '../../../types/i18n';
import { IInstrumentPair, PRICE_DIGITS, pairInfo } from '../../../types/instruments';
import { ITrade } from '../../../types/trades';
import { ITranslations } from '../../../types/translations';
import { DateSplitScrollBox } from '../../widgets/DateSplitScrollBox';
import PrettyDecimals, { SidedPrettyDecimals } from '../../widgets/PrettyDecimals';
import {
  $Cell,
  $DigitCell,
  $Table,
  $TableHeading,
  $TableHeadingBadge,
  $TableRow,
  ICellProps,
} from '../../widgets/Table';
import GenericTooltip from '../../widgets/tooltips/GenericTooltip';
import TradeSideIndicator from '../../widgets/TradeSideIndicator';

// images

// styled components
const $LastTrades = styled($Table).attrs((p) => ({
  noPadding: true,
  overlayColor: p.theme.components.lastTrades.activeMenuItemColor,
}))`
  position: relative;
`;

const $TradeRow = styled($TableRow)<{ bold: boolean }>`
  font-weight: ${(p) => (p.bold ? 'bold' : 'none')};
`;

const $TradeSideWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

// component types and interfaces
type ICellNames = 'side' | 'time' | 'quantity' | 'price' | 'total';
const CELLS: { [key in ICellNames]: Partial<ICellProps> } = {
  side: { width: '1.8rem', align: 'left', noGrow: true },
  time: { width: '4.4rem', align: 'left', noGrow: true },
  quantity: { width: 'calc((100% - 6.2rem) * 0.33)', align: 'right', noGrow: true },
  price: { width: 'calc((100% - 6.2rem) * 0.33)', align: 'right', noGrow: true },
  total: { width: 'calc((100% - 6.2rem) * 0.33)', align: 'right', noGrow: true },
};

interface IPassedProps {
  tradeType: 'user' | 'market';
}

interface ILastTradesProps extends IPassedProps {
  tradesData: IMarketTradesData;
  pair: IInstrumentPair;
  loggedIn: boolean;
}

interface ILastTradesState {}

class LastTrades extends React.Component<ILastTradesProps, ILastTradesState> {
  static contextType: any = I18nContext;

  shouldComponentUpdate(nextProps: Readonly<ILastTradesProps>): boolean {
    if (
      lodash.isEqual(this.props.pair, nextProps.pair) &&
      lodash.isEqual(this.props.tradeType, nextProps.tradeType) &&
      lodash.isEqual(this.props.tradesData, nextProps.tradesData)
    ) {
      return false;
    }

    return true;
  }

  renderRow = (trade: ITrade) => {
    const { t }: II18nextT = this.context;

    const pair = pairInfo(trade.pair);

    // NOTE: For user we show a trade that was result of their BUY order as BUY,
    //       even though it was technically a SELL trade because it was matched by a SELL taker order.
    //       For trades we display to the world, we display the normal trade side.
    const displaySide = getTradeDisplaySide(trade);
    const userRoleInTrade = getTradeUserRole(trade);
    // Example of what this will be like: 'lastTrades.lastTrades.tradeSideLabels.buy.maker'
    const tooltipTitle = ('lastTrades.tradeSideLabels.' +
      displaySide +
      '.' +
      userRoleInTrade) as ITranslations;

    return (
      <$TradeRow
        key={trade.signature}
        noPointer
        noBottomBorder
        bold={userRoleInTrade !== 'uninvolved'}
      >
        <$Cell {...CELLS.side}>
          <GenericTooltip placement="left" trigger={['hover']} overlay={t(tooltipTitle)}>
            <$TradeSideWrapper>
              <TradeSideIndicator tradeSide={trade.side} userSide={trade.user_side} />
            </$TradeSideWrapper>
          </GenericTooltip>
        </$Cell>
        <$Cell {...CELLS.time} title={formatFullDate(trade.timestamp)}>
          {formatHoursOnly(trade.timestamp)}
        </$Cell>
        <$DigitCell {...CELLS.quantity}>
          <PrettyDecimals value={formatNumberToFixed(trade.quantity, pair.quote.digits)} />
        </$DigitCell>
        <$DigitCell {...CELLS.price}>
          <SidedPrettyDecimals
            value={formatNumberToFixed(trade.price, PRICE_DIGITS)}
            side={displaySide}
          />
        </$DigitCell>
        <$DigitCell {...CELLS.total}>
          <PrettyDecimals
            value={formatNumberToFixed(
              trade.user_side ? trade.total : trade.quantity * trade.price,
              pair.base.digits
            )}
          />
        </$DigitCell>
      </$TradeRow>
    );
  };

  render() {
    const { t }: II18nextT = this.context;
    const { pair } = this.props;

    if (!pair) {
      return null;
    }

    return (
      <$LastTrades>
        {!this.props.tradesData.tradesGroupsByDate.length && <div />}
        <$TableHeading noBorder>
          <$Cell {...CELLS.side}>&nbsp;</$Cell>
          <$Cell {...CELLS.time}>
            {t('lastTrades.columns.time')}
            <$TableHeadingBadge>{t('utc')}</$TableHeadingBadge>
          </$Cell>
          <$Cell {...CELLS.quantity}>
            <$TableHeadingBadge>{t('lastTrades.columns.amount')}</$TableHeadingBadge>
            {pair.quote.symbol}
          </$Cell>
          <$Cell {...CELLS.price}>
            <$TableHeadingBadge>{t('lastTrades.columns.price')}</$TableHeadingBadge>
            {pair.base.symbol}
          </$Cell>
          <$Cell {...CELLS.total}>
            <$TableHeadingBadge>{t('lastTrades.columns.total')}</$TableHeadingBadge>
            {pair.base.symbol}
          </$Cell>
        </$TableHeading>
        <DateSplitScrollBox
          mobileHeight="200px"
          laptopHeight="300px"
          data={this.props.tradesData.tradesGroupsByDate}
          startText={
            this.props.loggedIn
              ? t('momentDate.viewMoreTradesPrivate', { listSize: LIST_SIZE_LIMIT })
              : t('momentDate.viewMoreTradesGeneral', { listSize: LIST_SIZE_LIMIT })
          }
          showStartText={this.props.tradesData.tradesLength >= LIST_SIZE_LIMIT}
          renderRow={this.renderRow}
        />
      </$LastTrades>
    );
  }
}

export default connect((state: IState, props: IPassedProps) => ({
  loggedIn: isUserLoggedIn(state),
  tradesData:
    props.tradeType === 'user'
      ? getUserTradesForCurrentInstrumentPair(state)
      : getMarketTradesForCurrentInstrumentPair(state),
  pair: getCurrentPair(state),
}))(LastTrades);
