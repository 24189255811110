import lodash from '../lib/lodash';
import { Logger } from '../lib/logger';
import { commit, val } from '../lib/redux_helpers';
import { Store } from '../lib/store';
import { TimeUnits } from '../types/constants';

export interface IUserActivityState {
  userLastSeenTs: number;
}
export const USER_ACTIVITY_STATE: IUserActivityState = {
  userLastSeenTs: null,
};

export const setUserLastSeen = (ts: number, basedOnEventType: string) =>
  commit(`Set user last seen to ${Date.now() - ts} ms ago based on event "${basedOnEventType}"`, {
    userLastSeenTs: val(ts),
  });

/**
 * How often do we update user's activity. We don't want to bother the store all the time.
 * But also, needs to be something reasonable in terms of keep idle timeout at bay, though.
 */
export const USER_ACTIVITY_UPDATE_FREQUENCY = 15 * TimeUnits.second;

/**
 * Service whose job is to track user activity in browser and update state accordingly.
 */
export class UserActivityTracker {
  private notifyUserLastSeenThrottled: typeof UserActivityTracker.prototype.notifyUserSeen;

  constructor(
    private store: Store,
    private logger: Logger,
    private document: Document,
    userActivityUpdateFrequency = USER_ACTIVITY_UPDATE_FREQUENCY
  ) {
    this.notifyUserLastSeenThrottled = lodash.throttle(
      this.notifyUserSeen,
      userActivityUpdateFrequency
    );
  }

  start() {
    this.document.addEventListener('mousedown', this.onActivityEvent);
    this.document.addEventListener('mousemove', this.onActivityEvent);
    this.document.addEventListener('keydown', this.onActivityEvent);
    this.document.addEventListener('scroll', this.onActivityEvent);
    this.document.addEventListener('touchstart', this.onActivityEvent);
    this.document.addEventListener('visibilitychange', this.onBrowserVisibilityChangeEvent);

    // Presume user is active during initialization
    this.notifyUserLastSeenThrottled(Date.now(), 'app_initialization');
  }

  private onActivityEvent = (e: Event) => {
    this.notifyUserLastSeenThrottled(Date.now(), e.type);
  };

  private onBrowserVisibilityChangeEvent = (e: Event) => {
    if (!this.document.hidden) {
      this.notifyUserLastSeenThrottled(Date.now(), e.type);
    }
  };

  private notifyUserSeen = (ts: number, basedOnEventType: string) => {
    this.store.dispatch(setUserLastSeen(ts, basedOnEventType));
  };
}
