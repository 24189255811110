import styled, { IThemeColorsUnion, IThemeFonts, css } from '../../lib/styled_components';
import { hex2rgba } from '../../lib/util';

export interface ICellProps {
  align?: 'left' | 'center' | 'right';
  noShrink?: boolean;
  noGrow?: boolean;
  width?: string;
  minWidth?: string;
  withOverflow?: boolean;
}

export const $Cell = styled('div')<ICellProps>`
  display: inline-block;
  text-align: ${(p) => (p.align ? p.align : 'left')};
  flex-shrink: ${(p) => (p.noShrink ? '0' : '1')};
  flex-grow: ${(p) => (p.noGrow ? '0' : '1')};
  align-items: center;

  width: ${(p) => (p.width ? p.width : ' auto')};
  min-width: ${(p) => (p.minWidth ? p.minWidth : 'auto')};
  box-sizing: border-box;

  overflow: ${(p) => !p.withOverflow && 'hidden'};
  text-overflow: ${(p) => !p.withOverflow && 'ellipsis'};

  @media screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    font-size: ${(p) => p.theme.widgets.table.cell.iphonePlus.fontSize} !important;
  }
`;

export const $SubHeaderCell = styled('h3')<ICellProps>`
  display: inline-block;
  margin: 0;
  font-size: inherit;
  text-align: ${(p) => (p.align ? p.align : 'left')};
  flex-shrink: ${(p) => (p.noShrink ? '0' : '1')};
  flex-grow: ${(p) => (p.noGrow ? '0' : '1')};
  align-items: center;

  width: ${(p) => (p.width ? p.width : ' auto')};
  min-width: ${(p) => (p.minWidth ? p.minWidth : 'auto')};
  box-sizing: border-box;

  overflow: ${(p) => !p.withOverflow && 'hidden'};
  text-overflow: ${(p) => !p.withOverflow && 'ellipsis'};

  @media screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    font-size: ${(p) => p.theme.widgets.table.cell.iphonePlus.fontSize} !important;
  }
`;

export const $DigitCell = styled($Cell)`
  padding: 0 5px;
`;

export const $HeadingCell = styled($Cell)`
  padding-left: 5px;

  &:last-child {
    padding-right: 5px;
  }
`;

export const $EllipsisWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 1.5rem;
  line-height: 1.5rem;
`;

interface IRowProps {
  noPointer?: boolean;
  noBottomBorder?: boolean;
  hoverBackground?: IThemeColorsUnion;
  selectedBackground?: IThemeColorsUnion;
  hoverColor?: IThemeColorsUnion;
  selectedColor?: IThemeColorsUnion;
  selected?: boolean;
  color?: string;
  removeAnimation?: boolean;
}
export const $TableRow = styled('div')<IRowProps>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px 2px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  font-size: ${(p) => p.theme.fontSize.small};

  border-bottom: ${(p) =>
    p.noBottomBorder ? 'none' : `1px solid ${p.theme.widgets.table.borderBottomColor}`};
  transition: background-color 0.1s ease-in;

  ${(p) => !p.removeAnimation && `animation: fadein 0.5s ease-in;`}

  &:hover {
    background: ${(p) =>
      p.hoverBackground ? p.hoverBackground : p.theme.widgets.table.backgroundHover};
    color: ${(p) => (p.hoverColor ? p.hoverColor : 'inherit')};
    cursor: ${(p) => (p.noPointer ? 'default' : 'pointer')};
  }

  /* Selected */
  background: ${(p) =>
    p.selected && p.selectedBackground
      ? p.selectedBackground
      : p.selected
      ? p.theme.widgets.table.backgroundHover
      : 'inherit'};

  color: ${(p) => (p.color ? p.color : p.theme.widgets.table.color)};

  ${(p) =>
    p.selected &&
    p.selectedColor &&
    css`
      color: ${p.selectedColor};
    `}
`;

interface ITableHeadingProps {
  noBorder?: boolean;
  noUppercase?: boolean;
}
export const $TableHeading = styled('div')<ITableHeadingProps>`
  display: flex;
  color: ${(p) => p.theme.widgets.table.heading.color};
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
  text-transform: ${(p) => (p.noUppercase ? 'auto' : 'uppercase')};
  border-bottom: ${(p) => (p.noBorder ? 'none' : `1px solid ${p.theme.colors.lineColor}`)};
  font-size: ${(p) => p.theme.fontSize.large};
  padding: 5px 5px 5px 2px;

  &:first-child {
    text-align: center;
  }

  ${$Cell} {
    overflow: visible;
    font-family: ${(p) => p.theme.widgets.table.heading.fontFamily} !important;
    font-weight: bold;
  }

  @media ${(p) => p.theme.device.laptop} {
    ${$Cell} {
      font-size: ${(p) => p.theme.fontSize.small} !important;
    }
  }
  @media ${(p) => p.theme.device.mobile} {
    ${$Cell} {
      font-size: ${(p) => p.theme.fontSize.small} !important;
    }
  }

  @media ${(p) => p.theme.device.mobile_IphonePlus} {
    ${$Cell} {
      font-size: ${(p) => p.theme.widgets.table.headingCell.iphonePlus.fontSize}!important;
    }
  }
`;

interface ITableBodyProps extends Partial<IThemeFonts> {
  maxHeight?: number;
}

export const $TableBody = styled('div')<ITableBodyProps>`
  max-height: ${(p) => (p.maxHeight ? `${p.maxHeight}px` : 'none')};
  padding: 5px 7px 5px 2px;
  overflow: auto;
`;

interface ITableProps extends Partial<IThemeFonts> {
  noPadding?: boolean;
  overlayColor?: IThemeColorsUnion;
  darkBackground?: boolean;
}
export const $Table = styled('div')<ITableProps>`
  position: relative;
  padding: ${(p) => (p.noPadding ? '0rem' : '0.5rem')};
  font-family: ${(p) =>
    p.fontFamily ? p.theme.fontFamily[p.fontFamily] : p.theme.widgets.table.fontFamily};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  ${(p) =>
    (p.overlayColor &&
      css`
        border-top: 1px solid ${p.overlayColor};
        background: linear-gradient(
          to bottom,
          ${hex2rgba(p.overlayColor, 0.15)} 0%,
          ${hex2rgba(p.overlayColor, 0)} 40%
        );
      `) ||
    (p.darkBackground &&
      css`
        background: rgba(0, 0, 0, 0.1);
      `)};
`;

export const $SubRow = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const $TableHeadingBadge = styled('span')`
  font-size: ${(p) => p.theme.fontSize.badge};
  margin-left: 0.4em;
  margin-right: 0.4em;
`;
