import * as React from 'react';
import { I18nContext } from 'react-i18next';

import styled, { IThemeColorsUnion, zIndex } from '../../lib/styled_components';
import { CloseIcon } from '../../media/svg_icons';
import { II18nextT } from '../../types/i18n';
import { $GreenLightButton, $RedLightButton } from './buttons';
import { $Icon } from './Icon';

const $Modal = styled.div<{
  open: boolean;
  topBorderColor?: string;
}>`
  border-top: ${(p) => (p.topBorderColor ? `4px solid ${p.theme.colors[p.topBorderColor]}` : '')};
  transform: scale(0);
  z-index: ${zIndex.modal};

  background: ${(p) => p.theme.widgets.modal.boxBackground};

  overflow-y: auto;
  @media screen and ${(p) => p.theme.device.mobile} {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }

  animation: zoomIn 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

  @keyframes zoomIn {
    0% {
      opacity: 0.5;
      transform: scale(0.9);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const $CloseButton = styled.button`
  position: absolute;
  z-index: 1;

  cursor: pointer;
  border: none;

  &:focus {
    outline: none;
  }

  &:hover,
  &:active {
    svg {
      g {
        path {
          stroke: ${(p) => p.theme.colors.info};
        }
      }
    }
  }

  top: 9.5px;
  right: 7.2px;
  background: none;
`;

const $Header = styled.div`
  height: 4.17rem;
  position: relative;

  ${$CloseButton} {
    top: 50%;
    transform: translate(0, -50%);
  }
`;

const $Content = styled.div`
  max-width: 100%;
  height: 100%;

  @media screen and ${(p) => p.theme.device.mobile} {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: inherit;
  }
`;

const $Overlay = styled.div<{ closing: boolean }>`
  z-index: ${zIndex.modal - 1};

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0);
  overflow: auto;

  @media ${(p) => p.theme.device.mobile} {
    display: block;
  }

  animation: ${(p) => (p.closing ? 'fadeOut' : 'fadeIn')} 0.25s cubic-bezier(0.165, 0.84, 0.44, 1)
    forwards;

  @keyframes fadeIn {
    0% {
      background: rgba(0, 0, 0, 0);
    }
    100% {
      background: ${(p) => p.theme.widgets.modal.overlayBackground};
    }
  }

  @keyframes fadeOut {
    0% {
      background: ${(p) => p.theme.widgets.modal.overlayBackground};
    }
    100% {
      background: rgba(0, 0, 0, 0);
    }
  }

  ${$Modal} {
    animation: ${(p) =>
      p.closing ? 'zoomOut .25s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;' : ''};
  }

  @keyframes zoomOut {
    0% {
      opacity: 1;
      transform: scale(1);
    }

    50% {
      opacity: 0.4;
    }

    100% {
      opacity: 0;
      transform: scale(0.9);
    }
  }
`;

const $ModalTitle = styled.div<{ titleUppercase?: boolean; titleColor?: string }>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: ${(p) => (p.titleUppercase ? 'uppercase' : '')};
  word-break: break-word;
  padding: 0 50px;
  background: ${(p) => p.theme.widgets.modal.titleBackground};
  font-weight: 600;
  font-size: ${(p) => p.theme.widgets.modal.titleSize};
  color: ${(p) => (p.titleColor ? p.theme.colors[p.titleColor] : p.theme.colors.white)};
`;

const $MobileWrapper = styled.div`
  flex: 1;

  @media screen and ${(p) => p.theme.device.mobile} {
    max-height: inherit;
  }
`;

const $ConfirmationWrapper = styled.div`
  display: flex;
  padding: 0 2.3125rem 0.3125rem 2.3125rem;

  button {
    margin-bottom: 2rem;
  }

  button:first-child {
    margin-right: 0.2rem;
  }
`;

interface IModalProps {
  isOpen?: boolean;
  topBorderColor?: IThemeColorsUnion;
  title?: string;
  content?: string;
  titleUppercase?: boolean;
  titleColor?: string;
  withoutCloseButton?: boolean;
  onConfirm?: () => any;
  /** Close animation has started */
  onClose?: () => void;
  /** Close animation has ended */
  onClosed?: () => void;
}

interface IModalState {
  isOpen: boolean;
  closing: boolean;
}

export default class Modal extends React.PureComponent<IModalProps, IModalState> {
  static contextType: any = I18nContext;
  private layoutRef = React.createRef<HTMLDivElement>();
  private draggedFromLayout: boolean;
  private mounted: boolean;

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      closing: false,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<IModalProps>,
    prevState: Readonly<IModalState>,
    snapshot?: any
  ) {
    this.updateControlledState(prevProps);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleEsc);
    this.mounted = true;
    this.updateControlledState(null);
  }

  updateControlledState(prevProps: IModalProps) {
    if (this.props.isOpen !== undefined && (!prevProps || this.props.isOpen !== prevProps.isOpen)) {
      if (this.props.isOpen) {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  componentWillUnmount = () => {
    document.body.style.overflow = 'auto';
    document.removeEventListener('keydown', this.handleEsc);
    this.mounted = false;
  };

  show = () => {
    this.setState({ isOpen: true, closing: false }, () => {
      document.body.style.overflow = 'hidden';
    });
  };

  hide = () => {
    return new Promise((resolve) => {
      this.setState({ closing: true }, () => {
        if (this.props.onClose) {
          this.props.onClose();
        }

        setTimeout(() => {
          if (!this.mounted) {
            document.body.style.overflow = 'auto';
            return resolve();
          }

          const onClosed = this.props.onClosed;
          this.setState({ isOpen: false }, () => {
            document.body.style.overflow = 'auto';
            if (onClosed) {
              onClosed();
            }
            resolve();
          });
        }, 250);
      });
    });
  };

  hideOnLayoutClick = () => {
    this.draggedFromLayout && this.hide();
  };

  findDragLocation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.target === this.layoutRef.current
      ? (this.draggedFromLayout = true)
      : (this.draggedFromLayout = false);
  };

  get isOpen() {
    return this.state.isOpen;
  }

  private handleEsc = (event) => {
    const isEscape =
      'key' in event ? event.key === 'Escape' || event.key === 'Esc' : event.keyCode === 27;

    if (isEscape && this.state.isOpen) {
      this.hide();
    }
  };

  renderConfirmation = () => {
    const { t }: II18nextT = this.context;

    return (
      <$ConfirmationWrapper>
        <$RedLightButton display={'block'} onClick={() => this.hide()}>
          {t('button.cancel')}
        </$RedLightButton>
        <$GreenLightButton display={'block'} onClick={() => this.props.onConfirm()} autoFocus>
          {t('button.confirm')}
        </$GreenLightButton>
      </$ConfirmationWrapper>
    );
  };

  render() {
    const { title, onConfirm, topBorderColor, withoutCloseButton } = this.props;
    const { isOpen } = this.state;

    const closeButton = !withoutCloseButton && (
      <$CloseButton onClick={this.hide}>
        <$Icon src={CloseIcon} size={17} />
      </$CloseButton>
    );

    return isOpen ? (
      <$Overlay
        ref={this.layoutRef}
        onMouseDown={this.findDragLocation}
        onClick={this.hideOnLayoutClick}
        closing={this.state.closing}
      >
        <$Modal onClick={(e) => e.stopPropagation()} open={isOpen} topBorderColor={topBorderColor}>
          {title ? (
            <$Header>
              <$ModalTitle
                titleUppercase={this.props.titleUppercase}
                titleColor={this.props.titleColor}
              >
                {title}
              </$ModalTitle>
              {closeButton}
            </$Header>
          ) : (
            closeButton
          )}

          <$MobileWrapper>
            <$Content>{this.props.children}</$Content>
            {onConfirm && this.renderConfirmation()}
          </$MobileWrapper>
        </$Modal>
      </$Overlay>
    ) : null;
  }
}
