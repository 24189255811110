import { IPaginated } from '../../types/api';
import {
  IApiCensoredCardPayment,
  IApiCensoredManagedBuyOrder,
  IApiCensoredManagedSellOrder,
  IApiManagedUserConfig,
  IApiPaymentInstrumentConfig,
} from '../../types/backend_definitions';
import { IApiWithdrawalWindowReport, IInstrument } from '../../types/backend_definitions';
import { ALL_INSTRUMENTS_SYMBOLS } from '../../types/instruments';
import { IDeposit, IWithdrawal } from '../../types/transactions';
import { IExtendedApiPaymentConfigForUser } from './card_payments';

export type IDepositAddresses = { [instrument in IInstrument]?: string };

export type IInstrumentDeposits = { [instrument in IInstrument]: IPaginated<IDeposit> };
export interface IDeposits extends IInstrumentDeposits {
  all: IPaginated<IWithdrawal>;
}

export type IPendingDeposits = { [instrument in IInstrument]?: number };

export type IInstrumentWithdrawals = { [instrument in IInstrument]: IPaginated<IWithdrawal> };
export interface IWithdrawals extends IInstrumentWithdrawals {
  all: IPaginated<IWithdrawal>;
}

export type IWithdrawalReports = { [instrument in IInstrument]: IApiWithdrawalWindowReport };

export type IManagedBuyOrders = IPaginated<IApiCensoredManagedBuyOrder>;
export type IManagedSellOrders = IPaginated<IApiCensoredManagedSellOrder>;

export interface ITransactions {
  deposits: IDeposits;
  pendingDeposits: IPendingDeposits;
  withdrawals: IWithdrawals;
  withdrawalReport: IWithdrawalReports;
  depositAddresses: IDepositAddresses;
  cardPaymentUserConfig: IExtendedApiPaymentConfigForUser;
  cardPaymentInstrumentConfig: IApiPaymentInstrumentConfig;
  cardPayments: IPaginated<IApiCensoredCardPayment>;
  managedUserConfig: IApiManagedUserConfig;
  managedCoinsMarketData: Object;
  managedBuyOrders: IManagedBuyOrders;
  managedSellOrders: IManagedSellOrders;
}

export interface ITransactionsState {
  transactions: ITransactions;
}

const initialPaginationState = {
  items: [],
  page: 1,
  page_size: 25,
  total_records: 0,
  total_pages: 0,
};

const initialManagedUserConfigState: IApiManagedUserConfig = {
  hide_service: null,
  disable_instruments: {},
  supported_crypto: [],
  supported_fiat: [],
  buy_order_payment_instrument: 'USD',
  buy_order_min_payment_quantity: '0',
  buy_order_fee_pct: 0,
  buy_order_fee_fixed: '0',
  sell_order_fee_pct: 0,
  sell_order_fee_fixed: '0',
  sell_order_payout_instrument: 'USD',
  sell_order_min_payout_quantity: '0',
  registered: null,
};

const initialCardPaymentUserConfigState: IExtendedApiPaymentConfigForUser = {
  loaded: false,
  disable_service: false,
  disable_service_reason: null,
  disable_instruments: {},
  payment_limit: {
    instrument: 'USD',
    maximal: 0,
    minimal: 0,
  },
};

const initialCardPaymentInstrumentState: IApiPaymentInstrumentConfig = {
  supported_fiat: [],
  supported_crypto: [],
};

const initInstrumentLookup = (value) =>
  ALL_INSTRUMENTS_SYMBOLS.reduce((acc, cur) => {
    acc[cur] = value;
    return acc;
  }, {});

const initInstrumentLookupWithCombinedProp = (value) =>
  ALL_INSTRUMENTS_SYMBOLS.reduce(
    (acc, cur) => {
      acc[cur] = value;
      return acc;
    },
    // { all: value } represents combined prop
    { all: value }
  );

export const TRANSACTIONS_STATE: ITransactionsState = {
  transactions: {
    deposits: initInstrumentLookupWithCombinedProp(initialPaginationState) as IDeposits,
    pendingDeposits: initInstrumentLookup('') as IPendingDeposits,
    withdrawals: initInstrumentLookupWithCombinedProp(initialPaginationState) as IWithdrawals,
    withdrawalReport: initInstrumentLookup('') as IWithdrawalReports,
    depositAddresses: initInstrumentLookup('') as IDepositAddresses,
    cardPaymentUserConfig: initialCardPaymentUserConfigState,
    cardPaymentInstrumentConfig: initialCardPaymentInstrumentState,
    cardPayments: initialPaginationState,
    managedUserConfig: initialManagedUserConfigState,
    managedCoinsMarketData: null,
    managedBuyOrders: initialPaginationState,
    managedSellOrders: initialPaginationState,
  },
};
