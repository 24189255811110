import { commit, val } from '../lib/redux_helpers';
import { IState } from '../lib/store';

export type IWindowSize = 'mobile' | 'tablet' | 'laptop' | 'desktop';

export interface IDocumentState {
  document: {
    title: string;
    windowSize: IWindowSize;
  };
}

export const DOCUMENT_STATE = {
  document: {
    title: null,
    windowSize: null,
  },
};

export const setDocumentTitle = (documentTitle: string) => (dispatch) => {
  dispatch(
    commit('Set document title', {
      document: {
        title: val<IState['document']['title']>(documentTitle),
      },
    })
  );
};

export const updateWindowSize = (size: IWindowSize) =>
  commit(`Update window size to ${size}`, {
    document: {
      windowSize: val<IState['document']['windowSize']>(size),
    },
  });
