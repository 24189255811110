import * as React from 'react';
import { Link } from 'react-router-dom';

import R from '../../../lib/routes';
import styled from '../../../lib/styled_components';
import ActiveInstrumentOrders from '../../components/exchange/ActiveInstrumentOrders';
import FooterLinks from '../../components/exchange/FooterLinks';
import MarketList from '../../components/exchange/MarketList';
import $Link from '../../widgets/Link';
import Logo from '../../widgets/Logo';

const $LeftWidgets = styled.div`
  width: ${(p) => p.theme.layout.sidebarWidth}px;
  display: grid;
  grid-template-rows: auto minmax(auto, 43rem) auto 1fr auto;
  height: 100%;

  @media screen and ${(p) => p.theme.device.mobile} {
    width: 100%;
    background-color: transparent;
  }
`;

const $Wrapper = styled.div<{ maxHeight?: string }>`
  position: relative;
  margin-top: 7px;
  min-height: 0;
  display: flex;
  ${(p) => (p.maxHeight ? `max-height: ${p.maxHeight}` : '')};
  > * {
    flex-grow: 1;
  }
`;

const $LogoWrapper = styled.div`
  position: relative;
  margin-top: 7px;
  min-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    flex-grow: 1;
  }
`;

export default () => {
  return (
    <$LeftWidgets>
      <$LogoWrapper className="hide-on-mobile">
        <$Link as={Link} to={R.HOME}>
          <Logo margin="0 auto" />
        </$Link>
      </$LogoWrapper>

      <$Wrapper>
        <MarketList />
      </$Wrapper>

      <$Wrapper maxHeight="30vh">
        <ActiveInstrumentOrders />
      </$Wrapper>

      <div>
        {/* This is just to serve as empty space and allow active instruments to float up */}
      </div>

      <$Wrapper>
        <FooterLinks />
      </$Wrapper>
    </$LeftWidgets>
  );
};
