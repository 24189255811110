import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { submitOrder } from '../../../actions/orders/submit_order';
import { navigateToDynamicRoute } from '../../../actions/routing';
import lodash from '../../../lib/lodash';
import { getBuySellAmount, getBuySellTotal } from '../../../lib/price_estimator';
import Quantity from '../../../lib/quantity';
import R from '../../../lib/routes';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { formatNumberToFixed } from '../../../lib/util';
import { getUserOrderConfirmationsSettings, isUserLoggedIn } from '../../../selectors/auth';
import { getAvailableBalanceForCurrentPair, getBalances } from '../../../selectors/balances';
import { getBestPrices, getMarketDepthSide } from '../../../selectors/exchange';
import { getCurrentPair, getCurrentPairConfig } from '../../../selectors/exchange';
import { IOrderSide, IOrderType } from '../../../types/backend_definitions';
import { IBalanceTypes } from '../../../types/balances';
import { II18nextT } from '../../../types/i18n';
import { IInstrumentObject, PRICE_DIGITS } from '../../../types/instruments';
import { $Button, $TransparentSmallButton } from '../../widgets/buttons';
import LoadingAnimation from '../../widgets/LoadingAnimation';
import Modal from '../../widgets/Modal';
import NumberInput from '../../widgets/NumberInput';
import PrettyDecimals from '../../widgets/PrettyDecimals';
import { $ToggleMenu, $ToggleMenuButton } from '../../widgets/ToggleMenu';
import GenericTooltip from '../../widgets/tooltips/GenericTooltip';
import HelpTooltip from '../../widgets/tooltips/HelpTooltip';

const $BuySell = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  height: 100%;
`;

const $GradientWrapper = styled.div<{ side: IOrderSide }>`
  padding: 5px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  border-top: 1px solid
    ${(p) =>
      p.side === 'buy'
        ? p.theme.components.buySell.colorBuy
        : p.theme.components.buySell.colorSell};
  background: ${(p) =>
    p.side === 'buy'
      ? p.theme.components.buySell.gradientWrapperBuyBackground
      : p.theme.components.buySell.gradientWrapperSellBackground};
`;

const $InputRow = styled.div<{ bolded?: boolean }>`
  height: 25%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  // Limit vertical contraction on phones. On desktops, it should always be above this.
  min-height: 30px;
  @media screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    min-height: 26px;
  }

  ${(p) =>
    p.bolded &&
    `
    label {
      font-weight: bold;
    }
    input {
      font-weight: bold;
    }
  `}
`;

const $InputRowContent = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  position: relative;

  input {
    font-size: ${(p) => p.theme.fontSize.large};
  }

  > button {
    margin-right: 2px;
  }
`;

const $InputLabel = styled.label<{ validationError?: boolean }>`
  color: ${(p) => p.validationError && p.theme.colors.error};
  transition: color 0.25s ease;
  width: 6em;
  padding-right: 0.5em;
  text-align: right;
  font-size: ${(p) => p.theme.fontSize.large};

  @media screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    font-size: ${(p) => p.theme.components.buySell.components.label.iphonePlus.fontSize};
    line-height: ${(p) => p.theme.components.buySell.components.label.iphonePlus.fontSize};
  }
`;

const $InputLine = styled.div<{ validationError?: boolean }>`
  position: absolute;
  grid-column: 2/5;
  height: 1px;
  bottom: 1px;
  width: 100%;
  background: ${(p) => (p.validationError ? 'transparent' : `rgba(255, 255, 255, 0.2)`)};
  transition: background-color 0.25s ease;

  &:after,
  &:before {
    content: '';
    width: ${(p) => (p.validationError ? '100%' : '0px')};
    margin: auto;
    height: 1px;
    background: ${(p) => (p.validationError ? p.theme.colors.error : 'transparent')};
    transition: width 0.25s ease, background-color 0.25s ease;
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

const $InstrumentLabel = styled('strong')<{ side: IOrderSide }>`
  width: 2.9rem;
  text-align: right;
  color: ${(p) =>
    p.side === 'buy' ? p.theme.components.buySell.colorBuy : p.theme.components.buySell.colorSell};

  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    .selector:not(*:root),
    & {
      // Chrome-only: this looks a bit better
      height: 11px;
    }
  }
`;

const $ExplanationLabel = styled.span`
  color: ${(p) => p.theme.colors.grayLight};
  font-size: ${(p) => p.theme.fontSize.base};
  cursor: help;
  margin-right: 0.5rem;
  &:focus {
    outline: ${(p) => p.theme.base.focusOutline};
  }
`;
const $ExplanationTooltip = styled.span`
  display: inline-block;
  max-width: 20rem; // Prevent the text going too wide
`;

const $BalanceButtonHolder = styled.div`
  min-height: 25px; // Matches other rows
  display: flex;
  align-items: center;
`;

const $BalanceButton = styled($TransparentSmallButton)`
  display: inline-block;
  margin: 1px; // So that outline doesn't get cut off
  font-size: ${(p) => p.theme.fontSize.large} !important;

  @media screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    > span {
      font-size: ${(p) => p.theme.widgets.input.iphonePlus.fontSize};
      line-height: ${(p) => p.theme.widgets.input.iphonePlus.fontSize};
    }
  }
`;

const $InputPlaceholder = styled.div`
  padding: ${(p) => p.theme.widgets.input.light.padding};
`;

const $BuySellButton = styled($Button).attrs((p) => ({
  backgroundColor: p.theme.colors.transparent,
}))<{ validationError?: boolean }>`
  position: relative;
  height: 31px;
  text-transform: uppercase;
  width: 100%;
  border-image-slice: 1;
  border-width: 2px;
  background-color: ${(p) =>
    p.side === 'buy'
      ? p.theme.components.buySell.backgroundBuy
      : p.theme.components.buySell.backgroundSell};
  transition: color 0.25s ease, background-image 0.25s ease;

  border-color: initial; // Safari fix
  border-image: ${(p) =>
    p.validationError
      ? p.theme.components.buySell.validationError
      : p.side === 'buy'
      ? p.theme.components.buySell.borderBuy
      : p.theme.components.buySell.borderSell};

  color: ${(p) => p.validationError && p.theme.colors.error};

  &:hover {
    color: ${(p) => p.validationError && p.theme.colors.error};
    background-color: ${(p) =>
      p.validationError
        ? 'transparent'
        : p.side === 'buy'
        ? p.theme.components.buySell.backgroundHoverBuy
        : p.theme.components.buySell.backgroundHoverSell};
  }

  &:disabled {
    background: ${(p) => p.theme.colors.transparent} !important;
    cursor: initial !important;
    color: inherit !important;
  }

  @media ${(p) => p.theme.device.mobile} {
    height: 28px;
  }
`;

const $LoadingAnimationWrapper = styled.div`
  height: 100%;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  transform: translate(-50%, -50%);
`;

const $OrderTypeButton = styled($ToggleMenuButton).attrs((p: any) => ({
  hasTriangle: true,
  activeColor:
    p.side === 'buy' ? p.theme.components.buySell.colorBuy : p.theme.components.buySell.colorSell,
  type: 'button',
  withoutFocusOutline: true,
}))`
  &:disabled {
    color: ${(p) => p.theme.colors.gray};
    background-color: ${(p) => p.theme.colors.transparent} !important;
  }
  &:focus > * {
    outline: ${(p) => p.theme.base.focusOutline};
  }
  .svg-icon-color-fill {
    fill: ${(p) => (p.active ? p.activeColor : p.theme.colors.grayLighter)};
  }
  &:hover .svg-icon-color-fill {
    fill: ${(p) => (p.active ? p.activeColor : p.theme.colors.white)};
  }
  // svg-icon-color-fill
`;

const $StopOrderButton = styled($OrderTypeButton)`
  padding-right: 0;
  display: flex;
  flex-direction: row;
  align-items: end;

  svg {
    margin-left: 0.4rem;
  }
  svg:focus {
    outline: ${(p) => p.theme.base.focusOutline};
  }
`;

const $Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  text-align: center;
  padding: 0 8px;

  span:last-child {
    font-size: 1.4rem;
    padding: 4px 0;
    font-weight: bold;
  }
`;

const $Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.42rem 0;

  @media screen and ${(p) => p.theme.device.mobile} {
    flex-direction: column;

    ${$Column} {
      padding: 6px 8px;
    }
  }
`;

const $TradingFeeWrapper = styled.div`
  width: 100%;
  text-align: center;

  span {
    font-weight: bold;
  }
`;

const $Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2.3125rem;

  @media screen and ${(p) => p.theme.device.mobile} {
    width: 100vw;
  }
`;

const $TradingDisabledWrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 2.5125rem 2.3125rem 2.3125rem 2.3125rem;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
`;

interface IOwnProps {
  side: IOrderSide;
}

interface IBuySellProps extends IOwnProps, ConnectedProps<typeof connector> {}

interface IBuySellState {
  type: IOrderType;
  quantityInput: string;
  quantityValue: Quantity;
  quantityFocused: boolean;
  priceInput: string;
  priceValue: Quantity;
  totalInput: string;
  totalValue: Quantity;
  totalFocused: boolean;
  fee: Quantity;
  validationErrors: {
    quantityEmpty: boolean;
    quantityBelowMinimum: boolean;
    priceEmpty: boolean;
  };
  sendingOrderWithoutConfirmation: boolean;
  notEnoughBalance: boolean;
}

class BuySell extends React.PureComponent<IBuySellProps, IBuySellState> {
  private mounted = false;
  private modalRef: React.RefObject<Modal> = React.createRef();
  private allowSubmitOrder: boolean = true;

  form: React.RefObject<any> = React.createRef();
  confirmationDialogRef: React.RefObject<Modal> = React.createRef();

  defaultState: IBuySellState = {
    type: 'limit',
    quantityInput: '',
    quantityValue: Quantity(0),
    quantityFocused: false,
    priceInput: '',
    priceValue: Quantity(0),
    totalInput: '',
    totalValue: Quantity(0),
    totalFocused: false,
    fee: Quantity(0),
    validationErrors: {
      quantityEmpty: false,
      quantityBelowMinimum: false,
      priceEmpty: false,
    },
    sendingOrderWithoutConfirmation: false,
    notEnoughBalance: false,
  };

  constructor(props: IBuySellProps) {
    super(props);
    this.state = {
      ...this.defaultState,
    };
  }

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps: IBuySellProps) {
    const { inputValues, pairConfig, pair } = this.props;
    if (!pair) {
      // Don't update anything if pair isn't set
      return;
    }

    // set default price to current best price
    if (prevProps.bestPrices[this.props.side] === 0 && this.props.bestPrices[this.props.side] > 0) {
      this.setPrice(this.props.bestPrices[this.props.side] || 0);
    }

    if (inputValues !== prevProps.inputValues) {
      if (inputValues.price === 0 && inputValues.quantity === 0) {
        this.setState({ ...this.defaultState });
      } else {
        inputValues.price && this.setPrice(inputValues.price, false);
        inputValues.quantity && this.setQuantity(inputValues.quantity, false);
        if (inputValues.price && inputValues.quantity) {
          const [total, fee] = getBuySellTotal(
            this.props.marketDepthSlice.levels,
            this.props.side,
            Quantity(inputValues.quantity),
            inputValues.price,
            pairConfig ? pairConfig.trade_fee : 0
          );
          total && this.setTotal(total, false);
          fee && this.setState({ fee });
        }
        this.cleanValidationErrors();
      }
    }

    if (
      prevProps.pairConfig !== this.props.pairConfig ||
      // balances updated (user logged in probably)
      (lodash.isEmpty(prevProps.balances) && !lodash.isEmpty(this.props.balances))
    ) {
      // update total with fee
      this.setTotalFromQuantityAndPrice();
    }
  }

  getBalance = (instrument: IInstrumentObject, type: IBalanceTypes) => {
    const { balances } = this.props;

    if (balances[instrument.symbol]) {
      return balances[instrument.symbol][type];
    }
    return 0;
  };

  changeType = (type: IOrderType) => {
    this.setState({ type });
    this.setCurrentPrice();
  };

  setCurrentPrice = () => {
    const { side, bestPrices } = this.props;

    if (bestPrices) {
      this.setPrice(bestPrices[side]);
    }
  };

  setTotalFromQuantityAndPrice = () => {
    const { priceInput, quantityValue } = this.state;
    const { side, pair, pairConfig } = this.props;
    const quantity = Quantity.castOr(quantityValue || 0);
    const targetPrice = this.state.type === 'market' ? null : Number(priceInput) || 0;
    const [total, fee] = getBuySellTotal(
      this.props.marketDepthSlice.levels,
      side,
      quantity,
      targetPrice,
      pairConfig ? pairConfig.trade_fee : 0
    );

    if (!total) {
      return;
    }

    this.setState({
      fee: Quantity(fee || 0),
      totalValue: new Quantity(total),
      totalInput: total.truncateForInstrument(pair.base.symbol).toString(),
      notEnoughBalance: !this.hasEnoughBalance(total),
    });
  };

  setQuantityFromTotalAndPrice = () => {
    const { priceInput, totalValue } = this.state;
    const { side, pair, pairConfig } = this.props;

    const total = Quantity.castOr(totalValue || 0);
    const targetPrice = this.state.type === 'market' ? null : Number(priceInput) || 0;

    const [amount, fee] = getBuySellAmount(
      this.props.marketDepthSlice.levels,
      side,
      targetPrice,
      total,
      pairConfig ? pairConfig.trade_fee : 0
    );

    if (amount) {
      this.setState(
        {
          fee: Quantity(fee || 0),
          quantityValue: new Quantity(amount),
          quantityInput: amount.truncateForInstrument(pair.quote.symbol).toString(),
        },
        () => this.updateValidationErrors()
      );
    }
  };

  handleQuantityChanged = (e) => {
    this.setState(
      {
        quantityInput: e.target.value,
        quantityValue: Quantity.castOr(e.target.value),
      },
      () => {
        this.setTotalFromQuantityAndPrice();
        this.updateValidationErrors();
      }
    );
  };

  setQuantity = (newQuantity, updateTotal = true) => {
    const quantityValue = Quantity.max(Quantity.castOr(newQuantity || 0)).truncateForInstrument(
      this.props.pair.quote.symbol
    );
    this.setState(
      {
        quantityValue,
        quantityInput: quantityValue.toString(),
      },
      () => {
        updateTotal && this.setTotalFromQuantityAndPrice();
        this.updateValidationErrors();
      }
    );
  };

  handlePriceChanged = (e) => {
    this.setState(
      {
        priceInput: e.target.value,
        priceValue: Quantity.castOr(e.target.value),
      },
      () => {
        this.setTotalFromQuantityAndPrice();
        this.updateValidationErrors();
      }
    );
  };

  setPrice = (newPrice, updateTotal = true) => {
    const priceValue = Quantity._round(
      Quantity.max(Quantity.castOr(newPrice || 0)),
      PRICE_DIGITS,
      0
    );
    this.setState(
      {
        priceValue,
        priceInput: priceValue.toString(),
      },
      () => {
        updateTotal && this.setTotalFromQuantityAndPrice();
      }
    );
  };

  handleTotalChanged = (e) => {
    this.setState(
      {
        totalValue: Quantity.castOr(e.target.value),
        totalInput: e.target.value,
      },
      () => {
        this.setState({ notEnoughBalance: !this.hasEnoughBalance(this.state.totalValue) }, () =>
          this.setQuantityFromTotalAndPrice()
        );
      }
    );
  };

  setTotal = (newTotal, updateQuantity = true) => {
    const total = Quantity.max(Quantity.castOr(newTotal || 0));
    this.setState(
      {
        totalValue: new Quantity(total),
        totalInput: total.truncateForInstrument(this.props.pair.base.symbol).toString(),
      },
      () => {
        updateQuantity && this.setQuantityFromTotalAndPrice();
      }
    );
  };

  setMaxQuantity = () => {
    const { side, instrumentBalances } = this.props;

    if (side === 'sell') {
      this.setQuantity(instrumentBalances.quote);
    } else {
      this.setTotal(instrumentBalances.base);
    }
  };

  get minimumOrder(): Quantity {
    return Quantity.castOr(this.props.pairConfig ? this.props.pairConfig.min_order : '0');
  }

  updateValidationErrors = (): IBuySellState['validationErrors'] => {
    const validationErrors = {
      quantityEmpty: !this.state.quantityInput,
      quantityBelowMinimum: !!(
        this.state.quantityInput && this.state.quantityValue.lt(this.minimumOrder)
      ),
      priceEmpty: !this.state.priceValue.gt(0),
    };
    this.setState({ validationErrors });
    return validationErrors;
  };

  cleanValidationErrors = () => {
    this.setState({
      validationErrors: { quantityEmpty: false, quantityBelowMinimum: false, priceEmpty: false },
    });
  };

  hasValidationErrors = (errors: IBuySellState['validationErrors']) => {
    return errors && (errors.quantityEmpty || errors.quantityBelowMinimum || errors.priceEmpty);
  };

  hasEnoughBalance = (total: Quantity): boolean => {
    const { side, instrumentBalances } = this.props;

    const amount = total.toNumber();
    if (side === 'sell') {
      // Sell always has enough total, it's dependent on amount
      return true;
    }
    return amount <= instrumentBalances.base;
  };

  submitOrderForm = (event) => {
    event && event.preventDefault();

    if (!this.props.loggedIn) {
      return this.props.navigateToDynamicRoute(R.LOGIN);
    }

    if (this.props.pairConfig && this.props.pairConfig.disable_orders) {
      return this.modalRef.current.show();
    }

    const validationErrors = this.updateValidationErrors();
    if (this.hasValidationErrors(validationErrors) || this.state.notEnoughBalance) {
      return;
    }

    if (this.props.orderConfirmations) {
      this.confirmationDialogRef.current.show();
    } else {
      this.setState({ sendingOrderWithoutConfirmation: true }, () => {
        this.cleanValidationErrors();
        this.doSubmitOrder();
      });
    }
  };

  doSubmitOrder = () => {
    const { priceInput, quantityInput, type } = this.state;
    const { side, pair } = this.props;

    // TODO: different parameters are required depending on order type
    return this.props.submitOrder({
      pair: pair.path,
      side,
      type: type as IOrderType,
      quantity: quantityInput,
      price: Quantity.castOr(priceInput || 0).toNumber(),
    });
  };

  renderOrderTypeSwitcher = () => {
    const { type } = this.state;
    const { side, bestPrices } = this.props;
    const { t }: II18nextT = this.context;
    const disableTradeBtn = !Boolean(bestPrices[side]);

    return (
      <$ToggleMenu>
        <$OrderTypeButton
          side={side}
          onClick={() => this.changeType('limit')}
          active={type === 'limit'}
        >
          <span>{t('orderType.limit')}</span>
        </$OrderTypeButton>

        <$OrderTypeButton
          side={side}
          onClick={() => this.changeType('market')}
          active={type === 'market'}
          disabled={disableTradeBtn}
        >
          <span>{t('orderType.market')}</span>
        </$OrderTypeButton>

        <$StopOrderButton
          side={side}
          onClick={() => this.changeType('stop')}
          active={type === 'stop'}
        >
          <span>{t('orderType.stop')}</span>
          <HelpTooltip
            placement={'top'}
            width={'11px'}
            height={'11px'}
            overlay={<$ExplanationTooltip>{t('buySell.stopTooltipMessage')}</$ExplanationTooltip>}
            tabIndex={0}
          />
        </$StopOrderButton>
      </$ToggleMenu>
    );
  };

  renderConfirmationDialog = () => {
    const { side, pair } = this.props;
    const { quantityInput, priceInput, totalInput, type, fee } = this.state;
    const totalQuantity = Quantity.castOr(totalInput || 0);
    const { t }: II18nextT = this.context;

    const isBuySide = side === 'buy';

    const data = {
      modalTitle: `${side === 'buy' ? t('buy') : t('sell')} ${pair.displayPath}`,
      amount: formatNumberToFixed(quantityInput, pair.quote.digits),
      price: formatNumberToFixed(priceInput, PRICE_DIGITS),
      subtotal: formatNumberToFixed(
        isBuySide ? totalQuantity.sub(fee) : totalQuantity.add(fee),
        pair.base.digits
      ),
      fee: formatNumberToFixed(fee, pair.base.digits),
      total: type === 'stop' ? null : formatNumberToFixed(totalQuantity, pair.base.digits),
    };

    const renderColumn = (label, { amount, color = null }) =>
      amount && (
        <$Column>
          <span>{label}</span>
          <span>
            <PrettyDecimals value={amount} color={color} />
          </span>
        </$Column>
      );

    return (
      <Modal
        topBorderColor={isBuySide ? 'buy' : 'sell'}
        title={data.modalTitle}
        titleColor={isBuySide ? 'buy' : 'sell'}
        titleUppercase={true}
        ref={this.confirmationDialogRef}
        onConfirm={() => {
          if (this.allowSubmitOrder) {
            this.allowSubmitOrder = false;
            ((this.doSubmitOrder() as unknown) as Promise<any>).then(() => {
              this.mounted &&
                this.confirmationDialogRef.current
                  .hide()
                  .then(() => (this.allowSubmitOrder = true));
            });
          }
        }}
      >
        <$Wrapper>
          <$Row>
            {renderColumn(
              `${t('buySell.spend')} (${isBuySide ? pair.base.symbol : pair.quote.symbol})`,
              { amount: isBuySide ? data.total : data.amount }
            )}
            {renderColumn(`${t('buySell.price')} (${pair.base.symbol})`, { amount: data.price })}
            {renderColumn(
              `${t('buySell.get')} (${isBuySide ? pair.quote.symbol : pair.base.symbol})`,
              { amount: isBuySide ? data.amount : data.total, color: 'buy' }
            )}
          </$Row>
          <$Row>
            <$TradingFeeWrapper>
              {t('buySell.tradingFee')}:{' '}
              <span>
                {formatNumberToFixed(Quantity(fee).abs(), pair.base.digits)} {pair.base.symbol}
              </span>
            </$TradingFeeWrapper>
          </$Row>
        </$Wrapper>
      </Modal>
    );
  };

  renderBalances = () => {
    const { side, pair } = this.props;
    const { t }: II18nextT = this.context;

    const id = 'BuySell-' + side + '-balance';
    const balanceInstrument = side === 'buy' ? pair.base : pair.quote;
    const balance = this.getBalance(balanceInstrument, 'available');

    return (
      <$InputRow>
        <$InputRowContent>
          <$InputLabel htmlFor={id}>{t('buySell.balance')}</$InputLabel>
          <$BalanceButtonHolder>
            <$BalanceButton type="button" onClick={this.setMaxQuantity} id={id}>
              <PrettyDecimals value={formatNumberToFixed(balance, balanceInstrument.digits)} />
            </$BalanceButton>
          </$BalanceButtonHolder>
          <$InstrumentLabel side={side}>{balanceInstrument.symbol}</$InstrumentLabel>
        </$InputRowContent>
      </$InputRow>
    );
  };

  renderQuantityInput = () => {
    const { quantityInput } = this.state;
    const { pairConfig, side, pair } = this.props;
    const { t }: II18nextT = this.context;

    const id = 'BuySell-' + side + '-quantity';
    const hasQuantityError =
      this.state.validationErrors.quantityEmpty || this.state.validationErrors.quantityBelowMinimum;

    return (
      <$InputRow>
        <$InputRowContent>
          <$InputLabel validationError={hasQuantityError} htmlFor={id}>
            {t('amount')}
          </$InputLabel>
          <NumberInput
            id={id}
            border="none"
            min="0"
            onChange={this.handleQuantityChanged}
            value={quantityInput}
            onFocus={() => this.setState({ quantityFocused: true })}
            onBlur={() => this.setState({ quantityFocused: false })}
            autoComplete="off"
          />
          <$TransparentSmallButton type="button" onClick={this.setMaxQuantity}>
            {t('buySell.maxAmount')}
          </$TransparentSmallButton>
          <$InstrumentLabel side={side}>{pair.quote.symbol}</$InstrumentLabel>
          <GenericTooltip
            trigger={[]}
            visible={this.state.quantityFocused && this.state.validationErrors.quantityBelowMinimum}
            placement={'bottom'}
            variant={'error'}
            overlay={t('buySell.minOrderNotice', {
              quantity: this.minimumOrder.toString(),
              instrument: pair.quote.symbol,
            })}
          >
            <$InputLine validationError={hasQuantityError} />
          </GenericTooltip>
        </$InputRowContent>
      </$InputRow>
    );
  };

  renderPriceInput = () => {
    const { priceInput, type } = this.state;
    const { side, pair } = this.props;
    const { t }: II18nextT = this.context;

    const id = 'BuySell-' + side + '-price';

    return (
      <$InputRow>
        <$InputRowContent>
          <$InputLabel validationError={this.state.validationErrors.priceEmpty} htmlFor={id}>
            {type === 'stop' ? t('buySell.stopPrice') : t('buySell.price')}
          </$InputLabel>
          {type === 'market' ? (
            <$InputPlaceholder>
              <GenericTooltip
                overlay={
                  <$ExplanationTooltip>
                    {t('buySell.marketPriceExplanationTooltip')}
                  </$ExplanationTooltip>
                }
              >
                <$ExplanationLabel tabIndex={0} id={id}>
                  {t('buySell.marketPriceExplanationLabel')}
                </$ExplanationLabel>
              </GenericTooltip>
            </$InputPlaceholder>
          ) : (
            <>
              <NumberInput
                id={id}
                border="none"
                min="0"
                onChange={this.handlePriceChanged}
                value={priceInput}
                autoComplete="off"
              />
              <$TransparentSmallButton type="button" onClick={this.setCurrentPrice}>
                {t('buySell.bestPrice')}
              </$TransparentSmallButton>
              <$InstrumentLabel side={side}>{pair.base.symbol}</$InstrumentLabel>
              <$InputLine validationError={this.state.validationErrors.priceEmpty} />
            </>
          )}
        </$InputRowContent>
      </$InputRow>
    );
  };

  renderTotalInput = () => {
    const { totalInput, type } = this.state;
    const { pair, side } = this.props;
    const { t }: II18nextT = this.context;

    if (type === 'stop') {
      return <$InputRow />;
    }

    const id = 'BuySell-' + side + '-total';

    return (
      <$InputRow bolded>
        <$InputRowContent>
          <$InputLabel htmlFor={id}>{t('total')}</$InputLabel>
          <NumberInput
            id={id}
            border="none"
            min="0"
            onChange={this.handleTotalChanged}
            value={totalInput}
            onFocus={() => this.setState({ totalFocused: true })}
            onBlur={() => this.setState({ totalFocused: false })}
            autoComplete="off"
          />
          {type === 'market' && (
            <GenericTooltip
              overlay={
                <$ExplanationTooltip>
                  {t('buySell.estimatedExplanationTooltip')}
                </$ExplanationTooltip>
              }
            >
              <$ExplanationLabel tabIndex={0}>
                {t('buySell.estimatedExplanationLabel')}
              </$ExplanationLabel>
            </GenericTooltip>
          )}
          <$InstrumentLabel side={side}>{pair.base.symbol}</$InstrumentLabel>
          <GenericTooltip
            trigger={[]}
            visible={this.state.totalFocused && this.state.notEnoughBalance}
            placement={'bottom'}
            variant={'error'}
            overlay={t('buySell.notEnoughBalanceNotice')}
          >
            <$InputLine validationError={this.state.notEnoughBalance} />
          </GenericTooltip>
        </$InputRowContent>
      </$InputRow>
    );
  };

  renderBuySellButton = () => {
    const { side } = this.props;
    const { t }: II18nextT = this.context;
    const hasError =
      this.hasValidationErrors(this.state.validationErrors) || this.state.notEnoughBalance;

    return (
      <$BuySellButton
        validationError={hasError}
        color="white"
        type="submit"
        onClick={this.submitOrderForm}
        side={side}
        tabIndex={-1}
        disabled={this.state.sendingOrderWithoutConfirmation}
      >
        {this.state.sendingOrderWithoutConfirmation ? (
          <$LoadingAnimationWrapper>
            <LoadingAnimation
              requests={['postExchangeOrder']}
              onAnimationEnd={() => this.setState({ sendingOrderWithoutConfirmation: false })}
              minimumLoadingAnimationDuration={1}
            />
          </$LoadingAnimationWrapper>
        ) : side === 'buy' ? (
          t('buy')
        ) : (
          t('sell')
        )}
      </$BuySellButton>
    );
  };

  renderDisabledTradingModal = () => {
    if (!this.props.pairConfig) {
      return null;
    }

    return (
      <Modal ref={this.modalRef} topBorderColor="error" titleUppercase={true}>
        <$TradingDisabledWrapper>
          <p>{this.context.t('buySell.tradingIsDisabled')}</p>
          {this.props.pairConfig.disable_orders_reason && (
            <p>
              {(this.props.pairConfig.disable_orders_reason as string).startsWith(
                'orders_disabled_reasons'
              )
                ? this.context.t(`backend:messages.${this.props.pairConfig.disable_orders_reason}`)
                : this.props.pairConfig.disable_orders_reason || ''}
            </p>
          )}
        </$TradingDisabledWrapper>
      </Modal>
    );
  };

  render() {
    if (!this.props.pair) {
      return null;
    }

    return (
      <>
        <$BuySell ref={this.form} onSubmit={this.submitOrderForm}>
          {this.renderOrderTypeSwitcher()}
          <$GradientWrapper side={this.props.side}>
            {this.renderBalances()}
            {this.renderPriceInput()}
            {this.renderQuantityInput()}
            {this.renderTotalInput()}
          </$GradientWrapper>
          {this.renderBuySellButton()}
        </$BuySell>

        {this.renderDisabledTradingModal()}
        {this.renderConfirmationDialog()}
      </>
    );
  }
}

const connector = connect(
  (state: IState, props: IOwnProps) => ({
    balances: getBalances(state),
    loggedIn: isUserLoggedIn(state),
    orderConfirmations: getUserOrderConfirmationsSettings(state),
    pairConfig: getCurrentPairConfig(state),
    inputValues: state.buySellWidget[props.side],
    bestPrices: getBestPrices(state),
    instrumentBalances: getAvailableBalanceForCurrentPair(state),
    pair: getCurrentPair(state),
    marketDepthSlice: getMarketDepthSide(state, props.side === 'buy' ? 'sell' : 'buy'),
  }),
  {
    submitOrder,
    navigateToDynamicRoute,
  }
);

export default connector(BuySell);

BuySell.contextType = I18nContext as any;
