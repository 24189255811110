import ColorHash from 'color-hash';
import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { getTrollboxHistory, sendTrollboxMessage } from '../../../actions/trollbox';
import { LocalStorage } from '../../../lib/local_storage';
import { inject } from '../../../lib/react_container';
import { SocketManager } from '../../../lib/socket_manager';
import { IState } from '../../../lib/store';
import styled, { zIndex } from '../../../lib/styled_components';
import { formatCompactTime, formatFullDate } from '../../../lib/util';
import { CrownIcon } from '../../../media/svg_icons';
import {
  getKYCLevel,
  getTrollboxBanDurationLeftMs,
  getTrollboxBanReason,
  getUserNickname,
  isUserEmailVerified,
  isUserLoggedIn,
  isUserSuspended,
} from '../../../selectors/auth';
import { IApiPublicTrollboxMessage, ITrollboxRoom } from '../../../types/backend_definitions';
import { II18nextT } from '../../../types/i18n';
import { $Button } from '../../widgets/buttons';
import { $Input } from '../../widgets/Input';
import ScrollBox, { IOnScrollData } from '../../widgets/ScrollBox';
import { $ToggleMenu, $ToggleMenuButton } from '../../widgets/ToggleMenu';
import GenericTooltip from '../../widgets/tooltips/GenericTooltip';

const $Trollbox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const $AnonymousModeOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.colors.info};
  pointer-events: none;

  h5 {
    font-size: ${(p) => p.theme.fontSize.large};
  }
`;

const $TrollboxTitle = styled.h4`
  color: ${(p) => p.theme.colors.info};
  @media only screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    font-size: ${(p) => p.theme.layout.exchange.sectionTitle.iphonePlus.fontSize};
    line-height: ${(p) => p.theme.layout.exchange.sectionTitle.iphonePlus.fontSize};
  }
`;

const $TrollboxContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

const $TrollboxChat = styled.div<{ showOverlay: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
  border-top: 1px solid ${(p) => p.theme.components.trollbox.activeMenuItemColor};
  &:before {
    display: ${(p) => (p.showOverlay ? 'block' : 'none')};
    pointer-events: none;
    content: '';
    position: absolute;
    height: 50px;
    width: 100%;
    z-index: ${zIndex.trollbox};

    background-image: ${(p) => p.theme.components.trollbox.topOverlay};
    /* hide on Safari */
    @media not all and (min-resolution: 0.001dpcm) {
      @media {
        & {
          display: none !important;
        }
      }
    }
  }
`;

const $TrollboxMessages = styled.div<{ anonymousMode: boolean }>`
  ${(p) => p.anonymousMode && `user-select: none;`};
  ${(p) => p.anonymousMode && `pointer-events: none;`};
  ${(p) => p.anonymousMode && `filter: blur(5px) saturate(0.3);`};
`;

const $InputWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0; // Prevent collapse on safari
  justify-content: space-between;
  background: ${(p) => p.theme.components.trollbox.inputWrapperBackground};
  border-radius: ${(p) => p.theme.components.trollbox.borderRadius};
  padding: 8px 8px 8px 12px;
  margin-top: 10px;
`;

const $ScrollToBottomButton = styled($Button)`
  position: absolute;
  bottom: 25px;
  right: 17px;

  border: ${(p) => p.theme.components.trollbox.scrollButton.border};
  border-radius: 3px;
  opacity: 0.9;
  animation: moveDown 1s infinite ease-in-out;
  @keyframes moveDown {
    100% {
      transform: translateY(30%);
    }
  }
`;

const $Message = styled.div`
  padding: 2px 0;

  animation: fadein 0.5s ease-in;
`;

const $MessageContent = styled('span')<{ highlight: boolean; userMessage: boolean }>`
  background-color: ${(p) => (p.highlight ? p.theme.colors.brandPrimary : 'transparent')};
  font-weight: ${(p) => (p.userMessage ? 'bold' : 'normal')};
`;

const $MessageInfo = styled.a`
  font-weight: bold;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const $MessageDate = styled.span`
  margin-right: 2px;
`;

const $MessageIcon = styled.span`
  margin: 0 2px;
`;

const $MessageNickname = styled.span`
  margin: 0 2px;
`;

const $TrollboxButton = styled($Button).attrs((p) => ({
  color: p.theme.components.trollbox.button.color,
  border: p.theme.components.trollbox.button.border,
  backgroundColor: p.theme.components.trollbox.button.background,
  padding: p.theme.components.trollbox.button.padding,
  borderRadius: p.theme.components.trollbox.button.borderRadius,
}))``;

const $RoomButton = styled($ToggleMenuButton).attrs((p) => ({
  hasTriangle: true,
  activeColor: p.theme.components.trollbox.activeMenuItemColor,
}))``;

const $DisabledLabel = styled.span`
  margin-right: 0.5rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.grayLight};
  font-size: ${(p) => p.theme.fontSize.base};
  cursor: help;
  &:focus {
    outline: ${(p) => p.theme.base.focusOutline};
  }
`;

interface IDefaultProps {
  maxMessages: number;
  lastServerMessages: number;
}

interface ITrollBoxProps extends ConnectedProps<typeof connector>, Partial<IDefaultProps> {
  socketManager: SocketManager;
  localStorage: LocalStorage;
}

interface ITrollBoxState {
  messages: IApiPublicTrollboxMessage[];
  message: string;
  isBanned: boolean;
  stickScrollbarToTheBottom: boolean;
}

class TrollBox extends React.PureComponent<ITrollBoxProps, ITrollBoxState> {
  static contextType: any = I18nContext;

  static defaultProps: IDefaultProps = {
    lastServerMessages: 100,
    maxMessages: 120,
  };

  private mounted: boolean = false;
  private scrollBoxRef: React.RefObject<any> = React.createRef();
  private messageInputElement: React.RefObject<any> = React.createRef();
  private colorHash: any;
  private banTimeoutId: number;

  constructor(props) {
    super(props);

    this.state = {
      messages: [],
      message: '',
      isBanned: false,
      stickScrollbarToTheBottom: true,
    };

    props.socketManager.onSectorEvent('trollbox', 'broadcast_message', this.onMessage);

    this.colorHash = new ColorHash();
  }

  componentDidMount() {
    this.mounted = true;

    this.getRoomHistory(this.props.localStorage.trollboxRoom);
    this.updateTrollboxBanState();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.props.socketManager.offSectorEvent('trollbox', 'broadcast_message', this.onMessage);
    if (this.banTimeoutId) {
      clearTimeout(this.banTimeoutId);
    }
  }

  componentDidUpdate(prevProps: ITrollBoxProps) {
    if (this.state.stickScrollbarToTheBottom) {
      this.scrollToBottom();
    }

    if (this.props.trollboxBanDurationLeftMs !== prevProps.trollboxBanDurationLeftMs) {
      this.updateTrollboxBanState();
    }
  }

  updateTrollboxBanState = () => {
    if (!this.props.trollboxBanDurationLeftMs) {
      return this.setState({ isBanned: false });
    }

    this.setState({ isBanned: true });

    if (this.banTimeoutId) {
      clearTimeout(this.banTimeoutId);
    }

    if (this.props.trollboxBanDurationLeftMs > 0) {
      this.banTimeoutId = setTimeout(() => {
        this.mounted && this.setState({ isBanned: false });
      }, this.props.trollboxBanDurationLeftMs);
    }
  };

  private scrollToBottom = () => {
    if (!this.state.stickScrollbarToTheBottom) {
      this.setState({
        stickScrollbarToTheBottom: true,
      });
    }
    this.scrollBoxRef.current.scrollToBottom();
  };

  private onScroll = (data: IOnScrollData) => {
    const stickScrollbarToTheBottom = this.isAnonymousMode()
      ? // When anonymous, always stick to bottom
        true
      : // Account for slightly buggy position
        data.position >= 0.99;

    this.setState({
      stickScrollbarToTheBottom,
    });
  };

  private changeRoom = (room: ITrollboxRoom) => {
    this.props.localStorage.trollboxRoom = room;
    this.getRoomHistory(room);
  };

  private getRoomHistory = (room: ITrollboxRoom) => {
    const { getTrollboxHistory, lastServerMessages, socketManager } = this.props;

    getTrollboxHistory(room, lastServerMessages).then((messages) => {
      if (!this.mounted) {
        return;
      }

      this.setState({ messages: messages || [], stickScrollbarToTheBottom: true }, () => {
        socketManager.setSector('trollbox', room);
      });
    });
  };

  private sendMessage = (e: React.MouseEvent | React.KeyboardEvent) => {
    if (
      (e.type === 'click' || (e as React.KeyboardEvent).key === 'Enter') &&
      this.state.message &&
      this.props.nickname
    ) {
      this.props.sendTrollboxMessage(this.props.localStorage.trollboxRoom, this.state.message);

      this.setState({
        message: '',
      });
    }
  };

  private onMessage = (message: IApiPublicTrollboxMessage) => {
    if (message) {
      this.setState({
        messages: [...this.state.messages, message].slice(-(this.props.maxMessages as Number)),
      });
    }
  };

  private changeMessage = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ message: e.currentTarget.value });
  };

  private quoteUser = (nickname: string) => {
    this.setState(
      { message: `${this.state.message}@${nickname} ` },
      () =>
        this.messageInputElement &&
        this.messageInputElement.current &&
        this.messageInputElement.current.focus()
    );
  };

  isAnonymousMode = (): boolean =>
    // TODO: When can user be logged in, but without a nickname?
    !this.props.nickname || !this.props.userLoggedIn;

  getPostingDisabledReason = (): string | null => {
    const { t }: II18nextT = this.context;
    // TODO: We seem to be loading a lot of this crap just so we can determine this. Can/should we move this into some selector?
    const {
      nickname,
      emailVerified,
      phoneVerified,
      suspended,
      kycLevel,
      userLoggedIn,
      trollboxBanDurationLeftMs,
      trollboxBanReason,
    } = this.props;

    if (suspended) {
      return t('trollbox.postingDisabledReasons.accountSuspended');
    }

    if (this.state.isBanned) {
      const permanent = trollboxBanDurationLeftMs === -1;
      if (trollboxBanReason) {
        return t(
          permanent
            ? 'trollbox.postingDisabledReasons.permBanWithReason'
            : 'trollbox.postingDisabledReasons.tempBanWithReason',
          { reason: trollboxBanReason }
        );
      }

      return t(
        permanent
          ? 'trollbox.postingDisabledReasons.permBanWithoutReason'
          : 'trollbox.postingDisabledReasons.tempBanWithoutReason'
      );
    }

    if (kycLevel < 1) {
      return t('trollbox.postingDisabledReasons.missingKYC1');
    }

    if (!emailVerified) {
      return t('trollbox.postingDisabledReasons.unverifiedEmail');
    }
    if (!phoneVerified) {
      return t('trollbox.postingDisabledReasons.unverifiedPhone');
    }

    return null;
  };

  private renderInput = (postingDisabledReason: string) => {
    const { t }: II18nextT = this.context;

    if (!postingDisabledReason) {
      return (
        <$Input
          border="none"
          value={this.state.message}
          placeholder={t('trollbox.placeholderMessage')}
          onKeyPress={this.sendMessage}
          onChange={this.changeMessage}
          ref={this.messageInputElement}
        />
      );
    }

    return (
      <GenericTooltip
        variant="error"
        placement="topLeft"
        trigger={['hover', 'focus']}
        overlay={postingDisabledReason}
      >
        <$DisabledLabel tabIndex={0}>{t('trollbox.postingDisabledMessage')}</$DisabledLabel>
      </GenericTooltip>
    );
  };

  render() {
    const { messages } = this.state;
    const { nickname } = this.props;
    const { t }: II18nextT = this.context;

    const anonymousMode = this.isAnonymousMode();
    const postingDisabledReason = this.getPostingDisabledReason();

    return (
      <$Trollbox>
        <$TrollboxTitle>{t('exchangeComponents.trollbox')}</$TrollboxTitle>
        <$TrollboxContent>
          <$ToggleMenu style={{ position: 'absolute', top: 0, right: 0 }}>
            {this.props.trollboxRooms.length > 1 &&
              this.props.trollboxRooms.map((room) => (
                <$RoomButton
                  key={room}
                  onClick={() => this.changeRoom(room)}
                  active={this.props.localStorage.trollboxRoom === room}
                  hasTriangle
                >
                  {room}
                </$RoomButton>
              ))}
          </$ToggleMenu>
          <$TrollboxChat showOverlay={messages.length > 15}>
            {anonymousMode && (
              <$AnonymousModeOverlay>
                <h5>{t('trollbox.anonymousModeOverlay')}</h5>
              </$AnonymousModeOverlay>
            )}
            <ScrollBox ref={this.scrollBoxRef} disabled={anonymousMode} onScroll={this.onScroll}>
              <$TrollboxMessages anonymousMode={anonymousMode}>
                {messages.map((msg) => (
                  <$Message key={String(msg.id)}>
                    <$MessageInfo
                      style={{ color: this.colorHash.hex(msg.nickname) }}
                      onClick={() => {
                        if (!postingDisabledReason) {
                          this.quoteUser(msg.nickname);
                        }
                      }}
                    >
                      <$MessageDate title={formatFullDate(msg.timestamp)}>
                        {`[${formatCompactTime(msg.timestamp)}]`}
                      </$MessageDate>
                      {msg.role && msg.role !== 'customer' && (
                        <$MessageIcon title="Moderator">
                          <CrownIcon width="9px" height="9px" />
                        </$MessageIcon>
                      )}
                      <$MessageNickname>{`${msg.nickname}:`}</$MessageNickname>
                    </$MessageInfo>
                    <$MessageContent
                      highlight={nickname && msg.message.indexOf(`@${nickname}`) !== -1}
                      userMessage={nickname === msg.nickname}
                    >
                      {msg.message}
                    </$MessageContent>
                  </$Message>
                ))}
              </$TrollboxMessages>
            </ScrollBox>
            {!anonymousMode && !this.state.stickScrollbarToTheBottom && (
              <$ScrollToBottomButton
                title={t('trollbox.thereAreNewMessages')}
                onClick={this.scrollToBottom}
              >
                &#8675;
              </$ScrollToBottomButton>
            )}
          </$TrollboxChat>

          {!anonymousMode && (
            <$InputWrapper>
              {this.renderInput(postingDisabledReason)}
              <$TrollboxButton onClick={this.sendMessage} disabled={!!postingDisabledReason}>
                {t('trollbox.buttonSend')}
              </$TrollboxButton>
            </$InputWrapper>
          )}
        </$TrollboxContent>
      </$Trollbox>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    userLoggedIn: isUserLoggedIn(state),
    nickname: getUserNickname(state),
    emailVerified: isUserEmailVerified(state),
    phoneVerified: state.customer && state.customer.phone_verified,
    suspended: isUserSuspended(state),
    kycLevel: getKYCLevel(state),
    trollboxBanDurationLeftMs: getTrollboxBanDurationLeftMs(state),
    trollboxBanReason: getTrollboxBanReason(state),
    trollboxRooms: state.env.trollbox.enabledRooms,
  }),
  {
    sendTrollboxMessage,
    getTrollboxHistory,
  }
);

export default connector(inject('socketManager', 'localStorage')(TrollBox));
