import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { IActiveRequestsKeys } from '../../actions/request_active';
import { IState } from '../../lib/store';
import styled from '../../lib/styled_components';
import { someRequestActive } from '../../selectors/request_active';

export type ILoadingAnimationVariant = 'normal' | 'inverse';

export const $LoadingAnimationWrapper = styled.div<{ variant: ILoadingAnimationVariant }>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 25px;
  background-color: ${(p) => p.theme.widgets.loading[p.variant].background};

  > svg {
    height: 100%;
  }

  path {
    fill: ${(p) => p.theme.widgets.loading[p.variant].color};
  }
`;

interface IOwnProps {
  requests?: IActiveRequestsKeys[];
  /** Additional trigger to initiate loading state */
  loading?: boolean;
  wrapperComponent?: React.FunctionComponent<{ variant: ILoadingAnimationVariant }>;
  minimumLoadingAnimationDuration: number;
  animationDuration: number;
  variant: ILoadingAnimationVariant;
  onAnimationEnd?: Function;
}

interface ILoadingAnimationProps extends Partial<IOwnProps>, ConnectedProps<typeof connector> {
  children?: any;
}

interface ILoadingAnimationState {
  isLoadingActive: boolean;
}

class LoadingAnimation extends React.PureComponent<ILoadingAnimationProps, ILoadingAnimationState> {
  private minimumLoadingTimeoutId: number;
  private mounted: boolean;
  private startTime: number = Date.now();
  private requestDurationInterval: any;
  private lastRequestDuration: number = 0;

  constructor(props) {
    super(props);
    this.state = {
      isLoadingActive: false,
    };
  }

  static defaultProps: IOwnProps = {
    variant: 'normal',
    minimumLoadingAnimationDuration: 1,
    animationDuration: 0.6,
  };

  private animateTransformProps = {
    attributeType: 'xml',
    attributeName: 'transform',
    type: 'translate',
    values: `0 0; 0 15; 0 0`,
    repeatCount: 'indefinite',
    dur: `${this.props.animationDuration}s`,
  };

  startRequestDurationInterval() {
    // Clear previous interval
    this.requestDurationInterval && clearInterval(this.requestDurationInterval);
    // Get last request duration
    this.startTime = Date.now();
    this.requestDurationInterval = setInterval(() => {
      const milliSecondsFromStart = Date.now() - this.startTime;
      this.lastRequestDuration = milliSecondsFromStart / 1000;
    });
  }

  calculateLoadingDuration() {
    // Calculate loading duration
    const actualLoadingDuration =
      this.lastRequestDuration > this.props.minimumLoadingAnimationDuration
        ? this.lastRequestDuration
        : this.props.minimumLoadingAnimationDuration - this.lastRequestDuration;
    return actualLoadingDuration;
  }

  startAnimation() {
    this.setState({ isLoadingActive: true });
    this.startRequestDurationInterval();
  }

  stopAnimation() {
    // Clear previous timeout
    this.minimumLoadingTimeoutId && clearTimeout(this.minimumLoadingTimeoutId);
    // Turn of loader after timeout
    this.minimumLoadingTimeoutId = setTimeout(
      () =>
        this.mounted &&
        this.setState(
          { isLoadingActive: false },
          () => this.props.onAnimationEnd && this.props.onAnimationEnd()
        ),
      this.calculateLoadingDuration() * 100 // TODO: Why? I reduced this by factor 10
    );
    clearInterval(this.requestDurationInterval);
  }

  componentDidUpdate() {
    if (this.mounted) {
      this.props.active ? this.startAnimation() : this.stopAnimation();
    }
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(this.minimumLoadingTimeoutId);
    clearInterval(this.requestDurationInterval);
  }

  render() {
    if (!this.state.isLoadingActive && !this.props.active) {
      return this.props.children || null;
    }

    const WrapperComponent = this.props.wrapperComponent || $LoadingAnimationWrapper;

    return (
      <WrapperComponent variant={this.props.variant}>
        <svg width={26} height={20} viewBox="0 0 26 20">
          <path d="M00 0h4v08h-4z">
            <animateTransform
              begin={`${(0 * this.props.animationDuration) / 3}s`}
              {...this.animateTransformProps}
            />
          </path>
          <path d="M10 0h4v08h-4z">
            <animateTransform
              begin={`${(1 * this.props.animationDuration) / 3}s`}
              {...this.animateTransformProps}
            />
          </path>
          <path d="M20 0h4v08h-4z">
            <animateTransform
              begin={`${(2 * this.props.animationDuration) / 3}s`}
              {...this.animateTransformProps}
            />
          </path>
        </svg>
      </WrapperComponent>
    );
  }
}
const connector = connect((state: IState, props: IOwnProps) => ({
  active:
    // We are active when you don't provide any controlling field
    (props.requests === undefined && props.loading === undefined) ||
    // Or when you set loading flag
    props.loading ||
    // Or when you provide requests and some are active
    (props.requests && someRequestActive(props.requests, state)),
}));

export default connector(LoadingAnimation);
