import * as React from 'react';
import { I18nContext } from 'react-i18next';

import { ICsvDownloadFunctions } from '../../actions/download_csv';
import styled, { zIndex } from '../../lib/styled_components';
import { DownloadIcon } from '../../media/svg_icons';
import { $Button, $SmallButton } from './buttons';
import { $HeaderWrapper } from './PageHeaderWrappers';
import { $Table } from './Table';

const $Wrapper = styled.div`
  padding: 0 0 20px 0;
`;

const $CloseButton = styled($SmallButton).attrs((p) => ({
  backgroundColor: p.theme.colors.transparent,
  hoverBackground: p.theme.colors.brandPrimaryLighter,
  fontSize: p.theme.fontSize.large,
  color: p.theme.colors.sell,
  padding: '0 3px',
}))``;

const $InnerWrapper = styled.div`
  height: calc(100% - 58px);
  padding: 10px 20px;
  display: flex;
  @media screen and ${(p) => p.theme.device.mobile} {
    padding: 10px;
  }
  flex-direction: column;

  ${$Table} {
    overflow: hidden;
  }
`;

const $ActionElementsWrapper = styled.div`
  display: flex;
  > div:nth-child(1) {
    display: flex;
  }

  > div:nth-child(2) {
    display: flex;
  }

  @media ${(p) => p.theme.device.mobile} {
    > div:nth-child(2) {
      margin-left: auto;
    }
  }
`;

const $HeaderWrapperJustified = styled($HeaderWrapper)<{ stickHeader?: boolean }>`
  justify-content: space-between;
  z-index: ${zIndex.obCell};

  & > div:first-child {
    display: flex;
    align-items: center;
  }

  @media screen and ${(p) => p.theme.device.mobile} {
    margin: initial;
    position: relative;
    top: initial;
    display: block;
    height: initial;
    padding: 0.5rem 20px 0.5rem 20px;
  }

  @media screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    padding: 0.5rem 10px 0.5rem 10px;
  }

  // STICKY HEADER STYLES
  position: sticky;
  top: ${(p) => p.theme.layout.headerHeight.default}px;
  transition: all 0.6s ease-in-out;
  z-index: ${(p) => p.stickHeader && zIndex.stickyHeader + 1};
  margin: ${(p) => p.stickHeader && '0 20px'};
  background: ${(p) =>
    p.stickHeader && p.theme.layout.settings.rightHeaderWrapper.stickyBackground};
`;

const $NoDataWrapper = styled.div`
  text-align: center;
  padding-top: 1rem;
  font-size: ${(p) => p.theme.fontSize.large};
  margin: 1.15rem;

  @media screen and ${(p) => p.theme.device.mobile} {
    padding-top: 4rem;
  }
`;

const $SectionButton = styled($Button).attrs<{ cancel?: boolean }>((p) => ({
  backgroundColor: p.cancel ? p.theme.colors.brandPrimaryLighter : p.theme.colors.buy,
  color: p.theme.colors.white,
}))<{ cancel?: boolean }>`
  background: transparent;
  border: 1px solid rgba(199, 195, 195, 0.45);
  font-weight: normal;
  height: 35px;

  justify-content: center;
  @media screen and ${(p) => p.theme.device.laptop} {
    font-size: ${(p) => p.theme.base.fontSizeLaptop};
  }

  color: #ffffff;

  margin-left: 1rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.75rem;
  }

  width: ${(p) => p.theme.widgets.select.defaultNormalWidth};

  @media screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    padding: 0 8px;
    width: ${(p) => p.theme.widgets.select.defaultIphoneWidth};
    svg {
      margin-right: 0.5rem;
    }
  }

  &:hover {
    background: ${(p) => p.theme.colors.sidebarColor};
  }
`;

export type IDownloadCSVAction = (csvDownloadFunction: ICsvDownloadFunctions, criteria) => void;

interface IDownloadCSVButtonProps {
  csvDownloadFunction: ICsvDownloadFunctions;
  criteria: Object;
  downloadCsv: IDownloadCSVAction;
}

class DownloadCSVButton extends React.PureComponent<IDownloadCSVButtonProps, {}> {
  static contextType: any = I18nContext;
  static defaultProps: IDownloadCSVButtonProps = {
    criteria: '',
    downloadCsv: null,
    csvDownloadFunction: null,
  };

  render() {
    const { downloadCsv, csvDownloadFunction, criteria } = this.props;
    return (
      <$SectionButton
        onClick={() => downloadCsv(csvDownloadFunction, criteria)}
        backgroundColor="transparent"
      >
        <DownloadIcon />
        CSV
      </$SectionButton>
    );
  }
}

export {
  $Wrapper,
  $CloseButton,
  $InnerWrapper,
  $HeaderWrapperJustified,
  $NoDataWrapper,
  $ActionElementsWrapper,
  DownloadCSVButton,
};
