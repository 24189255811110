import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { I18n } from '../../../lib/i18n';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { formatNumberToFixed } from '../../../lib/util';
import { getCurrentPair, getPriceSummary24hForCurrentPair } from '../../../selectors/exchange';
import InstrumentIcon from '../../widgets/InstrumentIcon';
import PrettyDecimals from '../../widgets/PrettyDecimals';

const $ActiveInstrumentLeft = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const $Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  > h3 {
    font-size: ${(p) => p.theme.fontSize.larger} !important;
    text-transform: uppercase;
    margin: 1rem 0 0 0;
    text-align: center;
  }

  > h4 {
    font-size: ${(p) => p.theme.fontSize.large};
    font-weight: normal;
    margin: 0 !important;
  }
`;

const $Volume = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  margin-bottom: 0.5rem;

  > h5 {
    text-transform: uppercase;
    margin: 0;
    color: ${(p) => p.theme.colors.grayLighter};
    font-size: ${(p) => p.theme.fontSize.smaller};
  }

  > h5 > strong {
    color: ${(p) => p.theme.colors.grayLightest};
  }

  > h4 {
    margin: 0.2rem 0 0 0 !important;
    font-size: ${(p) => p.theme.fontSize.larger} !important;
  }
`;

interface IActiveInstrumentLeftProps extends ConnectedProps<typeof connector> {}

const ActiveInstrumentLeft: React.FunctionComponent<IActiveInstrumentLeftProps> = ({
  summary24h,
  pair,
}) => {
  if (!summary24h || !pair) {
    return null;
  }

  return (
    <I18n>
      {(t) => (
        <$ActiveInstrumentLeft>
          <$Info>
            <InstrumentIcon name={pair.quote.symbol} size={70} type="color" />
            <h3>{pair.quote.name}</h3>
            <h4>{pair.displayPath}</h4>
          </$Info>
          <$Volume>
            <h5>
              {t('activeInstrument.volume24h') + ' '}(<strong>{pair.base.symbol}</strong>)
            </h5>
            <h4>
              <PrettyDecimals
                value={formatNumberToFixed(summary24h.volume, pair.base.digits)}
                wordBreak
              />
            </h4>
          </$Volume>
        </$ActiveInstrumentLeft>
      )}
    </I18n>
  );
};

const connector = connect((state: IState) => ({
  pair: getCurrentPair(state),
  summary24h: getPriceSummary24hForCurrentPair(state),
}));

export default connector(ActiveInstrumentLeft);
