import React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  isPhoneVerificationModalOpen,
  submitPhoneVerificationResult,
} from '../../../../bl/phone_verification';
import {
  $ValidationWrapper,
  ITranslatedError,
  ValidationWrapper,
  handleError,
  translateHttpError,
} from '../../../../lib/errors';
import { HttpErrorResponse } from '../../../../lib/http';
import { Trans } from '../../../../lib/i18n';
import { inject } from '../../../../lib/react_container';
import R from '../../../../lib/routes';
import { IState } from '../../../../lib/store';
import styled from '../../../../lib/styled_components';
import { promiseGuard } from '../../../../lib/util';
import { IApiPhoneVerificationState, XcalibraClient } from '../../../../types/backend_definitions';
import { TimeUnits } from '../../../../types/constants';
import { II18nextT } from '../../../../types/i18n';
import { $InfoButton, $TransparentButton } from '../../../widgets/buttons';
import { $CodeInput } from '../../../widgets/CodeInput';
import $Label from '../../../widgets/Label';
import $Link from '../../../widgets/Link';
import LoadingAnimation from '../../../widgets/LoadingAnimation';
import Modal from '../../../widgets/Modal';
import { TextualSecondCountdown } from '../../../widgets/TextualSecondCountdown';

const CODE_LENGTH = 6;

const $ModalForm = styled.form`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${$ValidationWrapper} {
    max-width: 25rem;
  }
`;

const $SendingInitialRequestWrapper = styled.div`
  padding: 4rem;
  min-width: 20rem;
  min-height: 20rem;
  display: flex;
  align-content: center;
  justify-content: center;
`;

const $ModalContent = styled.div`
  padding: 2rem;
  max-width: 40rem;
`;

const $IntroText = styled.div`
  font-size: ${(p) => p.theme.fontSize.large};
  text-align: center;
  margin-bottom: 3rem;
  max-width: 30rem;
`;

const $FormLabel = styled($Label)`
  font-weight: bold;
  display: block;
  margin-bottom: 1rem;
`;

const $ButtonsWrapper = styled.div`
  margin: 1rem 1rem 3rem 1rem;
  button + button {
    margin-left: 0.2rem;
  }
`;

const $FooterText = styled.div`
  max-width: 25rem;
  text-align: center;
  > a {
    color: ${(p) => p.theme.colors.info};
    text-decoration: none;
  }
`;

interface IContainerProps {
  api: XcalibraClient;
}
interface IOwnProps {
  requestExpiration?: number;
}
interface IProps extends IOwnProps, IContainerProps, ConnectedProps<typeof connector> {}
interface IComponentState {
  code: string;
  verificationState: IApiPhoneVerificationState;
  verificationCountdownSeconds: number;
  loading: boolean;
  codeError: ITranslatedError;
}

class PhoneVerificationModal extends React.Component<IProps, IComponentState> {
  private mounted = false;
  static contextType: any = I18nContext;

  static defaultProps: Partial<IProps> = {
    requestExpiration: 1 * TimeUnits.day,
  };

  constructor(props) {
    super(props);

    this.state = {
      code: '',
      verificationState: undefined,
      verificationCountdownSeconds: undefined,
      loading: false,
      codeError: null,
    };
  }

  get isVQF() {
    return this.props.purpose === 'vqf_signature';
  }

  componentDidMount() {
    this.mounted = true;
    if (!!this.props.isOpen) {
      this.handleOpen();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IComponentState>,
    snapshot?: any
  ) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.handleOpen();
    } else if (!this.props.isOpen && prevProps.isOpen) {
      this.handleClose();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleOpen() {
    promiseGuard(
      this,
      this.props.api.getPhoneVerificationState(this.props.purpose, this.props.phone)
    ).then(this.submitVerificationState, (err) => {
      this.props.handleError(err);
      this.cancel();
    });
  }

  handleClose() {
    this.setState({
      codeError: null,
      verificationState: undefined,
      verificationCountdownSeconds: undefined,
      code: '',
    });
  }

  submitVerificationState = (verificationState: IApiPhoneVerificationState) => {
    if (verificationState && verificationState.phone_verified) {
      // We are already verified. Call back with good news. We do this before setState, so we can disappear without flashing UI
      this.props.submitPhoneVerificationResult(true);
      return;
    }

    let verificationCountdownSeconds = null;
    if (verificationState) {
      const elapsedMs = Date.now() - new Date(verificationState.created_at).valueOf();
      const msLeft = Math.max(verificationState.request_cooldown - elapsedMs, 0);
      verificationCountdownSeconds = Math.ceil(msLeft / TimeUnits.second);
    }

    this.setState({
      verificationState,
      verificationCountdownSeconds,
    });

    const shouldRequestAnotherVerification =
      // When we don't have a verification yet
      !verificationState ||
      // Current verification has been terminated
      verificationState.terminated ||
      // Request has expired
      Date.now() - new Date(verificationState.created_at).valueOf() > this.props.requestExpiration;

    if (shouldRequestAnotherVerification) {
      // We can send a new request
      this.sendVerificationCodeRequest();
    }
  };

  sendVerificationCodeRequest = () => {
    if (this.state.loading) {
      return null;
    }

    promiseGuard(
      this,
      this.props.api.putPhoneVerificationRequest({
        purpose: this.props.purpose as any,
        phone: this.props.phone,
      })
    ).then(this.submitVerificationState, (err) => {
      this.props.handleError(err);
      this.cancel();
    });
  };

  handleCodeChange = (e) => {
    this.setState({
      code: e.target.value.toUpperCase(),
      codeError: null,
    });
  };

  cancel = () => {
    this.props.submitPhoneVerificationResult(false);
  };

  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    this.setState({
      codeError: null,
    });

    // Try to confirm code
    promiseGuard(
      this,
      this.props.api.putPhoneVerificationVerify({
        purpose: this.props.purpose as any,
        phone: this.props.phone,
        code: this.state.code,
      })
    ).then(
      () => {
        // Verified!
        this.props.submitPhoneVerificationResult(true);
      },
      (err: HttpErrorResponse) => {
        if (err.code === 400 || err.name === 'AttemptLimitReachedError') {
          // We display code errors within the modal
          return this.setState({
            codeError: translateHttpError(err, this.context.t),
          });
        }

        // Other errors cancel the flow
        this.cancel();
        this.props.handleError(err);
      }
    );
  };

  handleResendClick = (e: React.MouseEvent) => {
    e.preventDefault();
    this.sendVerificationCodeRequest();
  };

  renderModalBody() {
    if (!this.props.phone || !this.props.isOpen) {
      return null;
    }

    if (!this.state.verificationState) {
      // Still sending initial verification request. Show loader.
      return (
        <$SendingInitialRequestWrapper>
          <LoadingAnimation />
        </$SendingInitialRequestWrapper>
      );
    }

    const { t }: II18nextT = this.context;

    return (
      <$ModalForm onSubmit={this.handleSubmit}>
        <$IntroText>
          <Trans
            i18nKey={
              this.isVQF
                ? 'phoneVerificationModal.introTextVQF'
                : 'phoneVerificationModal.introTextOnboarding'
            }
            components={[
              <strong key={0}>___</strong>,
              <$Link
                key={0}
                to={R.MANAGED_AGREEMENT}
                target="_blank"
                as={Link}
                style={{ fontWeight: 'bold' }}
              >
                __
              </$Link>,
            ]}
            values={{ phone: this.props.phone }}
          />
        </$IntroText>
        <$FormLabel htmlFor="code">
          {t(
            this.isVQF
              ? 'phoneVerificationModal.verificationCodeLabelVQF'
              : 'phoneVerificationModal.verificationCodeLabelOnboarding'
          )}
        </$FormLabel>
        <ValidationWrapper error={this.state.codeError} path={'code'} fallbackToSingle>
          <$CodeInput
            id="code"
            centered
            border="none"
            value={this.state.code}
            onChange={this.handleCodeChange}
            maxLength={CODE_LENGTH}
            placeholder={'ABCDEF'}
            autoComplete="one-time-code"
            autoFocus={true}
          />
        </ValidationWrapper>
        <$ButtonsWrapper>
          <$InfoButton
            type="submit"
            disabled={this.state.loading || (this.state.code || '').length < CODE_LENGTH}
          >
            {t('phoneVerificationModal.submitButtonLabel')}
          </$InfoButton>
          <$TransparentButton onClick={this.cancel}>
            {t('phoneVerificationModal.cancelButtonLabel')}
          </$TransparentButton>
        </$ButtonsWrapper>
        <$FooterText>
          {this.state.verificationCountdownSeconds ? (
            <Trans
              i18nKey={'phoneVerificationModal.footerTextWithWaitCountdown'}
              components={[
                <TextualSecondCountdown
                  key={0}
                  seconds={this.state.verificationCountdownSeconds}
                  onCompleted={() => {
                    this.setState({
                      verificationCountdownSeconds: null,
                    });
                  }}
                />,
              ]}
            />
          ) : (
            <Trans
              i18nKey={'phoneVerificationModal.footerTextWithTryAgainLink'}
              components={[
                <$Link key={0} color="info" href="#" onClick={this.handleResendClick}>
                  __
                </$Link>,
              ]}
            />
          )}
        </$FooterText>
      </$ModalForm>
    );
  }

  render() {
    if (this.state.verificationState === undefined) {
      // Still loading. Don't render anything, because we might not even need the modal to be seen (if phone is already verified)
      return null;
    }

    const { t }: II18nextT = this.context;

    return (
      <Modal
        title={t(
          this.isVQF
            ? 'phoneVerificationModal.modalTitleVQF'
            : 'phoneVerificationModal.modalTitleOnboarding'
        )}
        titleUppercase={true}
        onClose={this.cancel}
        isOpen={this.props.isOpen}
      >
        {this.renderModalBody()}
      </Modal>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    purpose: state.phoneVerification ? state.phoneVerification.purpose : null,
    phone: state.phoneVerification ? state.phoneVerification.phone : null,
    isOpen: isPhoneVerificationModalOpen(state),
  }),
  { handleError, submitPhoneVerificationResult }
);
export default connector(inject('api')(PhoneVerificationModal));

// *********************************************************************************************************************
