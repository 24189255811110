import lodash from '../lib/lodash';
import { commit, val } from '../lib/redux_helpers';
import { IState, IThunkMethod } from '../lib/store';
import { IApiExternalRates, IApiMarketRates, IApiRates } from '../types/backend_definitions';

export interface IRatesState {
  rates: IApiRates;
}

export const RATES_STATE: IRatesState = {
  rates: { external: null, market: null, fallback: null, override: null },
};

export const setRates = (rates: IApiRates): IThunkMethod => (dispatch) =>
  dispatch(commit(`Update rates`, { rates: val<IState['rates']>(rates) }));

export const setExternalRates = (externalRates: IApiExternalRates): IThunkMethod => (dispatch) =>
  dispatch(
    commit(`Update external rates`, {
      rates: { external: val<IState['rates']['external']>(externalRates) },
    })
  );

export const setMarketRates = (marketRates: IApiMarketRates): IThunkMethod => (dispatch) =>
  dispatch(
    commit(`Update market rates`, {
      rates: { market: val<IState['rates']['market']>(marketRates) },
    })
  );

let pendingRates: IApiMarketRates = null;
const processMarketRatesThrottled = lodash.throttle(
  (dispatch) => {
    if (!pendingRates) {
      return;
    }
    const rates = { ...pendingRates };
    pendingRates = null;
    dispatch(
      commit(`Update market rates`, {
        rates: { market: val<IState['rates']['market']>(rates) },
      })
    );
  },
  200,
  {
    leading: false,
    trailing: true,
  }
);

export const processMarketRates = (rates: IApiMarketRates): IThunkMethod => (dispatch) => {
  pendingRates = rates;
  processMarketRatesThrottled(dispatch);
};
