import { createSelector } from 'reselect';

import { getSimpleNotificationData, notifySuccess } from '../actions/app';
import { showSessionTerminatedScreen, terminateSession } from '../actions/auth/logout';
import Container from '../lib/container';
import { handleError } from '../lib/errors';
import { Logger } from '../lib/logger';
import { commit, val } from '../lib/redux_helpers';
import { IState, IThunkMethod, Store } from '../lib/store';
import { getSession } from '../selectors/auth';
import { IDLE_TIME_COUNTDOWN, TimeUnits } from '../types/constants';
import { USER_ACTIVITY_UPDATE_FREQUENCY } from './user_activity_tracking';

export interface IUserKeepAliveState {
  idleTimeoutCountdownActive: boolean;
}
export const USER_KEEP_ALIVE_STATE: IUserKeepAliveState = {
  idleTimeoutCountdownActive: false,
};

const setIdleTimeoutCountdownActive = (active: boolean) =>
  commit(`${active ? 'Start' : 'Stop'} idle timeout countdown`, {
    idleTimeoutCountdownActive: val(active),

    // Also close phone verification modal
    // TODO: Can we make some system where this is not this flaky? Or maybe wait until we have 3 non-route based modals
    phoneVerification: val(null),
  });

/**
 * Notify server about when was user last seen. Returns true if the request succeeds.
 */
const reportUserSeenAt = (seenAt: number, now: number = null): IThunkMethod<Promise<boolean>> => (
  dispatch,
  getState,
  { api }: Container
) => {
  const session = getSession(getState());
  if (!session) {
    return Promise.resolve(false);
  }

  now = now || Date.now();

  dispatch(
    commit(`Proactively update session seen_at to ${new Date(seenAt).toISOString()}`, {
      session: {
        seen_at: val(new Date(seenAt).toISOString()),
      },
    })
  );

  return api
    .putAuthNotifySeen({
      access_token: session.access_token,
      seen_ago: now - seenAt,
    })
    .then(
      (updatedSession) => {
        dispatch(
          commit(
            `Update session after seen_at notification (new seen_at: ${updatedSession.seen_at})`,
            {
              session: val(updatedSession),
            }
          )
        );

        return true;
      },
      (err) => {
        dispatch(
          commit(
            `Revert session seen_at to ${session.seen_at} after a failed seen_at update request`,
            {
              session: {
                seen_at: val(session.seen_at),
              },
            }
          )
        );

        dispatch(handleError(err));
        return false;
      }
    );
};

const logoutDueToIdleTimeout = (): IThunkMethod => (dispatch, getState, { api }: Container) => {
  dispatch(terminateSession('idle_timeout', api.putAuthNotifyIdleTimeoutSpec()));
  dispatch(showSessionTerminatedScreen('idle_timeout'));
};

const notifyIdleTimeoutPrevented = (): IThunkMethod => (dispatch, _, { i18n }) => {
  dispatch(notifySuccess(getSimpleNotificationData(i18n.t('sessionInactiveTimeout.renewed'))));
};

// *********************************************************************************************************************

const getSessionSeenAt = (state: IState) =>
  state.session && state.session.seen_at ? new Date(state.session.seen_at).valueOf() : null;

const getSessionIdleTimeout = (state: IState) =>
  (state.session && state.session.idle_timeout) || null;

const getLocalSeenAt = (state: IState) => state.userLastSeenTs;

// *********************************************************************************************************************

export class SessionIdlenessTracker {
  /**
   * How long will idle timeout countdown last
   */
  private idleTimeCountdown = IDLE_TIME_COUNTDOWN;

  /**
   * Let's say our critical period is 4 times as long as update frequency, to be safe (1 min).
   */
  private criticalPeriodDuration = 4 * USER_ACTIVITY_UPDATE_FREQUENCY;

  /**
   * How long to wait at most between updating seen_at at the server. If server sets some super-long seen at,
   * we don't want it to be super-stale at the server.
   */
  private maxIntervalBetweenUpdates = 5 * TimeUnits.minute;

  /**
   * Do not update seen_at more frequently than this
   */
  private minIntervalBetweenUpdates = 1 * TimeUnits.second;

  /**
   * Active timeout id that we are waiting to trigger
   */
  private activeTimeout = null;

  /**
   * Last backend update payload we have sent
   */
  private lastReportedSeenAt: number = null;

  constructor(private store: Store, private logger: Logger) {
    this.logger = this.logger.prefixed(this);
  }

  start() {
    this.updateIfStoreChanged();
    this.store.subscribe(() => this.updateIfStoreChanged());
  }

  private updateIfStoreChanged = () => {
    this.doUpdateMemoized(this.store.getState());
  };

  private updateNow = () => {
    const state = this.store.getState();
    this.doUpdate(getSessionSeenAt(state), getSessionIdleTimeout(state), getLocalSeenAt(state));
  };

  private now() {
    return Date.now();
  }

  /**
   * Main update method. It will be called memoized, so every call is guaranteed to have different parameters given.
   */
  private doUpdate = (sessionSeenAt: number, sessionIdleTimeout: number, localSeenAt: number) => {
    // There are fundamentally 4 phases here.
    //
    //                1                    2                   3                    4
    //     >--------------------*--------------------*--------------------*-------------------->  time
    //      2-5 min left        ^ 2 min left         ^ 1 min left         ^ expiration
    //      (renew whenever)      (critical period,    (show countdown)     (logout)
    //                             renew ASAP)

    const now = this.now();
    const msUntilExpiration = sessionSeenAt + sessionIdleTimeout - now;

    this.logger.shouldVerbose &&
      this.logger.verbose(`Updating based on:`, {
        sessionSeenAt: new Date(sessionSeenAt).toISOString(),
        userSeenAt: new Date(localSeenAt).toISOString(),
        sessionIdleTimeout,
        msUntilExpiration,
      });

    clearTimeout(this.activeTimeout);
    this.activeTimeout = null;

    if (!sessionIdleTimeout || !sessionSeenAt) {
      // Not logged in or "keep alive" disabled. Easy case, nothing to do.
      this.setCountdownActive(false, false);

      this.logger.verbose(
        `Phase 0: No idle timeout needed due to lack of session or "keep session alive" being set`
      );
      return;
    }

    if (msUntilExpiration > this.criticalPeriodDuration + this.idleTimeCountdown) {
      // We are in phase 1. Plenty of time left.

      // If session is due for an update, let's do that now
      const msUntilSessionIsDueForAnUpdate = sessionSeenAt + this.maxIntervalBetweenUpdates - now;
      if (msUntilSessionIsDueForAnUpdate <= 0 && localSeenAt !== sessionSeenAt) {
        this.reportUserSeenAt(localSeenAt);
      }

      // Wake up when critical period starts or when session is due for an update, whichever comes first
      const msUntilCriticalPeriod =
        msUntilExpiration - this.idleTimeCountdown - this.criticalPeriodDuration;
      this.activeTimeout = setTimeout(
        this.updateNow,
        msUntilSessionIsDueForAnUpdate > 0
          ? Math.min(msUntilCriticalPeriod, msUntilSessionIsDueForAnUpdate)
          : msUntilCriticalPeriod
      );

      // Make sure we are not showing countdown
      this.setCountdownActive(false, true);

      this.logger.verbose(`Phase 1: Will enter critical period in ${msUntilCriticalPeriod}ms`);
      return;
    }

    if (msUntilExpiration > this.idleTimeCountdown) {
      // We are in phase 2, the critical period.

      // Any time we receive a different seenAt now, we should notify the server.
      if (localSeenAt !== sessionSeenAt) {
        this.reportUserSeenAt(localSeenAt);
      }

      // We should also schedule the timeout to show the countdown popup
      const msUntilCountdown = msUntilExpiration - this.idleTimeCountdown;
      this.activeTimeout = setTimeout(this.updateNow, msUntilCountdown);

      // But make sure we are not showing countdown now
      this.setCountdownActive(false, true);

      this.logger.verbose(
        `Phase 2: In critical period. We will show countdown screen in ${msUntilCountdown}ms`
      );
      return;
    }

    if (msUntilExpiration > 0) {
      // We are in phase 3. Less than 1 minute left.

      // We should be showing the timer now.
      this.setCountdownActive(true, true);

      // The timer should be for the session expiration now
      this.activeTimeout = setTimeout(this.updateNow, msUntilExpiration);

      // If user shows up, notify the server
      if (localSeenAt !== sessionSeenAt) {
        this.reportUserSeenAt(localSeenAt);
      }

      this.logger.verbose(
        `Phase 3: Showing countdown screen. User will be kicked out in ${msUntilExpiration}ms`
      );
      return;
    }

    {
      // Phase 4. Session should be expired now.

      // If countdown is still active, it means we must do the needful.
      if (this.isCountdownActive) {
        this.setCountdownActive(false, false);
        (this.store.dispatch as any)(logoutDueToIdleTimeout());

        this.logger.verbose(
          `Phase 4: User was logged out, countdown screen closed, idle timeout reported`
        );
      }
    }
  };

  private get isCountdownActive() {
    return this.store.getState().idleTimeoutCountdownActive;
  }

  private setCountdownActive(active: boolean, stillLoggedIn: boolean) {
    const wasActive = this.isCountdownActive;
    if (wasActive && !active) {
      // Close modal
      this.store.dispatch(setIdleTimeoutCountdownActive(false));

      // If we still have a valid session, that means the session timeout was aborted. Show toast.
      if (stillLoggedIn) {
        (this.store.dispatch as any)(notifyIdleTimeoutPrevented());
      }
    } else if (!wasActive && active) {
      // Show modal
      this.store.dispatch(setIdleTimeoutCountdownActive(true));
    }
  }

  private reportUserSeenAt = (seenAt: number) => {
    if (this.lastReportedSeenAt) {
      if (this.lastReportedSeenAt >= seenAt) {
        // We can drop this one, it's either the same or stale
        return;
      }

      if (seenAt - this.lastReportedSeenAt <= this.minIntervalBetweenUpdates) {
        // No need to send this one, too short time
        return;
      }
    }

    this.lastReportedSeenAt = seenAt;
    (this.store.dispatch as any)(reportUserSeenAt(seenAt, this.now()));
  };

  private doUpdateMemoized = createSelector(
    getSessionSeenAt,
    getSessionIdleTimeout,
    getLocalSeenAt,
    this.doUpdate
  );
}
