import { IPriceSummary24h, IPriceSummary24hPair } from '../actions/exchange';
import { IDepositAddresses } from '../actions/transactions/transactions';
import { IApiKey } from '../types/api_keys';
import {
  IApiBot,
  IApiCensoredDepositAddress,
  IApiCensoredOrder,
  IApiPriceHistorySummary,
  IApiPublicTradeInfo,
  IApiWallet,
  IInstrument,
  IPair,
} from '../types/backend_definitions';
import { IBalances } from '../types/balances';
import { instrumentInfo } from '../types/instruments';
import { IOrder } from '../types/orders';
import { ITrade } from '../types/trades';
import lodash from './lodash';

export const tradeResponseTransformation = (trade: IApiPublicTradeInfo): ITrade => ({
  ...trade,
  quantity: parseFloat(trade.quantity),
});

export const orderResponseTransformation = (order: IApiCensoredOrder): IOrder => ({
  ...lodash.omit(order, 'quantity'),
  quantity: parseFloat(order.quantity),
});

export const priceSummary24hTransformation = (
  summary: IApiPriceHistorySummary,
  supportedPairs: IPair[] | Readonly<IPair[]>
): IPriceSummary24h => {
  const result = {};

  for (const pair of supportedPairs) {
    if (summary.by_pair[pair]) {
      // Set the data from server
      result[pair] = summary.by_pair[pair] as IPriceSummary24hPair;
    } else {
      // Mock out empty data
      result[pair] = {
        pair,
        open: 0,
        high: 0,
        low: 0,
        close: 0,
        count: 0,
        volume: '0',
      } as IPriceSummary24hPair;
    }
  }

  return result;
};

export const balancesTransformation = (wallet: IApiWallet): IBalances => {
  const balances: IBalances = {};

  (Object.keys(wallet.instruments) as IInstrument[]).forEach((symbol) => {
    const balance = wallet.instruments[symbol];

    balances[symbol] = {
      ...instrumentInfo(symbol),
      total: parseFloat(balance.total),
      reserved: parseFloat(balance.reserved),
      available: parseFloat(balance.total) - parseFloat(balance.reserved),
    };
  });

  return balances;
};

export const depositAddressesResponseTransformation = (
  addresses: IApiCensoredDepositAddress[]
): IDepositAddresses =>
  addresses.reduce((a, c) => {
    for (const i of c.instruments) {
      a[i] = c.address;
    }

    return a;
  }, {}) as IDepositAddresses;

export const apiKeyResponseTransformation = (apiKey: IApiBot): IApiKey => ({
  api_key: apiKey.api_key,
  secret_key: null,
  permission_read: apiKey.bot_permissions.includes('read'),
  permission_place_order: apiKey.bot_permissions.includes('place_order'),
  permission_cancel_order: apiKey.bot_permissions.includes('cancel_order'),
  permission_withdraw: apiKey.bot_permissions.includes('withdraw'),
  cidr: apiKey.cidr || '',
  last_nonce: apiKey.last_nonce,
  created_at: apiKey.created_at,
  disabled_at: apiKey.disabled_at,
  suspended_at: apiKey.suspended_at,
  updated_at: apiKey.updated_at,
  seen_at: apiKey.seen_at,
  deleted_at: apiKey.deleted_at,
  description: apiKey.description,
});
