import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteChildrenProps, RouteComponentProps, StaticContext } from 'react-router';

import { clearActiveRoute, setActiveRoute } from '../../actions/routing';
import { ICustomLocationState } from '../../lib/history';
import { IRouteSpec } from '../../lib/routes';
import { IState } from '../../lib/store';

interface IOwnProps {
  route: IRouteSpec;
  routeProps: RouteChildrenProps;
  component?: React.ComponentType;
  layout?: React.ComponentType<{ route: IRouteSpec }>;
  render?: (
    props: RouteComponentProps<any, StaticContext, ICustomLocationState>
  ) => React.ReactNode;
}

interface ICustomRouteWrapperProps extends IOwnProps, ConnectedProps<typeof connector> {}

interface ICustomRouteWrapperState {
  activeRouteValid: boolean;
}

const getParams = (routeProps: RouteChildrenProps) =>
  routeProps.match && routeProps.match.params ? routeProps.match.params : null;

/**
 * The purpose of this wrapper is to set and clear currently active routes
 */
class CustomRouteWrapper extends React.PureComponent<
  ICustomRouteWrapperProps,
  ICustomRouteWrapperState
> {
  constructor(props) {
    super(props);

    this.state = {
      activeRouteValid: false,
    };
  }

  componentDidMount(): void {
    this.props.setActiveRoute(this.props.route, getParams(this.props.routeProps));
  }

  componentDidUpdate(): void {
    this.props.setActiveRoute(this.props.route, getParams(this.props.routeProps));
  }

  componentWillUnmount() {
    this.props.clearActiveRoute(this.props.route);
  }

  static getDerivedStateFromProps(
    props: ICustomRouteWrapperProps,
    state: ICustomRouteWrapperState
  ) {
    // We can render our content once the route in the state matches our own route
    const activeRouteValid = props.activeRoute && props.activeRoute.routeId === props.route.id;
    return {
      activeRouteValid,
    };
  }

  renderWithLayout(content) {
    if (!this.props.layout) {
      return content;
    }

    const Layout = this.props.layout;
    return <Layout route={this.props.route}>{content}</Layout>;
  }

  render() {
    if (!this.props.activeRoute) {
      // Do not render anything until we have set the correct active route
      return null;
    }

    if (this.props.render) {
      return this.renderWithLayout(this.props.render(this.props.routeProps));
    }

    const Component = this.props.component;
    return this.renderWithLayout(<Component />);
  }
}

const connector = connect(
  (state: IState, props: IOwnProps) => ({
    activeRoute: props.route.dynamic ? state.activeDynamicRoute : state.activeStaticRoute,
  }),
  {
    setActiveRoute,
    clearActiveRoute,
  }
);

export default connector(CustomRouteWrapper);
