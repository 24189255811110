import * as React from 'react';
import { I18nextProvider } from 'react-i18next';

import { I18n } from '../../../../lib/i18n';
import styled from '../../../../lib/styled_components';
import { IApiPublicCustomerInfo } from '../../../../types/backend_definitions';
import { II18nextT } from '../../../../types/i18n';
import { AlertWarningWithIcon } from '../../../widgets/Alert';

const $KYCFailureNotice = styled(AlertWarningWithIcon).attrs({ iconSize: '4.5rem' })`
  max-width: 400px;
  margin-bottom: 2rem;
`;

const $Message = styled.h4`
  background: rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin: 1rem 0;
  white-space: pre-wrap;
`;

interface IProps {
  title: string;
  message?: string;
  footer?: string;
}

const KYCFailureNotice: React.FunctionComponent<IProps> = ({ title, message, footer }) => {
  return (
    <$KYCFailureNotice>
      <div>
        <h3>{title}</h3>
        {message && <$Message>{message}</$Message>}
        {footer && <h4>{footer}</h4>}
      </div>
    </$KYCFailureNotice>
  );
};

export default KYCFailureNotice;

export const KYCLockedNotice: React.FunctionComponent<{
  customer: Pick<IApiPublicCustomerInfo, 'kyc_lock_message'>;
}> = ({ customer }) => {
  return (
    <I18n>
      {(t) => (
        <KYCFailureNotice
          title={t('settings:kyc.lockTitle')}
          message={t([`backend:messages.${customer.kyc_lock_message}`, customer.kyc_lock_message])}
        />
      )}
    </I18n>
  );
};
