import * as React from 'react';
import { Component, ComponentClass, createContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Omit } from '../types/typescript_helpers';
import Container, { IServiceName } from './container';

const hoistNonReactStatics = require('hoist-non-react-statics');

const ContainerContext = createContext<Container>(null);

// *********************************************************************************************************************

interface IContainerProviderProps {
  container: Container;
}

export class ContainerProvider extends React.Component<IContainerProviderProps> {
  render() {
    return (
      <ContainerContext.Provider value={this.props.container}>
        {this.props.children}
      </ContainerContext.Provider>
    );
  }
}

// *********************************************************************************************************************

// NOTE: If you get an error that some inject() target's prop doesn't extend the list of services, check that you are actually
//       injecting something.
type Injector = <P extends { [key in IServiceName]?: any }, S>(
  WrappedComponent: ComponentClass<P, S> | React.FunctionComponent<P>
) => ComponentClass<Omit<P, IServiceName>, S> | React.FunctionComponent<Omit<P, IServiceName>>;

export function inject(...serviceNames: IServiceName[]): Injector {
  return (WrappedComponent: React.Component | any) => {
    const wrappedComponentName =
      WrappedComponent.displayName || WrappedComponent.name || 'Component';

    class ContainerWrapper extends React.Component {
      _wrappedInstance = null;

      constructor(props) {
        super(props);
      }

      get wrappedInstance() {
        return this._wrappedInstance;
      }

      private setWrappedInstance = (ref) => {
        this._wrappedInstance = ref;
      };

      render() {
        return (
          <ContainerContext.Consumer>
            {(container) => {
              if (!container) {
                throw new Error(
                  `Container has not been injected. Did you forget to do ` +
                    `<ContainerProvider container="container"><MyApp></ContainerProvider>?`
                );
              }

              const serviceProps = {};
              serviceNames.forEach((serviceName) => {
                serviceProps[serviceName] = container[serviceName];
              });

              // https://stackoverflow.com/questions/41488713/react-how-to-determine-if-component-is-stateless-functional
              const supportsRef = Boolean(Component.prototype.render);

              return (
                <WrappedComponent
                  {...this.props}
                  ref={supportsRef ? this.setWrappedInstance : undefined}
                  {...serviceProps}
                />
              );
            }}
          </ContainerContext.Consumer>
        );
      }
    }

    WrappedComponent.displayName = wrappedComponentName;

    return hoistNonReactStatics(ContainerWrapper, WrappedComponent);
  };
}

export function withRouterAndRef<P>(WrappedComponent) {
  class InnerComponentWithRef extends React.Component<any, any> {
    render() {
      const { forwardRef, ...rest } = this.props;
      return <WrappedComponent {...rest} ref={forwardRef} />;
    }
  }

  const ComponentWithRouter = withRouter(InnerComponentWithRef);
  return React.forwardRef<P & RouteComponentProps, any>((props, ref) => {
    return <ComponentWithRouter {...props} forwardRef={ref} />;
  });
}
