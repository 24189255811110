import chunk from 'lodash-es/chunk';
import cloneDeep from 'lodash-es/cloneDeep';
import debounce from 'lodash-es/debounce';
import endsWith from 'lodash-es/endsWith';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import findIndex from 'lodash-es/findIndex';
import forEach from 'lodash-es/forEach';
import get from 'lodash-es/get';
import isArray from 'lodash-es/isArray';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import isFunction from 'lodash-es/isFunction';
import isNaN from 'lodash-es/isNaN';
import isObjectLike from 'lodash-es/isObjectLike';
import isPlainObject from 'lodash-es/isPlainObject';
import isString from 'lodash-es/isString';
import merge from 'lodash-es/merge';
import omit from 'lodash-es/omit';
import orderBy from 'lodash-es/orderBy';
import pad from 'lodash-es/pad';
import pickBy from 'lodash-es/pickBy';
import throttle from 'lodash-es/throttle';
import times from 'lodash-es/times';
import upperFirst from 'lodash-es/upperFirst';

export default {
  chunk,
  cloneDeep,
  debounce,
  endsWith,
  filter,
  find,
  findIndex,
  forEach,
  get,
  isArray,
  isEmpty,
  isEqual,
  isFunction,
  isNaN,
  isObjectLike,
  isPlainObject,
  isString,
  merge,
  omit,
  orderBy,
  pad,
  pickBy,
  throttle,
  times,
  upperFirst,
};
