import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { cancelOrder } from '../../../actions/orders/cancel_order';
import { IOrderState, loadActiveUserOrdersForInstrumentPair } from '../../../actions/orders/orders';
import Quantity from '../../../lib/quantity';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { formatFullDate, formatHoursOnly, formatNumberToFixed } from '../../../lib/util';
import { getCurrentPair } from '../../../selectors/exchange';
import {
  getActiveOrdersPaginationState,
  getSortedOrdersListForCurrentInstrumentPair,
} from '../../../selectors/orders';
import { IPaginated } from '../../../types/api';
import { IApiCensoredOrder, IOrderSide } from '../../../types/backend_definitions';
import { II18nextT } from '../../../types/i18n';
import { PRICE_DIGITS } from '../../../types/instruments';
import { $SmallButton } from '../../widgets/buttons';
import { DateSplitScrollBox } from '../../widgets/DateSplitScrollBox';
import PrettyDecimals, { SidedPrettyDecimals } from '../../widgets/PrettyDecimals';
import {
  $Cell,
  $DigitCell,
  $Table,
  $TableHeading,
  $TableHeadingBadge,
  $TableRow,
  ICellProps,
} from '../../widgets/Table';
import TableActionIndicator from '../../widgets/TableActionIndicator';
import TradeSideIndicator from '../../widgets/TradeSideIndicator';

const $CancelOrderButton = styled($SmallButton).attrs((p) => ({
  backgroundColor: p.theme.colors.transparent,
  hoverBackground: p.theme.colors.brandPrimaryLighter,
  fontSize: p.theme.fontSize.large,
  color: p.theme.colors.sell,
  padding: '0 3px',
}))``;

const $UserActiveOrders = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const $Title = styled.h4`
  color: ${(p) => p.theme.colors.info};
  @media only screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    font-size: ${(p) => p.theme.layout.exchange.sectionTitle.iphonePlus.fontSize};
    line-height: ${(p) => p.theme.layout.exchange.sectionTitle.iphonePlus.fontSize};
  }
`;

const $UserActiveOrdersTable = styled($Table).attrs((p) => ({
  noPadding: true,
  overlayColor: p.theme.components.userActiveOrders.overlayColor,
}))``;

const $OrderSideWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

type ICellNames = 'side' | 'time' | 'quantity' | 'price' | 'total' | 'cancel';
const CELLS: { [key in ICellNames]: Partial<ICellProps> } = {
  side: { width: '1.8rem', align: 'left', noGrow: true },
  time: { width: '4.2rem', align: 'left', noGrow: true },
  quantity: { width: 'calc((100% - 30px - 6.2rem) * 0.35)', align: 'right', noGrow: true },
  price: { width: 'calc((100% - 30px - 6.2rem) * 0.30)', align: 'right', noGrow: true },
  total: { width: 'calc((100% - 30px - 6.2rem) * 0.35)', align: 'right', noGrow: true },
  cancel: { width: '30px', align: 'center', noGrow: true, withOverflow: true },
};

export type IUserActiveOrdersPaginationState = Partial<IPaginated<IApiCensoredOrder>>;
// component types and interfaces
interface IUserActiveOrdersProps extends ConnectedProps<typeof connector> {}

interface IUserActiveOrdersState {}

class UserActiveOrders extends React.PureComponent<IUserActiveOrdersProps, IUserActiveOrdersState> {
  static contextType: any = I18nContext;

  private renderRow = (os: IOrderState) => {
    const {
      pair: { path, base, quote },
      cancelOrder,
    } = this.props;
    const orderMarkedForRemoval = Boolean(os.forCancellation);
    const total =
      os.order.side === 'buy'
        ? Quantity(os.order.quantity).baseLoss(os.order.price, os.order.fee, base.symbol)
        : Quantity(os.order.quantity).baseGain(os.order.price, os.order.fee, base.symbol);

    return (
      <$TableRow
        key={os.order.id}
        style={{ opacity: orderMarkedForRemoval ? 0.5 : 1, display: 'flex' }}
        noPointer
        noBottomBorder
      >
        <$Cell {...CELLS.side}>
          <$OrderSideWrapper>
            <TradeSideIndicator tradeSide={os.order.side} />
          </$OrderSideWrapper>
        </$Cell>
        <$Cell {...CELLS.time} title={formatFullDate(os.order.timestamp)}>
          {formatHoursOnly(os.order.timestamp)}
        </$Cell>
        <$DigitCell {...CELLS.quantity}>
          <PrettyDecimals value={formatNumberToFixed(os.order.quantity, quote.digits)} />
        </$DigitCell>
        <$DigitCell {...CELLS.price}>
          <SidedPrettyDecimals
            value={formatNumberToFixed(os.order.price, PRICE_DIGITS)}
            side={os.order.side as IOrderSide}
          />
        </$DigitCell>
        <$DigitCell {...CELLS.total}>
          <PrettyDecimals value={formatNumberToFixed(total, base.digits)} />
        </$DigitCell>
        <$Cell {...CELLS.cancel}>
          <$CancelOrderButton
            disabled={orderMarkedForRemoval}
            onClick={() => cancelOrder(path, os.order.id)}
          >
            {orderMarkedForRemoval ? '↻' : '✕'}
          </$CancelOrderButton>
        </$Cell>
      </$TableRow>
    );
  };

  render() {
    const { t }: II18nextT = this.context;
    const { pair } = this.props;
    if (!pair) {
      return null;
    }

    return (
      <$UserActiveOrders>
        <$Title>{t('exchangeComponents.myOrders')}</$Title>
        <$UserActiveOrdersTable>
          <$TableHeading noBorder>
            <$Cell {...CELLS.side}>&nbsp;</$Cell>
            <$Cell {...CELLS.time}>
              {t('userActiveOrders.columns.time')}
              <$TableHeadingBadge>{t('utc')}</$TableHeadingBadge>
            </$Cell>
            <$Cell {...CELLS.quantity}>
              <$TableHeadingBadge>{t('userActiveOrders.columns.amount')}</$TableHeadingBadge>
              {pair.quote.symbol}
            </$Cell>
            <$Cell {...CELLS.price}>
              <$TableHeadingBadge>{t('userActiveOrders.columns.price')}</$TableHeadingBadge>
              {pair.base.symbol}
            </$Cell>
            <$Cell {...CELLS.total}>
              <$TableHeadingBadge>{t('userActiveOrders.columns.total')}</$TableHeadingBadge>
              {pair.base.symbol}
            </$Cell>
          </$TableHeading>
          <DateSplitScrollBox
            mobileHeight="150px"
            data={this.props.data.items}
            renderRow={this.renderRow}
          >
            <TableActionIndicator
              paginationState={this.props.ordersPagination}
              requests={[
                'getActiveUserOrdersForAllInstrumentPairs',
                'getActiveUserOrdersForInstrumentPair',
              ]}
              itemsCount={this.props.data.count}
              actionFn={() =>
                this.props.loadActiveUserOrdersForInstrumentPair(this.props.pair.path)
              }
            />
          </DateSplitScrollBox>
        </$UserActiveOrdersTable>
      </$UserActiveOrders>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    data: getSortedOrdersListForCurrentInstrumentPair(state),
    ordersPagination: getActiveOrdersPaginationState(state),
    pair: getCurrentPair(state),
  }),
  { cancelOrder, loadActiveUserOrdersForInstrumentPair }
);

export default connector(UserActiveOrders);
