import { createSelector } from 'reselect';

import { IState } from '../lib/store';
import { IInstrument, IPair } from './backend_definitions';

export type IInstrumentType = 'bank' | 'crypto';

export type IInstrumentName =
  | 'Bitcoin'
  | 'Ethereum'
  | 'Litecoin'
  | 'Liberland Merit'
  | 'Safex Cash'
  | 'Wrapped Safex Cash'
  | 'Safex Token'
  | 'Serbian Dinar'
  | 'Euro'
  | 'American dollar'
  | 'Swiss franc'
  | 'Pound sterling'
  | 'Russian Rouble'
  | 'Danish krone'
  | 'Norwegian krone'
  | 'Czech koruna'
  | 'Polish zloty'
  | 'Swedish krona'
  | 'Australian dollar'
  | 'Hungarian forint'
  | 'Canadian dollar'
  | 'Japanese yen'
  | 'Turkish lira'
  | 'South African rand'
  | 'Israeli new shekel'
  | 'New Zealand dollar';

export interface IInstrumentObject {
  symbol: IInstrument;
  name: IInstrumentName;
  type: IInstrumentType;
  digits: number;
}

export type IInstruments = { [key in IInstrument]: Readonly<IInstrumentObject> };

export const ALL_INSTRUMENTS: Readonly<IInstruments> = {
  BTC: { symbol: 'BTC', name: 'Bitcoin', type: 'crypto', digits: 8 },
  ETH: { symbol: 'ETH', name: 'Ethereum', type: 'crypto', digits: 8 },
  LTC: { symbol: 'LTC', name: 'Litecoin', type: 'crypto', digits: 8 },
  LLM: { symbol: 'LLM', name: 'Liberland Merit', type: 'crypto', digits: 8 },
  SFX: { symbol: 'SFX', name: 'Safex Cash', type: 'crypto', digits: 8 },
  WSFX: { symbol: 'WSFX', name: 'Wrapped Safex Cash', type: 'crypto', digits: 8 },
  SFT: { symbol: 'SFT', name: 'Safex Token', type: 'crypto', digits: 0 },
  RSD: { symbol: 'RSD', name: 'Serbian Dinar', type: 'bank', digits: 2 },
  EUR: { symbol: 'EUR', name: 'Euro', type: 'bank', digits: 2 },
  USD: { symbol: 'USD', name: 'American dollar', type: 'bank', digits: 2 },
  CHF: { symbol: 'CHF', name: 'Swiss franc', type: 'bank', digits: 2 },
  GBP: { symbol: 'GBP', name: 'Pound sterling', type: 'bank', digits: 2 },
  RUB: { symbol: 'RUB', name: 'Russian Rouble', type: 'bank', digits: 2 },
  DKK: { symbol: 'DKK', name: 'Danish krone', type: 'bank', digits: 2 },
  NOK: { symbol: 'NOK', name: 'Norwegian krone', type: 'bank', digits: 2 },
  CZK: { symbol: 'CZK', name: 'Czech koruna', type: 'bank', digits: 2 },
  PLN: { symbol: 'PLN', name: 'Polish zloty', type: 'bank', digits: 2 },
  SEK: { symbol: 'SEK', name: 'Swedish krona', type: 'bank', digits: 2 },
  AUD: { symbol: 'AUD', name: 'Australian dollar', type: 'bank', digits: 2 },
  HUF: { symbol: 'HUF', name: 'Hungarian forint', type: 'bank', digits: 2 },
  CAD: { symbol: 'CAD', name: 'Canadian dollar', type: 'bank', digits: 2 },
  JPY: { symbol: 'JPY', name: 'Japanese yen', type: 'bank', digits: 2 },
  NZD: { symbol: 'NZD', name: 'New Zealand dollar', type: 'bank', digits: 2 },
  TRY: { symbol: 'TRY', name: 'Turkish lira', type: 'bank', digits: 2 },
  ZAR: { symbol: 'ZAR', name: 'South African rand', type: 'bank', digits: 2 },
  ILS: { symbol: 'ILS', name: 'Israeli new shekel', type: 'bank', digits: 2 },
};

export const ALL_INSTRUMENTS_SYMBOLS = Object.keys(ALL_INSTRUMENTS) as IInstrument[];

// NOTE: For now, all prices are at 8 digits. At some point, we'll want to implement price digits per exchange
export const PRICE_DIGITS = 8;

// source: https://github.com/lukechilds/coinlist/, https://github.com/lukechilds/coinlist/blob/master/src/index.js
const instrumentPairRegex: RegExp = /([A-Z0-9]{1,10})_([A-Z0-9]{1,10})/;

export function instrumentInfo(symbol: string): IInstrumentObject {
  symbol = symbol.toUpperCase();
  return ALL_INSTRUMENTS[symbol];
}

export interface IInstrumentPair {
  path: IPair;
  displayPath: string;
  base: IInstrumentObject;
  quote: IInstrumentObject;
}

export function pairInfo(pair: IPair): IInstrumentPair {
  const match = instrumentPairRegex.exec(pair);
  if (!match) {
    throw new TypeError(`Invalid instrument pair: ${pair}`);
  }

  const quote = instrumentInfo(match[1]);
  const base = instrumentInfo(match[2]);

  if (!base || !quote) {
    throw new TypeError(`Invalid instrument: ${base ? match[1] : match[2]}`);
  }

  const displayPath = pair.replace('_', '/');

  return {
    path: pair,
    displayPath,
    base,
    quote,
  };
}

export function makePair(quote: IInstrument, base: IInstrument): IPair {
  return `${quote}_${base}` as IPair;
}

export const instrumentInfosForType = createSelector(
  (type: IInstrumentType) => type,
  (type) =>
    createSelector(
      (state: IState) => Object.values(state.env.instruments.infos),
      (instrumentInfos) => instrumentInfos.filter((ii) => ii.type === type)
    )
);
