import styled from '../../../lib/styled_components';
import $Link from '../../widgets/Link';

export const $MenuGadgetHeader = styled.div<{ rounded?: boolean }>`
  background: ${(p) => p.theme.colors.brandPrimary};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1.5rem;
  justify-content: space-between;
  font-size: 1.2rem;
  height: 45px;
  ${(p) => p.rounded && 'border-radius: 6px 6px 0 0;'}
`;

export const $MenuGadgetHeaderTitle = styled($Link)`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  text-transform: uppercase;
  font-size: ${(p) => p.theme.fontSize.large};
`;

// TODO: Probably get rid of this?
export const $MenuGadgetFooter = styled.div<{ rounded?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3rem;
  padding: 0 1.5rem;
  border-top: 1px solid ${(p) => p.theme.colors.brandPrimaryDark};
  background: ${(p) => p.theme.colors.brandPrimary};
  text-align: right;
  font-size: ${(p) => p.theme.fontSize.large};
  ${(p) => p.rounded && 'border-radius: 0 0 6px 6px;'}
  a {
    margin-left: auto;
    color: ${(p) => p.theme.colors.white} !important;
    cursor: pointer;
  }
`;
