import * as React from 'react';

import styled, { IThemeColors } from '../../lib/styled_components';
import { WarningIcon } from '../../media/svg_icons';
import { $Icon } from './Icon';

type IAlertType = 'warning' | 'info';

interface IAlertProps extends Partial<IThemeColors> {
  type?: IAlertType;
  icon?: string | Function;
  iconSize?: string;
}

const $Alert = styled('div')<IAlertProps>`
  display: inline-flex;
  max-width: 650px;
  align-items: center;
  padding: 5px 10px;
  margin: 15px 0;
  font-weight: bold;
  background: ${(p) =>
    p.icon
      ? p.theme.widgets.alert.backgroundWithIcon
      : p.theme.widgets.alert.backgroundWithoutIcon};
  border: 3px solid;
  border-image: ${(p) => p.theme.widgets.alert[p.type]};

  img,
  svg {
    width: ${(p) => p.iconSize || '23px'};
    height: ${(p) => p.iconSize || '23px'};
    margin-right: 10px;
  }
`;

const Alert: React.SFC<IAlertProps> = (props) => (
  <$Alert {...props}>
    {props.icon && <$Icon src={props.icon} />}
    {props.children}
  </$Alert>
);

const AlertWarningWithIcon: React.SFC<IAlertProps> = (props) =>
  Alert({ type: 'warning', icon: WarningIcon, ...props });
const AlertWarning: React.SFC<IAlertProps> = (props) => Alert({ type: 'warning', ...props });
const AlertInfo: React.SFC<IAlertProps> = (props) => Alert({ type: 'info', ...props });

export { AlertWarningWithIcon, AlertWarning, AlertInfo };
export default Alert;
