import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import {
  getEstimatorData,
  requestManagedBuyOrder,
  updateManagedBuyOrder,
} from '../../../actions/transactions/managed';
import { localizeIntegerString } from '../../../bl/localized_numbers';
import getManagedBuyOrderEstimatedCryptoGains from '../../../lib/price_estimator';
import Quantity from '../../../lib/quantity_';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { promiseGuard } from '../../../lib/util';
import { ArrowIcon } from '../../../media/svg_icons';
import { getConversionRate, getRates } from '../../../selectors/rates';
import { getManagedConfig } from '../../../selectors/transactions';
import {
  IApiEstimatorData,
  IApiManagedBuyOrder,
  IApiManagedBuyOrderDistribution,
  IApiManagedBuyOrderRequestPayload,
  ICurrency,
  IInstrument,
  IMarketInstrument,
} from '../../../types/backend_definitions';
import { II18nextT } from '../../../types/i18n';
import { IManagedFiatCurrency } from '../../../types/managed';
import { ITranslations } from '../../../types/translations';
import { $GreenLightSmallButton } from '../../widgets/buttons';
import $Checkbox from '../../widgets/Checkbox';
import InstrumentIcon from '../../widgets/InstrumentIcon';
import $Link from '../../widgets/Link';
import {
  $AmountInputWrapper,
  $FormBlock,
  $FormLabel,
  $FormTextInfo,
  $FormWrapper,
  $ManagedGreenButton,
} from '../../widgets/Managed';
import Modal from '../../widgets/Modal';
import NumberInput from '../../widgets/NumberInput';
import { SimplexSelect } from '../../widgets/Select';

const $NoticeWrapper = styled.div<{ showOnMobile: boolean }>`
  display: ${(p) => p.showOnMobile && 'none'};
  color: ${(p) => p.theme.colors.baseNeutral};

  a {
    text-decoration: underline;
    color: ${(p) => p.theme.colors.white};
  }

  @media ${(p) => p.theme.device.mobile} {
    display: ${(p) => (p.showOnMobile ? 'unset' : 'none')};
  }
`;

const $SubmitButton = styled($ManagedGreenButton)`
  width: 100%;
  height: 35px !important;
  margin-top: 1rem !important;
`;

const $FormLabelDerived = styled($FormLabel)`
  cursor: default;
`;

const $EstimateLabel = styled($FormLabelDerived)`
  margin-left: 36px;
`;

const $SplitWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const $EstimatedGain = styled.span`
  color: ${(p) => p.theme.colors.buy};
  font-size: ${(p) => p.theme.fontSize.large};
  margin-left: 18px;
`;

const $SplitItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const $SplitItemsSymbol = styled.label`
  cursor: pointer;
  width: 30px;
  text-align: center;
`;

const $PatchedAmountInputWrapper = styled($AmountInputWrapper)`
  padding: 5px 12px !important;
`;

const $DistributionInputWrapper = styled($AmountInputWrapper)`
  width: 110px;
  height: 40px;
  margin: 0 10px;
  padding: 8px 8px !important;

  @media ${(p) => p.theme.device.mobile} {
    width: 85px;
  }
`;

const $ValidationMessage = styled('div')<{ active: boolean }>`
  margin-top: 0.6rem;
  padding-left: 1px;
  min-height: 1.1rem;

  color: ${(p) => (p.active ? p.theme.colors.white : p.theme.colors.grayLightest)};
`;

const $SplitContainer = styled.div`
  display: flex;
`;

const $EstimatedGainsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const $EstimatedGainsBody = styled.div`
  min-width: 150px;
  margin-top: 10px;
`;

const $EstimationRow = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const $ArrowIconWrapper = styled.span`
  svg {
    transform: rotate(45deg);
    path {
      fill: white;
    }
  }
`;

const $SplitFieldPct = styled.span`
  display: none;
  margin-left: 5px;
  @media ${(p) => p.theme.device.biggerThanMobile} {
    display: unset;
  }
`;

const $SplitFieldActionWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 7px;
`;

const $CheckboxWrapper = styled.div`
  margin-top: 15px;
`;

const INSTRUMENT_DECIMALS: { [key in IMarketInstrument]?: number } = {
  BTC: 4,
  ETH: 2,
  SFX: 0,
};

interface IBuyOrderFormProps extends ConnectedProps<typeof connector> {}

interface IBuyOrderFormState {
  orderId: string;
  purchaseTermsAccepted: boolean;
  currency: ICurrency;
  quantity: string;
  distribution: IApiManagedBuyOrderDistribution;
  validationErrors: {
    distribution: Object;
    quantity: boolean;
  };
  submitting: boolean;
  data: IApiEstimatorData;
}

class BuyOrderForm extends React.PureComponent<IBuyOrderFormProps, IBuyOrderFormState> {
  private mounted = false;
  static contextType: any = I18nContext;
  private modalRef: React.RefObject<Modal> = React.createRef();
  private estimateUpdateInterval;

  defaultState: Partial<IBuyOrderFormState> = {
    orderId: null,
    purchaseTermsAccepted: false,
    currency: null,
    quantity: '',
    distribution: {
      BTC: 0,
      ETH: 0,
      SFT: 0,
      SFX: 0,
    },
    validationErrors: {
      distribution: {},
      quantity: false,
    },
    submitting: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...(this.defaultState as IBuyOrderFormState),
    };
  }

  get distributionSum() {
    const { distribution } = this.state;
    return Object.keys(distribution)
      .filter((i) => !!distribution[i])
      .reduce((sum, i) => {
        sum = sum + distribution[i];
        return sum;
      }, 0);
  }

  get isDistributionValid() {
    const { distribution, validationErrors } = this.state;
    const sum = this.distributionSum;
    const hasInvalidField = Object.values(validationErrors.distribution).some((v) => v === true);
    return sum === 100 && !hasInvalidField;
  }

  get isFormReady() {
    const { validationErrors, currency, quantity, purchaseTermsAccepted, submitting } = this.state;

    return (
      purchaseTermsAccepted && this.isDistributionValid && !validationErrors.quantity && !submitting
    );
  }

  get minPaymentAmount(): number {
    const { buy_order_payment_instrument, buy_order_min_payment_quantity } = this.props.config;
    const amount =
      getConversionRate(
        this.props.rates,
        buy_order_payment_instrument as IInstrument,
        this.state.currency
      ) * Number(buy_order_min_payment_quantity);

    return amount && Math.ceil(amount);
  }

  selectCurrency = (currency) => this.setState({ currency }, () => this.validatePaymentAmount());

  onCheckboxCheck = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({
      purchaseTermsAccepted: event.currentTarget.checked,
    });

  validatePaymentAmount(value: string = this.state.quantity) {
    const coercedVal = Number(value);
    const isInvalid = coercedVal < this.minPaymentAmount;

    this.setState((state) => ({
      quantity: value,
      validationErrors: { ...state.validationErrors, quantity: isInvalid },
    }));
  }

  onPaymentAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    this.validatePaymentAmount(event.target.value);
  };

  updateEstimationData = (): Promise<void> => {
    return promiseGuard(this, this.props.getEstimatorData()).then((data) => {
      this.setState({ data } as IBuyOrderFormState);
    });
  };

  componentDidMount() {
    this.mounted = true;
    return this.updateEstimationData().then(() => {
      this.estimateUpdateInterval = setInterval(() => this.updateEstimationData(), 60 * 1000);
    });
  }

  componentDidUpdate(
    prevProps: Readonly<IBuyOrderFormProps>,
    prevState: Readonly<IBuyOrderFormState>,
    snapshot?: any
  ) {
    const { config } = this.props;
    if (!this.state.currency && config.supported_fiat) {
      this.setState({ currency: config.supported_fiat[0] as ICurrency }, () => {
        this.setState({ quantity: String(this.minPaymentAmount) });
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.estimateUpdateInterval) {
      clearInterval(this.estimateUpdateInterval);
    }
  }

  showForInstrument(instrument: IMarketInstrument = null) {
    const { config } = this.props;
    const currency = config.supported_fiat[0];

    const distribution = {};

    const state: Partial<IBuyOrderFormState> = { currency: currency as ICurrency };
    if (instrument) {
      state.distribution = { ...this.defaultState.distribution, [instrument]: 100 };
    }

    this.setState({ ...this.defaultState, ...state } as IBuyOrderFormState, () => {
      this.setState({ quantity: String(this.minPaymentAmount) }, () => {
        this.modalRef.current.show();
      });
    });
  }

  showForOrder(order: IApiManagedBuyOrder) {
    this.setState(
      {
        ...this.defaultState,
        orderId: order.id,
        quantity: order.quantity,
        currency: order.currency,
        distribution: order.distribution,
      } as IBuyOrderFormState,
      () => this.modalRef.current.show()
    );
  }

  clearStateKeepEstimatorData = () =>
    this.setState({ ...(this.defaultState as IBuyOrderFormState) });

  onDistributionInput = (instrument) => (event) => {
    const { distribution } = this.state;
    const value = Number(event.target.value);
    const isValueInvalid = value > 100;
    const sum =
      Object.keys(distribution)
        .filter((i) => i !== instrument && !!distribution[i])
        .reduce((sum, i) => {
          sum = sum + distribution[i];
          return sum;
        }, value) || value;

    const statePayload: Partial<IBuyOrderFormState> = {
      distribution: { ...this.state.distribution, [instrument]: value },
    };

    if (isValueInvalid) {
      return;
    } else if (sum !== 100) {
      statePayload.validationErrors = {
        ...this.state.validationErrors,
        distribution: { BTC: true, ETH: true, SFX: true, SFT: true },
      };
    } else {
      statePayload.validationErrors = {
        ...this.state.validationErrors,
        distribution: this.defaultState.distribution,
      };
    }

    this.setState(statePayload as IBuyOrderFormState);
  };

  get estimatedGains() {
    const { distribution, quantity, currency } = this.state;
    const { config, rates } = this.props;
    const feePct = config.buy_order_fee_pct;

    const coercedQuantity = Number(quantity);
    const feeQtyFromPct = coercedQuantity * (config.buy_order_fee_pct / 100);
    const rate = getConversionRate(
      rates,
      currency,
      config.buy_order_payment_instrument as IInstrument
    );

    const feeQtyFromFixed = rate && Quantity(config.buy_order_fee_fixed).divide(rate);
    const fee =
      feeQtyFromFixed && feeQtyFromFixed.gt(feeQtyFromPct)
        ? feeQtyFromFixed.toNumber()
        : feeQtyFromPct;
    const qtyAfterDeductedFee = coercedQuantity - fee;
    return Object.keys(distribution).reduce((res, i) => {
      const pct = distribution[i];
      if (!pct) {
        return res;
      }

      const dedicatedQty = qtyAfterDeductedFee * (pct / 100);
      const estimatedRevenue = getManagedBuyOrderEstimatedCryptoGains(
        this.state.data,
        rates,
        currency as IManagedFiatCurrency,
        dedicatedQty,
        i as IMarketInstrument
      );

      res[i] = estimatedRevenue
        ? Quantity(estimatedRevenue).truncateForInstrument(i).toNumber()
        : null;
      return res;
    }, {});
  }

  renderOrderSplit() {
    const { t }: II18nextT = this.context;
    return (
      <$SplitWrapper>
        {this.props.config.supported_crypto.map((symbol) => {
          const estimatedGain = this.estimatedGains[symbol];
          const pct = this.state.distribution[symbol];
          const leftToFill = !this.isDistributionValid && 100 - this.distributionSum;
          const fill = () =>
            this.setState({
              distribution: { ...this.state.distribution, [symbol]: pct + leftToFill },
              validationErrors: this.defaultState.validationErrors,
            } as IBuyOrderFormState);

          return (
            <$SplitItem key={symbol}>
              <InstrumentIcon name={symbol} size={15} />
              <$SplitItemsSymbol htmlFor={symbol}>{symbol}</$SplitItemsSymbol>
              <$DistributionInputWrapper
                validationError={this.state.validationErrors.distribution[symbol]}
              >
                <NumberInput
                  id={symbol}
                  border="none"
                  type="number"
                  min="0"
                  name={symbol}
                  value={pct}
                  onChange={this.onDistributionInput(symbol)}
                />
                <$SplitFieldActionWrapper>
                  <$GreenLightSmallButton
                    disabled={this.isDistributionValid || this.distributionSum > 100}
                    onClick={fill}
                  >
                    {t('managed.forms.fill')} {leftToFill > 0 && leftToFill}
                  </$GreenLightSmallButton>
                  <$SplitFieldPct>%</$SplitFieldPct>
                </$SplitFieldActionWrapper>
              </$DistributionInputWrapper>
            </$SplitItem>
          );
        })}
      </$SplitWrapper>
    );
  }

  renderEstimatedGains() {
    const { t }: II18nextT = this.context;
    return (
      <$EstimatedGainsBody>
        {this.props.config.supported_crypto.map((symbol) => {
          const estimatedGain = this.estimatedGains[symbol];
          const pct = this.state.distribution[symbol];
          return (
            <$EstimationRow key={symbol}>
              <span style={{ width: '18px' }}>
                {estimatedGain && (
                  <$ArrowIconWrapper>
                    <ArrowIcon />
                  </$ArrowIconWrapper>
                )}
              </span>
              <$EstimatedGain>
                {estimatedGain ? (
                  // TODO: rethink how to solve insignificant numbers
                  estimatedGain.toFixed(INSTRUMENT_DECIMALS[symbol])
                ) : pct && pct > 0 ? (
                  <span title={t('managed.notAvailable')}>
                    {t('managed.notAvailableAbbreviated')}
                  </span>
                ) : null}
              </$EstimatedGain>
            </$EstimationRow>
          );
        })}
      </$EstimatedGainsBody>
    );
  }

  submitOrder = () => {
    if (!this.isFormReady) {
      return null;
    }

    const { orderId, currency, quantity, distribution } = this.state;
    const payload: IApiManagedBuyOrderRequestPayload = {
      currency,
      quantity,
      distribution,
      estimated_gains: this.estimatedGains,
    };

    this.setState({ submitting: true }, () => {
      if (orderId) {
        return promiseGuard(this, this.props.updateManagedBuyOrder(orderId, payload)).then(() => {
          this.clearStateKeepEstimatorData();
          this.modalRef.current.hide();
        });
      }

      return promiseGuard(this, this.props.requestManagedBuyOrder(payload)).then(() => {
        this.clearStateKeepEstimatorData();
        this.modalRef.current.hide();
      });
    });
  };

  get currencyOptions() {
    return this.props.config.supported_fiat.reduce((res, instrument) => {
      res[instrument] = { value: instrument, label: instrument };
      return res;
    }, {});
  }

  get isOpen() {
    return this.modalRef.current.isOpen;
  }

  renderNotice(showOnMobile = false) {
    const { t }: II18nextT = this.context;
    return (
      <$NoticeWrapper showOnMobile={showOnMobile}>
        {t('managed.forms.buyEstimatedTextInfo')}{' '}
        <$Link href="" target="_blank">
          {t('managed.forms.estimatedTextClickHere')}
        </$Link>
        <$CheckboxWrapper>
          <$Checkbox
            uppercase={true}
            variant="dark"
            name="purchaseTermsAccepted"
            checked={this.state.purchaseTermsAccepted}
            onChange={this.onCheckboxCheck}
          >
            {t('managed.forms.iAcceptPurchaseTerms')}
          </$Checkbox>
        </$CheckboxWrapper>
      </$NoticeWrapper>
    );
  }

  render() {
    const { t }: II18nextT = this.context;

    return (
      <>
        <Modal
          ref={this.modalRef}
          title={t(
            this.state.orderId
              ? 'managed.forms.editBuyOrder'
              : ('managed.forms.newBuyOrder' as ITranslations)
          )}
          titleUppercase={true}
          onClose={this.clearStateKeepEstimatorData}
        >
          <$FormWrapper>
            <$FormTextInfo>{t('managed.forms.buyOrderTopInfo')}</$FormTextInfo>
            <$FormBlock>
              <$FormLabel htmlFor="paymentAmount">{t('managed.forms.paymentAmount')}</$FormLabel>
              <$PatchedAmountInputWrapper validationError={this.state.validationErrors.quantity}>
                <NumberInput
                  id="paymentAmount"
                  border="none"
                  min="0"
                  name="paymentAmount"
                  value={this.state.quantity}
                  onChange={this.onPaymentAmountChange}
                />
                <SimplexSelect
                  variant="transparent"
                  options={Object.values(this.currencyOptions)}
                  value={this.currencyOptions[this.state.currency]}
                  onChange={({ value }) => {
                    this.selectCurrency(value);
                  }}
                  for="currency"
                />
              </$PatchedAmountInputWrapper>
              <$ValidationMessage active={this.state.validationErrors.quantity}>
                {t('managed.forms.minimumPaymentAmount', {
                  amount: localizeIntegerString(this.minPaymentAmount),
                  instrument: this.state.currency,
                })}
              </$ValidationMessage>
            </$FormBlock>
            <$FormBlock>
              <$SplitContainer>
                <div>
                  <$FormLabelDerived htmlFor="split">{t('managed.order.split')}</$FormLabelDerived>
                  {this.renderOrderSplit()}
                </div>
                <$EstimatedGainsWrapper>
                  <$EstimateLabel>{t('managed.order.estimate')}</$EstimateLabel>
                  {this.renderEstimatedGains()}
                  {this.renderNotice()}
                </$EstimatedGainsWrapper>
              </$SplitContainer>
            </$FormBlock>
            <div>
              {this.renderNotice(true)}
              <$SubmitButton disabled={!this.isFormReady} onClick={this.submitOrder}>
                {t('button.submit')}
              </$SubmitButton>
            </div>
          </$FormWrapper>
        </Modal>
      </>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    config: getManagedConfig(state),
    rates: getRates(state),
  }),
  {
    updateManagedBuyOrder,
    requestManagedBuyOrder,
    getEstimatorData,
  },
  null,
  { forwardRef: true }
);

export default connector(BuyOrderForm);
