import { Store } from 'redux';

import lodash from './lodash';
import { SentryIntegration } from './sentry_integration';
import { IReduxAction } from './store';

type LoggerFunction = (message?: any, ...optionalParams: any[]) => void;

interface INativeLogger {
  debug: LoggerFunction;
  log: LoggerFunction;
  error: LoggerFunction;
  groupCollapsed: LoggerFunction;
  groupEnd: () => void;
}

export type LoggerLevel = 'verbose' | 'info' | 'warn' | 'error' | 'silent';
export interface ILoggerLevelByPrefix {
  [key: string]: LoggerLevel;
}

export const LEVEL_VALUES: { [key in LoggerLevel]: number } = {
  silent: 0,
  error: 1,
  warn: 2,
  info: 3,
  verbose: 4,
};

const MIDDLEWARE_LEVEL: LoggerLevel = 'info';

export enum LoggerColor {
  black = 5362620, // Arbitrary value that is not likely to show up in args
  gray,
  pale,
  blue,
  purple,
  green,
  orange,
}

const LOGGER_COLOR_VALUES = {
  [LoggerColor.black]: '#000000',
  [LoggerColor.gray]: '#727272',
  [LoggerColor.pale]: '#86a4b3',
  [LoggerColor.blue]: '#5c6ac8',
  [LoggerColor.purple]: '#8b69a6',
  [LoggerColor.green]: '#4e9c4e',
  [LoggerColor.orange]: '#fb8e00',
};

const LOGGER_COLOR_BOLDS = {
  [LoggerColor.orange]: true,
};

const colorToColorTag = (arg: LoggerColor | any) => {
  const result = [];
  if (arg in LoggerColor) {
    result.push('color: ' + LOGGER_COLOR_VALUES[arg]);
  }
  if (arg in LOGGER_COLOR_BOLDS) {
    result.push('font-weight: bold');
  }
  return result.join('; ');
};

class SeenErrorTracker {
  private memory = new WeakSet();

  public track(...errs) {
    for (const err of errs) {
      if (err && typeof err === 'object') {
        this.memory.add(err);
      }
    }
  }

  public seen(...errs) {
    for (const err of errs) {
      if (err && typeof err === 'object' && this.memory.has(err)) {
        return true;
      }
    }
    return false;
  }
}

export class Logger {
  static seenErrors = new SeenErrorTracker();

  constructor(
    public level: LoggerLevel = 'info',
    public levelByPrefix: ILoggerLevelByPrefix = {},
    public prefix = '',
    private sentryIntegration: SentryIntegration,
    private native: INativeLogger
  ) {}

  prefixed(prefix): Logger {
    if (!lodash.isString(prefix)) {
      if (prefix && prefix.props && prefix.refs) {
        // Sniffed out a react component
        prefix = `<${prefix.constructor.displayName}>`;
      } else {
        prefix = prefix.constructor
          ? prefix.constructor.displayName || prefix.constructor.name
          : prefix.name;
      }
    }

    const childPrefix = prefix
      ? this.prefix
        ? `${this.prefix} > ${prefix}`
        : prefix
      : this.prefix;

    const childLevel = this.levelByPrefix[prefix] || this.level;

    return new Logger(
      childLevel,
      this.levelByPrefix,
      childPrefix,
      this.sentryIntegration,
      this.native
    );
  }

  private shouldLog = (messageLevel: LoggerLevel) => {
    return LEVEL_VALUES[this.level] >= LEVEL_VALUES[messageLevel];
  };

  private doLog(
    method: LoggerFunction,
    message: string,
    args: any[],
    messageColor: LoggerColor = LoggerColor.black
  ) {
    let msgPrefix = '%c' + timestampTag();
    const addedColors = [];
    addedColors.push(colorToColorTag(LoggerColor.gray));

    if (this.prefix) {
      msgPrefix += '%c[' + this.prefix + ']';
      addedColors.push(colorToColorTag(LoggerColor.pale));
    }

    message = msgPrefix + ' %c' + message;
    addedColors.push(colorToColorTag(messageColor));

    method.call(this.native, message, ...addedColors, ...args);
  }

  get shouldVerbose() {
    return this.shouldLog('verbose');
  }
  verbose(message: string, ...args: any[]) {
    if (this.shouldVerbose) {
      this.doLog(this.native.debug, message, args);
    }
  }

  get shouldInfo() {
    return this.shouldLog('info');
  }
  info(message: string, ...args: any[]) {
    if (this.shouldInfo) {
      this.doLog(this.native.log, message, args);
    }
  }

  get shouldWarn() {
    return this.shouldLog('warn');
  }
  warn(message: string, ...args: any[]) {
    if (this.shouldWarn) {
      this.doLog(this.native.log, 'WARNING: ' + message, args, LoggerColor.orange);
    }
  }

  get shouldError() {
    return this.shouldLog('error');
  }
  error(err: string | Error | any, additionalData?: Error | any) {
    if (Logger.seenErrors.seen(err, additionalData)) {
      // Do not log the same error again
      return;
    }
    // Remember we've seen this error
    Logger.seenErrors.track(err, additionalData);

    // Always send error to sentry
    this.sentryIntegration.report(this.prefix, err, additionalData);

    if (!this.shouldError) {
      return;
    }

    const baseMessage = (err && err.message) || (err && String(err)) || 'Error';
    let errorMessage = timestampTag();
    if (this.prefix) {
      errorMessage += ' [' + this.prefix + ']';
    }
    errorMessage += ' ' + baseMessage;

    this.native.error(errorMessage, additionalData);
  }

  middleware() {
    return (store: Store) => (next: Function) => (action: IReduxAction) => {
      if (!this.shouldLog(MIDDLEWARE_LEVEL)) {
        return next(action);
      }

      const prevState = store.getState();

      const returnValue = next(action);

      const nextState = store.getState();
      const actionType = String(action.type);

      this.grouped(
        `%c${timestampTag()}%c[REDUX] %c${actionType}`,
        [LoggerColor.gray, LoggerColor.pale, LoggerColor.blue],
        () => {
          this.native.log(`%c Prev state`, colorToColorTag(LoggerColor.purple), prevState);
          this.native.log(`%c Action`, colorToColorTag(LoggerColor.blue), action);
          this.native.log(`%c Next state`, colorToColorTag(LoggerColor.green), nextState);
        }
      );

      return returnValue;
    };
  }

  grouped(
    groupTitle: string[] | string,
    colors: LoggerColor[],
    insideGroup: (logger: Logger) => void
  ) {
    this.native.groupCollapsed(groupTitle, ...(colors || []).map(colorToColorTag));
    insideGroup(this);
    this.native.groupEnd();
  }
}

function timestampTag() {
  const time = new Date();
  const hr = lodash.pad(time.getHours().toString(), 2);
  const min = lodash.pad(time.getMinutes().toString(), 2);
  const sec = lodash.pad(time.getSeconds().toString(), 2);
  const ms = lodash.pad(time.getMilliseconds().toString(), 3);
  return `[${hr}:${min}:${sec}.${ms}]`;
}
