import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import _ from './../../../lib/lodash';
import { setBuySellValues } from '../../../actions/buy_sell';
import { IWindowSize } from '../../../actions/document';
import { IPriceSummary24hPair } from '../../../actions/exchange';
import R from '../../../lib/routes';
import { IState } from '../../../lib/store';
import styled, { IThemeColorsUnion } from '../../../lib/styled_components';
import { eventMuter, formatNumberToFixed, formatPercentage, hex2rgba } from '../../../lib/util';
import {
  calculateHorizontalCandleRect,
  getChangePercentForPriceSummaryPair,
  getCurrentPair,
  getDirectionColor,
  getPriceSummary24hForCurrentPair,
  getPriceSummary24hSortedByVolumeList,
} from '../../../selectors/exchange';
import { II18nextT } from '../../../types/i18n';
import { PRICE_DIGITS, pairInfo } from '../../../types/instruments';
import Arrow from '../../widgets/Arrow';
import { DropdownMenu, IDropdownMenuItem } from '../../widgets/DropdownMenu';
import InstrumentIcon from '../../widgets/InstrumentIcon';
import PrettyDecimals from '../../widgets/PrettyDecimals';

const CANDLE_MARGIN = '0.4rem';

const shouldAllowSwitchingInstrument = (size: IWindowSize) => size === 'mobile';

const $ActiveInstrumentMobile = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
`;

const $TopRow = styled.div<{ clickable: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  cursor: ${(p) => (p.clickable ? 'pointer' : 'default')};
`;

const $InstrumentInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;

  > :first-child {
  }

  > :last-child {
    flex-grow: 1;
    margin-left: 0.5em;
  }

  h3 {
    font-size: ${(p) => p.theme.fontSize.larger} !important;
    text-transform: uppercase;
    margin: 0 0 0 0 !important;
  }

  h4 {
    font-size: ${(p) => p.theme.fontSize.large} !important;
    font-weight: normal;
    margin: 0 !important;
  }

  @media only screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    h3,
    h4 {
      font-size: ${(p) =>
        p.theme.components.activeInstrumentHorizontal.components.instrumentInfo.iphonePlus
          .fontSize} !important;
    }
  }
`;

const $PairLink = styled.a<{ showMenu: boolean }>`
  color: ${(p) => p.theme.colors.white};
  text-decoration: none;
  transition: all 0.2s ease-in;

  ${Arrow} {
    display: inline-block;
    margin-left: 0.4rem;
    transform: rotate(0);
    transition: transform 0.2s;
    vertical-align: middle;
  }

  :active {
    background: none !important;
  }

  ${(p) =>
    p.showMenu &&
    `${Arrow} {
      transform: translateX(1px) rotate(90deg);
    }    
  `};
`;

const $VolumeInfo = styled.div`
  text-align: right;
`;

const $Label = styled.h5`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.grayLighter};
  font-size: ${(p) => p.theme.fontSize.smaller};

  > strong {
    color: ${(p) => p.theme.colors.grayLightest};
  }
`;
const $Value = styled.h4`
  margin-top: 0 !important;
  margin-bottom: 0.2rem !important;
  text-transform: uppercase;
  font-size: ${(p) => p.theme.fontSize.larger} !important;

  a {
    text-decoration: none;
  }
  a:hover {
    background-color: ${(p) => hex2rgba(p.theme.colors.brandPrimary, 0.5)};
  }
  a:active {
    background-color: ${(p) => p.theme.colors.brandPrimary};
  }

  @media only screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    font-size: ${(p) =>
      p.theme.components.activeInstrumentHorizontal.components.priceInfo.iphonePlus
        .fontSize} !important;
  }
`;

const $TotalValue = styled($Value)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and ${(p) => p.theme.device.mobile} {
    width: 20rem;
    padding-top: 0;
  }

  @media screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    width: 13rem;
  }
`;
const $PriceInfoValue = styled($Value)`
  padding-top: 5px;
`;

const $Percentage = styled.h5<{ color: IThemeColorsUnion }>`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-size: ${(p) => p.theme.fontSize.small} !important;
  color: ${(p) => p.theme.colors[p.color]};
`;

const $Candle = styled.div`
  position: relative;
`;
const $CandleOpenClose = styled.div<{ color: IThemeColorsUnion }>`
  background: ${(p) => p.theme.colors[p.color]};
  position: relative;
  width: 100%;
  height: 1px;
  top: 4px;
`;
const $CandleHighLow = styled.div<{ color: IThemeColorsUnion; left: string; width: string }>`
  background: ${(p) => p.theme.colors[p.color]};
  position: relative;
  height: 7px;
  left: ${(p) => p.left};
  width: ${(p) => p.width};
`;

const $CandleGuide = styled.div<{ color: IThemeColorsUnion }>`
  width: 1px;
  background: ${(p) => p.theme.colors[p.color]};
  align-self: start;
  position: absolute;
  height: 100%;
`;

const $PriceInfo = styled.div<{ maxWidth?: number }>`
  flex-grow: 2;
  padding: 0;
  margin-top: 0;

  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: repeat(3, auto);
  position: relative;

  // Percentage
  > :nth-child(1) {
    grid-column: 2;
    grid-row: 1;
    text-align: center;
  }

  // Value, low
  > :nth-child(2) {
    max-width: ${(p) => p.maxWidth + 'px'};
    min-width: 90px;
    grid-column: 1;
    grid-row: 2;
    text-align: right;
    margin-right: ${CANDLE_MARGIN};
    align-self: start;
  }

  // Value, current
  > :nth-child(3) {
    min-width: 90px;
    grid-column: 2;
    grid-row: 2;
    text-align: center;
    align-self: start;
    margin-left: ${CANDLE_MARGIN};
    margin-right: ${CANDLE_MARGIN};
  }

  // Value, high
  > :nth-child(4) {
    max-width: ${(p) => p.maxWidth + 'px'};
    min-width: 90px;
    grid-column: 3;
    grid-row: 2;
    margin-left: ${CANDLE_MARGIN};
    align-self: start;
  }

  // Label, low
  > :nth-child(5) {
    grid-column: 1;
    grid-row: 3;
    margin-right: ${CANDLE_MARGIN};
    align-self: center;
    text-align: right;
  }

  // Left candle guide
  > :nth-child(6) {
    grid-column: 2;
    grid-row: 2 / 4;
  }

  // Candle
  > :nth-child(7) {
    grid-column: 2;
    grid-row: 3;
  }

  // Right candle guide
  > :nth-child(8) {
    grid-column: 3;
    grid-row: 2 / 4;
    margin-left: -1px;
  }

  // Label, high
  > :nth-child(9) {
    grid-column: 3;
    grid-row: 3;
    margin-left: ${CANDLE_MARGIN};
    align-self: center;
  }
`;

const $MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 1rem;
  width: 100%;

  :hover {
    background-color: ${(p) => hex2rgba(p.theme.colors.brandPrimary, 0.5)};
  }
`;

const $MenuPriceInfo = styled.div`
  text-align: right;
`;

interface IActiveInstrumentHorizontalProps extends ConnectedProps<typeof connector> {}

interface IComponentState {
  showMenu: boolean;
  priceInfoWidth: number;
}

class ActiveInstrumentHorizontal extends React.PureComponent<
  IActiveInstrumentHorizontalProps,
  IComponentState
> {
  static contextType: any = I18nContext;
  priceInfoRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      priceInfoWidth: null,
      showMenu: false,
    };
  }

  getPriceInfoWidthOnResize = _.throttle(() => {
    this.setState({
      priceInfoWidth: this.priceInfoRef.current && this.priceInfoRef.current.clientWidth,
    });
  }, 300);

  componentDidMount() {
    this.setState({
      priceInfoWidth: this.priceInfoRef.current && this.priceInfoRef.current.clientWidth,
    });
    window.addEventListener('resize', this.getPriceInfoWidthOnResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getPriceInfoWidthOnResize);
  }

  componentDidUpdate(
    prevProps: Readonly<IActiveInstrumentHorizontalProps>,
    prevState: Readonly<IComponentState>,
    snapshot?: any
  ): void {
    if ((this.props.pair && this.props.pair.path) !== (prevProps.pair && prevProps.pair.path)) {
      this.setState({
        showMenu: false,
      });
    }
  }

  toggleMobileMenu = () => {
    if (shouldAllowSwitchingInstrument(this.props.windowSize)) {
      this.setState((prevState) => ({ showMenu: !prevState.showMenu }));
    }
  };

  setPrice = (price) => {
    this.props.setBuySellValues({ buy: { price }, sell: { price } });
  };

  renderMenuPriceInfo = (summary24h: IPriceSummary24hPair) => {
    const directionColor: IThemeColorsUnion =
      summary24h.close > summary24h.open
        ? 'buy'
        : summary24h.close < summary24h.open
        ? 'sell'
        : 'white';

    return (
      <$MenuPriceInfo>
        <$Value>
          <PrettyDecimals
            value={formatNumberToFixed(summary24h.close, PRICE_DIGITS)}
            color={directionColor}
            wordBreak
          />
        </$Value>
        <$Percentage color={directionColor}>
          {formatPercentage(getChangePercentForPriceSummaryPair(summary24h), 2)}
        </$Percentage>
      </$MenuPriceInfo>
    );
  };

  renderMenuItem = (summary24h: IPriceSummary24hPair) => {
    const pair = pairInfo(summary24h.pair);

    return (
      <$MenuItem>
        <$InstrumentInfo>
          <InstrumentIcon name={pair.quote.symbol} size={30} type="color" />
          <div>
            <h3>{pair.quote.name}</h3>
            <h4>{pair.displayPath}</h4>
          </div>
        </$InstrumentInfo>
        {this.renderMenuPriceInfo(summary24h)}
      </$MenuItem>
    );
  };

  renderDropDownMenu = () => {
    if (!shouldAllowSwitchingInstrument(this.props.windowSize)) {
      return null;
    }

    const { pair, allSummaries } = this.props;
    const menuItems: IDropdownMenuItem[] = allSummaries
      .filter((summary) => summary.pair !== pair.path)
      .map((summary) => {
        const notThisPair = pairInfo(summary.pair);
        return {
          label: notThisPair.displayPath,
          route: R.EXCHANGE.to({ pair: notThisPair.path }),
          render: () => this.renderMenuItem(summary),
        } as IDropdownMenuItem;
      });

    return (
      <div>
        <DropdownMenu
          toggleMenu={this.toggleMobileMenu}
          isOpen={this.state.showMenu}
          selectedRoute={R.EXCHANGE.to({ pair: pair.path })}
          sections={[
            {
              items: menuItems,
            },
          ]}
          style={{ wrapper: { paddingTop: '0.8rem' }, item: { padding: 0 } }}
        />
      </div>
    );
  };

  render() {
    const { summary24h, pair, windowSize } = this.props;
    const { t }: II18nextT = this.context;

    if (!summary24h || !pair) {
      // Not ready yet
      return null;
    }

    const directionColor = getDirectionColor(summary24h);
    const candleRect = calculateHorizontalCandleRect(summary24h);
    const allowSwitchingInstrument = shouldAllowSwitchingInstrument(windowSize);

    return (
      <$ActiveInstrumentMobile>
        <$TopRow
          onClick={eventMuter(this.toggleMobileMenu)}
          clickable={allowSwitchingInstrument && !this.state.showMenu}
        >
          <$InstrumentInfo>
            <InstrumentIcon name={pair.quote.symbol} size={30} type="color" />
            <div>
              <h3>{pair.quote.name}</h3>
              {allowSwitchingInstrument ? (
                <$PairLink
                  href="#"
                  onClick={eventMuter(this.toggleMobileMenu)}
                  showMenu={this.state.showMenu}
                >
                  <h4>
                    {pair.displayPath}
                    <Arrow direction="right" size="5px" color="white" />
                  </h4>
                </$PairLink>
              ) : (
                <h4>{pair.displayPath}</h4>
              )}
            </div>
          </$InstrumentInfo>
          {this.state.showMenu ? (
            this.renderMenuPriceInfo(summary24h)
          ) : (
            <$VolumeInfo>
              <$TotalValue>
                <PrettyDecimals
                  value={formatNumberToFixed(summary24h.volume, PRICE_DIGITS)}
                  wordBreak
                />
              </$TotalValue>
              <$Label>
                {t('activeInstrument.volume24h') + ' '}(<strong>{pair.base.symbol}</strong>)
              </$Label>
            </$VolumeInfo>
          )}
        </$TopRow>

        {this.renderDropDownMenu()}

        <$PriceInfo
          ref={this.priceInfoRef}
          maxWidth={this.state.priceInfoWidth && this.state.priceInfoWidth / 3}
        >
          <$Percentage color={directionColor}>
            {formatPercentage(getChangePercentForPriceSummaryPair(summary24h), 2)}
          </$Percentage>

          <$PriceInfoValue>
            <a href="#" onClick={eventMuter(() => this.setPrice(summary24h.low))}>
              <PrettyDecimals value={formatNumberToFixed(summary24h.low, PRICE_DIGITS)} wordBreak />
            </a>
          </$PriceInfoValue>
          <$PriceInfoValue>
            <a href="#" onClick={eventMuter(() => this.setPrice(summary24h.close))}>
              <PrettyDecimals
                value={formatNumberToFixed(summary24h.close, PRICE_DIGITS)}
                color={directionColor}
                wordBreak
              />
            </a>
          </$PriceInfoValue>
          <$PriceInfoValue>
            <a href="#" onClick={eventMuter(() => this.setPrice(summary24h.high))}>
              <PrettyDecimals
                value={formatNumberToFixed(summary24h.high, PRICE_DIGITS)}
                wordBreak
              />
            </a>
          </$PriceInfoValue>

          <$Label>{t('activeInstrument.low24h')}</$Label>
          <$CandleGuide color={directionColor} />
          <$Candle>
            <$CandleOpenClose color={directionColor} />
            <$CandleHighLow color={directionColor} {...candleRect} />
          </$Candle>
          <$CandleGuide color={directionColor} />
          <$Label>{t('activeInstrument.high24h')}</$Label>
        </$PriceInfo>
      </$ActiveInstrumentMobile>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    pair: getCurrentPair(state),
    summary24h: getPriceSummary24hForCurrentPair(state),
    allSummaries: getPriceSummary24hSortedByVolumeList(state),
    windowSize: state.document.windowSize,
  }),
  {
    setBuySellValues,
  }
);

export default connector(ActiveInstrumentHorizontal);
