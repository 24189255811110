import * as React from 'react';

import { IActiveRequestsKeys } from '../../../actions/request_active';
import { ITranslatedError } from '../../../lib/errors';
import { I18n } from '../../../lib/i18n';
import styled from '../../../lib/styled_components';
import { $Button } from '../../widgets/buttons';
import $Checkbox from '../../widgets/Checkbox';
import $ColorText from '../../widgets/ColorText';
import LoadingAnimation from '../../widgets/LoadingAnimation';

export const $SettingsWrapper = styled.div`
  padding: 10px 20px;
  @media screen and ${(p) => p.theme.device.mobile} {
    padding: 10px;
  }
`;

export const $Section = styled.section`
  max-width: 650px;
  margin-bottom: 1em;
  animation: fadein 0.7s;

  &:last-child {
    margin-bottom: 0;
  }
`;
const $SectionHeaderTextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  @media screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    flex-direction: column;
    > h3 {
      width: 100% !important;
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
  }
`;
export const $SectionHeader = styled('div')<{ icon: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 28px 12px 75px;
  background-color: ${(p) => p.theme.components.settingsSections.heading.background};
  color: ${(p) => p.theme.components.settingsSections.heading.color};
  background-image: url(${(p) => p.icon});
  background-repeat: no-repeat;
  background-position: 20px 50%;

  @media screen and ${(p) => p.theme.device.mobile} {
    padding-left: 70px;
    padding-right: 15px;
  }
  h3 {
    width: 40%;
    font-size: ${(p) => p.theme.fontSize.large};
    label {
      cursor: pointer;
      user-select: none;
    }
  }
`;
export const $SectionSectionsContent = styled.div`
  background: ${(p) => p.theme.components.settingsSections.content.background};
  color: ${(p) => p.theme.components.settingsSections.content.color};
  padding: 12px 15px;

  &:empty {
    padding: 0;
  }

  @media screen and ${(p) => p.theme.device.mobile} {
    padding: 5px;
  }

  animation: fadein 0.5s ease;
`;
export const $SectionSectionsValidationMessage = styled.div`
  background: ${(p) => p.theme.components.settingsSections.content.validationMessageBackground};
  color: ${(p) => p.theme.components.settingsSections.content.validationMessageColor};
  padding: 4px 6px;
  font-weight: bold;
  text-align: center;
  font-size: ${(p) => p.theme.fontSize.large};

  animation: fadein 0.5s ease;
`;
export const $SectionDescription = styled.div`
  padding: 1em 1em 2em 1em;
`;
const $LoadingAnimationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const $SectionButton = styled($Button).attrs<{ cancel?: boolean }>((p) => ({
  backgroundColor: p.cancel ? p.theme.colors.brandPrimaryLighter : p.theme.colors.buy,
  color: p.theme.colors.white,
}))<{ cancel?: boolean }>`
  justify-self: flex-end;
  width: auto;
  margin-left: 5%;
`;

const $ValidationSummary = styled.div`
  padding: 4px 6px;
  font-size: ${(p) => p.theme.fontSize.large};
  background: ${(p) => p.theme.components.settingsSections.content.validationMessageBackground};
  color: ${(p) => p.theme.components.settingsSections.content.validationMessageColor};
  font-weight: bold;
  text-align: center;
  animation: fadein 0.5s ease;
`;

const $DismissableErrorBox = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  font-weight: bold;
  background: ${(p) => p.theme.widgets.alert.backgroundWithoutIcon};
  border: 3px solid;
  border-image: ${(p) => p.theme.widgets.alert.warning};
`;

interface ISettingsSectionProps {
  label: string;
  description: any;
  icon: string;
  rightContent?: any;
  children?: any;
  requests?: IActiveRequestsKeys[];
}

interface ISettingsSectionGenericProps extends ISettingsSectionProps {
  rightContent?: any;
  validationError?: ITranslatedError;
}
export const SettingsSectionGeneric: React.FunctionComponent<ISettingsSectionGenericProps> = ({
  label,
  description,
  icon,
  validationError,
  rightContent = null,
  children = null,
  requests = null,
}) => {
  return (
    <$Section>
      <$SectionHeader icon={icon}>
        <h3>{label}</h3>
        {requests ? (
          <$LoadingAnimationWrapper>
            <LoadingAnimation requests={requests}>{rightContent}</LoadingAnimation>
          </$LoadingAnimationWrapper>
        ) : (
          rightContent
        )}
      </$SectionHeader>

      {/* Red validation header */}
      {validationError && <$ValidationSummary>{validationError.summaryMessage}</$ValidationSummary>}

      <$SectionSectionsContent>{children}</$SectionSectionsContent>

      {description && <$SectionDescription>{description}</$SectionDescription>}
    </$Section>
  );
};

interface ISettingsSectionCollapsibleProps extends ISettingsSectionProps {
  changeInitiated: boolean;
  value: string;
  onChangeClick: any;
  onCancelClick: any;
  onSaveClick: any;
  validationError: ITranslatedError;
  dismissableError?: any;
}
export const SettingsSectionCollapsible: React.FunctionComponent<ISettingsSectionCollapsibleProps> = ({
  label,
  description,
  icon,
  changeInitiated,
  value,
  children,
  onChangeClick,
  onCancelClick,
  onSaveClick,
  validationError,
  requests = null,
  dismissableError,
}) => {
  const rightButtons =
    onChangeClick && onSaveClick && changeInitiated ? (
      <I18n>
        {(t) => (
          <>
            <$SectionButton onClick={onSaveClick}>{t('button.save')}</$SectionButton>
            <$SectionButton onClick={onCancelClick} cancel>
              {t('button.cancel')}
            </$SectionButton>
          </>
        )}
      </I18n>
    ) : onChangeClick && changeInitiated ? (
      <I18n>
        {(t) => (
          <>
            <$SectionButton onClick={onCancelClick} cancel>
              {t('button.cancel')}
            </$SectionButton>
          </>
        )}
      </I18n>
    ) : (
      <I18n>
        {(t) => <$SectionButton onClick={onChangeClick}>{t('button.change')}</$SectionButton>}
      </I18n>
    );

  return (
    <$Section>
      <$SectionHeader icon={icon}>
        <$SectionHeaderTextWrapper>
          <h3>{label}</h3>
          <h3>
            <$ColorText color="grayLighter">{value}</$ColorText>
          </h3>
        </$SectionHeaderTextWrapper>
        {/* Loading animation and buttons */}
        {requests ? (
          <$LoadingAnimationWrapper>
            <LoadingAnimation requests={requests}>{rightButtons}</LoadingAnimation>
          </$LoadingAnimationWrapper>
        ) : (
          rightButtons
        )}
      </$SectionHeader>

      {/* Red validation header */}
      {changeInitiated && validationError && (
        <$ValidationSummary>{validationError.summaryMessage}</$ValidationSummary>
      )}

      {/* Main content */}
      {changeInitiated && children && <$SectionSectionsContent>{children}</$SectionSectionsContent>}

      {/* Dismissable Error */}
      {!changeInitiated && dismissableError && (
        <$DismissableErrorBox>{dismissableError}</$DismissableErrorBox>
      )}

      {/* Description */}
      <$SectionDescription>{description}</$SectionDescription>
    </$Section>
  );
};

interface ISettingsSectionCheckbox extends ISettingsSectionProps {
  name: string;
  icon: string;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export const SettingsSectionCheckbox: React.FunctionComponent<ISettingsSectionCheckbox> = ({
  label,
  name,
  description,
  icon,
  checked,
  onChange,
  requests = null,
}) => {
  const checkbox = <$Checkbox variant="dark" name={name} checked={checked} onChange={onChange} />;

  return (
    <$Section>
      <$SectionHeader icon={icon}>
        <h3>
          <label htmlFor={name}>{label}</label>
        </h3>
        {requests ? (
          <$LoadingAnimationWrapper>
            <LoadingAnimation requests={requests}>{checkbox}</LoadingAnimation>
          </$LoadingAnimationWrapper>
        ) : (
          checkbox
        )}
      </$SectionHeader>

      <$SectionDescription>{description}</$SectionDescription>
    </$Section>
  );
};
