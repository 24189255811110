import * as React from 'react';

import styled from '../../lib/styled_components';

const $SimplePageLayout = styled.div`
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-content: center;
`;

const $Image = styled.img`
  max-height: 256px;
`;

const $Content = styled.div`
  margin: 4rem 0;
  font-size: 3rem;
`;

export const SimplePageLayout: React.FunctionComponent<{
  errorImageSrc: string;
  errorImageAlt: string;
}> = ({ errorImageSrc, errorImageAlt, children }) => (
  <$SimplePageLayout>
    {errorImageSrc && (
      <div>
        <$Image src={errorImageSrc} alt={errorImageAlt} />
      </div>
    )}
    <$Content>{children}</$Content>
  </$SimplePageLayout>
);
