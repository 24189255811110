import * as React from 'react';

import styled from '../../../lib/styled_components';

type ISlotNames =
  | 'activeInstrumentHorizontal'
  | 'candleChart'
  | 'lastTradesSwitcher'
  | 'activeInstrumentLeft'
  | 'buy'
  | 'sell'
  | 'activeInstrumentRight'
  | 'userActiveOrders'
  | 'orderBookSell'
  | 'orderBookBuy'
  | 'trollBox';

const slotClass = (slot: ISlotNames) => `ExchangeGrid_${slot}`;

export const $ExchangeGrid = styled.div`
  padding: ${(p) => p.theme.layout.mainGap}px;

  display: grid;
  grid-gap: ${(p) => p.theme.layout.mainGap}px;

  // By default, we have 5 columns and 4 gaps
  grid-template-columns:
    ${(p) => p.theme.layout.exchange.desktopCol1}
    calc(${(p) => p.theme.layout.exchange.desktopCol2} - ${(p) => p.theme.layout.mainGap}px / 2)
    calc(${(p) => p.theme.layout.exchange.desktopCol3} - ${(p) => p.theme.layout.mainGap}px / 2)
    calc(${(p) => p.theme.layout.exchange.desktopCol4} - ${(p) => p.theme.layout.mainGap}px / 2)
    calc(${(p) => p.theme.layout.exchange.desktopCol5} - ${(p) => p.theme.layout.mainGap}px / 2)
    ${(p) => p.theme.layout.exchange.desktopCol6}
    calc(${(p) => p.theme.layout.exchange.desktopCol7} - ${(p) => p.theme.layout.mainGap}px * 3);

  // Desktop rows. By default, we presume the height of all the content will fit on screen vertically.
  // This get overridden if height is less than minScrollbarlessVerticalHeight
  grid-template-rows:
    // Chart and trades
    calc(
      (
          100vh - ${(p) => p.theme.layout.headerHeight.default}px -
            ${(p) => p.theme.layout.mainGap * 2}px - ${(p) => p.theme.layout.exchange.buySellHeight}
        ) * 0.55 - ${(p) => p.theme.layout.mainGap}px
    )
    // Active instrument, buy / sell, active orders
    ${(p) => p.theme.layout.exchange.buySellHeight}
    // Market depth, trollbox
    calc(
      (
          100vh - ${(p) => p.theme.layout.headerHeight.default}px -
            ${(p) => p.theme.layout.mainGap * 2}px - ${(p) => p.theme.layout.exchange.buySellHeight}
        ) * 0.45 - ${(p) => p.theme.layout.mainGap}px
    );

  @media only screen and ${(p) => p.theme.device.laptop} {
    // On laptop, we have 4 columns and 4 gaps

    grid-template-columns:
      ${(p) => p.theme.layout.exchange.laptopCol1}
      calc(${(p) => p.theme.layout.exchange.laptopCol2} - ${(p) => p.theme.layout.mainGap}px / 2)
      calc(${(p) => p.theme.layout.exchange.laptopCol3} - ${(p) => p.theme.layout.mainGap}px / 2)
      calc(${(p) => p.theme.layout.exchange.laptopCol4} - ${(p) => p.theme.layout.mainGap}px * 2);

    grid-template-rows:
      // Chart
      ${(p) => p.theme.layout.exchange.chartHeight}
      // Active instrument left / right, buy / sell
      ${(p) => p.theme.layout.exchange.buySellHeight}
      // Market depth
      ${(p) => p.theme.layout.exchange.otherHeight}
      // Trades and active orders
      ${(p) => p.theme.layout.exchange.otherHeight}
      // Trollbox
      ${(p) => p.theme.layout.exchange.otherHeight};
  }

  @media only screen and ${(p) => p.theme.device.tablet} {
    // On tablet, everything is the same as on laptop, except there isn't enough space for 4 central widgets
    // So we split them into 2 rows:
    // | ActiveInstrumentH. |
    // |---------|----------|
    // | Sell    | Buy      |

    grid-template-rows:
      // Chart
      ${(p) => p.theme.layout.exchange.chartHeight}
      // Active instrument horizontal
      auto
      // Buy / sell
      ${(p) => p.theme.layout.exchange.buySellHeight}
      // Market depth
      ${(p) => p.theme.layout.exchange.otherHeight}
      // Trades and active orders
      ${(p) => p.theme.layout.exchange.otherHeight}
      // Trollbox
      ${(p) => p.theme.layout.exchange.otherHeight};
  }

  @media only screen and (max-height: ${(p) =>
      p.theme.layout.exchange.minScrollbarlessVerticalHeight}px) and ${(p) =>
      p.theme.device.desktop} {
    // On desktop, if height is below the limit, we fall back to fixed widget height (matching those on laptop)

    grid-template-rows:
      // Chart and trades
      ${(p) => p.theme.layout.exchange.chartHeight}
      // Buy / sell, active orders
      ${(p) => p.theme.layout.exchange.buySellHeight}
      // Market depth, trollbox
      ${(p) => p.theme.layout.exchange.otherHeight};
  }

  @media only screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    padding: ${(p) => p.theme.layout.mainGap}px;
  }

  @media only screen and ${(p) => p.theme.device.mobile} {
    display: grid;
    padding: calc(${(p) => p.theme.layout.mainGap / 2}px + 3px);
    grid-template-rows: auto;
    grid-row-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const $ExchangeGridSlot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  // TODO: Tighten
  h4 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: ${(p) => p.theme.fontSize.large};
    text-transform: uppercase;
  }

  &.${slotClass('activeInstrumentHorizontal')} {
    display: none;
  }

  // Row 1
  &.${slotClass('candleChart')} {
    grid-column: 1 / 7;
    padding: 0;
  }
  &.${slotClass('lastTradesSwitcher')} {
    grid-column: 7 / 8;
  }
  // Row 2
  &.${slotClass('activeInstrumentLeft')} {
    grid-column: 1 / 2;
  }
  &.${slotClass('sell')} {
    position: relative;
    padding: 2px;
    grid-column: 4 / 6;
  }
  &.${slotClass('buy')} {
    position: relative;
    padding: 2px;
    grid-column: 2 / 4;
  }
  &.${slotClass('activeInstrumentRight')} {
    grid-column: 6 / 7;
  }
  &.${slotClass('userActiveOrders')} {
    position: relative;
    padding: 0;
    grid-column: 7 / 8;
  }
  // Row 3
  &.${slotClass('orderBookBuy')} {
    grid-column: 4 / 7;
  }
  &.${slotClass('orderBookSell')} {
    grid-column: 1 / 4;
  }
  &.${slotClass('trollBox')} {
    grid-column: 7 / 8;
    position: relative;
  }

  @media screen and ${(p) => p.theme.device.laptop} {
    // Row 1
    &.${slotClass('candleChart')} {
      grid-column: 1 / 5;
      order: 1;
    }
    // Row 2
    &.${slotClass('activeInstrumentLeft')} {
      grid-column: 1 / 2;
      order: 2;
    }
    &.${slotClass('sell')} {
      grid-column: 3 / 4;
      order: 4;
    }
    &.${slotClass('buy')} {
      grid-column: 2 / 3;
      order: 3;
    }
    &.${slotClass('activeInstrumentRight')} {
      grid-column: 4 / 5;
      order: 5;
    }
    // Row 3
    &.${slotClass('orderBookBuy')} {
      grid-column: 3 / 5;
      order: 7;
    }
    &.${slotClass('orderBookSell')} {
      grid-column: 1 / 3;
      order: 6;
    }
    // Row 4
    &.${slotClass('lastTradesSwitcher')} {
      order: 8;
      grid-column: 1 / 3;
    }
    &.${slotClass('userActiveOrders')} {
      grid-column: 3 / 5;
      order: 9;
    }
    // Row 5
    &.${slotClass('trollBox')} {
      order: 10;
      grid-column: 1 / 5;
    }
  }

  @media screen and ${(p) => p.theme.device.tablet} {
    &.${slotClass('activeInstrumentLeft')} {
      display: none;
    }
    &.${slotClass('activeInstrumentRight')} {
      display: none;
    }
    // Row 2
    &.${slotClass('activeInstrumentHorizontal')} {
      grid-column: 1 / 5;
      order: 2;
      display: block;
    }
    // Row 3
    &.${slotClass('sell')} {
      grid-column: 3 / 5;
      order: 5;
    }
    &.${slotClass('buy')} {
      grid-column: 1 / 3;
      order: 4;
    }
  }

  @media screen and ${(p) => p.theme.device.mobile} {
    &.${slotClass('activeInstrumentHorizontal')} {
      margin-top: 0.5rem;
      grid-column: 1 / 3;

      // NOTE: This is needed because CandleChartOverlay will put CandleChart beneath this thing, and we need to
      //       have equal height in both the Overlay and ExchangePage. We are fairly safe to do this
      //       because ActiveInstrumentHorizontal will not break into multiline under any condition.
      min-height: 100px;
    }
    &.${slotClass('candleChart')} {
      order: 2;
      height: 460px;
      grid-column: 1 / 3;
    }
    &.${slotClass('buy')} {
      order: 3;
      grid-column: 1 / 2;
    }
    &.${slotClass('sell')} {
      order: 4;
      grid-column: 2 / 3;
    }
    &.${slotClass('orderBookBuy')} {
      order: 6;
      grid-column: 2 / 3;
    }
    &.${slotClass('orderBookSell')} {
      order: 5;
      grid-column: 1 / 2;
    }
    &.${slotClass('userActiveOrders')} {
      order: 7;
      height: 250px;
      grid-column: 1 / 3;
    }
    &.${slotClass('lastTradesSwitcher')} {
      order: 8;
      height: 250px;
      grid-column: 1 / 3;
    }
    &.${slotClass('trollBox')} {
      height: 250px;
      grid-column: 1 / 3;
    }
  }

  @media screen and ${(p) => p.theme.device.mobile_SamsungGalaxyTab2} {
    &.${slotClass('buy')} {
      order: 3;
      grid-column: 1 / 3;
    }
    &.${slotClass('sell')} {
      order: 4;
      grid-column: 1 / 3;
    }
  }
`;

type ISlotNameProps = {
  [key in ISlotNames]?: React.ReactNode;
};
interface IProps extends ISlotNameProps {}

const ExchangeGrid: React.FunctionComponent<IProps> = (props) => {
  const renderSlot = (slot: ISlotNames) => {
    return <$ExchangeGridSlot className={slotClass(slot)}>{props[slot] || null}</$ExchangeGridSlot>;
  };

  return (
    <$ExchangeGrid>
      {renderSlot('activeInstrumentHorizontal')}
      {renderSlot('candleChart')}
      {renderSlot('lastTradesSwitcher')}
      {renderSlot('activeInstrumentLeft')}
      {renderSlot('buy')}
      {renderSlot('sell')}
      {renderSlot('activeInstrumentRight')}
      {renderSlot('userActiveOrders')}
      {renderSlot('orderBookSell')}
      {renderSlot('orderBookBuy')}
      {renderSlot('trollBox')}
    </$ExchangeGrid>
  );
};

export default ExchangeGrid;
