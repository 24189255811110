import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import {
  enableTFAOrThrow,
  updateGeoLocationPinning,
  updateKeepSessionAlive,
  updateWithdrawalConfirmations,
} from '../../../actions/settings';
import { ITranslatedError } from '../../../lib/errors';
import { I18n } from '../../../lib/i18n';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import authenticationIcon from '../../../media/authentication.png';
import geoLocationPinningIcon from '../../../media/geo-location-pinning.png';
import keepSessionAliveIcon from '../../../media/keep-session-alive.png';
import monitorWithdrawalsByIpIcon from '../../../media/monitor-withdrawals-by-ip.png';
import { BoxIcon } from '../../../media/svg_icons';
import {
  getUserGeoLocationPinningStatus,
  getUserKeepAliveSettings,
  getUserTFAStatus,
  getUserWithdrawalConfirmationsSettings,
} from '../../../selectors/auth';
import { IApiTFAEnableResponse, IGeoLocationPinning } from '../../../types/backend_definitions';
import { HideMobile } from '../../utility_components';
import ActiveSessions from '../../widgets/ActiveSessions';
import Header from '../../widgets/Header';
import LoadingAnimation from '../../widgets/LoadingAnimation';
import { $HeaderWrapper } from '../../widgets/PageHeaderWrappers';
import { $ToggleMenu, $ToggleMenuButton } from '../../widgets/ToggleMenu';
import {
  $SectionButton,
  $SettingsWrapper,
  SettingsSectionCheckbox,
  SettingsSectionGeneric,
} from './settings_components';
import { DisableTFA, EnableTFA } from './tfa/EnableDisableTFA';

const $GeoLocationPinningList = styled.ul`
  padding-left: 40px;
`;
const $GeoLocationPinningListItem = styled.li`
  /* padding-left: 45px; */
  margin: 1em 0;
  h3 {
    margin: 0;
  }
  p {
    margin: 0 0 0.7em 0;
  }
`;

const $EnableDisableButton = styled($SectionButton).attrs<{ enable: boolean }>((p) => ({
  backgroundColor: p.enable ? p.theme.colors.buy : p.theme.colors.sell,
}))<{ enable: boolean }>``;

const $MenuButtonActiveGreen = styled($ToggleMenuButton)`
  &.active {
    color: ${(p) => p.theme.colors.buy};
  }
`;

const $TwoFactorAuthentication = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
`;

interface ISecuritySettingsProps extends ConnectedProps<typeof connector> {}

interface ISecuritySettingsState {
  tfaSecretKey: string;
  TFAServerError: ITranslatedError;
  enableTfaProcessStarted: boolean;
  disableTfaProcessStarted: boolean;
  currentTFAStep: number;
}

class SecuritySettings extends React.PureComponent<ISecuritySettingsProps, ISecuritySettingsState> {
  private mounted = false;
  private SUCCESSFUL_TFA_STEP: number = 3;

  constructor(props) {
    super(props);
    this.state = {
      tfaSecretKey: '',
      TFAServerError: null,
      enableTfaProcessStarted: false,
      disableTfaProcessStarted: false,
      currentTFAStep: 1,
    };
  }

  private geoLocationPinningOptions: Array<{ value: IGeoLocationPinning; label: string }> = [
    { value: 'off', label: 'Off' },
    { value: 'loose', label: 'Loose' },
    { value: 'strict', label: 'Strict' },
  ];

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setGeoLocationPinning = (geoLocationPinning: IGeoLocationPinning) => {
    // if value is not changed, do not send unnecessary request
    if (this.props.geoLocationPinning !== geoLocationPinning) {
      this.props.updateGeoLocationPinning(geoLocationPinning);
    }
  };

  startEnableTfaProcess = () => {
    (this.props.enableTFAOrThrow as () => Promise<IApiTFAEnableResponse>)()
      .then(
        (response) =>
          this.mounted &&
          this.setState({
            enableTfaProcessStarted: true,
            tfaSecretKey: response.tfa_secret_key,
            TFAServerError: null,
          })
      )
      .catch(
        (errorResponse: ITranslatedError) => this.mounted && this.setTFAServerError(errorResponse)
      );
  };
  startDisableTfaProcess = () => this.setState({ disableTfaProcessStarted: true });
  enableTfaProcessFinished = () => this.setState({ enableTfaProcessStarted: false });
  disableTfaProcessFinished = () => this.setState({ disableTfaProcessStarted: false });

  cancelTfaProcess = () => {
    this.setState({
      enableTfaProcessStarted: false,
      disableTfaProcessStarted: false,
      tfaSecretKey: '',
      TFAServerError: null,
    });
  };

  setTFAServerError = (errorResponse) => {
    if (errorResponse) {
      this.setState({
        TFAServerError: errorResponse,
      });
    } else {
      this.setState({
        TFAServerError: null,
      });
    }
  };

  trackTFAProcess = (step) => {
    this.setState({ currentTFAStep: step });
  };

  render() {
    const {
      enableTfaProcessStarted,
      disableTfaProcessStarted,
      tfaSecretKey,
      TFAServerError,
    } = this.state;

    const {
      geoLocationPinning,
      keepSessionAlive,
      enableWithdrawalConfirmations,
      enabledTFA,
      updateKeepSessionAlive,
      updateWithdrawalConfirmations,
    } = this.props;

    return (
      <I18n>
        {(t) => (
          <>
            <HideMobile>
              <$HeaderWrapper>
                <Header
                  icon={BoxIcon}
                  subtitle={t('account')}
                  title={t('settings:sections.security')}
                  variant="dark"
                />
              </$HeaderWrapper>
            </HideMobile>

            <$SettingsWrapper>
              <SettingsSectionGeneric
                label={t('settings:security.activeSessions')}
                description=""
                icon={keepSessionAliveIcon}
                rightContent={
                  <LoadingAnimation requests={['getActiveSessions', 'deleteSessions']} />
                }
              >
                <ActiveSessions />
              </SettingsSectionGeneric>

              <SettingsSectionGeneric
                label={t('settings:security.tfaLong')}
                icon={authenticationIcon}
                description={t('settings:security.tfaDescription')}
                validationError={TFAServerError}
                rightContent={
                  <LoadingAnimation requests={['enableTFA', 'confirmTFA', 'disableTFA']}>
                    {!enableTfaProcessStarted && !disableTfaProcessStarted ? (
                      enabledTFA ? (
                        <$EnableDisableButton onClick={this.startDisableTfaProcess} enable={false}>
                          {t('button.disable')}
                        </$EnableDisableButton>
                      ) : (
                        <$EnableDisableButton onClick={this.startEnableTfaProcess} enable={true}>
                          {t('button.enable')}
                        </$EnableDisableButton>
                      )
                    ) : (
                      this.SUCCESSFUL_TFA_STEP !== this.state.currentTFAStep && (
                        <$SectionButton onClick={this.cancelTfaProcess} cancel>
                          {t('button.cancel')}
                        </$SectionButton>
                      )
                    )}
                  </LoadingAnimation>
                }
              >
                {enableTfaProcessStarted && (
                  <$TwoFactorAuthentication>
                    <EnableTFA
                      tfaSecretKey={tfaSecretKey}
                      finishTfaProcess={this.enableTfaProcessFinished}
                      setTFAServerError={this.setTFAServerError}
                      trackTFAProcess={this.trackTFAProcess}
                    />
                  </$TwoFactorAuthentication>
                )}

                {disableTfaProcessStarted && (
                  <$TwoFactorAuthentication>
                    <DisableTFA
                      finishTfaProcess={this.disableTfaProcessFinished}
                      setTFAServerError={this.setTFAServerError}
                      trackTFAProcess={this.trackTFAProcess}
                    />
                  </$TwoFactorAuthentication>
                )}
              </SettingsSectionGeneric>

              <SettingsSectionCheckbox
                label={t('settings:security.keepSessionAlive')}
                name="keepSessionAlive"
                icon={keepSessionAliveIcon}
                description={t('settings:security.keepSessionAliveDescription')}
                checked={keepSessionAlive}
                requests={['enableKeepAlive']}
                onChange={(event) => updateKeepSessionAlive(event.target.checked)}
              />

              <SettingsSectionCheckbox
                label={t('settings:security.withdrawalConfirmations')}
                name="withdrawalConfirmations"
                icon={monitorWithdrawalsByIpIcon}
                description={t('settings:security.withdrawalConfirmationsDescription')}
                checked={enableWithdrawalConfirmations}
                requests={['enableWithdrawalConfirmations']}
                onChange={(event) => updateWithdrawalConfirmations(event.target.checked)}
              />

              <SettingsSectionGeneric
                label={t('settings:security.geoLocationPinning')}
                icon={geoLocationPinningIcon}
                requests={['geoLocationPinning']}
                description={
                  <>
                    <span>{t('settings:security.geoLocationPinningDescription')}</span>

                    <$GeoLocationPinningList>
                      <$GeoLocationPinningListItem>
                        <h3>{t('settings:security.geoLocationPinningOptions.off')}</h3>
                        <p>{t('settings:security.geoLocationPinningOptions.offDescription')}</p>
                      </$GeoLocationPinningListItem>

                      <$GeoLocationPinningListItem>
                        <h3>{t('settings:security.geoLocationPinningOptions.loose')}</h3>
                        <p>{t('settings:security.geoLocationPinningOptions.looseDescription')}</p>
                      </$GeoLocationPinningListItem>

                      <$GeoLocationPinningListItem>
                        <h3>{t('settings:security.geoLocationPinningOptions.strict')}</h3>
                        <p>{t('settings:security.geoLocationPinningOptions.strictDescription')}</p>
                      </$GeoLocationPinningListItem>
                    </$GeoLocationPinningList>
                  </>
                }
                rightContent={
                  <$ToggleMenu>
                    {this.geoLocationPinningOptions.map((geoLocationPinningOption) => (
                      <$MenuButtonActiveGreen
                        key={geoLocationPinningOption.value}
                        onClick={() => this.setGeoLocationPinning(geoLocationPinningOption.value)}
                        active={geoLocationPinning === geoLocationPinningOption.value}
                      >
                        {geoLocationPinningOption.label}
                      </$MenuButtonActiveGreen>
                    ))}
                  </$ToggleMenu>
                }
              />
            </$SettingsWrapper>
          </>
        )}
      </I18n>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    enabledTFA: getUserTFAStatus(state),
    keepSessionAlive: getUserKeepAliveSettings(state),
    enableWithdrawalConfirmations: getUserWithdrawalConfirmationsSettings(state),
    geoLocationPinning: getUserGeoLocationPinningStatus(state),
  }),
  {
    enableTFAOrThrow,
    updateGeoLocationPinning,
    updateKeepSessionAlive,
    updateWithdrawalConfirmations,
  }
);

export default connector(SecuritySettings);
