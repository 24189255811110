import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { requestResetPassword } from '../../../actions/auth/password_reset';
import { ITranslatedError, ValidationSingleMessage, ValidationWrapper } from '../../../lib/errors';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { isRequestActive } from '../../../selectors/request_active';
import { II18nextT } from '../../../types/i18n';
import { $SuccessMessage } from '../../layout/KioskLayout';
import { $InputDark } from '../../widgets/Input';
import {
  $AccountPagesButton,
  $AccountPagesInputWrapper,
  $AccountPagesLinks,
  LoginLink,
  RegisterLink,
} from './auth_components';

const $Wrapper = styled.div`
  width: 340px;
  max-width: 100%;
`;

interface IPasswordResetRequestScreenProps
  extends RouteComponentProps,
    ConnectedProps<typeof connector> {}
interface IPasswordResetRequestScreenState {
  email: string;
  error: ITranslatedError;
  success: boolean;
}

class PasswordResetRequestScreen extends React.PureComponent<
  IPasswordResetRequestScreenProps,
  IPasswordResetRequestScreenState
> {
  mounted: boolean;
  static contextType: any = I18nContext;

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: null,
      success: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleUserInput = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    this.setState({ [name]: value } as any);
  };

  submitForm = (e: React.FormEvent) => {
    e.preventDefault();

    this.setState({ error: null }, () =>
      this.props
        .requestResetPassword(this.state.email)
        .then((successResponse) => {
          if (this.mounted) {
            successResponse && this.setState({ success: true });
          }
        })
        .catch((errorResponse: ITranslatedError) => {
          this.mounted && this.setState({ error: errorResponse });
        })
    );
  };

  render() {
    const { t }: II18nextT = this.context;

    return (
      <$Wrapper>
        {this.state.success ? (
          <$SuccessMessage>{t('requestPasswordReset.successMessage')}</$SuccessMessage>
        ) : (
          <form onSubmit={this.submitForm}>
            <ValidationSingleMessage error={this.state.error} />

            <$AccountPagesInputWrapper>
              <ValidationWrapper error={this.state.error} path={'body.email'}>
                <$InputDark
                  name="email"
                  type="text"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={(e) => this.handleUserInput(e)}
                  autoFocus
                />
              </ValidationWrapper>
            </$AccountPagesInputWrapper>

            <$AccountPagesInputWrapper>
              <$AccountPagesButton disabled={this.props.submittingForm} type="submit">
                {t('button.submit')}
              </$AccountPagesButton>
            </$AccountPagesInputWrapper>
          </form>
        )}
        <$AccountPagesLinks>
          <LoginLink />
          <RegisterLink />
        </$AccountPagesLinks>
      </$Wrapper>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    submittingForm: isRequestActive('requestResetPassword', state),
  }),
  {
    requestResetPassword,
  }
);

export default withRouter(connector(PasswordResetRequestScreen));
