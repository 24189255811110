import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  BUY_ORDER_STATUS,
  cancelManagedBuyOrder,
  downloadReport,
  getManagedBuyOrderStatus,
  loadManagedUserBuyOrders,
} from '../../../actions/transactions/managed';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { formatFullDateRegular } from '../../../lib/util';
import { OppositeArrowsIcon } from '../../../media/svg_icons';
import { getManagedBuyOrders, getManagedConfig } from '../../../selectors/transactions';
import { IApiCensoredManagedBuyOrder } from '../../../types/backend_definitions';
import { CURRENCY_TO_COUNTRY_ISO } from '../../../types/constants';
import { II18nextT } from '../../../types/i18n';
import BinaryPrefixDecimals from '../../widgets/BinaryPrefixDecimals';
import $ColorText from '../../widgets/ColorText';
import CountryFlag from '../../widgets/CountryFlag';
import Header from '../../widgets/Header';
import InstrumentIcon from '../../widgets/InstrumentIcon';
import { $ToolbarLabel } from '../../widgets/Label';
import {
  $ActionsInfo,
  $AdditionalInfo,
  $CardActionsBlock,
  $CardColumnBlock,
  $CardColumnFixedBlock,
  $InfoLabel,
  $InnerWrapper,
  $ManagedGreenButton,
  $ManagedInfoButton,
  $ManagedRedButton,
  $OrderCard,
  $Wrapper,
} from '../../widgets/Managed';
import { $HeaderWrapperJustified } from '../../widgets/Managed';
import WireTransferInfoPopup from '../WireTransferInfoPopup';
import BuyOrderForm from './BuyOrderForm';

const $ForInfo = styled.div`
  font-size: ${(p) => p.theme.fontSize.larger};
  font-weight: bold;
  color: ${(p) => p.theme.colors.white};
`;

const $StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const $NoDataMessage = styled.h3`
  text-align: center;
`;

const $CurrencyLabel = styled.div`
  position: relative;
  margin-left: 3px;
  bottom: 1px;

  > span {
    margin-left: 2px;
  }
`;

const $ForLabelWrapper = styled.div`
  display: flex;
`;

const $SplitColumn = styled($CardColumnFixedBlock)`
  min-width: 200px;
`;

const $SplitWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem 0.75rem;

  div {
    font-size: ${(p) => p.theme.fontSize.base};
    color: ${(p) => p.theme.colors.baseDark};

    span {
      font-size: ${(p) => p.theme.fontSize.large};
      color: ${(p) => p.theme.colors.white};
    }
  }

  @media ${(p) => p.theme.device.mobile_SamsungGalaxyTab2} {
    display: flex;
    flex-wrap: wrap;

    div:nth-child(-n + 3) {
      margin-right: 1rem;
    }
  }
`;

// *********************************************************************************************************************

class OrderRow extends React.PureComponent<
  {
    order: IApiCensoredManagedBuyOrder;
    downloadReport: (orderId: string) => Promise<void>;
    buyOrderFormRef: React.RefObject<any>;
    cancelManagedBuyOrder: (orderId: string) => Promise<void>;
    onShowPaymentInfo: (order: IApiCensoredManagedBuyOrder) => any;
  },
  { cancelling: boolean }
> {
  static contextType: any = I18nContext;

  constructor(props) {
    super(props);

    this.state = {
      cancelling: false,
    };
  }

  cancelOrder(orderId) {
    this.setState({ cancelling: true }, () => {
      return this.props.cancelManagedBuyOrder(orderId);
    });
  }

  renderStatus(order: IApiCensoredManagedBuyOrder) {
    const { t }: II18nextT = this.context;
    const status = getManagedBuyOrderStatus(order);
    if (status === BUY_ORDER_STATUS.cancelled) {
      return <$ColorText color="warning">{t('managed.order.status.cancelled')}</$ColorText>;
    }

    if (status === BUY_ORDER_STATUS.refunded) {
      return (
        <$StatusWrapper>
          <$ColorText color="warning">{t('managed.order.status.failed')}</$ColorText>
          <span>{order.failure_reason}</span>
        </$StatusWrapper>
      );
    }

    if (status === BUY_ORDER_STATUS.fulfilled) {
      return <$ColorText color="success">{t('managed.order.status.fulfilled')}</$ColorText>;
    }

    if (status === BUY_ORDER_STATUS.executingOrder) {
      return <$ColorText color="info">{t('managed.order.status.executingOrder')}</$ColorText>;
    }

    if (status === BUY_ORDER_STATUS.suspended) {
      return (
        <$StatusWrapper>
          <$ColorText color="info">{t('managed.order.status.suspended')}</$ColorText>
          <span>{order.suspension_reason}</span>
        </$StatusWrapper>
      );
    }

    if (status === BUY_ORDER_STATUS.paymentReceived) {
      return <$ColorText color="info">{t('managed.order.status.paymentReceived')}</$ColorText>;
    }

    return (
      <$StatusWrapper>
        <$ColorText color="info">{t('managed.order.status.waitingForPayment')}</$ColorText>
        <span>{t('managed.order.status.waitingInfo')}</span>
      </$StatusWrapper>
    );
  }

  renderBuyOrderContent(order: IApiCensoredManagedBuyOrder) {
    const { t }: II18nextT = this.context;
    return (
      <div>
        {/* FOR INFO */}
        <$CardColumnFixedBlock>
          <$ForLabelWrapper>
            <$InfoLabel>{t('managed.order.buyWith')}</$InfoLabel>
            <$CurrencyLabel>
              {order.currency}
              <CountryFlag isoCode={CURRENCY_TO_COUNTRY_ISO[order.currency]} />
            </$CurrencyLabel>
          </$ForLabelWrapper>
          <$ForInfo>
            <BinaryPrefixDecimals int value={order.quantity} />
          </$ForInfo>
        </$CardColumnFixedBlock>
        {/* ORDERED INFO */}
        <$SplitColumn>
          <$InfoLabel>{t('managed.order.split')}</$InfoLabel>
          <$SplitWrapper>
            {Object.keys(order.distribution)
              .filter((i) => !!order.distribution[i])
              .map((inst) => (
                <div key={inst}>
                  <InstrumentIcon
                    style={{ verticalAlign: 'bottom', marginRight: '2px' }}
                    name={inst}
                    size={15}
                  />
                  <span>
                    {inst}: {order.distribution[inst]}%
                  </span>
                </div>
              ))}
          </$SplitWrapper>
        </$SplitColumn>
        {/* REFERENCE ID INFO */}
        <$CardColumnFixedBlock>
          <$InfoLabel>{t('managed.order.referenceId')}</$InfoLabel>
          <$AdditionalInfo>{order.payment_reference_number}</$AdditionalInfo>
        </$CardColumnFixedBlock>
        {/* CREATED INFO */}
        <$CardColumnBlock>
          <$InfoLabel>{t('managed.order.created')}</$InfoLabel>
          <$AdditionalInfo>{formatFullDateRegular(order.created_at)}</$AdditionalInfo>
        </$CardColumnBlock>

        {order.payment_received_at && (
          <$CardColumnBlock>
            <$InfoLabel>{t('managed.order.status.paymentReceived')}</$InfoLabel>
            <$AdditionalInfo>{formatFullDateRegular(order.payment_received_at)}</$AdditionalInfo>
          </$CardColumnBlock>
        )}

        {order.taken_at && (
          <$CardColumnBlock>
            <$InfoLabel>{t('managed.order.processingStarted')}</$InfoLabel>
            <$AdditionalInfo>{formatFullDateRegular(order.taken_at)}</$AdditionalInfo>
          </$CardColumnBlock>
        )}

        {order.completed_at && (
          <$CardColumnBlock>
            <$InfoLabel>{t('managed.order.completed')}</$InfoLabel>
            <$AdditionalInfo>{formatFullDateRegular(order.completed_at)}</$AdditionalInfo>
          </$CardColumnBlock>
        )}

        {/* STATUS INFO */}
        <$CardColumnBlock>
          <$InfoLabel>{t('managed.order.status.label')}</$InfoLabel>
          {this.renderStatus(order)}
        </$CardColumnBlock>
      </div>
    );
  }

  downloadReport(orderId) {
    return this.props.downloadReport(orderId);
  }

  renderActions(order: IApiCensoredManagedBuyOrder) {
    const { t }: II18nextT = this.context;
    const status = getManagedBuyOrderStatus(order);

    if (status === BUY_ORDER_STATUS.waitingForPayment) {
      return (
        <$CardActionsBlock>
          <$InfoLabel>{t('managed.order.actions.label')}</$InfoLabel>
          <$ActionsInfo>
            <$ManagedInfoButton onClick={() => this.props.onShowPaymentInfo(this.props.order)}>
              {t('managed.order.actions.paymentInfo')}
            </$ManagedInfoButton>
            <$ManagedGreenButton
              onClick={() => this.props.buyOrderFormRef.current.showForOrder(order)}
            >
              {t('managed.order.actions.editOrder')}
            </$ManagedGreenButton>
            <$ManagedRedButton
              disabled={this.state.cancelling}
              onClick={() => this.cancelOrder(order.id)}
            >
              {t('managed.order.actions.cancelOrder')}
            </$ManagedRedButton>
          </$ActionsInfo>
        </$CardActionsBlock>
      );
    }

    if (status === BUY_ORDER_STATUS.fulfilled) {
      return (
        <$CardActionsBlock>
          <$InfoLabel>{t('managed.order.actions.label')}</$InfoLabel>
          <$ActionsInfo>
            <$ManagedGreenButton onClick={() => this.downloadReport(order.id)}>
              {t('managed.order.actions.downloadReport')}
            </$ManagedGreenButton>
          </$ActionsInfo>
        </$CardActionsBlock>
      );
    }

    return null;
  }

  render() {
    return (
      <$OrderCard>
        {this.renderBuyOrderContent(this.props.order)}
        {this.renderActions(this.props.order)}
      </$OrderCard>
    );
  }
}

// *********************************************************************************************************************

interface IProps extends ConnectedProps<typeof connector>, RouteComponentProps {}
interface IComponentState {
  paymentInfoOrder: IApiCensoredManagedBuyOrder;
  paymentInfoShown: boolean;
}

class BuyOrders extends React.PureComponent<IProps, IComponentState> {
  static contextType: any = I18nContext;
  private buyOrderFormRef: React.RefObject<any> = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      paymentInfoOrder: null,
      paymentInfoShown: false,
    };
  }

  componentDidMount() {
    this.props.loadManagedUserBuyOrders().then(() => {
      const { location } = this.props;
      if (
        location.state.routeProps &&
        location.state.routeProps.instrument &&
        this.buyOrderFormRef.current
      ) {
        this.buyOrderFormRef.current.showForInstrument(location.state.routeProps.instrument);
      }
    });
  }

  renderOrders() {
    const { t }: II18nextT = this.context;
    const { orders } = this.props;

    return (
      <$Wrapper>
        <$HeaderWrapperJustified>
          <Header
            title={t('managed.pages.buyOrders')}
            icon={OppositeArrowsIcon}
            loadingKeys={['getUserManagedBuyOrders']}
            hideOnMobile={true}
          />
          <div>
            <$ToolbarLabel htmlFor="newOrder">{t('managed.newOrder')}:</$ToolbarLabel>
            <$ManagedGreenButton onClick={() => this.buyOrderFormRef.current.showForInstrument()}>
              {t('buy')}
            </$ManagedGreenButton>
          </div>
        </$HeaderWrapperJustified>

        <$InnerWrapper>
          {orders.items.length ? (
            orders.items.map((order) => {
              return (
                <OrderRow
                  key={order.id}
                  order={order}
                  cancelManagedBuyOrder={this.props.cancelManagedBuyOrder as any}
                  downloadReport={this.props.downloadReport as any}
                  buyOrderFormRef={this.buyOrderFormRef}
                  onShowPaymentInfo={() => {
                    this.setState({
                      paymentInfoOrder: order,
                      paymentInfoShown: true,
                    });
                  }}
                />
              );
            })
          ) : (
            <$NoDataMessage>{t('managed.noOrdersMessage')}</$NoDataMessage>
          )}
        </$InnerWrapper>
      </$Wrapper>
    );
  }

  render() {
    const { t }: II18nextT = this.context;
    const paymentInfoIban =
      this.state.paymentInfoOrder && this.state.paymentInfoOrder.currency === 'CHF'
        ? this.props.managedSettings.ibanCHF
        : this.props.managedSettings.ibanEUR;

    return (
      <div>
        {this.renderOrders()}
        <BuyOrderForm ref={this.buyOrderFormRef} />
        <WireTransferInfoPopup
          recipientDetails={this.props.managedSettings.recipientDetails}
          title={t('managed.forms.paymentInfo')}
          bankName={this.props.managedSettings.bankName}
          iban={paymentInfoIban}
          swift={this.props.managedSettings.swift}
          currency={(this.state.paymentInfoOrder && this.state.paymentInfoOrder.currency) || null}
          isOpen={this.state.paymentInfoShown}
          onClose={() => {
            this.setState({
              paymentInfoShown: false,
            });
          }}
          referenceNumber={
            this.state.paymentInfoOrder && this.state.paymentInfoOrder.payment_reference_number
          }
          amount={this.state.paymentInfoOrder && this.state.paymentInfoOrder.quantity}
        />
      </div>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    orders: getManagedBuyOrders(state),
    config: getManagedConfig(state),
    managedSettings: state.env.managed,
  }),
  {
    loadManagedUserBuyOrders,
    cancelManagedBuyOrder,
    downloadReport,
  }
);

export default withRouter(connector(BuyOrders));
