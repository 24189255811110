import * as React from 'react';

import { IActiveRequestsKeys } from '../../actions/request_active';
import styled, { css } from '../../lib/styled_components';
import { IThemeVariant } from '../../styles/theme_night';
import { $Icon } from './Icon';
import LoadingAnimation from './LoadingAnimation';

interface IHeaderSCProps {
  underlined?: boolean;
  capitalized?: boolean;
  variant?: IThemeVariant;
  hideOnMobile?: boolean;
}
interface IHeaderProps extends IHeaderSCProps {
  loadingKeys?: IActiveRequestsKeys[];
  icon: any; // IInstrument | string | Element;
  iconSize?: number;
  title?: string;
  subtitle?: string;
  marginTop?: number;
  height?: number;
}

const $HeaderTitles = styled.h2<{ capitalized: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-transform: ${(p) => (p.capitalized ? 'uppercase' : 'none')};
  margin: 0;
`;
const $Title = styled.span`
  font-size: 14.04px;
`;
const $Subtitle = styled.span`
  font-size: ${(p) => p.theme.base.fontSizeDesktop};
`;

const $Header = styled('div')<IHeaderSCProps>`
  display: flex;
  align-items: center;

  ${(p) =>
    p.underlined
      ? css`
          border-bottom: 1px solid ${p.theme.colors.baseDarker};
          height: 80px;
        `
      : css`
          height: 50px;
          padding: 5px 0;
        `};

  color: ${(p) => p.variant && p.theme.widgets.header[p.variant].color};

  @media ${(p) => p.theme.device.mobile} {
    display: ${(p) => (p.hideOnMobile ? 'none' : 'flex')} !important;
  }

  svg {
    width: ${(p) => p.theme.layout.sideMenu.icons.activeSize};
    height: ${(p) => p.theme.layout.sideMenu.icons.activeSize};
  }
`;

export const $HeaderIndentWrapper = styled($Header)<{ paddingLeft?: string }>`
  padding-left: ${(p) => (p.paddingLeft ? p.paddingLeft : '10px')};
`;

function breakSentence(title: string) {
  return title.split(' ').map((word, i, arr) => {
    if (arr.length - 1 === i) {
      return <span key={i}>{word}</span>;
    }
    return (
      <span key={i}>
        {word}
        <br />
      </span>
    );
  });
}

const Header: React.FunctionComponent<IHeaderProps> = ({
  icon,
  iconSize = 32,
  title,
  subtitle,
  underlined,
  loadingKeys,
  marginTop = 0,
  height,
  variant = 'dark' as IThemeVariant,
  capitalized = false,
  hideOnMobile = false,
}) => {
  return (
    <$Header
      underlined={underlined}
      capitalized={capitalized}
      variant={variant}
      style={{ marginTop, height }}
      hideOnMobile={hideOnMobile}
    >
      <$Icon src={icon} size={iconSize} />
      <$HeaderTitles capitalized={capitalized}>
        {subtitle && <$Subtitle>{subtitle}</$Subtitle>}
        {title && <$Title>{title}</$Title>}
      </$HeaderTitles>
      {loadingKeys && <LoadingAnimation requests={loadingKeys} />}
    </$Header>
  );
};

export default Header;
