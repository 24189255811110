import * as React from 'react';

const RECAPTCHA_SCRIPT_URL = 'https://www.recaptcha.net/recaptcha/api.js';
const RECAPTCHA_SCRIPT_REGEX = /(http|https):\/\/(www)?.+\/recaptcha/;

declare global {
  //   tslint:disable-next-line
  interface Window {
    grecaptcha: {
      ready: (callback: Function) => Promise<void>;
      render: (container: HTMLElement, config: IRecaptchaRenderConfig) => number;
      execute: (id: number) => void;
      reset: (id: number) => void;
    };
  }
}

interface IRecaptchaRenderConfig {
  sitekey: string;
  theme?: string;
  size?: string;
  badge?: string;
  tabindex?: number;
  callback?: Function;
  'expired-callback'?: Function;
  'error-callback'?: Function;
  isolated?: boolean;
  hl?: string;
}

interface IRenderProps {
  reset: () => Promise<void>;
  execute: () => Promise<void>;
  recaptchaComponent: any;
}

interface IRecaptchaProps {
  id: string;
  onVerify: (string) => void;
  isLoaded?: boolean;
  theme: 'dark' | 'light';
  languageCode: string;
  onLoad?: () => void;
  type: 'invisible' | 'checkbox';
  children?: (renderProps: IRenderProps) => Node;
  inject: boolean;
  siteKey: string;
  badgePosition: 'bottomright' | 'bottomleft' | 'inline';
  onRender?: () => void;
}

interface IRecaptchaState {
  instanceId: number;
  ready: boolean;
  rendered: boolean;
}

class Recaptcha extends React.PureComponent<IRecaptchaProps, IRecaptchaState> {
  private mounted: boolean;
  private timer;
  private container: React.RefObject<HTMLDivElement> = React.createRef();

  static defaultProps: Partial<IRecaptchaProps> = {
    id: '',
    theme: 'light',
    languageCode: '',
    type: 'invisible',
    inject: true,
    badgePosition: 'bottomright',
  };

  constructor(props) {
    super(props);

    this.state = {
      instanceId: null,
      ready: false,
      rendered: false,
    };
  }

  private get isInvisible() {
    return this.props.type === 'invisible';
  }

  private isAvailable = (): boolean =>
    Boolean(window && window.grecaptcha && window.grecaptcha.ready);

  private inject = (): void => {
    const isScriptPresent = Array.from(document.scripts).reduce(
      (isPresent, script) => (isPresent ? isPresent : RECAPTCHA_SCRIPT_REGEX.test(script.src)),
      false
    );

    if (this.props.inject && !isScriptPresent) {
      const hlParam = this.props.languageCode ? `&hl=${this.props.languageCode}` : '';
      const src = `${RECAPTCHA_SCRIPT_URL}?render=explicit${hlParam}`;

      const script = document.createElement('script');

      script.async = true;
      script.defer = true;
      script.src = src;

      if (document.head) {
        document.head.appendChild(script);
      }
    }
  };

  private prepare = (): void => {
    window.grecaptcha.ready(() => {
      if (this.mounted) {
        this.setState({ ready: true });
        if (this.props.onLoad) {
          this.props.onLoad();
        }
      }
    });
  };

  private renderRecaptcha = (container: any, config: IRecaptchaRenderConfig): number => {
    return window.grecaptcha.render(container, config);
  };

  private resetRecaptcha = (): void =>
    this.mounted && window.grecaptcha.reset(this.state.instanceId);

  private executeRecaptcha = (): void => window.grecaptcha.execute(this.state.instanceId);

  private stopTimer = (): void => {
    if (this.timer) {
      clearInterval(this.timer);
    }
  };

  componentDidMount = (): void => {
    this.mounted = true;
    this.inject();

    if (this.isAvailable()) {
      return this.prepare();
    }

    this.timer = setInterval(() => {
      if (this.isAvailable()) {
        this.prepare();
        this.stopTimer();
      }
    }, 500);
  };

  componentWillUnmount = (): void => {
    this.mounted = false;
    this.stopTimer();

    if (this.state.rendered) {
      this.resetRecaptcha();
    }
  };

  renderExplicitly = (): Promise<void> => {
    return Promise.resolve().then(() => {
      if (this.state.rendered) {
        throw new Error('This Recaptcha instance has been already rendered.');
      }

      if (this.state.ready && this.container.current) {
        const instanceId = this.renderRecaptcha(this.container.current, {
          sitekey: this.props.siteKey,
          theme: this.props.theme,
          size: this.props.type,
          badge: this.isInvisible ? this.props.badgePosition : null,
          callback: this.props.onVerify,
          hl: this.isInvisible ? null : this.props.languageCode,
        });

        return this.setState(
          {
            instanceId,
            rendered: true,
          },
          () => {
            if (this.props.onRender) {
              return this.props.onRender();
            }
          }
        );
      }

      throw new CaptchaNotReadyError();
    });
  };

  reset = (): Promise<void> => {
    return Promise.resolve().then(() => {
      if (this.state.rendered) {
        return this.resetRecaptcha();
      }

      throw new CaptchaNotRenderedError();
    });
  };

  execute = (): Promise<void> => {
    return Promise.resolve().then(() => {
      if (!this.isInvisible) {
        throw new Error('Manual execution is only available to invisible type.');
      }

      if (this.state.rendered) {
        return this.executeRecaptcha();
      }

      throw new CaptchaNotRenderedError();
    });
  };

  render = () => {
    const container = <div id={this.props.id} className="g-recaptcha" ref={this.container} />;

    return this.props.children
      ? this.props.children({
          reset: this.reset,
          execute: this.execute,
          recaptchaComponent: container,
        })
      : container;
  };
}

class CaptchaNotReadyError extends Error {
  constructor() {
    super('Recaptcha is not ready for rendering yet.');
  }
}

class CaptchaNotRenderedError extends Error {
  constructor() {
    super('This Recaptcha instance has not rendered yet.');
  }
}

export default Recaptcha;
