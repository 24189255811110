import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { logout } from '../../../actions/auth/logout';
import { register } from '../../../actions/auth/register';
import { goToExchange } from '../../../actions/routing';
import {
  ITranslatedError,
  ValidationSingleMessage,
  ValidationWrapper,
  clientValidator,
} from '../../../lib/errors';
import R from '../../../lib/routes';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { getRegistrationPolicy, isUserLoggedIn } from '../../../selectors/auth';
import { isRequestActive } from '../../../selectors/request_active';
import { ICountryCode } from '../../../types/backend_definitions';
import { TimeUnits } from '../../../types/constants';
import { II18nextT } from '../../../types/i18n';
import $Checkbox from '../../widgets/Checkbox';
import { $InputDark } from '../../widgets/Input';
import $Link from '../../widgets/Link';
import { CountrySelect } from '../../widgets/Select';
import {
  $AccountPagesButton,
  $AccountPagesInputWrapper,
  $AccountPagesLinks,
  LoginLink,
  RequestPasswordResetLink,
} from './auth_components';
import Recaptcha from './Recaptcha';

// types and constants

const $AuthButton = styled($AccountPagesButton)`
  width: 100%;
  margin: 1em 0;
`;

const $RecaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5em 0;
`;

const $Form = styled.form`
  width: 340px;
  max-width: 100%;
`;

const $DisabledMessage = styled.div`
  max-width: 300px;
  text-align: center;
  font-size: 1.2rem;
  margin: 2rem auto;
`;

interface IRegisterScreenProps extends RouteComponentProps, ConnectedProps<typeof connector> {}

interface IRegisterScreenState {
  firstName: string;
  lastName: string;
  nationality: string;
  email: string;
  password: string;
  passwordConfirm: string;
  agreedToTerms: boolean;
  disableButton: boolean;
  error: ITranslatedError;
  captchaRendered: boolean;
  captchaLoaded: boolean;
  captchaToken: string;
  resetCaptcha: boolean;
}

class RegisterScreen extends React.PureComponent<IRegisterScreenProps, IRegisterScreenState> {
  mounted: boolean;
  static contextType: any = I18nContext;
  captcha: React.RefObject<Recaptcha> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      nationality: '',
      email: '',
      password: '',
      passwordConfirm: '',
      agreedToTerms: false,
      disableButton: true,
      error: null,
      captchaRendered: false,
      captchaLoaded: false,
      captchaToken: null,
      resetCaptcha: false,
    };
  }

  componentDidMount() {
    this.mounted = true;

    const { registrationPolicy } = this.props;

    if (registrationPolicy && !registrationPolicy.require_captcha) {
      this.setState({ disableButton: false });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleUserInput = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    let value;

    switch (e.currentTarget.type) {
      case 'checkbox':
        value = e.currentTarget.checked;
        break;

      default:
        value = e.currentTarget.value;
        break;
    }

    this.setState({ [name]: value } as any);
  };

  handleCountrySelect = (option) => this.setState({ nationality: option.value });

  renderCaptcha = () => {
    if (this.captcha) {
      return this.captcha.current.renderExplicitly().then(() => {
        this.setState({ captchaRendered: true });
      });
    }

    return null;
  };

  submitForm = (e: React.FormEvent = null) => {
    if (e) {
      e.preventDefault();
    }

    const isFormReady = this.validateForm();

    if (isFormReady) {
      // submit form
      this.setState({ error: null }, () =>
        this.props
          .register(
            this.state.firstName,
            this.state.lastName,
            this.state.nationality as ICountryCode,
            this.state.email,
            this.state.password,
            this.state.captchaToken
          )
          .catch((errorResponse: ITranslatedError) => {
            // TODO: Do we have to have all these captcha states, repeated in each modal?
            if (this.mounted) {
              // Reset Captcha challenge to receive new valid token on completed Captcha
              if (this.state.captchaToken) {
                this.setState({ resetCaptcha: true });
              }
              if (errorResponse.name === 'CaptchaRequiredError') {
                this.setState({ disableButton: true });
              } else if (errorResponse.name === 'InvalidCaptchaTokenError') {
                this.setState({ captchaToken: null });
              } else {
                this.setState({ error: errorResponse, resetCaptcha: false, disableButton: true });
              }
            }
          })
      );
    }
  };

  validateForm = () => {
    return clientValidator(this.state, this.context.t, 'body.')
      .passwordsMatch('passwordConfirm', 'password')
      .required('firstName')
      .required('lastName')
      .required('agreedToTerms')
      .test(
        'captchaToken',
        !this.props.registrationPolicy.require_captcha || !!this.state.captchaToken,
        'validation.messages.captchaRequired'
      )
      .onFail((error) => {
        this.setState({
          error,
        });

        // TODO: Should we do better here?
        setTimeout(
          () => this.mounted && !this.state.captchaRendered && this.setState({ error: null }),
          10 * TimeUnits.second
        );
      })
      .isValid();
  };

  logout = () => {
    this.props.logout();
    this.props.goToExchange();
  };

  render() {
    const { t }: II18nextT = this.context;
    const { registrationPolicy } = this.props;

    if (registrationPolicy && registrationPolicy.disabled) {
      return <$DisabledMessage>{registrationPolicy.disabled_message}</$DisabledMessage>;
    }

    return (
      // TODO: what request did we intend to pass instead of requests={['getRegistrationPolicy']} ?
      <$Form onSubmit={this.submitForm}>
        <ValidationSingleMessage error={this.state.error} />

        <$AccountPagesInputWrapper>
          <ValidationWrapper error={this.state.error} path={'body.firstName'}>
            <$InputDark
              height="35px"
              type="text"
              name="firstName"
              placeholder={t('register.fields.firstName')}
              value={this.state.firstName}
              onChange={this.handleUserInput}
              autoFocus
            />
          </ValidationWrapper>
        </$AccountPagesInputWrapper>

        <$AccountPagesInputWrapper>
          <ValidationWrapper error={this.state.error} path={'body.lastName'}>
            <$InputDark
              height="35px"
              type="text"
              name="lastName"
              placeholder={t('register.fields.lastName')}
              value={this.state.lastName}
              onChange={this.handleUserInput}
            />
          </ValidationWrapper>
        </$AccountPagesInputWrapper>

        <$AccountPagesInputWrapper>
          <ValidationWrapper error={this.state.error} path={'body.nationality'}>
            <CountrySelect
              isoCode={this.state.nationality}
              variant="dark"
              onChange={this.handleCountrySelect}
              placeholder={t('register.fields.nationality')}
              background="transparent"
              centered
            />
          </ValidationWrapper>
        </$AccountPagesInputWrapper>

        <$AccountPagesInputWrapper>
          <ValidationWrapper error={this.state.error} path={'body.email'}>
            <$InputDark
              height="35px"
              name="email"
              type="email"
              placeholder={t('register.fields.email')}
              autoComplete="username"
              value={this.state.email}
              onChange={this.handleUserInput}
            />
          </ValidationWrapper>
        </$AccountPagesInputWrapper>

        <$AccountPagesInputWrapper>
          <ValidationWrapper error={this.state.error} path={'body.password'}>
            <$InputDark
              height="35px"
              name="password"
              type="password"
              autoComplete="new-password"
              placeholder={t('register.fields.password')}
              value={this.state.password}
              onChange={this.handleUserInput}
            />
          </ValidationWrapper>
        </$AccountPagesInputWrapper>

        <$AccountPagesInputWrapper>
          <ValidationWrapper error={this.state.error} path={'body.passwordConfirm'}>
            <$InputDark
              height="35px"
              name="passwordConfirm"
              type="password"
              autoComplete="new-password"
              placeholder={t('register.fields.confirmPassword')}
              value={this.state.passwordConfirm}
              onChange={this.handleUserInput}
            />
          </ValidationWrapper>
        </$AccountPagesInputWrapper>

        <$AccountPagesInputWrapper>
          <ValidationWrapper error={this.state.error} path={'body.agreedToTerms'}>
            <$Checkbox
              name="agreedToTerms"
              variant="light"
              checked={this.state.agreedToTerms}
              onChange={this.handleUserInput}
            >
              {t('register.termsCheckbox.iAgree')}{' '}
              <$Link underlined="true" to={R.TERMS_OF_USE} target="_blank" as={Link}>
                {t('register.termsCheckbox.termsOfUse')}
              </$Link>{' '}
              {t('register.termsCheckbox.and')}{' '}
              <$Link underlined="true" to={R.PRIVACY_POLICY} target="_blank" as={Link}>
                {t('register.termsCheckbox.privacyPolicy')}
              </$Link>
            </$Checkbox>
          </ValidationWrapper>
        </$AccountPagesInputWrapper>

        <$AccountPagesInputWrapper>
          {!this.state.resetCaptcha && registrationPolicy && registrationPolicy.require_captcha && (
            <$RecaptchaWrapper>
              <ValidationWrapper error={this.state.error} path={'body.captchaToken'}>
                <Recaptcha
                  ref={this.captcha}
                  siteKey={this.props.recaptchaSiteKey}
                  type="checkbox"
                  onLoad={() => {
                    this.setState({ captchaLoaded: true }, () => this.renderCaptcha());
                  }}
                  onVerify={(token) => {
                    this.setState({ captchaToken: token, disableButton: false });
                  }}
                />
              </ValidationWrapper>
            </$RecaptchaWrapper>
          )}
          <$AuthButton
            disabled={this.state.disableButton || this.props.registeringUser}
            type="submit"
          >
            {t('register.submit')}
          </$AuthButton>
        </$AccountPagesInputWrapper>

        <$AccountPagesLinks>
          <RequestPasswordResetLink />
          <LoginLink />
        </$AccountPagesLinks>
      </$Form>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    recaptchaSiteKey: state.env.recaptchaSiteKey,
    loggedIn: isUserLoggedIn(state),
    registrationPolicy: getRegistrationPolicy(state),
    registeringUser: isRequestActive('postCustomersRegister', state),
  }),
  { register, logout, goToExchange }
);

export default withRouter(connector(RegisterScreen));
