import * as React from 'react';

import { IStateGetter } from './store';

/**
 * Show notices about updates
 * https://github.com/maicki/why-did-you-update
 */
export function initWhyDidYouUpdate(getState: IStateGetter) {
  if (!getState().env.whyDidYouUpdate) {
    // Do not initialize
    return;
  }

  const { whyDidYouUpdate } = require('why-did-you-update');

  whyDidYouUpdate(React, { exclude: [/^Styled/, /^WithMergedOpti/] });
}
