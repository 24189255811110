import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { ICsvDownloadFunctions, downloadCSV } from '../../../actions/download_csv';
import { loadUserTrades, resetUserTrades } from '../../../actions/orders/user_trades';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { formatFullDate, formatFullDateRegular, formatNumberToFixed } from '../../../lib/util';
import { BanknoteWithArrowsIcon } from '../../../media/svg_icons';
import { getTradesPaginationState } from '../../../selectors/trades';
import { IPair } from '../../../types/backend_definitions';
import { II18nextT } from '../../../types/i18n';
import { PRICE_DIGITS, pairInfo } from '../../../types/instruments';
import { ITrade } from '../../../types/trades';
import { Omit } from '../../../types/typescript_helpers';
import Header from '../../widgets/Header';
import HorizontalScrollTable, {
  $FixedHeightCell,
  $FixedHeightTableRow,
  $HighlightableTableRow,
  IGridColumnProps,
  IScrollableRow,
  ITableParts,
} from '../../widgets/HorizontalScrollTable';
import InstrumentIcon from '../../widgets/InstrumentIcon';
import { $ToolbarLabel } from '../../widgets/Label';
import { SidedPrettyDecimals } from '../../widgets/PrettyDecimals';
import {
  $ActionElementsWrapper,
  $HeaderWrapperJustified,
  $InnerWrapper,
  $NoDataWrapper,
  $Wrapper,
  DownloadCSVButton,
  IDownloadCSVAction,
} from '../../widgets/Reports';
import { ResponsiveSelectWrapper, SimpleSelect } from '../../widgets/Select';
import { $Cell, ICellProps } from '../../widgets/Table';
import TableActionIndicator from '../../widgets/TableActionIndicator';
import HelpTooltip from '../../widgets/tooltips/HelpTooltip';

const $TooltipWrapper = styled.div<{ hideOnMobile: boolean }>`
  @media ${(p) => p.theme.device.mobile} {
    display: ${(p) => (p.hideOnMobile ? 'none' : 'flex')};
  }
`;

interface ITradeHistoryProps extends ConnectedProps<typeof connector> {
  stickHeader: boolean;
  getTableHeaderTopOffset?: (topOffset) => void;
  downloadCsv: IDownloadCSVAction;
  ICsvDownloadFunctions: ICsvDownloadFunctions;
}
interface ITradeHistoryPropsState {
  filterOrdersByPair: string;
  loading: boolean;
}

type ICellNames = 'pair' | 'date' | 'buy_sell' | 'type' | 'price' | 'amount' | 'fee' | 'total';

const CELLS: { [key in ICellNames]?: Partial<ICellProps> } = {
  date: { align: 'center', minWidth: '9rem' },
  pair: { align: 'center', minWidth: '7rem' },
  buy_sell: { align: 'center', minWidth: '6rem' },
  type: { align: 'center', minWidth: '3.5rem' },
  price: { align: 'center', minWidth: '11rem' },
  amount: { align: 'center', minWidth: '11rem' },
  fee: { align: 'center', minWidth: '11rem' },
  total: { align: 'center', minWidth: '11rem' },
};

const GRID_COLUMNS: IGridColumnProps<ICellNames> = {
  fixed: { date: { width: '1fr' } },
  scrollable: {
    pair: { width: '7rem' },
    buy_sell: { width: '6rem' },
    type: { width: '5rem' },
    price: { width: '1fr' },
    amount: { width: '1fr' },
    fee: { width: '1fr' },
    total: { width: '1fr' },
  },
};

export interface ITradeScrollableTableRow extends Omit<ITrade, 'timestamp'> {}

class TradeHistory extends React.PureComponent<ITradeHistoryProps, ITradeHistoryPropsState> {
  static contextType: any = I18nContext;
  private filterOptions: Object;
  private mounted: boolean = false;

  constructor(props) {
    super(props);

    this.state = {
      filterOrdersByPair: 'all',
      loading: false,
    };
  }

  componentWillMount() {
    this.filterOptions = this.props.pairs.reduce(
      (res, pair) => {
        const formattedPair = pair.replace(/_/g, '/');
        res[pair] = { value: pair, label: formattedPair };
        return res;
      },
      { all: { value: 'all', label: this.context.t('all') } }
    );
  }

  componentDidMount() {
    this.mounted = true;
    this.props.resetUserTrades();
    this.onLoadUserTrades(null, true);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onLoadUserTrades = (pair?: IPair, firstPage?: boolean) => {
    this.setState({ loading: true });
    this.props
      .loadUserTrades(pair, firstPage)
      .finally(() => this.mounted && this.setState({ loading: false }));
  };

  renderTableParts(): ITableParts {
    const { t }: II18nextT = this.context;

    return {
      header: {
        fixed: () => <$FixedHeightCell {...CELLS.date}>{t('date')}</$FixedHeightCell>,
        scrollable: () => (
          <>
            <$FixedHeightCell {...CELLS.pair}>{t('pair')}</$FixedHeightCell>
            <$FixedHeightCell {...CELLS.buy_sell}>
              {t('buy')} / {t('sell')}
            </$FixedHeightCell>
            <$FixedHeightCell {...CELLS.type}>{t('type')}</$FixedHeightCell>
            <$FixedHeightCell {...CELLS.price}>{t('price')}</$FixedHeightCell>
            <$FixedHeightCell {...CELLS.amount}>{t('amount')}</$FixedHeightCell>
            <$FixedHeightCell {...CELLS.fee}>{t('fee')}</$FixedHeightCell>
            <$FixedHeightCell {...CELLS.total}>{t('estimatedTotal')}</$FixedHeightCell>
          </>
        ),
      },
      body: {
        fixed: (highlightedRowIndex, highlightSelectedRow) => {
          return this.props.tradesPaginationState.items.fixed.map((timestamp, index) => {
            return (
              <$HighlightableTableRow
                onMouseOver={() => highlightSelectedRow(index)}
                highlighted={index === highlightedRowIndex}
                key={index}
              >
                <$Cell {...CELLS.date} title={formatFullDate(timestamp)}>
                  {formatFullDateRegular(timestamp)}
                </$Cell>
              </$HighlightableTableRow>
            );
          });
        },
        scrollable: (tradeRow: ITradeScrollableTableRow, index, highlightSelectedRow) =>
          this.renderScrollableRow(tradeRow, index, highlightSelectedRow),
      },
    };
  }

  renderScrollableRow: IScrollableRow = (
    tradeRow: ITradeScrollableTableRow,
    index,
    highlightSelectedRow
  ) => {
    const { t }: II18nextT = this.context;

    const { displayPath, quote, base } = pairInfo(tradeRow.pair);

    return (
      <$FixedHeightTableRow
        onMouseOver={() => highlightSelectedRow(index)}
        key={tradeRow.signature}
        noPointer
      >
        <$Cell {...CELLS.pair}>
          <InstrumentIcon
            size={22}
            name={quote.symbol}
            type="white"
            style={{ verticalAlign: 'middle' }}
          />
          &nbsp;&nbsp;
          <strong>{displayPath}</strong>
        </$Cell>
        <$Cell {...CELLS.buy_sell}>{t(tradeRow.user_side)}</$Cell>
        <$Cell {...CELLS.type}>{t(`orderType.${tradeRow.order_type}` as any)}</$Cell>
        <$Cell {...CELLS.price}>
          <SidedPrettyDecimals
            value={formatNumberToFixed(tradeRow.price, PRICE_DIGITS)}
            side={tradeRow.user_side}
          />{' '}
          {base.symbol}
        </$Cell>
        <$Cell {...CELLS.amount}>
          <SidedPrettyDecimals
            value={formatNumberToFixed(tradeRow.quantity, quote.digits)}
            side={tradeRow.user_side}
          />{' '}
          {quote.symbol}
        </$Cell>
        <$Cell {...CELLS.fee}>
          <SidedPrettyDecimals
            value={formatNumberToFixed(tradeRow.fee_quantity, base.digits)}
            side={tradeRow.user_side}
          />{' '}
          {base.symbol}
        </$Cell>
        <$Cell {...CELLS.total}>
          <SidedPrettyDecimals
            value={formatNumberToFixed(tradeRow.total, base.digits)}
            side={tradeRow.user_side}
          />{' '}
          {base.symbol}
        </$Cell>
      </$FixedHeightTableRow>
    );
  };

  renderTradeHistory() {
    const { t }: II18nextT = this.context;
    const criteria =
      this.state.filterOrdersByPair !== 'all'
        ? {
            pair: this.state.filterOrdersByPair,
            sort_direction: ['desc'],
            sort_field: ['sort_index'],
          }
        : { sort_direction: ['desc'], sort_field: ['sort_index'] };

    return (
      <$Wrapper>
        <$HeaderWrapperJustified stickHeader={this.props.stickHeader}>
          <div>
            <Header
              title={t('reportsPage.tradeHistory')}
              icon={BanknoteWithArrowsIcon}
              loadingKeys={['getUserTrades']}
              hideOnMobile={true}
            />
            <$TooltipWrapper hideOnMobile={true}>
              &nbsp; &nbsp;
              <HelpTooltip overlay={t('reportsPage.tradeHistoryIsDelayed')} />
            </$TooltipWrapper>
          </div>
          <$ActionElementsWrapper>
            <div>
              <$ToolbarLabel htmlFor="tradesPair">{t('pair')}:</$ToolbarLabel>
              <ResponsiveSelectWrapper>
                <SimpleSelect
                  inputId="tradesPair"
                  width="8rem"
                  options={Object.values(this.filterOptions)}
                  value={this.filterOptions[this.state.filterOrdersByPair]}
                  onChange={({ value }) => {
                    this.setState({ filterOrdersByPair: value });
                    this.onLoadUserTrades(value === 'all' ? null : value, true);
                  }}
                  variant="transparent"
                />
              </ResponsiveSelectWrapper>
            </div>
            <div>
              <DownloadCSVButton
                downloadCsv={this.props.downloadCsv}
                csvDownloadFunction={'getExchangeTradesCsv'}
                criteria={criteria}
              />
            </div>
          </$ActionElementsWrapper>
        </$HeaderWrapperJustified>

        {this.state.loading ? (
          <div />
        ) : !this.props.tradesPaginationState.total_records ? (
          <$NoDataWrapper>{t('reportsPage.noData.tradeHistory')}</$NoDataWrapper>
        ) : (
          <$InnerWrapper>
            <HorizontalScrollTable
              data={this.props.tradesPaginationState.items.scrollable}
              gridColumns={GRID_COLUMNS}
              stickHeader={this.props.stickHeader}
              getTableHeaderTopOffset={this.props.getTableHeaderTopOffset}
              tableParts={this.renderTableParts()}
            >
              <TableActionIndicator
                paginationState={this.props.tradesPaginationState}
                requests={['getUserTrades']}
                itemsCount={this.props.tradesPaginationState.items.length}
                actionFn={() =>
                  this.props.loadUserTrades(
                    this.state.filterOrdersByPair === 'all'
                      ? null
                      : (this.state.filterOrdersByPair as IPair)
                  )
                }
              />
            </HorizontalScrollTable>
          </$InnerWrapper>
        )}
      </$Wrapper>
    );
  }

  render() {
    return this.renderTradeHistory();
  }
}

const connector = connect(
  (state: IState) => ({
    pairs: state.env.pairs.list,
    tradesPaginationState: getTradesPaginationState(state),
  }),
  {
    loadUserTrades,
    resetUserTrades,
    downloadCsv: downloadCSV,
  }
);

export default connector(TradeHistory);
