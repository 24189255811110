import {
  IApiXcalibraClientRequest,
  IBackendErrorName,
  ISessionTerminationReason,
} from '../types/backend_definitions';
import { ITranslations } from '../types/translations';
import { LocalStorage } from './local_storage';
import { Logger, LoggerColor } from './logger';
import { IStateGetter } from './store';

type IFetchFunction = (input?: Request | string, init?: RequestInit) => Promise<Response>;

export const UNAUTHENTICATED_ERROR_CODE = 401;
export const NON_HTTP_ERROR_CODE = 600;

export type IHttpErrorResponseName = IBackendErrorName | 'ServerError' | 'ClientError';
export class HttpErrorResponse extends Error {
  name: IHttpErrorResponseName;
  message: string;
  status: number;
  code: number;
  inner_error?: any;
  panic?: boolean;
  translation_key?: string;
  errors?: IHttpErrorResponseItem[];

  constructor(err) {
    super(err.message);
    Object.assign(this, err);
    this.status = this.code;
  }
}

export interface IHttpErrorResponseItem {
  known: boolean;
  message: string;
  message_suffix: string;
  error_code: ITranslations;
  data: { [data_key: string]: any };
  path: string;
}

export class ISessionTerminatedErrorResponse extends HttpErrorResponse {
  name: 'SessionTerminatedError';
  reason: ISessionTerminationReason;
}

class NonHttpError extends HttpErrorResponse {
  constructor(message) {
    super({ message, code: NON_HTTP_ERROR_CODE, name: null });
  }
}

// *********************************************************************************************************************

export interface IHttpRequestOpts extends IApiXcalibraClientRequest {
  /**
   * Override access token for the request
   */
  accessToken: string;
}

export class Http {
  private requestId = 0;

  constructor(
    private getState: IStateGetter,
    private logger: Logger,
    private localStorage: LocalStorage,
    private fetch: IFetchFunction
  ) {
    this.logger = this.logger.prefixed(this);
  }

  /**
   * Generate backend URL. Useful for download links
   */
  public generateUrl(opts: IApiXcalibraClientRequest): string {
    return this.getState().env.backendBaseUrl + opts.endpoint;
  }

  /**
   * Call request() while providing your own auth token.
   * Tip: you can generate opts by calling nameOfMyApiMethodSpec().
   */
  public customAuthRequest<T>(accessToken: string, opts: IApiXcalibraClientRequest): Promise<T> {
    return this.request({
      ...opts,
      accessToken,
    });
  }

  /**
   * Perform a request against backend. You can generate IHttpRequestOpts from backend_definitions.ts.
   */
  public request<T>(opts: IHttpRequestOpts): Promise<T> {
    const headers = opts.headers ? { ...opts.headers } : {};

    const state = this.getState();

    // if auth is required ensure 'Authorization' header is present
    if (opts.auth && !('Authorization' in headers)) {
      let accessToken = opts.accessToken;

      if (!accessToken) {
        accessToken = state.session && state.session.access_token;
      }

      if (!accessToken) {
        return Promise.reject(
          new NonHttpError(
            `Endpoint ${opts.endpoint} requires authentication, but no auth token is set`
          )
        );
      }

      headers.Authorization = `Bearer ${accessToken}`;
    }

    // create full url
    let url = state.env.backendBaseUrl + opts.endpoint;

    // add query part to the url
    if (opts.query) {
      const params = [];
      for (const key in opts.query) {
        if (opts.query.hasOwnProperty(key)) {
          params.push(encodeURIComponent(key) + '=' + encodeURIComponent(String(opts.query[key])));
        }
      }
      url += '?' + params.join('&');
    }

    // create fetch options object
    const fetchOptions = { method: opts.verb.toUpperCase(), headers } as any;

    if (opts.body && opts.content_type === 'multipart') {
      fetchOptions.body = opts.body;
    } else if (opts.body && opts.content_type === 'json') {
      fetchOptions.body = JSON.stringify(opts.body);
      headers['content-type'] = 'application/json';
    }

    // log start of the request
    const requestId = ++this.requestId;
    this.logger.info(
      `%c[${requestId}] ${opts.method} >>> %c${opts.verb.toUpperCase()}%c ${opts.endpoint}`,
      LoggerColor.gray,
      LoggerColor.blue,
      LoggerColor.black,
      opts.body || ''
    );

    return this.fetch(url, fetchOptions)
      .then((res) => {
        return res.text().then((bodyTxt) => {
          let data;
          // ! raw_response: boolean - If true, response will be binary data, instead of JSON
          // ! if (raw_response) then ...

          if (bodyTxt) {
            try {
              data = JSON.parse(bodyTxt);
            } catch (err) {
              throw new NonHttpError('Invalid response type. Expected JSON, got: ' + bodyTxt);
            }
          }

          if (res.status >= 400) {
            const err = new HttpErrorResponse(
              data !== undefined ? data : { message: res.statusText }
            );
            err.status = res.status;
            throw err;
          }

          this.logger.info(
            `%c[${requestId}] ${opts.method} <<< %c${opts.verb.toUpperCase()}%c ${opts.endpoint}: ${
              res.status
            }`,
            LoggerColor.gray,
            LoggerColor.blue,
            LoggerColor.black,
            data
          );

          return data;
        });
      })
      .catch((err) => {
        if (err.message === 'Failed to fetch') {
          // Presume this is a CORS error
          err = new NonHttpError('Server cannot be reached');
        }

        this.logger.error(
          `[${requestId}]  ${opts.method} <<< ${opts.verb.toUpperCase()} ${opts.endpoint}: ${
            err.code || err.status || ''
          }  ${err.message}`,
          err
        );

        throw err;
      });
  }
}
