import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import {
  requestManagedSellOrder,
  updateManagedSellOrder,
} from '../../../actions/transactions/managed';
import Quantity from '../../../lib/quantity_';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { formatNumberToFixed } from '../../../lib/util';
import { getCustomer } from '../../../selectors/auth';
import { getBalances } from '../../../selectors/balances';
import { getConversionRate, getRates } from '../../../selectors/rates';
import { getManagedConfig } from '../../../selectors/transactions';
import {
  IApiCensoredManagedSellOrder,
  IApiManagedSellOrderRequestPayload,
  ICurrency,
  IInstrument,
  IMarketInstrument,
} from '../../../types/backend_definitions';
import { II18nextT } from '../../../types/i18n';
import { ALL_INSTRUMENTS } from '../../../types/instruments';
import { ITranslations } from '../../../types/translations';
import $Checkbox from '../../widgets/Checkbox';
import { $InputDark } from '../../widgets/Input';
import $Link from '../../widgets/Link';
import {
  $AmountInputWrapper,
  $FormBlock,
  $FormLabel,
  $FormTextInfo,
  $FormWrapper,
  $ManagedGreenButton,
} from '../../widgets/Managed';
// widgets
import Modal from '../../widgets/Modal';
import NumberInput from '../../widgets/NumberInput';
import { SimplexSelect } from '../../widgets/Select';

const $PatchedInput = styled($InputDark)`
  background: transparent;
  border: none;

  &:focus {
    outline: none;
    border: none;
  }
`;

const $PatchedAmountInputWrapper = styled($AmountInputWrapper)`
  padding: 5px 12px !important;
`;

const $ValidationMessage = styled('div')<{ active: boolean }>`
  margin-top: 0.6rem;
  padding-left: 1px;
  min-height: 1.1rem;

  color: ${(p) => (p.active ? p.theme.colors.white : p.theme.colors.grayLightest)};
`;

interface ISellOrderFormProps extends ConnectedProps<typeof connector> {}

interface ISellOrderFormState {
  orderId: string;
  saleTermsAccepted: boolean;
  payoutCurrency: ICurrency;
  bankName: string;
  accountNumber: string;
  swift: string;
  recipientName: string;
  recipientAddress: string;
  quantity: string;
  autoFillQuantity: boolean;
  instrument: IMarketInstrument;
  validationErrors: {
    quantity: boolean;
  };
  submitting: boolean;
}

class SellOrderForm extends React.PureComponent<ISellOrderFormProps, ISellOrderFormState> {
  static contextType: any = I18nContext;
  private modalRef: React.RefObject<Modal> = React.createRef();

  defaultState: ISellOrderFormState = {
    orderId: null,
    saleTermsAccepted: false,
    payoutCurrency: null,
    bankName: '',
    accountNumber: '',
    swift: '',
    recipientName: '',
    recipientAddress: '',
    quantity: '',
    autoFillQuantity: false,
    instrument: null,
    validationErrors: {
      quantity: false,
    },
    submitting: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.defaultState,
    };
  }

  static getDerivedStateFromProps(nextProps: ISellOrderFormProps, state: ISellOrderFormState) {
    const { instrument, payoutCurrency } = state;
    const { config } = nextProps;

    const payload: Partial<ISellOrderFormState> = {};

    if (config.supported_crypto.length && !instrument) {
      payload.instrument = config.supported_crypto[0] as IMarketInstrument;
    }

    if (config.supported_fiat.length && !payoutCurrency) {
      payload.payoutCurrency = config.supported_fiat[0] as ICurrency;
    }

    if (Object.keys(payload).length === 0) {
      return null;
    }

    return payload;
  }

  get currencyOptions() {
    return this.props.config.supported_fiat.reduce((res, currency) => {
      res[currency] = { value: currency, label: currency };
      return res;
    }, {});
  }

  get instrumentOptions() {
    return this.props.config.supported_crypto.reduce((res, instrument) => {
      res[instrument] = { value: instrument, label: instrument };
      return res;
    }, {});
  }

  get isFormReady() {
    const {
      saleTermsAccepted,
      quantity,
      payoutCurrency,
      instrument,
      recipientName,
      recipientAddress,
      bankName,
      swift,
      accountNumber,
      validationErrors,
      submitting,
    } = this.state;
    return (
      this.hasEnoughFunds &&
      saleTermsAccepted &&
      Number(quantity) &&
      !validationErrors.quantity &&
      payoutCurrency &&
      instrument &&
      bankName &&
      swift &&
      accountNumber &&
      recipientAddress &&
      recipientName &&
      !submitting
    );
  }

  selectInstrument = (instrument) =>
    this.setState({ instrument }, () => this.validateSellQuantity());

  onCheckboxCheck = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({
      saleTermsAccepted: event.currentTarget.checked,
    });

  onAutoFill = (event: React.ChangeEvent<HTMLInputElement>) => {
    const autoFillQuantity = event.currentTarget.checked;

    if (autoFillQuantity) {
      this.setState((state) => ({
        autoFillQuantity,
        validationErrors: { ...state.validationErrors, quantity: false },
      }));
    } else {
      this.setState({ autoFillQuantity });
      this.validateSellQuantity();
    }
  };

  get minSellQuantity() {
    return Quantity(
      Number(this.props.config.sell_order_min_payout_quantity) *
        getConversionRate(
          this.props.rates,
          this.props.config.sell_order_payout_instrument as IInstrument,
          this.state.instrument
        )
    )
      .truncateForInstrument(this.state.instrument)
      .toNumber();
  }

  validateSellQuantity(value: string = this.state.quantity) {
    const coercedValue = Number(value);
    const isInvalid =
      coercedValue < this.minSellQuantity || coercedValue > this.availableInstrument;

    this.setState((state) => ({
      quantity: value,
      validationErrors: { ...state.validationErrors, quantity: isInvalid },
    }));
  }

  onQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    this.validateSellQuantity(event.target.value);
  };

  onStringFieldChange = (name) => (e) => {
    this.setState({ [name]: e.target.value } as any);
  };

  showForInstrument = (instrument: IMarketInstrument) => {
    this.setState(
      {
        payoutCurrency: this.props.config.supported_fiat[0] as ICurrency,
        instrument: instrument || (this.props.config.supported_crypto[0] as IMarketInstrument),
        recipientName: `${this.props.customer.first_name} ${this.props.customer.last_name}`,
      },
      () => {
        this.setState({ quantity: String(this.availableInstrument) }, () => {
          this.modalRef.current.show();
        });
      }
    );
  };

  showForOrder(order: IApiCensoredManagedSellOrder) {
    this.setState(
      {
        orderId: order.id,
        quantity: order.quantity,
        instrument: order.instrument,
        payoutCurrency: order.payout_currency as ICurrency,
        recipientName: order.recipient_name,
        recipientAddress: order.recipient_address,
        bankName: order.bank_name,
        accountNumber: order.bank_account_number,
        swift: order.swift,
      },
      () => this.modalRef.current.show()
    );
  }

  clearState = () => this.setState({ ...this.defaultState });

  submitOrder = () => {
    if (!this.isFormReady) {
      return null;
    }

    const {
      orderId,
      payoutCurrency,
      bankName,
      accountNumber,
      swift,
      recipientName,
      recipientAddress,
      instrument,
      quantity,
      saleTermsAccepted,
      autoFillQuantity,
    } = this.state;
    const payload: IApiManagedSellOrderRequestPayload = {
      instrument,
      quantity,
      payout_currency: payoutCurrency,
      bank_name: bankName,
      bank_account_number: accountNumber,
      auto_fill_quantity: autoFillQuantity,
      swift,
      recipient_name: recipientName,
      recipient_address: recipientAddress,
    };

    this.setState({ submitting: true }, () => {
      if (orderId) {
        return this.props.updateManagedSellOrder(orderId, payload).then(() => {
          this.clearState();
          this.modalRef.current.hide();
        });
      }

      return this.props.requestManagedSellOrder(payload).then(() => {
        this.clearState();
        this.modalRef.current.hide();
      });
    });
  };

  get availableInstrument() {
    if (!this.props.balances || !this.props.balances[this.state.instrument]) {
      return null;
    }
    return this.props.balances[this.state.instrument].available;
  }

  get hasEnoughFunds() {
    return this.availableInstrument > this.minSellQuantity;
  }

  render() {
    const { t }: II18nextT = this.context;
    const { instrument } = this.state;

    if (!this.props.config || !this.props.rates || !this.props.balances) {
      return null;
    }

    return (
      <>
        <Modal
          ref={this.modalRef}
          title={t(
            this.state.orderId
              ? 'managed.forms.editSellOrder'
              : ('managed.forms.newSellOrder' as ITranslations)
          )}
          titleUppercase={true}
          onClose={this.clearState}
        >
          <$FormWrapper>
            <$FormTextInfo>{t('managed.forms.sellOrderTopInfo')}</$FormTextInfo>
            <$FormBlock>
              <$FormLabel htmlFor="quantity">{t('managed.forms.youWillSell')}</$FormLabel>
              <$PatchedAmountInputWrapper validationError={this.state.validationErrors.quantity}>
                {this.state.autoFillQuantity || !this.hasEnoughFunds ? (
                  <$PatchedInput
                    readOnly
                    type="text"
                    value={
                      !this.hasEnoughFunds
                        ? t('managed.forms.notEnoughFunds')
                        : t('managed.forms.autoFillMessage', {
                            instrument,
                          })
                    }
                  />
                ) : (
                  <NumberInput
                    id="quantity"
                    border="none"
                    min="0"
                    name="quantity"
                    value={this.state.quantity}
                    onChange={this.onQuantityChange}
                    disabled={this.state.autoFillQuantity}
                  />
                )}
                <SimplexSelect
                  variant="transparent"
                  options={Object.values(this.instrumentOptions)}
                  value={this.instrumentOptions[instrument]}
                  onChange={({ value }) => {
                    this.selectInstrument(value);
                  }}
                  for="instrument"
                />
              </$PatchedAmountInputWrapper>
              <$ValidationMessage active={this.state.validationErrors.quantity}>
                {t('managed.forms.minimumSellAmount', {
                  amount: this.minSellQuantity,
                  instrument,
                  available:
                    instrument &&
                    formatNumberToFixed(
                      this.availableInstrument,
                      ALL_INSTRUMENTS[instrument].digits
                    ),
                })}
              </$ValidationMessage>
            </$FormBlock>
            <$FormBlock>
              <$Checkbox
                uppercase={true}
                variant="light"
                name="autoFillQuantity"
                checked={this.state.autoFillQuantity}
                onChange={this.onAutoFill}
                disabled={!this.hasEnoughFunds}
              >
                {t('managed.forms.autoFillAmount')}
              </$Checkbox>
            </$FormBlock>

            <$FormBlock>
              <$FormLabel>{t('managed.forms.youWillReceive')}</$FormLabel>
              <SimplexSelect
                width="100%"
                variant="managed"
                options={Object.values(this.currencyOptions)}
                value={this.currencyOptions[this.state.payoutCurrency]}
                onChange={({ value }) => {
                  this.setState({ payoutCurrency: value });
                }}
                for="currency"
              />
            </$FormBlock>

            <$FormBlock>
              <$FormLabel htmlFor="recipientName">{t('managed.forms.recipientName')}</$FormLabel>
              <$AmountInputWrapper validationError={null}>
                <$PatchedInput
                  id="recipientName"
                  name="recipientName"
                  type="text"
                  value={this.state.recipientName}
                  onChange={this.onStringFieldChange('recipientName')}
                />
              </$AmountInputWrapper>
            </$FormBlock>

            <$FormBlock>
              <$FormLabel htmlFor="recipientAddress">
                {t('managed.forms.recipientAddress')}
              </$FormLabel>
              <$AmountInputWrapper validationError={null}>
                <$PatchedInput
                  id="recipientAddress"
                  name="recipientAddress"
                  type="text"
                  value={this.state.recipientAddress}
                  onChange={this.onStringFieldChange('recipientAddress')}
                />
              </$AmountInputWrapper>
            </$FormBlock>

            <$FormBlock>
              <$FormLabel htmlFor="accountNumber">{t('managed.forms.bankName')}</$FormLabel>
              <$AmountInputWrapper validationError={null}>
                <$PatchedInput
                  id="bankName"
                  name="bankName"
                  border="none"
                  type="text"
                  value={this.state.bankName}
                  onChange={this.onStringFieldChange('bankName')}
                />
              </$AmountInputWrapper>
            </$FormBlock>

            <$FormBlock>
              <$FormLabel htmlFor="accountNumber">{t('managed.forms.iban')}</$FormLabel>
              <$AmountInputWrapper validationError={null}>
                <$PatchedInput
                  id="accountNumber"
                  name="accountNumber"
                  border="none"
                  type="text"
                  value={this.state.accountNumber}
                  onChange={this.onStringFieldChange('accountNumber')}
                />
              </$AmountInputWrapper>
            </$FormBlock>

            <$FormBlock>
              <$FormLabel htmlFor="accountNumber">{t('managed.forms.swift')}</$FormLabel>
              <$AmountInputWrapper validationError={null}>
                <$PatchedInput
                  id="swift"
                  name="swift"
                  border="none"
                  type="text"
                  value={this.state.swift}
                  onChange={this.onStringFieldChange('swift')}
                />
              </$AmountInputWrapper>
            </$FormBlock>
            <$FormBlock>
              <$Checkbox
                uppercase={true}
                variant="light"
                name="saleTermsAccepted"
                checked={this.state.saleTermsAccepted}
                onChange={this.onCheckboxCheck}
              >
                {t('managed.forms.iAcceptSellTerms')}
              </$Checkbox>
            </$FormBlock>
            <$FormTextInfo>
              {t('managed.forms.sellEstimatedTextInfo')}{' '}
              <$Link href="" target="_blank">
                {t('managed.forms.estimatedTextClickHere')}
              </$Link>
            </$FormTextInfo>
            <$ManagedGreenButton disabled={!this.isFormReady} onClick={this.submitOrder}>
              {t('button.submit')}
            </$ManagedGreenButton>
          </$FormWrapper>
        </Modal>
      </>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    config: getManagedConfig(state),
    customer: getCustomer(state),
    rates: getRates(state),
    balances: getBalances(state),
  }),
  {
    updateManagedSellOrder,
    requestManagedSellOrder,
  },
  null,
  { forwardRef: true }
);

export default connector(SellOrderForm);
