import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { submitSourceOfFunds } from '../../../../actions/auth/customer';
import { ITranslatedError, ValidationWrapper, clientValidator } from '../../../../lib/errors';
import { intentionallyUntranslated } from '../../../../lib/i18n';
import lodash from '../../../../lib/lodash';
import { IState } from '../../../../lib/store';
import styled from '../../../../lib/styled_components';
import { typedKeys } from '../../../../lib/util';
import { getCustomer } from '../../../../selectors/auth';
import { someRequestActive } from '../../../../selectors/request_active';
import {
  IApiCustomerSourceOfFundsPayload,
  IApiPublicCustomerInfo,
  ICurrency,
  ISOFOrigin,
  ISOFSalaryRange,
} from '../../../../types/backend_definitions';
import { II18nextT } from '../../../../types/i18n';
import { ITranslations } from '../../../../types/translations';
import { $SuccessMessage } from '../../../layout/KioskLayout';
import { $InfoButton, $TransparentButton } from '../../../widgets/buttons';
import { $InputDark } from '../../../widgets/Input';
import { $AlertWrapper, $KycPanelEmphasized } from '../../../widgets/KycElements';
import $Label from '../../../widgets/Label';
import { LoaderButton } from '../../../widgets/LoaderButton';
import { ISelectOption, SimpleSelect } from '../../../widgets/Select';
import WireTransferInfoPopup, { IWireTransferFields } from '../../WireTransferInfoPopup';
import KYCFailureNotice from './KYCFailureNotice';

const SOF_ORIGINS: { [value in ISOFOrigin]: string } = {
  salary: 'Salary or savings',
  inheritance: 'Inheritance',
  business: 'Own business operations',
  other: 'Other',
};

const SOF_SALARY_RANGES: { [value in ISOFSalaryRange]: string } = {
  sr_0_20: 'Less than 20K',
  sr_20_60: '20K - 60K',
  sr_60_100: '60K - 100K',
  sr_100_500: '100K - 500K',
  sr_500_plus: 'Over 500K',
};

const $ObtainKycLevel3 = styled.div`
  text-align: center;
`;

const $ContentBox = styled($KycPanelEmphasized)`
  max-width: ${(p) => p.theme.components.kyc.containerWidth};
`;

const $Form = styled.div`
  width: 100%;
`;

const $FormInputWrapper = styled('div')<{ inline?: boolean }>`
  display: ${(p) => (p.inline ? 'flex' : 'block')};
  width: 100%;
  justify-content: ${(p) => p.inline && 'space-between'};
  padding: 0.8em 0;

  > label > input,
  > label > div {
    margin-top: 0.2em;
  }
`;
const $FormInput = styled($InputDark).attrs({ required: true })`
  height: 35px;
`;
const $TextArea = styled($InputDark).attrs({ as: 'textarea' })`
  min-height: 60px;
  max-height: 150px;
  resize: horizontal;
`;

const $PaymentInstructions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

const $ExpectedVolumeExample = styled.div`
  font-size: ${(p) => p.theme.fontSize.small};
  color: ${(p) => p.theme.colors.grayLightest};
`;

const $SOFButtons = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  > * {
    min-width: 5rem;
  }
  > * + * {
    margin-left: 1rem;
  }
`;

type IMicropaymentInstructionKinds = 'swiss' | 'international';
interface IMicropaymentInstructions extends IWireTransferFields {
  currency: ICurrency;
}

interface IObtainKycLevel3Props extends ConnectedProps<typeof connector> {}

interface IObtainKycLevel3State {
  micropaymentInstructionProps: IMicropaymentInstructions & { title: string };
  micropaymentInstructionsOpen: boolean;
  sofPayload: IApiCustomerSourceOfFundsPayload;
  validationError: ITranslatedError;
  editingSofPayload: boolean;
}

function customerToSOFPayload(customer: IApiPublicCustomerInfo) {
  return lodash.pickBy(customer || {}, (_, key) =>
    key.startsWith('sof_')
  ) as IApiCustomerSourceOfFundsPayload;
}

function hasSubmittedSOF(customer: IApiPublicCustomerInfo) {
  if (!customer) {
    return false;
  }

  return !!(
    customer.sof_profession &&
    (customer.sof_origin === 'other' ? customer.sof_origin_other : customer.sof_origin) &&
    customer.sof_expected_volume &&
    customer.sof_salary_range
  );
}

class ObtainKycLevel3 extends React.PureComponent<IObtainKycLevel3Props, IObtainKycLevel3State> {
  static contextType: any = I18nContext;

  constructor(props: IObtainKycLevel3Props) {
    super(props);

    this.state = {
      micropaymentInstructionProps: null,
      micropaymentInstructionsOpen: false,
      sofPayload: customerToSOFPayload(props.customer),
      editingSofPayload: !hasSubmittedSOF(props.customer),
      validationError: null,
    };
  }

  componentDidUpdate(prevProps: Readonly<IObtainKycLevel3Props>) {
    if (prevProps.customer !== this.props.customer) {
      this.setState({
        sofPayload: customerToSOFPayload(this.props.customer),
        editingSofPayload: !hasSubmittedSOF(this.props.customer),
      });
    }
  }

  showWireTransferInfo(kind: IMicropaymentInstructionKinds) {
    const { t }: II18nextT = this.context;

    this.setState({
      micropaymentInstructionsOpen: true,
      micropaymentInstructionProps: {
        bankName: this.props.kyc3MicropaymentSettings.bankName,
        recipientDetails: this.props.kyc3MicropaymentSettings.recipientDetails,
        amount: this.props.kyc3MicropaymentSettings.amount,
        swift: this.props.kyc3MicropaymentSettings.swift,
        referenceNumber: this.props.customer.reference_number_for_micropayments,
        ...(kind === 'swiss'
          ? {
              currency: 'CHF',
              title: t('settings:kyc.obtain3.instructionsSwiss'),
              iban: this.props.kyc3MicropaymentSettings.ibanCHF,
            }
          : {
              currency: 'EUR',
              title: t('settings:kyc.obtain3.instructionsInternational'),
              iban: this.props.kyc3MicropaymentSettings.ibanEUR,
            }),
      },
    });
  }

  submitSOF = () => {
    if (this.props.sofRequestActive) {
      return;
    }

    if (
      !clientValidator(this.state.sofPayload, this.context.t)
        .required('sof_profession')
        .required('sof_salary_range')
        .required('sof_origin')
        .required('sof_expected_volume')
        .test(
          'sof_origin_other',
          this.state.sofPayload.sof_origin !== 'other' || !!this.state.sofPayload.sof_origin_other,
          intentionallyUntranslated(
            'If you select "other" as the origin of your funds, you must provide details'
          )
        )
        .onResult((validationError) => this.setState({ validationError }))
        .isValid()
    ) {
      return;
    }

    this.props.submitSourceOfFunds(this.state.sofPayload);
  };

  renderSOFField(
    field: keyof IApiCustomerSourceOfFundsPayload,
    label: ITranslations,
    render: (field: string, setValue: (newValue) => void) => React.ReactNode
  ) {
    const { t }: II18nextT = this.context;
    const setValue = (value) =>
      this.setState({
        sofPayload: {
          ...this.state.sofPayload,
          [field]: value,
        },
      });
    return (
      <$FormInputWrapper>
        {label && (
          <$Label bold htmlFor={field}>
            {t(label)}
          </$Label>
        )}
        <ValidationWrapper error={this.state.validationError} path={field}>
          {render(field, setValue)}
        </ValidationWrapper>
      </$FormInputWrapper>
    );
  }

  sofSalaryRangeOptions: Array<ISelectOption<ISOFSalaryRange>> = typedKeys(
    SOF_SALARY_RANGES
  ).map((value) => ({ value, label: SOF_SALARY_RANGES[value] }));

  sofOriginOptions: Array<ISelectOption<ISOFOrigin>> = typedKeys(SOF_ORIGINS).map((value) => ({
    value,
    label: SOF_ORIGINS[value],
  }));

  renderSOFForm() {
    const { t }: II18nextT = this.context;
    return (
      <>
        {!hasSubmittedSOF(this.props.customer) && (
          <p style={{ marginTop: 0 }}>
            <strong>
              {t(
                intentionallyUntranslated(
                  `To proceed, please fill the form below as accurately as you can.`
                )
              )}
            </strong>
          </p>
        )}
        <$Form>
          {this.renderSOFField(
            'sof_profession',
            intentionallyUntranslated('Profession'),
            (field, setValue) => (
              <$FormInput
                type="text"
                id={field}
                name={field}
                value={this.state.sofPayload.sof_profession}
                onChange={(e) => setValue(e.currentTarget.value)}
                readOnly={!this.state.editingSofPayload}
              />
            )
          )}
          {this.renderSOFField(
            'sof_salary_range',
            intentionallyUntranslated('Salary range'),
            (field, setValue) => (
              <SimpleSelect
                inputId={field}
                value={
                  this.sofSalaryRangeOptions.find(
                    (option) => option.value === this.state.sofPayload.sof_salary_range
                  ) as any
                }
                options={this.sofSalaryRangeOptions}
                onChange={(option) => setValue(option.value)}
                variant={'dark'}
                disabled={!this.state.editingSofPayload}
              />
            )
          )}
          {this.renderSOFField(
            'sof_origin',
            intentionallyUntranslated('Origin of funds'),
            (field, setValue) => (
              <SimpleSelect
                inputId={field}
                value={
                  this.sofOriginOptions.find(
                    (option) => option.value === this.state.sofPayload.sof_origin
                  ) as any
                }
                options={this.sofOriginOptions}
                onChange={(option) => setValue(option.value)}
                variant={'dark'}
                disabled={!this.state.editingSofPayload}
              />
            )
          )}
          {this.state.sofPayload.sof_origin === 'other' &&
            this.renderSOFField('sof_origin_other', null, (field, setValue) => (
              <$TextArea
                id={field}
                name={field}
                value={this.state.sofPayload.sof_origin_other}
                onChange={(e) => setValue(e.currentTarget.value)}
                readOnly={!this.state.editingSofPayload}
              />
            ))}
          {this.renderSOFField(
            'sof_expected_volume',
            intentionallyUntranslated('Expected transaction volume and frequency'),
            (field, setValue) => (
              <>
                <$TextArea
                  id={field}
                  name={field}
                  value={this.state.sofPayload.sof_expected_volume}
                  onChange={(e) => setValue(e.currentTarget.value)}
                  readOnly={!this.state.editingSofPayload}
                />
                <$ExpectedVolumeExample>
                  {t(
                    intentionallyUntranslated(
                      'For example: "2-5 transfers per month, up to 10K total" or "one-off 50K deposit"'
                    )
                  )}
                </$ExpectedVolumeExample>
              </>
            )
          )}
          <$SOFButtons>
            {this.state.editingSofPayload === false ? (
              <$TransparentButton
                onClick={() =>
                  this.setState({
                    editingSofPayload: true,
                  })
                }
              >
                {t(intentionallyUntranslated('Change details'))}
              </$TransparentButton>
            ) : (
              <>
                <LoaderButton
                  onClick={() => this.submitSOF()}
                  loading={this.props.sofRequestActive}
                >
                  {t(
                    intentionallyUntranslated(
                      hasSubmittedSOF(this.props.customer) ? `Save changes` : `Submit statement`
                    )
                  )}
                </LoaderButton>
                {hasSubmittedSOF(this.props.customer) && (
                  <$TransparentButton
                    backgroundColor="brandSecondary"
                    color="white"
                    onClick={() =>
                      this.setState({
                        sofPayload: customerToSOFPayload(this.props.customer),
                        editingSofPayload: false,
                      })
                    }
                  >
                    {t(intentionallyUntranslated('Cancel'))}
                  </$TransparentButton>
                )}
              </>
            )}
          </$SOFButtons>
        </$Form>
      </>
    );
  }

  renderInstructions() {
    const { t }: II18nextT = this.context;
    if (!hasSubmittedSOF(this.props.customer)) {
      return null;
    }
    return (
      <>
        <p>{t(intentionallyUntranslated(`The financial statement has been submitted.`))}</p>
        <div>
          {t(
            intentionallyUntranslated(
              `Please proceed with sending us a micropayment wire transfer. ` +
                `It must be made from your personal account, the same account you will later use for Managed buy orders.`
            )
          )}
        </div>
        <p style={{ marginTop: '2rem' }}>
          {t(
            intentionallyUntranslated(
              `Click one of the buttons below for the wire transfer instructions:`
            )
          )}
        </p>
        <$PaymentInstructions>
          <$InfoButton
            disabled={this.state.editingSofPayload}
            backgroundColor="brandPrimary"
            color="white"
            onClick={() => this.showWireTransferInfo('swiss')}
          >
            {t('settings:kyc.obtain3.requestInstructionsButtonSwiss')}
          </$InfoButton>
          <$InfoButton
            disabled={this.state.editingSofPayload}
            backgroundColor="brandPrimary"
            color="white"
            onClick={() => this.showWireTransferInfo('international')}
          >
            {t('settings:kyc.obtain3.requestInstructionsButtonInternational')}
          </$InfoButton>
        </$PaymentInstructions>
        {this.state.micropaymentInstructionProps && (
          <WireTransferInfoPopup
            {...this.state.micropaymentInstructionProps}
            isOpen={this.state.micropaymentInstructionsOpen}
            onClose={() => this.setState({ micropaymentInstructionsOpen: false })}
          />
        )}
      </>
    );
  }

  renderContent() {
    const { t }: II18nextT = this.context;

    let refundMessage;
    let failureNotice;

    if (this.props.customer.kyc3_micropayment) {
      refundMessage = this.props.customer.kyc3_micropayment.refunded_at
        ? t('settings:kyc.obtain3.refundCompletedMessage')
        : t('settings:kyc.obtain3.refundPendingMessage');

      if (this.props.customer.kyc3_micropayment.approved) {
        // Approved
        return (
          <$AlertWrapper>
            <$SuccessMessage>
              <p>{t('settings:kyc.obtain3.grantedMessage')}</p>
            </$SuccessMessage>
            <div>{refundMessage}</div>
          </$AlertWrapper>
        );
      }

      if (this.props.customer.kyc3_micropayment.approved !== false) {
        // Auditing
        return (
          <$AlertWrapper>
            <$SuccessMessage>
              <p>
                {t(
                  'settings:kyc.obtain3.pendingIntroMessage',
                  this.props.customer.kyc3_micropayment
                )}
              </p>
              <p>
                {t('settings:kyc.obtain3.pendingTransactionIdLabel')}
                <br />
                <strong>
                  <code key={1}>{this.props.customer.kyc3_micropayment.transaction_id}</code>
                </strong>
              </p>
              <p>{t('settings:kyc.obtain3.pendingOutroMessage')}</p>
            </$SuccessMessage>
          </$AlertWrapper>
        );
      }

      // Failed
      failureNotice = (
        <KYCFailureNotice
          title={t('settings:kyc.obtain3.rejectedTitle')}
          message={
            this.props.customer.kyc3_micropayment.failure_message ||
            t('settings:kyc.obtain3.rejectedGenericFailureMessage')
          }
          footer={refundMessage}
        />
      );
    }

    return (
      <>
        {failureNotice}
        <$ContentBox>
          <div style={{ marginBottom: '2rem' }}>
            {t(
              intentionallyUntranslated(
                `To complete your Managed account verification, you must supply a statement about the ` +
                  `source of the funds you intend to deposit, ` +
                  `then send us a small wire transfer to establish your bank credentials.`
              )
            )}
          </div>
          {this.renderSOFForm()}
          {this.renderInstructions()}
        </$ContentBox>
      </>
    );
  }

  render() {
    return <$ObtainKycLevel3>{this.renderContent()}</$ObtainKycLevel3>;
  }
}

const connector = connect(
  (state: IState) => {
    const customer = getCustomer(state);
    return {
      customer,
      kyc3MicropaymentSettings: state.env.kyc3Micropayment,
      sofRequestActive: someRequestActive(['submitSourceOfFunds'], state),
    };
  },
  {
    submitSourceOfFunds,
  }
);

export default connector(ObtainKycLevel3);
