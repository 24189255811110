import React from 'react';

import { TimeUnits } from '../../types/constants';

export class TextualSecondCountdown extends React.Component<
  { seconds: number; onCompleted?: () => void },
  { secondsLeft: number }
> {
  private interval;
  private primedAtTimestamp: number;

  constructor(props) {
    super(props);

    this.state = {
      secondsLeft: null,
    };
  }

  componentDidMount() {
    this.reset();
  }

  componentDidUpdate(
    prevProps: Readonly<{ seconds: number; onCompleted?: () => void }>,
    prevState: Readonly<{ secondsLeft: number }>,
    snapshot?: any
  ) {
    if (prevProps.seconds !== this.props.seconds) {
      this.reset();
    }
  }

  reset() {
    clearInterval(this.interval);
    this.primedAtTimestamp = Date.now();
    this.setState({
      secondsLeft: this.props.seconds,
    });

    this.interval = setInterval(() => {
      const elapsedSeconds = Math.floor((Date.now() - this.primedAtTimestamp) / TimeUnits.second);
      const secondsLeft = this.props.seconds - elapsedSeconds;
      if (secondsLeft !== this.state.secondsLeft) {
        this.setState({ secondsLeft });
        if (secondsLeft === 0 && this.props.onCompleted) {
          this.props.onCompleted();
          clearInterval(this.interval);
        }
      }
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return this.state.secondsLeft;
  }
}
