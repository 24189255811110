import { LinkProps } from 'react-router-dom';

import { ICustomHref } from '../../lib/history';
import styled, { IThemeColorsUnion } from '../../lib/styled_components';
import { shadeColor } from '../../lib/util';
import { Omit } from '../../types/typescript_helpers';

interface ILinkProps extends Partial<Omit<LinkProps, 'to'>> {
  underlined?: string;
  type?: 'button' | 'link';
  color?: IThemeColorsUnion;
  hovercolor?: string;
  to?: ICustomHref;
}

const $Link = styled('a')<ILinkProps>`
  text-decoration: ${(p) => (p.underlined === 'true' ? 'underline' : 'none')};
  align-items: center;
  color: ${(p) =>
    p.color
      ? p.theme.colors[p.color]
      : p.type === 'link'
      ? p.theme.base.linkColor
      : p.theme.base.color};

  &:focus,
  &:visited,
  &:link {
    text-decoration: ${(p) => (p.underlined ? 'underline' : 'none')};
    color: ${(p) =>
      p.color
        ? p.theme.colors[p.color]
        : p.type === 'link'
        ? p.theme.base.linkColor
        : p.theme.base.color};
  }

  &:hover {
    text-decoration: ${(p) => (p.underlined ? 'underline' : 'none')};
    color: ${(p) =>
      p.hovercolor
        ? p.theme.colors[p.hovercolor] + ' !important'
        : p.type === 'link'
        ? shadeColor(p.theme.base.linkColor, -0.15)
        : shadeColor(p.theme.base.color, -0.15)};
  }
  &:active {
    text-decoration: ${(p) => (p.underlined ? 'underline' : 'none')};
    color: ${(p) => p.theme.colors[p.hovercolor] + ' !important'};
  }
`;

export default $Link;

export const $ExternalInfoLink = styled($Link).attrs((p) => ({
  ...p,
  target: '_blank',
  color: 'info',
}))``;
