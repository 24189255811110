import { tradeResponseTransformation } from '../../lib/api_response_transformations';
import { handleError } from '../../lib/errors';
import lodash from '../../lib/lodash';
import { getNextPageCriteria, mergePaginatedResults } from '../../lib/redux_helpers';
import { commit, val } from '../../lib/redux_helpers';
import { IState, IStateGetter, IThunkMethod } from '../../lib/store';
import { getUser, isUserLoggedIn } from '../../selectors/auth';
import { getCurrentPairPath } from '../../selectors/exchange';
import { getUserTrades } from '../../selectors/trades';
import { IPaginated } from '../../types/api';
import { IApiPublicTradeInfo, IPair } from '../../types/backend_definitions';
import { IApiPublicTradeInfosCriteria } from '../../types/backend_definitions';
import { ITrade } from '../../types/trades';
import { loadingWrapper } from '../request_active';

const DEFAULT_PAGE_SIZE = 25;

// STATE TYPES
export interface IUserTradesState {
  userTradesCurrentInstrumentPair: ITrade[];
  userTrades: IPaginated<ITrade>;
}

// STATE
export const USER_TRADES_STATE: IUserTradesState = {
  userTradesCurrentInstrumentPair: [],
  userTrades: {
    items: [],
    page: 1,
    page_size: DEFAULT_PAGE_SIZE,
    total_records: 0,
    total_pages: 0,
  },
};

const USER_TRADES_COUNT_LIMIT = 100;

let pendingTrades: ITrade[] = [];
const addUserTradeThrottled = lodash.throttle((dispatch, getState: IStateGetter) => {
  if (!pendingTrades.length) {
    return;
  }

  const currentPair = getCurrentPairPath(getState());

  const seenSignatures = {};
  const oldList = getState().userTradesCurrentInstrumentPair;
  const newList = [];

  for (const trade of pendingTrades) {
    if (trade.pair === currentPair && !seenSignatures[trade.signature]) {
      newList.push(trade);
      seenSignatures[trade.signature] = true;
    }
  }
  const addedCount = newList.length;
  if (!addedCount) {
    // No need to update
    return;
  }

  for (let i = 0; i < oldList.length && newList.length < USER_TRADES_COUNT_LIMIT; i++) {
    // NOTE: We are not checking for current pair because ajax load will take care of that
    const trade = oldList[i];
    if (!seenSignatures[trade.signature]) {
      newList.push(trade);
      seenSignatures[trade.signature] = true;
    }
  }

  pendingTrades = [];

  dispatch(
    commit(`Added ${addedCount} user trades from socket (throttled)`, {
      userTradesCurrentInstrumentPair: val<IState['userTradesCurrentInstrumentPair']>(newList),
    })
  );
}, 500);

export const addUserTrade = (newTrade: ITrade) => (dispatch, getState: IStateGetter) => {
  if (!getUser(getState())) {
    return;
  }

  pendingTrades.unshift(newTrade);
  addUserTradeThrottled(dispatch, getState);
};

export const submitUserTradesForCurrentInstrumentPair = (
  instrumentPair: IPair,
  userTrades: IApiPublicTradeInfo[]
) => (dispatch) => {
  const userTradesFormatted = lodash.orderBy(
    userTrades.map(tradeResponseTransformation),
    ['timestamp', 'price'],
    ['desc', 'desc']
  );

  dispatch(
    commit(`List of user trades for ${instrumentPair} is updated`, {
      userTradesCurrentInstrumentPair: val<IState['userTradesCurrentInstrumentPair']>(
        userTradesFormatted
      ),
    })
  );
};

export const resetUserTrades = () => (dispatch) => {
  dispatch(
    commit(`Reset user trades state`, {
      userTradesCurrentInstrumentPair: val<IState['userTradesCurrentInstrumentPair']>(
        USER_TRADES_STATE.userTradesCurrentInstrumentPair
      ),
      userTrades: val<IState['userTrades']>(USER_TRADES_STATE.userTrades),
    })
  );
};

// all instruments
export const loadUserTrades = (pair: IPair, firstPage = false): IThunkMethod<Promise<void>> => (
  dispatch,
  getState,
  { api }
) => {
  const state = getState();
  const oldTrades = getUserTrades(state);
  const nextPageCriteria = getNextPageCriteria(oldTrades, DEFAULT_PAGE_SIZE, firstPage);

  const apiCriteria = {
    ...nextPageCriteria,
    sort_field: ['sort_index'],
    sort_direction: 'desc',
  } as IApiPublicTradeInfosCriteria;

  if (pair) {
    apiCriteria.pair = [pair];
  }

  return dispatch(
    loadingWrapper(
      'getUserTrades',
      api
        .getExchangeTrades(apiCriteria)
        .then((newTrades) => {
          if (!isUserLoggedIn(state)) {
            return;
          }

          const mergedTrades = mergePaginatedResults<IApiPublicTradeInfo, ITrade>(
            newTrades,
            firstPage ? ({ items: [] } as IPaginated<ITrade>) : oldTrades,
            'signature',
            tradeResponseTransformation
          );

          dispatch(
            commit('List of user trades is updated', {
              userTrades: val<IState['userTrades']>(mergedTrades),
            })
          );
        })
        .catch((err) => dispatch(handleError(err)))
    )
  );
};
