import React from 'react';

import styled, { zIndex } from '../../lib/styled_components';

const $FullScreenOverlay = styled.div<{ fade: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.overlay};

  background: ${(p) => p.theme.base.bodyBackground} url(${(p) => p.theme.base.bodyBackgroundImage})
    no-repeat center center fixed;
  background-size: cover;

  ${(p) =>
    p.fade &&
    `
    opacity: 0;
    animation: fadein 0.3s ease-in forwards;
  
    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `}
`;

export const FullScreenOverlay: React.FunctionComponent<{ fade?: boolean }> = ({
  fade,
  children,
}) => {
  return <$FullScreenOverlay fade={fade || false}>{children}</$FullScreenOverlay>;
};
