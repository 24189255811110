import styled, { IThemeColorsUnion } from '../../lib/styled_components';

interface IProps {
  direction: 'left' | 'right' | 'up' | 'down';
  size: string;
  color: IThemeColorsUnion;
}

const Arrow = styled.div<IProps>`
  width: 0;
  height: 0;
  border-style: solid;

  ${(p) => {
    switch (p.direction) {
      case 'left':
        return `
          border-width: ${p.size} ${p.size} ${p.size} 0;
          border-color: transparent ${p.theme.colors[p.color]} transparent transparent;
        `;
      case 'right':
        return `
          border-width: ${p.size} 0 ${p.size} ${p.size};
          border-color: transparent transparent transparent ${p.theme.colors[p.color]};
        `;
      case 'up':
        return `
          border-width: 0 ${p.size} ${p.size} ${p.size};
          border-color: transparent transparent ${p.theme.colors[p.color]} transparent;
        `;
      case 'down':
        return `
          border-width: ${p.size} ${p.size} 0 ${p.size};
          border-color: ${p.theme.colors[p.color]} transparent transparent transparent;
        `;
      default:
        return '';
    }
  }}
`;

export default Arrow;
