import { orderResponseTransformation } from '../../lib/api_response_transformations';
import Container from '../../lib/container';
import { handleError } from '../../lib/errors';
import { commit, getNextPageCriteria, val } from '../../lib/redux_helpers';
import { IState, IStateGetter } from '../../lib/store';
import { getUserOrderNotificationsSettings } from '../../selectors/auth';
import { getActiveOrdersPaginationState } from '../../selectors/orders';
import { IPaginated } from '../../types/api';
import {
  IApiCensoredOrder,
  IApiQueryResultForCensoredOrder,
  IOrderSide,
  IPair,
} from '../../types/backend_definitions';
import { IOrder } from '../../types/orders';
import { Omit } from '../../types/typescript_helpers';
import { getSimpleNotificationData, notifySuccess } from '../app';
import { loadingWrapper } from '../request_active';

const DEFAULT_PAGE_SIZE = 25;

export interface IOrderState {
  order: IOrder;
  forCancellation?: Boolean;
}

export interface IOrdersStateById {
  [id: string]: IOrderState;
}

export interface IOrdersPaginationState extends Omit<IPaginated<IApiCensoredOrder>, 'items'> {
  pair: IPair;
}

export interface IOrdersState {
  ordersById: IOrdersStateById;
  ordersPagination: IOrdersPaginationState;
}

// STATE
export const ORDERS_STATE: IOrdersState = {
  ordersById: {},
  ordersPagination: {
    pair: null,
    page: 1,
    page_size: DEFAULT_PAGE_SIZE,
    total_pages: 0,
    total_records: 0,
  },
};

const derivePaginationState = (
  data: IPaginated<any>,
  pair: IPair = null
): IOrdersPaginationState => {
  const dataIsPaginated = data.page_size;
  return {
    pair,
    page: dataIsPaginated ? data.page : null,
    page_size: dataIsPaginated ? data.page_size : null,
    total_pages: dataIsPaginated ? data.total_pages : null,
    total_records: dataIsPaginated ? data.total_records : null,
  };
};

/**
 * Update order, for example after a partial match. Order won't be added if it doesn't already exists.
 */
export const updateOrder = (updatedOrder: IOrder) => (dispatch, getState: IStateGetter) => {
  const exists = getState().ordersById[updatedOrder.id];
  if (!exists) {
    // Order has been removed
    return;
  }

  return dispatch(
    commit(`Update order ${updatedOrder.id}`, {
      ordersById: {
        [updatedOrder.id]: {
          order: val<IOrder>(updatedOrder),
        },
      },
    })
  );
};

/**
 * Update total records of orders after cancelOrder or submitOrder.
 */
export const updateTotalRecordsOfOrders = (change: 1 | -1) => (
  dispatch,
  getState: IStateGetter
) => {
  const ordersPagination = getState().ordersPagination;
  const updatedOrdersPagination = {
    ...ordersPagination,
    total_records: ordersPagination.total_records + change,
    total_pages: Math.ceil((ordersPagination.total_records + change) / ordersPagination.page_size),
  };
  const msgStart = change === 1 ? 'Increment' : 'Decrement';

  dispatch(
    commit(`${msgStart} total records of orders by ${change}`, {
      ordersPagination: val(updatedOrdersPagination),
    })
  );
};

export const resetUserOrders = () => (dispatch) => {
  dispatch(
    commit(`Reset user orders state`, {
      ordersById: val<IState['ordersById']>({}),
      ordersPagination: val<IState['ordersPagination']>(ORDERS_STATE.ordersPagination),
    })
  );
};

export const submitActiveUserOrdersForInstrumentPair = (
  pair: IPair,
  userOrders: IApiQueryResultForCensoredOrder,
  firstPage: boolean
) => (dispatch, getState: IStateGetter) => {
  const ordersById: IOrdersStateById = {};
  const newPaginationState = derivePaginationState(userOrders, pair);

  for (const order of userOrders.items) {
    ordersById[order.id] = {
      order: orderResponseTransformation(order),
    };
  }

  if (!firstPage) {
    const existingOrdersById = getState().ordersById;
    for (const existingId in existingOrdersById) {
      if (existingOrdersById[existingId].order.pair === pair && !ordersById[existingId]) {
        ordersById[existingId] = { order: existingOrdersById[existingId].order };
      }
    }
  }

  return dispatch(
    commit(`Load all user active orders for instrument pair ${pair}`, {
      ordersById: val(ordersById),
      ordersPagination: val(newPaginationState),
    })
  );
};

export const loadActiveUserOrdersForInstrumentPair = (instrumentPair: IPair, firstPage = false) => (
  dispatch,
  getState: IStateGetter,
  { api }: Container
): Promise<IApiQueryResultForCensoredOrder> => {
  const state = getState();
  const paginationState = getActiveOrdersPaginationState(state);
  const nextPageCriteria = getNextPageCriteria(paginationState, DEFAULT_PAGE_SIZE, firstPage);
  return dispatch(
    loadingWrapper(
      'getActiveUserOrdersForInstrumentPair',
      api
        .getExchangeOrders({ pair: instrumentPair, ...nextPageCriteria })
        .then((response) => {
          dispatch(submitActiveUserOrdersForInstrumentPair(instrumentPair, response, firstPage));
        })
        .catch((err) => dispatch(handleError(err)))
    )
  );
};

export const loadActiveUserOrdersForAllInstrumentPairs = (firstPage = false) => (
  dispatch,
  getState: IStateGetter,
  { api }: Container
) => {
  const state = getState();
  const paginationState = getActiveOrdersPaginationState(state);
  const nextPageCriteria = getNextPageCriteria(paginationState, DEFAULT_PAGE_SIZE, firstPage);

  return dispatch(
    loadingWrapper(
      'getActiveUserOrdersForAllInstrumentPairs',
      api
        .getExchangeOrders({ ...nextPageCriteria })
        .then((response) => {
          // NOTE: Since we know we are loading everything from scratch, we can safely remove all existing data and start fresh
          const ordersById: IOrdersStateById = {};
          const newPaginationState = derivePaginationState(response);

          for (const order of response.items) {
            ordersById[order.id] = {
              order: orderResponseTransformation(order),
            };
          }

          if (!firstPage) {
            const existingOrdersById = getState().ordersById;
            for (const existingId in existingOrdersById) {
              if (!ordersById[existingId]) {
                ordersById[existingId] = { order: existingOrdersById[existingId].order };
              }
            }
          }

          return dispatch(
            commit(`Load all user active orders`, {
              ordersById: val(ordersById),
              ordersPagination: val(newPaginationState),
            })
          );
        })
        .catch((err) => dispatch(handleError(err)))
    )
  );
};

export const notifyCompletedOrder = (
  type: 'partial' | 'full' | 'stop',
  side: IOrderSide,
  pair: IPair
) => (dispatch, getState: IStateGetter, { i18n }: Container) => {
  const orderNotificationsEnabled = getUserOrderNotificationsSettings(getState());

  if (orderNotificationsEnabled) {
    let message: string;

    switch (type) {
      case 'full':
        message = i18n.t('orders.orderCompleted', { side, pair });
        break;
      case 'partial':
        message = i18n.t('orders.orderPartiallyCompleted', { side, pair });
        break;
      case 'stop':
        message = i18n.t('orders.orderStopCompleted', { side, pair });
        break;
    }

    dispatch(notifySuccess(getSimpleNotificationData(message, side)));
  }
};
