import styled from '../../lib/styled_components';

export interface ILabelProps {
  bold?: boolean;
}

export const $Label = styled('label')<ILabelProps>`
  color: white;
  cursor: pointer;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: ${(p) => p.bold && 'bold'};
`;

export const $ToolbarLabel = styled.label`
  margin-right: 0.8rem;
  font-weight: bold;
  display: inline;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
`;

export default $Label;
