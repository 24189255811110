import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { IState } from '../../../../lib/store';
import styled from '../../../../lib/styled_components';
import { IDLE_TIME_COUNTDOWN, TimeUnits } from '../../../../types/constants';
import { II18nextT } from '../../../../types/i18n';
import KioskLayout from '../../../layout/KioskLayout';
import Modal from '../../../widgets/Modal';
import Timer from '../../../widgets/Timer';

export const $Message = styled.p`
  font-size: ${(p) => p.theme.fontSize.larger};
`;

export const $StatusMessage = styled.div`
  text-align: center;
  font-weight: bold;
  padding: 1.5rem 0;
  font-size: 2rem;
`;

const $ContentWrapper = styled.div`
  max-width: 40rem;
  text-align: center;
`;

interface ISessionIdleTimeoutCountdownScreenProps extends ConnectedProps<typeof connector> {}

interface ISessionIdleTimeoutCountdownScreenState {}

class SessionIdleTimeoutCountdownScreen extends React.PureComponent<
  ISessionIdleTimeoutCountdownScreenProps,
  ISessionIdleTimeoutCountdownScreenState
> {
  static contextType: any = I18nContext;
  private modalRef: React.RefObject<Modal> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(): void {
    // When user becomes inactive
    if (this.props.idleTimeoutCountdownActive && !this.modalRef.current.isOpen) {
      this.modalRef.current.show();
    }

    // When user becomes active
    if (!this.props.idleTimeoutCountdownActive && this.modalRef.current.isOpen) {
      this.modalRef.current.hide();
    }
  }

  handleCountdownEnd = () => {
    // External service will do the cleanup, we will just go away
    this.modalRef.current.hide();
  };

  render() {
    const { t }: II18nextT = this.context;
    const count = Math.floor(IDLE_TIME_COUNTDOWN / TimeUnits.minute);
    return (
      <Modal ref={this.modalRef} title={t('sessionInactiveTimeout.modalTitle')} withoutCloseButton>
        <KioskLayout>
          <$ContentWrapper>
            <$StatusMessage>{t('sessionInactiveTimeout.title')}</$StatusMessage>
            <Timer
              time={IDLE_TIME_COUNTDOWN}
              radius={60}
              handleCountdownEnd={this.handleCountdownEnd}
            />
            <$Message>
              {count === 1
                ? t('sessionInactiveTimeout.securityReason')
                : t('sessionInactiveTimeout.securityReason_plural', {
                    count,
                  })}
            </$Message>
            <$Message>{t('sessionInactiveTimeout.continueSession')}</$Message>
          </$ContentWrapper>
        </KioskLayout>
      </Modal>
    );
  }
}

const connector = connect((state: IState) => ({
  idleTimeoutCountdownActive: state.idleTimeoutCountdownActive,
}));

export default connector(SessionIdleTimeoutCountdownScreen);
