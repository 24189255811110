import * as React from 'react';

import { ICurrencyDisplay, localizeNumberString } from '../../bl/localized_numbers';
import styled, { IThemeColorsUnion } from '../../lib/styled_components';
import { abbreviateNumber } from '../../lib/util';
import { ICurrency } from '../../types/backend_definitions';

type IBPSize = 'medium' | 'superlarge';

const $BinaryPrefixDecimals = styled.span<{
  bold?: boolean;
  wordBreak?: boolean;
  color?: IThemeColorsUnion;
  decimalColor?: IThemeColorsUnion;
  symbolColor?: IThemeColorsUnion;
  size?: IBPSize;
}>`
  // We can set different colors of decimal and binary prefix symbol, via decimalColor and symbolColor prop
  ${(p) => p.bold && `font-weight: bold;`}
  ${(p) => p.wordBreak && `word-break: break-all;`}
  ${(p) => p.size && `font-size: ${p.size === 'superlarge' ? '3rem' : '1.6rem'};`}
  >span:first-child {
    color: ${(p) =>
      p.decimalColor
        ? p.theme.colors[p.decimalColor]
        : p.color
        ? p.theme.colors[p.color]
        : 'white'};
  }

  > span:last-child {
    color: ${(p) => (p.symbolColor ? p.theme.colors[p.symbolColor] : 'white')};

    display: inline-block;
  }
`;

interface IBinaryPrefixDecimalsProps {
  value: number | string;
  bold?: boolean;
  size?: IBPSize;
  int?: boolean;
  decimals?: number;
  currency?: ICurrency;
  currencyDisplay?: ICurrencyDisplay;
}

const BinaryPrefixDecimals: React.FC<IBinaryPrefixDecimalsProps> = ({
  value,
  bold,
  size,
  int,
  decimals,
  currency,
  currencyDisplay,
}) => {
  if (typeof value === 'string') {
    value = Number(value);
  }

  if (!Number.isFinite(value)) {
    return null;
  }

  const abbreviated = abbreviateNumber(value);

  const localized = localizeNumberString(value, {
    decimals: int ? 0 : decimals,
    currency,
    currencyDisplay,
  });

  return (
    <$BinaryPrefixDecimals {...{ bold, size }}>
      <span title={`${localized}`}>{abbreviated === value ? localized : abbreviated}</span>
    </$BinaryPrefixDecimals>
  );
};

export default BinaryPrefixDecimals;
