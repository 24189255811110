import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { IAccountAlert, getAccountAlert } from '../../../bl/account_alert';
import {
  requestVerificationEmail,
  submitEmailVerificationCode,
} from '../../../bl/email_verification';
import {
  clearPhoneVerificationState,
  openPhoneVerificationModal,
  setCustomerPhone,
} from '../../../bl/phone_verification';
import { US_STATES } from '../../../lib/countries';
import {
  $ValidationWrapper,
  ITranslatedError,
  ValidationWrapper,
  clientValidator,
} from '../../../lib/errors';
import { I18n, Trans } from '../../../lib/i18n';
import R from '../../../lib/routes';
import { IState } from '../../../lib/store';
import styled, { IThemeColorsUnion } from '../../../lib/styled_components';
import {
  AccountAlertIndicatorImage,
  EmailVerificationIndicatorImage,
} from '../../../media/svg_icons';
import { getCustomer, getUser } from '../../../selectors/auth';
import { PHONE_NUMBER_E164_EXPLANATION_URL } from '../../../types/constants';
import { $Button, $InfoButton } from '../../widgets/buttons';
import { $CodeInput } from '../../widgets/CodeInput';
import IndicatorIcon, {
  IIndicatorIconIndication,
} from '../../widgets/indicator_icons/IndicatorIcon';
import KYCLevelIndicatorIcon from '../../widgets/indicator_icons/KYCLevelIndicatorIcon';
import { $InputDark } from '../../widgets/Input';
import { $ExternalInfoLink } from '../../widgets/Link';
import { LoaderButton } from '../../widgets/LoaderButton';
import LoadingAnimation from '../../widgets/LoadingAnimation';
import { $MenuGadgetHeader, $MenuGadgetHeaderTitle } from './menu_gadgets_common';

const CODE_LENGTH = 6;

const $AccountGadget = styled.div<{ roundedBottom?: boolean }>`
  ${(p) => p.roundedBottom && 'border-radius: 0 0 6px 6px;'}
`;

const $AccountLevelIndicators = styled.div`
  display: grid;
  grid-template-columns: 35px 30px 30px 30px;
  grid-column-gap: 5px;
  position: relative;
`;

const $AdditionalInfoArrow = styled.div<{ position: number }>`
  grid-column: ${(p) => p.position};
  position: relative;
  height: 0;
  bottom: 11px; // Approximate method :)

  &:after {
    content: ' ';
    position: absolute;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${(p) => p.theme.base.bodyBackground} transparent;
    left: 50%;
    margin-left: -10px;
  }
`;

const $AdditionalInfo = styled.div`
  background-color: ${(p) => p.theme.base.bodyBackground};
  padding: 1.5rem;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
`;

const $AdditionalInfoBody = styled.div`
  grid-column: 2;
  grid-row: 1;

  > p:first-child {
    margin-top: 0;
  }
  > p:last-child {
    margin-bottom: 0;
  }
`;

const $AdditionalInfoActions = styled.div`
  grid-column: 2;
  grid-row: 2;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-top: 1.5rem;

  > * {
    flex-grow: 1;
    flex-basis: 0; // Equalize all widths
  }
`;

const $AdditionalInfoActionsGap = styled.div`
  width: 0.5rem;
  flex-basis: 0.5rem;
  flex-grow: 0;
`;

const $AdditionalInfoIcon = styled.div`
  grid-column: 1;
  grid-row: 1;
  width: 4rem;
  margin-right: 1rem;
`;

const $PhoneNumberForm = styled.form`
  display: flex;
  flex-direction: row;

  > div {
    position: relative;
    flex-grow: 1;
  }
  > div input {
    height: 100%;
  }
  > div + button {
    margin-left: 0.5rem;
  }

  // TODO: Once we add real floating validation, get rid of this
  ${$ValidationWrapper} {
    position: absolute;
    width: 100%;
    z-index: 1;
  }
`;

const $EmailCodeWrapper = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

interface IAccountGadgetProps extends RouteComponentProps, ConnectedProps<typeof connector> {
  roundedTop?: boolean;
  roundedBottom?: boolean;
}

interface IAccountGadgetState {
  submitingEmailVerificationCode: boolean;
  sendingVerificationEmail: boolean;
  phone: string;
  phoneValidationError: ITranslatedError;
  emailVerificationCode: string;
  emailVerificationCodeError: ITranslatedError;
}

class AccountGadget extends React.PureComponent<IAccountGadgetProps, IAccountGadgetState> {
  private mounted = false;
  static contextType: any = I18nContext;
  private phoneVerificationId: number;

  constructor(props) {
    super(props);

    this.state = {
      submitingEmailVerificationCode: false,
      sendingVerificationEmail: false,

      phone: '',
      phoneValidationError: null,
      emailVerificationCode: '',
      emailVerificationCodeError: null,
    };
  }

  componentDidMount() {
    this.mounted = true;

    if (this.props.customer) {
      this.setState({
        phone: this.props.customer.phone || '',
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IAccountGadgetProps>,
    prevState: Readonly<IAccountGadgetState>,
    snapshot?: any
  ) {
    if (
      this.props.customer &&
      (!prevProps.customer || prevProps.customer.phone !== this.props.customer.phone)
    ) {
      // Reinitialize phone number
      this.setState({
        phone: this.props.customer.phone || '',
      });
    }

    if (
      this.props.phoneVerificationState &&
      this.props.phoneVerificationState.result &&
      this.props.phoneVerificationState.id === this.phoneVerificationId
    ) {
      // This is our callback. Clear the result and set customer's phone number
      this.props.clearPhoneVerificationState();
      this.props.setCustomerPhone(this.state.phone);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  resendVerificationEmail = () => {
    if (this.state.sendingVerificationEmail) {
      return;
    }
    this.setState({
      sendingVerificationEmail: true,
    });
    this.props.requestVerificationEmail(true).finally(() => {
      this.mounted &&
        this.setState({
          sendingVerificationEmail: false,
        });
    });
  };

  submitVerificationCode = () => {
    if (this.state.submitingEmailVerificationCode) {
      return;
    }

    this.setState({
      submitingEmailVerificationCode: true,
    });

    this.props
      .submitEmailVerificationCode({ code: this.state.emailVerificationCode })
      .finally(() => {
        this.mounted &&
          this.setState({
            submitingEmailVerificationCode: false,
          });
      });
  };

  renderSuspendedInfo = () => {
    return (
      <I18n>
        {(t) => (
          <$AdditionalInfo>
            <$AdditionalInfoIcon>
              <IndicatorIcon IndicatorImage={AccountAlertIndicatorImage} indication={'warning'} />
            </$AdditionalInfoIcon>
            <$AdditionalInfoBody>
              <p>{t('accountGadget.suspended.whatIsTheIssue')}</p>
              <p>{t('accountGadget.suspended.explanationAboutCurrentRightsAndRestrictions')}</p>
              <p>
                <Trans
                  i18nKey={'accountGadget.suspended.contactSupportCTA'}
                  components={[
                    <$ExternalInfoLink key={0} href={this.props.supportLink}>
                      ___
                    </$ExternalInfoLink>,
                  ]}
                />
              </p>
            </$AdditionalInfoBody>
          </$AdditionalInfo>
        )}
      </I18n>
    );
  };

  handleCodeChange = (e) => {
    this.setState({
      emailVerificationCode: e.target.value.toUpperCase(),
      emailVerificationCodeError: null,
    });
  };

  renderEmailVerificationCodeInput = () => {
    return (
      <I18n>
        {(t) => (
          <$EmailCodeWrapper>
            <span>{t('accountGadget.emailVerification.enterCode')}</span>
            <ValidationWrapper
              error={this.state.emailVerificationCodeError}
              path={'code'}
              fallbackToSingle
            >
              <$CodeInput
                id="code"
                centered
                border="none"
                value={this.state.emailVerificationCode}
                onChange={this.handleCodeChange}
                maxLength={CODE_LENGTH}
                placeholder={'ABCDEF'}
                autoComplete="one-time-code"
                autoFocus={true}
              />
            </ValidationWrapper>
            <LoaderButton
              onClick={this.submitVerificationCode}
              loading={this.state.submitingEmailVerificationCode}
            >
              {t('accountGadget.emailVerification.codeButtonTitle')}
            </LoaderButton>
          </$EmailCodeWrapper>
        )}
      </I18n>
    );
  };

  renderEmailVerificationInfo = () => {
    return (
      <I18n>
        {(t) => (
          <$AdditionalInfo>
            <$AdditionalInfoBody>
              {this.props.verificationEmailRecentlyRequested ? (
                <>
                  <p>
                    <Trans
                      i18nKey={'accountGadget.emailVerification.recentlyRequested.whatIsTheIssue'}
                      components={[<strong key={0}>___</strong>]}
                      values={{ emailAddress: this.props.user.email }}
                    />
                  </p>
                  {this.renderEmailVerificationCodeInput()}
                  <p>
                    <Trans
                      i18nKey={
                        'accountGadget.emailVerification.recentlyRequested.contactSupportCTA'
                      }
                      components={[
                        <$ExternalInfoLink key={0} href={this.props.supportLink}>
                          ___
                        </$ExternalInfoLink>,
                      ]}
                    />
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <Trans
                      i18nKey={'accountGadget.emailVerification.canRequestResend.whatIsTheIssue'}
                      components={[<strong key={0}>___</strong>]}
                      values={{ emailAddress: this.props.user.email }}
                    />
                  </p>
                  {this.renderEmailVerificationCodeInput()}
                  <p>
                    {t('accountGadget.emailVerification.canRequestResend.clickTheResendButtonCTA')}
                  </p>
                </>
              )}
            </$AdditionalInfoBody>
            <$AdditionalInfoActions>
              <LoaderButton
                onClick={this.resendVerificationEmail}
                disabled={this.props.verificationEmailRecentlyRequested}
                loading={this.state.sendingVerificationEmail}
              >
                {this.props.verificationEmailRecentlyRequested
                  ? t('accountGadget.emailVerification.recentlyRequested.buttonTitle')
                  : t('accountGadget.emailVerification.canRequestResend.buttonTitle')}
              </LoaderButton>
            </$AdditionalInfoActions>
          </$AdditionalInfo>
        )}
      </I18n>
    );
  };

  renderKYC0Info = () => {
    return (
      <I18n>
        {(t) => (
          <$AdditionalInfo>
            <$AdditionalInfoBody>
              <p>
                {t('accountGadget.kyc0.whatIsTheIssue', {
                  exchangeTitle: this.props.exchangeTitle,
                })}
              </p>
              <p>
                {t('accountGadget.kyc0.benefitsOfKYC1', {
                  maxWithdrawal: '$' + (this.props.maxWithdrawalKYC1 || 0),
                })}
              </p>
              <p>{t('accountGadget.kyc0.reassuranceRegardingTheSpeedOfTheProcess')}</p>
            </$AdditionalInfoBody>
            <$AdditionalInfoActions>
              <$Button
                display={'flex'}
                onClick={() => this.props.history.push(R.SETTINGS.to({ page: 'kyc' }))}
              >
                {t('accountGadget.kyc0.buttonTitle')}
              </$Button>
            </$AdditionalInfoActions>
          </$AdditionalInfo>
        )}
      </I18n>
    );
  };

  handlePhoneChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.phoneVerificationState && this.props.phoneVerificationState.result === null) {
      // Modal is still open, don't accept inout
      return;
    }
    this.setState({
      phone: e.target.value,
      phoneValidationError: null,
    });
  };

  handleSubmitPhoneNumber = (e: React.FormEvent) => {
    e.preventDefault();

    if (
      !clientValidator(this.state, this.context.t)
        .required('phone')
        .phoneNumber('phone')
        .onFail((phoneValidationError) => this.setState({ phoneValidationError }))
        .isValid()
    ) {
      return;
    }

    this.phoneVerificationId = Date.now();
    this.props.openPhoneVerificationModal('onboarding', this.state.phone, this.phoneVerificationId);
  };

  renderAddPhoneInfo = () => {
    return (
      <I18n>
        {(t) => (
          <$AdditionalInfo>
            <$AdditionalInfoBody>
              <p>{t('accountGadget.addPhone.whatIsTheIssue')}</p>
              <$PhoneNumberForm onSubmit={this.handleSubmitPhoneNumber}>
                <div>
                  <ValidationWrapper path="phone" error={this.state.phoneValidationError}>
                    <$InputDark
                      type="text"
                      placeholder={t('accountGadget.addPhone.phoneNumberPlaceholder')}
                      autoComplete="tel"
                      value={this.state.phone}
                      onChange={this.handlePhoneChanged}
                    />
                  </ValidationWrapper>
                </div>
                <LoadingAnimation requests={['setCustomerPhone']}>
                  <$InfoButton type="submit">
                    {t('accountGadget.addPhone.phoneNumberSubmit')}
                  </$InfoButton>
                </LoadingAnimation>
              </$PhoneNumberForm>
              <p>
                <Trans
                  i18nKey={'accountGadget.addPhone.phoneNumberExplanation'}
                  components={[
                    <$ExternalInfoLink key={0} href={PHONE_NUMBER_E164_EXPLANATION_URL}>
                      ___
                    </$ExternalInfoLink>,
                    <code key={1}>__</code>,
                  ]}
                />
              </p>
              <p>{t('accountGadget.addPhone.benefits')}</p>
              <p>
                <Trans
                  i18nKey={'accountGadget.addPhone.unverifiedDeadlines'}
                  components={[<strong key={0}>___</strong>, <strong key={1}>___</strong>]}
                />
              </p>
            </$AdditionalInfoBody>
          </$AdditionalInfo>
        )}
      </I18n>
    );
  };

  renderUSPolicyForbiddenInfo = () => {
    const usStateLabel = US_STATES[this.props.customer.residence_us_state].label;
    console.error(usStateLabel);
    return (
      <I18n>
        {(t) => (
          <$AdditionalInfo>
            <$AdditionalInfoBody>
              <p>
                <Trans
                  i18nKey={'accountGadget.usPolicyForbiddenState.whatIsTheIssue'}
                  components={[<strong key={0}>___</strong>, <strong key={1}>___</strong>]}
                  values={{
                    exchangeTitle: this.props.exchangeTitle,
                    usState: usStateLabel,
                  }}
                />
              </p>
              <p>
                <Trans
                  i18nKey={'accountGadget.usPolicyForbiddenState.explanationAndApology'}
                  components={[<strong key={0}>___</strong>, <strong key={1}>___</strong>]}
                  values={{
                    exchangeTitle: this.props.exchangeTitle,
                  }}
                />
              </p>
              <p>{t('accountGadget.usPolicyForbiddenState.contactSupportCTA')}</p>
            </$AdditionalInfoBody>
          </$AdditionalInfo>
        )}
      </I18n>
    );
  };

  renderUSPolicyUndefinedInfo = () => {
    return (
      <I18n>
        {(t) => (
          <$AdditionalInfo>
            <$AdditionalInfoBody>
              <p>
                {t('accountGadget.usPolicyUndefinedState.whatIsTheIssue', {
                  exchangeTitle: this.props.exchangeTitle,
                })}
              </p>
              <p>{t('accountGadget.usPolicyUndefinedState.explanationAndApology')}</p>
              <p>{t('accountGadget.usPolicyUndefinedState.contactSupportCTA')}</p>
            </$AdditionalInfoBody>
          </$AdditionalInfo>
        )}
      </I18n>
    );
  };

  renderAdditionalInfo = () => {
    const { user, accountAlert } = this.props;

    if (accountAlert === 'suspended') {
      return this.renderSuspendedInfo();
    }

    if (accountAlert === 'email_verification') {
      return this.renderEmailVerificationInfo();
    }

    if (accountAlert === 'kyc0') {
      return this.renderKYC0Info();
    }

    if (accountAlert === 'add_phone') {
      return this.renderAddPhoneInfo();
    }

    if (accountAlert === 'us_policy_forbidden') {
      return this.renderUSPolicyForbiddenInfo();
    }

    if (accountAlert === 'us_policy_undefined') {
      return this.renderUSPolicyUndefinedInfo();
    }

    // No special alert or state, so render nothing
    return null;
  };

  render() {
    const { user, customer, accountAlert } = this.props;
    if (!user || !customer) {
      return null;
    }

    const accountAlertArrowPosition =
      accountAlert === 'kyc0' ? 2 : accountAlert === 'email_verification' ? 1 : false;

    return (
      <I18n>
        {(t) => (
          <>
            <$AccountGadget roundedBottom={this.props.roundedBottom}>
              <$MenuGadgetHeader rounded={this.props.roundedTop}>
                <$MenuGadgetHeaderTitle as={Link} to={R.SETTINGS.to({ page: 'account' })}>
                  {t('accountGadget.title')}
                </$MenuGadgetHeaderTitle>
                <$AccountLevelIndicators>
                  <IndicatorIcon
                    IndicatorImage={EmailVerificationIndicatorImage}
                    color={'success'}
                    indication={accountAlert === 'email_verification' ? 'warning' : null}
                  />
                  <KYCLevelIndicatorIcon
                    {...getKYCIndicatorProps(
                      accountAlert,
                      user.kyc_level_granted,
                      customer.kyc_level_requested,
                      0
                    )}
                  />
                  <KYCLevelIndicatorIcon
                    {...getKYCIndicatorProps(
                      accountAlert,
                      user.kyc_level_granted,
                      customer.kyc_level_requested,
                      1
                    )}
                  />
                  <KYCLevelIndicatorIcon
                    {...getKYCIndicatorProps(
                      accountAlert,
                      user.kyc_level_granted,
                      customer.kyc_level_requested,
                      2
                    )}
                  />
                  {accountAlertArrowPosition && (
                    <$AdditionalInfoArrow position={accountAlertArrowPosition} />
                  )}
                </$AccountLevelIndicators>
              </$MenuGadgetHeader>
              {this.renderAdditionalInfo()}
            </$AccountGadget>
          </>
        )}
      </I18n>
    );
  }
}

function getKYCIndicatorProps(
  accountAlert: IAccountAlert,
  levelGranted: number,
  levelRequested: number,
  level: number
): { level: number; indication?: IIndicatorIconIndication; color?: IThemeColorsUnion } {
  if (accountAlert === 'suspended' || accountAlert === 'email_verification') {
    // If user is suspended or unverified, disable all icons
    return { level, indication: 'disabled' };
  }

  if (levelGranted < level) {
    // User doesn't have this KYC level yet. But have they requested it?
    if (levelRequested === level) {
      // Show blinking level in its normal color
      return { level, indication: 'enabling' };
    }
    // Show disabled level
    return { level, indication: 'disabled' };
  }

  if (levelGranted === level && level === 0) {
    // User has this level, but it's not enough to trade
    return { level, indication: 'warning' };
  }

  // Display all the levels at and below the acquired one as success
  return { level, color: 'success' };
}

const connector = connect(
  (state: IState) => ({
    user: getUser(state),
    customer: getCustomer(state),
    verificationEmailRecentlyRequested: state.verificationEmailRecentlyRequested,
    accountAlert: getAccountAlert(state),
    accountAlertMuted: state.accountAlertMuted,
    exchangeTitle: state.env.whitelabel.exchangeTitle,
    supportLink: state.env.links.support,
    maxWithdrawalKYC1: state.env.maxWithdrawalPerKYCLevel['1'],
    phoneVerificationState: state.phoneVerification,
  }),
  {
    requestVerificationEmail,
    submitEmailVerificationCode,
    setCustomerPhone,
    openPhoneVerificationModal,
    clearPhoneVerificationState,
  }
);

export default withRouter(connector(AccountGadget));
