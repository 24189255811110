import Container from '../../lib/container';
import { rethrowTranslated } from '../../lib/errors';
import { IThunkMethod } from '../../lib/store';
import { loadingWrapper } from '../request_active';
import { submitLoggedInPrincipal } from './auth';

export const login = (
  email: string,
  password: string,
  tfa_token: string | undefined = undefined,
  captcha_token: string | undefined = undefined
) => (dispatch, _, { api, matomo }: Container) => {
  return dispatch(
    loadingWrapper(
      'postAuthLogin',
      api.postAuthLogin({ email, password, tfa_token, captcha_token }).then((principal) => {
        dispatch(submitLoggedInPrincipal(principal));
        matomo.sendEvent('Session', 'login');
      })
    )
  ).catch((err) => dispatch(rethrowTranslated(err)));
};

export const shouldAskCaptchaForLogin = (): IThunkMethod<Promise<any>> => (
  dispatch,
  _,
  { api }
) => {
  return api.getAuthLoginShouldAskCaptcha().catch((err) => dispatch(rethrowTranslated(err)));
};
