import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { clearSessionTerminatedReason } from '../../../../actions/auth/auth';
import { navigateToDynamicRoute } from '../../../../actions/routing';
import { I18n } from '../../../../lib/i18n';
import R from '../../../../lib/routes';
import { IState } from '../../../../lib/store';
import styled from '../../../../lib/styled_components';
import { ITranslations } from '../../../../types/translations';
import LoginForm from '.././LoginForm';

const $ErrorMessage = styled.div`
  font-size: ${(p) => p.theme.fontSize.large};
  width: ${(p) => p.theme.widgets.loginForm.width};
  margin-top: 1rem;
`;

const $LogInAgainMessage = styled.div`
  font-size: ${(p) => p.theme.fontSize.large};
  width: ${(p) => p.theme.widgets.loginForm.width};
  margin-top: 1rem;
  margin-bottom: ${(p) => p.theme.widgets.loginForm.topDistance};
`;

interface IProps extends ConnectedProps<typeof connector> {}

class SessionTerminatedScreen extends React.PureComponent<IProps> {
  constructor(props) {
    super(props);

    if (!this.props.sessionTerminatedReason) {
      // Default to just the normal login screen
      this.props.navigateToDynamicRoute(R.LOGIN, true);
    }
  }

  componentWillUnmount() {
    // Clear session terminated message after leaving
    this.props.clearSessionTerminatedReason();
  }

  render() {
    return (
      <I18n>
        {(t) => (
          <div>
            <$ErrorMessage>
              {t([
                ('backend:sessionTerminationReasons.' +
                  this.props.sessionTerminatedReason) as ITranslations,
                'sessionTerminated.fallbackSessionTerminatedMessage',
              ])}
            </$ErrorMessage>
            {this.props.sessionTerminatedReason === 'idle_timeout' && (
              <$ErrorMessage> {t('sessionTerminated.disableIdleTimeout')}</$ErrorMessage>
            )}
            <$LogInAgainMessage>{t('sessionTerminated.loginAgain')}.</$LogInAgainMessage>
            <LoginForm initialEmail={this.props.lastUsedEmail || null} />
          </div>
        )}
      </I18n>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    sessionTerminatedReason: state.sessionTerminatedReason,
    lastUsedEmail: state.lastUsedEmail,
  }),
  {
    navigateToDynamicRoute,
    clearSessionTerminatedReason,
  }
);

export default connector(SessionTerminatedScreen);
