import * as React from 'react';
import { Link } from 'react-router-dom';

import R from '../../lib/routes';
import styled from '../../lib/styled_components';
import { ShowMobile } from '../utility_components';
import $Link from '../widgets/Link';
import Logo from '../widgets/Logo';

const $CenteredBox = styled.div`
  display: flex;

  flex-direction: column;
  @media screen and ${(p) => p.theme.device.mobile} {
    width: 100vw;
    height: 100%;
  }
  justify-content: center;
  align-items: center;

  padding: 1.3125rem 2.3125rem 2.3125rem 2.3125rem;

  color: ${(p) => p.theme.layout.kiosk.wrapper.color};

  h2 {
    text-align: center;
    margin: 10px 0;
  }
`;

const $Content = styled.div`
  max-width: 380px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  * {
    transition: background-color 0.25s ease-in, color 0.25s ease-in;
  }
`;

export const $SuccessMessage = styled.div`
  padding: 2rem;
  margin: 1rem 0 2rem;
  text-align: center;
  font-size: ${(p) => p.theme.layout.kiosk.successMessage.fontSize};
  border: ${(p) => p.theme.layout.kiosk.successMessage.border};
  color: ${(p) => p.theme.layout.kiosk.successMessage.color};
  background-color: ${(p) => p.theme.layout.kiosk.successMessage.backgroundColor};
`;

export interface IKioskLayoutProps {
  page?: string;
}

const KioskLayout: React.FunctionComponent<IKioskLayoutProps> = (props) => (
  <$CenteredBox>
    <ShowMobile>
      <$Link as={Link} to={R.HOME}>
        <Logo width={198} height={70.5} />
      </$Link>
    </ShowMobile>
    <$Content>{props.children}</$Content>
  </$CenteredBox>
);

export default KioskLayout;
