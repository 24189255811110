// https://www.styled-components.com/docs/api#typescript
import * as styledComponents from 'styled-components';

import { ITheme } from '../types/theme';
import { PickNestedObject } from '../types/typescript_helpers';

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  withTheme,
  ThemeProvider,
  ThemeConsumer,
  ThemeContext,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<ITheme>;

export type IThemeColorsUnion = keyof PickNestedObject<ITheme, 'colors'>;
export type IThemeFontsUnion = keyof PickNestedObject<ITheme, 'fontFamily'>;
export type IThemeFontSizeUnion = keyof PickNestedObject<ITheme, 'fontSize'>;

export interface IThemeColors {
  color: IThemeColorsUnion;
}

export interface IThemeFonts {
  fontFamily: IThemeFontsUnion;
}
export interface IThemeFontSize {
  fontSize: IThemeFontSizeUnion;
}

export type IThemeProps = styledComponents.ThemeProps<ITheme>;

// NOTE: each component has +-5 indexes
export const zIndex = {
  obCell: 1000,
  trollbox: 1010,
  marketDepth: 1020,
  widgetOverlay: 1030,
  dropdownMenu: 1040,
  stickyHeader: 1045,
  topBar: 1050,
  mobileMenu: 1060,
  modal: 1070,
  overlay: 1080,
  tooltip: 1090,
};

export { css, createGlobalStyle, keyframes, withTheme, ThemeProvider, ThemeConsumer, ThemeContext };

export default styled;
