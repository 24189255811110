import * as React from 'react';

import { I18n } from '../../../lib/i18n';
import styled, { IThemeColorsUnion } from '../../../lib/styled_components';
import { II18nextTfunction } from '../../../types/i18n';
import { instrumentInfo } from '../../../types/instruments';
import { IDeposit } from '../../../types/transactions';
import { ITranslations } from '../../../types/translations';
import $ColorText from '../../widgets/ColorText';

interface IProps {
  deposit: IDeposit;
}

interface IRSDStatus {
  color: IThemeColorsUnion;
  message: ITranslations;
}

// NOTE: Fiat deposits are currently using confirmations_received in an idiotic fashion
//       to be interpreted as enum messages.
const FIAT_DEPOSIT_STATUSES: { [key: number]: IRSDStatus } = {
  0: { color: 'sell', message: 'transactions:deposits.statuses.failed' },
  1: { color: 'yellowDark', message: 'transactions:deposits.statuses.pending' },
  2: { color: 'brandPrimaryLight', message: 'transactions:deposits.statuses.moreInfo' },
  3: { color: 'grayLight', message: '?' as ITranslations },
  4: { color: 'buy', message: 'transactions:deposits.statuses.completed' },
};

const renderDepositStatus = (t: II18nextTfunction, deposit: IDeposit) => {
  if (deposit.finalized_at) {
    if (!deposit.completed_at) {
      // Finalized but not completed deposit means it is on hold
      return <$ColorText color="warning">{t('transactions:deposits.statuses.onHold')}</$ColorText>;
    }

    if (deposit.failure_code) {
      // Finalized, completed and with failure code, means it is failed
      return <$ColorText color="sell">{t('transactions:deposits.statuses.failed')}</$ColorText>;
    }

    // Finalized and completed
    return <$ColorText color="buy">{t('transactions:deposits.statuses.completed')}</$ColorText>;
  }

  // We are treating in-progress transaction differently, based on whether they are crypto or fiat

  if (instrumentInfo(deposit.instrument).type === 'bank') {
    const { color, message } =
      FIAT_DEPOSIT_STATUSES[deposit.confirmations_received] || FIAT_DEPOSIT_STATUSES[3];
    return <$ColorText color={color}>{t(message as ITranslations)}</$ColorText>;
  }

  return (
    <$ColorText color="grayLight">
      {deposit.confirmations_received + '/' + deposit.confirmations_required}
    </$ColorText>
  );
};

const $DepositStatus = styled.strong``;

const DepositStatus: React.FunctionComponent<IProps> = (props) => {
  return (
    <$DepositStatus>
      <I18n>{(t) => renderDepositStatus(t, props.deposit)}</I18n>
    </$DepositStatus>
  );
};

export default DepositStatus;
