const { ALL_INSTRUMENTS_SYMBOLS, ALL_INSTRUMENTS } = require('../types/instruments.ts');

function assert(x, y) {}

const QUANTITY_SCALE_BY_INSTRUMENT = ALL_INSTRUMENTS_SYMBOLS.reduce((acc, curr) => {
  acc[curr] = ALL_INSTRUMENTS[curr].digits;
  return acc;
}, {});

// NOTE: Since we are not doing asserts, no need for this
const BASE_INSTRUMENTS = {};


const DIVISION_MAX_DP = 20;

/**
 * Arbitrary length representation of quantity. Based on Big.js.
 * @param {Quantity|Number|String} [source]
 * @return Quantity
 */
function Quantity(source) {
  if (!(this instanceof Quantity)) {
    // Used as "Quantity(x)"
    if (source instanceof Quantity) {
      // Act as casting
      return source;
    }

    // Use a static instance for some constants
    if (source === 0 || source === '0') {
      return Quantity.ZERO;
    }

    // Create a new instance
    return source === undefined ? new Quantity() : new Quantity(source);
  }

  this.str = undefined;

  // Used as "new Quantity(x)"
  if (source instanceof Quantity) {
    this._sign = source._sign;
    this._exponent = source._exponent;
    this._digits = source._digits.slice();
    this.str = source.str;
  } else {
    if (source === undefined) {
      source = '0';
    }
    Quantity._parse(this, source);
  }
}

// *********************************************************************************************************************

const NUMERIC = /^-?(\d+(\.\d*)?|\.\d+)(e[+-]?\d+)?$/i;
const ROUNDING_MODE_DOWN = 0;
const ROUNDING_MODE_HALF_UP = 1;
const ROUNDING_MODE_HALF_EVEN = 2;
const ROUNDING_MODE_UP = 3;
const DEFAULT_ROUNDING_DIGITS = 8;

/**
 * Parse the number or string value passed to a Quantity constructor.
 * NOTE: This mutates given quantity object
 * @param {Quantity} quantity A Quantity instance.
 * @param {number|string} source A numeric value.
 */
Quantity._parse = (quantity, source) => {
  let index;

  // Minus zero? We don't support that
  if (source === 0 && 1 / source < 0) {
    source = '0';
  } else {
    // Covert to string
    source += '';
    if (!NUMERIC.test(source)) {
      throw new TypeError('Invalid number: ' + source);
    }
  }

  // Determine sign.
  if (source.charAt(0) === '-') {
    source = source.slice(1);
    quantity._sign = -1;
  } else {
    quantity._sign = 1;
  }

  // Decimal point?
  let decimal = source.indexOf('.');
  if (decimal > -1) {
    source = source.replace('.', '');
  }

  // Exponential form?
  index = source.search(/e/i);
  if (index > 0) {
    // Determine exponent.
    if (decimal < 0) {
      decimal = index;
    }
    decimal += Number(source.slice(index + 1));
    source = source.substring(0, index);
  } else if (decimal < 0) {
    // Integer.
    decimal = source.length;
  }

  let numberLength = source.length;

  // Determine leading zeros.
  index = 0;
  while (index < numberLength && source.charAt(index) === '0') {
    index++;
  }
  if (index === numberLength) {
    // Zero.
    quantity._digits = [(quantity._exponent = 0)];
  } else {
    // Determine trailing zeros.
    while (numberLength > 0) {
      numberLength--;
      if (source.charAt(numberLength) !== '0') {
        break;
      }
    }

    quantity._exponent = decimal - index - 1;
    quantity._digits = [];

    // Convert string to array of digits without leading/trailing zeros.
    let digitIndex = 0;
    while (index <= numberLength) {
      quantity._digits[digitIndex] = Number(source.charAt(index));
      index++;
      digitIndex++;
    }
  }

  quantity._onMutated();

  return quantity;
};

/**
 * Round quantity to a maximum of decimalPlaces using roundingMode.
 * NOTE: This mutates given quantity object
 * @param {Quantity} quantity The quantity to round.
 * @param {Number} decimalPlaces Digital places.
 * @param {Number} roundingMode Rounding mode, one of ROUNDING_MODES
 * @param {Boolean} [more] Whether the result of division was truncated.
 */
Quantity._round = (quantity, decimalPlaces, roundingMode, more) => {
  const digits = quantity._digits;
  let i = quantity._exponent + decimalPlaces + 1;

  if (i >= digits.length) {
    // We are already where we want to be
    if (roundingMode < 0 || roundingMode > 3 || roundingMode !== ~~roundingMode) {
      throw new Error('Invalid rounding mode');
    }
    return quantity;
  }

  if (roundingMode === ROUNDING_MODE_HALF_UP) {
    // digits[i] is the digit after the digit that may be rounded up.
    more = digits[i] >= 5;
  } else if (roundingMode === ROUNDING_MODE_HALF_EVEN) {
    more =
      digits[i] > 5 ||
      (digits[i] === 5 && (more || i < 0 || digits[i + 1] !== undefined || digits[i - 1] & 1));
  } else if (roundingMode === ROUNDING_MODE_UP) {
    more = more || digits[i] !== undefined || i < 0;
  } else {
    more = false;
    if (roundingMode !== ROUNDING_MODE_DOWN) {
      throw new Error('Invalid rounding mode');
    }
  }

  if (i < 1) {
    digits.length = 1;

    if (more) {
      // 1, 0.1, 0.01, 0.001, 0.0001 etc.
      quantity._exponent = -decimalPlaces;
      digits[0] = 1;
    } else {
      // Zero.
      digits[0] = quantity._exponent = 0;
    }
  } else {
    // Remove any digits after the required decimal places.
    digits.length = i--;

    // Round up?
    if (more) {
      // Rounding up may mean the previous digit has to be rounded up.
      for (; ++digits[i] > 9; ) {
        digits[i] = 0;
        if (!i--) {
          ++quantity._exponent;
          digits.unshift(1);
        }
      }
    }

    // Remove trailing zeros.
    for (i = digits.length; !digits[--i]; ) {
      digits.pop();
    }
  }

  quantity._onMutated();

  return quantity;
};

/**
 * NOTE: Signs must be the same. Types are not checked. Y is mutated.
 * @param {Quantity} x
 * @param {Quantity} y
 */
Quantity._addXToY = (x, y) => {
  const xExponent = x._exponent;
  let xDigits = x._digits;
  let yExponent = y._exponent;
  let yDigits = y._digits;

  // Either zero? y is non-zero? x is non-zero? Or both are zero.
  if (!xDigits[0] || !yDigits[0]) {
    return yDigits[0] ? y : new Quantity(xDigits[0] ? x : 0);
  }

  xDigits = xDigits.slice();

  // Prepend zeros to equalise exponents.
  // Note: Faster to use reverse then do unshifts.
  let exponentDiff = xExponent - yExponent;
  if (exponentDiff) {
    let target;
    if (exponentDiff > 0) {
      // noinspection JSSuspiciousNameCombination
      yExponent = xExponent;
      target = yDigits;
    } else {
      exponentDiff = -exponentDiff;
      target = xDigits;
    }

    target.reverse();
    for (; exponentDiff--; ) {
      target.push(0);
    }
    target.reverse();
  }

  // Point xc to the longer array.
  if (xDigits.length - yDigits.length < 0) {
    const temp = yDigits;
    // noinspection JSSuspiciousNameCombination
    yDigits = xDigits;
    xDigits = temp;
  }

  // Only start adding at yc.length - 1 as the further digits of xc can be left as they are.
  let a = yDigits.length;
  let b = 0;
  while (a) {
    a--;
    xDigits[a] = xDigits[a] + yDigits[a] + b;
    b = (xDigits[a] / 10) | 0;
    xDigits[a] %= 10;
  }

  // No need to check for zero, as +x + +y != 0 && -x + -y != 0

  if (b) {
    xDigits.unshift(b);
    ++yExponent;
  }

  // Remove trailing zeros.
  for (a = xDigits.length; xDigits[--a] === 0; ) {
    xDigits.pop();
  }

  y._digits = xDigits;
  y._exponent = yExponent;
  y._onMutated();

  return y;
};

/**
 * NOTE: Signs must be the same. Types are not checked. Y is mutated.
 * @param {Quantity} x
 * @param {Quantity} y
 */
Quantity._subYFromX = (x, y) => {
  let xDigits = x._digits.slice();
  const xExponent = x._exponent;
  let yDigits = y._digits;
  let yExponent = y._exponent;

  // Either zero?
  if (!xDigits[0] || !yDigits[0]) {
    // y is non-zero? x is non-zero? Or both are zero.
    if (yDigits[0]) {
      y._sign = -y._sign;
      y._onMutated();
      return y;
    }
    return new Quantity(xDigits[0] ? x : 0);
  }

  let xLessThanY;

  // Determine which is the bigger number. Prepend zeros to equalise exponents.
  let exponentDiff = xExponent - yExponent;
  if (exponentDiff) {
    xLessThanY = exponentDiff < 0;

    let target;
    if (xLessThanY) {
      exponentDiff = -exponentDiff;
      target = xDigits;
    } else {
      // noinspection JSSuspiciousNameCombination
      yExponent = xExponent;
      target = yDigits;
    }

    target.reverse();
    for (let i = exponentDiff - 1; i >= 0; i--) {
      target.push(0);
    }
    target.reverse();
  } else {
    // Exponents equal. Check digit by digit.
    xLessThanY = xDigits.length < yDigits.length;
    const smallerLength = (xLessThanY ? xDigits : yDigits).length;

    for (let i = 0; i < smallerLength; i++) {
      if (xDigits[i] !== yDigits[i]) {
        xLessThanY = xDigits[i] < yDigits[i];
        break;
      }
    }
  }

  // x < y? Point xDigits to the array of the bigger number.
  if (xLessThanY) {
    const temp = xDigits;
    // noinspection JSSuspiciousNameCombination
    xDigits = yDigits;
    yDigits = temp;
    y._sign = -y._sign;
  }

  // Append zeros to xDigits if shorter. No need to add zeros to yDigits if shorter as subtraction only
  // needs to start at yDigits.length.
  let xLength = xDigits.length;
  let yLength = yDigits.length;
  let lengthDiff = yLength - xLength;
  if (lengthDiff > 0) {
    for (; lengthDiff--; ) {
      xDigits[xLength] = 0;
      xLength++;
    }
  }

  // Subtract yDigits from xDigits.
  while (yLength > exponentDiff) {
    yLength--;
    if (xDigits[yLength] < yDigits[yLength]) {
      let index = yLength;
      while (index && !xDigits[--index]) {
        xDigits[index] = 9;
      }
      xDigits[index]--;
      xDigits[yLength] += 10;
    }

    xDigits[yLength] -= yDigits[yLength];
  }

  // Remove trailing zeros.
  for (let i = xLength - 1; xDigits[i] === 0; i--) {
    xDigits.pop();
  }

  // Remove leading zeros and adjust exponent accordingly.
  for (; xDigits[0] === 0; ) {
    xDigits.shift();
    --yExponent;
  }

  if (!xDigits[0]) {
    // n - n = +0
    y._sign = 1;

    // Result must be zero.
    yExponent = 0;
    xDigits = [0];
  }

  y._digits = xDigits;
  y._exponent = yExponent;
  y._onMutated();

  return y;
};

/**
 * @param {Quantity} dividend (MUST be Quantity) (x in original code)
 * @param {Quantity} divisor (Will be coerced to Quantity)
 * @param maxDecimalPlaces How many decimal places to keep, defaults to DIVISION_MAX_DP
 * @param roundingMode How to round result, defaults to ROUNDING_MODE_HALF_UP
 * @return {Quantity}
 */
Quantity._divide = (
  dividend,
  divisor,
  maxDecimalPlaces = DIVISION_MAX_DP,
  roundingMode = ROUNDING_MODE_HALF_UP
) => {
  const dividendDigits = dividend._digits; // a
  const dividendLength = dividendDigits.length;

  const quotient = new Quantity(divisor); // y, q
  const divisorDigits = quotient._digits; // b

  if (!divisorDigits[0]) {
    throw new Error(`Division by zero: ${dividend.toString()} / ${divisor.toString()}`);
  }

  if (!dividendDigits[0]) {
    return new Quantity(0);
  }

  // Division sign
  quotient._sign = dividend._sign === quotient._sign ? 1 : -1;
  const quotientDigits = (quotient._digits = []);
  quotient._exponent = dividend._exponent - quotient._exponent;
  let quotientIndex = 0; // qi

  // Create version of divisor with leading zero.
  const divisorDigitsZero = divisorDigits.slice(); // bz
  divisorDigitsZero.unshift(0);

  let dividendIndex = divisorDigits.length; // ai
  const divisorLength = divisorDigits.length; // bl

  let remainder = dividendDigits.slice(0, divisorLength); // r
  let remainderLength = remainder.length; // rl

  // Add zeros to make remainder as long as divisor.
  for (; remainderLength++ < divisorLength; ) {
    remainder.push(0);
  }

  const resultDigits = maxDecimalPlaces + quotient._exponent + 1;
  let k = resultDigits < 0 ? 0 : resultDigits;

  do {
    let cmp;
    // n is how many times the divisor goes into current remainder.
    let n;
    for (n = 0; n < 10; n++) {
      // Compare divisor and remainder.
      remainderLength = remainder.length;
      if (divisorLength !== remainderLength) {
        cmp = divisorLength > remainderLength ? 1 : -1;
      } else {
        cmp = 0;
        for (let ri = -1; ++ri < divisorLength; ) {
          if (divisorDigits[ri] !== remainder[ri]) {
            cmp = divisorDigits[ri] > remainder[ri] ? 1 : -1;
            break;
          }
        }
      }

      // If divisor < remainder, subtract divisor from remainder.
      if (cmp < 0) {
        // Remainder can't be more than 1 digit longer than divisor.
        // Equalise lengths using divisor with extra leading zero?
        const digits = remainderLength === divisorLength ? divisorDigits : divisorDigitsZero;
        while (remainderLength) {
          remainderLength--;
          if (remainder[remainderLength] < digits[remainderLength]) {
            let ri = remainderLength;
            for (; ri && !remainder[--ri]; ) {
              remainder[ri] = 9;
            }
            --remainder[ri];
            remainder[remainderLength] += 10;
          }
          remainder[remainderLength] -= digits[remainderLength];
        }

        for (; !remainder[0]; ) {
          remainder.shift();
        }
      } else {
        break;
      }
    }

    // Add the digit n to the result array.
    quotientDigits[quotientIndex] = cmp ? n : ++n;
    quotientIndex++;

    // Update the remainder.
    if (remainder[0] && cmp) {
      remainder[remainderLength] = dividendDigits[dividendIndex] || 0;
    } else {
      remainder = [dividendDigits[dividendIndex]];
    }
  } while ((dividendIndex++ < dividendLength || remainder[0] !== undefined) && k--);

  // Leading zero? Do not remove if result is simply zero (quotientIndex == 1).
  if (!quotientDigits[0] && quotientIndex !== 1) {
    // There can't be more than one zero.
    quotientDigits.shift();
    quotient._exponent--;
  }

  // Round?
  if (quotientIndex > resultDigits) {
    Quantity._round(quotient, maxDecimalPlaces, roundingMode, remainder[0] !== undefined);
  }

  quotient._onMutated();

  return quotient;
};

/**
 * Return   1 if the value of x is greater than the value of y,
 *         -1 if the value of x is less than the value of y, or
 *          0 if they have the same value.
 *  undefined if either is not a valid number
 * @param {Quantity|Number|String} x
 * @param {Quantity|Number|String} y
 * @return {Number|undefined}
 */
Quantity.compare = (x, y) => {
  if (x === null || x === undefined || y === null || y === undefined) {
    return undefined;
  }

  x = Quantity(x);
  y = Quantity(y);

  const xDigits = x._digits;
  const yDigits = y._digits;
  const xSign = x._sign;
  const ySign = y._sign;

  // Either zero?
  if (!xDigits[0] || !yDigits[0]) {
    return !xDigits[0] ? (!yDigits[0] ? 0 : -ySign) : xSign;
  }

  // Signs differ?
  if (xSign !== ySign) {
    return xSign;
  }

  const aIsNegative = xSign < 0;

  // Compare exponents.
  const aExp = x._exponent;
  const bExp = y._exponent;
  if (aExp !== bExp) {
    return (aExp > bExp) ^ aIsNegative ? 1 : -1;
  }

  const xLength = xDigits.length;
  const yLength = yDigits.length;
  const minLength = xLength < yLength ? xLength : yLength;

  // Compare digit by digit.
  for (let i = 0; i < minLength; i++) {
    if (xDigits[i] !== yDigits[i]) {
      return (xDigits[i] > yDigits[i]) ^ aIsNegative ? 1 : -1;
    }
  }

  // Compare lengths.
  return xLength === yLength ? 0 : (xLength > yLength) ^ aIsNegative ? 1 : -1;
};

Quantity.eq = function(x, y) {
  return Quantity.compare(x, y) === 0;
};

Quantity.gt = function(x, y) {
  return Quantity.compare(x, y) > 0;
};

Quantity.gte = function(x, y) {
  return Quantity.compare(x, y) > -1;
};

Quantity.lt = function(x, y) {
  return Quantity.compare(x, y) < 0;
};

Quantity.lte = function(x, y) {
  return Quantity.compare(x, y) < 1;
};

/**
 * Convert source into Quantity, but only if it's set
 */
Quantity.cast = function(source) {
  if (source === null || source === undefined) {
    return source;
  }
  return Quantity(source);
};

function findExtreme(method, args) {
  let result = null;
  for (let i = 0; i < args.length; i++) {
    const q = Quantity.cast(args[i]);
    if (q instanceof Quantity) {
      if (!(result instanceof Quantity) || q[method](result)) {
        result = q;
      }
    }
  }
  return result;
}

/**
 * @return {Quantity}
 */
Quantity.max = function() {
  return findExtreme('gt', arguments);
};

/**
 * @return {Quantity}
 */
Quantity.min = function() {
  return findExtreme('lt', arguments);
};

// *********************************************************************************************************************

/**
 * Internal function that will be called every time Quantity instance is mutated.
 * The idea is to use this method to update string representation, which will be used for development
 */
Quantity.prototype._onMutated = function() {
  // TODO: Hide functionality behind a switch, for production!

  this.str = this.toString();
};

Quantity.prototype.compareTo = function(y) {
  return Quantity.compare(this, y);
};

Quantity.prototype.eq = function(y) {
  return this === y || Quantity.compare(this, y) === 0;
};

Quantity.prototype.gt = function(y) {
  return Quantity.compare(this, y) > 0;
};

Quantity.prototype.gte = function(y) {
  return Quantity.compare(this, y) > -1;
};

Quantity.prototype.lt = function(y) {
  return Quantity.compare(this, y) < 0;
};

Quantity.prototype.lte = function(y) {
  return Quantity.compare(this, y) < 1;
};

Quantity.prototype._roundForInstrument = function(instrument, mode) {
  let decimalPlaces = QUANTITY_SCALE_BY_INSTRUMENT[instrument];
  if (decimalPlaces === undefined) {
    decimalPlaces = DEFAULT_ROUNDING_DIGITS;
  }
  Quantity._round(this, decimalPlaces, mode);
};

/**
 * A method that truncates extra information in a quantity based on an instrument.
 * Suitable for being called when cutting off values received from the wild.
 * NOTE: This will mutate this quantity!
 * @return {Quantity}
 */
Quantity.prototype.truncateForInstrument = function(instrument) {
  this._roundForInstrument(instrument, ROUNDING_MODE_DOWN);
  return this;
};

/**
 * Add this quantity and y
 * @param {Quantity|String|Number} y
 * @return {Quantity}
 */
Quantity.prototype.add = function(y) {
  y = new Quantity(y);

  // Signs differ?
  if (this._sign !== y._sign) {
    y._sign = -y._sign;
    return Quantity._subYFromX(this, y);
  }

  return Quantity._addXToY(this, y);
};

/**
 * Subtract given quantity from this one. Returns a new Quantity.
 * @param {Quantity|String|Number} y
 * @return {Quantity}
 */
Quantity.prototype.sub = function(y) {
  y = new Quantity(y);

  // Signs differ?
  if (this._sign !== y._sign) {
    y._sign = -y._sign;
    return Quantity._addXToY(this, y);
  }

  return Quantity._subYFromX(this, y);
};

/**
 * Return a new Quantity whose value is this multiplied by given value
 * @param {Quantity|Number|String} y
 * @return {Quantity}
 */
Quantity.prototype.multiply = function(y) {
  y = Quantity(y);

  let xDigits = this._digits;
  let yDigits = y._digits;

  // Return 0 if either 0.
  if (!xDigits[0] || !yDigits[0]) {
    return new Quantity(0);
  }

  let xLength = xDigits.length;
  let yLength = yDigits.length;

  const result = new Quantity();

  // Determine sign of result.
  result._sign = this._sign === y._sign ? 1 : -1;

  // Initialise exponent of result as x.e + y.e.
  result._exponent = this._exponent + y._exponent;

  // If x has fewer digits than y, swap x and y digits and lengths.
  if (xLength < yLength) {
    let temp = xDigits;
    // noinspection JSSuspiciousNameCombination
    xDigits = yDigits;
    yDigits = temp;
    temp = xLength;
    // noinspection JSSuspiciousNameCombination
    xLength = yLength;
    yLength = temp;
  }

  // Initialise coefficient array of result with zeros.
  let i = xLength + yLength;
  const resultDigits = new Array(i);
  while (i > 0) {
    resultDigits[i - 1] = 0;
    i--;
  }

  // Multiply.

  // i starts from whichever is the shorter value
  let carry = 0;
  for (i = yLength; i--; ) {
    carry = 0;

    let j;
    for (j = xLength + i; j > i; j--) {
      // Current sum of products at this digit position, plus carry.
      carry = resultDigits[j] + yDigits[i] * xDigits[j - i - 1] + carry;
      resultDigits[j] = carry % 10;

      // carry
      carry = (carry / 10) | 0;
    }

    resultDigits[j] = (resultDigits[j] + carry) % 10;
  }

  // Increment result exponent if there is a final carry, otherwise remove leading zero.
  if (carry) {
    ++result._exponent;
  } else {
    resultDigits.shift();
  }

  // Remove trailing zeros.
  for (i = resultDigits.length; !resultDigits[--i]; ) {
    resultDigits.pop();
  }
  result._digits = resultDigits;

  result._onMutated();

  return result;
};

/**
 * Divide this Quantity with given divisor
 * @param {Quantity} divisor
 * @return Quantity
 */
Quantity.prototype.divide = function(divisor) {
  return Quantity._divide(this, divisor);
};

/**
 * Return a new Quantity whose value is the value of this modulo the value of divisor.
 * @param {Quantity} divisor
 * @return Quantity
 */
Quantity.prototype.mod = function(divisor) {
  divisor = Quantity(divisor);
  if (!divisor._digits[0]) {
    throw new Error(`Division by zero: ${this.toString()} / ${divisor.toString()}`);
  }

  // Save sign
  const thisSign = this._sign;
  const divisorSign = divisor._sign;

  this._sign = divisor._sign = 1;

  const divisorIsGreater = divisor.compareTo(this) > 0;

  // Restore sign
  this._sign = thisSign;
  divisor._sign = divisorSign;

  if (divisorIsGreater) {
    // If we are dividing by greater number, the result is the number we started with. 3 % 5 = 3
    return new Quantity(this);
  }

  // Whole number division
  const wholeQuotient = Quantity._divide(this, divisor, 0, ROUNDING_MODE_DOWN);

  // Return the leftover
  return this.sub(wholeQuotient.multiply(divisor));
};

/**
 * Raise quantity to the given power
 * Auto-rounded to the DIVISION_MAX_DP
 * @return {Quantity}
 */
Quantity.prototype.pow = function(n) {
  const negative = n < 0;
  if (negative) {
    n = -n;
  }

  const one = new Quantity(1);
  let x = this;
  let res = one;

  for (;;) {
    // noinspection JSBitwiseOperatorUsage
    if (n & 1) {
      res = res.multiply(x);
    }
    n >>= 1;
    if (!n) {
      break;
    }
    x = x.multiply(x);
  }

  if (negative) {
    res = one.divide(res);
  }

  res._onMutated();

  return res;
};

/**
 * Return the same number, with a flipped sign
 * @return {Quantity}
 */
Quantity.prototype.negate = function() {
  const result = new Quantity(this);
  if (Quantity.ZERO.eq(result)) {
    return result;
  }

  result._sign = result._sign > 0 ? -1 : 1;
  result._onMutated();

  return result;
};

/**
 * Return absolute version of the number
 * @return {Quantity}
 */
Quantity.prototype.abs = function() {
  const result = new Quantity(this);
  result._sign = 1;

  result._onMutated();

  return result;
};

/**
 * Return a string representing the quantity.
 * @return {String}
 */
Quantity.prototype.toString = function() {
  let exponent = this._exponent;
  let result = this._digits.join('');
  const length = result.length;

  if (exponent < 0) {
    for (; ++exponent; ) {
      result = '0' + result;
    }
    result = '0.' + result;
  } else if (exponent > 0) {
    if (++exponent > length) {
      for (exponent -= length; exponent--; ) {
        result += '0';
      }
    } else if (exponent < length) {
      result = result.slice(0, exponent) + '.' + result.slice(exponent);
    }
  } else if (length > 1) {
    result = result.charAt(0) + '.' + result.slice(1);
  }

  if (this._sign < 0 && !!this._digits[0]) {
    return '-' + result;
  }
  return result;
};

/**
 * Format quantity into string with precise number of decimal places.
 * @param {Number} dp
 * @return {string}
 */
Quantity.prototype.toFixed = function(dp = undefined) {
  if (dp === undefined || dp === null) {
    return this.toString();
  }

  if (dp !== Number(dp) || dp < 0) {
    throw new TypeError(`Invalid decimal places given: Quantity.toFixed(${dp})`);
  }

  const tmp = new Quantity(this);
  Quantity._round(tmp, dp, ROUNDING_MODE_DOWN);

  const targetDigits = this._exponent + dp + 1;
  while (tmp._digits.length < targetDigits) {
    tmp._digits.push(0);
  }

  return tmp.toString();
};

/**
 * Returns a string representation that is truncated to certain number of digits
 * @param {Number} dp
 * @return {string}
 */
Quantity.prototype.toTruncatedString = function(dp = undefined) {
  if (dp === undefined || dp === null) {
    return this.toString();
  }

  if (dp !== Number(dp) || dp < 0) {
    throw new TypeError(`Invalid decimal places given: Quantity.toTruncatedString(${dp})`);
  }

  const tmp = new Quantity(this);
  Quantity._round(tmp, dp, ROUNDING_MODE_DOWN);

  // Need to be rounded, like in toFixed()
  return tmp.toString();
};

/**
 * Returns a number representing the quantity. Note that this might not be fully accurate.
 * @return {Number}
 */
Quantity.prototype.toNumber = function() {
  return Number(this.toString());
};

Quantity.prototype.toJSON = Quantity.prototype.toString;

Quantity.prototype.valueOf = function() {
  throw new TypeError(
    `Attempted to convert quantity "${this.toString()}" to a native value (probably due to a math operation), but ` +
      `that is disabled. Use Quantity-specific math operations instead`
  );
};

/**
 * Calculate how much should BASE instrument GAIN for given price and fee
 *
 * Calculation:
 *   gain = price * quantity - price * quantity * fee
 *   gain = quantity * (price * (1 - fee))
 *
 * @param {Number|Quantity} price
 * @param {Number} fee
 * @param instrument BASE instrument using for rounding
 * @return {Quantity}
 */
Quantity.prototype.baseGain = function(price, fee, instrument) {
  assert(BASE_INSTRUMENTS[instrument], 'Instrument for baseGain must be a base instrument');

  // Fee is reduced from gain
  const priceFactor = Quantity(price).multiply(1 - fee);

  const result = this.multiply(priceFactor);

  // Round down for gain
  result._roundForInstrument(instrument, ROUNDING_MODE_DOWN);

  return result;
};

/**
 * Calculate how much should BASE instrument LOSE for given price and fee
 * @param {Number|Quantity} price
 * @param {Number} fee
 * @param instrument BASE instrument using for rounding
 * @return {Quantity}
 */
Quantity.prototype.baseLoss = function(price, fee, instrument) {
  assert(BASE_INSTRUMENTS[instrument], 'Instrument for baseLoss must be a base instrument');

  // Fee is added to loss
  const priceFactor = Quantity(price).multiply(1 + fee);

  const result = this.multiply(priceFactor);

  // NOTE: We initially wanted to do round up when determining how much you must pay.
  //       However, this turned out to be problematic due to multiple rounding-s during partial fill
  //       matches eating more money than we can predict when reserving funds.
  //       So we switched this too to round down, which should bias the situation
  //       towards our initial budget calculation remaining correct.
  result._roundForInstrument(instrument, ROUNDING_MODE_DOWN);

  return result;
};

/**
 * Calculate the amount of BASE currency we should reserve. Almost the same as baseLoss, except
 * we round UP. This ensures we always end up reserving at least something, even if we
 * don't end up spending every last cent/satoshi of the reservation.
 * @param {Number|Quantity} price
 * @param {Number|Quantity} fee
 * @param instrument BASE instrument using for rounding
 * @return {Quantity}
 */
Quantity.prototype.baseReservation = function(price, fee, instrument) {
  assert(BASE_INSTRUMENTS[instrument], 'Instrument for baseLoss must be a base instrument');

  // Fee is added to loss
  const priceFactor = Quantity(price).multiply(1 + fee);

  const result = this.multiply(priceFactor);

  result._roundForInstrument(instrument, ROUNDING_MODE_UP);

  return result;
};

/**
 * How much revenue will be gained from a trade with given price and fees
 * @param {Number|Quantity} price
 * @param buyerFee Fee for the party BUYING the quote instrument (they are LOSING base instrument)
 * @param sellerFee Fee for the party SELLING the quote instrument (they are GAINING base instrument)
 * @param instrument QUOTE instrument using for rounding
 * @return {Quantity}
 */
Quantity.prototype.baseRevenue = function(price, buyerFee, sellerFee, instrument) {
  const takeFromBuyer = this.baseLoss(price, buyerFee, instrument);
  const giveToSeller = this.baseGain(price, sellerFee, instrument);

  // Revenue is the difference between how much we take from one side and give to the other side
  return takeFromBuyer.sub(giveToSeller);
};

/**
 * Calculate how much user can afford of given QUOTE instrument treating this Quantity as BASE budget,
 * based on given price and fee
 * @param {Number|Quantity} price Price for which the budget is calculated
 * @param fee Fee to be applied
 * @param instrument QUOTE instrument used for rounding
 */
Quantity.prototype.quoteAffordable = function(price, fee, instrument) {
  return (
    this
      // Price is reversed (1/price) to get price for BASE in QUOTE (eg. 1 BTC is worth 100000 DOG)
      .multiply(
        Quantity(1).divide(
          Quantity(price)
            // Price is increased by fee, because we are dealing with LOSS here
            .multiply(1 + fee)
        )
      )
      .truncateForInstrument(instrument)
  );
};

/**
 * Calculate total withdrawal or deposit fee, given absolute fee, percent fee and instrument
 * Returned value is an absolute quantity that needs to be subtracted from this quantity
 * @param {Number|Quantity} feeAbs Given as quantity or number, fixed cost
 * @param {Number} feePct Given as percent number 0-100, cost that scales with amount
 * @param instrument
 * @return {Quantity}
 */
Quantity.prototype.combinedFee = function(feeAbs, feePct, instrument) {
  const result = this.sub(feeAbs || 0)
    .multiply(feePct || 0)
    .divide(100)
    .add(feeAbs || 0);

  // Round up how much we take
  result._roundForInstrument(instrument, ROUNDING_MODE_UP);

  return result;
};

// Generate some constants, for performance
Quantity.ZERO = new Quantity(0);

// Register serialization and coercion globally, so it just works by declaring something Quantity through JSDoc-s.

/**
 * Smallest indivisible units for each instrument
 * @type {Object.<string, Quantity>}
 */
Quantity.INSTRUMENT_UNITS = Object.keys(QUANTITY_SCALE_BY_INSTRUMENT).reduce(
  (result, instrument) => {
    const digits = QUANTITY_SCALE_BY_INSTRUMENT[instrument];
    result[instrument] = Quantity(10).pow(digits * -1);
    return result;
  },
  {}
);


Quantity.castOr = function(input, fallback = Quantity.ZERO) {
  try {
    return Quantity(input);
  }
  catch(e) {
    return fallback;
  }
};

export default Quantity;
