import Container from '../../lib/container';
import { handleError } from '../../lib/errors';
import { commit, getNextPageCriteria, mergePaginatedResults, val } from '../../lib/redux_helpers';
import { IState, IThunkMethod } from '../../lib/store';
import { getSecurityEventsForType } from '../../selectors/auth';
import { IPaginated } from '../../types/api';
import { IPrincipal, ISecurityEventInfo, ISession, ISessionInfo, IUser } from '../../types/auth';
import {
  IApiKYC1Policy,
  IApiPublicCustomerInfo,
  IApiRegistrationPolicy,
  ISecurityEventType,
  ISessionTerminationReason,
} from '../../types/backend_definitions';
import { ALL_SECURITY_EVENTS } from '../../types/constants';
import { DeepReadonly } from '../../types/typescript_helpers';
import { getSimpleNotificationData, loadUserSessionData, notifyError } from '../app';
import { loadingWrapper } from '../request_active';

const DEFAULT_PAGE_SIZE = 20;

const initialPaginationState = {
  items: [],
  page: 1,
  page_size: DEFAULT_PAGE_SIZE,
  total_records: 0,
  total_pages: 0,
};

const initEventTypeLookupWithCombinedProp = (value) =>
  ALL_SECURITY_EVENTS.reduce(
    (acc, cur) => {
      acc[cur] = value;
      return acc;
    },
    // { all: value } represents combined prop
    { all: value }
  );

export type IEventTypeSecurityEvents = {
  [eventType in ISecurityEventType]: IPaginated<ISecurityEventInfo>;
};

export interface ISecurityEvents extends IEventTypeSecurityEvents {
  all: IPaginated<ISecurityEventInfo>;
}
export interface IAuthState {
  session: ISession;
  user: IUser;
  lastUsedEmail: string;
  customer: IApiPublicCustomerInfo;
  sessions: ISessionInfo[];
  registrationPolicy: IApiRegistrationPolicy;
  sessionTerminatedReason: ISessionTerminationReason;
  securityEvents: ISecurityEvents;
  kyc1Policy: IApiKYC1Policy;
}

export const AUTH_STATE: IAuthState = {
  session: null,
  user: null,
  lastUsedEmail: null,
  customer: {
    first_name: '',
    middle_name: '',
    last_name: '',
    residence_address: '',
    residence_city: '',
    residence_postal_code: '',
    phone: '',
    phone_verified: null, // Don't display alert prior to having this ready
  } as IApiPublicCustomerInfo,
  sessions: [],
  registrationPolicy: null,
  kyc1Policy: null,
  sessionTerminatedReason: null,
  securityEvents: initEventTypeLookupWithCombinedProp(initialPaginationState) as ISecurityEvents,
};

export const submitSession = (session: ISession) => (dispatch, _, { localStorage }: Container) => {
  // save session it the store and access_token into the LocalStorage
  localStorage.accessToken = session.access_token;

  dispatch(
    commit('Submit session', {
      session: val<IState['session']>(session),
    })
  );

  dispatch(loadUserSessionData());
};

export const submitUser = (user: IUser | DeepReadonly<IUser>) => (
  dispatch,
  _,
  { i18n }: Container
) => {
  // switch UI to user language
  user.language && i18n.changeLanguage(user.language);

  return dispatch(
    commit('Submit user', {
      user: val<IState['user']>(user),
    })
  );
};

export const setUserKYCLevel = (kycLevel) =>
  commit(`Set user's KYC level to ${kycLevel}`, {
    user: {
      kyc_level_granted: val<IState['user']['kyc_level_granted']>(kycLevel),
    },
  });

export const submitLoggedInPrincipal = (principal: IPrincipal): IThunkMethod => (
  dispatch,
  _,
  { matomo }
) => {
  dispatch(submitUser(principal.user));
  dispatch(submitSession(principal.session));
  dispatch(
    commit('Set last used login email', {
      lastUsedEmail: val(principal.user.email),
    })
  );
  matomo.setUserId(principal.user.id);
  matomo.setSession(principal.session.access_token);
};

export const submitSuspendedUser = (suspendedUser: IUser) => (dispatch, _, { i18n }: Container) => {
  dispatch(submitUser(suspendedUser));
  dispatch(notifyError(getSimpleNotificationData(i18n.t('suspension.notification'))));
  dispatch(loadUserSessionData());
};

export const setRegistrationPolicy = (registrationPolicy: IApiRegistrationPolicy) => (dispatch) => {
  return dispatch(
    commit('Set registration policy', {
      registrationPolicy: val<IState['registrationPolicy']>(registrationPolicy),
    })
  );
};

export const setKYC1Policy = (policy: IApiKYC1Policy) => (dispatch) => {
  return dispatch(
    commit('Set KYC1 policy', {
      kyc1Policy: val<IState['kyc1Policy']>(policy),
    })
  );
};

export const setSessionTerminatedReason = (reason: ISessionTerminationReason) =>
  commit(`Set sessionTerminatedReason to ${reason}`, {
    sessionTerminatedReason: val(reason),
  });

export const clearSessionTerminatedReason = () =>
  commit(`Clear sessionTerminatedReason`, {
    sessionTerminatedReason: val(null),
  });

export const loadAuthPrincipal = (): IThunkMethod<Promise<any>> => (
  dispatch,
  _,
  { api, matomo }
) => {
  return api
    .getAuthPrincipal()
    .then((principal) => {
      dispatch(submitLoggedInPrincipal(principal));
      matomo.sendEvent('Session', 'resume_session');
    })
    .catch((err) => dispatch(handleError(err)));
};

export const resetUserSecurityEvents = () => (dispatch) => {
  dispatch(
    commit(`Reset user deposits state`, {
      securityEvents: val<IAuthState['securityEvents']>(AUTH_STATE.securityEvents),
    })
  );
};

export const loadSecurityEvents = (
  eventType: keyof ISecurityEvents,
  firstPage = false
): IThunkMethod<Promise<void>> => (dispatch, getState, { api }) => {
  const oldEvents = getSecurityEventsForType(getState(), eventType);
  const nextPageCriteria = getNextPageCriteria(oldEvents, DEFAULT_PAGE_SIZE, firstPage);

  return dispatch(
    loadingWrapper(
      'getSecurityEvents',
      api
        .getSecurityEventsInfos({
          // NOTE: If eventType !== 'all' we are loading data for specific eventType, otherwise we load all data,
          event_types: eventType !== 'all' ? [eventType] : [],
          sort_direction: 'desc',
          sort_field: ['timestamp', 'id'],
          ...nextPageCriteria,
        })
        .then((newEvents) => {
          const mergedEvents = mergePaginatedResults<ISecurityEventInfo, ISecurityEventInfo>(
            newEvents,
            oldEvents,
            'id'
          );
          dispatch(
            commit(`Get user security events for event type "${eventType}"`, {
              securityEvents: { [eventType]: val<IPaginated<ISecurityEventInfo>>(mergedEvents) },
            })
          );
        })
        .catch((err) => dispatch(handleError(err)))
    )
  );
};
