import { ISecurityEventType } from './backend_definitions';

export enum TimeUnits {
  second = 1000,
  minute = 60 * second,
  hour = 60 * minute,
  day = 24 * hour,
  month = 31 * day,
}

export const LIST_SIZE_LIMIT = 100;
// MUST BE WHOLE NUMBER, eg. 1,2,3,4,5, same goes for session.idle_timeout
export const IDLE_TIME_COUNTDOWN = 1 * TimeUnits.minute;
export const TOAST_AUTOCLOSE_TIMEOUT = 10 * TimeUnits.second;

export const CURRENCY_TO_COUNTRY_ISO = {
  EUR: 'EU',
  USD: 'US',
  CHF: 'CH',
  GBP: 'GB',
  RUB: 'RU',
  DKK: 'DK',
  NOK: 'NO',
  CZK: 'CZ',
  PLN: 'PL',
  SEK: 'SE',
  AUD: 'AU',
  HUF: 'HU',
  JPY: 'JP',
  CAD: 'CA',
  NZD: 'NZ',
  TRY: 'TR',
  ZAR: 'ZA',
  ILS: 'IL',
};
export type IWhitelabel = 'xcalibra' | 'chyngex';

export const SECURITY_EVENTS: { [key in ISecurityEventType]: key } = {
  login_user: 'login_user',
  register_customer: 'register_customer',
  delete_session: 'delete_session',
  force_log_out_user: 'force_log_out_user',
  request_password_reset: 'request_password_reset',
  execute_password_reset: 'execute_password_reset',
  request_email_verification: 'request_email_verification',
  execute_email_verification: 'execute_email_verification',
  failed_login_limit_reached: 'failed_login_limit_reached',
  tfa_login_timeout: 'tfa_login_timeout',
  tfa_enabled: 'tfa_enabled',
  tfa_disabled: 'tfa_disabled',
  email_changed: 'email_changed',
  password_changed: 'password_changed',
  user_profile_updated: 'user_profile_updated',
  bot_created: 'bot_created',
  bot_updated: 'bot_updated',
  bot_signature_secret_revealed: 'bot_signature_secret_revealed',
};
export const ALL_SECURITY_EVENTS = Object.keys(SECURITY_EVENTS);

export const PHONE_NUMBER_E164_EXPLANATION_URL = 'https://en.wikipedia.org/wiki/E.164';
