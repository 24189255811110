import { IState } from '../lib/store';
import { IApiGraphData, IApiGraphDataForInstrument } from '../types/backend_definitions';

interface IGraphDataForInstrument extends IApiGraphDataForInstrument {
  change: number;
}

export const getManagedGraph = (state: IState) => {
  return state.graph;
};
