import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';

import { intentionallyUntranslated } from '../../lib/i18n';
import R, { IManagedPage, IRouteEnablementProps, getRouteEnablementProps } from '../../lib/routes';
import { IState } from '../../lib/store';
import { typedKeys } from '../../lib/util';
import {
  CheckedPaperIcon,
  Cog4Icon,
  LaptopWithChartIcon,
  MonitorIcon,
  OppositeArrowsIcon,
} from '../../media/svg_icons';
import { II18nextT } from '../../types/i18n';
import BuyOrders from '../components/managed/BuyOrders';
import ManagedSetup from '../components/managed/ManagedSetup';
import Portfolio from '../components/managed/Portfolio';
import SellOrders from '../components/managed/SellOrders';
import SideMenuLayout, { ISideMenuItem } from '../layout/SideMenuLayout';
import PageSeoWrapper from '../widgets/PageSeoWrapper';
import NotFoundPage from './NotFoundPage';

const MANAGED_COMPONENTS: { [page in IManagedPage]: React.ComponentType<any> } = {
  setup: ManagedSetup,
  portfolio: Portfolio,
  'buy-orders': BuyOrders,
  'sell-orders': SellOrders,
};

type ManagedMenuItems = { [key in IManagedPage]: ISideMenuItem };

const makeManagedMenuItems = (props: IRouteEnablementProps): ManagedMenuItems => {
  const items: Array<ISideMenuItem & { page: IManagedPage }> = [
    {
      page: 'setup',
      label: intentionallyUntranslated('Setup'),
      icon: CheckedPaperIcon,
    },
  ];

  if (props.managedUserConfig.registered) {
    items.unshift(
      {
        page: 'portfolio',
        label: 'managed.pages.portfolio',
        icon: MonitorIcon,
      },
      {
        page: 'buy-orders',
        label: 'managed.pages.buyOrders',
        icon: OppositeArrowsIcon,
      },
      {
        page: 'sell-orders',
        label: 'managed.pages.sellOrders',
        icon: OppositeArrowsIcon,
      }
    );
  }

  return items.reduce((lookup, item) => {
    lookup[item.page] = {
      ...item,
      route: item.route || R.MANAGED.to({ page: item.page }),
    };
    return lookup;
  }, {} as ManagedMenuItems);
};

export interface IManagedLayoutProps
  extends RouteComponentProps<{ page: IManagedPage }>,
    ConnectedProps<typeof connector> {}

interface IManagedLayoutState {}

class ManagedPage extends React.PureComponent<IManagedLayoutProps, IManagedLayoutState> {
  static contextType: any = I18nContext;

  render() {
    const { t }: II18nextT = this.context;

    const page = (this.props.match.params && this.props.match.params.page) || null;

    if (!R.MANAGED_HOME.isEnabled(this.props.routeEnablementProps)) {
      return null;
    }

    const items = makeManagedMenuItems(this.props.routeEnablementProps);
    if (!page || !items[page]) {
      // Redirect to the first good page
      const page = typedKeys(items)[0];
      if (!page) {
        return null;
      }

      return <Redirect to={R.MANAGED.to({ page })} />;
    }

    const Component = MANAGED_COMPONENTS[page];

    return (
      <PageSeoWrapper pageTitle={t(items[page].label)}>
        <SideMenuLayout
          pages={items}
          headerIcon={LaptopWithChartIcon}
          headerText={'pages.managed'}
          currentPage={items[page]}
          selectedRoute={R.MANAGED.to({ page })}
        >
          <Component />
        </SideMenuLayout>
      </PageSeoWrapper>
    );
  }
}

const connector = connect((state: IState) => ({
  routeEnablementProps: getRouteEnablementProps(state),
  theme: state.app.theme,
}));

export default withRouter(connector(ManagedPage));
