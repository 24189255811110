import { commit, val } from '../lib/redux_helpers';
import { IState } from '../lib/store';
import { submitSourceOfFunds } from './auth/customer';

export interface IRequestActive {
  // Initial App Data
  loadAppDataUsingAccessToken: boolean;

  // System Time
  getWebuiSystemTime: boolean;

  // User Exchange Data
  getWebuiUserExchangeData: boolean;

  // User Session Data
  getWebuiUserSessionData: boolean;

  // Auth
  requestResetPassword: boolean;
  postAuthLogin: boolean;
  postCustomersRegister: boolean;

  // Security Events
  getSecurityEvents: boolean;

  // Sessions
  getActiveSessions: boolean;
  deleteSessions: boolean;
  // Orders
  getActiveUserOrdersForAllInstrumentPairs: boolean;
  getActiveUserOrdersForInstrumentPair: boolean;
  cancelOrderFromListOfAllOrders: boolean;
  postExchangeOrder: boolean;
  // Trades
  getExchangeTrades: boolean;
  getUserTrades: boolean;
  // Card Payments
  getUserCardPayments: boolean;
  // API keys
  getKeys: boolean;
  createKey: boolean;
  editKey: boolean;
  deleteKey: boolean;
  getSecretKey: boolean;
  // TFA
  enableTFA: boolean;
  confirmTFA: boolean;
  disableTFA: boolean;
  // Profile
  changeEmail: boolean;
  changePassword: boolean;
  language: boolean;
  nickname: boolean;
  requestedNickname: boolean;
  nicknameDeclineReason: boolean;
  // Settings
  geoLocationPinning: boolean;
  enableKeepAlive: boolean;
  enableWithdrawalConfirmations: boolean;
  enableOrderConfirmations: boolean;
  enableOrderNotifications: boolean;
  // KYC
  setCustomerPhone: boolean;
  putKycLevel1: boolean;
  putKycLevel2: boolean;
  submitSourceOfFunds: boolean;
  // Transactions
  loadDepositsForInstrument: boolean;
  loadWithdrawalsForInstrument: boolean;
  loadAllWithdrawals: boolean;
  cancelWithdrawal: boolean;

  // Managed
  getUserManagedBuyOrders: boolean;
  getUserManagedSellOrders: boolean;
  getGraphData: boolean;
  getEstimatorData: boolean;
}

export type IActiveRequestsKeys = keyof IRequestActive;

export interface IRequestActiveState {
  requestActive: IRequestActive;
}

export const REQUEST_ACTIVE_STATE: IRequestActiveState = {
  requestActive: {
    enableTFA: false,
    confirmTFA: false,
    disableTFA: false,
    changeEmail: false,
    changePassword: false,
    loadAppDataUsingAccessToken: false,
    getWebuiSystemTime: false,
    getWebuiUserExchangeData: false,
    getWebuiUserSessionData: false,
    requestResetPassword: false,
    postAuthLogin: false,
    postCustomersRegister: false,
    getExchangeTrades: false,
    getUserTrades: false,
    getUserCardPayments: false,
    getSecurityEvents: false,
    getActiveSessions: false,
    deleteSessions: false,
    getActiveUserOrdersForAllInstrumentPairs: false,
    getActiveUserOrdersForInstrumentPair: false,
    cancelOrderFromListOfAllOrders: false,
    postExchangeOrder: false,
    getKeys: false,
    createKey: false,
    editKey: false,
    deleteKey: false,
    getSecretKey: false,
    language: false,
    nickname: false,
    requestedNickname: false,
    nicknameDeclineReason: false,
    geoLocationPinning: false,
    enableKeepAlive: false,
    enableWithdrawalConfirmations: false,
    enableOrderConfirmations: false,
    enableOrderNotifications: false,
    setCustomerPhone: false,
    putKycLevel1: false,
    putKycLevel2: false,
    submitSourceOfFunds: false,
    loadDepositsForInstrument: false,
    loadWithdrawalsForInstrument: false,
    loadAllWithdrawals: false,
    cancelWithdrawal: false,
    getUserManagedBuyOrders: false,
    getUserManagedSellOrders: false,
    getGraphData: false,
    getEstimatorData: false,
  },
};

export const loadingWrapper = <T>(
  key: IActiveRequestsKeys,
  action: Promise<T>
): ((any) => Promise<T>) => {
  return (dispatch) => {
    dispatch(loadingStarted(key));
    return action.finally(() => {
      dispatch(loadingCompleted(key));
    });
  };
};

export const loadingStarted = (action: IActiveRequestsKeys) =>
  commit(`Request '${action}' started`, {
    requestActive: {
      [action]: val<boolean>(true),
    } as Partial<IState['requestActive']>,
  });

export const loadingCompleted = (action: IActiveRequestsKeys) =>
  commit(`Request '${action}' completed`, {
    requestActive: {
      [action]: val<boolean>(false),
    } as Partial<IState['requestActive']>,
  });
