import moment from 'moment';

import {
  Bar,
  CustomTimezones,
  IBasicDataFeed,
  IChartingLibraryWidget,
  LibrarySymbolInfo,
  SubscribeBarsCallback,
} from '../charting_library/charting_library';
import { ResolutionString } from '../charting_library/datafeed-api';
import {
  getSystemTimeDrift,
  getSystemTimeError,
  systemTimeDriftToUTCMoment,
} from '../selectors/app';
import { ISession } from '../types/auth';
import {
  IApiPriceHistory,
  IApiPublicTradeInfo,
  IPair,
  IPriceHistorianGroupingInterval,
  XcalibraClient,
} from '../types/backend_definitions';
import { pairInfo } from '../types/instruments';
import { handleError } from './errors';
import { Http, HttpErrorResponse } from './http';
import { Logger } from './logger';
import { SocketManager } from './socket_manager';
import { Store } from './store';
import { guardedAsyncOp, wait } from './util';

type IDataFeedResolution =
  | '1'
  | '5'
  | '15'
  | '30'
  | '60'
  | '120'
  | '240'
  | '1D'
  | 'W'
  | '1W'
  | '1M';

interface IDataFeedResolutionSpec {
  unit: moment.unitOfTime.StartOf;
  count: number;
  truncUnit?: moment.unitOfTime.StartOf;
  query: IPriceHistorianGroupingInterval;
}

const RESOLUTIONS: { [key in IDataFeedResolution]: IDataFeedResolutionSpec } = {
  '1': { count: 1, unit: 'minute', query: 'minute' },
  '5': { count: 5, unit: 'minute', query: 'minutes_5', truncUnit: 'hour' },
  '15': { count: 15, unit: 'minute', query: 'minutes_15', truncUnit: 'hour' },
  '30': { count: 30, unit: 'minute', query: 'minutes_30', truncUnit: 'hour' },
  '60': { count: 1, unit: 'hour', query: 'hour' },
  '120': { count: 2, unit: 'hour', query: 'hours_2', truncUnit: 'day' },
  '240': { count: 4, unit: 'hour', query: 'hours_4', truncUnit: 'day' },
  '1D': { count: 1, unit: 'day', query: 'day' },
  // 1yr/5yr timeframe selection uses 'W' as resolution and not '1W' which is used for interval selection
  W: { count: 1, unit: 'week', query: 'week' },
  '1W': { count: 1, unit: 'week', query: 'week' },
  '1M': { count: 1, unit: 'month', query: 'month' },
};
// NOTE: Ugh, we get a warning without filtering out the duplicate 'W'
const SUPPORTED_RESOLUTIONS = Object.keys(RESOLUTIONS).filter(
  (res) => res !== 'W'
) as IDataFeedResolution[];

function dateToGroupedTs(date: Date, spec: IDataFeedResolutionSpec) {
  // isoWeek starts on Monday instead of Sunday. This makes sure we match the format TradingView expects to see.
  const specUnit: any = spec.unit === 'week' ? 'isoWeek' : spec.unit;

  if (spec.count <= 1) {
    // Simple variant. We can just create a grouping directly
    return moment.utc(date).startOf(specUnit).valueOf();
  }

  // If we are looking at 14:37 minutes, and need to round it at 15 min boundary (14:45), truncM will be 14:00.
  const truncM = moment.utc(date).startOf(spec.truncUnit);

  // This will be accurate number of minutes, 37
  const accurate = moment.utc(date)[specUnit]();

  // Round to the next upper bound. ceil(37 / 15) * 15 = ceil(2.46) * 15 = 3 * 15 = 45
  const rounded = Math.ceil(accurate / spec.count) * spec.count;

  // Set the unit to new value. 14:00 -> 14:45
  truncM[specUnit](rounded);

  return truncM.valueOf();
}

function priceHistoryToBar(record: IApiPriceHistory): Bar {
  return {
    time: new Date(record.timestamp).valueOf(),
    open: record.open,
    high: record.high,
    low: record.low,
    close: record.close,
    volume: parseFloat(record.volume),
  };
}

/**
 * This will generate string like BTC_5, which should match subscription id that the chart uses
 */
function makeSubscriptionId(pair, resolution) {
  return `${pair}_#_${resolution}`;
}

interface IDataFeedSubscription {
  id: string;
  symbolInfo: LibrarySymbolInfo;
  resolution: string;
  lastBar: Bar;
  update: SubscribeBarsCallback;
}

/**
 * Class that can be given to candle chart, and used to provide it with data and other facilities
 */
export class CandleChartAdapter implements IBasicDataFeed {
  private subscriptions: { [id: string]: IDataFeedSubscription } = {};
  private lastLoadedBarPerSubscription: { [subscriptionId: string]: Bar } = {};
  private logger: Logger;

  /**
   * Widget we are currently serving. It is the responsibility of this library to always call back
   * to the currently active widget.
   */
  private widget: IChartingLibraryWidget;

  /**
   * We can call widget directly only when this is true.
   */
  private widgetReady: boolean = false;

  /**
   *  Session we are currently basing our actions on. This will be updated from the store
   */
  private currentSession: ISession = null;

  /**
   * If we load saved state for a session, we will remember here for which one did we load.
   * This ensures we don't load twice needlessly (as different state changes can cause us to load again).
   */
  private lastLoadedSavedStateAccessToken = null;

  /**
   * TradingView has tendency to forget some saveState() callback, then call it willy-nilly at some point later.
   * We will remember each call here. And make sure we only call back for the most recent request.
   */
  private currentSaveStateRequestId = 0;

  private currentUnloading = false;

  constructor(
    private store: Store,
    private api: XcalibraClient,
    private http: Http,
    private socketManager: SocketManager,
    logger: Logger
  ) {
    this.logger = logger.prefixed('CandleChartAdapter');
  }

  handleError = (err: HttpErrorResponse) => {
    if (err.name === 'SessionTerminatedError') {
      // Not interested in those for background requests
      return;
    }

    // NOTE: This is required due to the way actions are typed. TODO at some point to fix this.
    return (this.store.dispatch as any)(handleError(err));
  };

  start() {
    // Subscribe to the trades feed
    // NOTE: Since price feed is here to stay, I think we don't need unsubscribe
    this.socketManager.onEvent('public_trade', this.onTrade);

    this.store.subscribe(() => {
      const state = this.store.getState();

      if (
        (this.currentSession && this.currentSession.access_token) !==
        (state.session && state.session.access_token)
      ) {
        const prevSession = this.currentSession;
        this.currentSession = state.session;
        if (this.currentSession) {
          this.tryLoadSavedState();
        } else if (prevSession) {
          this.saveState(prevSession);
        }
      }

      if (state.app.unmounting && !this.currentUnloading) {
        // Best effort save
        this.currentUnloading = true;
        this.saveState();
      }
    });
  }

  /**
   * Charting library will call this when widget has been created, but hasn't loaded.
   * During this stage, widget can call us, but we can't call it.
   * If loading fails, this will be called with null.
   */
  notifyWidgetLoading(widget: IChartingLibraryWidget | null) {
    this.logger.verbose(`notifyWidgetLoading(${widget ? '{}' : 'null'})`);
    this.widget = widget;
  }

  /**
   * Chart should call this once widget is ready
   */
  notifyWidgetReady(widget: IChartingLibraryWidget) {
    this.logger.verbose(`notifyWidgetReady()`);

    if (widget !== this.widget) {
      this.logger.error(`Wrong widget supplied as "ready"`);
      return;
    }
    this.widgetReady = true;

    this.tryLoadSavedState();

    widget.subscribe('onAutoSaveNeeded', this.saveState);
  }

  /**
   * Charting library will call this just before widget is unloaded, giving us the chance to cleanup
   */
  unloadWidget(widget: IChartingLibraryWidget): Promise<void> {
    this.logger.verbose(`notifyWidgetUnloading()`);

    if (!this.widget || !this.widgetReady || !this.currentSession) {
      return Promise.resolve();
    }

    // Make best effort to autoSave
    this.saveState();
    return wait(100);
  }

  /**
   * Execute some code asynchronously (after a delay), making sure widget is present and stays the same
   */
  private asyncResponse(fn) {
    const w = this.widget;
    if (!w) {
      this.logger.warn(`Async response aborted due to the lack of active widget`);
      return;
    }

    wait(1).then(() => {
      if (this.widget !== w) {
        // Something changed, abort
        this.logger.warn(
          `Async response aborted due to active widget disappearing in the meantime`
        );
        return;
      }

      fn();
    });
  }

  /**
   * This will be called for each trade that came through socket and update all active
   * subscriptions that match its pair.
   */
  private onTrade = (trade: IApiPublicTradeInfo) => {
    const timestamp = new Date(trade.timestamp);

    // noinspection TsLint
    // tslint:disable-next-line:forin
    for (const id in this.subscriptions) {
      const subscription = this.subscriptions[id];

      if (subscription.symbolInfo.name !== trade.pair) {
        // Don't care about this one
        continue;
      }

      let bar = subscription.lastBar;
      if (!bar) {
        // Nothing we can do
        continue;
      }

      const groupedTs = dateToGroupedTs(timestamp, RESOLUTIONS[subscription.resolution]);
      if (bar.time > groupedTs) {
        // Nothing we can do with this trade
        this.logger.error(`Received a trade that falls behind current tip of the chart`, {
          bar: subscription.lastBar,
          trade,
        });
        continue;
      }

      if (bar.time < groupedTs) {
        // Create a new bar
        bar = {
          time: groupedTs,
          high: 0,
          low: 0,
          open: bar.close,
          close: 0,
          volume: 0,
        };
        subscription.lastBar = bar;
      }

      if (bar.high < trade.price) {
        bar.high = trade.price;
      }
      if (bar.low === 0 || bar.low > trade.price) {
        bar.low = trade.price;
      }
      bar.close = trade.price;
      subscription.lastBar.volume += Number(trade.quantity) * trade.price;

      this.logger.shouldVerbose && this.logger.verbose(`Updated bar on ${subscription.id}`, bar);

      subscription.update(bar);
    }
  };

  // ******************
  // IBasicDataFeed interface
  // ******************

  /**
   * Called immediately after the initialization
   * Pass the config options to the callback.
   * NOTE: The timeout is some cargo cult code found somewhere, not sure if truly needed.
   */
  onReady(callback) {
    this.logger.verbose(`onReady()`);
    this.asyncResponse(() => {
      callback({
        supported_resolutions: SUPPORTED_RESOLUTIONS,
        supports_time: true,
      });
    });
  }

  /**
   * This call is intended to provide server time
   */
  getServerTime(callback) {
    this.asyncResponse(() => {
      const unixTime = systemTimeDriftToUTCMoment(getSystemTimeDrift(this.store.getState())).unix();

      const error = getSystemTimeError(this.store.getState());
      if (error) {
        this.logger.warn(
          `getServerTime() => ${unixTime} (with error: ${(error as any).message || error})`
        );
      } else {
        this.logger.verbose(`getServerTime() => ${unixTime} (Synced)`);
      }

      callback(unixTime);
    });
  }

  /**
   * This call is intended to provide the list of symbols that match the user's search query.
   * TODO: Why is this here? Are we using it? Remove?
   */
  searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
    this.logger.verbose(`searchSymbols(${userInput}, ${exchange}, ${symbolType})`);

    this.asyncResponse(() => {
      onResultReadyCallback([]);
    });
  }

  /**
   * Charting Library will call this function when it needs to get SymbolInfo by symbol name.
   * Basically, they will send in "SFX_BTC" and we will send the stuff they need to display on screen.
   */
  resolveSymbol(symbolName, onResolve: (symbol: LibrarySymbolInfo) => any, onError) {
    this.logger.verbose(`resolveSymbol(${symbolName})`);

    this.asyncResponse(() => {
      const state = this.store.getState();

      if (!state.env.pairs.infos[symbolName]) {
        this.logger.error(`resolveSymbol called for invalid symbol: "${symbolName}"`);
        // This will cause the chart to bug out, but that's (maybe) ok in this case?
        return onError(`Symbol not found: ${symbolName}`);
      }

      const info = pairInfo(symbolName);

      const symbolInfo: LibrarySymbolInfo = {
        name: symbolName,
        full_name: symbolName,
        exchange: state.env.whitelabel.name,
        listed_exchange: state.env.whitelabel.exchangeTitle,
        supported_resolutions: Object.keys(RESOLUTIONS) as ResolutionString[],
        description: info.displayPath,
        ticker: symbolName,
        type: 'crypto',
        session: '24x7',

        // the amount of price precision steps for 1 tick
        minmov: 1,

        // the number of decimal places (10^number-of-decimal-places)
        pricescale: 100000000,

        // whether the symbol includes intraday (minutes) historical data
        has_intraday: true,

        has_daily: true,
        has_weekly_and_monthly: true,

        // Only support grouping data by minute, let the chart build up intra-day groupings (eg. 15 min)
        // TODO: Should we use this, or create own code for dragging in intraday data?
        // intraday_multipliers: ['1'],

        timezone: 'Etc/UTC',
        format: 'price',
      };

      return onResolve(symbolInfo);
    });
  }

  /**
   * Get history fragment defined by dates range.
   * Chart will call this when it wants to call bars from data source (backend server in our case).
   * In testing, this is called the first time chart is loaded, and while you zoom or scroll around.
   * NOTE: If you call onErrorCallback, the chart will get into a broken state. Don't do it unless it's truly broken!
   */
  getBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    from: number,
    to: number,
    onHistoryCallback,
    onErrorCallback,
    firstDataRequest: boolean
  ) {
    // TV requires bar time in ms
    const fromDate = new Date(from * 1000);
    const toDate = new Date(to * 1000);

    this.logger.verbose('getBars', {
      symbolInfo,
      resolution,
      from,
      to,
      fromDate,
      toDate,
      firstDataRequest,
    });

    const targetPair = symbolInfo.name as IPair;

    const intervalQuery = RESOLUTIONS[resolution] && RESOLUTIONS[resolution].query;
    if (!intervalQuery) {
      this.logger.error(`getBars called with invalid resolution/interval: "${resolution}"`);
      return this.asyncResponse(() => onErrorCallback(`Unsupported resolution: ${resolution}`));
    }

    guardedAsyncOp(
      () => this.widget,
      () => {
        return this.api.getExchangeReportsPriceHistory({
          pair: targetPair,
          interval: intervalQuery,
          from_timestamp: fromDate.toISOString(),
          to_timestamp: toDate.toISOString(),
        });
      },
      () => {
        this.logger.warn(
          `Active widget has changed while fetching bars for ${targetPair}. Aborted.`
        );
      }
    ).then(
      (history) => {
        const bars = history.map(priceHistoryToBar);

        const guessedSubscriptionId = makeSubscriptionId(symbolInfo.name, resolution);
        this.lastLoadedBarPerSubscription[guessedSubscriptionId] = bars[bars.length - 1];

        onHistoryCallback(bars, { noData: history.length === 0 });
      },
      (err) => {
        this.handleError(err);
        // Pretend there is no data, because we don't want to get into a broken state. Good idea?
        onHistoryCallback([], { noData: true });
      }
    );
  }

  /**
   * Chart calls this function when it wants to receive real-time updates for a symbol.
   * Resolution is one of possible resolutions defined in IDataFeedResolution.
   * SubscriberUID is what will be used to later unsubscribe.
   * Until unsubscribe is called, we can call onRealtimeCallback to send updates to the chart.
   * Updates must be for the current last bar in the chart or for later bar. Historic updates are a no-no.
   */
  subscribeBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: string,
    onRealtimeCallback: SubscribeBarsCallback,
    subscriptionId: string,
    onResetCacheNeededCallback
  ) {
    this.logger.info(`subscribeBars ${subscriptionId}`);

    if (!this.lastLoadedBarPerSubscription[subscriptionId]) {
      // We don't have the initial bar, so we can't subscribe
      this.logger.error(`Couldn't find loaded bar for subscription ${subscriptionId}`);
      return;
    }

    this.subscriptions[subscriptionId] = {
      id: subscriptionId,
      symbolInfo,
      resolution,
      lastBar: this.lastLoadedBarPerSubscription[subscriptionId],
      update: onRealtimeCallback,
    };
  }

  /**
   * Chart calls this when it wants to unsubscribe. We are given the same id as in subscribe.
   * In testing, this is almost always called in pair with subscribeBars. There hasn't been a
   * case where we had multiple subscriptions active at the same time, but API design
   * suggests that is possible, so we account for that.
   */
  unsubscribeBars(subscriptionId) {
    this.logger.info(`unsubscribeBars ${subscriptionId}`);
    delete this.subscriptions[subscriptionId];
  }

  // *************************
  // Saving and loading
  // *************************

  /**
   * TradingView chart likes to save current symbol with its state.
   * This screws us up when loading state, as chart will switch to a different pair then it should display
   * So we will coerce saved state to have the same pair as is currently loaded on exchange
   * For an example of how this state might look, look in misc/
   */
  private fixUpSavedState(chartSaveState: any): object {
    let successful = false;
    const currentPair = this.store.getState().currentPair;
    if (currentPair) {
      for (const pane of chartSaveState.charts[0].panes) {
        for (const source of pane.sources) {
          if (source.state && source.type === 'MainSeries') {
            source.state.symbol = currentPair.path;
            source.state.shortName = currentPair.path;
            successful = true;
          }
        }
      }
    }

    if (!successful) {
      this.logger.error(`fixUpSavedState has failed. Current pair: ${currentPair}`, chartSaveState);
    }

    return chartSaveState;
  }

  /**
   * Try to get initial saved state for the chart
   */
  getInitialSavedState = (): Promise<object> => {
    const session = this.currentSession;
    if (!session) {
      // Nothing to load
      return Promise.resolve(null);
    }

    return this.api.getTradingViewChartSavedState().then(
      (savedState) => {
        this.logger.info(`Provide initial state for session ${session.access_token}`);
        this.lastLoadedSavedStateAccessToken = session.access_token;
        return this.fixUpSavedState(savedState);
      },
      (err) => {
        this.handleError(err);
        return null;
      }
    );
  };

  /**
   * Try to load saved state from backend. This will be called when user changes
   */
  private tryLoadSavedState = () => {
    this.logger.verbose('tryLoadSavedState()');

    if (!this.widgetReady) {
      this.logger.verbose("Unable to load saved state, widget isn't ready");
      return;
    }

    const session = this.currentSession;
    if (!session) {
      this.logger.verbose('Unable to load saved state, there is no logged in user');
      return;
    }

    if (this.lastLoadedSavedStateAccessToken === session.access_token) {
      this.logger.verbose(
        `We have already loaded the saved state for session ${session.access_token}`
      );
      return;
    }

    guardedAsyncOp(
      [
        () => this.currentSession,
        () => this.widgetReady,
        () => this.lastLoadedSavedStateAccessToken,
      ],
      () => this.api.getTradingViewChartSavedState(),
      (originalValue, currentValue, index) => {
        this.logger.warn(
          `Saved state loading was aborted due to ${
            index === 0
              ? `user logging out`
              : index === 1
              ? `chart unloading`
              : `some other process loading the saved state`
          }`
        );
      }
    ).then(
      (savedState) => {
        if (!savedState) {
          // Nothing to do
          return;
        }

        this.lastLoadedSavedStateAccessToken = session.access_token;
        this.logger.info(`Load saved state for session ${this.currentSession.access_token}`);
        const fixedSavedState = this.fixUpSavedState(savedState);
        this.widget.load(fixedSavedState);
      },
      (err) => this.handleError(err)
    );
  };

  /**
   * Saves the chart state if there are conditions to do so. Called automatically by trading view sometimes.
   */
  saveState = (session?: ISession): boolean => {
    session = session || this.currentSession;

    this.logger.verbose(`saveState(${session ? session.access_token : 'null'})`);

    if (!this.widgetReady) {
      this.logger.verbose("Unable to save chart state, widget isn't ready");
      return false;
    }

    if (!session) {
      this.logger.verbose('Unable to save chart state, there is no active session');
      return false;
    }

    this.currentSaveStateRequestId++;

    guardedAsyncOp(
      [() => this.currentSaveStateRequestId],
      () => new Promise((resolve) => this.widget.save(resolve)),
      (originalValue, currentValue) => {
        this.logger.verbose(
          `Aborted saveState request #${originalValue}, since there has been a newer request (#${currentValue})`
        );
      }
    )
      .then((savedState) =>
        this.http.customAuthRequest(
          session.access_token,
          this.api.putTradingViewChartSavedStateSpec(savedState as object)
        )
      )
      .catch((err) => this.handleError(err));

    return true;
  };
}

// Supported timezones by the candle chart
const SUPPORTED_TIMEZONES: CustomTimezones[] = [
  'America/New_York',
  'America/Los_Angeles',
  'America/Chicago',
  'America/Phoenix',
  'America/Toronto',
  'America/Vancouver',
  'America/Argentina/Buenos_Aires',
  'America/El_Salvador',
  'America/Sao_Paulo',
  'America/Bogota',
  'America/Caracas',
  'Europe/Moscow',
  'Europe/Athens',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Paris',
  'Europe/Rome',
  'Europe/Warsaw',
  'Europe/Istanbul',
  'Europe/Zurich',
  'Australia/Sydney',
  'Australia/Brisbane',
  'Australia/Adelaide',
  'Australia/ACT',
  'Asia/Almaty',
  'Asia/Ashkhabad',
  'Asia/Tokyo',
  'Asia/Taipei',
  'Asia/Singapore',
  'Asia/Shanghai',
  'Asia/Seoul',
  'Asia/Tehran',
  'Asia/Dubai',
  'Asia/Kolkata',
  'Asia/Hong_Kong',
  'Asia/Bangkok',
  'Asia/Chongqing',
  'Asia/Jerusalem',
  'Asia/Kuwait',
  'Asia/Muscat',
  'Asia/Qatar',
  'Asia/Riyadh',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'Pacific/Fakaofo',
  'Pacific/Honolulu',
  'America/Mexico_City',
  'Africa/Cairo',
  'Africa/Johannesburg',
  'Asia/Kathmandu',
  'US/Mountain',
];
