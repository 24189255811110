import { IState } from '../lib/store';
import { IApiMarketRates, IApiRates, IInstrument } from '../types/backend_definitions';

export const getMarketRates = (state: IState): IApiMarketRates => state.rates.market;
export const getRates = (state: IState): IApiRates => state.rates;

export const getConversionRate = (
  rates: IApiRates,
  from: IInstrument,
  to: IInstrument,
  glueInstrument: IInstrument = 'BTC'
) => {
  if (from === to) {
    return 1;
  }

  if (!rates || !rates.market || !rates.external) {
    // rates are not loaded
    return null;
  }

  const resolve = () => {
    if (rates.override) {
      const value =
        (rates.override[from] && rates.override[from][to]) ||
        reverseRate((rates.override[to] && rates.override[to][from]) || null) ||
        rates.override.default;

      if (value) {
        return value;
      }
    }

    if (rates.market[from]) {
      if (rates.market[from][to]) {
        return rates.market[from][to];
      }

      return rates.market[from][glueInstrument] * rates.external[glueInstrument][to];
    }

    if (!rates.external[from]) {
      return null;
    }

    if (rates.market[to]) {
      return rates.external[from][glueInstrument] * rates.market[glueInstrument][to];
    }

    return rates.external[from][to];
  };

  const value = resolve();

  if (value) {
    return value;
  }

  if (rates.fallback) {
    const value =
      (rates.fallback[from] && rates.fallback[from][to]) ||
      reverseRate((rates.fallback[to] && rates.fallback[to][from]) || null) ||
      rates.fallback.default;

    if (value) {
      return value;
    }
  }

  return null;
};

const reverseRate = (rate) => (Number(rate) !== 0 ? 1 / rate : 0);
