import Container from '../../lib/container';
import { handleError } from '../../lib/errors';
import { commit, val } from '../../lib/redux_helpers';
import {
  getNextPageCriteria,
  mergePaginatedResults,
  updatePaginatedResult,
} from '../../lib/redux_helpers';
import { IState, IThunkMethod } from '../../lib/store';
import { isUserLoggedIn } from '../../selectors/auth';
import { getCardPaymentStatus, getUserCardPayments } from '../../selectors/card_payments';
import { IPaginated } from '../../types/api';
import {
  IApiCardPaymentsCriteria,
  IApiCensoredCardPayment,
  IApiPaymentConfigForUser,
  IApiPaymentInstrumentConfig,
} from '../../types/backend_definitions';
import {
  IApiPaymentRequestPayload,
  IApiPaymentResult,
  IApiQuoteRequestPayload,
  IApiQuoteResult,
  IInstrument,
} from '../../types/backend_definitions';
import { getSimpleNotificationData, notifyError, notifySuccess } from '../app';
import { loadBalances } from '../balances';
import { loadingWrapper } from '../request_active';
import { TRANSACTIONS_STATE } from './transactions';

export interface IExtendedApiPaymentConfigForUser extends IApiPaymentConfigForUser {
  loaded: boolean;
}

const DEFAULT_PAGE_SIZE = 25;

export const getQuote = (payload: IApiQuoteRequestPayload) => (
  dispatch,
  _,
  { api }: Container
): Promise<IApiQuoteResult> => {
  return api.postCardPaymentsQuote(payload);
};

export const requestPayment = (payload: IApiPaymentRequestPayload) => (
  dispatch,
  _,
  { api }: Container
): Promise<IApiPaymentResult> => {
  return api.postCardPayment(payload).catch((err) => dispatch(handleError(err)));
};

export const markPaymentUserFlowCompleted = (paymentId: string) => (
  dispatch,
  _,
  { api }: Container
) => {
  return api.putCardPaymentSubmitted(paymentId).catch((err) => dispatch(handleError(err)));
};

export const setCardPaymentUserConfig = (config: IExtendedApiPaymentConfigForUser) =>
  commit('Set card payment user config', {
    transactions: {
      cardPaymentUserConfig: val<IExtendedApiPaymentConfigForUser>(config),
    },
  });

export const setCardPaymentInstrumentConfig = (config: IApiPaymentInstrumentConfig) =>
  commit('Set card payment instrument config', {
    transactions: {
      cardPaymentInstrumentConfig: val<IApiPaymentInstrumentConfig>(config),
    },
  });

export const notifyApprovedPayment = (cardPayment) => (dispatch, _, { i18n }: Container) => {
  dispatch(
    notifySuccess(
      getSimpleNotificationData(
        i18n.t('transactions:cardPayments.paymentApprovedNotification', cardPayment)
      )
    )
  );
};

export const notifyDeclinedPayment = (cardPayment) => (dispatch, _, { i18n }: Container) => {
  dispatch(
    notifyError(
      getSimpleNotificationData(
        i18n.t('transactions:cardPayments.paymentDeclinedNotification', cardPayment)
      )
    )
  );
};

export const resetUserCardPayments = () => (dispatch) => {
  dispatch(
    commit(`Reset user card payments state`, {
      transactions: {
        cardPayments: val<IState['transactions']['cardPayments']>(
          TRANSACTIONS_STATE.transactions.cardPayments
        ),
      },
    })
  );
};

export const loadUserCardPayments = (
  instrument: IInstrument,
  firstPage = false
): IThunkMethod<Promise<void>> => (dispatch, getState, { api }) => {
  const state = getState();
  const oldCardPayments = getUserCardPayments(state);
  const nextPageCriteria = getNextPageCriteria(oldCardPayments, DEFAULT_PAGE_SIZE, firstPage);

  const apiCriteria = {
    ...nextPageCriteria,
    sort_field: ['submitted_at', 'payment_id'],
    sort_direction: 'desc',
  } as IApiCardPaymentsCriteria;

  if (instrument) {
    apiCriteria.instruments = [instrument];
  }

  return dispatch(
    loadingWrapper(
      'getUserCardPayments',
      api
        .getCardPayments(apiCriteria)
        .then((newCardPayments) => {
          if (!isUserLoggedIn(state)) {
            return;
          }

          const mergedCardPayments = mergePaginatedResults<
            IApiCensoredCardPayment,
            IApiCensoredCardPayment
          >(
            newCardPayments,
            firstPage ? ({ items: [] } as IPaginated<IApiCensoredCardPayment>) : oldCardPayments,
            'payment_id'
          );

          dispatch(
            commit('List of user card payments is updated', {
              transactions: {
                cardPayments: val<IState['transactions']['cardPayments']>(mergedCardPayments),
              },
            })
          );
        })
        .catch((err) => dispatch(handleError(err)))
    )
  );
};

export const updateCardPayment = (cardPayment: IApiCensoredCardPayment): IThunkMethod => (
  dispatch,
  getState
) => {
  const oldCardPayments = getUserCardPayments(getState());
  const [updatedCardPayments] = updatePaginatedResult<IApiCensoredCardPayment>(
    oldCardPayments,
    cardPayment,
    'payment_id'
  );

  const notifyData = {
    instrument: cardPayment.instrument,
    quantity: cardPayment.quantity,
  };

  const status = getCardPaymentStatus(cardPayment);

  if (status === 'declined') {
    dispatch(notifyDeclinedPayment(notifyData));
  } else if (status === 'processed') {
    dispatch(notifyApprovedPayment(notifyData));
    dispatch(loadBalances());
  }

  return dispatch(
    commit(
      status === 'processed' ? `Card payment has been processed` : `Update card payment status`,
      {
        transactions: {
          cardPayments: val<IPaginated<IApiCensoredCardPayment>>(updatedCardPayments),
        },
      }
    )
  );
};
