import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { IState } from '../../lib/store';
import { IWhitelabel } from '../../types/constants';
import ChyngexPP from '../components/legal/ChyngexPP';
import XcalibraPP from '../components/legal/XcalibraPP';

interface IPrivacyPolicyPageProps extends ConnectedProps<typeof connector> {}
interface IPrivacyPolicyPageState {}

class PrivacyPolicyPage extends React.PureComponent<
  IPrivacyPolicyPageProps,
  IPrivacyPolicyPageState
> {
  renderPage() {
    const PPPages: { [key in IWhitelabel]: any } = {
      xcalibra: XcalibraPP,
      chyngex: ChyngexPP,
    };

    const Component = PPPages[this.props.whitelabel];
    return <Component />;
  }

  render() {
    return this.renderPage();
  }
}

const connector = connect((state: IState) => ({
  whitelabel: state.env.whitelabel.name,
}));

export default connector(PrivacyPolicyPage);
