import styled from '../../lib/styled_components';

interface ISpanProps {
  monospace?: boolean;
}

export const $Span = styled.span<ISpanProps>`
  ${(p) => p.monospace && `font-family: monospace;`}
`;

export const $DottedSpan = styled($Span)`
  position: relative;
  &:hover:after {
    content: '';
    position: absolute;
    bottom: -1.5px;
    width: 100%;
    left: 0;
    border-bottom: dotted 2px ${(p) => (p.color ? p.theme.colors[p.color] : 'inherit')} !important;
  }
`;
