import { apiKeyRequestTransformation } from '../lib/api_request_transformations';
import { apiKeyResponseTransformation } from '../lib/api_response_transformations';
import Container from '../lib/container';
import { handleError, rethrowTranslated } from '../lib/errors';
import lodash from '../lib/lodash';
import { commit, val } from '../lib/redux_helpers';
import { IState, IStateGetter, IThunkMethod } from '../lib/store';
import { getApiKeyList } from '../selectors/api_keys';
import { IApiKey, IApiKeyPayload } from '../types/api_keys';
import { getSimpleNotificationData, notifySuccess } from './app';
import { loadingWrapper } from './request_active';

export interface IApiKeysState {
  apiKeys: IApiKey[];
}

export const APIKEYS_STATE: IApiKeysState = {
  apiKeys: null,
};

const setApiKeys = (apiKeys: IApiKey[]) =>
  commit('List of api keys is updated', {
    apiKeys: val<IState['apiKeys']>(apiKeys),
  });

const setApiSecretKey = (apiKey: string, secretKey: string): IThunkMethod => (
  dispatch,
  getState
) => {
  const apiKeys: IApiKey[] = [...getApiKeyList(getState())];

  const apiKeyToUpdate = lodash.find(apiKeys, { api_key: apiKey });

  if (apiKeyToUpdate) {
    apiKeyToUpdate.secret_key = secretKey;
    return dispatch(setApiKeys(apiKeys));
  }

  return Promise.resolve();
};

export const loadApiKeys = (): IThunkMethod => (dispatch, getState, { api }) => {
  return dispatch(
    loadingWrapper(
      'getKeys',
      api
        .getBots()
        .then((apiKeys) => dispatch(setApiKeys(apiKeys.map(apiKeyResponseTransformation))))
        .catch((err) => dispatch(handleError(err)))
    )
  );
};

export const loadApiSecretKey = (apiKey: string, tfa_token?: string): IThunkMethod => (
  dispatch,
  getState,
  { api }
) => {
  return dispatch(
    loadingWrapper(
      'getSecretKey',
      (api.getBotSignatureSecret(apiKey, { tfa_token }) as Promise<string>)
        .then((secretKey) => dispatch(setApiSecretKey(apiKey, secretKey)))
        .catch((err) => dispatch(handleError(err)))
    )
  );
};

export const createNewApiKey = (newApiKey?: IApiKeyPayload): IThunkMethod => (
  dispatch,
  getState,
  { api, i18n }
) => {
  return dispatch(
    loadingWrapper(
      'createKey',
      api
        .postBot(newApiKey)
        .then((newApiKeyResponse) => {
          const apiKeys = [
            ...getApiKeyList(getState()),
            apiKeyResponseTransformation(newApiKeyResponse),
          ];

          dispatch(
            notifySuccess(getSimpleNotificationData(i18n.t('settings:api.notifications.keyAdded')))
          );

          return dispatch(setApiKeys(apiKeys));
        })
        .catch((err) => dispatch(handleError(err)))
    )
  );
};

export const editApiKeyOrThrow = (apiKeyData: IApiKey): IThunkMethod<Promise<void>> => (
  dispatch,
  getState,
  { api, i18n }
) => {
  return dispatch(
    loadingWrapper(
      'editKey',
      api
        .putBot(apiKeyData.api_key, apiKeyRequestTransformation(apiKeyData))
        .then((updatedApiKeyResponse) => {
          const apiKeys: IApiKey[] = [...getApiKeyList(getState())];

          const apiKeyToUpdateIndex = lodash.findIndex(apiKeys, {
            api_key: updatedApiKeyResponse.api_key,
          });

          if (apiKeyToUpdateIndex !== -1) {
            apiKeys[apiKeyToUpdateIndex] = apiKeyResponseTransformation(updatedApiKeyResponse);

            dispatch(
              notifySuccess(
                getSimpleNotificationData(
                  i18n.t('settings:api.notifications.keyEdited', {
                    apiKey: updatedApiKeyResponse.api_key.substr(0, 15),
                  })
                )
              )
            );

            dispatch(setApiKeys(apiKeys));
          }
        })
        .catch((err) => dispatch(rethrowTranslated(err)))
    )
  );
};

export const deleteApiKey = (apiKeyToDelete: string) => (
  dispatch,
  getState: IStateGetter,
  { api, i18n }: Container
) => {
  return dispatch(
    loadingWrapper(
      'deleteKey',
      api
        .deleteBot(apiKeyToDelete)
        .then(() => {
          const apiKeys = [...getApiKeyList(getState())].filter(
            (apiKey) => apiKey.api_key !== apiKeyToDelete
          );

          dispatch(
            notifySuccess(
              getSimpleNotificationData(
                i18n.t('settings:api.notifications.keyDeleted', {
                  apiKey: apiKeyToDelete.substr(0, 15),
                })
              )
            )
          );
          return dispatch(setApiKeys(apiKeys));
        })
        .catch((err) => dispatch(handleError(err)))
    )
  );
};
