import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import {
  ISecurityEvents,
  loadSecurityEvents,
  resetUserSecurityEvents,
} from '../../../actions/auth/auth';
import { ICsvDownloadFunctions, downloadCSV } from '../../../actions/download_csv';
import lodash from '../../../lib/lodash';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { formatFullDate, formatFullDateRegular } from '../../../lib/util';
import { LockIcon } from '../../../media/svg_icons';
import {
  ISecurityEventInfoPaginationState,
  createSecurityEventsHorizontalScrollTableData,
  getSecurityEvents,
} from '../../../selectors/auth';
import { ISecurityEventInfo } from '../../../types/auth';
import { SECURITY_EVENTS } from '../../../types/constants';
import { II18nextT } from '../../../types/i18n';
import { ITranslations } from '../../../types/translations';
import { $InfoSmallButton } from '../../widgets/buttons';
import Header from '../../widgets/Header';
import HorizontalScrollTable, {
  $FixedHeightCell,
  $FixedHeightTableRow,
  $HighlightableTableRow,
  IGridColumnProps,
  IScrollableRow,
  ITableParts,
} from '../../widgets/HorizontalScrollTable';
import { $ToolbarLabel } from '../../widgets/Label';
import Modal from '../../widgets/Modal';
import {
  $ActionElementsWrapper,
  $HeaderWrapperJustified,
  $InnerWrapper,
  $NoDataWrapper,
  $Wrapper,
  DownloadCSVButton,
  IDownloadCSVAction,
} from '../../widgets/Reports';
import { ResponsiveSelectWrapper, SimpleSelect } from '../../widgets/Select';
import { $DottedSpan } from '../../widgets/Span';
import { $Cell, $EllipsisWrapper, ICellProps } from '../../widgets/Table';
import TableActionIndicator from '../../widgets/TableActionIndicator';
import ClickableTooltipWithCopy from '../../widgets/tooltips/ClickableTooltipWithCopy';

const $MoreInfoButton = styled($InfoSmallButton)`
  width: 75px;
  @media screen and ${(p) => p.theme.device.mobile} {
    width: 60px;
    margin: 5px 0;
  }
`;

const $ModalTable = styled.table`
  display: block;
  margin: 0 auto;
  padding: 1.3125rem;
  width: 100%;

  border-collapse: collapse;
  font-size: ${(p) => p.theme.fontSize.large};
  height: 100%;

  > tbody {
    margin-bottom: 20px;
    display: block;
    overflow: auto;
    height: 80%;
    width: 100%;
  }
`;

const $ModalLabel = styled.th`
  text-align: right;
  padding: 5px;
`;

const $ModalData = styled.td`
  padding: 5px;
`;

type ICellNames =
  | 'date'
  | 'event'
  | 'browser'
  | 'os'
  | 'city'
  | 'country'
  | 'ipAddress'
  | 'actions';

const CELLS: { [key in ICellNames]?: Partial<ICellProps> } = {
  date: { align: 'center', minWidth: '9rem' },
  event: { align: 'center', minWidth: '6rem' },
  browser: { align: 'center', minWidth: '7rem' },
  os: { align: 'center', minWidth: '6rem' },
  city: { align: 'center', minWidth: '6rem' },
  country: { align: 'center', minWidth: '6rem' },
  ipAddress: { align: 'center', minWidth: '7rem' },
  actions: { align: 'center', minWidth: '5rem' },
};

const GRID_COLUMNS: IGridColumnProps<ICellNames> = {
  fixed: { date: { width: '1fr' } },
  scrollable: {
    event: { width: '3fr' },
    browser: { width: '1fr' },
    os: { width: '1fr' },
    city: { width: '9rem' },
    country: { width: '9rem' },
    ipAddress: { width: '9rem' },
    actions: { width: '9rem' },
  },
};

interface ISecurityEventsProps extends ConnectedProps<typeof connector> {
  stickHeader: boolean;
  getTableHeaderTopOffset?: (topOffset) => void;
  downloadCSV: IDownloadCSVAction;
  ICsvDownloadFunctions: ICsvDownloadFunctions;
}

interface ISecurityEventsState {
  modalContentSecurityEventId: number;
  filterEventType: keyof ISecurityEvents;
  filterOptions: Object;
  loading: boolean;
}

class SecurityEvents extends React.PureComponent<ISecurityEventsProps, ISecurityEventsState> {
  modalRef: React.RefObject<Modal> = React.createRef();
  static contextType: any = I18nContext;
  private mounted: boolean = false;

  constructor(props) {
    super(props);

    this.state = {
      modalContentSecurityEventId: 1,
      filterEventType: 'all',
      filterOptions: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.props.resetUserSecurityEvents();
    this.onLoadSecurityEventsForType(this.state.filterEventType, true);
    this.setState({ filterOptions: this.deriveFilterOptions(SECURITY_EVENTS) });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onLoadSecurityEventsForType = (eventType?: keyof ISecurityEvents, firstPage?: boolean) => {
    this.setState({ loading: true });
    return this.props.loadSecurityEvents(eventType, firstPage).finally(() => {
      this.mounted && this.setState({ loading: false });
    });
  };

  componentDidUpdate(
    prevProps: Readonly<ISecurityEventsProps>,
    prevState: Readonly<ISecurityEventsState>,
    snapshot?: any
  ): void {
    if (prevState.filterEventType !== this.state.filterEventType) {
      this.onLoadSecurityEventsForType(this.state.filterEventType, true);
    }
  }

  deriveFilterOptions(securityEvents) {
    const { t }: II18nextT = this.context;

    return Object.keys(securityEvents).reduce(
      (res, key) => {
        res[key] = {
          value: securityEvents[key],
          label: t(
            `backend:messages.security_events-event_types-${securityEvents[key]}` as ITranslations
          ),
        };
        return res;
      },
      { all: { value: 'all', label: this.context.t('all') } }
    );
  }

  getSecurityEventsPaginationStateByEventType(): ISecurityEventInfoPaginationState {
    const securityEventsByType = this.props.securityEvents[this.state.filterEventType];
    return createSecurityEventsHorizontalScrollTableData(securityEventsByType);
  }

  formatKeyName = (name: string) => {
    const { t }: II18nextT = this.context;
    return t(`backend:messages.security_events-additional_data-${name}` as ITranslations);
  };

  showModal = (modalId: number) => {
    this.setState({ modalContentSecurityEventId: modalId }, () => this.modalRef.current.show());
  };

  modalContent = () => {
    const securityEventInfo = lodash.pickBy(
      this.props.securityEvents[this.state.filterEventType].items[
        this.state.modalContentSecurityEventId
      ]
    );

    if (!securityEventInfo.additional_data) {
      return null;
    }

    const renderModalRowData = (name, value) => {
      if (name.includes('_at')) {
        return formatFullDate(value);
      }

      if (Array.isArray(value)) {
        return value.join(', ');
      }

      return value.toString();
    };

    return (
      <$ModalTable>
        <tbody>
          {securityEventInfo.additional_data.map((data) => {
            if (data.value === '' || data.value === null || data.value === undefined) {
              return null;
            }

            return (
              <tr key={data.name}>
                <$ModalLabel>{this.formatKeyName(data.name)}:</$ModalLabel>
                <$ModalData style={{ wordWrap: 'break-word', maxWidth: 300 }}>
                  {renderModalRowData(data.name, data.value)}
                </$ModalData>
              </tr>
            );
          })}
        </tbody>
      </$ModalTable>
    );
  };

  renderTableParts(): ITableParts {
    const { t }: II18nextT = this.context;
    const securityEventsPaginationState = this.getSecurityEventsPaginationStateByEventType();

    return {
      header: {
        fixed: () => <$FixedHeightCell {...CELLS.date}>{t('date')}</$FixedHeightCell>,
        scrollable: () => {
          return (
            <>
              <$FixedHeightCell {...CELLS.event}>
                {t('securityEvents.columns.event')}
              </$FixedHeightCell>
              <$FixedHeightCell {...CELLS.browser}>
                {t('securityEvents.columns.browser')}
              </$FixedHeightCell>
              <$FixedHeightCell {...CELLS.os}>{t('securityEvents.columns.os')}</$FixedHeightCell>
              <$FixedHeightCell {...CELLS.city}>
                {t('securityEvents.columns.city')}
              </$FixedHeightCell>
              <$FixedHeightCell {...CELLS.country}>
                {t('securityEvents.columns.country')}
              </$FixedHeightCell>
              <$FixedHeightCell {...CELLS.ipAddress}>
                {t('securityEvents.columns.ipAddress')}
              </$FixedHeightCell>
              <$FixedHeightCell {...CELLS.actions}>{t('actions')}</$FixedHeightCell>
            </>
          );
        },
      },
      body: {
        fixed: (highlightedRowIndex, highlightSelectedRow) => {
          return securityEventsPaginationState.items.fixed.map((securityEvent, index) => {
            const date = securityEvent.timestamp;
            return (
              <$HighlightableTableRow
                onMouseOver={() => highlightSelectedRow(index)}
                highlighted={index === highlightedRowIndex}
                key={index}
              >
                <$Cell {...CELLS.date}>
                  <b title={formatFullDate(date)}>{formatFullDateRegular(date)}</b>
                </$Cell>
              </$HighlightableTableRow>
            );
          });
        },
        scrollable: (securityEvent: ISecurityEventInfo, index, highlightSelectedRow) =>
          this.renderScrollableRow(securityEvent, index, highlightSelectedRow),
      },
    };
  }

  renderScrollableRow: IScrollableRow = (
    securityEvent: ISecurityEventInfo,
    index,
    highlightSelectedRow
  ) => {
    const { t }: II18nextT = this.context;

    return (
      <$FixedHeightTableRow onMouseOver={() => highlightSelectedRow(index)} key={securityEvent.id}>
        <$Cell {...CELLS.event}>
          <b>
            {t(
              `backend:messages.security_events-event_types-${securityEvent.event_type}` as ITranslations
            )}
          </b>
        </$Cell>

        <$Cell {...CELLS.browser}>
          <b>{securityEvent.browser_family}</b>
        </$Cell>
        <$Cell {...CELLS.os}>
          <b>{securityEvent.os_family}</b>
        </$Cell>
        <$Cell {...CELLS.city}>
          <b>{securityEvent.city_name}</b>
        </$Cell>
        <$Cell {...CELLS.country}>
          <b>{securityEvent.country_name}</b>
        </$Cell>
        <$Cell {...CELLS.ipAddress}>
          <b>{securityEvent.ip_address}</b>
        </$Cell>
        <$Cell {...CELLS.actions}>
          {securityEvent.additional_data && securityEvent.additional_data.length ? (
            <$MoreInfoButton onClick={() => this.showModal(index)}>
              {t('button.moreInfo')}
            </$MoreInfoButton>
          ) : null}
        </$Cell>
      </$FixedHeightTableRow>
    );
  };

  render() {
    const { t }: II18nextT = this.context;
    const securityEventsPaginationState = this.getSecurityEventsPaginationStateByEventType();
    const criteria =
      this.state.filterEventType !== 'all'
        ? {
            event_types: [this.state.filterEventType],
            sort_direction: ['desc'],
            sort_field: ['timestamp', 'id'],
          }
        : { sort_direction: ['desc'], sort_field: ['timestamp', 'id'] };

    return (
      <$Wrapper>
        <$HeaderWrapperJustified stickHeader={this.props.stickHeader}>
          <Header
            title={t('reportsPage.securityEvents')}
            icon={LockIcon}
            hideOnMobile={true}
            loadingKeys={['getSecurityEvents']}
          />
          {this.state.filterOptions && (
            <$ActionElementsWrapper>
              <div>
                <$ToolbarLabel htmlFor="securityEventType">{t('type')}:</$ToolbarLabel>
                <ResponsiveSelectWrapper>
                  <SimpleSelect
                    inputId="securityEventType"
                    width="16rem"
                    options={Object.values(this.state.filterOptions)}
                    value={this.state.filterOptions[this.state.filterEventType]}
                    onChange={({ value }) => {
                      this.setState({ filterEventType: value });
                    }}
                    variant="transparent"
                  />
                </ResponsiveSelectWrapper>
              </div>
              <div>
                <DownloadCSVButton
                  downloadCsv={this.props.downloadCSV}
                  csvDownloadFunction={'getSecurityEventsInfosCsv'}
                  criteria={criteria}
                />
              </div>
            </$ActionElementsWrapper>
          )}
        </$HeaderWrapperJustified>

        {this.state.loading ? (
          <div />
        ) : !securityEventsPaginationState.total_records ? (
          <$NoDataWrapper>{t('reportsPage.noData.securityEvents')}</$NoDataWrapper>
        ) : (
          <$InnerWrapper>
            <HorizontalScrollTable
              data={securityEventsPaginationState.items.scrollable}
              gridColumns={GRID_COLUMNS}
              stickHeader={this.props.stickHeader}
              getTableHeaderTopOffset={this.props.getTableHeaderTopOffset}
              tableParts={this.renderTableParts()}
            >
              <TableActionIndicator
                paginationState={securityEventsPaginationState}
                requests={['getSecurityEvents']}
                itemsCount={securityEventsPaginationState.items.length}
                actionFn={() => this.props.loadSecurityEvents(this.state.filterEventType)}
              />
            </HorizontalScrollTable>
          </$InnerWrapper>
        )}

        <Modal title={t('securityEvents.additionalInfo')} titleUppercase={true} ref={this.modalRef}>
          {this.modalContent()}
        </Modal>
      </$Wrapper>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    securityEvents: getSecurityEvents(state),
  }),
  {
    resetUserSecurityEvents,
    loadSecurityEvents,
    downloadCSV,
  }
);

export default connector(SecurityEvents);
