import React from 'react';

import { IToastNotification, IToastNotificationType } from '../../actions/app';
import styled, { IThemeColorsUnion } from '../../lib/styled_components';
import { formatRelativeTimeFromNow } from '../../lib/util';
import { CheckIcon, ErrorIcon, InfoIcon, TradingIcon, WarningIcon } from '../../media/svg_icons';

const $NotificationDate = styled.div`
  padding-top: 0.5rem;
  font-size: 12px;
`;

const $NotificationMessage = styled.li`
  font-size: ${(p) => p.theme.fontSize.large};
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
`;
const $NotificationMessages = styled.ul<{ showBullet?: boolean }>`
  list-style: ${(p) => (p.showBullet ? 'disc' : 'none')};
  padding-left: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const $Notification = styled.div`
  padding: 1rem;
  color: #fff;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1.5rem;
  word-break: break-word;
`;
const $NormalNotification = styled($Notification)`
  border-bottom: 1px solid ${(p) => p.theme.colors.brandPrimaryDark};
  &:last-child {
    border-bottom: none;
  }
`;
const $PushNotification = styled($Notification)<{ backgroundColor: IThemeColorsUnion }>`
  background: ${(p) => p.theme.colors[p.backgroundColor]};
`;

const $IconWrapper = styled.div<{ color: IThemeColorsUnion; inverse?: boolean }>`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2rem;
    height: 2rem;
    * {
      fill: ${(p) => p.inverse && p.theme.colors[p.color]};
    }
    fill: ${(p) => p.inverse && p.theme.colors[p.color]};
  }
`;

const $FilledIconWrapper = styled($IconWrapper)`
  background: ${(p) => (p.inverse ? p.theme.colors[p.color] : p.theme.colors.white)};
  svg {
    > * {
      fill: ${(p) => (p.inverse ? 'white' : p.theme.colors[p.color])};
    }
    fill: ${(p) => (p.inverse ? 'white' : p.theme.colors[p.color])};
  }
`;

const $SuccessIconWrapper = styled($FilledIconWrapper)`
  svg {
    width: 1.5rem;
  }
`;

const $BuySellIconWrapper = styled($IconWrapper)`
  svg {
    width: 3rem;
  }
`;

interface INotificationProps {
  notification: IToastNotification;
}

const icons: { [type in IToastNotificationType]: any } = {
  success: CheckIcon,
  info: InfoIcon,
  error: ErrorIcon,
  warning: WarningIcon,
  buy: TradingIcon,
  sell: TradingIcon,
};
const iconWrapper: { [type in IToastNotificationType]: any } = {
  success: $SuccessIconWrapper,
  info: $FilledIconWrapper,
  error: $FilledIconWrapper,
  warning: $IconWrapper,
  buy: $BuySellIconWrapper,
  sell: $BuySellIconWrapper,
};

const renderNotificationIcon = (type: IToastNotificationType, inverse?: boolean) => {
  const Icon = icons[type];
  const IconWrapper = iconWrapper[type];
  return (
    <IconWrapper color={type} inverse={inverse}>
      <Icon />
    </IconWrapper>
  );
};

export const NormalNotification: React.FC<INotificationProps> = ({ notification }) => {
  const { date, messages, type } = notification;

  return (
    <$NormalNotification>
      {renderNotificationIcon(type, true)}
      <div style={{ paddingLeft: messages.length > 1 ? '1rem' : '0' }}>
        <$NotificationMessages showBullet={messages.length > 1}>
          {messages.map((message, i) => (
            <$NotificationMessage key={i}>{message}</$NotificationMessage>
          ))}
        </$NotificationMessages>
        <$NotificationDate>{formatRelativeTimeFromNow(date)}</$NotificationDate>
      </div>
    </$NormalNotification>
  );
};

export const PushNotification: React.FC<INotificationProps> = ({ notification }) => {
  const { messages, type } = notification;
  return (
    <$PushNotification backgroundColor={type}>
      {renderNotificationIcon(type)}
      <$NotificationMessages
        showBullet={messages.length > 1}
        style={{ paddingLeft: messages.length > 1 && '1rem' }}
      >
        {messages.map((message, i) => (
          <$NotificationMessage key={i}>{message}</$NotificationMessage>
        ))}
      </$NotificationMessages>
    </$PushNotification>
  );
};
