import { submitSession } from '../actions/auth/auth';
import Container from '../lib/container';
import { handleError } from '../lib/errors';
import { Logger } from '../lib/logger';
import { Store } from '../lib/store';
import { getSession } from '../selectors/auth';

/**
 * Create a new session based on the current one.
 * Controlled by ISession.expires_at
 */
export const renewSession = () => (dispatch, _, { api }: Container) => {
  return api
    .postAuthRenew()
    .then((session) => dispatch(submitSession(session)))
    .catch((err) => dispatch(handleError(err)));
};

/**
 * Service that keeps track of session renewal time and renews it when the time is right.
 */
export class SessionRenewer {
  private expiresAt: string;
  private renewalTimeout;

  constructor(private store: Store, private logger: Logger) {
    this.logger = this.logger.prefixed(this);
    this.store.subscribe(() => this.update());
  }

  start() {
    this.update();
  }

  stop() {
    this.clearRenewal();
  }

  private update() {
    const state = this.store.getState();
    const session = getSession(state);

    // Check expiration timeout
    const targetExpiresAt = (session && session.expires_at) || null;
    if (targetExpiresAt !== this.expiresAt) {
      this.updateRenewal(targetExpiresAt);
    }
  }

  private updateRenewal(targetExpiresAt: string) {
    this.clearRenewal();
    this.expiresAt = targetExpiresAt;

    if (!targetExpiresAt) {
      // Nothing else to do
      return;
    }

    const remainingDuration = new Date(targetExpiresAt).valueOf() - new Date().valueOf();
    // No need to set if not a decent chance we can make the renewal
    if (remainingDuration >= 100) {
      this.renewalTimeout = setTimeout(() => this.renewSession(), remainingDuration * 0.95);
      this.logger.info(`Session renewal scheduled for ${remainingDuration * 0.95}ms from now`);
    }
  }

  private clearRenewal() {
    if (this.renewalTimeout) {
      clearTimeout(this.renewalTimeout);
      this.renewalTimeout = null;
    }
  }

  private renewSession() {
    this.logger.info(`Triggering session renewal`);
    (this.store.dispatch as any)(renewSession());
    this.clearRenewal();
  }
}
