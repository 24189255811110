import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { I18n } from '../../../lib/i18n';
import R from '../../../lib/routes';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { CoinMarketCapIcon } from '../../../media/svg_icons';
import $Link from '../../widgets/Link';
import SystemTime from '../../widgets/SystemTime';

const $FooterLinks = styled.div`
  padding-bottom: 1rem;
  @media screen and ${(p) => p.theme.device.mobile} {
    padding-bottom: 0;
    padding-top: 1rem;
  }
`;

const $LinksRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  height: 1.5rem;
  align-items: center;
`;

const $FooterLink = styled($Link as any)`
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  &:first-child {
    text-align: right;
  }
`;

const $CMCFooterLink = styled($FooterLink as any)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  svg {
    width: 1.3rem;
    height: 1.3rem;
    margin-right: 0.4rem;
  }
`;

const $FooterLinkSplitter = styled.span`
  margin: 0 0.4rem;
  vertical-align: middle;
`;

const $SystemTimeWrapper = styled.div`
  margin: 5px 0 0;
  display: flex;
  justify-content: center;
`;

interface IFooterLinksProps extends ConnectedProps<typeof connector> {}

const FooterLinks: React.FunctionComponent<IFooterLinksProps> = ({
  cmcPageLink,
  landingPageLink,
  exchangeTitle,
}) => {
  return (
    <$FooterLinks>
      <I18n>
        {(t) => (
          <>
            <$LinksRow>
              <$CMCFooterLink href={cmcPageLink} target="_blank">
                <CoinMarketCapIcon />
                <span>{t('footerLinks.cmc')}</span>
              </$CMCFooterLink>
              <$FooterLinkSplitter>|</$FooterLinkSplitter>
              <$FooterLink href={landingPageLink} target="_blank">
                {exchangeTitle}
              </$FooterLink>
            </$LinksRow>
            <$LinksRow>
              <$FooterLink to={R.TERMS_OF_USE} target="_blank" as={Link}>
                {t('footerLinks.termsOfUse')}
              </$FooterLink>
              <$FooterLinkSplitter>|</$FooterLinkSplitter>
              <$FooterLink to={R.PRIVACY_POLICY} target="_blank" as={Link}>
                {t('footerLinks.privacyPolicy')}
              </$FooterLink>
            </$LinksRow>
          </>
        )}
      </I18n>
      <$SystemTimeWrapper>
        <SystemTime />
      </$SystemTimeWrapper>
    </$FooterLinks>
  );
};

const connector = connect((state: IState) => ({
  cmcPageLink: state.env.links.cmcPage,
  landingPageLink: state.env.links.landingPage,
  exchangeTitle: state.env.whitelabel.exchangeTitle,
}));

export default connector(FooterLinks);
