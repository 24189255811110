import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  loadAllDepositAddress,
  loadDepositAddress,
  loadDepositsForInstrument,
} from '../../../actions/transactions/deposits';
import { I18n } from '../../../lib/i18n';
import lodash from '../../../lib/lodash';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { formatCompactTime, formatFullDate } from '../../../lib/util';
import { BoxIcon, CSVIcon } from '../../../media/svg_icons';
import { getCardPaymentConfig } from '../../../selectors/card_payments';
import { getInstrumentsConfig } from '../../../selectors/exchange';
import {
  getDepositsAddressForInstrument,
  getDepositsForInstrument,
} from '../../../selectors/transactions';
import { IPaginated } from '../../../types/api';
import { IMarketInstrument } from '../../../types/backend_definitions';
import { II18nextT } from '../../../types/i18n';
import { IInstrumentObject } from '../../../types/instruments';
import { IDeposit } from '../../../types/transactions';
import { ITranslations } from '../../../types/translations';
import { DeepReadonly } from '../../../types/typescript_helpers';
import { $ContentWrapper } from '../../pages/transactions/TransactionPagesLayout';
import { HideMobile } from '../../utility_components';
import AddressBox from '../../widgets/AddressBox';
import { AlertWarningWithIcon } from '../../widgets/Alert';
import { $InfoSmallButton, $SmallButton } from '../../widgets/buttons';
import Header from '../../widgets/Header';
import { $HeaderWrapper } from '../../widgets/PageHeaderWrappers';
import ScrollBox from '../../widgets/ScrollBox';
import { $DottedSpan, $Span } from '../../widgets/Span';
import {
  $DigitCell,
  $EllipsisWrapper,
  $HeadingCell,
  $Table,
  $TableHeading,
  $TableRow,
  ICellProps,
} from '../../widgets/Table';
import TableActionIndicator from '../../widgets/TableActionIndicator';
import ClickableTooltipWrapper from '../../widgets/tooltips/ClickableTooltipWrapper';
import GenericTooltip from '../../widgets/tooltips/GenericTooltip';
import BuyWithCard from '../buy_with_card/BuyWithCard';
import TransactionsBalances from './CurrentBalance';
import DepositStatus from './DepositStatus';
import RSDDeposit from './RSDDeposit';

type DepositCellNames = 'transactionId' | 'quantity' | 'fee' | 'status' | 'id' | 'time';
const DEPOSIT_CELLS: { [key in DepositCellNames]: Partial<ICellProps> } = {
  transactionId: { width: '30%' },
  quantity: { width: '10%', align: 'center' },
  fee: { width: '10%', align: 'center' },
  status: { width: '15%', align: 'center' },
  id: { width: '15%', align: 'center' },
  time: { width: '20%', align: 'right' },
};

interface ITRowProps {
  deposit: IDeposit;
}

class TableRow extends React.PureComponent<ITRowProps> {
  static contextType: any = I18nContext;

  render() {
    const { deposit } = this.props;
    const time = deposit.finalized_at || deposit.created_at;

    return (
      <$TableRow noPointer>
        <$DigitCell {...DEPOSIT_CELLS.transactionId}>
          <ClickableTooltipWrapper overlay={deposit.transaction_id}>
            <$EllipsisWrapper>
              <$DottedSpan monospace>{deposit.transaction_id}</$DottedSpan>
            </$EllipsisWrapper>
          </ClickableTooltipWrapper>
        </$DigitCell>
        <$DigitCell {...DEPOSIT_CELLS.quantity}>
          <b>{deposit.quantity}</b>
        </$DigitCell>
        <$DigitCell {...DEPOSIT_CELLS.fee}>
          <b>{deposit.fee_total}</b>
        </$DigitCell>
        <$DigitCell {...DEPOSIT_CELLS.status}>
          <DepositStatus deposit={deposit} />
        </$DigitCell>
        <$DigitCell {...DEPOSIT_CELLS.id}>
          <$Span monospace>{deposit.id}</$Span>
        </$DigitCell>
        <$DigitCell {...DEPOSIT_CELLS.time}>
          <b title={formatFullDate(time)}>{formatCompactTime(time)}</b>
        </$DigitCell>
      </$TableRow>
    );
  }
}

const $Deposit = styled.div`
  margin-bottom: 1rem;
`;

const $NewDeposit = styled.div`
  @media ${(p) => p.theme.device.mobile} {
    padding: 0;
  }
`;

const $TopRow = styled.div`
  display: flex;
  @media ${(p) => p.theme.device.laptop} {
    display: block;
  }
`;

const $TopRowRight = styled.div`
  margin-left: 2rem;
  @media ${(p) => p.theme.device.laptop} {
    margin-left: 0;
    margin-top: 2rem;
  }
`;

const $BuyWithCardButton = styled($SmallButton).attrs(() => ({
  backgroundColor: '#eaeaea',
  color: '#545454',
}))`
  height: 25px;
  line-height: 1;
  font-weight: 100;
`;
const $ShowAddressButton = styled($InfoSmallButton)`
  height: 25px;
  line-height: 1;
  font-weight: 100;
`;

export type IDepositPaginationState = IPaginated<IDeposit>;

interface IOwnProps {
  instrument?: IInstrumentObject;
}
interface IDepositProps extends RouteComponentProps, ConnectedProps<typeof connector>, IOwnProps {}

interface IDepositState {}

class Deposit extends React.PureComponent<IDepositProps, IDepositState> {
  static contextType: any = I18nContext;
  private buyWithCardRef: React.RefObject<any> = React.createRef();

  componentDidMount() {
    this.props.loadAllDepositAddress();
    this.props.loadDepositsForInstrument(this.props.instrument.symbol, true);
  }

  componentDidUpdate(prevProps: Readonly<IDepositProps>): void {
    if (prevProps.instrument.symbol !== this.props.instrument.symbol) {
      this.props.loadDepositsForInstrument(this.props.instrument.symbol, true);
    }
  }

  renderHistoryTable = (deposits: DeepReadonly<IDepositPaginationState>) => {
    const { instrument } = this.props;
    return (
      <I18n>
        {(t) => (
          <$Table noPadding darkBackground>
            <$TableHeading>
              <$HeadingCell {...DEPOSIT_CELLS.transactionId}>
                {t('transactions:deposits.transactionId')}
              </$HeadingCell>
              <$HeadingCell {...DEPOSIT_CELLS.quantity}>
                {t('amount')} ({instrument.symbol})
              </$HeadingCell>
              <$HeadingCell {...DEPOSIT_CELLS.fee}>
                {t('fee')} ({instrument.symbol})
              </$HeadingCell>
              <$HeadingCell {...DEPOSIT_CELLS.status}>{t('transactions:status')}</$HeadingCell>
              <$HeadingCell {...DEPOSIT_CELLS.id}>{t('transactions:deposits.id')}</$HeadingCell>
              <$HeadingCell width="20%" align="right">
                {t('time')}
              </$HeadingCell>
            </$TableHeading>
            <ScrollBox height="390px">
              {deposits.items.map((deposit) => (
                <TableRow key={deposit.id} deposit={deposit} />
              ))}
              <TableActionIndicator
                paginationState={this.props.deposits}
                requests={['loadDepositsForInstrument']}
                itemsCount={this.props.deposits.items.length}
                actionFn={() => this.props.loadDepositsForInstrument(this.props.instrument.symbol)}
              />
            </ScrollBox>
          </$Table>
        )}
      </I18n>
    );
  };

  renderBuyWithCardButton(instrument: IInstrumentObject) {
    const { t }: II18nextT = this.context;
    const { cardPaymentConfig } = this.props;

    let isDisabled = null;
    let disableReasonKey = null;

    if (cardPaymentConfig.userConfig.disable_service) {
      isDisabled = true;
      disableReasonKey = cardPaymentConfig.userConfig.disable_service_reason;
    } else if (!!cardPaymentConfig.userConfig.disable_instruments[instrument.symbol]) {
      isDisabled = true;
      disableReasonKey = cardPaymentConfig.userConfig.disable_instruments[instrument.symbol];
    }

    const buyButton = () => (
      <$BuyWithCardButton
        onClick={() => this.buyWithCardRef.current.initializeWithInstrument(instrument.symbol)}
        disabled={isDisabled}
      >
        {t('transactions:cardPayments.depositWithCardButton')}
      </$BuyWithCardButton>
    );

    if (isDisabled) {
      return (
        <GenericTooltip
          wrapperProps={{ disabled: isDisabled }}
          overlay={
            lodash.isString(disableReasonKey)
              ? ((disableReasonKey.startsWith('deposits_disabled_reasons') ||
                  disableReasonKey.startsWith('card_payments_disabled_reasons')) &&
                  t(`backend:messages.${disableReasonKey}` as ITranslations)) ||
                disableReasonKey
              : t('transactions:deposits.depositsAreDisabled')
          }
        >
          {buyButton()}
        </GenericTooltip>
      );
    }

    return (
      <GenericTooltip
        overlay={t('transactions:cardPayments.depositWithCardButtonTooltip', {
          instrument: instrument.name,
        })}
      >
        {buyButton()}
      </GenericTooltip>
    );
  }

  renderOtherOptions() {
    const { instrument, cardPaymentConfig } = this.props;

    if (
      !cardPaymentConfig ||
      !cardPaymentConfig.instrumentConfig.supported_crypto.includes(instrument.symbol)
    ) {
      return null;
    }

    return (
      <$TopRowRight>
        <$NewDeposit>
          <Header capitalized title={'Other options'} icon={BoxIcon} iconSize={25} />
          {this.renderBuyWithCardButton(instrument)}
        </$NewDeposit>
      </$TopRowRight>
    );
  }

  renderNewDeposit() {
    const { instrument, depositAddress, config } = this.props;
    const { t }: II18nextT = this.context;

    if (!config || !config[instrument.symbol]) {
      // Still loading
      // TODO: This is always truthy, so something gets pre-populated. Why?
      return null;
    }

    /**
     * create info message about deposit fee based on fee configuration for a instrument
     *
     * there are 4 variants:
     * 1. no fee
     * 2. fixed fee
     * 3. percentage fee
     * 4. both fixed and percentage fee
     */
    let feeNotice: string;
    const { deposit_fee_abs, deposit_fee_pct } = config[instrument.symbol];

    if (parseFloat(deposit_fee_abs)) {
      feeNotice = deposit_fee_pct
        ? t('transactions:deposits.infoAboutFixedAndPercentageFee', {
            depositFeeFixed: deposit_fee_abs,
            depositFeePercentage: deposit_fee_pct,
            instrumentSymbol: instrument.symbol,
          })
        : t('transactions:deposits.infoAboutFixedFee', {
            depositFeeFixed: deposit_fee_abs,
            instrumentSymbol: instrument.symbol,
          });
    } else {
      feeNotice = deposit_fee_pct
        ? t('transactions:deposits.infoAboutPercentageFee', {
            depositFeePercentage: deposit_fee_pct,
            instrumentSymbol: instrument.symbol,
          })
        : t('transactions:deposits.infoAboutNoFee');
    }

    if (config[instrument.symbol].disable_deposits) {
      return (
        <AlertWarningWithIcon>
          {t('transactions:deposits.depositsAreDisabled')} <br />
          {config[instrument.symbol].disable_deposits_reason
            ? (config[instrument.symbol].disable_deposits_reason as string).startsWith(
                'deposits_disabled_reasons'
              )
              ? t(`backend:messages.${config[instrument.symbol].disable_deposits_reason}` as any)
              : config[instrument.symbol].disable_deposits_reason
            : null}
        </AlertWarningWithIcon>
      );
    }

    if (depositAddress) {
      return instrument.symbol === 'RSD' ? (
        <RSDDeposit depositAddress={depositAddress} />
      ) : (
        <AddressBox
          address={depositAddress}
          instrumentSymbol={instrument.symbol}
          feeNotice={feeNotice}
        />
      );
    }

    return (
      <$ShowAddressButton
        onClick={() => this.props.loadDepositAddress(instrument.symbol as IMarketInstrument)}
      >
        {t('transactions:deposits.showAddress')}
      </$ShowAddressButton>
    );
  }

  render() {
    const { instrument, deposits } = this.props;
    const { t }: II18nextT = this.context;

    return (
      <$Deposit>
        <HideMobile>
          <$HeaderWrapper>
            <Header
              title={instrument.name.toUpperCase()}
              subtitle={t('transactions:deposits.deposit')}
              icon={instrument.symbol}
            />
          </$HeaderWrapper>
        </HideMobile>

        <$ContentWrapper>
          <$TopRow>
            <TransactionsBalances instrument={instrument} />
            <$TopRowRight>
              <$NewDeposit>
                <Header
                  capitalized
                  title={t('transactions:deposits.newDeposit')}
                  icon={BoxIcon}
                  iconSize={25}
                />
                {this.renderNewDeposit()}
              </$NewDeposit>
            </$TopRowRight>
            {this.renderOtherOptions()}
          </$TopRow>

          <Header
            marginTop={30}
            capitalized
            title={t('transactions:deposits.depositHistory')}
            icon={CSVIcon}
            iconSize={25}
          />
          {this.renderHistoryTable(deposits)}
        </$ContentWrapper>
        <BuyWithCard showModal={true} ref={this.buyWithCardRef} />
      </$Deposit>
    );
  }
}

const connector = connect(
  (state: IState, props: IOwnProps) => ({
    depositAddress: getDepositsAddressForInstrument(state, props.instrument.symbol),
    config: getInstrumentsConfig(state),
    deposits: getDepositsForInstrument(state, props.instrument.symbol),
    cardPaymentConfig: getCardPaymentConfig(state),
  }),
  {
    loadDepositAddress,
    loadDepositsForInstrument,
    loadAllDepositAddress,
  }
);

export default withRouter(connector(Deposit));
