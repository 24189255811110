import QRCode from 'qrcode.react';
import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { confirmTFAOrThrow, disableTFAOrThrow } from '../../../../actions/settings';
import { ITranslatedError } from '../../../../lib/errors';
import { IState } from '../../../../lib/store';
import styled from '../../../../lib/styled_components';
import twoFactorAuthEnabledIcon from '../../../../media/green-key.png';
import twoFactorAuthDisabledIcon from '../../../../media/red-key.png';
import { getUserEmail } from '../../../../selectors/auth';
import { II18nextT } from '../../../../types/i18n';
import { $Button } from '../../../widgets/buttons';
import { $CodeInputDark } from '../../../widgets/CodeInput';
import { $ReadOnlyInputDark } from '../../../widgets/Input';

const $QRCodeHash = styled($ReadOnlyInputDark)`
  font-size: ${(p) => p.theme.fontSize.largest};
  text-align: center;
  resize: none;
  padding: 1em;
  vertical-align: middle;
  margin-top: 1em;
`;

export const $ButtonTFA = styled($Button).attrs((p) => ({
  backgroundColor: p.theme.colors.buy,
  color: p.theme.colors.white,
}))`
  width: 100%;
`;

export const $ButtonTFADanger = styled($Button).attrs((p) => ({
  backgroundColor: p.theme.colors.sell,
  color: p.theme.colors.white,
}))`
  width: 100%;
`;

export const $QRWrapper = styled.div`
  padding: 0.4em;
  background-color: ${(p) => p.theme.colors.brandPrimaryLight};
`;

interface IEnableTFAProps extends ConnectedProps<typeof enableTFAConnector> {
  tfaSecretKey: string;
  finishTfaProcess: () => any;
  setTFAServerError: (errorResponse: ITranslatedError | false) => void;
  trackTFAProcess: (step: number) => void;
}

interface IEnableTFAState {
  step: number;
  tfaToken: string;
}

class EnableTFAClass extends React.PureComponent<IEnableTFAProps, IEnableTFAState> {
  static contextType: any = I18nContext;
  private mounted = false;

  constructor(props) {
    super(props);
    this.state = { step: 1, tfaToken: '' };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  otpAuthAddress = () =>
    `otpauth://totp/${this.props.whitelabel}:${this.props.email}?secret=${this.props.tfaSecretKey}`;

  goToNextStep = () => {
    this.setState(
      (prevState) => ({ step: prevState.step + 1 }),
      () => {
        this.props.trackTFAProcess(this.state.step);
      }
    );
    this.props.setTFAServerError(false);
  };

  confirmTfaProcess = () => {
    if (this.mounted) {
      this.props
        .confirmTFAOrThrow(this.state.tfaToken)
        .then(() => this.mounted && this.goToNextStep())
        .catch(
          (errorResponse: ITranslatedError) =>
            this.mounted && this.props.setTFAServerError(errorResponse)
        );
    }
  };

  render() {
    const { step, tfaToken } = this.state;
    const { t }: II18nextT = this.context;

    switch (step) {
      case 1:
        const { tfaSecretKey } = this.props;
        return (
          <>
            {tfaSecretKey && (
              <$QRWrapper>
                <QRCode size={180} value={this.otpAuthAddress()} />
              </$QRWrapper>
            )}
            <$QRCodeHash value={tfaSecretKey} />
            <p>{t('settings:security.tfaEnable.qrInstruction')}</p>
            <$ButtonTFA onClick={this.goToNextStep}>{t('button.next')}</$ButtonTFA>
          </>
        );
      case 2:
        return (
          <>
            <$CodeInputDark
              value={tfaToken}
              onChange={(e) =>
                this.setState({
                  tfaToken: e.currentTarget.value,
                })
              }
              onKeyPress={(e) => e.key === 'Enter' && this.confirmTfaProcess()}
              fontSize="2.5em"
            />
            <p>{t('settings:security.tfaEnable.enterCode')}</p>
            <$ButtonTFA onClick={this.confirmTfaProcess}>{t('button.submit')}</$ButtonTFA>
          </>
        );
      case 3:
        return (
          <>
            <img src={twoFactorAuthEnabledIcon} alt="2FA enabled" />
            <p>{t('settings:security.tfaEnable.enabled')}</p>
            <$ButtonTFA onClick={this.props.finishTfaProcess}>{t('button.ok')}</$ButtonTFA>
          </>
        );
      default:
        return null;
    }
  }
}

const enableTFAConnector = connect(
  (state: IState) => ({
    email: getUserEmail(state),
    whitelabel: state.env.whitelabel.name,
  }),
  {
    confirmTFAOrThrow,
  }
);

export const EnableTFA = enableTFAConnector(EnableTFAClass);

// DISABLE TFA

interface IDisableTFAProps extends ConnectedProps<typeof disableTFAConnector> {
  finishTfaProcess: () => any;
  setTFAServerError: (errorResponse: ITranslatedError | false) => void;
  trackTFAProcess: (step: number) => void;
}

interface IDisableTFAState {
  step: number;
  tfaToken: string;
}

class DisableTFAClass extends React.PureComponent<IDisableTFAProps, IDisableTFAState> {
  static contextType: any = I18nContext;
  private mounted = false;

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      tfaToken: '',
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  goToNextStep = () => {
    this.setState(
      (prevState) => ({ step: prevState.step + 1 }),
      () => {
        this.props.trackTFAProcess(this.state.step);
      }
    );
    this.props.setTFAServerError(false);
  };

  confirmTfaProcess = () => {
    this.props
      .disableTFAOrThrow(this.state.tfaToken)
      .then(() => this.mounted && this.goToNextStep())
      .catch(
        (errorResponse: ITranslatedError) =>
          this.mounted && this.props.setTFAServerError(errorResponse)
      );
  };

  render() {
    const { step, tfaToken } = this.state;
    const { t }: II18nextT = this.context;

    switch (step) {
      case 1:
        return (
          <>
            <p>{t('settings:security.tfaDisable.whyNot')}</p>
            <$ButtonTFADanger onClick={this.goToNextStep}>{t('button.continue')}</$ButtonTFADanger>
          </>
        );
      case 2:
        return (
          <>
            <$CodeInputDark
              value={tfaToken}
              onChange={(e) =>
                this.setState({
                  tfaToken: e.currentTarget.value,
                })
              }
              onKeyPress={(e) => e.key === 'Enter' && this.confirmTfaProcess()}
              fontSize="2.5em"
            />
            <p>{t('settings:security.tfaDisable.enterCode')}</p>
            <$ButtonTFADanger onClick={this.confirmTfaProcess}>
              {t('button.submit')}
            </$ButtonTFADanger>
          </>
        );
      case 3:
        return (
          <>
            <img src={twoFactorAuthDisabledIcon} alt="2FA disabled" />
            <p>{t('settings:security.tfaDisable.disabled')}</p>
            <$ButtonTFADanger onClick={this.props.finishTfaProcess}>
              {t('button.ok')}
            </$ButtonTFADanger>
          </>
        );
      default:
        return null;
    }
  }
}

const disableTFAConnector = connect(null, {
  disableTFAOrThrow,
});

export const DisableTFA = disableTFAConnector(DisableTFAClass);
