import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { createSelector } from 'reselect';

import { agreeToManagedTOS } from '../../../actions/transactions/managed';
import {
  clearPhoneVerificationState,
  openPhoneVerificationModal,
} from '../../../bl/phone_verification';
import { Trans, intentionallyUntranslated } from '../../../lib/i18n';
import R from '../../../lib/routes';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { CheckIcon, CheckedPaperIcon, FailIcon, HourglassIcon } from '../../../media/svg_icons';
import { getCustomer } from '../../../selectors/auth';
import { getManagedConfig } from '../../../selectors/transactions';
import { IApiPublicCustomerInfo, ICountryCode } from '../../../types/backend_definitions';
import { II18nextT } from '../../../types/i18n';
import { $SuccessMessage } from '../../layout/KioskLayout';
import Alert, { AlertWarningWithIcon } from '../../widgets/Alert';
import { $InfoButton } from '../../widgets/buttons';
import CountryLabel from '../../widgets/CountryLabel';
import Header from '../../widgets/Header';
import { $AlertWrapper } from '../../widgets/KycElements';
import $Link from '../../widgets/Link';
import { $HeaderWrapperJustified, $InnerWrapper, $Wrapper } from '../../widgets/Managed';
import HelpTooltip from '../../widgets/tooltips/HelpTooltip';
import { KYCLockedNotice } from '../settings/kyc/KYCFailureNotice';
import ObtainKycLevel1 from '../settings/kyc/ObtainKycLevel1';
import ObtainKycLevel2 from '../settings/kyc/ObtainKycLevel2';
import ObtainKycLevel3 from '../settings/kyc/ObtainKycLevel3';
import ManagedCountries from './ManagedCountries';

// *********************************************************************************************************************

type IIndication = 'success' | 'failure' | 'waiting';

const $IndicationIcon = styled.span`
  > svg {
    width: 17px;
    height: 17px;
    vertical-align: middle;
  }
`;

const IndicationIconComponent: React.FunctionComponent<
  { indication: IIndication } & ConnectedProps<typeof indicationConnect>
> = ({ indication, theme }) => {
  let icon;
  switch (indication) {
    case 'success':
      icon = <CheckIcon color={theme.colors.success} />;
      break;
    case 'failure':
      icon = <FailIcon color={theme.colors.redLight} />;
      break;
    case 'waiting':
      icon = <HourglassIcon color={theme.colors.grayLightest} />;
      break;
    default:
      return null;
  }

  return <$IndicationIcon>{icon}</$IndicationIcon>;
};

const indicationConnect = connect(
  (state: IState) => ({
    theme: state.app.theme,
  }),
  {}
);

const IndicationIcon = indicationConnect(IndicationIconComponent);

// *********************************************************************************************************************

const $Step = styled.div``;

const $StepTitle = styled.div<{ expandable: boolean; expanded: boolean }>`
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  align-items: center;
  background-color: ${(p) =>
    p.expandable
      ? p.expanded
        ? p.theme.components.managedStep.expandable.expandedBackground
        : p.theme.components.managedStep.expandable.background
      : p.expanded
      ? p.theme.components.managedStep.static.expandedBackground
      : p.theme.components.managedStep.static.background};
  color: ${(p) =>
    p.expandable
      ? p.theme.components.managedStep.expandable.color
      : p.expanded
      ? p.theme.components.managedStep.static.expandedColor
      : p.theme.components.managedStep.static.color};
  height: 50px;

  cursor: ${(p) => (p.expandable ? 'pointer' : 'default')};

  &:hover {
    background-color: ${(p) =>
      p.expandable ? p.theme.components.managedStep.expandable.hoverBackground : undefined};
  }

  > :first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: ${(p) => p.theme.fontSize.larger};
    background: rgba(255, 255, 255, 0.1);
    height: 100%;
  }

  > h3 {
    margin: 0;
    padding: 0 10px;
  }
`;

const $StepCollapser = styled.div<{ height: number }>`
  transition: height 0.2s;
  overflow: ${(p) => (p.height ? null : 'hidden')};
  position: relative;
  height: ${(p) => p.height}px;

  > div {
    padding: 1rem;
    position: absolute;
    width: 100%;
  }
`;

class Step extends React.PureComponent<
  {
    number: number;
    title: React.ReactNode;
    indication?: IIndication;
    expanded?: boolean;
  },
  { expanded: boolean; expandedHeight: number }
> {
  private contentEl: HTMLDivElement;
  private sizeObserver = new ResizeObserver(() => this.updateExpandedHeight());

  constructor(props) {
    super(props);

    this.state = {
      expanded: !!props.expanded,
      expandedHeight: undefined,
    };
  }

  private updateExpandedHeight() {
    if (this.contentEl) {
      this.setState({
        expandedHeight: this.contentEl.offsetHeight,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expanded !== this.props.expanded) {
      this.setState({
        expanded: this.props.expanded,
      });
    }
  }

  setContentRef = (el: HTMLDivElement) => {
    this.contentEl = el;
    if (el) {
      this.sizeObserver.observe(el);
    }
    this.updateExpandedHeight();
  };

  render() {
    const { number, title, indication, children, expanded } = this.props;

    // Allow expanding when we are not enforced by props
    const expandable = typeof expanded !== 'boolean';

    return (
      <$Step>
        <$StepTitle
          expandable={expandable}
          expanded={this.state.expanded}
          tabIndex={expandable ? 0 : undefined}
          onClick={() => {
            if (expandable) {
              this.setState({
                expanded: !this.state.expanded,
              });
            }
          }}
        >
          <div>{number}</div>
          <h3>
            <span>{title}</span>
          </h3>
          <div>
            <IndicationIcon indication={indication} />
          </div>
        </$StepTitle>
        <$StepCollapser height={this.state.expanded ? this.state.expandedHeight : 0}>
          <div ref={this.setContentRef}>{children}</div>
        </$StepCollapser>
      </$Step>
    );
  }
}

// *********************************************************************************************************************

const $PrerequisiteItem = styled.div`
  padding: 0 1rem;

  > p {
    height: 2rem;
    white-space: nowrap;
    padding: 0;
    margin: 0;
  }
  > p > :first-child {
    margin-right: 0.5rem;
  }
  > div {
    margin-top: 0;
    margin-left: calc(17px + 0.5em);
  }
`;

const $ManagedCountriesHelp = styled.span`
  vertical-align: middle;
  > * {
    vertical-align: middle;
  }
`;

const $ConstrainedInnerWrapper = styled($InnerWrapper)`
  max-width: 650px;
`;

const $KYCStepHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const $AgreementButtonHolder = styled.div`
  text-align: center;
  padding: 2rem;
`;

interface IManagedSetupProps extends RouteComponentProps, ConnectedProps<typeof connector> {}

class ManagedSetup extends React.PureComponent<IManagedSetupProps> {
  static contextType: any = I18nContext;

  private agreementSigningId: number;

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate(prevProps: Readonly<IManagedSetupProps>) {
    if (
      this.props.phoneVerificationState &&
      this.props.phoneVerificationState.result &&
      this.props.phoneVerificationState.id === this.agreementSigningId
    ) {
      // This is our callback. Clear the result and enroll customer into Managed
      this.props.clearPhoneVerificationState();
      this.props.agreeToManagedTOS();
    }
  }

  renderPrerequisitesItem(success: boolean, content: React.ReactNode, alert?: React.ReactNode) {
    return (
      <$PrerequisiteItem>
        <p>
          <IndicationIcon indication={success ? 'success' : 'failure'} />
          {content}
        </p>
        {alert && <Alert type={'warning'}>{alert}</Alert>}
      </$PrerequisiteItem>
    );
  }

  _selectPrerequisites = createSelector(
    (customer: IApiPublicCustomerInfo) => customer,
    (_, countryWhitelist: readonly ICountryCode[]) => countryWhitelist,
    (customer, countryWhitelist) => {
      const kyc = customer.kyc_level_granted >= 2;
      const country = countryWhitelist.includes(customer.nationality);
      return {
        kyc,
        country,
        pass: kyc && country,
      };
    }
  );

  get prerequisites() {
    return this._selectPrerequisites(this.props.customer, this.props.countryWhitelist);
  }

  renderPrerequisitesStep() {
    const { t }: II18nextT = this.context;

    return (
      <Step
        number={1}
        title={t(intentionallyUntranslated('Prerequisites check'))}
        expanded={this.prerequisites.pass ? undefined : true}
        indication={this.prerequisites.pass ? 'success' : 'failure'}
      >
        <p>
          {t(
            intentionallyUntranslated(
              `These are the prerequisites you must fill in order to qualify for Xcalibra Managed`
            )
          )}
        </p>

        {this.renderPrerequisitesItem(
          this.prerequisites.country,
          <>
            {t(intentionallyUntranslated(`Come from one of supported countries`))}{' '}
            <$ManagedCountriesHelp>
              <HelpTooltip
                trigger={['focus']}
                width={'1rem'}
                tabIndex={0}
                overlay={
                  <div>
                    <p>
                      {t(
                        intentionallyUntranslated(
                          'Xcalibra Managed is currently available for the citizens of following countries'
                        )
                      )}
                    </p>
                    <ManagedCountries />
                  </div>
                }
              />
            </$ManagedCountriesHelp>
          </>,
          !this.prerequisites.country && (
            <span>
              {t(
                intentionallyUntranslated(
                  `Unfortunately, Xcalibra Managed is currently not available in your country`
                )
              )}{' '}
              (<CountryLabel isoCode={this.props.customer.nationality} label={true} />)
            </span>
          )
        )}

        {this.renderPrerequisitesItem(
          this.prerequisites.kyc,
          t(intentionallyUntranslated(`Obtain Xcalibra Advanced Account (level 2)`)),
          !this.prerequisites.kyc && (
            <>
              {t(
                intentionallyUntranslated(`You must complete Xcalibra KYC verification before you can apply for Managed. To
              examine the current KYC state of your account, click here.`)
              )}
              <Trans
                i18nKey={intentionallyUntranslated('TODO')}
                components={[
                  <$Link key={0} as={Link} to={R.SETTINGS.to({ page: 'kyc' })}>
                    ___
                  </$Link>,
                ]}
              />
            </>
          )
        )}
      </Step>
    );
  }

  renderKYC1Step() {
    const { t }: II18nextT = this.context;
    const customer = this.props.customer;
    const isCurrent = this.prerequisites.pass && customer.kyc_level_granted_vqf < 1;
    const granted = customer.kyc_level_granted_vqf >= 1;
    const failed = !!customer.kyc_locked;

    return (
      <Step
        number={2}
        title={'Identity verification'}
        expanded={isCurrent ? true : granted ? null : false}
        indication={isCurrent ? (failed ? 'failure' : 'waiting') : granted ? 'success' : null}
      >
        <$KYCStepHolder>
          {isCurrent && customer.kyc_locked ? (
            <KYCLockedNotice customer={customer} />
          ) : (
            <ObtainKycLevel1 kycTrack={'vqf'} granted={granted} />
          )}
        </$KYCStepHolder>
      </Step>
    );
  }

  renderKYC2Step() {
    const { t }: II18nextT = this.context;
    const customer = this.props.customer;
    const isCurrent = this.prerequisites.pass && customer.kyc_level_granted_vqf === 1;
    const granted = customer.kyc_level_granted_vqf >= 2;
    const failed = !!customer.kyc_locked;

    return (
      <Step
        number={3}
        title={'Residence verification'}
        expanded={isCurrent ? true : granted ? null : false}
        indication={isCurrent ? (failed ? 'failure' : 'waiting') : granted ? 'success' : null}
      >
        <$KYCStepHolder>
          {isCurrent && customer.kyc_locked ? (
            <KYCLockedNotice customer={customer} />
          ) : granted ? (
            <$AlertWrapper>
              <$SuccessMessage>
                {t(
                  intentionallyUntranslated(
                    'Congratulations! We have received and accepted your proof of residence.'
                  )
                )}
              </$SuccessMessage>
            </$AlertWrapper>
          ) : (
            <ObtainKycLevel2 kycTrack={'vqf'} />
          )}
        </$KYCStepHolder>
      </Step>
    );
  }

  renderKYC3Step() {
    const { t }: II18nextT = this.context;
    const customer = this.props.customer;
    const isCurrent = this.prerequisites.pass && customer.kyc_level_granted_vqf === 2;
    const granted = customer.kyc_level_granted_vqf >= 3;
    const failed = !!customer.kyc_locked;

    return (
      <Step
        number={4}
        title={'Bank account verification'}
        expanded={isCurrent ? true : granted ? null : false}
        indication={isCurrent ? (failed ? 'failure' : 'waiting') : granted ? 'success' : null}
      >
        <$KYCStepHolder>
          {isCurrent && customer.kyc_locked ? (
            <KYCLockedNotice customer={customer} />
          ) : granted ? (
            <$AlertWrapper>
              <$SuccessMessage>
                {t(
                  intentionallyUntranslated(
                    'Congratulations! We have accepted and approved your micro-payment.'
                  )
                )}
              </$SuccessMessage>
            </$AlertWrapper>
          ) : (
            <ObtainKycLevel3 />
          )}
        </$KYCStepHolder>
      </Step>
    );
  }

  signTheAgreement() {
    if (!this.props.customer.phone) {
      return;
    }

    this.agreementSigningId = Date.now();
    this.props.openPhoneVerificationModal(
      'vqf_signature',
      this.props.customer.phone,
      this.agreementSigningId
    );
  }

  renderTermsStep() {
    const { t }: II18nextT = this.context;
    const signed = !!this.props.config.registered;
    const active =
      this.prerequisites.pass && this.props.customer.kyc_level_granted_vqf >= 3 && !signed;

    return (
      <Step
        number={5}
        title={'Agree to Terms of Service'}
        expanded={active ? true : signed ? null : false}
        indication={active ? 'waiting' : signed ? 'success' : null}
      >
        <$KYCStepHolder>
          {signed ? (
            <$AlertWrapper>
              <$SuccessMessage>
                <Trans
                  i18nKey={'managed.setup.termsStep.signedMessage'}
                  components={[
                    <$Link
                      key={0}
                      to={R.MANAGED_AGREEMENT}
                      target="_blank"
                      as={Link}
                      style={{ fontWeight: 'bold' }}
                    >
                      __
                    </$Link>,
                  ]}
                />
              </$SuccessMessage>
            </$AlertWrapper>
          ) : (
            <>
              <p>
                <Trans
                  i18nKey={'managed.setup.termsStep.prompt'}
                  components={[
                    <$Link
                      key={0}
                      to={R.MANAGED_AGREEMENT}
                      target="_blank"
                      as={Link}
                      style={{ fontWeight: 'bold' }}
                    >
                      __
                    </$Link>,
                  ]}
                />
              </p>
              <div>
                {t(
                  intentionallyUntranslated(
                    'Once ready, click the button below to signal your agreement.'
                  )
                )}
              </div>
              <div>
                {t(
                  intentionallyUntranslated(
                    'You might be asked to sign the agreement via a text message.'
                  )
                )}
              </div>
              <$AgreementButtonHolder>
                {this.props.customer.phone ? (
                  <$InfoButton onClick={() => this.signTheAgreement()}>
                    {t(intentionallyUntranslated('I Agree to the Terms of Service'))}
                  </$InfoButton>
                ) : (
                  <AlertWarningWithIcon>
                    {t(
                      intentionallyUntranslated(
                        `We couldn't find a phone number on record. Please contact customer support.`
                      )
                    )}
                  </AlertWarningWithIcon>
                )}
              </$AgreementButtonHolder>
            </>
          )}
        </$KYCStepHolder>
      </Step>
    );
  }

  render() {
    const { t }: II18nextT = this.context;
    const { config } = this.props;

    return (
      <$Wrapper>
        <$HeaderWrapperJustified>
          <Header
            title={t(intentionallyUntranslated('Xcalibra Managed Setup'))}
            icon={CheckedPaperIcon}
            hideOnMobile={true}
          />
        </$HeaderWrapperJustified>

        <$ConstrainedInnerWrapper>
          <h4>
            {config.registered
              ? t(
                  intentionallyUntranslated(
                    `The Xcalibra Managed setup is completed. You have full access.`
                  )
                )
              : t(
                  intentionallyUntranslated(
                    `In order to gain access to Xcalibra Managed, you need to complete the following steps.`
                  )
                )}
          </h4>
          <div>
            {this.renderPrerequisitesStep()}
            {this.renderKYC1Step()}
            {this.renderKYC2Step()}
            {this.renderKYC3Step()}
            {this.renderTermsStep()}
          </div>
        </$ConstrainedInnerWrapper>
      </$Wrapper>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    countryWhitelist: state.env.managed.countryWhitelist,
    customer: getCustomer(state),
    config: getManagedConfig(state),
    phoneVerificationState: state.phoneVerification,
    theme: state.app.theme,
  }),
  {
    openPhoneVerificationModal,
    clearPhoneVerificationState,
    agreeToManagedTOS,
  }
);

export default withRouter(connector(ManagedSetup));
