import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import lodash from '../../lib/lodash';
import { IState } from '../../lib/store';
import styled from '../../lib/styled_components';
import { zIndex } from '../../lib/styled_components';
import { $Cell, $Table, $TableHeading, $TableRow } from './Table';

export const $HorizontalScrollTable = styled($Table)<{ tableEndReached: boolean }>`
  flex-direction: column;
  padding: 0;
  animation: fadein 0.5s ease-in;

  // Display vertical line when table is scrollable
  position: relative;
  &:after {
    width: 6px;
    height: 100%;
    right: 0;
    content: ' ';
    position: absolute;
    top: 0;
    opacity: ${(p) => (p.tableEndReached ? '0' : '1')};
    transition: opacity 0.5s ease;
    background: ${(p) => p.theme.layout.settings.rightHeaderWrapper.stickyBackground};
  }

  @media screen and ${(p) => p.theme.device.mobile} {
    margin: 0;
    &:after {
      top: 23px;
    }
  }
`;

// noinspection CssInvalidPseudoSelector
export const $FixedHeightTableRow = styled($TableRow)`
  cursor: pointer;
  padding: 5px 5px 5px 2px;
  min-height: 33px;
  white-space: nowrap !important;
  text-overflow: ellipsis;

  // Remove inherited hover styles, in $HorizontalScrollTable $FixedHeightTableRow will be highlighted with the help of $HighlightableTableRow
  &:hover {
    background: transparent;
  }

  &:selected {
    pointer-events: none;
    background: transparent;
  }

  @media ${(p) => p.theme.device.mobile} {
    cursor: default !important;
  }
`;
export const $FixedHeightCell = styled($Cell)`
  white-space: nowrap !important;
  overflow: hidden !important;
`;

export const $HighlightableTableRow = styled($FixedHeightTableRow)<{
  highlighted: boolean;
  recentWithdrawal?: boolean;
}>`
  position: relative;

  @keyframes blink {
    0% {
      background: transparent;
    }

    50% {
      background: ${(p) => p.theme.colors.buy};
    }

    100% {
      background: transparent;
    }
  }

  ${(p) =>
    p.recentWithdrawal
      ? `
  &:after {
    animation-name: blink;
    animation-timing-function: ease;
    animation-delay: 0.5s;
    animation-iteration-count: 3;
    animation-duration: 1.25s;
    content: ' ';
    height: 100%;
    width: 100000%;
    position: absolute;
    transition:initial;
    z-index: -1;

      &:selected {
        pointer-events:none;
      }
      &:hover {
        pointer-events:none;
      }
    }
  `
      : `
  &:after {
    content: ' ';
    height: 100%;
    width: 100000%;
    position: absolute;
    background: ${p.highlighted ? p.theme.widgets.table.backgroundHover : 'transparent'};
    z-index: -1;
    transition: background-color 0.1s ease-in;
  }

  &:selected {
    transition: background-color 0.1s ease-in;
    &:after {
      background: ${(p) => p.theme.widgets.table.backgroundHover};
    }
  }

  &:hover {
    transition: background-color 0.1s ease-in;
    &:after {
      background: ${(p) => p.theme.widgets.table.backgroundHover};
    }
    }
  }
`}
`;

export const $TableBody = styled.div`
  transition: border 0.5s ease-out;
`;

export const $Fixed = styled.div<{ scrolled?: boolean }>`
  ${$TableHeading} {
    display: flex;
  }

  ${$TableBody} {
    border-right: ${(p) =>
      p.scrolled ? '3px solid rgba(255,255,255, 0.06)' : '3px solid transparent'};

    ${$FixedHeightTableRow} {
      position: relative;
      &:before {
        pointer-events:none;
        content: ' ';
        right: -8px;
        width: 10px;
        height: 1px;
        background ${(p) => p.theme.widgets.table.borderBottomColor};
        bottom: -1px;
        position: absolute;
      }
    }
  }
`;

export const $Scrollable = styled.div<{
  hasData: boolean;
  gridTemplateColumns: string;
}>`
  display: grid;
  overflow-x: ${(p) => p.hasData && 'scroll'};
  &::-webkit-scrollbar {
    /* WebKit */
    height: ${(p) => p.theme.scrollbars.width};
  }
  scrollbar-width: ${(p) => p.theme.scrollbars.width}; /* Firefox */
  -ms-overflow-style: ${(p) => p.theme.scrollbars.width}; /* Internet Explorer 10+ */

  ${$TableHeading}, ${$FixedHeightTableRow} {
    display: grid;
    grid-template-columns: ${(p) => p.gridTemplateColumns};
    grid-column-gap: 5px;
  }
`;

export const $TableContent = styled.div<{
  columnsNumber: number;
}>`
  display: flex;
  flex-direction: row;

  ${$Scrollable} {
    flex: 1;
  }

  ${$FixedHeightTableRow} {
    border-radius: initial;
  }

  ${$TableHeading} {
    display: none !important;
  }

  @media ${(p) => p.theme.device.mobile} {
    ${$TableHeading} {
      display: grid !important;
    }
  }
`;

export const $StickyTableHeader = styled.div<{
  columnsNumber: number;
  stickHeader: boolean;
}>`
  animation: fadein 0.5s ease-in;
  display: flex;
  padding: 2px 0;
  pointer-events: none;
  position: sticky;
  top: calc(
    (
      ${(p) => p.theme.widgets.header.wrappers.left.height}px +
        ${(p) => p.theme.layout.headerHeight.default}px
    )
  );
  transition: background 0.5s ease-in-out;

  z-index: ${(p) => (p.stickHeader ? zIndex.stickyHeader : 'inherit')};
  background: ${(p) =>
    p.stickHeader ? p.theme.layout.settings.rightHeaderWrapper.stickyBackground : 'inherit'};
  border-top: ${(p) =>
    p.stickHeader ? '1px solid ' + p.theme.colors.info : '1px solid transparent'};

  ${$TableHeading} {
    border-bottom: ${(p) => p.stickHeader && '1px solid transparent'};
  }

  ${$Fixed} {
    ${$TableHeading} {
      // When Scrollable TableHeading's break into multiple lines, Fixed>TableHeading will have same height
      height: 100%;
    }
  }

  ${$Scrollable} {
    flex: ${(p) => p.columnsNumber - 1};
    &::-webkit-scrollbar {
      /* WebKit */
      height: 0px;
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  @media screen and ${(p) => p.theme.device.mobile} {
    opacity: 0;
    visibility: hidden;
    height: 0;
    width: 0;
    pointer-events: none;
  }
`;

// component types and interfaces

export type IScrollableRow = (
  rowData: any,
  index: number,
  highlightSelectedRow: IHighlightSelectedRow
) => JSX.Element;

export interface IGridColumnProps<T> {
  fixed: { [key in any]?: { width: string } };
  scrollable: { [key in any]?: { width: string } };
}

export interface ITableParts {
  header: {
    fixed: () => JSX.Element;
    scrollable: () => JSX.Element;
  };
  body: {
    fixed: (
      highlightableRowIndex: number,
      highlightSelectedRow?: IHighlightSelectedRow
    ) => JSX.Element[];
    scrollable: IScrollableRow;
  };
}

interface IHorizontalScrollTableProps extends ConnectedProps<typeof connector> {
  data: any;
  stickHeader: boolean;
  getTableHeaderTopOffset?: (topOffset) => void;
  gridColumns: IGridColumnProps<any>;
  tableParts: ITableParts;
  children: any;
}

interface IHorizontalScrollTableState {
  tableScrolled: boolean;
  tableEndReached: boolean;
  horizontalScrollEnabled: boolean;
  highlightedRowIndex: number;
  scrolledLeft: number;
}

export interface IHorizontalScrollTableData<F, S> {
  fixed: F[];
  scrollable: S[];
  length: number;
}

type IHighlightSelectedRow = (index: number) => void;

export interface IScrollableRowsProps {
  scrollable: any[];
  renderScrollableRow: IScrollableRow;
  highlightSelectedRow: IHighlightSelectedRow;
}

class ScrollableRows extends React.PureComponent<IScrollableRowsProps> {
  render() {
    return this.props.scrollable.map((item, index) =>
      this.props.renderScrollableRow(item, index, this.props.highlightSelectedRow)
    );
  }
}

class HorizontalScrollTable extends React.PureComponent<
  IHorizontalScrollTableProps,
  IHorizontalScrollTableState
> {
  static contextType: any = I18nContext;
  // Timeout
  private horizontalScrollTimeout: number;
  private updateTableHeaderOffsetTimeout: number;
  // Refs
  private stickyTableHeaderScrollableRef: React.RefObject<HTMLDivElement> = React.createRef();
  private tableBodyScrollableRef: React.RefObject<HTMLDivElement> = React.createRef();
  private tableRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      tableScrolled: true,
      tableEndReached: true,
      horizontalScrollEnabled: false,
      highlightedRowIndex: -1,
      scrolledLeft: 0,
    };
  }

  onResize = lodash.throttle(() => {
    this.updateTableHeaderTopOffset();
    this.isTableEndReached(this.tableBodyScrollableRef.current.scrollLeft);
    this.isHorizontalScrollEnabled();
  }, 300);

  shouldEnableHorizontalScroll() {
    if (window.innerWidth <= this.props.theme.base.laptopBreakpoint) {
      this.setState({ horizontalScrollEnabled: true });
    }
  }

  highlightSelectedRow: IHighlightSelectedRow = (index: number) => {
    if (this.state.highlightedRowIndex !== index) {
      this.setState({ highlightedRowIndex: index });
    }
  };

  onMouseLeave = lodash.throttle(() => this.setState({ highlightedRowIndex: -1 }), 150);

  updateTableHeaderTopOffset = () => {
    const tableHeaderFromTop =
      this.tableRef.current.getBoundingClientRect().top -
      this.stickyTableHeaderScrollableRef.current.getBoundingClientRect().height -
      this.stickyTableHeaderScrollableRef.current.offsetTop;
    this.props.getTableHeaderTopOffset(tableHeaderFromTop + window.scrollY);
  };

  componentDidMount() {
    this.shouldEnableHorizontalScroll();
    window.addEventListener('resize', this.onResize);
    this.updateTableHeaderTopOffset();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    clearTimeout(this.horizontalScrollTimeout);
    clearTimeout(this.updateTableHeaderOffsetTimeout);
  }

  onHorizontalScroll = (e): void => {
    // Update stickyTableHeaderScrollableRef position when tableBodyScrollableRef is scrolled
    this.stickyTableHeaderScrollableRef.current.scrollLeft = e.target.scrollLeft;
    e.persist();
    !this.state.tableScrolled && this.setState({ tableScrolled: true });
    if (e.target.scrollLeft === 0) {
      this.horizontalScrollTimeout && clearTimeout(this.horizontalScrollTimeout);
      this.horizontalScrollTimeout = setTimeout(() => this.setState({ tableScrolled: false }), 400);
    }

    // Update tableEndReached state prop, when scroll reaches table end
    this.isTableEndReached(e.target.scrollLeft);
  };

  isHorizontalScrollEnabled() {
    // When horizontalScrollEnabled = true, table will become scrollable once columns reach their set up min-width
    if (window.innerWidth <= this.props.theme.base.laptopBreakpoint) {
      !this.state.horizontalScrollEnabled && this.setState({ horizontalScrollEnabled: true });
    } else {
      this.state.horizontalScrollEnabled && this.setState({ horizontalScrollEnabled: false });
    }
  }

  isTableEndReached(tableScrolledLeft: number) {
    const TABLE_END_OFFSET = 10;

    if (
      this.tableBodyScrollableRef.current.scrollWidth -
        this.tableBodyScrollableRef.current.clientWidth <=
      tableScrolledLeft + TABLE_END_OFFSET
    ) {
      this.setState({ tableEndReached: true });
    } else {
      this.state.tableEndReached && this.setState({ tableEndReached: false });
    }
  }

  createGridTemplateColumnString() {
    let gridTemplateColumns = '';

    for (const key in this.props.gridColumns.scrollable) {
      if (this.props.gridColumns.scrollable.hasOwnProperty(key)) {
        gridTemplateColumns += `${this.props.gridColumns.scrollable[key].width} `;
      }
    }

    return gridTemplateColumns;
  }

  renderStickyTableHeader() {
    const columnsNumber = Object.keys(this.props.gridColumns.scrollable).length;
    const gridTemplateColumns = this.createGridTemplateColumnString();
    return (
      <$StickyTableHeader columnsNumber={columnsNumber} stickHeader={this.props.stickHeader}>
        <$Fixed scrolled={this.state.tableScrolled}>
          <$TableHeading noUppercase={true}>{this.props.tableParts.header.fixed()}</$TableHeading>
        </$Fixed>
        <$Scrollable
          ref={this.stickyTableHeaderScrollableRef}
          onScroll={this.onHorizontalScroll}
          hasData={this.props.data.length > 0}
          gridTemplateColumns={gridTemplateColumns}
        >
          <$TableHeading noUppercase={true}>
            {this.props.tableParts.header.scrollable()}
          </$TableHeading>
        </$Scrollable>
      </$StickyTableHeader>
    );
  }

  renderTableContent() {
    const columnsNumber = Object.keys(this.props.gridColumns.scrollable).length;
    const gridTemplateColumns = this.createGridTemplateColumnString();
    return (
      <>
        <$TableContent columnsNumber={columnsNumber}>
          <$Fixed scrolled={this.state.tableScrolled}>
            <$TableHeading noUppercase={true}>{this.props.tableParts.header.fixed()}</$TableHeading>
            <$TableBody onMouseLeave={this.onMouseLeave}>
              {this.props.tableParts.body.fixed(
                this.state.highlightedRowIndex,
                this.highlightSelectedRow
              )}
            </$TableBody>
          </$Fixed>
          <$Scrollable
            ref={this.tableBodyScrollableRef}
            onScroll={this.onHorizontalScroll}
            hasData={this.props.data.length > 0}
            gridTemplateColumns={gridTemplateColumns}
          >
            <$TableHeading noUppercase={true}>
              {this.props.tableParts.header.scrollable()}
            </$TableHeading>
            <$TableBody onMouseLeave={this.onMouseLeave}>
              <ScrollableRows
                highlightSelectedRow={this.highlightSelectedRow}
                scrollable={this.props.data}
                renderScrollableRow={this.props.tableParts.body.scrollable}
              />
            </$TableBody>
          </$Scrollable>
        </$TableContent>
        <div>{this.props.children}</div>
      </>
    );
  }

  render() {
    return (
      <>
        {this.renderStickyTableHeader()}
        <$HorizontalScrollTable ref={this.tableRef} tableEndReached={this.state.tableEndReached}>
          {this.renderTableContent()}
        </$HorizontalScrollTable>
      </>
    );
  }
}

const connector = connect((state: IState) => ({
  theme: state.app.theme,
}));

export default connector(HorizontalScrollTable);
