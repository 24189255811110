import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { Link, LinkProps } from 'react-router-dom';

import { IInstrumentOrdersSummedUp } from '../../../actions/orders/active_instruments_orders';
import R from '../../../lib/routes';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { getNonEmptyActiveInstrumentOrders } from '../../../selectors/active_instruments_orders';
import { getCurrentPairPath } from '../../../selectors/exchange';
import { IOrderSide, IPair } from '../../../types/backend_definitions';
import { II18nextT } from '../../../types/i18n';
import { pairInfo } from '../../../types/instruments';
import InstrumentIcon from '../../widgets/InstrumentIcon';
import ScrollBox from '../../widgets/ScrollBox';

const $ActiveInstrumentOrders = styled.div`
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  display: flex;
  flex-direction: column;
`;

const $Title = styled.h4`
  text-transform: uppercase;
  margin: 0 0 0.3rem;
`;

const $ListItem = styled.a<{ selected: boolean } & LinkProps>`
  text-decoration: none;
  padding: 0.5rem;
  margin-right: 0.5rem;
  color: ${(p) => p.theme.components.marketList.color};
  background: ${(p) =>
    p.selected ? p.theme.components.marketList.selectedBackground : 'transparent'};
  &:hover {
    background: ${(p) =>
      p.selected
        ? p.theme.components.marketList.hoverSelectedBackground
        : p.theme.components.marketList.hoverBackground};
    color: white;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1.2px solid ${(p) => p.theme.components.marketList.separatorColor};
  }
`;

const $ListItemPair = styled.div`
  display: flex;
`;

const $ListItemPairLabels = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5px;
`;

const $PairLongName = styled.div``;

const $PairShortName = styled.div`
  color: ${(p) => p.theme.colors.grayLight};
  font-size: ${(p) => p.theme.fontSize.smaller};
`;

const $ListItemData = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
`;

const $SideLabel = styled.span<{ side: IOrderSide }>`
  color: ${(p) => (p.side === 'buy' ? p.theme.colors.buy : p.theme.colors.sell)};
  text-transform: uppercase;
  font-size: ${(p) => p.theme.fontSize.smallest};
  font-weight: bold;
  align-self: end;
  margin-bottom: 0.1rem;
`;

const $SideValue = styled.span<{ side: IOrderSide }>`
  color: ${(p) => (p.side === 'buy' ? p.theme.colors.buy : p.theme.colors.sell)};
  text-align: right;
  margin-right: 0.2rem;
  font-size: ${(p) => p.theme.fontSize.base};
  font-weight: bold;
`;

interface IActiveInstrumentOrdersProps extends ConnectedProps<typeof connector> {}

interface IActiveInstrumentOrdersState {}

class ActiveInstrumentOrders extends React.PureComponent<
  IActiveInstrumentOrdersProps,
  IActiveInstrumentOrdersState
> {
  static contextType: any = I18nContext;

  renderPair = (instrumentPair: IPair, index: number) => {
    const { t }: II18nextT = this.context;
    const summary: IInstrumentOrdersSummedUp = this.props.instrumentOrders[instrumentPair];
    const pair = pairInfo(summary.pair);

    return (
      <$ListItem
        as={Link}
        key={index}
        to={R.EXCHANGE.to({ pair: instrumentPair })}
        selected={this.props.currentPairPath === instrumentPair}
      >
        <$ListItemPair>
          <InstrumentIcon name={pair.quote.symbol} type="icon" />
          <$ListItemPairLabels>
            <$PairLongName>{pair.quote.name}</$PairLongName>
            <$PairShortName>{pair.displayPath}</$PairShortName>
          </$ListItemPairLabels>
        </$ListItemPair>

        <$ListItemData>
          <$SideValue side={'buy'}>{summary.buy_count}</$SideValue>
          <$SideLabel side={'buy'}>{t('buy')}</$SideLabel>
          <$SideValue side={'sell'}>{summary.sell_count} </$SideValue>
          <$SideLabel side={'sell'}>{t('sell')}</$SideLabel>
        </$ListItemData>
      </$ListItem>
    );
  };

  render() {
    const { t }: II18nextT = this.context;

    const activeInstrumentsCount = Object.keys(this.props.instrumentOrders).length;
    if (!activeInstrumentsCount) {
      return null;
    }

    const pairList = Object.keys(this.props.instrumentOrders) as IPair[];
    const pairItems = pairList.map(this.renderPair);

    return (
      <$ActiveInstrumentOrders>
        <$Title>{t('activeInstruments.title')}</$Title>
        <ScrollBox>{pairItems}</ScrollBox>
      </$ActiveInstrumentOrders>
    );
  }
}

const connector = connect((state: IState) => ({
  instrumentOrders: getNonEmptyActiveInstrumentOrders(state),
  currentPairPath: getCurrentPairPath(state),
}));

export default connector(ActiveInstrumentOrders);
