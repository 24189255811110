import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { ICsvDownloadFunctions, downloadCSV } from '../../../actions/download_csv';
import {
  loadDepositsForInstrument,
  resetUserDeposits,
} from '../../../actions/transactions/deposits';
import { IState } from '../../../lib/store';
import { formatFullDate, formatFullDateRegular } from '../../../lib/util';
import { BanknoteWithArrowsIcon } from '../../../media/svg_icons';
import { getBalances } from '../../../selectors/balances';
import { getInstrumentsConfig } from '../../../selectors/exchange';
import {
  IDepositFixed,
  IDepositsPaginationState,
  createDepositsHorizontalScrollTableData,
  getDeposits,
} from '../../../selectors/transactions';
import { IInstrument } from '../../../types/backend_definitions';
import { II18nextT } from '../../../types/i18n';
import { IInstrumentsConfig } from '../../../types/pairs_instruments_config';
import { IDeposit } from '../../../types/transactions';
import Header from '../../widgets/Header';
import HorizontalScrollTable, {
  $FixedHeightCell,
  $FixedHeightTableRow,
  $HighlightableTableRow,
  IGridColumnProps,
  IScrollableRow,
  ITableParts,
} from '../../widgets/HorizontalScrollTable';
import { $ToolbarLabel } from '../../widgets/Label';
import {
  $ActionElementsWrapper,
  $HeaderWrapperJustified,
  $InnerWrapper,
  $NoDataWrapper,
  $Wrapper,
  DownloadCSVButton,
  IDownloadCSVAction,
} from '../../widgets/Reports';
import { ResponsiveSelectWrapper, SimpleSelect } from '../../widgets/Select';
import { $DottedSpan, $Span } from '../../widgets/Span';
import { $Cell, $EllipsisWrapper, ICellProps } from '../../widgets/Table';
import TableActionIndicator from '../../widgets/TableActionIndicator';
import ClickableTooltipWithCopy from '../../widgets/tooltips/ClickableTooltipWithCopy';
import DepositStatus from '../transactions/DepositStatus';

type ICellNames = 'transactionId' | 'quantity' | 'fee' | 'status' | 'id' | 'date';

const CELLS: { [key in ICellNames]?: Partial<ICellProps> } = {
  date: { align: 'center', minWidth: '9rem' },
  transactionId: { align: 'center', minWidth: '9rem' },
  quantity: { align: 'center', minWidth: '7rem' },
  fee: { align: 'center', minWidth: '6rem' },
  status: { align: 'center', minWidth: '5rem' },
  id: { align: 'center', minWidth: '5rem' },
};

const GRID_COLUMNS: IGridColumnProps<ICellNames> = {
  fixed: { date: { width: '1fr' } },
  scrollable: {
    transactionId: { width: '5fr' },
    quantity: { width: '1fr' },
    fee: { width: '1fr' },
    status: { width: '5rem' },
    id: { width: '5rem' },
  },
};

type IFilterInstrument = IInstrument & 'all';

interface IDepositProps extends ConnectedProps<typeof connector> {
  stickHeader: boolean;
  getTableHeaderTopOffset?: (topOffset) => void;
  downloadCSV: IDownloadCSVAction;
  ICsvDownloadFunctions: ICsvDownloadFunctions;
}

interface IDepositState {
  filterInstrument: IFilterInstrument;
  filterOptions: Object;
  loading: boolean;
}

class Deposit extends React.PureComponent<IDepositProps, IDepositState> {
  static contextType: any = I18nContext;
  private mounted: boolean = false;

  constructor(props) {
    super(props);

    this.state = {
      filterInstrument: 'all' as IFilterInstrument,
      filterOptions: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.props.resetUserDeposits();
    this.onLoadDepositsForInstrument(this.state.filterInstrument, true);
    this.setState({ filterOptions: this.deriveFilterOptions(this.props.instrumentsConfig) });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onLoadDepositsForInstrument = (instrument?: IFilterInstrument, firstPage?: boolean) => {
    this.setState({ loading: true });
    return this.props.loadDepositsForInstrument(instrument, firstPage).finally(() => {
      this.mounted && this.setState({ loading: false });
    });
  };

  componentDidUpdate(
    prevProps: Readonly<IDepositProps>,
    prevState: Readonly<IDepositState>,
    snapshot?: any
  ): void {
    if (prevState.filterInstrument !== this.state.filterInstrument) {
      this.onLoadDepositsForInstrument(this.state.filterInstrument, true);
    }
  }

  deriveFilterOptions(config: IInstrumentsConfig) {
    return Object.keys(config).reduce(
      (res, key) => {
        res[key] = { value: config[key].instrument, label: config[key].instrument };
        return res;
      },
      { all: { value: 'all', label: this.context.t('all') } }
    );
  }

  getDepositsPaginationStateByInstrument(): IDepositsPaginationState {
    const depositsByInstrument = this.props.deposits[this.state.filterInstrument];
    return createDepositsHorizontalScrollTableData(depositsByInstrument);
  }

  renderTableParts(): ITableParts {
    const { t }: II18nextT = this.context;
    const depositsPaginationState = this.getDepositsPaginationStateByInstrument();

    return {
      header: {
        fixed: () => <$FixedHeightCell {...CELLS.date}>{t('date')}</$FixedHeightCell>,
        scrollable: () => {
          return (
            <>
              <$FixedHeightCell {...CELLS.transactionId}>
                {t('transactions:deposits.transactionId')}
              </$FixedHeightCell>
              <$FixedHeightCell {...CELLS.quantity}>{t('amount')}</$FixedHeightCell>
              <$FixedHeightCell {...CELLS.fee}>{t('fee')}</$FixedHeightCell>
              <$FixedHeightCell {...CELLS.status}>{t('transactions:status')}</$FixedHeightCell>
              <$FixedHeightCell {...CELLS.id}>{t('transactions:deposits.id')}</$FixedHeightCell>
            </>
          );
        },
      },
      body: {
        fixed: (highlightedRowIndex, highlightSelectedRow) => {
          return depositsPaginationState.items.fixed.map((depositFixed: IDepositFixed, index) => {
            const date = depositFixed.completed_at || depositFixed.created_at;
            return (
              <$HighlightableTableRow
                onMouseOver={() => highlightSelectedRow(index)}
                highlighted={index === highlightedRowIndex}
                key={index}
              >
                <$Cell {...CELLS.date}>
                  <b title={formatFullDate(date)}>{formatFullDateRegular(date)}</b>
                </$Cell>
              </$HighlightableTableRow>
            );
          });
        },
        scrollable: (deposit: IDeposit, index, highlightSelectedRow) =>
          this.renderScrollableRow(deposit, index, highlightSelectedRow),
      },
    };
  }

  renderScrollableRow: IScrollableRow = (deposit: IDeposit, index, highlightSelectedRow) => {
    return (
      <$FixedHeightTableRow onMouseOver={() => highlightSelectedRow(index)} key={deposit.id}>
        <$Cell {...CELLS.transactionId}>
          <ClickableTooltipWithCopy color="white" tooltipText={deposit.transaction_id}>
            <$EllipsisWrapper>
              <$DottedSpan color="white" monospace>
                {deposit.transaction_id}
              </$DottedSpan>
            </$EllipsisWrapper>
          </ClickableTooltipWithCopy>
        </$Cell>

        <$Cell {...CELLS.quantity}>
          <b>{deposit.quantity}</b> {deposit.instrument}
        </$Cell>
        <$Cell {...CELLS.fee}>
          <b>{deposit.fee_total}</b> {deposit.instrument}
        </$Cell>
        <$Cell {...CELLS.status}>
          <DepositStatus deposit={deposit} />
        </$Cell>
        <$Cell {...CELLS.id}>
          <$Span monospace>{deposit.id}</$Span>
        </$Cell>
      </$FixedHeightTableRow>
    );
  };

  render() {
    const { t }: II18nextT = this.context;
    const depositsPaginationState = this.getDepositsPaginationStateByInstrument();
    const criteria =
      this.state.filterInstrument !== 'all'
        ? {
            instrument: this.state.filterInstrument,
            sort_direction: ['desc'],
            sort_field: ['created_at', 'id'],
          }
        : { sort_direction: ['desc'], sort_field: ['created_at', 'id'] };

    return (
      <$Wrapper>
        <$HeaderWrapperJustified stickHeader={this.props.stickHeader}>
          <Header
            title={t('reportsPage.deposits')}
            icon={BanknoteWithArrowsIcon}
            hideOnMobile={true}
            loadingKeys={['loadDepositsForInstrument', 'cancelWithdrawal']}
          />
          {this.state.filterOptions && (
            <$ActionElementsWrapper>
              <div>
                <$ToolbarLabel htmlFor="depositsInstrument">{t('instrument')}:</$ToolbarLabel>
                <ResponsiveSelectWrapper>
                  <SimpleSelect
                    inputId="depositsInstrument"
                    width="8rem"
                    options={Object.values(this.state.filterOptions)}
                    value={this.state.filterOptions[this.state.filterInstrument]}
                    onChange={({ value }) => {
                      this.setState({ filterInstrument: value as IFilterInstrument });
                    }}
                    variant="transparent"
                  />
                </ResponsiveSelectWrapper>
              </div>
              <div>
                <DownloadCSVButton
                  downloadCsv={this.props.downloadCSV}
                  csvDownloadFunction={'getDepositsCsv'}
                  criteria={criteria}
                />
              </div>
            </$ActionElementsWrapper>
          )}
        </$HeaderWrapperJustified>

        {this.state.loading ? (
          <div />
        ) : !depositsPaginationState.total_records ? (
          <$NoDataWrapper>{t('reportsPage.noData.deposits')}</$NoDataWrapper>
        ) : (
          <$InnerWrapper>
            <HorizontalScrollTable
              data={depositsPaginationState.items.scrollable}
              gridColumns={GRID_COLUMNS}
              stickHeader={this.props.stickHeader}
              getTableHeaderTopOffset={this.props.getTableHeaderTopOffset}
              tableParts={this.renderTableParts()}
            >
              <TableActionIndicator
                paginationState={depositsPaginationState}
                requests={['loadDepositsForInstrument']}
                itemsCount={depositsPaginationState.items.length}
                actionFn={() => this.props.loadDepositsForInstrument(this.state.filterInstrument)}
              />
            </HorizontalScrollTable>
          </$InnerWrapper>
        )}
      </$Wrapper>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    balances: getBalances(state),
    instrumentsConfig: getInstrumentsConfig(state),
    deposits: getDeposits(state),
  }),
  {
    loadDepositsForInstrument,
    resetUserDeposits,
    downloadCSV,
  }
);

export default connector(Deposit);
