import moment from 'moment';
import React from 'react';

import { IOrderState } from '../actions/orders/orders';
import { ITrade } from '../types/trades';
import { DeepReadonly } from '../types/typescript_helpers';
import { IDateGroup } from '../views/widgets/DateSplitScrollBox';
import Quantity from './quantity';

/* tslint:disable:no-bitwise */

// https://stackoverflow.com/a/13542669
export const shadeColor = (color, percent) => {
  const f = parseInt(color.slice(1), 16);
  const t = percent < 0 ? 0 : 255;
  const p = percent < 0 ? percent * -1 : percent;
  const R = f >> 16;
  const G = (f >> 8) & 0x00ff;
  const B = f & 0x0000ff;
  return (
    '#' +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
};
// https://stackoverflow.com/a/13542669
export const blendColors = (c0, c1, p) => {
  const f = parseInt(c0.slice(1), 16);
  const t = parseInt(c1.slice(1), 16);
  const R1 = f >> 16;
  const G1 = (f >> 8) & 0x00ff;
  const B1 = f & 0x0000ff;
  const R2 = t >> 16;
  const G2 = (t >> 8) & 0x00ff;
  const B2 = t & 0x0000ff;
  return (
    '#' +
    (
      0x1000000 +
      (Math.round((R2 - R1) * p) + R1) * 0x10000 +
      (Math.round((G2 - G1) * p) + G1) * 0x100 +
      (Math.round((B2 - B1) * p) + B1)
    )
      .toString(16)
      .slice(1)
  );
};

// https://stackoverflow.com/a/51564734
export const hex2rgba = (hex, opacity = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${opacity})`;
};

/**
 * Join all arguments into a className string
 */
export function classes(...args: string[]) {
  const results = [];
  for (let arg of args) {
    if (!arg) {
      continue;
    }
    if (Array.isArray(arg)) {
      arg = classes.apply(null, arg);
    }
    results.push(String(arg));
  }
  return results.join(' ');
}

/**
 * Format a number, optionally limiting it to certain number of decimal places (if too long)
 */
export function formatNumber(num: number | string | Quantity, decimalPlaces?: number) {
  return Quantity(num).toTruncatedString(decimalPlaces);
}

/**
 * Format a number to fixed number of decimal places.
 */
export function formatNumberToFixed(
  num: number | string | Quantity,
  decimalPlaces: number
): string {
  const q = Quantity.castOr(num, null);
  if (!q) {
    return '';
  }
  return q.toFixed(decimalPlaces);
}

/**
 * Format a string like "+16%" or "-0.040000%"
 */
export function formatPercentage(num: number | string, decimalPlaces?: number): string {
  const prefix = Number(num) > 0 ? '+' : '';
  const formattedNumber = formatNumberToFixed(num, decimalPlaces);
  /**
   * When @num = -0.00... =>
   * formattedNumber will have '0' value
   * resulting in '-0.00..%'
   */
  if (Number(num) < 0 && formattedNumber === '0') {
    return '-' + formattedNumber + '.00..%';
  }
  /**
   * When @num = 0.00... =>
   * formattedNumber will have '0' value
   * resulting in '+0.00..%'
   */
  if (Number(num) > 0 && formattedNumber === '0') {
    return prefix + formattedNumber + '.00..%';
  }
  return prefix + formattedNumber + '%';
}

export function formatFullDate(timestamp) {
  return moment.utc(timestamp).format('YYYY-MM-DD HH:mm:ss:SSS') + ' UTC';
}
export function formatFullDateRegular(timestamp) {
  return moment.utc(timestamp).format('YYYY-MM-DD HH:mm:ss');
}

export function formatCompactTime(timestamp) {
  return moment.utc(timestamp).format('MM-DD HH:mm:ss');
}

export function formatHoursOnly(timestamp: string): string {
  return moment.utc(timestamp).format('HH:mm:ss');
}
export function formatRelativeTimeFromNow(date: Date): string {
  return moment(date).fromNow();
}

export function groupTradesByDate(sourceArray: DeepReadonly<ITrade[]>): IDateGroup[] {
  return sourceArray.reduce((dateGroupArray: IDateGroup[], trade: ITrade) => {
    const dateOfTrade: string = moment.utc(trade.timestamp).format('YYYY-MM-DD');
    const dateGroupExists = dateGroupArray.find((dateGroup) => dateGroup.date === dateOfTrade);
    if (!dateGroupExists) {
      const newDateGroup: IDateGroup = { date: dateOfTrade, trades: [trade] };
      dateGroupArray.push(newDateGroup);
    } else {
      dateGroupExists.trades.push(trade);
    }
    return dateGroupArray;
  }, []);
}

export function groupOrdersByDate(sourceArray: IOrderState[]): IDateGroup[] {
  return sourceArray.reduce((dateGroupArray: IDateGroup[], orderState: IOrderState) => {
    const dateOfOrder: string = moment.utc(orderState.order.timestamp).format('YYYY-MM-DD');
    const dateGroupExists = dateGroupArray.find((dateGroup) => dateGroup.date === dateOfOrder);
    if (!dateGroupExists) {
      const newDateGroup: IDateGroup = { date: dateOfOrder, orders: [orderState] };
      dateGroupArray.push(newDateGroup);
    } else {
      dateGroupExists.orders.push(orderState);
    }
    return dateGroupArray;
  }, []);
}

/**
 * Create an "event muter" wrapper function, which will "mute" dom event and execute the wrapped function.
 * Useful for "button-links".
 */
export function eventMuter(fn, stopPropagation = true, preventDefault = true) {
  return (e) => {
    if (e) {
      if (stopPropagation && e.stopPropagation) {
        e.stopPropagation();
      }
      if (preventDefault && e.preventDefault) {
        e.preventDefault();
      }
    }
    fn();
  };
}

export function wait(ms): Promise<any> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function flattenMilliseconds(timestamp) {
  const date = new Date(timestamp);
  date.setMilliseconds(0);
  return date.toISOString();
}

export function abbreviateNumber(value) {
  if (value < 99999) {
    return value;
  }
  let newValue = value;
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }

  newValue = newValue.toPrecision(3);

  newValue += suffixes[suffixNum];
  return newValue;
}

/**
 * Make sure URL doesn't end with "/". With normalized url, you are safe to do stuff like: normalizedUrl + '/my/path'.
 */
export function normalizeUrl(url) {
  url = url || '';
  if (url[url.length - 1] === '/') {
    url = url.substring(0, url.length - 1);
  }
  return url;
}

/**
 * Searches through a hierarchy of elements and returns the one matchFn pinpoints. Otherwise, returns null.
 */
export function findParentElement(
  target: Element,
  matchFn: (e: Element) => boolean
): Element | null {
  while (target) {
    if (matchFn(target)) {
      // Found
      return target;
    }

    target = target.parentElement;
  }

  return null;
}

/**
 * Generate promise that never resolves
 */
export function cutOffPromiseChain<T>(): Promise<T> {
  return new Promise(() => {}) as any;
}

/**
 * A wrapper for promises made inside components.
 * It checks for "mounted" state, and knows how to cut off promises if it changes.
 * It also maintains a "loading" state, in case your component has it.
 */
export function promiseGuard<T, TComponent, TState>(
  target: React.Component<TComponent, TState>,
  promise: Promise<T> | (() => Promise<T>),
  loadingStateProp: keyof typeof target['state'] = 'loading' as any
): Promise<T> {
  const usesLoadingState = loadingStateProp in target.state;
  if (usesLoadingState) {
    target.setState({ [loadingStateProp]: true } as any);
  }

  return Promise.resolve()
    .then(() => (typeof promise === 'function' ? promise() : promise))
    .then(
      (res) => {
        if ((target as any).mounted === false) {
          // We are no longer mounted. Kill promise.
          return cutOffPromiseChain();
        }

        if (usesLoadingState) {
          target.setState({ [loadingStateProp]: false } as any);
        }

        return res;
      },
      (err) => {
        if ((target as any).mounted === false) {
          // We are no longer mounted. Kill promise.
          return cutOffPromiseChain();
        }

        // TODO: Should we do something with error here?

        if (usesLoadingState) {
          target.setState({ [loadingStateProp]: false } as any);
        }

        throw err;
      }
    );
}

/**
 * Execute given operation asynchronously, but only as long as given guard or guards return the same values
 * they did at the start.
 * Errors go around this entire chain.
 * If you supply the final callback, it will be called in case guard is triggered.
 *
 * This is used if you want to do something like "get current user details" and want to make sure current user doesn't change.
 */
export function guardedAsyncOp<T>(
  guards: (() => any) | Array<() => any>,
  op: () => T,
  onGuarded?: (originalValue: any, currentValue: any, index: number) => void
): T extends Promise<any> ? T : Promise<T> {
  const guardArray = Array.isArray(guards) ? guards : [guards];

  const initialValues = guardArray.map((guard) => guard());

  const checkGuards = (res) => {
    for (let i = 0; i < guardArray.length; i++) {
      const current = guardArray[i]();
      if (current !== initialValues[i]) {
        onGuarded && onGuarded(initialValues[i], current, i);
        return cutOffPromiseChain();
      }
    }
    return res;
  };

  return Promise.resolve()
    .then(checkGuards)
    .then(() => op())
    .then(checkGuards) as any;
}


/**
 * Like Object.keys(), except preserves types
 */
export function typedKeys<T extends object>(target: T): Array<keyof T> {
  return Object.keys(target) as Array<keyof T>;
}