import * as React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';

import Container from '../lib/container';
import { ContainerProvider } from '../lib/react_container';
import App from './App';

interface IRootProps {
  container: Container;
}

export default class Root extends React.PureComponent<IRootProps> {
  render() {
    const children = this.props.children || <App />;

    return (
      <Router history={this.props.container.history}>
        <ReduxProvider store={this.props.container.store}>
          <ContainerProvider container={this.props.container}>
            <I18nextProvider
              i18n={this.props.container.i18n.i18nextRef}
              initialLanguage={this.props.container.localStorage.language}
            >
              {children}
            </I18nextProvider>
          </ContainerProvider>
        </ReduxProvider>
      </Router>
    );
  }
}
