import 'rc-tooltip/assets/bootstrap_white.css';

import QRCode from 'qrcode.react';
import * as React from 'react';
import { I18nContext } from 'react-i18next';

import styled from '../../lib/styled_components';
import { TodoList1Icon } from '../../media/svg_icons';
import { IInstrument } from '../../types/backend_definitions';
import { II18nextT } from '../../types/i18n';
import { AlertWarningWithIcon } from './Alert';
import GenericTooltip from './tooltips/GenericTooltip';

const $Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: start;
`;

const $InnerWrapper = styled.div`
  max-width: 270px;
  text-align: center;
`;

const $WarningNotice = styled(AlertWarningWithIcon)`
  font-size: 1.2rem;
  margin-top: 1em;
`;

const $InfoNotice = styled.div`
  padding: 5px;
  text-align: center;
  font-size: 1.2rem;
`;

const $QRCodeWrapper = styled.div`
  align-self: center;
  margin-top: 1em;
  margin-bottom: 1.5em;
`;

const $AddressBox = styled.div`
  padding-right: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.colors.brandPrimaryLight};
  font-weight: bold;
  width: 270px; /** Empirically determined */
  svg,
  input {
    overflow: scroll;
    cursor: pointer;
    width: auto;
  }

  svg {
    position: absolute;
    width: 30px;
    height: 30px;
  }

  .clipboardIcon {
    right: 10px;
  }
  .qrIcon {
    right: 50px;
  }
`;

const $AddressBoxArea = styled.textarea`
  resize: none;
  position: relative;
  font-size: 14px;
  width: 100%;
  text-align: center;
  padding: 1em;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  background: transparent;
  border: 1px solid ${(p) => p.theme.colors.transparent} !important;
  color: ${(p) => p.theme.colors.white};
  outline-width: 0;
`;

const $FeeNotice = styled.div`
  font-size: 1.2rem;
`;

interface IAddressBoxProps {
  address: string;
  instrumentSymbol: IInstrument;
  feeNotice: string;
}

interface IAddressBoxState {
  copySuccess: boolean;
}

class AddressBox extends React.PureComponent<IAddressBoxProps, IAddressBoxState> {
  private inputRef: React.RefObject<any> = React.createRef();
  static contextType: any = I18nContext;

  constructor(props) {
    super(props);
    this.state = {
      copySuccess: false,
    };
  }

  copyToClipboard = () => {
    this.inputRef.current.select();
    document.execCommand('copy');
    this.setState({ copySuccess: true });
  };

  renderWarning = () => {
    const { t }: II18nextT = this.context;

    if (this.props.instrumentSymbol !== 'SFX') {
      return t('transactions:deposits.depositWarning', {
        instrument: this.props.instrumentSymbol,
      });
    }

    return t('transactions:deposits.sfxDepositWarning');
  }

  render() {
    const { address, feeNotice } = this.props;
    const { t }: II18nextT = this.context;

    return (
      <$Wrapper>
        <$InnerWrapper>
          <$InfoNotice>{t('transactions:deposits.depositInstruction')}</$InfoNotice>

          <$QRCodeWrapper>
            <QRCode value={address} />
          </$QRCodeWrapper>

          <$AddressBox>
            <$AddressBoxArea
              ref={this.inputRef}
              readOnly
              value={address}
              rows={Math.ceil(address.length / 25)}
              cols={30}
              onClick={(e) => e.currentTarget.select()}
            />
            <GenericTooltip
              overlay={
                this.state.copySuccess
                  ? t('transactions:deposits.addressBoxCopied')
                  : t('transactions:deposits.addressBoxCopy') +
                    ' ' +
                    t('transactions:deposits.addressBoxToClipboard')
              }
            >
              <TodoList1Icon className="clipboardIcon" onClick={this.copyToClipboard} />
            </GenericTooltip>
          </$AddressBox>
          <$WarningNotice>
            {this.renderWarning()}
          </$WarningNotice>
          <$FeeNotice>{feeNotice}</$FeeNotice>
        </$InnerWrapper>
      </$Wrapper>
    );
  }
}

export default AddressBox;
