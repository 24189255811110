import { IReportsPage } from '../lib/routes';
import { blendColors, hex2rgba, shadeColor } from '../lib/util';
import bgImg from '../media/bg-night.png';
import { ITheme } from '../types/theme';

// @ts-ignore
export type IThemeVariant = 'dark' | 'light';

// NOTE: The part below is used for theme generation, do not remove
//       If you add stuff to imports above, you will generally have to mirror that stuff here as well
// SHIM: const blendColors = (a: any, b: any, c: any) => '';
// SHIM: const shadeColor = (a: any, b: any) => '';
// SHIM: const hex2rgba = (a: any, b: any) => '';
// SHIM: const bgImg = '';
// SHIM: type IReportsPage = any;

// COLORS
const white = '#FFFFFF';
const black = '#000000';

const brandPrimary = '#17629C';

const brandPrimaryDarker = shadeColor(brandPrimary, -0.35);
const brandPrimaryDark = shadeColor(brandPrimary, -0.2);

const brandPrimaryLight = shadeColor(brandPrimary, 0.2);
const brandPrimaryLighter = shadeColor(brandPrimary, 0.45);

const brandSecondary = '#6CD58C';
const brandTertiary = '#FF5C6F';

const baseDarker = '#CCCCCC';
const baseLighter = white;

const baseDark = blendColors(baseDarker, baseLighter, 0.25);
const baseNeutral = blendColors(baseDarker, baseLighter, 0.5);
const baseLight = blendColors(baseDarker, baseLighter, 0.8);

const headerColor = '#154980';
const sidebarColor = '#193D74';

const greenDark = '#448500';
const redDark = '#850000';
const yellowDark = '#ffbb00';

const greenLight = '#59d8b2';
const redLight = '#ff6d96';
const info = '#00C6FF';
const transparent = 'transparent';
const warning = '#ffaf84';
const error = '#f31856';
const success = brandSecondary;

const grayDarker = shadeColor(black, 0.2);
const grayDark = shadeColor(black, 0.4);
const gray = shadeColor(black, 0.5);
const grayLight = shadeColor(black, 0.6);
const grayLighter = shadeColor(black, 0.7);
const grayLightest = shadeColor(black, 0.8);

const disabled = grayLighter;

const lineColor = `rgba(199, 195, 195, 0.45)`;

// FONTS
const serif = 'Georgia, "Times New Roman", Times, serif';
const sansSerif = 'Nunito, "Helvetica Neue", Helvetica, Arial, sans-serif';
const monospace = 'monospace';

// FONT SIZES
const fontSize = {
  badge: '0.6rem',
  smallest: '0.7rem',
  mediumSmaller: '0.75rem',
  smaller: '0.85rem',
  small: '0.925rem',
  base: '1rem',
  large: '1.15rem',
  larger: '1.30rem',
  largest: '1.45rem',
};

// BREAKPOINTS
const mobileIphonePlus = 414;
const samsungGalaxyTab2 = 600;
const mobileBreakpoint = 900;
const tabletBreakpoint = 1170;
const laptopBreakpoint = 1440;

// LAYOUT CONSTS
const layoutHeaderHeight = 61;
const layoutHeaderHeightMobile = 47;
const layoutMainGap = 10;

const exchangeChartHeight = 460;
const exchangeBuySellHeight = 200;
const exchangeOtherHeight = 350;

const reportPageMaxWidths: { [page in IReportsPage]: string } = {
  'open-orders': '80rem',
  'trade-history': '82rem',
  'card-payments': '70rem',
  deposits: '82rem',
  withdrawals: '90rem',
  'security-events': '90rem',
};

const theme: ITheme = {
  name: 'night',
  base: {
    bodyBackground: '#1B3C75',
    bodyBackgroundImage: `${bgImg}`,

    color: white,
    linkColor: brandPrimary,
    font: sansSerif,

    fontSizeDesktop: '12px',
    fontSizeLaptop: '12px',
    fontSizeMobile: '10px',

    mobileIphonePlus,
    samsungGalaxyTab2,
    mobileBreakpoint,
    tabletBreakpoint,
    laptopBreakpoint,

    focusOutline: '1px dotted rgba(255, 255, 255, 0.5)',
    focusOutlineOffset: '-1px',
  },
  device: {
    // TODO: Rework these breakpoints
    mobile_IphonePlus: `(max-width: ${mobileIphonePlus}px)`,
    mobile_SamsungGalaxyTab2: `(max-width: ${samsungGalaxyTab2}px)`,
    mobile: `(max-width: ${mobileBreakpoint}px)`,
    tablet: `(max-width: ${tabletBreakpoint}px)`,
    laptop: `(max-width: ${laptopBreakpoint}px)`,
    desktop: `(min-width: ${laptopBreakpoint + 1}px)`,
    biggerThanMobile: `(min-width: ${mobileBreakpoint + 1}px)`,
  },
  fontFamily: {
    serif,
    sansSerif,
    monospace,
  },
  fontSize,
  colors: {
    brandPrimary,
    brandPrimaryDarker,
    brandPrimaryDark,
    brandPrimaryLight,
    brandPrimaryLighter,

    brandSecondary,

    transparent,

    baseDarker,
    baseDark,
    baseNeutral,
    baseLight,
    baseLighter,

    headerColor,
    sidebarColor,

    greenDark,
    redDark,
    yellowDark,
    greenLight,
    redLight,

    white,
    black,

    info,
    success,
    warning,
    error,
    disabled,

    grayDarker,
    grayDark,
    gray,
    grayLight,
    grayLighter,
    grayLightest,

    lineColor,

    buy: greenLight,
    sell: redLight,

    increase: greenLight,
    decrease: redLight,

    kycLevel0: redLight,
    kycLevel1: '#f1af6d',
    kycLevel2: '#8dddf3',
    kycLevel3: '#ffd700',
  },
  scrollbars: {
    backgroundColor: info,
    borderRadius: '3px',
    width: '5px',
  },
  layout: {
    // How wide can the interface stretch. Further right, we don't have header, for example
    maxWidth: 1900,
    showTooltipIconMenu: '1220px',

    headerHeight: { default: layoutHeaderHeight, mobile: layoutHeaderHeightMobile },
    sidebarWidth: 210,
    mainGap: layoutMainGap,

    overlayBoxShadow: `0px 2px 7px 0px ${hex2rgba(black, 0.2)}`,

    exchange: {
      chartHeight: `${exchangeChartHeight}px`,
      buySellHeight: `${exchangeBuySellHeight}px`,
      otherHeight: `${exchangeOtherHeight}px`,

      desktopCol1: '11%',
      desktopCol2: '11%',
      desktopCol3: '11%',
      desktopCol4: '11%',
      desktopCol5: '11%',
      desktopCol6: '11%',
      desktopCol7: '34%',

      laptopCol1: '16.66%',
      laptopCol2: '33.33%',
      laptopCol3: '33.33%',
      laptopCol4: '16.66%',

      // If width is like desktop, but vertical height is less than this value,
      // we give up trying to fit everything vertically and introduce vertical scrollbars
      // We want this height to about equal the desktop height of the header, first 3 rows and gaps between them
      minScrollbarlessVerticalHeight:
        layoutHeaderHeight +
        exchangeChartHeight +
        exchangeBuySellHeight +
        exchangeOtherHeight +
        layoutMainGap * 4,

      sectionTitle: {
        iphonePlus: {
          fontSize: fontSize.base,
        },
      },
    },

    topWidgets: {
      icons: {
        defaultPadding: '10px 25px',
        laptopPadding: '10px 17px',
        defaultSize: '24px',
        laptopSize: '23px',
        defaultMarginRight: '0.75rem',
        laptopMarginRight: '0.6rem',
      },
    },

    header: {
      background: `linear-gradient(to right, ${hex2rgba(headerColor, 0.85)}, ${hex2rgba(
        '#1769A4',
        0.85
      )}, ${hex2rgba(headerColor, 0.85)})`,
    },
    sidebar: {
      background: `linear-gradient(to bottom, ${hex2rgba(sidebarColor, 0.8)}, ${hex2rgba(
        '#202A62',
        0.8
      )})`,
    },
    sideMenu: {
      icons: {
        activeSize: '30px',
        inactiveSize: '24px',
      },
      defaultGap: '300px',
      laptopGap: '180px',
      transitionFunc: 'ease',
      transitionDuration: '0.5s',
      reportPageMaxWidths,
      openOrdersMaxWidth: '80rem',
      tradeHistoryMaxWidth: '82rem',
      cardPaymentsMaxWidth: '70rem',
      depositsMaxWidth: '82rem',
      withdrawalsMaxWidth: '90rem',
      portfolioMaxWidth: '62rem',
      portfolioTabletMaxWidth: '30rem',
      portfolioMobileMaxWidth: '60rem',
      portfolioSamsungMaxWidth: '30rem',
    },
    kiosk: {
      wrapper: {
        backgroundColor: `linear-gradient(to bottom, ${hex2rgba('#1f81bf', 0.95)} 20%, ${hex2rgba(
          '#165f9a',
          0.95
        )} 100%)`,
        color: white,
      },
      button: {
        backgroundColor: brandSecondary,
        color: baseLighter,
      },
      successMessage: {
        fontSize: fontSize.largest,
        border: `2px solid ${hex2rgba(brandSecondary, 0.4)}`,
        color: white,
        backgroundColor: hex2rgba(brandSecondary, 0.2),
      },
    },
    settings: {
      leftHeaderWrapper: {
        background: hex2rgba('#172a58', 0.5),
        borderTop: `none`,
      },
      rightHeaderWrapper: {
        background: `linear-gradient(to right, ${hex2rgba('#193D74', 0.4)}, ${hex2rgba(
          '#202A62',
          0.3
        )})`,
        stickyBackground: `linear-gradient(to right, ${hex2rgba('#193D74', 0.9)}, ${hex2rgba(
          '#202A62',
          0.8
        )})`,
        borderTop: `0px solid transparent`,
      },
    },
  },
  widgets: {
    validation: {
      color: white,
      background: '#B775A0',
    },
    header: {
      light: { color: grayDark },
      dark: { color: white },
      wrappers: {
        left: {
          height: 58,
        },
      },
    },
    loading: {
      normal: {
        background: transparent,
        color: greenLight,
      },
      inverse: {
        background: transparent,
        color: brandPrimary,
      },
    },
    loginForm: {
      topDistance: '20px',
      width: '340px',
    },
    kycStatus: {
      activeColor: '#08a6de',
      completedColor: success,
      checkColor: '#BBFF00',
    },
    table: {
      color: white,
      backgroundHover: brandPrimary,
      borderBottomColor: brandPrimaryDark,
      fontFamily: sansSerif,
      heading: {
        color: white,
        fontFamily: sansSerif,
      },
      scrollIndicator: {
        arrowColor: white,
        backgroundColor: hex2rgba('#172a58', 0.6),
      },
      cell: {
        iphonePlus: {
          fontSize: fontSize.mediumSmaller,
        },
      },
      headingCell: {
        iphonePlus: {
          fontSize: fontSize.smaller,
        },
      },
    },
    button: {
      background: info,
      color: white,
      border: '1px solid transparent',
      borderRadius: 'none',
      fontSize: fontSize.base,
      textTransform: 'uppercase',
      padding: '0.375rem 0.75rem',
      small: { fontSize: fontSize.small, borderRadius: '0.1rem', padding: '2px 4px 1px' },
    },
    input: {
      light: {
        background: transparent,
        backgroundDisabled: disabled,
        fontSize: fontSize.base,
        placeholderColor: grayLight,
        border: `2px solid ${grayLighter}`,
        borderFocus: `0px`,
        padding: '5px',
        fontWeight: 'normal',
      },
      dark: {
        background: brandPrimaryLight,
        backgroundDisabled: disabled,
        optionHover: shadeColor(brandPrimary, 0.3),
        optionActiveHover: '#135181',
        fontSize: fontSize.base,
        placeholderColor: white,
        border: `1px solid ${transparent}`,
        borderFocus: `1px solid ${brandPrimaryLighter}`,
        padding: '5px',
        fontWeight: 'normal',
      },
      transparent: {
        border: `1px solid ${lineColor}`,
        backgroundDisabled: disabled,
      },
      iphonePlus: {
        fontSize: fontSize.base,
      },
    },
    menu: {
      borderStyle: 'none',
      fontSize: fontSize.large,
      padding: '5px 1.4rem 7px  0.1rem',
      backgroundHover: 'transparent',
    },
    alert: {
      backgroundWithIcon: `linear-gradient(to right, #172a58, ${hex2rgba('#172a58', 0.1)})`,
      backgroundWithoutIcon: transparent,
      warning: `linear-gradient(to right, #d62a77, #ffab6f) 1`,
      info: `linear-gradient(to right, #6CD58C, #00C6FF) 1`,
    },
    tooltip: {
      color: white,
      background: brandPrimary,
      borderColor: brandPrimary,
      errorColor: white,
      errorBackground: error,
      errorBorderColor: error,
    },
    modal: {
      boxBackground: `linear-gradient(to bottom, ${hex2rgba('#1f81bf', 0.95)} 20%, ${hex2rgba(
        '#165f9a',
        0.95
      )} 100%)`,
      titleBackground: `${hex2rgba('#165f9a', 0.25)}`,
      overlayBackground: 'rgba(0,0,0,0.7)',
      titleSize: '1.5rem',
    },
    dateSplit: {
      textColor: hex2rgba(baseDarker, 0.9),
      borderColor: hex2rgba(baseDarker, 0.7),
      hoverDateBackground: hex2rgba(info, 0.4),
    },
    select: {
      defaultNormalWidth: '8rem',
      defaultIphoneWidth: '6rem',
    },
  },
  components: {
    managedStep: {
      expandable: {
        color: baseNeutral,
        background: hex2rgba(brandPrimary, 0.7),
        hoverBackground: hex2rgba(brandPrimaryLight, 0.7),
        expandedBackground: hex2rgba(brandPrimaryDark, 0.8),
      },
      static: {
        color: hex2rgba(baseDark, 0.7),
        expandedColor: baseDark,
        background: hex2rgba(brandPrimary, 0),
        expandedBackground: hex2rgba(brandPrimaryDarker, 0.35),
      },
    },
    topMenu: {
      normal: {
        text: white,
        icon: baseNeutral,
        background: null,
      },
      hover: {
        text: info,
        icon: info,
        background: null,
      },
      active: {
        text: white,
        icon: white,
        background: sidebarColor,
      },
    },
    indicatorIcon: {
      disabledColor: 'rgba(255,255,255,0.6)',
      warningColor: redLight,
      alertForegroundColor: white,
      alertBackgroundColor: error,
    },
    dropdownMenu: {
      background: hex2rgba(black, 0.5),
      selectedBackground: '#195994',
      hoverBackground: brandPrimaryLight,
    },
    settingsSections: {
      heading: {
        background: hex2rgba(brandPrimary, 0.7),
        collapsableBackground: hex2rgba(brandPrimaryDarker, 0.75),
        color: baseNeutral,
      },
      content: {
        color: baseNeutral,
        background: hex2rgba('#172a58', 0.5),
        validationMessageBackground: '#dd455e',
        validationMessageColor: white,
        sectionMessageBackground: info,
        sectionMessageColor: white,
      },
    },
    marketList: {
      search: {
        background: `${hex2rgba(white, 0.1)}`,
        padding: '8px 30px 8px 11px',
        color: white,
      },
      color: baseNeutral,
      selectedBackground: headerColor,
      hoverBackground: shadeColor(headerColor, 0.1),
      hoverSelectedBackground: shadeColor(headerColor, 0.05),
      separatorColor: lineColor,
    },
    activeInstrumentHorizontal: {
      components: {
        priceInfo: {
          iphonePlus: {
            fontSize: fontSize.large,
          },
        },
        instrumentInfo: {
          iphonePlus: {
            fontSize: fontSize.large,
          },
        },
      },
    },
    activeInstrumentOrders: {
      color: baseNeutral,
      selectedBackground: headerColor,
      background: 'transparent',
      hoverColor: baseLight,
    },
    userActiveOrders: {
      overlayColor: info,
    },
    activeInstrument: {},
    buySell: {
      backgroundBuy: `transparent`,
      backgroundSell: `transparent`,
      backgroundHoverBuy: hex2rgba(brandSecondary, 0.4),
      backgroundHoverSell: hex2rgba('#FF5C6F', 0.4),
      borderBuy: `linear-gradient(to right, #71E11F, ${info}) 1`,
      borderSell: `linear-gradient(to right, #FF5C6F, #FFAB6F) 1`,
      validationError: `linear-gradient(to right,${error}, ${redLight}) 1`,
      colorBuy: greenLight,
      colorSell: redLight,
      gradientWrapperBuyBackground: `linear-gradient(
        to bottom,  ${hex2rgba(greenLight, 0.4)} 0%,
                    ${hex2rgba(greenLight, 0)} 70%)`,
      gradientWrapperSellBackground: `linear-gradient(
        to bottom,  ${hex2rgba(redLight, 0.4)} 0%,
                    ${hex2rgba(redLight, 0)} 70%)`,
      components: {
        label: {
          iphonePlus: {
            fontSize: fontSize.base,
          },
        },
      },
    },
    orderBook: {
      highlightedBuyBackground: hex2rgba(redLight, 0.3),
      highlightedSellBackground: hex2rgba(greenLight, 0.3),
      borderColor: hex2rgba(white, 0.4),
      components: {
        widgetTitle: {
          iphonePlus: {
            fontSize: fontSize.base,
          },
        },
      },
    },
    lastTrades: {
      activeMenuItemColor: info,
    },
    trollbox: {
      topOverlay: `linear-gradient(#1B2F67, transparent)`,
      inputWrapperBackground: hex2rgba(white, 0.1),
      activeMenuItemColor: info,
      borderRadius: '3px',
      scrollButton: {
        border: `3px solid ${shadeColor(info, 0.5)}`,
      },
      button: {
        color: baseLighter,
        padding: '4px 8px 8px',
        borderRadius: '3px',
        border: 'none',
        background: info,
      },
    },
    graph: {
      toolbarBg: '#1B3C75',
      loadingScreen: {
        backgroundColor: '#1C427C',
      },
      paneProperties: {
        background: '#1C427C',
        gridProperties: { color: 'red' },
        vertGridProperties: { color: brandPrimary },
        horzGridProperties: { color: brandPrimary },
        crossHairProperties: { color: '#FF5C6F' },
        topMargin: 15,
      },
      mainSeriesProperties: {
        candleStyle: {
          upColor: greenLight,
          downColor: redLight,
          wickUpColor: hex2rgba(greenLight, 0.35),
          wickDownColor: hex2rgba(redLight, 0.35),
          borderColor: 'transparent',
          borderUpColor: greenLight,
          borderDownColor: redLight,
        },
      },
      scalesProperties: {
        backgroundColor: white,
        lineColor: white,
        textColor: white,
      },
    },
    withdraw: {
      inputWrapperBackground: hex2rgba(white, 0.1),
      borderRadius: '3px',
    },
    withdrawLimit: {
      backgroundColor: transparent,
      filledColor: hex2rgba(baseDarker, 0.3),
      toBeFilledColor: hex2rgba(brandSecondary, 0.6),
      remainingColor: hex2rgba('#1C4567', 0.2),
      warningColor: hex2rgba(redLight, 0.8),
    },
    activeSessions: {
      tableRowHoverBackground: hex2rgba(brandPrimaryLight, 0.2),
    },
    kyc: {
      iconSize: '15px',
      mobileSizeMediaQuery: `(max-width: ${laptopBreakpoint}px)`,
      containerWidth: '550px',
      level1: {
        hr: {
          backgroundColor: hex2rgba(baseNeutral, 0.1),
        },
      },
    },
    kycPanel: {
      border: `1.2px solid ${info}`,
      background: `linear-gradient(to bottom, ${hex2rgba(info, 0.2)}, ${hex2rgba(info, 0.01)})`,
    },
    cardPayment: {
      invalidInputFieldBackground: hex2rgba(error, 0.6),
      inputFieldUpdateBackground: '#0000002b',
      inactiveCurrencyColor: '#ffffff85',
      background: 'rgba(23, 105, 164, 0.6)',
      bgCurrencyList: '#1336671f',
    },
    managed: {
      cardBgActive: hex2rgba('#3662a7', 0.3),
      cardBoxShadow: `0px 5px 5px 0px ${hex2rgba(black, 0.2)}`,
      portfolio: {
        change24HPositive: hex2rgba(greenLight, 0.4),
        change24HNegative: hex2rgba(redLight, 0.4),
        cardHeaderHeight: '6rem',
        mobileCardHeaderHeight: '4rem',
      },
      buyOrderFormInvalidInputBackground: hex2rgba(redLight, 0.6),
    },
  },
};

export default theme;
