import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { requestKycLevel2 } from '../../../../actions/auth/customer';
import { intentionallyUntranslated } from '../../../../lib/i18n';
import { IState } from '../../../../lib/store';
import styled from '../../../../lib/styled_components';
import { hex2rgba } from '../../../../lib/util';
import idUpload from '../../../../media/kyc/id-upload.png';
import { getCustomer } from '../../../../selectors/auth';
import { IKYCTrack } from '../../../../types/backend_definitions';
import { II18nextT } from '../../../../types/i18n';
import { $SuccessMessage } from '../../../layout/KioskLayout';
import { $InfoButton } from '../../../widgets/buttons';
import { $Bottom, $KycPanelEmphasized, $KycPanelTitle } from '../../../widgets/KycElements';
import LoadingAnimation from '../../../widgets/LoadingAnimation';
import { ImageUploadBox } from '../../../widgets/UploadBox';
import KYCFailureNotice from './KYCFailureNotice';

const $ObtainKycLevel2 = styled.div`
  text-align: center;
  margin: 2rem 0;
`;

const $SelfieWrapper = styled($KycPanelEmphasized)`
  max-width: 400px;
`;

const $InputCode = styled.input.attrs({
  type: 'text',
  spellCheck: false,
  maxLength: 6,
  autoComplete: 'off',
})`
  width: 100%;
  height: 40px;
  border: 0;
  outline: 0;
  border-bottom: 0.5px solid white;
  background: transparent;
  text-align: center;
  padding: 0.5em;
  letter-spacing: 1em;
  font-weight: bold;
  color: white;
  ::placeholder {
    color: ${(p) => p.theme.colors.baseNeutral};
    opacity: 1;
  }
`;

const $Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  min-width: 380px;
  margin-top: 30px;

  @media screen and ${(p) => p.theme.device.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

const $ExplanationMessage = styled.div`
  max-width: 300px;
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;

const $ImageUploadHolder = styled.div`
  margin-bottom: 2rem;
`;

const $LetterCodeWrapper = styled.div`
  max-width: 400px;
  border: 1.2px solid ${(p) => p.theme.colors.info};
  background-color: ${(p) => hex2rgba(p.theme.colors.info, 0.1)};
  padding: 15px 50px;
  height: auto;
  margin: 0 auto;
`;

const $ProcessingWrapper = styled.div`
  max-width: 400px;
  margin: 2rem auto;
`;

interface IObtainKycLevel2Props extends ConnectedProps<typeof connector> {
  kycTrack: IKYCTrack;
}

interface IObtainKycLevel2State {
  image: File;
  code: string;
}

class ObtainKycLevel2 extends React.PureComponent<IObtainKycLevel2Props, IObtainKycLevel2State> {
  private mounted = false;
  static contextType: any = I18nContext;

  constructor(props) {
    super(props);

    this.state = {
      image: null,
      code: '',
    };
  }

  get isVQF() {
    return this.props.kycTrack === 'vqf';
  }

  componentDidMount = () => {
    this.mounted = true;
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { t }: II18nextT = this.context;
    const { customer } = this.props;

    if (!customer || !customer.user_id) {
      // Must be set until now, so wait for the store to get initialized
      return null;
    }

    if (customer.kyc_level_requested && customer.kyc_level_requested !== 2) {
      // Wrong request
      return null;
    }

    if (customer.kyc2_method === 'image_upload') {
      // Uploaded image, now waiting
      if (customer.kyc_processing_started_at) {
        return (
          <$ObtainKycLevel2>
            <$ProcessingWrapper>
              <$SuccessMessage>{t('settings:kyc.obtain2.selfie.processingState')}</$SuccessMessage>
            </$ProcessingWrapper>
          </$ObtainKycLevel2>
        );
      }

      // Shouldn't happen
      return null;
    }

    // Nothing has been requested yet, render the request screen
    return (
      <$ObtainKycLevel2>
        {customer.kyc_failure_message && (
          <KYCFailureNotice
            title={t(this.isVQF ? intentionallyUntranslated('There was an issue with your residence verification documentation.') : 'settings:kyc.obtain2.rejectedHeader')}
            message={t([
              `backend:messages.${customer.kyc_failure_message}`,
              customer.kyc_failure_message,
            ])}
          />
        )}
        <$SelfieWrapper>
          {!this.isVQF && <$KycPanelTitle>{t('settings:kyc.obtain2.selfie.title')}</$KycPanelTitle>}
          <$ExplanationMessage>
            {t(
              this.isVQF
                ? intentionallyUntranslated(
                    'To proceed with residence verification, please upload a photograph of yourself holding a utility bill.'
                  )
                : 'settings:kyc.obtain2.selfie.topMessage'
            )}
          </$ExplanationMessage>
          <$ImageUploadHolder>
            <ImageUploadBox
              image={idUpload}
              label={t('settings:kyc.obtain2.selfie.imageBoxTitle')}
              onChange={(file) => this.mounted && this.setState({ image: file })}
            />
          </$ImageUploadHolder>
          <$ExplanationMessage>
            {t('settings:kyc.obtain2.selfie.bottomMessage')}
          </$ExplanationMessage>

          <$Bottom>
            <LoadingAnimation requests={['putKycLevel2']}>
              <$InfoButton
                disabled={!this.state.image}
                backgroundColor="brandPrimary"
                color="white"
                onClick={() => {
                  if (!this.state.image) {
                    return null;
                  }

                  return this.props.requestKycLevel2('image_upload', this.state.image).then(() => {
                    if (this.mounted) {
                      // Remove image from state
                      this.setState({
                        image: null,
                      });
                    }
                  });
                }}
              >
                {t('button.submit')}
              </$InfoButton>
            </LoadingAnimation>
          </$Bottom>
        </$SelfieWrapper>
      </$ObtainKycLevel2>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    customer: getCustomer(state),
  }),
  {
    requestKycLevel2,
  }
);

export default connector(ObtainKycLevel2);
