import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import styled from 'styled-components';

import { IState } from '../../../lib/store';
import { IThemeColorsUnion } from '../../../lib/styled_components';
import { IIndicatorImageProps } from '../../../media/svg_icons';
import { TimeUnits } from '../../../types/constants';

const $IndicatorIcon = styled.div<{
  size?: string;
  backgroundColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${(p) => p.size};
  height: ${(p) => p.size};
  background-color: ${(p) => p.backgroundColor || 'transparent'};
  flex-shrink: 0;

  > svg {
    position: ${(p) => {
      // If we set size, indicator drives the image. Otherwise, image drives the indicator.
      return p.size ? 'absolute' : 'relative';
    }};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

interface IIndicatorIconState {
  flashEnabled: boolean;
  flashAlert: boolean;
}

export type IIndicatorIconIndication = 'disabled' | 'enabling' | 'warning' | 'alert';
export interface IIndicatorIconProps {
  IndicatorImage: React.FunctionComponent<IIndicatorImageProps>;
  color?: IThemeColorsUnion;
  indication?: IIndicatorIconIndication;
  size?: string;
  title?: string;
  flashingInterval?: number;
}
export interface IIndicatorIconOwnProps
  extends IIndicatorIconProps,
    ConnectedProps<typeof connector> {}

class IndicatorIcon extends React.PureComponent<IIndicatorIconOwnProps, IIndicatorIconState> {
  static defaultProps: Partial<IIndicatorIconProps> = {
    flashingInterval: 0.8 * TimeUnits.second,
  };

  private flashingInterval;

  constructor(props) {
    super(props);

    this.state = {
      flashEnabled: false,
      flashAlert: false,
    };
  }

  componentDidMount() {
    this.updateFlashing();
  }

  componentDidUpdate(prevProps: Readonly<IIndicatorIconProps>) {
    if (
      prevProps.indication !== this.props.indication ||
      prevProps.flashingInterval !== this.props.flashingInterval
    ) {
      this.updateFlashing();
    }
  }

  componentWillUnmount() {
    this.clearFlashing();
  }

  updateFlashing() {
    this.clearFlashing();

    const flashingStateKey: keyof IIndicatorIconState =
      this.props.indication === 'alert'
        ? 'flashAlert'
        : this.props.indication === 'enabling'
        ? 'flashEnabled'
        : null;

    if (flashingStateKey) {
      this.flashingInterval = setInterval(() => {
        this.setState({
          [flashingStateKey]: !this.state[flashingStateKey],
        } as any);
      }, this.props.flashingInterval);
    }
  }

  clearFlashing() {
    if (this.flashingInterval) {
      clearInterval(this.flashingInterval);
      this.flashingInterval = null;
    }
    this.setState({ flashEnabled: false, flashAlert: false });
  }

  render() {
    const { theme, size, title, indication, IndicatorImage } = this.props;

    let color = theme.colors[this.props.color] || null;
    let backgroundColor = null;
    if (this.state.flashAlert) {
      color = theme.components.indicatorIcon.alertForegroundColor;
      backgroundColor = theme.components.indicatorIcon.alertBackgroundColor;
    } else if (this.state.flashEnabled) {
      // If we are enabling and flashing, use the normal color, assigned above
    } else if (indication === 'enabling') {
      // If we are enabling but not flashing, show disabled color
      color = theme.components.indicatorIcon.disabledColor;
    } else if (indication === 'alert') {
      // If we are alerting but not flashing, show the warning color
      color = theme.components.indicatorIcon.warningColor;
    } else if (indication === 'warning') {
      color = theme.components.indicatorIcon.warningColor;
    } else if (indication === 'disabled') {
      color = theme.components.indicatorIcon.disabledColor;
    }

    return (
      <$IndicatorIcon size={size} title={title} backgroundColor={backgroundColor}>
        <IndicatorImage color={color} simplified={!!backgroundColor} />
      </$IndicatorIcon>
    );
  }
}

const connector = connect((state: IState) => ({
  theme: state.app.theme,
}));

export default connector(IndicatorIcon);
