import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import Quantity from '../../../lib/quantity';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { formatNumber } from '../../../lib/util';
import { getCustomer } from '../../../selectors/auth';
import { getInstrumentConfig } from '../../../selectors/exchange';
import { II18nextT } from '../../../types/i18n';
import { instrumentInfo } from '../../../types/instruments';
import { ITranslations } from '../../../types/translations';
import { $InfoButton, $SuccessButton } from '../../widgets/buttons';
import { $Input } from '../../widgets/Input';
import $Label from '../../widgets/Label';
import Modal from '../../widgets/Modal';
import Uplatnica from './Uplatnica';

const $RSDDeposit = styled.div`
  max-width: 585px;
`;

const $InputWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  margin: 10px 0;
  justify-content: space-between;
  background: ${(p) => p.theme.components.withdraw.inputWrapperBackground};
  border-radius: ${(p) => p.theme.components.withdraw.borderRadius};
  padding: 8px 8px 8px 12px;
`;

const $ReadOnlyInput = styled($InputWrapper)`
  border: 2px dashed #cfcfcf2e;
`;

const $WidgetsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and ${(p) => p.theme.device.mobile} {
    flex-direction: column;
  }
`;

const $AmountWidget = styled.div`
  font-size: 1.2rem;
  width: 250px;
  border-radius: 3px;
  margin-top: 5px;
  height: fit-content;
  background-color: ${(p) => p.theme.colors.sidebarColor};
  margin-right: 13px;

  @media screen and (${(p) => p.theme.device.laptop}) {
    width: 100%;
    margin-right: 0;
  }
`;

const $AmountWidgetContent = styled.div`
  padding: 10px;
`;

const $AmountWidgetTotal = styled.span`
  font-weight: bold;
  font-size: 1.4rem;
  word-break: break-word;
`;

const $Hr = styled.hr`
  border: none;
  border-top: 2px dashed white;
`;

const $SlipForm = styled.div`
  flex: 1;
  margin-top: 15px;
  height: fit-content;
  min-width: 240px;

  @media screen and ${(p) => p.theme.device.desktop} {
    max-width: 400px;
  }
`;

const $FormInputWrapper = styled('div')<{ inline?: boolean }>`
  display: ${(p) => (p.inline ? 'flex' : 'block')};
  justify-content: ${(p) => p.inline && 'space-between'};
`;

const $TextArea = styled($Input).attrs({ as: 'textarea' })`
  min-height: 60px;
  max-height: 150px;
  resize: vertical;
`;

const $AmountWidgetField = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
`;

const $AmountWidgetFieldValue = styled.span`
  font-weight: bold;
  word-break: break-word;
`;

const $PaymentSlipDialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3.3125rem 2.125rem 2.125rem 2.125rem;
`;

const $PaymentSlipPrintButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;

  ${$SuccessButton} {
    width: 100%;
  }
`;
const INSTRUMENT = 'RSD';
interface IRSDDepositProps extends ConnectedProps<typeof connector> {
  depositAddress: string;
}
interface IRSDDepositState {
  quantity: string;
  total: string;
  fee: string;
}

interface IPaymentData {
  payer: string;
  purpose: string;
  payee: string;
  code: string;
  currency: string;
  amount: string;
  depositAddress: string;
  mod: string;
  referenceNumber: string;
}

class RSDDeposit extends React.PureComponent<IRSDDepositProps, IRSDDepositState> {
  static contextType: any = I18nContext;
  canvasRef;
  modalRef: React.RefObject<Modal> = React.createRef();

  constructor(props) {
    super(props);

    this.state = { quantity: '', total: '0', fee: '0' };
  }

  calculateTotalPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    const quantity = Quantity(value || 0);
    const fee = quantity.combinedFee(
      parseFloat(this.props.config.deposit_fee_abs),
      this.props.config.deposit_fee_pct,
      INSTRUMENT
    );
    const total = quantity.sub(fee);
    this.setState({ quantity: quantity.toString(), fee: fee.toString(), total: total.toString() });
  };

  get paymentSlipData(): IPaymentData {
    const {
      depositAddress,
      rsdDepositSettings,
      customer: { first_name, last_name, residence_address, residence_city, residence_postal_code },
    } = this.props;

    return {
      payer: [
        [first_name, last_name].filter(Boolean).join(' '),
        residence_address,
        [residence_postal_code, residence_city].filter(Boolean).join(' '),
      ]
        .filter(Boolean)
        .join(', '),
      purpose: (rsdDepositSettings.paymentPurpose || '').replace(/%s/g, depositAddress),
      payee: rsdDepositSettings.businessAddress,
      code: rsdDepositSettings.paymentCode,
      currency: INSTRUMENT,
      amount: Quantity(this.state.quantity || 0).toString(),
      depositAddress: rsdDepositSettings.accountNumber,
      mod: rsdDepositSettings.paymentModel,
      referenceNumber: depositAddress,
    };
  }

  printPaymentSlip = () => {
    const tmpWindow = window.open('');
    const image = new Image();
    image.src = this.canvasRef.canvasEl.current.toDataURL();
    image.onload = () => {
      tmpWindow.print();
      tmpWindow.close();
    };
    tmpWindow.document.body.appendChild(image);
    tmpWindow.focus();
  };

  get minDepositConditionPassed() {
    return parseFloat(this.state.quantity || '0') >= this.props.rsdDepositSettings.minRsdDeposit;
  }

  renderFormInput = (label, value, width = null) => {
    return (
      <$FormInputWrapper style={{ width }}>
        <$Label>
          {label}
          <$ReadOnlyInput>
            <$Input
              style={{ fontWeight: 'bold' }}
              readOnly
              border="none"
              value={value}
              onClick={(e) => e.currentTarget.select()}
            />
          </$ReadOnlyInput>
        </$Label>
      </$FormInputWrapper>
    );
  };

  renderForm = () => {
    const { t }: II18nextT = this.context;

    return (
      <$SlipForm>
        <$FormInputWrapper>
          <$Label>
            {t('transactions:deposits.rsd.payer')}
            <$ReadOnlyInput>
              <$TextArea
                style={{ fontWeight: 'bold' }}
                readOnly
                border="none"
                value={this.paymentSlipData.payer}
                onClick={(e) => e.currentTarget.select()}
              />
            </$ReadOnlyInput>
          </$Label>
        </$FormInputWrapper>

        {this.renderFormInput(t('transactions:deposits.rsd.payee'), this.paymentSlipData.payee)}

        {this.renderFormInput(
          t('transactions:deposits.rsd.paymentPurpose'),
          this.paymentSlipData.purpose
        )}

        <$FormInputWrapper inline>
          {this.renderFormInput(
            t('transactions:deposits.rsd.paymentCode'),
            this.paymentSlipData.code,
            '120px'
          )}
          {this.renderFormInput(t('transactions:deposits.rsd.currency'), INSTRUMENT, '100px')}
        </$FormInputWrapper>

        {this.renderFormInput(
          t('transactions:deposits.rsd.amount'),
          this.minDepositConditionPassed
            ? this.paymentSlipData.amount
            : t('transactions:deposits.rsd.minimumAmount', {
                amount: this.props.rsdDepositSettings.minRsdDeposit,
              })
        )}

        {this.renderFormInput(
          t('transactions:deposits.rsd.address'),
          this.paymentSlipData.depositAddress
        )}

        <$FormInputWrapper inline>
          {this.renderFormInput(
            t('transactions:deposits.rsd.mod'),
            this.paymentSlipData.mod,
            '80px'
          )}
          {this.renderFormInput(
            t('transactions:deposits.rsd.referenceNumber'),
            this.paymentSlipData.referenceNumber
          )}
        </$FormInputWrapper>
      </$SlipForm>
    );
  };

  renderWidgetField = (title: ITranslations, renderValue: () => any) => {
    const { t }: II18nextT = this.context;

    return (
      <$AmountWidgetField>
        <span>{t(title)}:</span>
        <$AmountWidgetFieldValue>{renderValue()}</$AmountWidgetFieldValue>
      </$AmountWidgetField>
    );
  };

  renderFee = () => {
    const pctFee = this.props.config.deposit_fee_pct || 0;
    const absFee = this.props.config.deposit_fee_abs
      ? formatNumber(this.props.config.deposit_fee_abs, instrumentInfo(INSTRUMENT).digits)
      : null;

    if (absFee && absFee !== '0' && !pctFee) {
      // Render only absolute fee
      return this.renderWidgetField('transactions:deposits.rsd.fee', () => (
        <span>
          {absFee} {INSTRUMENT}
        </span>
      ));
    }

    if ((!absFee || absFee === '0') && pctFee) {
      // Render only percent fee
      return this.renderWidgetField('transactions:deposits.rsd.fee', () => (
        <span>
          <span>{pctFee} %</span>
        </span>
      ));
    }

    // Render both
    return (
      <>
        {this.renderWidgetField('transactions:deposits.rsd.absoluteFee', () => (
          <span>
            {absFee} {INSTRUMENT}
          </span>
        ))}
        {this.renderWidgetField('transactions:deposits.rsd.percentageFee', () => (
          <span>{pctFee} %</span>
        ))}
      </>
    );
  };

  renderPaymentSlipModal = () => {
    const { t }: II18nextT = this.context;
    const isMobile = window.innerWidth < 576;

    return (
      <Modal ref={this.modalRef} titleUppercase={true}>
        <$PaymentSlipDialogWrapper>
          <Uplatnica
            ref={(ref) => (this.canvasRef = ref)}
            uplatilac={this.paymentSlipData.payer}
            svrhaUplate={this.paymentSlipData.purpose}
            primalac={this.paymentSlipData.payee}
            sifraPlacanja={this.paymentSlipData.code}
            valuta={INSTRUMENT}
            iznos={Quantity(this.state.quantity || 0).toNumber()}
            racunPrimaoca={this.paymentSlipData.depositAddress}
            model={this.paymentSlipData.mod}
            pozivNaBroj={this.paymentSlipData.referenceNumber}
            width={isMobile ? 300 : 562}
            height={isMobile ? 180 : 260}
          />
          <$PaymentSlipPrintButtonWrapper>
            <$SuccessButton onClick={this.printPaymentSlip}>{t('button.print')}</$SuccessButton>
          </$PaymentSlipPrintButtonWrapper>
        </$PaymentSlipDialogWrapper>
      </Modal>
    );
  };

  render() {
    const { t }: II18nextT = this.context;
    const { quantity, total, fee } = this.state;

    return (
      <$RSDDeposit>
        <h4>{t('transactions:deposits.rsd.depositInfo')}</h4>
        <$WidgetsWrapper>
          <$AmountWidget>
            <$AmountWidgetContent>
              <label htmlFor="RSDDeposit-amount">{t('amount')}:</label>
              <$InputWrapper>
                <$Input
                  id="RSDDeposit-amount"
                  border="none"
                  type="number"
                  value={quantity || ''}
                  onChange={this.calculateTotalPrice}
                />
              </$InputWrapper>
              {this.renderFee()}
              {this.renderWidgetField('transactions:deposits.rsd.feeAmount', () =>
                this.minDepositConditionPassed ? (
                  <span>
                    {fee.length > 8 && <br />} {!!parseFloat(this.state.fee) ? `- ${fee}` : 0}{' '}
                    {INSTRUMENT}
                  </span>
                ) : (
                  <span>0 {INSTRUMENT}</span>
                )
              )}
              <$Hr />
              {this.minDepositConditionPassed ? (
                <>
                  <p>{t('transactions:instrumentAmountToReceive')}:</p>
                  <$AmountWidgetTotal>
                    {total} {INSTRUMENT}
                  </$AmountWidgetTotal>
                </>
              ) : (
                <span
                  style={{
                    display: 'block',
                    marginTop: '10px',
                    fontWeight: 'bold',
                    wordBreak: 'break-word',
                  }}
                >
                  {t('transactions:deposits.rsd.minimumAmount', {
                    amount: this.props.rsdDepositSettings.minRsdDeposit,
                  })}
                </span>
              )}
              <p>* {t('transactions:taxIncluded')}</p>
              <$InfoButton
                style={{ marginTop: '10px', width: '100%' }}
                disabled={!this.minDepositConditionPassed}
                onClick={() => this.modalRef.current.show()}
              >
                {t('transactions:showPaymentSlip')}
              </$InfoButton>
            </$AmountWidgetContent>
          </$AmountWidget>
          {this.renderForm()}
        </$WidgetsWrapper>

        {this.renderPaymentSlipModal()}
      </$RSDDeposit>
    );
  }
}

const connector = connect((state: IState) => ({
  customer: getCustomer(state),
  config: getInstrumentConfig(state, INSTRUMENT),
  rsdDepositSettings: state.env.rsdDeposits,
}));

export default connector(RSDDeposit);
