import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import Container from './lib/container';
import { LoggerLevel } from './lib/logger';
import { commit, val } from './lib/redux_helpers';
import Root from './views/Root';

declare global {
  // tslint:disable-next-line
  interface Window {
    LOGGER_LEVEL: LoggerLevel;
    container: Container;
    val: typeof val;
    updateState: (key, value) => void;
    consoleError: (...parts) => void;
  }
}

// initialize application container
const container = new Container();
container.initialize();

// Override log level based on QA-injected variables
if (window.LOGGER_LEVEL) {
  container.logger.level = window.LOGGER_LEVEL;
}
if (window.location && window.location.search) {
  const match = /LOGGER_LEVEL=([a-z]+)/.exec(window.location.search);
  if (match) {
    container.logger.level = match[1] as LoggerLevel;
  }
}

// Attach some helpers to window. Useful for debugging and testing.
window.container = container;
window.val = val;
window.updateState = (key, value) => {
  window.container.store.dispatch(
    commit('Manual state update from outside', {
      [key]: val(value),
    })
  );
};
window.consoleError = (...parts) => {
  if (typeof console === 'object' && typeof console.error === 'function') {
    console.error(...parts);
  }
};

container.start().then(() => {
  ReactDOM.render(<Root container={container} />, document.getElementById('root') as HTMLElement);
});
