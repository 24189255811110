import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import {
  deleteAllButCurrentSession,
  deleteSession,
  loadActiveSessions,
} from '../../actions/auth/active_sessions';
import lodash from '../../lib/lodash';
import { IState } from '../../lib/store';
import styled from '../../lib/styled_components';
import { formatCompactTime, formatFullDate } from '../../lib/util';
import { getSessions } from '../../selectors/auth';
import { II18nextT } from '../../types/i18n';
import { ITranslations } from '../../types/translations';
import { $ErrorButton, $ErrorSmallButton, $InfoSmallButton, $SuccessButton } from './buttons';
import Modal from './Modal';
import { $Cell, $Table, $TableHeading, $TableRow } from './Table';

const $Wrapper = styled.div`
  padding-bottom: 10px;
`;

const $SignOutAllOtherSessionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-right: 1px;
  & button {
    margin-right: 10px;
  }
`;

const $Row = styled($TableRow).attrs((p) => ({
  noBottomBorder: true,
  hoverBackground: p.theme.components.activeSessions.tableRowHoverBackground,
}))``;

const $MoreInfoButton = styled($InfoSmallButton)`
  width: 75px;
  @media screen and ${(p) => p.theme.device.mobile} {
    width: 60px;
    margin: 5px 0;
  }
`;

const $SignOutButton = styled($ErrorSmallButton)`
  width: 75px;
  @media screen and ${(p) => p.theme.device.mobile} {
    width: 60px;
    margin: 5px 0;
  }
`;

const $ModalTable = styled.table`
  display: block;
  margin: 0 auto;
  padding: 1.3125rem;
  width: 100%;

  border-collapse: collapse;
  font-size: ${(p) => p.theme.fontSize.large};
  height: 100%;

  > tbody {
    margin-bottom: 20px;
    display: block;
    overflow: auto;
    height: 80%;
    width: 100%;
  }
`;

const $ModalLabel = styled.th`
  text-align: right;
  padding: 5px;
`;

const $ModalData = styled.td`
  padding: 5px;
`;

interface IActiveSessionsProps extends ConnectedProps<typeof connector> {}
interface IActiveSessionsState {
  modalContentSessionId: number;
}

class ActiveSessions extends React.PureComponent<IActiveSessionsProps, IActiveSessionsState> {
  mounted: boolean;
  modalRef: React.RefObject<Modal> = React.createRef();
  static contextType: any = I18nContext;

  constructor(props) {
    super(props);
    this.state = { modalContentSessionId: 1 };
  }

  updateListOfSessions = (userUpdatedInitiated = false) => {
    if (this.mounted) {
      this.props.loadActiveSessions(userUpdatedInitiated);
    }
  };

  componentDidMount() {
    this.mounted = true;
    this.updateListOfSessions();
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  formatKeyName = (name: string) => {
    const { t }: II18nextT = this.context;
    return t(`settings:security.activeSessionsAdditionalInfoColumns.${name}` as ITranslations);
  };

  showModal = (modalId: number) => {
    this.setState({ modalContentSessionId: modalId }, () => this.modalRef.current.show());
  };

  modalContent = () => {
    const hideKeys = [
      'access_token',
      'user_id',
      'user_email',
      'geo_location_validation',
      'has_geo_data',
      'accuracy_radius',
    ];

    const sessionInfo = lodash.omit(
      lodash.pickBy(this.props.sessions[this.state.modalContentSessionId]),
      hideKeys
    );

    return (
      <$ModalTable>
        <tbody>
          {Object.keys(sessionInfo).map((key) => (
            <tr key={key}>
              <$ModalLabel>{this.formatKeyName(key)}:</$ModalLabel>
              <$ModalData style={{ wordWrap: 'break-word', maxWidth: 300 }}>
                {lodash.endsWith(key, '_at') ? formatFullDate(sessionInfo[key]) : sessionInfo[key]}
              </$ModalData>
            </tr>
          ))}
        </tbody>
      </$ModalTable>
    );
  };

  render() {
    const { t }: II18nextT = this.context;

    return (
      <$Wrapper>
        <$Table noPadding>
          <$TableHeading>
            <$Cell align="left" width="21%">
              {t('settings:security.activeSessionsColumns.lastSeen')}
            </$Cell>
            <$Cell align="center" width="18%">
              {t('settings:security.activeSessionsColumns.browser')}
            </$Cell>
            <$Cell align="center" width="21%">
              {t('settings:security.activeSessionsColumns.os')}
            </$Cell>
            <$Cell align="center" width="21%">
              {t('settings:security.activeSessionsColumns.ipAddress')}
            </$Cell>
            <$Cell align="right" width="19%" style={{ paddingRight: 13 }}>
              {t('actions')}
            </$Cell>
          </$TableHeading>
          {this.props.sessions.map((session, modalId) => {
            return (
              <$Row key={session.access_token}>
                <$Cell width="21%" title={formatFullDate(session.seen_at)}>
                  {formatCompactTime(session.seen_at)}
                </$Cell>

                <$Cell align="center" width="18%" title={session.user_agent}>
                  {session.browser_family} {session.browser_major}
                </$Cell>

                <$Cell align="center" width="21%" title={session.user_agent}>
                  {session.device_family &&
                  session.device_model &&
                  session.device_family !== session.device_model
                    ? `${session.device_family} ${session.device_model} `
                    : session.device_family && `${session.device_family} `}
                  {session.os_family} {session.os_major}
                </$Cell>

                <$Cell align="center" width="21%" title={session.country_name}>
                  {session.ip_address}
                </$Cell>

                <$Cell align="right" width="19%">
                  <$MoreInfoButton onClick={() => this.showModal(modalId)}>
                    {t('button.moreInfo')}
                  </$MoreInfoButton>
                  <br />
                  <$SignOutButton onClick={() => this.props.deleteSession(session.access_token)}>
                    {t('settings:security.activeSessionsSignout')}
                  </$SignOutButton>
                </$Cell>
              </$Row>
            );
          })}
        </$Table>

        <$SignOutAllOtherSessionsWrapper>
          <$SuccessButton onClick={() => this.updateListOfSessions(true)}>
            {t('settings:security.activeSessionsUpdateList')}
          </$SuccessButton>

          <$ErrorButton onClick={this.props.deleteAllButCurrentSession}>
            {t('settings:security.activeSessionsSignoutAll')}
          </$ErrorButton>
        </$SignOutAllOtherSessionsWrapper>

        <Modal
          title={t('settings:security.activeSessionsAdditionalInfo')}
          titleUppercase={true}
          ref={this.modalRef}
        >
          {this.modalContent()}
        </Modal>
      </$Wrapper>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    sessions: getSessions(state),
  }),
  {
    loadActiveSessions,
    deleteSession,
    deleteAllButCurrentSession,
  }
);

export default connector(ActiveSessions);
