import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import {
  cancelWithdrawal,
  loadWithdrawalWindowReportForInstrument,
  loadWithdrawalsForInstrument,
  submitWithdrawal,
} from '../../../actions/transactions/withdrawals';
import Quantity from '../../../lib/quantity';
import { IState } from '../../../lib/store';
import styled, { IThemeColorsUnion } from '../../../lib/styled_components';
import { formatCompactTime, formatFullDate, formatNumberToFixed } from '../../../lib/util';
import {
  BoxIcon,
  CSVIcon,
  CurrencyIcon,
  MonitorIcon,
  Tfa2Icon,
  WalletIcon,
} from '../../../media/svg_icons';
import { getKYCLevel, getUser } from '../../../selectors/auth';
import { getBalanceForInstrument } from '../../../selectors/balances';
import { getInstrumentsConfig } from '../../../selectors/exchange';
import { isRequestActive } from '../../../selectors/request_active';
import {
  getWithdrawalReportForInstrument,
  getWithdrawalStatus,
  getWithdrawalTime,
  getWithdrawalsForInstrument,
} from '../../../selectors/transactions';
import { IPaginated } from '../../../types/api';
import {
  IApiWithdrawalRequestPayload,
  IMarketInstrument,
} from '../../../types/backend_definitions';
import { II18nextT } from '../../../types/i18n';
import { IInstrumentObject } from '../../../types/instruments';
import { IWithdrawal, IWithdrawalStatus } from '../../../types/transactions';
import { ITranslations } from '../../../types/translations';
import { $ContentWrapper } from '../../pages/transactions/TransactionPagesLayout';
import { HideMobile } from '../../utility_components';
import { AlertWarningWithIcon } from '../../widgets/Alert';
import { $ErrorSmallButton, $InfoSmallButton } from '../../widgets/buttons';
import $Checkbox from '../../widgets/Checkbox';
import { $CodeInput } from '../../widgets/CodeInput';
import $ColorText from '../../widgets/ColorText';
import Header from '../../widgets/Header';
import { $Input } from '../../widgets/Input';
import { $HeaderWrapper } from '../../widgets/PageHeaderWrappers';
import ScrollBox from '../../widgets/ScrollBox';
import { $DottedSpan, $Span } from '../../widgets/Span';
import {
  $DigitCell,
  $EllipsisWrapper,
  $HeadingCell,
  $Table,
  $TableHeading,
  $TableRow,
  ICellProps,
} from '../../widgets/Table';
import TableActionIndicator from '../../widgets/TableActionIndicator';
import ClickableTooltipWrapper from '../../widgets/tooltips/ClickableTooltipWrapper';
import HelpTooltip from '../../widgets/tooltips/HelpTooltip';
import LimitGraph from '../../widgets/WithdrawalLimit';
import TransactionsBalances from './CurrentBalance';

const $Withdraw = styled.div`
  margin-bottom: 1rem;
`;

const $TopRow = styled.div`
  display: flex;
  @media ${(p) => p.theme.device.laptop} {
    display: block;
  }
`;

const $TopRowRight = styled.div`
  margin-left: 2rem;
  @media only screen and ${(p) => p.theme.device.laptop} {
    margin-left: 0;
    margin-top: 2rem;
  }
`;

const $InputWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  max-width: 650px;
  justify-content: space-between;
  background: ${(p) => p.theme.components.withdraw.inputWrapperBackground};
  border-radius: ${(p) => p.theme.components.withdraw.borderRadius};
  padding: 8px 8px 8px 12px;
`;

const $MaxAmountCheckboxWrapper = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
  right: 10px;
`;

const $NewWithdrawal = styled.div`
  min-width: 250px;
`;

const $NewWithdrawalForm = styled.div`
  margin-left: 3rem;
`;

const $WithdrawalTally = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  p {
    margin: 0.5rem;
  }
  svg {
    position: absolute;
    margin-left: 0.4rem;
    margin-top: -0.1rem;
  }
`;

const $LimitGraphWrapper = styled('div')<{ width: number }>`
  width: ${(p) => p.width}px;
  height: ${(p) => p.width}px;
  margin-left: 3rem;
  margin-top: 16px;
`;

const $ReadOnlyInput = styled($Input)`
  &:read-only {
    color: ${(p) => p.theme.widgets.input.light.placeholderColor};
  }
`;

const $WithdrawButton = styled($InfoSmallButton)`
  width: 150px;
  height: 25px;
  line-height: 1;
  font-weight: 100;
  margin-top: 1em;
`;

const $WithdrawInputAmountWrapper = styled($InputWrapper)`
  padding-right: 50px;
  position: relative;
`;

const $TFAInputWrapper = styled($InputWrapper)`
  max-width: 250px;
`;

interface IWithdrawalStatusData {
  color: IThemeColorsUnion;
  message: ITranslations;
}

const WITHDRAWAL_STATUS_DATA: { [key in IWithdrawalStatus]: IWithdrawalStatusData } = {
  failed: { color: 'sell', message: 'transactions:withdrawals.statuses.failed' },
  cancelled: { color: 'gray', message: 'transactions:withdrawals.statuses.cancelled' },
  completed: { color: 'success', message: 'transactions:withdrawals.statuses.completed' },
  more_info: { color: 'warning', message: 'transactions:withdrawals.statuses.moreInfo' },
  unconfirmed: { color: 'gray', message: 'transactions:withdrawals.statuses.unconfirmed' },
  pending: { color: 'grayLighter', message: 'transactions:withdrawals.statuses.pending' },
  processing: { color: 'info', message: 'transactions:withdrawals.statuses.processing' },
};

type WithdrawCellNames =
  | 'id'
  | 'targetAddress'
  | 'status'
  | 'quantity'
  | 'fee'
  | 'time'
  | 'buttons';
const WITHDRAW_CELLS: { [key in WithdrawCellNames]: Partial<ICellProps> } = {
  targetAddress: { width: '25%' },
  status: { width: '15%', align: 'center' },
  quantity: { width: '10%', align: 'center' },
  fee: { width: '10%', align: 'center' },
  time: { width: '15%', align: 'center' },
  id: { width: '15%', align: 'center' },
  buttons: { width: '10%', align: 'right' },
};

export type IWithdrawPaginationState = IPaginated<IWithdrawal>;

interface IOwnProps {
  instrument?: IInstrumentObject;
}
interface IWithdrawProps extends IOwnProps, ConnectedProps<typeof connector> {}

interface IWithdrawState {
  address: string;
  quantity: string;
  tfa: string;
  max_selected: boolean;
}

class Withdraw extends React.PureComponent<IWithdrawProps, IWithdrawState> {
  static contextType: any = I18nContext;
  private mounted: boolean = false;

  state = {
    address: '',
    quantity: '',
    tfa: '',
    max_selected: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.props.loadWithdrawalsForInstrument(this.props.instrument.symbol, true);
    this.props.loadWithdrawalWindowReportForInstrument(this.props.instrument.symbol);
  }

  componentDidUpdate(prevProps: Readonly<IWithdrawProps>): void {
    if (prevProps.instrument.symbol !== this.props.instrument.symbol) {
      // reset state
      this.setState({ address: '', quantity: '', tfa: '', max_selected: false });
      this.props.loadWithdrawalsForInstrument(this.props.instrument.symbol, true);
      this.props.loadWithdrawalWindowReportForInstrument(this.props.instrument.symbol);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  get maxWithdrawAmount(): string {
    // there is no withdrawal limit, consume available balance
    if (!this.props.withdrawalReport.limit) {
      return formatNumberToFixed(this.props.balance.available, this.props.instrument.digits);
    }

    return formatNumberToFixed(
      Quantity(this.props.balance.available).lte(this.props.withdrawalReport.remaining)
        ? this.props.balance.available
        : this.props.withdrawalReport.remaining,
      this.props.instrument.digits
    );
  }

  submitWithdrawal = () => {
    this.coerceAddress(() => {
      const { address, quantity, tfa } = this.state;
      const { instrument, submitWithdrawal } = this.props;

      // TODO: validation; all fields are required
      const withdrawal: IApiWithdrawalRequestPayload = {
        target_address: address,
        instrument: instrument.symbol as IMarketInstrument,
        quantity,
      };

      if (this.state.max_selected) {
        withdrawal.auto_fill_quantity = true;
      }

      // if enabled add tfa token in request payload
      if (tfa) {
        withdrawal.tfa_token = tfa;
      }

      // TODO: show errors
      submitWithdrawal(withdrawal).then(() => {
        this.mounted && this.clearInputs();
        return this.props.loadWithdrawalWindowReportForInstrument(this.props.instrument.symbol);
      });
    });
  };

  clearInputs = () =>
    this.setState({
      address: '',
      quantity: '',
      tfa: '',
      max_selected: false,
    });

  coerceAddress = (callback?) => {
    const addr = this.state.address;
    if (!addr) {
      // Do not coerce empty field
      callback && callback();
      return;
    }

    if (this.props.instrument.symbol === 'RSD') {
      let segments;
      if (addr.length === 18 && addr.indexOf('-') === -1) {
        // If bank account is given as an electronic number (eg. 840000074514384344), split by offsets
        segments = [addr.slice(0, 3), addr.slice(3, 16), addr.slice(16)];
      } else {
        // Otherwise, split into segments based on dashes
        segments = addr.split('-');
      }

      const newAddr = segments
        .map((segment) => {
          // Remove leading zeroes
          let sliceIndex = 0;
          while (segment[sliceIndex] === '0' && sliceIndex < segment.length - 1) {
            sliceIndex++;
          }
          return segment.slice(sliceIndex);
        })
        .join('-');

      return this.setState(
        {
          address: newAddr,
        },
        callback
      );
    }

    callback && callback();
  };

  renderWithdrawalStatus = (withdrawal: IWithdrawal) => {
    const { t }: II18nextT = this.context;

    const status = getWithdrawalStatus(withdrawal);
    const data = WITHDRAWAL_STATUS_DATA[status];

    if (status === 'more_info') {
      return (
        <ClickableTooltipWrapper color={data.color} overlay={withdrawal.more_info}>
          {t(data.message)}
        </ClickableTooltipWrapper>
      );
    }

    return <$ColorText color={data.color}>{t(data.message)}</$ColorText>;
  };

  renderNewWithdrawal() {
    const { instrument, config, user } = this.props;
    const { t }: II18nextT = this.context;

    if (!config || !config[instrument.symbol]) {
      return null;
    }

    const instrumentConfig = config[instrument.symbol];

    if (instrumentConfig.disable_withdrawals) {
      const reason = instrumentConfig.disable_withdrawals_reason
        ? (instrumentConfig.disable_withdrawals_reason as string).startsWith(
            'withdrawals_disabled_reasons'
          )
          ? t(`backend:messages.${instrumentConfig.disable_withdrawals_reason}` as any)
          : instrumentConfig.disable_withdrawals_reason
        : null;

      return (
        <AlertWarningWithIcon>
          {t('transactions:withdrawals.withdrawalAreDisabled')} <br /> {reason}
        </AlertWarningWithIcon>
      );
    }

    const quantityNumber: number = parseFloat(this.state.quantity) || 0;

    const fee =
      instrumentConfig.withdrawal_fee_abs !== undefined &&
      instrumentConfig.withdrawal_fee_pct !== undefined
        ? Quantity(quantityNumber)
            .combinedFee(
              parseFloat(instrumentConfig.withdrawal_fee_abs),
              instrumentConfig.withdrawal_fee_pct,
              instrument.symbol
            )
            .truncateForInstrument(instrument.symbol)
        : Quantity.ZERO;

    const TFAEnabled = Boolean(user && user.tfa_enabled_at);

    const minWithdrawal = instrumentConfig.min_withdrawal ? instrumentConfig.min_withdrawal : 0;

    return (
      <$NewWithdrawalForm>
        <Header
          subtitle={`${instrument.symbol} ${t(
            instrument.type === 'crypto'
              ? 'transactions:withdrawals.crypto.addressTitle'
              : 'transactions:withdrawals.fiat.addressTitle'
          )}`}
          icon={WalletIcon}
          iconSize={20}
        />
        <$InputWrapper>
          <$Input
            border="none"
            placeholder={t(
              instrument.type === 'crypto'
                ? 'transactions:withdrawals.crypto.addressPlaceholder'
                : 'transactions:withdrawals.fiat.addressPlaceholder'
            )}
            value={this.state.address}
            onChange={(e) => {
              this.setState({ address: e.target.value });
            }}
            onBlur={() => this.coerceAddress()}
            hasError={true}
          />
        </$InputWrapper>

        <Header
          marginTop={10}
          subtitle={`${instrument.symbol} ${t('amount')}`}
          icon={CurrencyIcon}
          iconSize={20}
        />
        <$WithdrawInputAmountWrapper>
          <$ReadOnlyInput
            type="number"
            min="0"
            border="none"
            placeholder={t('transactions:withdrawals.enterAmount')}
            value={this.state.quantity}
            readOnly={this.state.max_selected}
            step="any"
            onChange={(e) => {
              this.setState({ quantity: e.target.value });
            }}
          />
          <$MaxAmountCheckboxWrapper>
            <$Checkbox
              variant="dark"
              name={`max_amount`}
              checked={this.state.max_selected}
              onChange={(e) =>
                this.setState({
                  max_selected: e.target.checked,
                  quantity: this.maxWithdrawAmount,
                })
              }
            >
              {t('transactions:withdrawals.maxAmount')}
            </$Checkbox>
          </$MaxAmountCheckboxWrapper>
        </$WithdrawInputAmountWrapper>

        {TFAEnabled && (
          <>
            <Header marginTop={10} subtitle={t('tfa')} icon={Tfa2Icon} iconSize={20} />
            <$TFAInputWrapper>
              <$CodeInput
                value={this.state.tfa}
                fontSize="1.0em"
                border="none"
                onChange={(e) =>
                  this.setState({
                    tfa: e.currentTarget.value,
                  })
                }
              />
            </$TFAInputWrapper>
          </>
        )}

        <$WithdrawalTally>
          <p>
            {t('transactions:withdrawals.minimumWithdrawal')}:{' '}
            <strong>
              {minWithdrawal} {instrument.symbol}
            </strong>
          </p>
          <p>
            {t('fee')}:{' '}
            <strong>
              {formatNumberToFixed(fee, instrument.digits)} {instrument.symbol}
            </strong>
          </p>
          <p>
            {t('transactions:withdrawals.youWillReceive')}:{' '}
            <strong>
              {formatNumberToFixed(
                Quantity.max(Quantity(quantityNumber).sub(fee), 0),
                instrument.digits
              )}{' '}
              {instrument.symbol}
            </strong>
            {this.state.max_selected && (
              <HelpTooltip
                width="1.2rem"
                height="1.2rem"
                placement="top"
                overlay={t('transactions:withdrawals.maxAmountNotice')}
              />
            )}
          </p>
        </$WithdrawalTally>
        <$WithdrawButton onClick={this.submitWithdrawal}>
          {t('transactions:withdrawals.withdraw')}
        </$WithdrawButton>
      </$NewWithdrawalForm>
    );
  }

  handleCancelWithdrawal = (withdrawalId) => {
    return Promise.resolve()
      .then(() => {
        return this.props.cancelWithdrawalInProcess || this.props.cancelWithdrawal(withdrawalId);
      })
      .then(() => this.props.loadWithdrawalWindowReportForInstrument(this.props.instrument.symbol));
  };

  renderTableRow = (withdrawal: IWithdrawal) => {
    const { t }: II18nextT = this.context;
    const { cancelWithdrawalInProcess } = this.props;

    const withdrawalTime = getWithdrawalTime(withdrawal);

    return (
      <$TableRow key={withdrawal.id} noPointer>
        <$DigitCell {...WITHDRAW_CELLS.targetAddress}>
          <ClickableTooltipWrapper color="white" overlay={withdrawal.target_address}>
            <$EllipsisWrapper>
              <$DottedSpan monospace>{withdrawal.target_address}</$DottedSpan>
            </$EllipsisWrapper>
          </ClickableTooltipWrapper>
        </$DigitCell>
        <$DigitCell {...WITHDRAW_CELLS.status}>
          <strong>{this.renderWithdrawalStatus(withdrawal)}</strong>
        </$DigitCell>
        <$DigitCell {...WITHDRAW_CELLS.quantity}>
          <b>{withdrawal.quantity}</b>
        </$DigitCell>
        <$DigitCell {...WITHDRAW_CELLS.fee}>
          <b>{withdrawal.fee_total}</b>
        </$DigitCell>
        <$DigitCell {...WITHDRAW_CELLS.time}>
          <b title={formatFullDate(withdrawalTime)}>{formatCompactTime(withdrawalTime)}</b>
        </$DigitCell>
        <$DigitCell {...WITHDRAW_CELLS.id}>
          <$Span monospace>{withdrawal.id}</$Span>
        </$DigitCell>
        <$DigitCell {...WITHDRAW_CELLS.buttons}>
          {!withdrawal.cancelled_at && !withdrawal.completed_at && !withdrawal.taken_at && (
            <$ErrorSmallButton
              disabled={cancelWithdrawalInProcess}
              onClick={() => this.handleCancelWithdrawal(withdrawal.id)}
            >
              {t('button.cancel')}
            </$ErrorSmallButton>
          )}
        </$DigitCell>
      </$TableRow>
    );
  };

  renderLimitGraph = (width) => {
    const { config, instrument, withdrawalReport, balance } = this.props;

    if (!config || !config[instrument.symbol]) {
      return null;
    }

    // in case withdrawals are disabled or there is no withdrawal limit
    if (config[instrument.symbol].disable_withdrawals || !withdrawalReport.limit) {
      return null;
    }

    return (
      <$TopRowRight>
        <$NewWithdrawal>
          <Header
            capitalized
            title={this.context.t('transactions:withdrawals.limitWidget.header')}
            icon={MonitorIcon}
            iconSize={25}
          />

          <$LimitGraphWrapper width={width}>
            <LimitGraph
              instrument={instrument}
              instrumentInputAmount={parseFloat(this.state.quantity)}
              instrumentLimit={parseFloat(withdrawalReport.limit)}
              fiatLimit={parseFloat(withdrawalReport.limit_in_fiat)}
              instrumentRemaining={parseFloat(withdrawalReport.remaining)}
              fiatRemaining={parseFloat(withdrawalReport.remaining_in_fiat)}
              budget={balance.available}
              width={width}
              onClickRemaining={() =>
                !this.state.max_selected &&
                this.setState({
                  quantity: this.maxWithdrawAmount,
                })
              }
            />
          </$LimitGraphWrapper>
        </$NewWithdrawal>
      </$TopRowRight>
    );
  };

  render() {
    const { instrument, withdrawals } = this.props;
    const { t }: II18nextT = this.context;

    return (
      <$Withdraw>
        <HideMobile>
          <$HeaderWrapper>
            <Header
              title={instrument.name.toUpperCase()}
              subtitle={t('transactions:withdrawals.withdraw')}
              icon={instrument.symbol}
            />
          </$HeaderWrapper>
        </HideMobile>

        <$ContentWrapper>
          <$TopRow>
            <TransactionsBalances instrument={instrument} />

            <$TopRowRight>
              <$NewWithdrawal>
                <Header
                  capitalized
                  title={t('transactions:withdrawals.newWithdrawal')}
                  icon={BoxIcon}
                  iconSize={25}
                />
                {this.renderNewWithdrawal()}
              </$NewWithdrawal>
            </$TopRowRight>
            {this.renderLimitGraph(230)}
          </$TopRow>

          <Header
            marginTop={30}
            capitalized
            title={t('transactions:withdrawals.withdrawHistory')}
            icon={CSVIcon}
            iconSize={25}
          />

          <$Table noPadding darkBackground>
            <$TableHeading>
              <$HeadingCell {...WITHDRAW_CELLS.targetAddress}>
                {t(
                  instrument.type === 'crypto'
                    ? 'transactions:withdrawals.crypto.addressTitle'
                    : 'transactions:withdrawals.fiat.addressTitle'
                )}
              </$HeadingCell>
              <$HeadingCell {...WITHDRAW_CELLS.status}>{t('transactions:status')}</$HeadingCell>
              <$HeadingCell {...WITHDRAW_CELLS.quantity}>
                {t('amount')} ({instrument.symbol})
              </$HeadingCell>
              <$HeadingCell {...WITHDRAW_CELLS.fee}>
                {t('fee')} ({instrument.symbol})
              </$HeadingCell>
              <$HeadingCell {...WITHDRAW_CELLS.time}>{t('time')}</$HeadingCell>
              <$HeadingCell {...WITHDRAW_CELLS.id}>{t('transactions:withdrawals.id')}</$HeadingCell>
              <$HeadingCell {...WITHDRAW_CELLS.buttons}>&nbsp;</$HeadingCell>
            </$TableHeading>
            <ScrollBox height="390px">
              {withdrawals.items.map(this.renderTableRow)}
              <TableActionIndicator
                paginationState={this.props.withdrawals}
                requests={['loadWithdrawalsForInstrument']}
                itemsCount={this.props.withdrawals.items.length}
                actionFn={() =>
                  this.props.loadWithdrawalsForInstrument(this.props.instrument.symbol)
                }
              />
            </ScrollBox>
          </$Table>
        </$ContentWrapper>
      </$Withdraw>
    );
  }
}

const connector = connect(
  (state: IState, props: IOwnProps) => ({
    user: getUser(state),
    currentKYCLevel: getKYCLevel(state),
    withdrawals: getWithdrawalsForInstrument(state, props.instrument.symbol),
    withdrawalReport: getWithdrawalReportForInstrument(state, props.instrument.symbol),
    config: getInstrumentsConfig(state),
    cancelWithdrawalInProcess: isRequestActive('cancelWithdrawal', state),
    balance: getBalanceForInstrument(state, props.instrument.symbol),
  }),
  {
    submitWithdrawal,
    cancelWithdrawal,
    loadWithdrawalsForInstrument,
    loadWithdrawalWindowReportForInstrument,
  }
);

export default connector(Withdraw);
