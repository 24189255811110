import isReact from 'is-react';
import React from 'react';

import { getErrorNotificationData, notifyError } from '../actions/app';
import {
  isActiveDynamicRoute,
  showSessionTerminatedScreen,
  terminateSession,
} from '../actions/auth/logout';
import Container from '../lib/container';
import { isUserLoggedIn } from '../selectors/auth';
import { PHONE_NUMBER_E164_EXPLANATION_URL } from '../types/constants';
import { II18nextTfunction } from '../types/i18n';
import { ITranslations } from '../types/translations';
import { $ExternalInfoLink } from '../views/widgets/Link';
import {
  HttpErrorResponse,
  IHttpErrorResponseItem,
  IHttpErrorResponseName,
  ISessionTerminatedErrorResponse,
  NON_HTTP_ERROR_CODE,
  UNAUTHENTICATED_ERROR_CODE,
} from './http';
import { Trans } from './i18n';
import R from './routes';
import { IThunkMethod } from './store';
import styled from './styled_components';

export type IErrorName = IHttpErrorResponseName | 'FrontendValidationError' | 'Error';

export interface ITranslatedError {
  /**
   * Error name. Eg. UserNotFoundError.
   */
  name: IErrorName;

  /**
   * Error code. Usually HTTP code, like 500
   */
  code: number | string;

  /**
   * Message that should be displayed if there was a singular problem to address.
   * If we have pathMessages, this will be empty.
   */
  singleMessage?: string | React.ReactNode;

  /**
   * Lookup of javascript object path ('a.b.c') to the error at that path.
   * If we don't have errors by path, this will be empty, but singleMessage will be set.
   * We only support displaying one error per path.
   */
  pathMessages?: { [path: string]: string | React.ReactNode };

  /**
   * Message that is guaranteed to have one thing we can display and get the gist of the problem.
   * Combines singleMessage and pathMessages
   */
  summaryMessage: string;
}

/**
 * Create translated error from given data.
 */
const createTranslatedError = (
  name: IErrorName,
  code: number | string,
  data: string | { [path: string]: string }
): ITranslatedError => {
  // If we are given a string, create a singleMessage error
  if (typeof data === 'string') {
    // Summary is just the message
    return { name, code, summaryMessage: data as string, singleMessage: data as string };
  }

  // If we are given an object, treat it as pathMessages
  if (typeof data === 'object') {
    let summaryMessage;
    for (const key in data as any) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        summaryMessage = data[key];
        break;
      }
    }
    if (!summaryMessage) {
      // We were given an object without any content.
      throw new Error(`Invalid translated error pathMessages data`);
    }

    return { name, code, summaryMessage, pathMessages: data as any };
  }

  // Something is wrong
  throw new Error(
    `TranslatedError data must be either string or lookup object. We were given: ${typeof data}`
  );
};

export const createFrontendValidationError = (data: string | { [path: string]: string }) => {
  return createTranslatedError('FrontendValidationError', 400, data);
};

// *********************************************************************************************************************

/**
 * Since i18n translate function treats ":" specially (as a namespace), it might end up mangling our
 * innocent error message (eg. "Request failed: Service is down"). Therefore, we will replace
 * ":" into this shitty UTF8 thing, so that i18n doesn't screw us over.
 */
export const escapeErrorMessage = (message) => message && message.replace(/:/g, '：');

/**
 * Take error received from backend and translate it into our own format.
 */
export const translateHttpError = (
  error: HttpErrorResponse | string,
  t: II18nextTfunction
): ITranslatedError => {
  if (typeof error === 'string') {
    // If we are given a string somehow, make sure we get what we expect
    error = ({ message: error } as any) as HttpErrorResponse;
  }
  const code = error.code || 500;
  const name = error.name || 'Error';

  if (error.errors && error.errors.length) {
    // This will be in case of a ValidationError or similar. We will generate pathMessages
    const byPathItems = new Map<string, IHttpErrorResponseItem>();

    const pathMessages = {};
    for (const item of error.errors) {
      if (!item.path) {
        // No path for some reason?
        continue;
      }

      const existing = byPathItems.get(item.path);
      if (!existing || (!existing.known && item.known)) {
        // If there is no existing error at this path, or existing error is not known, we should set this new one
        byPathItems.set(item.path, item);

        // Translation data will include item data, if it exists
        const translationData = item.data ? { ...error, ...item.data } : error;
        const pathMessage = t(
          [`backend:validation.${item.error_code}`, escapeErrorMessage(item.message)],
          translationData
        );
        pathMessages[item.path] = pathMessage;
      }
    }

    if (byPathItems.size) {
      // We have at least one pathMessage. We are good
      return createTranslatedError(name, code, pathMessages);
    }
  }

  // If we are here, it means that we are dealing with singleMessage kind of error.
  const singleMessage = t(
    [
      `backend:errors.${error.translation_key}`,
      `backend:errors.${error.name}`,
      escapeErrorMessage(error.message || 'Error'),
    ],
    error
  );
  return createTranslatedError(name, code, singleMessage);
};

// *********************************************************************************************************************

type ClientValidatorMessage<T> =
  | ITranslations
  | string
  | React.ReactNode
  | ((data?: T) => ITranslations | string | React.ReactNode);

/**
 * Helper object to execute frontend validation.
 *
 * NOTE: Pretty primitive so far. Could be improved by teaching it
 *       some actual tests to perform. Eg. builder.validEmail(val). builder.isSet(value)...
 *       But that will wait for some happier times.
 */
class ClientValidator<T extends object> {
  private onFailFn: (error: ITranslatedError) => void;
  private onPassFn: () => void;
  private onResultFn: (error: ITranslatedError | null) => void;
  private pathMessages = {};
  private errorCount = 0;

  constructor(private data: T, private t: II18nextTfunction, private pathPrefix = '') {}

  /**
   * Custom validator
   */
  test = <TKey extends keyof T>(
    key: TKey,
    isValid: boolean | ((value: T[TKey], data: T) => boolean | ClientValidatorMessage<T>),
    message: ClientValidatorMessage<T> = undefined
  ): ClientValidator<T> => {
    const validationResult =
      typeof isValid === 'function' ? isValid(this.data[key], this.data) : isValid;
    if (
      validationResult === false ||
      typeof validationResult === 'string' ||
      (typeof validationResult === 'object' && !!validationResult)
    ) {
      const path = this.pathPrefix + key;

      let finalMessage = message || validationResult;
      if (typeof finalMessage === 'function') {
        finalMessage = finalMessage(this.data);
      }

      if (!this.pathMessages[path] && finalMessage) {
        this.errorCount++;
        this.pathMessages[path] =
          typeof finalMessage === 'string' ? this.t(finalMessage as ITranslations) : finalMessage;
      }
    }
    return this;
  };

  required = <TKey extends keyof T>(key: TKey, message: ClientValidatorMessage<T> = undefined) => {
    return this.test(key, !!this.data[key], message || 'validation.messages.fieldIsRequired');
  };

  passwordsMatch = <TKey extends keyof T, TOtherKey extends keyof T>(
    key: TKey,
    passwordKey: TOtherKey,
    message: ClientValidatorMessage<T> = undefined
  ) => {
    return this.test(
      key,
      (this.data[key] as any) === (this.data[passwordKey] as any),
      message || 'validation.messages.passwordMismatch'
    );
  };

  phoneNumber = <TKey extends keyof T>(
    key: TKey,
    message: ClientValidatorMessage<T> = undefined
  ) => {
    return this.test(
      key,
      (val) =>
        /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/.test(
          String(val)
        ),
      () => (
        <Trans
          i18nKey={'validation.messages.phoneNumberFormat'}
          components={[
            <$ExternalInfoLink key={0} href={PHONE_NUMBER_E164_EXPLANATION_URL}>
              ___
            </$ExternalInfoLink>,
            <strong key={1}>___</strong>,
          ]}
        />
      )
    );
  };

  onFail = (fn: (error: ITranslatedError) => void): ClientValidator<T> => {
    this.onFailFn = fn;
    return this;
  };

  onPass = (fn: () => void): ClientValidator<T> => {
    this.onPassFn = fn;
    return this;
  };

  onResult = (fn: (error: ITranslatedError | null) => void): ClientValidator<T> => {
    this.onResultFn = fn;
    return this;
  };

  validate = (): ITranslatedError | null => {
    if (!this.errorCount) {
      // Validation passed
      if (this.onPassFn) {
        this.onPassFn();
      }
      if (this.onResultFn) {
        this.onResultFn(null);
      }
      return null;
    }

    // Validation failed
    const error = createFrontendValidationError(this.pathMessages);
    if (this.onFailFn) {
      this.onFailFn(error);
    }
    if (this.onResultFn) {
      this.onResultFn(error);
    }
    return error;
  };

  isValid = (): boolean => {
    return !this.validate();
  };
}

export function clientValidator<T extends object>(
  data: T,
  t: II18nextTfunction,
  pathPrefix = ''
): ClientValidator<T> {
  return new ClientValidator(data, t, pathPrefix);
}

// *********************************************************************************************************************

/**
 * Handle error by showing a toast notification or whatever else is needed.
 * Some errors will be deduped, and others might trigger logout. Returns null and never throws.
 */
export const handleError = (err: HttpErrorResponse): IThunkMethod<null> => (
  dispatch,
  getState,
  { logger, i18n }
) => {
  // Log error
  logger.error(err);

  // Silent errors when app is unmounting
  if (getState().app.unmounting) {
    return null;
  }

  if (err.name === 'SessionTerminatedError') {
    const reason = (err as ISessionTerminatedErrorResponse).reason;
    if (reason) {
      if (isActiveDynamicRoute(getState(), R.SESSION_TERMINATED)) {
        // We are already displaying session terminated dialog. Just update the reason maybe.
        dispatch(showSessionTerminatedScreen(reason));
        return null;
      }

      if (isUserLoggedIn(getState())) {
        // If we are logged in, log out and display "logged out" screen
        dispatch(terminateSession(reason));
        dispatch(showSessionTerminatedScreen(reason));
        return null;
      }
    }

    // If somehow we don't get a reason here, fall back to generic toast
  }

  if (err.code === UNAUTHENTICATED_ERROR_CODE) {
    // Other 401 errors will cause generic loss of session
    dispatch(terminateSession());
  }

  // In case we are handling certain kind of errors, we really want to ensure only one is
  // shown on screen at a time (to avoid the "avalanche" effect).
  const toastId =
    err.code === NON_HTTP_ERROR_CODE
      ? // Non-HTTP error, possibly server is down
        'error_toast_600'
      : err.name === 'SessionTerminatedError'
      ? 'error_toast_SessionTerminatedError'
      : undefined;

  // Translate the error and show notification
  const translatedError = translateHttpError(err, i18n.t);
  dispatch(notifyError(getErrorNotificationData(translatedError, toastId)));

  return null;
};

/**
 * Translate error and pass it along to the caller
 */
export const rethrowTranslated = (err: HttpErrorResponse) => (
  dispatch,
  getState,
  { logger, i18n }: Container
): never | null => {
  // In case where we receive session terminated error and user is logged in, then we need to log the user out
  // and send them to the login page
  // NOTE: A small hole here is if we receive a 401 error, we are logged in AND SessionTerminated is a valid error
  //       to get and stay logged in. But this is a pretty nonsense situation. Backend currently
  //       doesn't do that. And if it started, I'd consider it a backend bug. So we will do it this way.
  if (isUserLoggedIn(getState()) && err.name === 'SessionTerminatedError') {
    return dispatch(handleError(err));
  }

  // Log error
  logger.error(err);

  // Rethrow it translated
  const translatedError = translateHttpError(err, i18n.t);
  throw translatedError;
};

export const $ValidationWrapper = styled.div<{ show: boolean }>`
  padding-top: 0.3rem;
  display: ${(p) => (p.show ? 'flex' : 'none')};
  flex-direction: column;
  animation: fadein 0.5s ease;
`;

const $ValidationWrapperMessage = styled.div`
  color: ${(p) => p.theme.widgets.validation.color};
  background: ${(p) => p.theme.widgets.validation.background};
  padding: 0.7rem;
  text-align: center;
`;

const $ValidationWrapperArrow = styled.span`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 8px 10px;
  border-color: transparent transparent ${(p) => p.theme.widgets.validation.background} transparent;
  align-self: center;
`;

interface IValidationWrapperProps {
  error: ITranslatedError;

  /**
   * If this is set, we will extract message to display from pathMessages. Otherwise, we will use singleMessage.
   */
  path?: string;

  /**
   * If this is set, we will fall back to single message even if path is set
   */
  fallbackToSingle?: boolean;
}

export class ValidationWrapper extends React.Component<IValidationWrapperProps> {
  render() {
    const { error, path, fallbackToSingle } = this.props;

    let message = null;
    if (error) {
      // Try to get message at path
      message = path && error.pathMessages && error.pathMessages[path];

      // Otherwise, try to get as single
      if (!message && (!path || fallbackToSingle)) {
        message = error.singleMessage;
      }
    }

    // Modify children to know if there is an error
    const children = React.Children.map(this.props.children, (child) => {
      if (isReact.compositeTypeElement(child)) {
        // NOTE: It's important that we always either add or don't add hasError property, for every child. Otherwise, we will cause re-creation, with inputs losing focus.
        return React.cloneElement(child as any, { hasError: !!message });
      }
      return child;
    });

    return (
      <>
        {children}
        <$ValidationWrapper show={!!message}>
          <$ValidationWrapperArrow />
          <$ValidationWrapperMessage>{message}</$ValidationWrapperMessage>
        </$ValidationWrapper>
      </>
    );
  }
}

// *********************************************************************************************************************

export const $ValidationSingleMessage = styled.div`
  color: ${(p) => p.theme.widgets.validation.color};
  background: ${(p) => p.theme.widgets.validation.background};
  animation: fadein 0.5s ease;
  padding: 1rem;
  text-align: center;
`;

interface IValidationSingleMessageProps {
  error: ITranslatedError;
}

export const ValidationSingleMessage: React.FunctionComponent<IValidationSingleMessageProps> = ({
  error,
}): any => {
  if (!error) {
    return null;
  }
  if (!error.singleMessage) {
    return null;
  }

  return <$ValidationSingleMessage>{error.singleMessage}</$ValidationSingleMessage>;
};
