import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import R from '../../../lib/routes';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { getCurrentPair } from '../../../selectors/exchange';
import { pairInfo } from '../../../types/instruments';
import CandleChart, { $Chart } from './CandleChart';
import ExchangeGrid from './ExchangeGrid';

const $CandleChartOverlay = styled.div<{ show: boolean }>`
  visibility: ${(p) => (p.show ? 'inherit' : 'hidden')};
  position: absolute;
  pointer-events: none;

  ${$Chart} {
    pointer-events: auto;
  }

  // NOTE: These have to follow styles from MainLayout.tsx because we are absolute and can't participate in the flow otherwise
  width: calc(100% - ${(p) => p.theme.layout.sidebarWidth}px);
  @media screen and ${(p) => p.theme.device.mobile} {
    width: 100%;
  }

  > * {
    max-height: calc(100vh - ${(p) => p.theme.layout.headerHeight.default}px);
    overflow: hidden;

    @media screen and ${(p) => p.theme.device.mobile} {
      max-height: calc(100vh - ${(p) => p.theme.layout.headerHeight.mobile}px);
    }
  }
`;

interface ICandleChartOverlayProps extends ConnectedProps<typeof connector> {}

interface ICandleChartOverlayState {}

class CandleChartOverlay extends React.PureComponent<
  ICandleChartOverlayProps,
  ICandleChartOverlayState
> {
  static contextType: any = I18nContext;

  SHOW_TRADING_VIEW_FOR_ROUTES_ID = [R.EXCHANGE.id, R.EXCHANGE_HOME.id];

  render() {
    if (!this.props.currentPair) {
      // Render nothing until we get the pair
      return null;
    }

    const show = this.props.activeStaticRoute
      ? !!this.SHOW_TRADING_VIEW_FOR_ROUTES_ID.find(
          (routeId) => routeId === this.props.activeStaticRoute.routeId
        )
      : false;

    return (
      <$CandleChartOverlay show={show}>
        <ExchangeGrid candleChart={<CandleChart />} />
      </$CandleChartOverlay>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    currentPair: getCurrentPair(state) || pairInfo('SFX_BTC'),
    activeStaticRoute: state.activeStaticRoute,
  }),
  null
);

export default connector(CandleChartOverlay);
