import * as React from 'react';

// images
import uplatnicaImage from '../../../media/uplatnica.jpg';

// component types and interfaces
interface IUplatnicaProps {
  uplatilac: string;
  svrhaUplate: string;
  primalac: string;
  sifraPlacanja: string;
  valuta: string;
  iznos: number;
  racunPrimaoca: string;
  model: string;
  pozivNaBroj: string;

  width: number;
  height: number;
}
interface IUplatnicaState {}

class Uplatnica extends React.PureComponent<IUplatnicaProps, IUplatnicaState> {
  private canvasEl: React.RefObject<HTMLCanvasElement> = React.createRef();
  private canvasContext: CanvasRenderingContext2D = null;
  private uplatnicaImage;
  private mounted: boolean;

  static defaultProps: IUplatnicaProps = {
    uplatilac: '',
    svrhaUplate: '',
    primalac: '',
    sifraPlacanja: '',
    valuta: '',
    iznos: 0,
    racunPrimaoca: '',
    model: '',
    pozivNaBroj: '',
    width: 585,
    height: 303,
  };

  private textFields = {
    uplatilac: {
      xPercentage: 0.025,
      yPercentage: 0.2,
      fontSizePercentage: 0.0239,
      multiline: true,
    },
    svrhaUplate: {
      xPercentage: 0.025,
      yPercentage: 0.46,
      fontSizePercentage: 0.0239,
      multiline: true,
    },
    primalac: {
      xPercentage: 0.025,
      yPercentage: 0.72,
      fontSizePercentage: 0.0239,
      multiline: true,
    },
    sifraPlacanja: {
      xPercentage: 0.51,
      yPercentage: 0.28,
      fontSizePercentage: 0.0256,
      multiline: false,
    },
    valuta: { xPercentage: 0.65, yPercentage: 0.28, fontSizePercentage: 0.0256, multiline: false },
    iznos: { xPercentage: 0.79, yPercentage: 0.28, fontSizePercentage: 0.0239, multiline: false },
    racunPrimaoca: {
      xPercentage: 0.51,
      yPercentage: 0.46,
      fontSizePercentage: 0.0256,
      multiline: false,
    },
    model: { xPercentage: 0.51, yPercentage: 0.62, fontSizePercentage: 0.0256, multiline: false },
    pozivNaBroj: {
      xPercentage: 0.65,
      yPercentage: 0.62,
      fontSizePercentage: 0.0235,
      multiline: false,
    },
  };

  componentDidMount() {
    this.mounted = true;
    const image = new Image();
    image.src = uplatnicaImage;
    this.uplatnicaImage = image;
    this.canvasContext = this.canvasEl.current.getContext('2d');
    this.draw();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate() {
    this.draw();
  }

  formatLinesFromText(text: string, charactersPerLine: number = 27): string[] {
    const words = text.split(' ');

    return words.reduce((result, word) => {
      const currentLine = result[result.length - 1];
      if (!currentLine || currentLine.length + word.length > charactersPerLine) {
        result.push(word);
      } else {
        result[result.length - 1] = [currentLine, word].join(' ');
      }

      return result;
    }, []);
  }

  draw = () => {
    const { width, height } = this.props;

    this.uplatnicaImage.onload = () => {
      if (this.mounted) {
        this.canvasContext.drawImage(this.uplatnicaImage, 0, 0, width, height);
        this.canvasContext.lineWidth = 3;
        this.canvasContext.strokeStyle = '#000000';
        this.canvasContext.strokeRect(0, 0, width, height);

        Object.keys(this.textFields).forEach((fieldName) => {
          const { fontSizePercentage, multiline, xPercentage, yPercentage } = this.textFields[
            fieldName
          ];

          this.canvasContext.font = `${fontSizePercentage * width}pt arial`;
          const fieldContent = this.props[fieldName];

          if (multiline) {
            const [firstLine, secondLine] = this.formatLinesFromText(fieldContent);

            // first line
            this.canvasContext.fillText(firstLine, xPercentage * width, yPercentage * height);

            if (secondLine) {
              // second line
              this.canvasContext.fillText(
                secondLine,
                xPercentage * width,
                yPercentage * height + fontSizePercentage * width + (fontSizePercentage * width) / 3
              );
            }
          } else {
            this.canvasContext.fillText(fieldContent, xPercentage * width, yPercentage * height);
          }
        });
      }
    };
  };

  render() {
    return <canvas ref={this.canvasEl} width={this.props.width} height={this.props.height} />;
  }
}

export default Uplatnica;
