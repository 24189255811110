import { commit, val } from '../lib/redux_helpers';
import { IState } from '../lib/store';
import { IApiMarketDepth } from '../types/backend_definitions';

export interface IOrderbookState {
  marketDepth: IApiMarketDepth;
}

export const ORDERBOOK_STATE: IOrderbookState = {
  marketDepth: {
    pair: null,
    scale: 0,
    limit: 0,
    buy: {
      scale: 0,
      levels: [],
      limit: 0,
      quote_supply: '0',
      base_supply: '0',
    },
    sell: {
      scale: 0,
      levels: [],
      limit: 0,
      quote_supply: '0',
      base_supply: '0',
    },
  },
};

export const updateMarketDepth = (marketDepth: IApiMarketDepth) => {
  return commit('Market depth update', { marketDepth: val<IState['marketDepth']>(marketDepth) });
};
