import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { createSelector } from 'reselect';

import { I18n } from '../../lib/i18n';
import R, { IRouteEnablementProps, ISettingsPage, getRouteEnablementProps } from '../../lib/routes';
import { IState } from '../../lib/store';
import {
  AccountIcon,
  BoxIcon,
  Cog3Icon,
  FortressIcon,
  LifeVestIcon,
  NotifyIcon,
  TodoList2Icon,
} from '../../media/svg_icons';
import AccountSettings from '../components/settings/AccountSettings';
import ApiKeySettings from '../components/settings/ApiKeySettings';
import KycSettings from '../components/settings/KycSettings';
import NotificationSettings from '../components/settings/NotificationSettings';
import SecuritySettings from '../components/settings/SecuritySettings';
import SupportSettings from '../components/settings/SupportSettings';
import MainLayout from '../layout/main_layout/MainLayout';
import SideMenuLayout, { ISideMenuItem } from '../layout/SideMenuLayout';
import PageSeoWrapper from '../widgets/PageSeoWrapper';
import NotFoundPage from './NotFoundPage';

const SETTINGS_COMPONENTS: { [page in ISettingsPage]: React.ComponentType<any> } = {
  account: AccountSettings,
  security: SecuritySettings,
  notifications: NotificationSettings,
  kyc: KycSettings,
  api: ApiKeySettings,
  support: SupportSettings,
};

type SettingsMenuItems = { [key in ISettingsPage]: ISideMenuItem };

const makeSettingsMenuItems = (props: IRouteEnablementProps): SettingsMenuItems => {
  const itemsTemplate: Array<ISideMenuItem & { page: ISettingsPage }> = [
    {
      page: 'account',
      label: 'settings:sections.account',
      icon: AccountIcon,
      subtitle: 'user',
    },
    {
      page: 'security',
      label: 'settings:sections.security',
      icon: BoxIcon,
    },
    {
      page: 'kyc',
      label: 'settings:sections.kyc',
      icon: TodoList2Icon,
    },
    {
      page: 'notifications',
      label: 'settings:sections.notifications',
      icon: NotifyIcon,
      subtitle: 'user',
    },
    {
      page: 'api',
      label: 'settings:sections.api',
      icon: FortressIcon,
      subtitle: 'account',
    },
    {
      page: 'support',
      label: 'settings:sections.support',
      icon: LifeVestIcon,
    },
  ];

  return itemsTemplate
    .filter((item) => {
      return R.SETTINGS.isEnabled(props, {
        page: item.page,
      });
    })
    .reduce((lookup, item) => {
      lookup[item.page] = {
        ...item,
        route: item.route || R.SETTINGS.to({ page: item.page }),
      };
      return lookup;
    }, {} as SettingsMenuItems);
};

interface ISettingsPageProps
  extends ConnectedProps<typeof connector>,
    RouteComponentProps<{ page: ISettingsPage }> {}

class SettingsPage extends React.PureComponent<ISettingsPageProps> {
  settingsMenuItems = createSelector(
    (props: IRouteEnablementProps) => props,
    makeSettingsMenuItems
  );

  render() {
    const page = (this.props.match.params && this.props.match.params.page) || null;

    const Component = SETTINGS_COMPONENTS[page];
    if (Component === undefined) {
      return <NotFoundPage />;
    }

    const items = this.settingsMenuItems(this.props.routeEnablementProps);

    return (
      <I18n>
        {(t) => (
          <PageSeoWrapper pageTitle={t(items[page].label)}>
            <SideMenuLayout
              pages={items}
              headerIcon={Cog3Icon}
              headerText={'pages.settings'}
              currentPage={items[page]}
              selectedRoute={R.SETTINGS.to({ page })}
            >
              {Component ? <Component /> : null}
            </SideMenuLayout>
          </PageSeoWrapper>
        )}
      </I18n>
    );
  }
}

const connector = connect((state: IState) => ({
  routeEnablementProps: getRouteEnablementProps(state),
}));

export default withRouter(connector(SettingsPage));
