import '../../media/world_flags_sprite/stylesheets/flags16.css';
import '../../media/world_flags_sprite/stylesheets/flags32.css';

import React from 'react';

import styled from '../../lib/styled_components';
import { classes } from '../../lib/util';
import { ICountryCode } from '../../types/backend_definitions';

const $CountryFlag = styled.span`
  vertical-align: middle;
`;

interface ICountryFlagProps {
  isoCode: ICountryCode;
  large?: boolean;
  className?: string;
}

const CountryFlag: React.FunctionComponent<ICountryFlagProps> = ({
  isoCode,
  large = false,
  className = '',
}) => (
  <$CountryFlag className={classes('CountryFlag', className, large ? 'f32' : 'f16')}>
    <span className={classes('flag', isoCode.toLowerCase())} />
  </$CountryFlag>
);

export default CountryFlag;
