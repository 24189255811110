import { IApiKey, IApiKeyPayload } from '../types/api_keys';
import { IBotPermission } from '../types/backend_definitions';

export const apiKeyRequestTransformation = (apiKey: IApiKey): IApiKeyPayload => {
  const bot_permissions: IBotPermission[] = [];

  apiKey.permission_read && bot_permissions.push('read');
  apiKey.permission_place_order && bot_permissions.push('place_order');
  apiKey.permission_cancel_order && bot_permissions.push('cancel_order');
  apiKey.permission_withdraw && bot_permissions.push('withdraw');

  return {
    bot_permissions,
    cidr: apiKey.cidr || null,
    disabled: Boolean(apiKey.disabled_at),
    description: apiKey.description || null,
  };
};
