import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { loadPendingDepositsForAllInstruments } from '../../actions/transactions/deposits';
import { loadWithdrawalWindowReportForInstrument } from '../../actions/transactions/withdrawals';
import lodash from '../../lib/lodash';
import R from '../../lib/routes';
import { IState } from '../../lib/store';
import styled from '../../lib/styled_components';
import { formatNumberToFixed, formatPercentage } from '../../lib/util';
import { SearchIcon, WalletIcon } from '../../media/svg_icons';
import { getBalances } from '../../selectors/balances';
import { getCardPaymentConfig } from '../../selectors/card_payments';
import {
  getChangePercentForPriceSummaryPair,
  getInstrumentsConfig,
  getPriceSummaryForPair,
} from '../../selectors/exchange';
import { getConversionRate, getRates } from '../../selectors/rates';
import {
  getPendingDepositsForAllInstruments,
  getWithdrawalReportForInstrument,
} from '../../selectors/transactions';
import { IInstrument } from '../../types/backend_definitions';
import { II18nextT } from '../../types/i18n';
import { ALL_INSTRUMENTS } from '../../types/instruments';
import { makePair } from '../../types/instruments';
import BuyWithCard from '../components/buy_with_card/BuyWithCard';
import { $SmallButton } from '../widgets/buttons';
import $Checkbox from '../widgets/Checkbox';
import $ColorText from '../widgets/ColorText';
import Header from '../widgets/Header';
import { $Icon } from '../widgets/Icon';
import { $Input } from '../widgets/Input';
import InstrumentIcon from '../widgets/InstrumentIcon';
import $Link from '../widgets/Link';
import { $HeaderWrapper } from '../widgets/PageHeaderWrappers';
import PageSeoWrapper from '../widgets/PageSeoWrapper';
import PrettyDecimals from '../widgets/PrettyDecimals';
import GenericTooltip from '../widgets/tooltips/GenericTooltip';
import { LimitLine } from '../widgets/WithdrawalLimit';

const $TableWrapper = styled.div`
  display: none;
  padding: 10px 0;

  @media screen and (min-width: 500px) {
    display: block;
  }

  @media screen and (min-width: 900px) {
    margin: 0 20px;
  }
`;

const $TableRowWrapper = styled.div`
  padding: 5px 10px;
  border-bottom: 1px solid ${(p) => p.theme.widgets.table.borderBottomColor};

  transition: background-color 0.1s ease-in;

  &:not(:last-child) {
    border-bottom: 1.2px solid ${(p) => p.theme.colors.lineColor};
  }
`;

const $DataItemLabel = styled.label`
  color: #f9f9f9cf;
`;

const $DataItemValue = styled.div`
  padding: 3px;
`;

const $DataItem = styled.div`
  min-width: 150px;
  padding: 5px;
  margin: 10px 10px 0 0;
  border-radius: 3px;
  text-align: center;
  background-color: #f0f8ff17;

  @media screen and ${(p) => p.theme.device.desktop} {
    margin: 15px 15px 0 0;
  }
`;

const $DataItemDynamic = styled($DataItem)`
  min-width: 230px;
`;

const $DataRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
`;

const $TableWrapperMobile = styled.div`
  display: none;
  padding: 10px 0;

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

const $CurrencyName = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
`;

const $DepositWithdrawalButton = styled($SmallButton).attrs<{ variant: 'deposit' | 'withdrawal' }>(
  (p) => ({
    color: p.theme.colors.white,
    backgroundColor: p.variant === 'deposit' ? p.theme.colors.buy : p.theme.colors.sell,
  })
)<{ variant: 'deposit' | 'withdrawal' }>`
  line-height: 1;
  min-width: 84px;
  height: 25px;
  @media screen and ${(p) => p.theme.device.mobile} {
    margin: 3px 0;
    min-width: 73px;
  }
`;

const $BuyWithCardButton = styled($SmallButton).attrs(() => ({
  backgroundColor: '#eaeaea',
  color: '#545454',
}))`
  height: 25px;
  font-weight: 100;

  @media screen and ${(p) => p.theme.device.mobile} {
    margin: 3px 0;
  }
`;

const $WithdrawalLimitWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const $Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const $ListItemSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 3px 1px 0;
`;

const $Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
`;

const $ListItem = styled($Row)`
  padding: 5px 3px;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.widgets.table.borderBottomColor};

  ${$Column}:first-child {
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 2px;
    }
  }

  ${$Column}:last-child {
    div {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }

  transition: background-color 0.1s ease-in;

  &:hover {
    background: ${(p) => p.theme.widgets.table.backgroundHover};
    color: inherit;
    cursor: pointer;
  }
`;

const $ListItemMobile = styled($ListItem)`
  @media screen and ${(p) => p.theme.device.mobile} {
    align-items: unset;
    flex-direction: column;
    padding: 5px 10px;

    &:not(:last-child) {
      border-bottom: 1.2px solid #ffffff57;
    }
  }
`;

const $SearchBarInput = styled($Input).attrs((p) => ({
  placeholderColor: p.theme.colors.baseNeutral,
}))`
  height: 35px;
  border: none;
  background: ${(p) => p.theme.components.marketList.search.background};
  padding: ${(p) => p.theme.components.marketList.search.padding};
  color: ${(p) => p.theme.components.marketList.search.color};
`;

const $FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;

  @media screen and ${(p) => p.theme.device.mobile} {
    padding: 5px 10px;
  }
`;

const $SearchWrapper = styled.div`
  position: relative;
  width: 200px;
`;

const $SearchIconWrapper = styled.div`
  display: inline;
  position: absolute;
  right: 7px;
  top: 8px;
`;

const $CheckboxWrapper = styled.div`
  margin-left: 30px;
`;

const $ActionButtonsWrapper = styled.div`
  display: flex;
  padding: 10px 0;

  ${$DepositWithdrawalButton} {
    &:first-child {
      margin-right: 5px;
    }
  }
`;

const $Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const $RowHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0;
`;

type BalancesSortIndex = { [key in IInstrument]?: number };
const balancesSortIndex: BalancesSortIndex = { BTC: 1, ETH: 2, SFX: 3, WSFX: 4, SFT: 5 };

interface IBalancesPageProps extends ConnectedProps<typeof connector> {}
interface IBalancesPageState {
  filterText: string;
  hideZeroBalances: boolean;
}

class BalancesPage extends React.PureComponent<IBalancesPageProps, IBalancesPageState> {
  static contextType: any = I18nContext;
  private buyWithCardRef: React.RefObject<any> = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      filterText: '',
      hideZeroBalances: false,
    };
  }

  componentDidMount() {
    this.props.loadPendingDepositsForAllInstruments();
    this.props.loadWithdrawalWindowReportForInstrument(ALL_INSTRUMENTS.USD.symbol);
  }

  handleSearch = (filterText: string) => {
    this.setState({ filterText });
  };

  handleHideBalances = (e: React.FormEvent<HTMLInputElement>) => {
    const checked = e.currentTarget.checked;

    this.setState({ hideZeroBalances: checked });
  };

  getBalanceDataForInstrument(instrumentSymbol) {
    const { t }: II18nextT = this.context;
    const { balances, instrumentsConfig, pendingDeposits, getBTCPriceSummary, rates } = this.props;
    const { reserved, available, total, symbol, name, digits } = balances[instrumentSymbol];

    const pending = pendingDeposits[instrumentSymbol];
    const isDepositDisabled =
      instrumentsConfig && instrumentsConfig[symbol] && instrumentsConfig[symbol].disable_deposits;
    const depositDisabledReason = isDepositDisabled
      ? t(`backend:messages.${instrumentsConfig[symbol].disable_deposits_reason}` as any)
      : '';
    const isWithdrawalDisabled =
      instrumentsConfig &&
      instrumentsConfig[symbol] &&
      instrumentsConfig[symbol].disable_withdrawals;
    const withdrawalDisabledReason = isWithdrawalDisabled
      ? t(`backend:messages.${instrumentsConfig[symbol].disable_withdrawals_reason}` as any)
      : '';

    const rate = getConversionRate(rates, symbol, ALL_INSTRUMENTS.BTC.symbol);
    const btcPriceSummary = getBTCPriceSummary(symbol);
    const btcEstimatedValue = rate && btcPriceSummary && total * rate;
    const btcPercentChange =
      btcEstimatedValue && btcPriceSummary
        ? getChangePercentForPriceSummaryPair(btcPriceSummary)
        : 0;

    return {
      reserved,
      pending,
      available,
      total,
      symbol,
      name,
      digits,
      depositDisabledReason,
      withdrawalDisabledReason,
      btcEstimatedValue,
      btcPercentChange,
    };
  }

  renderBuyWithCardButton(symbol: IInstrument) {
    const { t }: II18nextT = this.context;
    const { cardPaymentConfig } = this.props;

    if (!this.props.cardPaymentConfig.buyWithCardExposed) {
      return null;
    }

    if (
      !cardPaymentConfig ||
      !cardPaymentConfig.instrumentConfig.supported_crypto.includes(symbol)
    ) {
      return null;
    }

    let isDisabled = null;
    let disableReasonKey = null;

    if (cardPaymentConfig.userConfig.disable_service) {
      isDisabled = true;
      disableReasonKey = cardPaymentConfig.userConfig.disable_service_reason;
    } else if (!!cardPaymentConfig.userConfig.disable_instruments[symbol]) {
      isDisabled = true;
      disableReasonKey = cardPaymentConfig.userConfig.disable_instruments[symbol];
    }

    let result = (
      <$BuyWithCardButton
        onClick={() => this.buyWithCardRef.current.initializeWithInstrument(symbol)}
        disabled={isDisabled}
      >
        {t('transactions:cardPayments.buyWithCardButton')}
      </$BuyWithCardButton>
    );

    if (isDisabled) {
      result = (
        <GenericTooltip
          wrapperProps={{ disabled: isDisabled }}
          placement="right"
          variant={'error'}
          overlay={
            // TODO WTF
            lodash.isString(disableReasonKey)
              ? ((disableReasonKey.startsWith('deposits_disabled_reasons') ||
                  disableReasonKey.startsWith('card_payments_disabled_reasons')) &&
                  this.context.t(`backend:messages.${disableReasonKey}`)) ||
                disableReasonKey
              : t('transactions:deposits.depositsAreDisabled')
          }
        >
          {result}
        </GenericTooltip>
      );
    }

    return result;
  }

  renderActionButtons(symbol, depositDisabled = false) {
    const { t }: II18nextT = this.context;

    return (
      <$ActionButtonsWrapper>
        <$Link as={Link} to={R.DEPOSIT.to({ instrument: symbol })}>
          <$DepositWithdrawalButton variant="deposit" disabled={depositDisabled}>
            {t('transactions:deposits.deposit')}
          </$DepositWithdrawalButton>
        </$Link>
        <$Link as={Link} to={R.WITHDRAW.to({ instrument: symbol })}>
          <$DepositWithdrawalButton variant="withdrawal">
            {t('transactions:withdrawals.withdraw')}
          </$DepositWithdrawalButton>
        </$Link>
        {this.renderBuyWithCardButton(symbol)}
      </$ActionButtonsWrapper>
    );
  }

  renderTabletRow(instrumentSymbol, index) {
    const { t }: II18nextT = this.context;
    const data = this.getBalanceDataForInstrument(instrumentSymbol);

    return (
      <$TableRowWrapper key={index}>
        <$RowHeader>
          <InstrumentIcon
            size={22}
            name={data.symbol}
            type="white"
            style={{ verticalAlign: 'middle' }}
          />
          &nbsp;&nbsp;
          <$CurrencyName>{`${data.name} (${data.symbol})`}</$CurrencyName>
        </$RowHeader>
        <$DataRow>
          <$DataItem>
            <$DataItemLabel>{t('balancesPage.availableBalance')}</$DataItemLabel>
            <$DataItemValue>
              <strong>
                <PrettyDecimals value={formatNumberToFixed(data.available, data.digits)} />
              </strong>
            </$DataItemValue>
          </$DataItem>
          <$DataItem>
            <$DataItemLabel>{t('balancesPage.reserved')}</$DataItemLabel>
            <$DataItemValue>
              <PrettyDecimals value={formatNumberToFixed(data.reserved, data.digits)} />
            </$DataItemValue>
          </$DataItem>
          <$DataItem>
            <$DataItemLabel>{t('balancesPage.total')}</$DataItemLabel>
            <$DataItemValue>
              <PrettyDecimals value={formatNumberToFixed(data.total, data.digits)} />
            </$DataItemValue>
          </$DataItem>
          {data.btcEstimatedValue !== null && (
            <$DataItemDynamic>
              <$DataItemLabel>{t('balancesPage.estBtcValue')}</$DataItemLabel>
              <$DataItemValue>
                <PrettyDecimals
                  value={formatNumberToFixed(data.btcEstimatedValue, ALL_INSTRUMENTS.BTC.digits)}
                />{' '}
                {data.btcPercentChange !== null && (
                  <$ColorText
                    color={
                      data.btcPercentChange > 0 ? 'buy' : data.btcPercentChange < 0 ? 'sell' : null
                    }
                  >
                    {`(${formatPercentage(data.btcPercentChange, 2)})`}
                  </$ColorText>
                )}
              </$DataItemValue>
            </$DataItemDynamic>
          )}
        </$DataRow>
        {this.renderActionButtons(data.symbol, data.symbol === ALL_INSTRUMENTS.RSD.symbol)}
      </$TableRowWrapper>
    );
  }

  renderMobileRow(instrumentSymbol, index) {
    const { t }: II18nextT = this.context;
    const data = this.getBalanceDataForInstrument(instrumentSymbol);

    return (
      <$ListItemMobile key={index}>
        <$ListItemSection>
          <$RowHeader>
            <InstrumentIcon
              size={22}
              name={data.symbol}
              type="white"
              style={{ verticalAlign: 'middle' }}
            />
            &nbsp;&nbsp;
            <$CurrencyName>{`${data.name} (${data.symbol})`}</$CurrencyName>
          </$RowHeader>
        </$ListItemSection>
        <$ListItemSection>
          <div>{t('balancesPage.availableBalance')}</div>
          <div>
            <strong>
              <PrettyDecimals value={formatNumberToFixed(data.available, data.digits)} />
            </strong>
          </div>
        </$ListItemSection>
        <$ListItemSection>
          <div>{t('balancesPage.reserved')}</div>
          <div>
            <PrettyDecimals value={formatNumberToFixed(data.reserved, data.digits)} />
          </div>
        </$ListItemSection>
        <$ListItemSection>
          <div>{t('balancesPage.total')}</div>
          <div>
            <PrettyDecimals value={formatNumberToFixed(data.total, data.digits)} />
          </div>
        </$ListItemSection>
        {data.btcEstimatedValue !== null && (
          <$ListItemSection>
            <div>{t('balancesPage.estBtcValue')}</div>
            <div>
              <PrettyDecimals
                value={formatNumberToFixed(data.btcEstimatedValue, ALL_INSTRUMENTS.BTC.digits)}
              />{' '}
              {data.btcPercentChange !== null && (
                <$ColorText
                  color={
                    data.btcPercentChange > 0 ? 'buy' : data.btcPercentChange < 0 ? 'sell' : null
                  }
                >
                  {`(${formatPercentage(data.btcPercentChange, 2)})`}
                </$ColorText>
              )}
            </div>
          </$ListItemSection>
        )}
        {this.renderActionButtons(data.symbol, data.symbol === ALL_INSTRUMENTS.RSD.symbol)}
      </$ListItemMobile>
    );
  }

  renderRows(renderFn) {
    return Object.values(this.props.balances)
      .sort((a, b) => (balancesSortIndex[a.symbol] || 1000) - (balancesSortIndex[b.symbol] || 1000))
      .map((instrumentBalanceData, index: number) => {
        const { symbol, name } = instrumentBalanceData;
        const search = this.state.filterText.toUpperCase();

        if (
          (this.state.hideZeroBalances && instrumentBalanceData.available === 0) ||
          (search && !symbol.toUpperCase().includes(search) && !name.toUpperCase().includes(search))
        ) {
          return null;
        }

        return renderFn(symbol, index);
      });
  }

  render() {
    const { withdrawalReport } = this.props;
    const { t }: II18nextT = this.context;
    return (
      <PageSeoWrapper pageTitle={t('pages.balances')}>
        <div>
          <$HeaderWrapper>
            <Header title={t('pages.balances')} icon={WalletIcon} />
          </$HeaderWrapper>
          <$Wrapper>
            {withdrawalReport.limit && (
              <$WithdrawalLimitWrapper>
                <LimitLine
                  instrument={ALL_INSTRUMENTS.USD}
                  width={500}
                  instrumentLimit={parseFloat(withdrawalReport.limit)}
                  instrumentRemaining={parseFloat(withdrawalReport.remaining)}
                />
              </$WithdrawalLimitWrapper>
            )}

            <$FiltersWrapper>
              <$SearchWrapper>
                <$SearchBarInput
                  placeholder={t('filter')}
                  name="filter"
                  border="0px"
                  onChange={(e) => this.handleSearch(e.currentTarget.value)}
                  value={this.state.filterText}
                  autoComplete="off"
                />
                <$SearchIconWrapper>
                  <$Icon src={SearchIcon} size={20} />
                </$SearchIconWrapper>
              </$SearchWrapper>
              <$CheckboxWrapper>
                <$Checkbox
                  name="hideZeroBalances"
                  variant="light"
                  checked={this.state.hideZeroBalances}
                  onChange={this.handleHideBalances}
                >
                  {t('balancesPage.hideZeroBalances')}
                </$Checkbox>
              </$CheckboxWrapper>
            </$FiltersWrapper>
            <$TableWrapperMobile>
              {this.renderRows(this.renderMobileRow.bind(this))}
            </$TableWrapperMobile>
            <$TableWrapper>{this.renderRows(this.renderTabletRow.bind(this))}</$TableWrapper>

            <BuyWithCard showModal={true} ref={this.buyWithCardRef} />
          </$Wrapper>
        </div>
      </PageSeoWrapper>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    balances: getBalances(state),
    instrumentsConfig: getInstrumentsConfig(state),
    pendingDeposits: getPendingDepositsForAllInstruments(state),
    rates: getRates(state),
    getBTCPriceSummary: (instrument: IInstrument) => {
      const pair = makePair(instrument, 'BTC');
      return getPriceSummaryForPair(state, pair);
    },
    withdrawalReport: getWithdrawalReportForInstrument(state, ALL_INSTRUMENTS.USD.symbol),
    cardPaymentConfig: getCardPaymentConfig(state as IState),
  }),
  {
    loadPendingDepositsForAllInstruments,
    loadWithdrawalWindowReportForInstrument,
  }
);

export default connector(BalancesPage);
