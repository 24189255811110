import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { Link, LinkProps } from 'react-router-dom';

import { IPriceSummary24hPair } from '../../../actions/exchange';
import { I18n } from '../../../lib/i18n';
import lodash from '../../../lib/lodash';
import R from '../../../lib/routes';
import { IState } from '../../../lib/store';
import styled, { IThemeColorsUnion } from '../../../lib/styled_components';
import { formatNumberToFixed, formatPercentage } from '../../../lib/util';
import {
  CrossCuttingArrowsIcon,
  ISortOrderDirection,
  SearchIcon,
  SortOrderIcon,
  VolumeIcon,
} from '../../../media/svg_icons';
import { getNonEmptyActiveInstrumentOrders } from '../../../selectors/active_instruments_orders';
import { isUserLoggedIn } from '../../../selectors/auth';
import {
  getChangePercentForPriceSummaryPair,
  getNextPair,
  getPriceSummary24hSortedByVolumeList,
} from '../../../selectors/exchange';
import { getConversionRate, getRates } from '../../../selectors/rates';
import { IInstrument } from '../../../types/backend_definitions';
import { II18nextT } from '../../../types/i18n';
import { IInstrumentPair, pairInfo } from '../../../types/instruments';
import $ColorText from '../../widgets/ColorText';
import { $Icon } from '../../widgets/Icon';
import { $Input } from '../../widgets/Input';
import PrettyDecimals from '../../widgets/PrettyDecimals';
import ScrollBox from '../../widgets/ScrollBox';
import { ISelectOption, SimpleSelect } from '../../widgets/Select';

const $MarketList = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
`;

const $MarketListFilters = styled.div`
  padding: 0.5rem;
`;

const $FiltersGroup = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  grid-column-gap: 0.5rem;
  padding-bottom: 0.5rem;
`;

const $SortWidget = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(199, 195, 195, 0.45);
  height: 100%;
`;

const $SortButtonBase = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
  color: ${(p) => p.theme.colors.white};
  outline: none !important;
  &:hover {
    background-color: ${(p) => p.theme.components.marketList.hoverBackground};
  }
  &:active {
    background-color: ${(p) => p.theme.components.marketList.selectedBackground};
  }
`;

const $SortFieldButton = styled($SortButtonBase)`
  flex-grow: 1;
  justify-content: start;

  > :first-child {
    margin-right: 0.5rem;
  }
`;

const $SortDirectionButton = styled($SortButtonBase)`
  justify-content: center;
  padding: 0.6rem;
`;

const $SearchBarInput = styled($Input).attrs((p) => ({
  placeholderColor: p.theme.colors.baseNeutral,
}))`
  height: 35px;
  border: none;
  background: ${(p) => p.theme.components.marketList.search.background};
  padding: ${(p) => p.theme.components.marketList.search.padding};
  color: ${(p) => p.theme.components.marketList.search.color};
`;

const $SearchWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const $SearchIconWrapper = styled.div`
  display: inline;
  position: absolute;
  right: 7px;
  top: 8px;
`;

const $Title = styled.h4`
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 4px;
`;

const $ScrollBoxWrapper = styled.div`
  padding-left: 0.5rem;
  > * {
    height: 100%;
  }
`;

const $ListItem = styled.a<{ selected: boolean } & LinkProps>`
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;
  padding: 4px 3px;
  color: ${(p) => p.theme.components.marketList.color};
  text-decoration: none;
  background: ${(p) =>
    p.selected ? p.theme.components.marketList.selectedBackground : 'transparent'};
  &:hover {
    background: ${(p) =>
      p.selected
        ? p.theme.components.marketList.hoverSelectedBackground
        : p.theme.components.marketList.hoverBackground};
    color: white;
  }
  &:not(:last-child) {
    border-bottom: 1.2px solid ${(p) => p.theme.components.marketList.separatorColor};
  }
`;

const $ListItemTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 1px 3px 1px 0;
`;

const $ListItemTitle = styled.h3`
  font-weight: bold;
  margin: 0;

  // Quote instrument
  > :first-child {
    font-size: 1.3rem;
    font-weight: bold;
  }

  // Slash (/)
  > :nth-child(2) {
    color: ${(p) => p.theme.colors.grayLight};
    margin-left: 0.2rem;
    font-size: 1.3rem;
  }

  // Base instrument
  > :nth-child(3) {
    vertical-align: top;
    color: ${(p) => p.theme.colors.grayLight};
    margin-left: 0.2rem;
    font-size: 1rem;
  }
`;

const $ListItemPriceAndPercent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  // Price
  > :first-child {
    font-size: 1rem;
    font-weight: bold;
    word-break: break-all;
    margin-left: 1rem;
    text-align: right;
  }

  // Percent
  > :last-child {
    font-size: 0.8rem;
    font-weight: bold;
  }
`;

const $ListItemVolume = styled.div`
  // Icon
  > :first-child {
    margin-right: 0.2rem;
    opacity: 0.7;
    font-size: 0.8rem;
  }

  // Volume instrument
  > :nth-child(3) {
    margin-left: 0.2rem;
    opacity: 0.7;
    font-size: 0.8rem;
  }
`;

type SortOptions = 'ref_volume' | 'change';

interface IMarketSelectOption extends ISelectOption<IInstrument> {}
interface IMarketSortOption extends ISelectOption<SortOptions> {}

interface IMarketListProps extends ConnectedProps<typeof connector> {}

interface IMarketListState {
  filterText: string;
  market: IMarketSelectOption;
  sortFieldIndex: number;
  sortDirection: ISortOrderDirection;
}

class MarketList extends React.Component<IMarketListProps, IMarketListState> {
  static contextType: any = I18nContext;
  private marketOptions: { [key in IInstrument | 'all']: IMarketSelectOption };
  private marketOptionsList: IMarketSelectOption[];
  private sortFieldOptions: IMarketSortOption[];

  constructor(props) {
    super(props);

    this.state = {
      filterText: '',
      market: null,
      sortFieldIndex: 0,
      sortDirection: 'ASC',
    };
  }

  shouldComponentUpdate(nextProps: IMarketListProps, nextState: IMarketListState) {
    if (
      lodash.isEqual(this.props.instruments, nextProps.instruments) &&
      lodash.isEqual(this.props.nextPair, nextProps.nextPair) &&
      lodash.isEqual(this.state, nextState) &&
      lodash.isEqual(this.props.priceSummaries, nextProps.priceSummaries)
    ) {
      return false;
    }
    return true;
  }

  componentWillMount() {
    const { t }: II18nextT = this.context;

    this.marketOptions = this.props.priceSummaries.reduce(
      (res, cur) => {
        const marketPair = pairInfo(cur.pair);
        const baseSymbol = marketPair.base.symbol;

        if (!res[baseSymbol]) {
          res[baseSymbol] = { value: baseSymbol, label: baseSymbol };
        }

        return res;
      },
      { all: { value: 'all', label: t('marketList.marketFilterAll') } } as any
    );

    this.marketOptionsList = Object.values(this.marketOptions);

    this.sortFieldOptions = [
      { label: t('marketList.sortFieldVolume'), value: 'ref_volume' },
      { label: t('marketList.sortFieldChange'), value: 'change' },
    ];

    this.setState({ market: this.marketOptions.all });
  }

  handleSearch = (e) => {
    const filterText = e.currentTarget.value;
    this.setState({ filterText });
  };

  handleMarketChange = (option) => {
    this.setState({ market: option });
  };

  toggleSortDirection = () => {
    const currentSortDirection = this.state.sortDirection;
    this.setState({ sortDirection: currentSortDirection === 'ASC' ? 'DESC' : 'ASC' });
  };

  toggleSortField = () => {
    this.setState((state) => ({
      sortFieldIndex:
        state.sortFieldIndex >= this.sortFieldOptions.length - 1 ? 0 : state.sortFieldIndex + 1,
    }));
  };

  renderRow = (summary: IPriceSummary24hPair, marketPair: IInstrumentPair) => {
    const percentageChange = getChangePercentForPriceSummaryPair(summary);

    const percentColor: IThemeColorsUnion =
      percentageChange > 0 ? 'increase' : percentageChange < 0 ? 'decrease' : 'baseDarker';

    const showBaseInstrument = !this.state.market || (this.state.market.value as any) === 'all';
    const rate = getConversionRate(
      this.props.rates,
      marketPair.quote.symbol,
      marketPair.base.symbol
    );

    return (
      <I18n key={marketPair.path}>
        {(t) => (
          <$ListItem
            selected={marketPair.path === this.props.nextPair}
            as={Link}
            to={R.EXCHANGE.to({ pair: marketPair.path })}
          >
            <$ListItemTopRow>
              <$ListItemTitle>
                <span>{marketPair.quote.symbol}</span>
                {showBaseInstrument && (
                  <>
                    <span>/</span>
                    <span>{marketPair.base.symbol}</span>
                  </>
                )}
              </$ListItemTitle>
              <$ListItemPriceAndPercent>
                <PrettyDecimals
                  bold
                  color="white"
                  title={t('marketList.priceLabel')}
                  value={formatNumberToFixed(rate, marketPair.base.digits)}
                />
                <$ColorText color={percentColor} title={t('marketList.changeLabel')}>
                  {formatPercentage(percentageChange, 2)}
                </$ColorText>
              </$ListItemPriceAndPercent>
            </$ListItemTopRow>
            <$ListItemVolume title={t('marketList.volumeLabel')}>
              <VolumeIcon style={{ width: '0.6rem' }} />
              {/* NOTE: We use 2 decimals here to reduce visual noise and because more isn't important for this level of details */}
              <PrettyDecimals color="white" value={formatNumberToFixed(summary.volume, 2)} />
              <span>{marketPair.base.symbol}</span>
            </$ListItemVolume>
          </$ListItem>
        )}
      </I18n>
    );
  };

  render() {
    const { t }: II18nextT = this.context;
    const { priceSummaries } = this.props;

    // apply market list filters

    const filteredMarkets = priceSummaries.filter((market) => {
      const marketPair = pairInfo(market.pair);
      const search = this.state.filterText.toUpperCase();

      if (
        marketPair.base.symbol === this.state.market.value ||
        this.state.market.value === ('all' as any)
      ) {
        if (search) {
          if (
            marketPair.path.includes(search) ||
            marketPair.quote.name.toUpperCase().includes(search) ||
            marketPair.base.name.toUpperCase().includes(search)
          ) {
            return market;
          }

          return null;
        }

        return market;
      }

      return null;
    });

    const sortField = this.sortFieldOptions[this.state.sortFieldIndex].value;

    filteredMarkets.sort((market, nextMarket) => {
      // sort in descending order
      return sortField === 'change'
        ? getChangePercentForPriceSummaryPair(nextMarket) -
            getChangePercentForPriceSummaryPair(market)
        : // sorting by volume
          parseFloat(nextMarket[sortField] as string) - parseFloat(market[sortField] as string);
    });

    if (this.state.sortDirection === 'DESC') {
      filteredMarkets.reverse();
    }

    return (
      <$MarketList>
        <$MarketListFilters>
          <$Title>{t('marketList.title')}</$Title>
          <$FiltersGroup>
            <SimpleSelect
              inputId="marketOptions"
              width="100%"
              options={this.marketOptionsList}
              variant="transparent"
              onChange={this.handleMarketChange}
              value={this.state.market as any}
            />
            <$SortWidget>
              <$SortFieldButton
                onClick={this.toggleSortField}
                title={t('marketList.sortFieldLabel')}
              >
                <CrossCuttingArrowsIcon style={{ transform: 'rotate(90deg)' }} />
                {this.sortFieldOptions[this.state.sortFieldIndex].label}
              </$SortFieldButton>
              <$SortDirectionButton
                onClick={this.toggleSortDirection}
                title={t('marketList.sortDirectionLabel')}
              >
                <SortOrderIcon direction={this.state.sortDirection} />
              </$SortDirectionButton>
            </$SortWidget>
          </$FiltersGroup>

          <$SearchWrapper>
            <$SearchBarInput
              placeholder={t('filter')}
              name="filter"
              border="0px"
              onChange={this.handleSearch}
              value={this.state.filterText}
              autoComplete="off"
              readOnly
              onFocus={(event: React.FocusEvent<HTMLInputElement>) =>
                event.target.removeAttribute('readonly')
              }
              onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                event.target.setAttribute('readonly', 'true')
              }
            />
            <$SearchIconWrapper>
              <$Icon src={SearchIcon} size={20} />
            </$SearchIconWrapper>
          </$SearchWrapper>
        </$MarketListFilters>

        <$ScrollBoxWrapper>
          <ScrollBox>
            {filteredMarkets.map((marketSummary: IPriceSummary24hPair) => {
              const marketPair = pairInfo(marketSummary.pair);
              return this.renderRow(marketSummary, marketPair);
            })}
          </ScrollBox>
        </$ScrollBoxWrapper>
      </$MarketList>
    );
  }
}

const connector = connect((state: IState) => ({
  loggedIn: isUserLoggedIn(state),
  nextPair: getNextPair(state),
  priceSummaries: getPriceSummary24hSortedByVolumeList(state),
  instruments: getNonEmptyActiveInstrumentOrders(state),
  rates: getRates(state),
}));

export default connector(MarketList);
