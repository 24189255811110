import { IToastNotification } from '../actions/app';
import { IEnv } from '../bl/env';
import { IAccessToken } from '../types/auth';
import { ILanguage, ITrollboxRoom } from '../types/backend_definitions';

const LOCAL_STORAGE_KEYS = {
  access_token: 'access_token',
  trollbox_room: 'trollbox_room',
  language: 'language',
  toastNotifications: 'toastNotifications',
};

export class LocalStorage {
  constructor(private localStorage: Storage, private env: IEnv) {
    if (!this.trollboxRoom) {
      this.trollboxRoom = this.env.trollbox.defaultRoom;
    }
    if (!this.language) {
      this.language = this.env.i18n.defaultLanguage;
    }
  }

  // ACCESS TOKEN
  get accessToken() {
    return this.localStorage.getItem(LOCAL_STORAGE_KEYS.access_token);
  }
  set accessToken(value: IAccessToken) {
    this.localStorage.setItem(LOCAL_STORAGE_KEYS.access_token, value);
  }
  deleteAccessToken() {
    this.localStorage.removeItem(LOCAL_STORAGE_KEYS.access_token);
  }

  // TROLLBOX ROOM
  get trollboxRoom() {
    return this.localStorage.getItem(LOCAL_STORAGE_KEYS.trollbox_room) as ITrollboxRoom;
  }
  set trollboxRoom(value: ITrollboxRoom) {
    this.localStorage.setItem(LOCAL_STORAGE_KEYS.trollbox_room, value);
  }

  // INTERFACE LANGUAGE
  get language() {
    return this.localStorage.getItem(LOCAL_STORAGE_KEYS.language) as ILanguage;
  }
  set language(value: ILanguage) {
    this.localStorage.setItem(LOCAL_STORAGE_KEYS.language, value);
  }

  // NOTIFICATIONS
  get toastNotifications(): IToastNotification[] {
    return (
      JSON.parse(this.localStorage.getItem(LOCAL_STORAGE_KEYS.toastNotifications) || null) || []
    );
  }
  set toastNotifications(value: IToastNotification[]) {
    this.localStorage.setItem(LOCAL_STORAGE_KEYS.toastNotifications, JSON.stringify(value));
  }
  clearNotifications() {
    this.localStorage.removeItem(LOCAL_STORAGE_KEYS.toastNotifications);
  }
}
