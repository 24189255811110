import { createSelector } from 'reselect';

import { IState } from '../lib/store';
import { IPaginated } from '../types/api';
import {
  IApiManagedUserConfig,
  IApiWithdrawalWindowReport,
  IInstrument,
} from '../types/backend_definitions';
import { IDeposit, IWithdrawal, IWithdrawalStatus } from '../types/transactions';
import { DeepReadonly, Omit } from '../types/typescript_helpers';
import { IHorizontalScrollTableData } from '../views/widgets/HorizontalScrollTable';

// ***** WITHDRAWALS ***************************************************************************************************

// IHorizontalScrollTableData interfaces and helpers
export interface IWithdrawalFixed
  extends Pick<
    IWithdrawal,
    'created_at' | 'completed_at' | 'cancelled_at' | 'taken_at' | 'confirmed_at' | 'id'
  > {}

export interface IWithdrawalsHorizontalScrollTableData
  extends IHorizontalScrollTableData<IWithdrawalFixed, IWithdrawal> {}
export interface IWithdrawalsPaginationState extends Omit<IPaginated<IWithdrawal>, 'items'> {
  items: IWithdrawalsHorizontalScrollTableData;
}

// Helper functions
export const createWithdrawalsHorizontalScrollTableData = (
  data: IPaginated<IWithdrawal>
): IWithdrawalsPaginationState => {
  const { items, ...rest } = data;
  return items.reduce(
    (
      modifiedContent: IWithdrawalsPaginationState,
      withdrawal: IWithdrawal
    ): IWithdrawalsPaginationState => {
      const fixed: IWithdrawalFixed = {
        completed_at: withdrawal.completed_at,
        cancelled_at: withdrawal.cancelled_at,
        taken_at: withdrawal.taken_at,
        confirmed_at: withdrawal.confirmed_at,
        created_at: withdrawal.created_at,
        id: withdrawal.id,
      };
      modifiedContent.items.fixed.push(fixed);
      modifiedContent.items.scrollable.push(withdrawal);
      return modifiedContent;
    },
    { ...rest, items: { fixed: [], scrollable: [], length: data.items.length } }
  );
};

// Selectors
export const getWithdrawals = (state: IState) => state.transactions.withdrawals;

export const getWithdrawalsForInstrument = (state: IState, instrument: IInstrument) => {
  return getWithdrawals(state)[instrument];
};

export const getWithdrawalReport = (state: IState) => state.transactions.withdrawalReport;

export const getWithdrawalReportForInstrument = (
  state: IState,
  instrument: IInstrument
): IApiWithdrawalWindowReport => getWithdrawalReport(state)[instrument];

export const getWithdrawalTime = (withdrawal: IWithdrawal) =>
  withdrawal.completed_at ||
  withdrawal.cancelled_at ||
  withdrawal.taken_at ||
  withdrawal.confirmed_at ||
  withdrawal.created_at;

export const getWithdrawalStatus = (withdrawal: IWithdrawal): IWithdrawalStatus =>
  (withdrawal.failure_code && 'failed') ||
  (withdrawal.cancelled_at && 'cancelled') ||
  // completed, but hasn't failed => succeeded
  (withdrawal.completed_at && 'completed') ||
  // requires more info to proceed
  (withdrawal.more_info && 'more_info') ||
  (!withdrawal.confirmed_at && 'unconfirmed') ||
  // waiting in queue to be taken
  (!withdrawal.taken_at && 'pending') ||
  'processing';

// ***** DEPOSITS ******************************************************************************************************

export interface IDepositFixed extends Pick<IDeposit, 'created_at' | 'completed_at'> {}

export interface IDepositsHorizontalScrollTableData
  extends IHorizontalScrollTableData<IDepositFixed, IDeposit> {}
export interface IDepositsPaginationState extends Omit<IPaginated<IDeposit>, 'items'> {
  items: IDepositsHorizontalScrollTableData;
}

// Helper functions
export const createDepositsHorizontalScrollTableData = (
  data: IPaginated<IDeposit>
): IDepositsPaginationState => {
  const { items, ...rest } = data;
  return items.reduce(
    (modifiedContent: IDepositsPaginationState, deposit: IDeposit): IDepositsPaginationState => {
      const { created_at, completed_at } = deposit;
      const fixed = { created_at, completed_at };

      modifiedContent.items.fixed.push(fixed);
      modifiedContent.items.scrollable.push(deposit);
      return modifiedContent;
    },
    { ...rest, items: { fixed: [], scrollable: [], length: data.items.length } }
  );
};

// Selectors
export const getDeposits = (state: IState) => state.transactions.deposits;

export const getDepositsForInstrument = (
  state: IState,
  instrument: IInstrument
): DeepReadonly<IPaginated<IDeposit>> => state.transactions.deposits[instrument];

export const getPendingDepositsForAllInstruments = (state: IState) =>
  state.transactions.pendingDeposits;

export const getDepositsAddressForInstrument = (state: IState, instrument: IInstrument) =>
  state.transactions.depositAddresses[instrument];

// ***** MANAGED *******************************************************************************************************

export const getManagedConfig = (state: IState): DeepReadonly<IApiManagedUserConfig> =>
  state.transactions.managedUserConfig;
export const getManagedFiatFilterOptions = createSelector(
  (state: IState) => state.transactions.managedUserConfig.supported_fiat,
  (supportedFiat: readonly string[]) =>
    supportedFiat.reduce((opts, i) => {
      opts[i] = { value: i, label: i };
      return opts;
    }, {})
);

export const getManagedCoinsMarketData = (state: IState) =>
  state.transactions.managedCoinsMarketData;

export const getManagedBuyOrders = (state: IState) => state.transactions.managedBuyOrders;
export const getManagedSellOrders = (state: IState) => state.transactions.managedSellOrders;
