import { balancesTransformation } from '../lib/api_response_transformations';
import Container from '../lib/container';
import { handleError } from '../lib/errors';
import { commit, val } from '../lib/redux_helpers';
import { IState } from '../lib/store';
import { IApiWallet } from '../types/backend_definitions';
import { IBalances } from '../types/balances';

export interface IBalancesState {
  balances: IBalances;
}

export const BALANCES_STATE: IBalancesState = {
  balances: {},
};

export const updateBalances = (wallet: IApiWallet) =>
  commit('Update balances', {
    balances: val<IState['balances']>(balancesTransformation(wallet)),
  });

export const loadBalances = () => (dispatch, _, { api }: Container) => {
  return api
    .getWallets()
    .then((wallet) => dispatch(updateBalances(wallet)))
    .catch((err) => dispatch(handleError(err)));
};
