import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { I18n } from '../../lib/i18n';
import R, { IDevPage } from '../../lib/routes';
import { BoxIcon, Cog3Icon } from '../../media/svg_icons';
import { ITranslations } from '../../types/translations';
import DevIcons from '../components/dev/DevIcons';
import SideMenuLayout, { ISideMenuItem } from '../layout/SideMenuLayout';
import PageSeoWrapper from '../widgets/PageSeoWrapper';
import NotFoundPage from './NotFoundPage';

const DEV_COMPONENTS: { [page in IDevPage]: React.ComponentType<any> } = {
  icons: DevIcons,
};

type IDevMenuItems = { [key in IDevPage]: ISideMenuItem };
const DEV_MENU_ITEMS: IDevMenuItems = {
  icons: {
    route: R.DEV.to({ page: 'icons' }),
    icon: BoxIcon,
    label: 'Icons' as ITranslations,
  },
};

interface IDevPageProps extends RouteComponentProps<{ page: IDevPage }> {}

class DevPage extends React.PureComponent<IDevPageProps> {
  render() {
    const page = (this.props.match.params && this.props.match.params.page) || null;

    let Component = null;
    if (page) {
      Component = DEV_COMPONENTS[page];
      if (!Component) {
        return <NotFoundPage />;
      }
    }

    const items = DEV_MENU_ITEMS;
    return (
      <I18n>
        {(t) => (
          <PageSeoWrapper pageTitle={page ? t(items[page].label) : 'Select a page'}>
            <SideMenuLayout
              pages={items}
              headerIcon={Cog3Icon}
              headerText={'Development tools' as ITranslations}
              currentPage={items[page] || null}
              selectedRoute={page ? R.DEV.to({ page }) : null}
            >
              {Component ? <Component /> : null}
            </SideMenuLayout>
          </PageSeoWrapper>
        )}
      </I18n>
    );
  }
}

export default withRouter(DevPage);
