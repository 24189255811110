import {
  History,
  Location,
  LocationDescriptorObject,
  TransitionPromptHook,
  UnregisterCallback,
} from 'history';

import { Omit } from '../types/typescript_helpers';
import { Logger } from './logger';
import { IRouteId, IRouteSpecWithoutParams } from './routes';

export interface ICustomLocationState {
  id: IRouteId;
  prefixPath: string;
  routeProps?: object;
}
export type ICustomLocationDescriptor = LocationDescriptorObject<ICustomLocationState>;
export type ICustomLocation = Location<ICustomLocationState>;
export type ICustomHref = string | ICustomLocationDescriptor | IRouteSpecWithoutParams;

export const customHrefToString = (href: ICustomHref): string => {
  if (!href) {
    return '';
  }
  if (typeof href === 'string') {
    return href;
  }
  if ((href as IRouteSpecWithoutParams).to) {
    href = (href as IRouteSpecWithoutParams).to();
  }
  return (href as ICustomLocationDescriptor).pathname;
};

const PREVIOUS_LOCATIONS_LIMIT = 10;

const $customHistorySymbol = Symbol('customHistorySymbol');

export interface ICustomHistory
  extends Omit<History<ICustomLocationState>, 'createHref' | 'push' | 'replace'> {
  $customHistorySymbol: typeof $customHistorySymbol;

  /**
   * List of 10 most recent locations, starting with the current one
   */
  recentLocations: ICustomLocationDescriptor[];

  createHref(href: ICustomHref): string;
  push(href: ICustomHref, state?: ICustomLocationState): void;
  replace(href: ICustomHref, state?: ICustomLocationState): void;
}

const DELEGATED_METHOD_LEVELS = {
  createHref: 'verbose',
  push: 'info',
  replace: 'info',
};

/**
 * Turn ordinary history into our CustomHistory by mutating it.
 */
export const createCustomHistory = (
  baseHistory: History<ICustomLocationState>,
  logger: Logger
): ICustomHistory => {
  const log = logger.prefixed('History');

  const history: ICustomHistory = {
    recentLocations: [],

    $customHistorySymbol,

    get length() {
      return baseHistory.length;
    },
    get action() {
      return baseHistory.action;
    },
    get location() {
      return baseHistory.location;
    },

    listen(listener) {
      return baseHistory.listen(listener);
    },
    go(n: number) {
      return baseHistory.go(n);
    },
    goForward() {
      return baseHistory.goForward();
    },
    goBack() {
      return baseHistory.goBack();
    },
    block(prompt?: boolean | string | TransitionPromptHook): UnregisterCallback {
      return baseHistory.block(prompt);
    },

    createHref: makeCallDelegator(baseHistory.createHref, 'createHref'),
    push: makeCallDelegator(baseHistory.push, 'push'),
    replace: makeCallDelegator(baseHistory.replace, 'replace'),
  };

  baseHistory.listen((location) => {
    history.recentLocations.unshift(location);
    if (history.recentLocations.length > PREVIOUS_LOCATIONS_LIMIT) {
      history.recentLocations.length = PREVIOUS_LOCATIONS_LIMIT;
    }
  });
  history.recentLocations.unshift(baseHistory.location);

  history.$customHistorySymbol = $customHistorySymbol;

  return history;

  function makeCallDelegator(originalFn, methodName: keyof typeof DELEGATED_METHOD_LEVELS) {
    return (href: ICustomHref, state?: ICustomLocationState) => {
      if (typeof href === 'string') {
        if (state !== undefined) {
          logDelegatedCall(href, state);
          return originalFn.call(baseHistory, href, state);
        }
        logDelegatedCall(href);
        return originalFn.call(baseHistory, href);
      }

      // Support supplying IRouteSpecWithoutParams instead of calling IRouteSpecWithoutParams.to()
      let fromRouteSpec = false;
      if (typeof href === 'object' && (href as IRouteSpecWithoutParams).to) {
        href = (href as IRouteSpecWithoutParams).to();
        fromRouteSpec = true;
      }

      logDelegatedCall(
        (href as ICustomLocation).pathname,
        (href as ICustomLocation).state,
        fromRouteSpec
      );
      return originalFn.call(baseHistory, href);
    };

    function logDelegatedCall(
      pathname: string,
      state?: ICustomLocationState,
      fromRouteSpec = false
    ) {
      const parts = [methodName, ' ', pathname];
      if (state) {
        parts.push(' (id: ', state.id, ', prefix: ', state.prefixPath || '<none>', ')');
      }
      if (fromRouteSpec) {
        parts.push(' (from RouteSpec)');
      }
      log[DELEGATED_METHOD_LEVELS[methodName]](parts.join(''));
    }
  }
};
