import moment from 'moment';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { I18n } from '../../lib/i18n';
import { IState } from '../../lib/store';
import styled from '../../lib/styled_components';
import { WarningIcon } from '../../media/svg_icons';
import {
  getSystemTimeDrift,
  getSystemTimeError,
  systemTimeDriftToUTCMoment,
} from '../../selectors/app';
import { TimeUnits } from '../../types/constants';
import { $Icon } from './Icon';
import GenericTooltip from './tooltips/GenericTooltip';

const $Time = styled.time`
  font-size: 0.9rem;
  opacity: 0.7;
  cursor: default;
`;

const $TimeAlert = styled.span`
  margin-left: 5px;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ISystemTimeProps extends ConnectedProps<typeof connector> {}

interface ISystemTimeState {
  date: string;
}

class SystemTime extends React.PureComponent<ISystemTimeProps, ISystemTimeState> {
  private timer;

  constructor(props) {
    super(props);

    this.state = {
      date: moment.utc().format('HH:mm:ss (UTC)'),
    };
  }

  componentDidMount() {
    this.timer = setInterval(this.updateTime, TimeUnits.second / 2);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  updateTime = () => {
    this.setState({
      date: systemTimeDriftToUTCMoment(this.props.systemTimeDrift).format('HH:mm:ss (UTC)'),
    });
  };

  renderTooltip = (overlay) => {
    return (
      <GenericTooltip placement="right" trigger={['hover']} overlay={overlay} variant={'error'}>
        <$TimeAlert>
          <$Icon src={WarningIcon} size={9} />
        </$TimeAlert>
      </GenericTooltip>
    );
  };

  renderWarning = (t) => {
    if (Math.abs(this.props.systemTimeDrift) < 30 * TimeUnits.second) {
      return null;
    }

    return this.renderTooltip(t('systemTimeWidget.outOfSync'));
  };

  renderError = (t) => {
    if (!this.props.systemTimeError) {
      return null;
    }

    return this.renderTooltip(t('systemTimeWidget.failedToLoad'));
  };

  render() {
    return (
      <I18n>
        {(t) => (
          <>
            <$Time dateTime={this.state.date} title={t('systemTimeWidget.title')}>
              {this.state.date}
            </$Time>
            {this.renderWarning(t)}
            {this.renderError(t)}
          </>
        )}
      </I18n>
    );
  }
}

const connector = connect((state: IState) => ({
  systemTimeDrift: getSystemTimeDrift(state),
  systemTimeError: getSystemTimeError(state),
}));

export default connector(SystemTime);
