import styled, { css } from '../../lib/styled_components';
import { $Input, $InputDark } from './Input';

const attrs = {
  type: 'text',
  maxLength: 6,
  placeholder: '123456',
  autoComplete: 'off',
};

const cssStyle = css`
  width: 100%;
  text-align: center;
  padding: 0.5em;
  letter-spacing: 1em;
  font-weight: bold;
  ::placeholder {
    color: ${(p) => p.theme.colors.baseNeutral};
    opacity: 0.2;
  }
`;

export const $CodeInput = styled($Input).attrs(attrs)`
  ${cssStyle}
`;

export const $CodeInputDark = styled($InputDark).attrs(attrs)`
  ${cssStyle}
  ::placeholder {
    color: ${(p) => p.theme.colors.baseLighter};
    opacity: 0.2;
  }
`;
