export type IEmitterHandler = (any, string?) => any;
export type IEventUnsubscriber = () => boolean;

export class EventEmitter {
  private handlersByEvent: { [event: string]: IEmitterHandler[] } = {};

  /**
   * Subscribe to event. Returns function that can be called to unsubscribe
   */
  subscribe(event: string, handler: IEmitterHandler): IEventUnsubscriber {
    this.handlersByEvent[event] = (this.handlersByEvent[event] || []).concat(handler);
    return () => {
      return this.unsubscribe(event, handler);
    };
  }

  /**
   * Unsubscribe specific handler from specific event. Returns true if such handler is found.
   */
  unsubscribe(event: string, handler: IEmitterHandler): boolean {
    if (this.handlersByEvent[event]) {
      for (let i = 0; i < this.handlersByEvent[event].length; i++) {
        if (this.handlersByEvent[event][i] === handler) {
          // Found handler
          this.handlersByEvent[event].splice(i, 1);
          if (!this.handlersByEvent[event].length) {
            delete this.handlersByEvent[event];
          }

          return true;
        }
      }
    }

    return false;
  }

  /**
   * Unsubscribe all handlers for event or all handlers in general (if event is not supplied)
   */
  unsubscribeAll(event: string) {
    if (event) {
      delete this.handlersByEvent[event];
    } else {
      this.handlersByEvent = {};
    }
  }

  /**
   * Emit given payload to all handlers for given event
   */
  emit(event: string, payload, eventName?: string) {
    const handlers = this.handlersByEvent[event];
    if (handlers) {
      for (const handler of handlers) {
        // TODO: Do we need a try/catch here? How would that work?
        handler(payload, eventName || event);
      }
    }
  }
}
