import * as React from 'react';

import { I18n } from '../../../lib/i18n';
import Withdraw from '../../../views/components/transactions/Withdraw';
import PageSeoWrapper from '../../widgets/PageSeoWrapper';
import TransactionPagesLayout from './TransactionPagesLayout';

const WithdrawPage: React.FunctionComponent<{}> = () => (
  <I18n>
    {(t) => (
      <PageSeoWrapper pageTitle={t('pages.withdraw')}>
        <TransactionPagesLayout type="withdraw">
          <Withdraw />
        </TransactionPagesLayout>
      </PageSeoWrapper>
    )}
  </I18n>
);

export default WithdrawPage;
