import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CSSObject } from 'styled-components';

import { ICustomHref, customHrefToString } from '../../lib/history';
import R from '../../lib/routes';
import styled, { zIndex } from '../../lib/styled_components';
import { II18nextT } from '../../types/i18n';
import { IInstrumentObject } from '../../types/instruments';
import { ITranslations } from '../../types/translations';
import { $Icon } from './Icon';
import $Link from './Link';

const $InstrumentTitle = styled('div')<{ bold: boolean }>`
  padding: 6px;
  font-weight: bold;
  color: ${(p) => (p.bold === true ? 'white' : p.theme.colors.grayLighter)};

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 12rem;
`;

const $DropdownMenuOverlay = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.dropdownMenu};

  @media screen and ${(p) => p.theme.device.mobile} {
    position: absolute;
    left: 0;
    background-color: ${(p) => p.theme.components.dropdownMenu.background};
  }
`;

const $DropdownMenuWrapper = styled.div`
  @media screen and ${(p) => p.theme.device.mobile} {
    background-color: ${(p) => p.theme.base.bodyBackground};
  }
`;

const $DropdownMenuHeader = styled.div`
  font-weight: bold;
  background: ${(p) => p.theme.colors.brandPrimary};
  text-transform: uppercase;
  padding: 5px 40px;
  letter-spacing: 2px;
  font-size: ${(p) => p.theme.fontSize.large};
`;

const $DropdownMenuList = styled.ul`
  padding: 0;
  margin: 0;
  width: 100%;
  z-index: 3;
`;

const $DropdownMenuItem = styled('li')<{ selected: boolean }>`
  padding: 0 40px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  min-height: 50px;
  background: ${(p) => p.selected && p.theme.components.dropdownMenu.selectedBackground};
  transition: padding
    ${(p) =>
      p.theme.layout.sideMenu.transitionDuration + ' ' + p.theme.layout.sideMenu.transitionFunc};

  svg {
    width: ${(p) => p.theme.layout.sideMenu.icons.inactiveSize};
    height: ${(p) => p.theme.layout.sideMenu.icons.inactiveSize};
  }

  @media screen and ${(p) => p.theme.device.laptop} {
    padding: 0 0 0 36px;
  }
  @media screen and ${(p) => p.theme.device.mobile} {
    padding: 0 0 0 30px;
  }

  &:hover {
    background: ${(p) => p.theme.components.dropdownMenu.hoverBackground};
  }
`;

export interface IDropdownMenuItem {
  label: ITranslations;
  route?: ICustomHref;
  icon?: any;
  render?: () => any;
}

export interface IDropdownMenuSection {
  header?: ITranslations;
  items: IDropdownMenuItem[];
}

interface IDropdownMenuStyle {
  overlay?: CSSObject;
  wrapper?: CSSObject;
  list?: CSSObject;
  item?: CSSObject;
}

interface IDropdownMenuProps {
  sections: IDropdownMenuSection[];
  selectedRoute?: ICustomHref;
  isOpen: boolean;
  toggleMenu?: () => void;
  style?: IDropdownMenuStyle;
}

interface ILinkProps {
  to: ICustomHref;
  key: number | string;
  onClick?: () => any;
}

class MenuLink extends React.PureComponent<ILinkProps> {
  render() {
    const to = this.props.to;
    if (typeof to === 'string' && (to.startsWith('http') || to.startsWith('www.'))) {
      // External link
      return (
        <a href={to} target="_blank" {...this.props} style={{ textDecoration: 'none' }}>
          {this.props.children}
        </a>
      );
    }

    // Internal link
    return (
      <$Link as={Link} {...this.props}>
        {this.props.children}
      </$Link>
    );
  }
}

interface IInstrumentMenuProps {
  instrumentsBank: ReadonlyArray<IInstrumentObject>;
  instrumentsCrypto: ReadonlyArray<IInstrumentObject>;
  type: 'deposit' | 'withdraw';
  selectedRoute: ICustomHref;
  isOpen: boolean;
  toggleMenu?: () => void;
}

export class InstrumentMenu extends React.PureComponent<IInstrumentMenuProps> {
  prepareSection = (header: ITranslations, items): IDropdownMenuSection => {
    return items.reduce(
      (res, cur) => {
        res.items.push({
          label: cur.name,
          icon: cur.symbol,
          route: (this.props.type === 'deposit' ? R.DEPOSIT : R.WITHDRAW).to({
            instrument: cur.symbol,
          }),
        } as IDropdownMenuItem);
        return res;
      },
      { header, items: [] } as IDropdownMenuSection
    );
  };

  render() {
    const bankSection = this.prepareSection(
      'transactionPages.bankWire',
      this.props.instrumentsBank
    );
    const cryptoSection = this.prepareSection(
      'transactionPages.cryptocurrencies',
      this.props.instrumentsCrypto
    );
    return (
      <DropdownMenu
        isOpen={this.props.isOpen}
        sections={[bankSection, cryptoSection]}
        selectedRoute={this.props.selectedRoute}
        toggleMenu={this.props.toggleMenu}
      />
    );
  }
}

export class DropdownMenu extends React.PureComponent<IDropdownMenuProps> {
  static contextType: any = I18nContext;

  renderSection = (section: IDropdownMenuSection, key) => {
    const { t }: II18nextT = this.context;

    if (!section.items || section.items.length === 0) {
      return null;
    }

    return (
      <$DropdownMenuList style={this.props.style && this.props.style.list} key={key}>
        {section.header && <$DropdownMenuHeader>{t(section.header)}</$DropdownMenuHeader>}
        {section.items.map((item, i) => (
          <MenuLink
            to={item.route}
            key={i}
            onClick={() => this.props.toggleMenu && this.props.toggleMenu()}
          >
            <$DropdownMenuItem
              style={this.props.style && this.props.style.item}
              selected={
                customHrefToString(item.route) === customHrefToString(this.props.selectedRoute)
              }
            >
              {item.render ? (
                item.render()
              ) : (
                <>
                  {item.icon && <$Icon src={item.icon} />}
                  <$InstrumentTitle bold={true}>{t(item.label as ITranslations)}</$InstrumentTitle>
                </>
              )}
            </$DropdownMenuItem>
          </MenuLink>
        ))}
      </$DropdownMenuList>
    );
  };

  render() {
    return (
      <>
        {this.props.isOpen && (
          <$DropdownMenuOverlay
            style={this.props.style && this.props.style.overlay}
            onClick={(e) => {
              e.target === e.currentTarget && this.props.toggleMenu && this.props.toggleMenu();
            }}
          >
            <$DropdownMenuWrapper style={this.props.style && this.props.style.wrapper}>
              {this.props.sections.map(this.renderSection)}
            </$DropdownMenuWrapper>
          </$DropdownMenuOverlay>
        )}
      </>
    );
  }
}
