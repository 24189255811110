import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { CoinsIcon } from '../../../media/svg_icons';
import { getBalanceForInstrument } from '../../../selectors/balances';
import { IBalance } from '../../../types/balances';
import { II18nextT } from '../../../types/i18n';
import { IInstrumentObject } from '../../../types/instruments';
import Header from '../../widgets/Header';

const $CurrentBalance = styled.div`
  min-width: 250px;
`;

const $Balance = styled.div`
  margin: 5px 0;
  padding-left: 30px;
  h3 {
    text-transform: uppercase;
    font-weight: bold;
    margin: 3px 0;
    font-size: 1rem;
  }
  div {
    font-size: 2rem;
  }
`;
export const $AvailableBalance = styled($Balance)`
  padding-bottom: 5px;
  h3 {
    font-size: 1rem;
  }
  div {
    margin-left: 10px;
    font-size: 2rem;
  }
`;
export const $ReservedBalance = styled($Balance)`
  color: ${(p) => p.theme.colors.grayLight};
  padding-bottom: 5px;
  max-width: 500px;
  h3 {
    font-size: 1rem;
  }
  div {
    margin-left: 10px;
    font-size: 2rem;
  }
`;
export const $TotalBalance = styled($Balance)`
  padding-bottom: 5px;
  h3 {
    font-size: 1rem;
    color: ${(p) => p.theme.colors.white};
  }
  div {
    margin-left: 10px;
    color: ${(p) => p.theme.colors.info};
    font-size: 2rem;
  }
`;
interface IOwnProps {
  instrument: IInstrumentObject;
}

interface ICurrentBalanceProps extends IOwnProps, ConnectedProps<typeof connector> {}
interface ICurrentBalanceState {}

class CurrentBalance extends React.PureComponent<ICurrentBalanceProps, ICurrentBalanceState> {
  static contextType: any = I18nContext;

  render() {
    const { t }: II18nextT = this.context;

    const { instrument } = this.props;

    const balance: IBalance = this.props.balance || {
      total: 0,
      reserved: 0,
      available: 0,
      ...instrument,
    };

    return (
      <$CurrentBalance>
        <Header
          title={t('transactions:currentBalance', { instrumentSymbol: instrument.symbol })}
          icon={CoinsIcon}
          iconSize={25}
          capitalized
        />
        <$AvailableBalance>
          <h3>{t('available')}</h3>
          <div className="balances">
            {balance.available.toFixed(instrument.digits)} {instrument.symbol}
          </div>
        </$AvailableBalance>
        <$ReservedBalance>
          <h3>{t('reserved')}</h3>
          <div className="balances">
            {balance.reserved.toFixed(instrument.digits)} {instrument.symbol}
          </div>
        </$ReservedBalance>
        <$TotalBalance>
          <h3>{t('total')}</h3>
          <div className="balances">
            {balance.total.toFixed(instrument.digits)} {instrument.symbol}
          </div>
        </$TotalBalance>
      </$CurrentBalance>
    );
  }
}
const connector = connect((state: IState, props: IOwnProps) => ({
  balance: getBalanceForInstrument(state, props.instrument.symbol),
}));

export default connector(CurrentBalance);
