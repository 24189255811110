import { getSession } from '../selectors/auth';
import { IWebSocketEvent, IWebSocketSector } from '../types/backend_definitions';
import { EventEmitter, IEmitterHandler, IEventUnsubscriber } from './event_emitter';
import lodash from './lodash';
import { Logger } from './logger';
import { Store } from './store';

const DEFAULT_SECTOR_VALUE = '_default_';
// This meta-event is sent as part of sector switching flow
const NAMESPACE_EVENT = '_namespace';

class SectorInfo {
  sector: IWebSocketSector;
  value: any;
  namespace: string;
  socket?: any;

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();

      // Because socket.io IS SUCH A WONDERFUL LIBRARY
      // we have to "help it out" clean up, like wiping toddler's butt
      // https://stackoverflow.com/a/28172886/2405595
      delete this.socket.io.nsps[this.socket.nsp];

      this.socket = null;
    }
  }
}

export class SocketManager {
  private token?: string = null;
  private eventEmitter = new EventEmitter();
  private sectors: { [sector in IWebSocketSector]?: SectorInfo } = {};

  private _enabled = false;
  get enabled() {
    return this._enabled;
  }
  set enabled(value) {
    this._enabled = value;
    this.updateSockets();
  }

  constructor(private store: Store, private logger: Logger, private createSocket: any) {
    this.logger = this.logger.prefixed(this);

    // This will create default connection
    this.setSector('', DEFAULT_SECTOR_VALUE);

    // This will try to reconnect when backend opens up a new namespace
    this.onEvent(NAMESPACE_EVENT as any, () => this.updateSockets());

    this.store.subscribe(() => this.updateToken());
  }

  private updateToken() {
    const session = getSession(this.store.getState());
    const currentToken = (session && session.access_token) || null;
    if (this.token !== currentToken) {
      this.setToken(currentToken);
    }
  }

  private setToken(newToken) {
    if (newToken) {
      this.logger.info(`New token: ${newToken}`);
      this.token = newToken;
    } else {
      this.logger.info(`Logged out`);
      this.token = null;
    }

    lodash.forEach(this.sectors, (sectorInfo: SectorInfo) => {
      // Why the hell are we doing this, you might ask?
      // Because SocketIO suuuuuuuuuuuuuuuuuuucks
      // Once you set token, it doesn't update it. So you have to dig in and change it yourself
      // on the underlying Manager instance
      if (sectorInfo.socket && sectorInfo.socket.io.opts) {
        sectorInfo.socket.io.opts.query = 'token=' + (newToken || '');
      }

      sectorInfo.disconnect();
    });

    this.updateSockets();
  }

  private updateSockets() {
    lodash.forEach(this.sectors, (sectorInfo: SectorInfo) => {
      const namespace = generateNamespace(sectorInfo.sector, sectorInfo.value);
      if (!this.enabled || (namespace !== sectorInfo.namespace && sectorInfo.socket)) {
        this.logger.info(`Socket disconnected for ${sectorInfo.namespace}`);
        sectorInfo.disconnect();
        sectorInfo.namespace = null;
      }

      if (this.enabled && !sectorInfo.socket && sectorInfo.value) {
        this.logger.info(`Socket opened for ${namespace} (token = ${this.token})`);

        sectorInfo.namespace = namespace;
        sectorInfo.socket = this.createSocket(this.store.getState().env.socketBaseUrl + namespace, {
          query: 'token=' + (this.token || ''),
        });

        sectorInfo.socket.on('message', (data) => {
          this.handleMessage(sectorInfo, data.name, data.payload);
        });

        sectorInfo.socket.on('error', (error) => {
          if (error === 'Invalid namespace') {
            sectorInfo.disconnect();
          }
        });
      }
    });
  }

  private handleMessage(sectorInfo: SectorInfo, name, payload) {
    this.logger.shouldVerbose &&
      this.logger.verbose(`[${sectorInfo.sector}:${sectorInfo.value}][${name}]`, payload);
    const handlerKey = generateHandlerKey(sectorInfo.sector, name);
    this.eventEmitter.emit(handlerKey, payload);
  }

  /**
   * Set value for a given sector. So within this sector, this client
   * will receive only the messages sent under given value.
   * In the background, this will (re)create namespace for this sector.
   */
  setSector(sector: IWebSocketSector | '', value) {
    let sectorInfo = this.sectors[sector];
    if (!sectorInfo) {
      sectorInfo = this.sectors[sector] = new SectorInfo();
      sectorInfo.sector = sector;
      sectorInfo.namespace = generateNamespace(sector, value);
    }
    sectorInfo.value = value || '';
    this.updateSockets();
  }

  /**
   * Attach handler to normal event
   * (untargetted broadcast or targeted to this client)
   */
  onEvent(name: IWebSocketEvent, handler): IEventUnsubscriber {
    const handlerKey = generateHandlerKey('', name);
    return this.eventEmitter.subscribe(handlerKey, handler);
  }

  /**
   * Attach handler to receive events for specific sector
   */
  onSectorEvent(
    sector: IWebSocketSector,
    name: IWebSocketEvent,
    handler: IEmitterHandler
  ): IEventUnsubscriber {
    const handlerKey = generateHandlerKey(sector, name);
    return this.eventEmitter.subscribe(handlerKey, handler);
  }

  // noinspection JSUnusedGlobalSymbols
  offEvent(name: IWebSocketEvent, handler: IEmitterHandler): boolean {
    return this.offSectorEvent('', name, handler);
  }

  offSectorEvent(
    sector: IWebSocketSector | '',
    name: IWebSocketEvent,
    handler: IEmitterHandler
  ): boolean {
    const handlerKey = generateHandlerKey(sector, name);
    return this.eventEmitter.unsubscribe(handlerKey, handler);
  }
}

function generateHandlerKey(sector: IWebSocketSector | '', name): string {
  return `${sector}:${name}`;
}

function generateNamespace(sector: IWebSocketSector | '', value): string {
  return sector ? `/${sector}/${value}` : '/';
}
