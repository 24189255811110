import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import {
  IManagedGraphInterval,
  loadGraphData,
  loadManagedCoinsMarketData,
  updateManagedGraph,
} from '../../../actions/transactions/managed';
import { ICustomLocationDescriptor } from '../../../lib/history';
import { I18n } from '../../../lib/i18n';
import Quantity from '../../../lib/quantity_';
import R from '../../../lib/routes';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { formatNumberToFixed } from '../../../lib/util';
import { MonitorIcon, WaveIcon } from '../../../media/svg_icons';
import { getBalances } from '../../../selectors/balances';
import { getManagedGraph } from '../../../selectors/managed';
import { getConversionRate, getRates } from '../../../selectors/rates';
import {
  getManagedCoinsMarketData,
  getManagedConfig,
  getManagedFiatFilterOptions,
} from '../../../selectors/transactions';
import {
  IApiGraphDataForInstrument,
  IInstrument,
  IMarketInstrument,
} from '../../../types/backend_definitions';
import { IBalance } from '../../../types/balances';
import { II18nextT } from '../../../types/i18n';
import { ALL_INSTRUMENTS } from '../../../types/instruments';
import { IManagedFiatCurrency } from '../../../types/managed';
import { ITranslations } from '../../../types/translations';
import BinaryPrefixDecimals from '../../widgets/BinaryPrefixDecimals';
import { $InfoSmallButton } from '../../widgets/buttons';
import $ColorText from '../../widgets/ColorText';
import Header from '../../widgets/Header';
import InstrumentIcon from '../../widgets/InstrumentIcon';
import $Link from '../../widgets/Link';
import {
  $HeaderWrapperJustified,
  $InnerWrapper,
  $ManagedGreenButton,
  $ManagedRedButton,
  $RectangleCard,
  $Wrapper,
} from '../../widgets/Managed';
import PrettyDecimals from '../../widgets/PrettyDecimals';
import { SimpleSelect, SimplexSelect } from '../../widgets/Select';
import GenericTooltip from '../../widgets/tooltips/GenericTooltip';
import BarChart from './BarChart';

const $BalanceCard = styled($RectangleCard)`
  max-width: 62rem;
  margin-bottom: 2rem;

  display: grid;
  grid-template-columns: 1fr 1fr;

  h2 {
    margin: 0 0 0.5rem 0;
    text-transform: uppercase;
  }

  @media screen and ${(p) => p.theme.device.tablet} {
    max-width: 30rem;
  }

  @media screen and ${(p) => p.theme.device.mobile} {
    max-width: 61rem;
    margin-bottom: 1rem;

    h2 {
      font-size: ${(p) => p.theme.fontSize.largest};
    }

    > div:nth-child(1) {
      > span {
        font-size: ${(p) => p.theme.fontSize.larger};
      }
    }
  }

  @media ${(p) => p.theme.device.mobile_SamsungGalaxyTab2} {
    max-width: 30rem;
  }
`;

const $CardHeaderLeft = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 0.8rem;
  }

  > div {
    h2 {
      margin: 0.4rem 0;
    }

    h3 {
      margin: 0.4rem 0;
    }
  }

  @media screen and ${(p) => p.theme.device.mobile} {
    > img {
      height: 35px;
      width: 35px;
    }

    h2 {
      margin: 0.1rem 0;
      font-size: ${(p) => p.theme.fontSize.large};
      line-height: ${(p) => p.theme.fontSize.base};
    }
    h3 {
      font-size: ${(p) => p.theme.fontSize.base};
      line-height: ${(p) => p.theme.fontSize.small};
      margin: 0.1rem 0;
    }
  }
`;
const $CardHeaderRight = styled.div`
  display: flex;
  > a:last-child {
    button {
      margin-right: 0;
    }
  }
`;

const $CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  height: ${(p) => p.theme.components.managed.portfolio.cardHeaderHeight};

  @media screen and ${(p) => p.theme.device.mobile} {
    height: ${(p) => p.theme.components.managed.portfolio.mobileCardHeaderHeight};
  }
`;
const $CardHeaderWave = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  transform: translateX(-40%);
`;

const $CardBodyHistory = styled.p`
  font-size: ${(p) => p.theme.fontSize.large};
  color: ${(p) => p.theme.colors.baseNeutral};
  line-height: 16px;
  min-height: 65px;
  margin: 0.5rem 0;
  margin-bottom: 1rem;
`;

const $Arrow = styled.div<{}>`
  text-align: center;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #fff;
  padding: 0 2px;
`;

const $InfoLabel = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  color: ${(p) => p.theme.colors.baseDarker};
  font-size: 1rem;
  margin-right: 5px;
`;

const $CardBodyInfoItem = styled.div<{
  changeValue?: 'high' | 'low';
  isPositive?: boolean;
  isNegative?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  word-break: break-all;

  margin-top: 0;

  > span:nth-child(2) {
    color: ${(p) =>
      p.changeValue === 'high' || p.isPositive
        ? p.theme.colors.buy
        : p.changeValue === 'low' || p.isNegative
        ? p.theme.colors.sell
        : p.theme.colors.white};
  }
`;

const $CustomizableInfoItem = styled($CardBodyInfoItem)`
  justify-content: space-around;
`;

const $TopCardBodyInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const $InactiveCardItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  > ${$CustomizableInfoItem}:first-child {
    justify-content: flex-start;
  }
`;

const $InactiveCardBottomInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;

  > span:first-child {
    text-transform: uppercase;
    font-weight: bold;
    font-size: ${(p) => p.theme.fontSize.smaller};
    color: ${(p) => p.theme.colors.baseDarker};

    > span {
      font-weight: normal;
      font-size: ${(p) => p.theme.fontSize.large};
      color: ${(p) => p.theme.colors.white};
    }
  }

  a {
    text-transform: uppercase;
    font-weight: bold;
    font-size: ${(p) => p.theme.fontSize.large};
    text-decoration: underline !important;
  }
`;

const $ActiveCardItemsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 130px;
  margin-top: 5px;
`;

const $CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 30rem));
  grid-auto-rows: 24rem;
  grid-gap: 2rem;

  @media screen and ${(p) => p.theme.device.tablet} {
    grid-template-columns: 30rem;
  }
  @media screen and ${(p) => p.theme.device.mobile} {
    grid-auto-rows: 22rem;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, minmax(auto, 30rem));
  }

  @media screen and ${(p) => p.theme.device.mobile_SamsungGalaxyTab2} {
    grid-template-columns: auto;
  }
`;
const $CardBody = styled.div<{ slideIn: boolean }>`
  position: absolute;
  width: 100%;
  height: calc(100% - ${(p) => p.theme.components.managed.portfolio.cardHeaderHeight});
  padding: 0.4rem 1rem 1rem 2rem;
  bottom: 0%;
  transition: transform 0.3s ease-in-out 0.1s;

  @media screen and ${(p) => p.theme.device.mobile} {
    height: calc(100% - ${(p) => p.theme.components.managed.portfolio.mobileCardHeaderHeight});
  }
`;

const $CardBodyActive = styled($CardBody)`
  font-size: ${(p) => p.theme.fontSize.large};
  transform: ${(p) => (p.slideIn ? 'translateX(0%)' : 'translateX(-100%)')};
`;
const $CardBodyInactive = styled($CardBody)`
  transform: ${(p) => (!p.slideIn ? 'translateX(0%)' : 'translateX(100%)')};
`;

const $CardSwitch = styled($InfoSmallButton)`
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  z-index: 10;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  background: #2c8ec8;
`;

const $CardSwitchLeft = styled($CardSwitch)`
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.2);
  ${$Arrow} {
    transform: rotate(0);
  }
  left: 0px;
`;

const $CardSwitchRight = styled($CardSwitch)`
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.2);
  ${$Arrow} {
    transform: rotate(180deg);
  }
  right: 0px;
`;

const $Card = styled.div`
  position: relative;
  background: ${(p) => p.theme.components.managed.cardBgActive};
  max-width: 30rem;
  box-shadow: ${(p) => p.theme.components.managed.cardBoxShadow};
  overflow: hidden;
  border-radius: 3px;
`;

const $CryptoBalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const $CryptoBalanceAmount = styled.span`
  font-size: 1.6rem;
  font-weight: bold;
`;

const $FiatBalanceWrapper = styled.div`
  display: flex;
  font-size: 1rem;
`;

const $CoinLink = styled($Link)`
  position: absolute;
  bottom: 2px;
  right: 15px;
`;

const $VerticalLabelWrapper = styled.div`
  position: absolute;
  width: 110px;
  transform: rotate(-90deg);
  top: 111px;
  left: -25px;
  text-align: center;
  color: #5b739d;
  font-size: 1.6rem;
  font-weight: bold;
`;

const $VerticalLabelText = styled.span`
  text-transform: uppercase;
`;

const $StatsWrapper = styled.div`
  display: flex;
  min-width: 70px;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 20px;
`;

const $SelectorLabel = styled.label`
  margin-right: 0.8rem;
  font-weight: bold;
  display: inline;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
`;

const $FeeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  opacity: 68%;
`;

const $FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: end;

  > div {
    height: 35px;
    margin-bottom: 10px;
    margin-right: 10px;
  }
`;

const $TotalBalance = styled.div`
  font-size: 3rem;
  text-transform: lowercase;

  @media screen and ${(p) => p.theme.device.mobile} {
    font-size: 2rem;
  }
`;

const $CoinBalance = styled.div`
  font-size: 2.5rem;

  @media screen and ${(p) => p.theme.device.mobile} {
    font-size: 2rem;
  }
`;

const $TimeRangeWrapper = styled.div`
  display: flex;
  margin-left: 20px;

  @media screen and ${(p) => p.theme.device.mobile} {
    margin-left: 0;
  }
`;

const $ChartWrapper = styled.div`
  position: relative;
  margin-top: 5px;
  width: 70%;
  height: 100%;
`;

interface IPortfolioProps extends RouteComponentProps, ConnectedProps<typeof connector> {}

type IRange = '24h' | '7d' | '6m';

interface IPortfolioState {
  fiatCurrency: IManagedFiatCurrency;
  range: IRange;
}

const RANGE_TO_INTERVAL: { [key in IRange]: IManagedGraphInterval } = {
  '24h': 'hour',
  '7d': 'day',
  '6m': 'month',
};

// Rate can be minuscule, format for presentation
const formatRate = (qty, currency) => {
  if (!qty) {
    return;
  }

  if (qty > 1) {
    return Quantity(qty).truncateForInstrument(currency).toNumber();
  }

  const decimals = qty.toString().split('.')[1];
  if (decimals && decimals.length > 6) {
    return qty.toFixed(6);
  }

  return qty;
};

const COIN_HOMEPAGE_LOOKUP = {
  BTC: 'bitcoin.org',
  ETH: 'ethereum.org',
  SFX: 'safex.org',
  SFT: 'safex.org',
};

const COIN_RATE_DECIMALS = {
  BTC: 0,
  ETH: 0,
  SFX: 4,
  SFT: 4,
};

interface IButtonLinkProps {
  route: ICustomLocationDescriptor;
  color: string;
  tooltip: string;
  text: ITranslations;
}

function ButtonLink({ route, color, tooltip, text }: IButtonLinkProps) {
  const wrapTooltip = (btnEl) => (
    <GenericTooltip wrapperProps={{ disabled: true }} placement="bottom" overlay={tooltip}>
      {btnEl}
    </GenericTooltip>
  );

  const BTN = color === 'green' ? $ManagedGreenButton : $ManagedRedButton;

  return (
    <I18n>
      {(t) => {
        return tooltip ? (
          wrapTooltip(
            <BTN disabled={true}>
              <$Link as={Link} to={route}>
                {t(text)}
              </$Link>
            </BTN>
          )
        ) : (
          <$Link as={Link} to={route}>
            <BTN>{t(text)}</BTN>
          </$Link>
        );
      }}
    </I18n>
  );
}

function NotAvailable() {
  return (
    <I18n>
      {(t) => <span title={t('managed.notAvailable')}>{t('managed.notAvailableAbbreviated')}</span>}
    </I18n>
  );
}

const dataPointLabels: { [key in IRange]: { [key: number]: ITranslations } } = {
  '24h': {
    0: 'managed.timeRangePointLabels.24h',
    4: null,
    8: 'managed.timeRangePointLabels.16h',
    12: null,
    16: 'managed.timeRangePointLabels.8h',
    20: null,
    23: 'managed.timeRangePointLabels.now',
  },
  '7d': {
    0: 'managed.timeRangePointLabels.6d',
    1: null,
    2: 'managed.timeRangePointLabels.4d',
    3: null,
    4: 'managed.timeRangePointLabels.2d',
    5: null,
    6: 'managed.timeRangePointLabels.now',
  },
  '6m': {
    0: 'managed.timeRangePointLabels.5m',
    1: null,
    2: 'managed.timeRangePointLabels.3m',
    3: null,
    4: 'managed.timeRangePointLabels.1m',
    5: 'managed.timeRangePointLabels.now',
  },
};

interface IPortfolioCard {
  instrument: IMarketInstrument;
  coin: any;
  disabled: boolean;
  disabledMessage: ITranslations;
  fiatCurrency: IManagedFiatCurrency;
  balance: IBalance;
  rate: number;
  range: string;
  chartData: IApiGraphDataForInstrument;
  fees: number[];
}

function PortfolioCard({
  instrument,
  coin,
  chartData,
  fiatCurrency,
  disabled,
  disabledMessage,
  balance,
  rate,
  range,
  fees,
}: IPortfolioCard) {
  const hasAvailableBalance = balance.available > 0;
  const [showStats, toggleView] = React.useState(hasAvailableBalance);
  const coinLink = COIN_HOMEPAGE_LOOKUP[coin.symbol];

  const availableBalanceFixed = formatNumberToFixed(balance.available, balance.digits);
  const mulWithBalanceAndFormat = (val) =>
    Quantity(val).multiply(availableBalanceFixed).truncateForInstrument(fiatCurrency).toNumber();

  const balanceInFiat = rate && mulWithBalanceAndFormat(rate);
  const isBalanceInFiatPositive = Number.isFinite(balanceInFiat) && balanceInFiat >= 0;

  return (
    <I18n>
      {(t) => {
        // ------ Chart data -----
        // -----------------------
        let high = null;
        let low = null;
        let balanceInFiatForFirstTimeUnit = null;
        let points = [];
        if (chartData) {
          const highByBalance = mulWithBalanceAndFormat(chartData.high);
          const lowByBalance = mulWithBalanceAndFormat(chartData.low);

          high =
            isBalanceInFiatPositive && balanceInFiat < highByBalance
              ? highByBalance
              : balanceInFiat;

          low =
            isBalanceInFiatPositive && balanceInFiat > lowByBalance ? lowByBalance : balanceInFiat;

          if (chartData.points && chartData.points.length) {
            const labels = dataPointLabels[range];
            // coerce point values to chart api points
            points = chartData.points
              .filter((rate, i) => Object.keys(labels).includes(String(i)))
              .map((rate, i) => ({
                value: mulWithBalanceAndFormat(rate),
                label: t(Object.values(labels)[i] as ITranslations),
              }));

            balanceInFiatForFirstTimeUnit = points[0].value;

            // set last chart point to current balance value
            points[points.length - 1] = {
              value: balanceInFiat,
              label: t('managed.timeRangePointLabels.now'),
            };
          }
        }

        const change =
          (isBalanceInFiatPositive &&
            Number.isFinite(balanceInFiatForFirstTimeUnit) &&
            ((balanceInFiat - balanceInFiatForFirstTimeUnit) / balanceInFiatForFirstTimeUnit) *
              100) ||
          0;

        // ------------------------

        return (
          <$Card key={instrument}>
            <$CardHeader>
              <$CardHeaderWave>
                <WaveIcon />
              </$CardHeaderWave>
              <$CardHeaderLeft>
                <InstrumentIcon
                  size={50}
                  name={instrument}
                  type="color"
                  style={{ verticalAlign: 'middle' }}
                />
                <div>
                  <h2>{instrument}</h2>
                  <h3>{coin.name}</h3>
                </div>
              </$CardHeaderLeft>
              <$CardHeaderRight>
                <ButtonLink
                  route={R.MANAGED.to(
                    {
                      page: 'sell-orders',
                    },
                    { instrument }
                  )}
                  color={'red'}
                  tooltip={t(disabledMessage)}
                  text={'sell'}
                />
                <ButtonLink
                  route={R.MANAGED.to(
                    {
                      page: 'buy-orders',
                    },
                    { instrument }
                  )}
                  color={'green'}
                  tooltip={t(disabledMessage)}
                  text={'buy'}
                />
              </$CardHeaderRight>
            </$CardHeader>
            <$CardBodyActive slideIn={showStats}>
              <$VerticalLabelWrapper>
                <$VerticalLabelText>{t('managed.last')}</$VerticalLabelText>
                <span> {t(`managed.timeRangeOptions.${range}` as ITranslations)}</span>
              </$VerticalLabelWrapper>
              <$CardSwitchRight onClick={() => toggleView(!showStats)}>
                {t('managed.info')}&nbsp;
                <$Arrow />
              </$CardSwitchRight>
              <$TopCardBodyInfo>
                <$CustomizableInfoItem>
                  <$InfoLabel>{fiatCurrency}</$InfoLabel>
                  <$CoinBalance>
                    {isBalanceInFiatPositive ? (
                      <BinaryPrefixDecimals int value={balanceInFiat} />
                    ) : (
                      <NotAvailable />
                    )}
                  </$CoinBalance>
                </$CustomizableInfoItem>
                <$CustomizableInfoItem>
                  <$CryptoBalanceWrapper>
                    <$InfoLabel>{coin.symbol}</$InfoLabel>
                    <$CryptoBalanceAmount>
                      {formatNumberToFixed(balance.available, balance.digits)}
                    </$CryptoBalanceAmount>
                  </$CryptoBalanceWrapper>
                  <$FiatBalanceWrapper>
                    <$InfoLabel>{t('price')}:</$InfoLabel>
                    <span>
                      {rate ? (
                        <BinaryPrefixDecimals
                          int
                          value={rate}
                          currency={fiatCurrency}
                          currencyDisplay="code"
                        />
                      ) : (
                        <NotAvailable />
                      )}
                    </span>
                  </$FiatBalanceWrapper>
                </$CustomizableInfoItem>
              </$TopCardBodyInfo>
              <$ActiveCardItemsWrapper>
                <$StatsWrapper>
                  <$CardBodyInfoItem changeValue={high ? 'high' : null}>
                    <$InfoLabel>{t('managed.high')}</$InfoLabel>
                    {high ? <BinaryPrefixDecimals int value={high} /> : <NotAvailable />}
                  </$CardBodyInfoItem>
                  <$CardBodyInfoItem>
                    <$InfoLabel>{t('managed.low')}</$InfoLabel>
                    {low ? <BinaryPrefixDecimals int value={low} /> : <NotAvailable />}
                  </$CardBodyInfoItem>
                  <$CardBodyInfoItem isPositive={change > 0} isNegative={change < 0}>
                    <$InfoLabel>{t('managed.change')} %</$InfoLabel>
                    <span>
                      <$ColorText color={change > 0 ? 'buy' : 'sell'}>
                        {change > 0 ? '+' : ''}
                      </$ColorText>
                      <PrettyDecimals
                        value={formatNumberToFixed(change, 2)}
                        color={(change > 0 && 'buy') || (change < 0 && 'sell') || null}
                      />
                    </span>
                  </$CardBodyInfoItem>
                </$StatsWrapper>
                <$ChartWrapper>
                  <BarChart
                    points={points.map((p) => p.value)}
                    labels={points.map((p) => p.label)}
                  />
                </$ChartWrapper>
              </$ActiveCardItemsWrapper>
            </$CardBodyActive>
            <$CardBodyInactive slideIn={showStats}>
              {hasAvailableBalance && (
                <$CardSwitchLeft onClick={() => toggleView(!showStats)}>
                  <$Arrow />
                  &nbsp;{t('managed.balance')}
                </$CardSwitchLeft>
              )}
              <$CardBodyHistory>
                {t(`managed.coins.about.${coin.symbol}` as ITranslations)}
              </$CardBodyHistory>
              <div>
                <$InactiveCardItemsWrapper>
                  <div>
                    <$CustomizableInfoItem>
                      <$InfoLabel>
                        {t('managed.marketCap')} {fiatCurrency}
                      </$InfoLabel>
                      <$CoinBalance>
                        <BinaryPrefixDecimals
                          value={coin.quote[fiatCurrency].market_cap}
                          decimals={ALL_INSTRUMENTS[coin.symbol].digits}
                        />
                      </$CoinBalance>
                    </$CustomizableInfoItem>
                    <$FeeWrapper>
                      <span>
                        {t('managed.coins.buyFee')}: {fees[0]}%
                      </span>
                      <span>
                        {t('managed.coins.sellFee')}: {fees[1]}%
                      </span>
                    </$FeeWrapper>
                  </div>
                  <div>
                    <$CardBodyInfoItem>
                      <$InfoLabel>
                        {t('managed.coins.circulatingSupply')} {coin.symbol}
                      </$InfoLabel>
                      <BinaryPrefixDecimals
                        size="medium"
                        bold
                        value={coin.circulating_supply}
                        decimals={ALL_INSTRUMENTS[coin.symbol].digits}
                      />
                    </$CardBodyInfoItem>
                    <$CardBodyInfoItem style={{ marginTop: '5px' }}>
                      <$InfoLabel>{t('price')}</$InfoLabel>
                      {rate ? (
                        <BinaryPrefixDecimals
                          value={rate}
                          currency={fiatCurrency}
                          currencyDisplay="code"
                          decimals={COIN_RATE_DECIMALS[instrument]}
                        />
                      ) : (
                        <NotAvailable />
                      )}
                    </$CardBodyInfoItem>
                  </div>
                </$InactiveCardItemsWrapper>
                <$InactiveCardBottomInfo>
                  {coinLink && (
                    <$CoinLink target="_blank" href={`https://${coinLink}`}>
                      {coinLink}
                    </$CoinLink>
                  )}
                </$InactiveCardBottomInfo>
              </div>
            </$CardBodyInactive>
          </$Card>
        );
      }}
    </I18n>
  );
}

class Portfolio extends React.PureComponent<IPortfolioProps, IPortfolioState> {
  static contextType: any = I18nContext;
  private graphUpdateInterval;
  private graphUpdatedAt;

  constructor(props) {
    super(props);

    this.state = {
      fiatCurrency: 'EUR',
      range: '24h',
    };
  }

  updateGraphData = () => {
    const now = new Date();
    const hour = now.getHours();

    if (hour !== this.graphUpdatedAt.getHours()) {
      this.props.updateManagedGraph('hour');
    }

    const day = now.getDay();
    if (day !== this.graphUpdatedAt.getDay()) {
      this.props.updateManagedGraph('day');
    }

    const month = now.getMonth();
    if (month !== this.graphUpdatedAt.getMonth()) {
      this.props.updateManagedGraph('month');
    }

    this.graphUpdatedAt = now;
  };

  componentDidUpdate(
    prevProps: Readonly<IPortfolioProps>,
    prevState: Readonly<IPortfolioState>,
    snapshot?: any
  ) {
    const { graph } = this.props;
    if (!this.graphUpdateInterval && graph && graph.timestamp) {
      this.graphUpdateInterval = setInterval(() => this.updateGraphData(), 60 * 1000);
      this.graphUpdatedAt = new Date(graph.timestamp);
    }
  }

  componentDidMount() {
    return this.props.loadManagedCoinsMarketData().then(() => {
      return this.props.loadGraphData();
    });
  }

  componentWillUnmount() {
    if (this.graphUpdateInterval) {
      clearInterval(this.graphUpdateInterval);
    }
  }

  get filterCurrencyOptions() {
    return this.props.config.supported_fiat.reduce((res, instrument) => {
      res[instrument] = { value: instrument, label: instrument };
      return res;
    }, {});
  }

  get timeRangeSelectorOptions(): { [key in IRange]: { label: string; value: IRange } } {
    const { t }: II18nextT = this.context;
    return {
      '24h': { label: t(`managed.timeRangeOptions.24h`), value: '24h' },
      '7d': { label: t(`managed.timeRangeOptions.7d`), value: '7d' },
      '6m': { label: t(`managed.timeRangeOptions.6m`), value: '6m' },
    };
  }

  get balances() {
    const { supported_crypto, supported_fiat } = this.props.config;
    return supported_crypto.reduce((res, coin) => {
      const balance = this.props.balances[coin].available;
      supported_fiat.forEach((i) => {
        const convertedBalance = Quantity(
          balance * getConversionRate(this.props.rates, coin as IInstrument, i as IInstrument)
        )
          .truncateForInstrument(i)
          .toNumber();
        if (!res[i]) {
          res[i] = { total: 0 };
        }

        res[i][coin] = convertedBalance;
        res[i].total += convertedBalance;
      });
      return res;
    }, {});
  }

  render() {
    const { t }: II18nextT = this.context;
    const { config, marketData, balances, filterFiatOptions, graph } = this.props;

    return (
      <$Wrapper>
        <$HeaderWrapperJustified portfolio={true}>
          <Header
            title={t('managed.pages.portfolio')}
            icon={MonitorIcon}
            loadingKeys={['getGraphData']}
            hideOnMobile={true}
          />
        </$HeaderWrapperJustified>

        <$InnerWrapper>
          {!config.supported_crypto.length || !marketData || !graph ? null : (
            <>
              <$BalanceCard>
                <div>
                  <h2>{t('managed.totalValue')}</h2>
                  <$TotalBalance>
                    {this.balances[this.state.fiatCurrency].total >= 0 ? (
                      <>
                        <BinaryPrefixDecimals
                          int
                          bold
                          value={this.balances[this.state.fiatCurrency].total}
                        />
                      </>
                    ) : (
                      <NotAvailable />
                    )}
                  </$TotalBalance>
                </div>
                <$FiltersWrapper>
                  {this.filterCurrencyOptions && (
                    <div style={{ display: 'flex' }}>
                      <$SelectorLabel>{t('currency')}:</$SelectorLabel>
                      <SimplexSelect
                        borderless={false}
                        variant="transparent"
                        options={Object.values(this.filterCurrencyOptions)}
                        value={this.filterCurrencyOptions[this.state.fiatCurrency]}
                        onChange={({ value }) => {
                          this.setState({ fiatCurrency: value });
                        }}
                        for="currency"
                      />
                    </div>
                  )}
                  <$TimeRangeWrapper>
                    <$SelectorLabel>{t('managed.timeRangeLabel')}:</$SelectorLabel>
                    <SimpleSelect
                      width="6rem"
                      onChange={({ value }) => {
                        this.setState({ range: value });
                      }}
                      value={this.timeRangeSelectorOptions[this.state.range] as any}
                      variant="transparent"
                      options={Object.values(this.timeRangeSelectorOptions)}
                    />
                  </$TimeRangeWrapper>
                </$FiltersWrapper>
              </$BalanceCard>
              <$CardsWrapper>
                {config.supported_crypto.map((instrument) => {
                  const coin = this.props.marketData[instrument];

                  let disabled = false;
                  let disabledMessage = null;
                  if (!config.registered) {
                    disabled = true;
                  } else if (!!config.disable_instruments[instrument]) {
                    disabled = true;
                    if (typeof config.disable_instruments[instrument] === 'string') {
                      disabledMessage = t(
                        `backend:messages.${config.disable_instruments[instrument]}` as ITranslations,
                        config.disable_instruments[instrument] as any
                      );
                    }
                  }

                  const interval = RANGE_TO_INTERVAL[this.state.range];
                  const chartData = this.props.graph[coin.symbol].find(
                    (rec) => rec.currency === this.state.fiatCurrency && rec.interval === interval
                  );
                  const balance = this.props.balances[coin.symbol];

                  const { fiatCurrency, range } = this.state;
                  const rate = getConversionRate(
                    this.props.rates,
                    coin.symbol,
                    fiatCurrency as IInstrument
                  );

                  return (
                    <PortfolioCard
                      key={instrument}
                      instrument={instrument as IMarketInstrument}
                      fiatCurrency={fiatCurrency}
                      range={range}
                      coin={coin}
                      chartData={chartData}
                      disabled={disabled}
                      disabledMessage={disabledMessage}
                      balance={balance}
                      rate={rate}
                      fees={[config.buy_order_fee_pct, config.sell_order_fee_pct]}
                    />
                  );
                })}
              </$CardsWrapper>
            </>
          )}
        </$InnerWrapper>
      </$Wrapper>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    config: getManagedConfig(state as IState),
    marketData: getManagedCoinsMarketData(state as IState),
    rates: getRates(state as IState),
    filterFiatOptions: getManagedFiatFilterOptions(state as IState),
    balances: getBalances(state as IState),
    graph: getManagedGraph(state as IState),
    theme: state.app.theme,
  }),
  {
    loadManagedCoinsMarketData,
    loadGraphData,
    updateManagedGraph,
  }
);

export default withRouter(connector(Portfolio));
