import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { makeDynamicLocationDescriptor, navigateToDynamicRoute } from '../../actions/routing';
import { Trans } from '../../lib/i18n';
import lodash from '../../lib/lodash';
import R from '../../lib/routes';
import { IState } from '../../lib/store';
import styled, { IThemeColorsUnion } from '../../lib/styled_components';
import { shadeColor } from '../../lib/util';
import { ArrowDownIcon, CheckIcon, MasterCard, VisaCard } from '../../media/svg_icons';
import { isUserEmailVerified, isUserLoggedIn } from '../../selectors/auth';
import { getCardPaymentConfig } from '../../selectors/card_payments';
import { ICardPaymentCryptoInstrument } from '../../types/backend_definitions';
import { CURRENCY_TO_COUNTRY_ISO } from '../../types/constants';
import { II18nextT } from '../../types/i18n';
import { ITranslations } from '../../types/translations';
import BuyWithCard, { BUY_WITH_CARD_INITIAL_VALUE } from '../components/buy_with_card/BuyWithCard';
import { $SuccessButton } from '../widgets/buttons';
import CountryFlag from '../widgets/CountryFlag';
import $Link from '../widgets/Link';
import PageSeoWrapper from '../widgets/PageSeoWrapper';

const $Text = styled.p`
  margin-bottom: 0;
  line-height: 1.4;
  font-size: ${(p) => p.theme.fontSize.large};
`;
const $HighlightedText = styled.span<{ color: IThemeColorsUnion }>`
  color: ${(p) => p.theme.colors[p.color]} !important;
`;
const $Heading = styled.h2`
  font-size: ${(p) => p.theme.fontSize.larger};
  line-height: 1.3;
  margin: 0;
  padding: 0;
`;

const $TopContent = styled.div`
  display: flex;

  @media screen and ${(p) => p.theme.device.tablet} {
    flex-direction: column;
  }
`;
const $Hero = styled.div`
  margin-right: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  margin-bottom: 2rem;

  @media screen and ${(p) => p.theme.device.tablet} {
    margin-right: 0;
  }
`;

const $BuyWithCardPageTitle = styled.div`
  font-size: 2.5rem;
  color: #fff;
  padding: 3rem 0;

  @media (max-width: ${(p) => p.theme.layout.showTooltipIconMenu}) {
    display: none;
  }
  @media screen and ${(p) => p.theme.device.tablet} {
    padding: 0 0 2rem 0;
  }
  @media screen and ${(p) => p.theme.device.mobile} {
    font-size: 1.75rem;
  }
`;
const $BuyWithCardPageMobileTitle = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin: 0;
  padding: 3rem 0;
  display: none;
  @media (max-width: ${(p) => p.theme.layout.showTooltipIconMenu}) {
    display: block;
  }
  @media screen and ${(p) => p.theme.device.tablet} {
    padding: 0 0 2rem 0;
  }
  @media screen and ${(p) => p.theme.device.mobile} {
    font-size: 1.75rem;
  }
`;

const $CheckIconWrapper = styled.span`
  margin-right: 1rem;
  svg {
    width: 2rem;
    height: 2rem;
    polygon {
      fill: ${(p) => p.theme.colors.buy};
    }
  }

  @media screen and ${(p) => p.theme.device.tablet} {
    margin-right: 0.5rem;
    svg {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
`;
const $BenefitsSummary = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 80%;
  padding-left: 1.5rem;
  li {
    position: relative;
    left: -3px;
    color: ${(p) => p.theme.colors.white};
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    font-size: ${(p) => p.theme.fontSize.larger};
    line-height: 1.3;
  }

  @media screen and ${(p) => p.theme.device.tablet} {
    width: 100%;
  }

  @media screen and ${(p) => p.theme.device.mobile} {
    padding-left: 0.5rem;
  }
`;

const $SupportedCards = styled.div`
  text-align: center;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-color: rgba(255, 255, 255, 0.1);
  margin: 3rem 0;

  svg {
    width: 10rem;
    height: 10rem;
    margin-left: 5rem;
    &:first-child {
      margin-left: 0;
    }
  }

  @media screen and ${(p) => p.theme.device.mobile} {
    margin: 2rem 0.5rem;
    display: flex;
    justify-content: center;

    svg {
      width: 8rem;
      height: 8rem;
    }
  }
`;
const $SectionDescription = styled.ul`
  grid-column: 1/3;
  grid-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  list-style: none;
  margin: 0;
  padding-left: 1.5rem;
  li {
    width: 80%;
    ${$Heading} {
      color: #fff;
    }
    ${$Text} {
      color: ${(p) => p.theme.colors.grayLighter};
    }
  }

  @media screen and ${(p) => p.theme.device.mobile} {
    margin-top: 2rem;
    padding-left: 0.5rem;
    display: block;
    li {
      width: 100%;
      ${$Heading} {
        margin-top: 2rem;
      }
    }
  }
`;

const $AuthRegisterButton = styled($SuccessButton)`
  height: 3rem;
  font-size: ${(p) => p.theme.fontSize.large};
  width: 97%;
  margin: 1.15rem 0 1rem 0;

  @media screen and ${(p) => p.theme.device.mobile} {
    font-size: ${(p) => p.theme.fontSize.base};
  }
`;

const $CheckOutListText = styled($Text)`
  color: #fff;
  padding-left: 1.5rem;

  @media screen and ${(p) => p.theme.device.mobile} {
    padding-left: 0.5rem;
  }

  > span {
    color: ${(p) => p.theme.colors.info};
    cursor: pointer;
    &:hover {
      color: ${(p) => shadeColor(p.theme.base.color, -0.15)};
    }
  }
`;

const $CurrencyItem = styled.li`
  color: ${(p) => p.theme.colors.grayLightest};
  font-size: ${(p) => p.theme.fontSize.large};
  line-height: 1.4;
  span {
    margin-right: 0.5rem;
  }
`;

const $NationalCurrenciesCardHeader = styled($Heading)<{ listExpanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  cursor: pointer;

  &:hover {
    background: ${(p) => p.theme.widgets.table.backgroundHover};
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    transition: all 0.4s ease-in-out;
    transform: rotate(${(p) => (p.listExpanded ? '180deg' : '0deg')});
  }

  @media screen and ${(p) => p.theme.device.mobile} {
    padding: 1rem;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
`;

const $NationalCurrenciesList = styled.ul`
  background: ${(p) => p.theme.components.cardPayment.bgCurrencyList};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2.5rem;
  list-style: none;
  margin: 0;
  padding: 1rem 2rem;

  > ul {
    grid-row-gap: 1rem;
    list-style: none;
    display: grid;
    grid-template-rows: max-content;
    padding: 0;
  }

  @media screen and ${(p) => p.theme.device.mobile} {
    grid-template-columns: repeat(2, 1fr);
    padding: 1.25rem 1rem;
  }
  @media screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const $NationalCurrenciesCard = styled.div<{ cardHeight: number; scrolled: boolean }>`
  margin-top: 3rem;
  overflow: hidden;
  box-shadow: ${(p) => p.theme.layout.overlayBoxShadow};
  background: ${(p) => p.theme.components.cardPayment.background};
  border-radius: 6px;
  height: ${(p) => p.cardHeight}px;
  transition: ${(p) => (p.scrolled ? 'none' : 'all 0.4s ease-in-out')};
  padding: 0;
  @media screen and ${(p) => p.theme.device.mobile} {
    margin-top: 2rem;
  }
`;

const $ColorizedText = styled($Text)<{ color: IThemeColorsUnion }>`
  color: ${(p) => p.theme.colors[p.color]} !important;
`;

const $AuthCard = styled.div`
  text-align: center;
  max-width: 280px;
  box-shadow: ${(p) => p.theme.layout.overlayBoxShadow};
  background: ${(p) => p.theme.components.cardPayment.background};
  border-radius: 6px;
  padding: 2rem 1.5rem;

  place-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${$Heading} {
    text-align: center;
    font-weight: bold;
    padding-bottom: 0.5rem;
  }

  ${$Text}, ${$ColorizedText} {
    color: ${(p) => p.theme.colors.grayLighter};
  }

  @media screen and ${(p) => p.theme.device.mobile} {
    padding: 1rem;
    margin: 0 0.5rem;
  }
`;

const $AuthLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  > div {
    span {
      color: ${(p) => p.theme.colors.grayLighter};
    }

    a {
      margin-left: 5px;
    }
  }
`;

const $Wrapper = styled.div`
  max-width: 90rem;
  margin: 3rem auto;
  padding: 0 2.5rem;

  @media screen and ${(p) => p.theme.device.tablet} {
    padding: 0;
    margin: 2.5rem;
  }
  @media screen and ${(p) => p.theme.device.mobile} {
    margin: 2rem 1rem;
  }
`;

interface IBuyWithCardPageProps extends ConnectedProps<typeof connector> {}
interface IBuyWithCardPageState {
  listExpanded: boolean;
  cardHeight: number;
  scrolled: boolean;
}

class BuyWithCardPage extends React.PureComponent<IBuyWithCardPageProps, IBuyWithCardPageState> {
  static contextType: any = I18nContext;
  private nationalCurrenciesCardRef: React.RefObject<HTMLDivElement> = React.createRef();
  private nationalCurrenciesCardHeaderRef: React.RefObject<HTMLHeadingElement> = React.createRef();
  private nationalCurrenciesCardListRef: React.RefObject<HTMLUListElement> = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      listExpanded: false,
      cardHeight: null,
      scrolled: false,
    };
  }

  componentDidMount() {
    this.setState({ cardHeight: this.nationalCurrenciesCardHeaderRef.current.clientHeight });
  }

  scrollToList() {
    this.setState({ scrolled: true }, () => {
      window.scrollTo({
        top:
          window.outerHeight + this.nationalCurrenciesCardRef.current.getBoundingClientRect().top,
        behavior: 'smooth',
      });
    });
  }

  renderCardContent() {
    const {
      userConfig: { disable_instruments, disable_service, disable_service_reason, loaded },
      instrumentConfig: { supported_crypto },
    } = this.props.cardPaymentConfig;

    const disabledInstruments = Object.keys(disable_instruments);
    const instrumentsDisabled =
      disabledInstruments.length === supported_crypto.length &&
      disabledInstruments.every((instrument) => !!disable_instruments[instrument]);

    const enabledInstrument = supported_crypto.filter(
      (supportedIns) =>
        supportedIns !== disabledInstruments.find((disabledIns) => disabledIns === supportedIns)
    )[0];

    const serviceUnavailable = loaded && (instrumentsDisabled || disable_service);
    const { t }: II18nextT = this.context;
    // USER IS NOT LOGGED IN
    if (!this.props.loggedIn) {
      return (
        <$AuthCard>
          <$Heading>{t('buyWithCardPage.getCryptoText')}</$Heading>
          <$Text>{t('buyWithCardPage.enableCryptoPurchase')}</$Text>
          <$AuthLinkWrapper>
            <$AuthRegisterButton onClick={() => this.props.navigateToDynamicRoute(R.REGISTER)}>
              {t('buyWithCardPage.register')}
            </$AuthRegisterButton>
            <div>
              <span>{t('buyWithCardPage.alreadyHaveAccount')}</span>
              <$Link
                color={'info'}
                as={Link}
                to={this.props.makeDynamicLocationDescriptor(R.LOGIN, this.props.activeStaticRoute)}
              >
                {t('buyWithCardPage.login')}
              </$Link>
            </div>
          </$AuthLinkWrapper>
        </$AuthCard>
      );
    }
    // USER IS LOGGED IN BUT DIDN'T VERIFY EMAIL
    if (this.props.loggedIn && !this.props.emailVerified) {
      return (
        <$AuthCard>
          <$Heading>{t('buyWithCardPage.getCryptoText')}</$Heading>
          <$ColorizedText color="buy"> {t('buyWithCardPage.verifyEmailPraise')}</$ColorizedText>
          <$Text>{t('buyWithCardPage.verifyEmailCompletion')}</$Text>
        </$AuthCard>
      );
    }

    // SERVICE DISABLED
    if (serviceUnavailable) {
      // If instruments are disabled without specific reason
      const areInstrumentsDisabledWithoutReason = disabledInstruments.every(
        (instrument) => typeof disable_instruments[instrument] === 'boolean'
      );
      const firstDisabledInstrumentMessage = disable_instruments[disabledInstruments[0]];
      const areInstrumentsDisabledWithSameMessages = disabledInstruments.every(
        (instrument) => disable_instruments[instrument] === firstDisabledInstrumentMessage
      );

      return (
        <$AuthCard>
          <$Heading>{t('buyWithCardPage.serviceUnavailableHeading')}</$Heading>
          <$Text>
            {/* 1.Service unavailable because instruments are disabled */}
            {instrumentsDisabled ? (
              <>
                {/* Instruments disabled without reason */}
                {(areInstrumentsDisabledWithoutReason && (
                  <$HighlightedText color={'sell'}>
                    {t('buyWithCardPage.serviceUnavailableDueToDisabledDepositsGenericMessage')}
                  </$HighlightedText>
                )) ||
                  // Instruments disabled for different reasons
                  (!areInstrumentsDisabledWithSameMessages && (
                    <$HighlightedText color={'sell'}>
                      {t('buyWithCardPage.serviceUnavailableDueToDisabledDepositsGenericMessage')}
                    </$HighlightedText>
                  ))}
                {/* Instruments disabled for same reason */}
                {!areInstrumentsDisabledWithoutReason && areInstrumentsDisabledWithSameMessages && (
                  <>
                    {t('buyWithCardPage.serviceUnavailableFollowedByReason')}
                    <br />
                    <br />
                    <$HighlightedText color={'sell'}>
                      {t([
                        `backend:messages.${firstDisabledInstrumentMessage}` as ITranslations,
                        `${firstDisabledInstrumentMessage}` as ITranslations,
                      ])}
                    </$HighlightedText>
                  </>
                )}
              </>
            ) : (
              // 2.Service unavailable because disable_service reason
              <>
                {t('buyWithCardPage.serviceUnavailableFollowedByReason')}
                <br />
                <br />
                <$HighlightedText color={'sell'}>
                  {t([
                    `backend:messages.${disable_service_reason}` as ITranslations,
                    'buyWithCardPage.serviceUnavailableFallbackReason',
                  ])}
                </$HighlightedText>
              </>
            )}
          </$Text>

          {/* Contact support message */}
          <$Text>
            <Trans
              i18nKey={'buyWithCardPage.contactSupportCTA'}
              components={[
                <$Link color={'info'} target="_blank" key={0} href={this.props.supportLink}>
                  ___
                </$Link>,
              ]}
            />
          </$Text>
        </$AuthCard>
      );
    }

    if (!loaded) {
      return null;
    }
    return <BuyWithCard cryptoInstrument={enabledInstrument as ICardPaymentCryptoInstrument} />;
  }

  getFullCardHeight() {
    return (
      this.nationalCurrenciesCardHeaderRef.current.clientHeight +
      this.nationalCurrenciesCardListRef.current.clientHeight
    );
  }

  toggleListExpansion = () => {
    const listExpanded = this.state.scrolled ? false : !this.state.listExpanded;
    const cardHeight = listExpanded
      ? this.getFullCardHeight()
      : this.nationalCurrenciesCardHeaderRef.current.clientHeight;
    this.setState({ listExpanded, cardHeight, scrolled: false });
  };

  render() {
    const { t }: II18nextT = this.context;
    if (!this.props.cardPaymentConfig.instrumentConfig.supported_crypto) {
      return null;
    }

    return (
      <PageSeoWrapper
        title={t('seo.title.buyWithCard')}
        description={t('seo.description.buyWithCard')}
      >
        <$Wrapper>
          <$TopContent>
            <$Hero>
              <$BuyWithCardPageTitle>{t('buyWithCardPage.title')}</$BuyWithCardPageTitle>
              <$BuyWithCardPageMobileTitle>
                {t('buyWithCardPage.title')}
              </$BuyWithCardPageMobileTitle>
              <$BenefitsSummary>
                <li>
                  <$CheckIconWrapper>
                    <CheckIcon />
                  </$CheckIconWrapper>
                  {t('buyWithCardPage.easyPurchase', {
                    price: `${BUY_WITH_CARD_INITIAL_VALUE.fiatAmount} ${BUY_WITH_CARD_INITIAL_VALUE.fiatInstrument}`,
                  })}
                </li>
                <li>
                  <$CheckIconWrapper>
                    <CheckIcon />
                  </$CheckIconWrapper>
                  {t('buyWithCardPage.effortlessJoining')}
                </li>
                <li>
                  <$CheckIconWrapper>
                    <CheckIcon />
                  </$CheckIconWrapper>
                  {t('buyWithCardPage.highCapacity')}
                </li>
                <li>
                  <$CheckIconWrapper>
                    <CheckIcon />
                  </$CheckIconWrapper>
                  {t('buyWithCardPage.extensiveCoverage')}
                </li>
              </$BenefitsSummary>
              <$CheckOutListText>
                {t('buyWithCardPage.checkOutList')}{' '}
                <span onClick={() => this.scrollToList()}>
                  {t('buyWithCardPage.availableNationalCurrencies')}
                </span>
                .
              </$CheckOutListText>
            </$Hero>
            {this.renderCardContent()}
          </$TopContent>
          <$SupportedCards>
            <VisaCard />
            <MasterCard />
          </$SupportedCards>
          <$SectionDescription>
            <li>
              <$Heading>{t('buyWithCardPage.pagePurposeHeader')}</$Heading>
              <$Text>{t('buyWithCardPage.pagePurpose')}</$Text>
            </li>
            <li>
              <$Heading>{t('buyWithCardPage.yourProfileTextHeader')}</$Heading>
              <$Text>{t('buyWithCardPage.registrationRequiredText')}</$Text>
            </li>
            <li>
              <$Heading>{t('buyWithCardPage.specialPurchasesHeader')}</$Heading>
              <$Text>
                {t('buyWithCardPage.specialPurchases', {
                  price: `${BUY_WITH_CARD_INITIAL_VALUE.fiatAmount} ${BUY_WITH_CARD_INITIAL_VALUE.fiatInstrument}`,
                })}
              </$Text>
            </li>
          </$SectionDescription>
          <$NationalCurrenciesCard
            cardHeight={this.state.scrolled ? this.getFullCardHeight() : this.state.cardHeight}
            scrolled={this.state.scrolled}
            ref={this.nationalCurrenciesCardRef}
          >
            <$NationalCurrenciesCardHeader
              listExpanded={this.state.scrolled ? true : this.state.listExpanded}
              ref={this.nationalCurrenciesCardHeaderRef}
              onClick={() => this.toggleListExpansion()}
            >
              {t('buyWithCardPage.availableNationalCurrencies')}
              <ArrowDownIcon />
            </$NationalCurrenciesCardHeader>
            <$NationalCurrenciesList ref={this.nationalCurrenciesCardListRef}>
              {lodash
                .chunk(this.props.cardPaymentConfig.instrumentConfig.supported_fiat, 3)
                .map((chunk, key) => {
                  return (
                    <ul key={key}>
                      {chunk.map((natCurr, i) => (
                        <$CurrencyItem key={i}>
                          <CountryFlag isoCode={CURRENCY_TO_COUNTRY_ISO[natCurr]} />
                          {t(`currencyName.${natCurr}` as ITranslations)} ({natCurr})
                        </$CurrencyItem>
                      ))}
                    </ul>
                  );
                })}
            </$NationalCurrenciesList>
          </$NationalCurrenciesCard>
        </$Wrapper>
      </PageSeoWrapper>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    activeStaticRoute: state.activeStaticRoute,
    cardPaymentConfig: getCardPaymentConfig(state),
    loggedIn: isUserLoggedIn(state),
    emailVerified: isUserEmailVerified(state),
    supportLink: state.env.links.support,
  }),
  {
    navigateToDynamicRoute,
    makeDynamicLocationDescriptor,
  }
);

export default connector(BuyWithCardPage);
