import Container from '../lib/container';
import { ICustomHistory, ICustomLocation, ICustomLocationDescriptor } from '../lib/history';
import { commit, val } from '../lib/redux_helpers';
import R, { IRouteId, IRouteSpec, IRouteSpecWithoutParams, appendPathname } from '../lib/routes';
import { IThunkMethod } from '../lib/store';
import { IPair } from '../types/backend_definitions';

export function routeDescriptorToLocation(
  descriptor: IRouteDescriptor,
  fallback: ICustomLocationDescriptor = null
): ICustomLocationDescriptor {
  if (!descriptor || !descriptor.routeId) {
    return fallback;
  }
  const route = R[descriptor.routeId];
  if (!route) {
    return fallback;
  }
  return route.prefixed(descriptor.prefixPath).to(descriptor.params);
}

export interface IRouteDescriptor {
  routeId: IRouteId;
  prefixPath: string;
  params: any;
  paramsDescriptor: string;
}

export interface IRoutingState {
  activeStaticRoute: IRouteDescriptor;
  activeDynamicRoute: IRouteDescriptor;
}
export const ROUTING_STATE: IRoutingState = {
  activeStaticRoute: null,
  activeDynamicRoute: null,
};

export const goToExchange = (pair?: IPair) => (_, __, { history }: Container) => {
  if (pair) {
    history.push(R.EXCHANGE.to({ pair }));
  } else {
    history.push(R.EXCHANGE_HOME);
  }
};

export const goToHome = (): IThunkMethod => (_, getState, { history, store }) => {
  const currentPair = store.getState().currentPair;

  history.push(
    R.EXCHANGE.to({
      pair: currentPair ? currentPair.path : getState().env.pairs.defaultPair,
    })
  );
};

export const goToSettings = () => (_, __, { history }: Container) => {
  history.push(R.SETTINGS_HOME);
};

export const goToLogin = () => (_, __, { history }: Container) => {
  history.push(R.LOGIN.prefixed('/'));
};

const routeToRoutingStateKey = (route: IRouteSpec): keyof IRoutingState => {
  return route.dynamic ? 'activeDynamicRoute' : 'activeStaticRoute';
};

export const setActiveRoute = (route: IRouteSpec, params: any): IThunkMethod => (
  dispatch,
  getState
) => {
  const existing = getState()[routeToRoutingStateKey(route)];
  const paramsDescriptor = params ? JSON.stringify(params) : '<none>';

  if (
    existing &&
    existing.routeId === route.id &&
    existing.prefixPath === route.prefixPath &&
    paramsDescriptor === existing.paramsDescriptor
  ) {
    // Everything is the same, nothing to do
    return;
  }

  dispatch(
    commit(
      `Set current ${route.dynamic ? 'dynamic' : 'static'} route to "${
        route.path
      }" with params ${paramsDescriptor}`,
      {
        [routeToRoutingStateKey(route)]: val({
          routeId: route.id,
          prefixPath: route.prefixPath,
          params,
          paramsDescriptor,
        }),
      }
    )
  );
};

export const clearActiveRoute = (route: IRouteSpec): IThunkMethod => (
  dispatch,
  getState,
  { logger }
) => {
  const existing = getState()[routeToRoutingStateKey(route)];
  if (!(existing && existing.routeId === route.id && existing.prefixPath === route.prefixPath)) {
    logger.error(`Tried to clear non-existent active route ${route.id} (${route.prefixPath})`);
    return;
  }

  dispatch(
    commit(`Clear current ${route.dynamic ? 'dynamic' : 'static'} route (${route.describe()})`, {
      [routeToRoutingStateKey(route)]: val(null),
    })
  );
};

export const makeDynamicLocationDescriptor = (
  toDynamicRoute: ICustomLocationDescriptor | IRouteSpecWithoutParams,
  fromStaticRoute: IRouteDescriptor
): IThunkMethod<ICustomLocationDescriptor> => (dispatch, getState, { history, logger }) => {
  const dynamicLocation: ICustomLocationDescriptor = (toDynamicRoute as IRouteSpecWithoutParams).to
    ? (toDynamicRoute as IRouteSpecWithoutParams).to()
    : (toDynamicRoute as ICustomLocationDescriptor);
  if (!R[dynamicLocation.state.id].dynamic) {
    throw new Error(`You can only call navigateToDynamicRoute with a dynamic route`);
  }

  let staticLocation;
  if (fromStaticRoute) {
    staticLocation = routeDescriptorToLocation(fromStaticRoute);
  } else {
    logger.warn(
      `Defaulting active static route to HOME when making link for: ${JSON.stringify(
        toDynamicRoute
      )}`
    );
    staticLocation = R.HOME.to();
  }

  const href: ICustomLocationDescriptor = {
    ...dynamicLocation,
    pathname: appendPathname(staticLocation.pathname, dynamicLocation.pathname),
  };

  return href;
};

/**
 * Open a dynamic route, regardless of current static vs dynamic route
 */
export const navigateToDynamicRoute = (
  to: ICustomLocationDescriptor | IRouteSpecWithoutParams,
  replace = false
): IThunkMethod => (dispatch, getState, { history, logger }) => {
  const href = dispatch(makeDynamicLocationDescriptor(to, getState().activeStaticRoute));
  if (replace) {
    history.replace(href);
  } else {
    history.push(href);
  }
};

/**
 * If there is an active dynamic route, navigate to active static route
 */
export const navigateAwayFromActiveDynamicRoute = (): IThunkMethod => (
  dispatch,
  getState,
  { history, logger }
) => {
  const { activeDynamicRoute, activeStaticRoute } = getState();
  if (!activeDynamicRoute) {
    // Nothing to do
    return;
  }

  const targetHref = routeDescriptorToLocation(activeStaticRoute, R.EXCHANGE_HOME.to());
  if (!targetHref) {
    logger.error(`Couldn't get location descriptor from current activeStaticRoute`);
    return;
  }

  history.push(targetHref);
};

export const doFindStaticRedirectRoute = (
  activeStaticRoute: IRouteDescriptor,
  history: ICustomHistory,
  testRouteFn: (route: IRouteSpec) => boolean
): ICustomLocationDescriptor => {
  // First check active static route, see if it matches what we want
  if (activeStaticRoute) {
    const route = R[activeStaticRoute.routeId];
    if (testRouteFn(route)) {
      return routeDescriptorToLocation(activeStaticRoute);
    }
  }

  // If no luck, try to find something in the history
  for (const location of history.recentLocations) {
    if (location.state) {
      const route = R[location.state.id];
      if (route && testRouteFn(route)) {
        // A satisfactory static route was found. Let's go there!
        return location;
      }
    }
  }

  // If nothing was found, go home
  return R.HOME.to();
};

export const findStaticRedirectRoute = (
  testRouteFn: (route: IRouteSpec) => boolean
): IThunkMethod<ICustomLocationDescriptor> => (_, getState, { history }) => {
  const state = getState();
  return doFindStaticRedirectRoute(state.activeStaticRoute, history, testRouteFn);
};
