import Container from '../../lib/container';
import { handleError } from '../../lib/errors';
import { intentionallyUntranslated } from '../../lib/i18n';
import Quantity from '../../lib/quantity_';
import { commit, updatePaginatedResult, val } from '../../lib/redux_helpers';
import R from '../../lib/routes';
import { IThunkMethod } from '../../lib/store';
import { getBalances } from '../../selectors/balances';
import { getManagedGraph } from '../../selectors/managed';
import { getConversionRate, getRates } from '../../selectors/rates';
import {
  getManagedBuyOrders,
  getManagedConfig,
  getManagedSellOrders,
} from '../../selectors/transactions';
import { IPaginated } from '../../types/api';
import {
  IApiCensoredManagedBuyOrder,
  IApiCensoredManagedSellOrder,
  IApiGraphData,
  IApiManagedBuyOrderRequestPayload,
  IApiManagedSellOrderRequestPayload,
  IApiManagedUserConfig,
  IInstrument,
} from '../../types/backend_definitions';
import { ITranslations } from '../../types/translations';
import { DeepReadonly } from '../../types/typescript_helpers';
import { getSimpleNotificationData, notifyInfo, notifySuccess } from '../app';
import { loadingWrapper } from '../request_active';
import { IManagedBuyOrders, IManagedSellOrders } from './transactions';

export const setManagedUserConfig = (config: IApiManagedUserConfig) =>
  commit(`Set managed user config`, {
    transactions: {
      managedUserConfig: val<IApiManagedUserConfig>(config),
    },
  });

export const getEstimatorData = () => (dispatch, _, { api }: Container) => {
  return dispatch(
    loadingWrapper(
      'getEstimatorData',
      api.getBrokerEstimatorData().catch((err) => dispatch(handleError(err)))
    )
  );
};

export interface IGraphState {
  graph: IApiGraphData;
}

export const GRAPH_STATE: IGraphState = {
  graph: {
    BTC: [],
    ETH: [],
    SFT: [],
    SFX: [],
    timestamp: null,
  },
};

export const setManagedGraphState = (
  message: string,
  data: IApiGraphData | DeepReadonly<IApiGraphData>
) =>
  commit(message, {
    graph: val(data),
  });

export const agreeToManagedTOS = () => (dispatch, _, { api, i18n, history }: Container) => {
  return api
    .putBrokerAgreeToTerm()
    .then((config) => {
      dispatch(setManagedUserConfig(config));
      if (config.registered) {
        dispatch(
          notifySuccess(
            getSimpleNotificationData(
              i18n.t(
                intentionallyUntranslated(
                  'Congratulation! Access to Xcalibra Managed has been granted!'
                )
              )
            )
          )
        );
        history.push(R.MANAGED.url({ page: 'portfolio' }));
      }
    })
    .catch((err) => dispatch(handleError(err)));
};

export const loadGraphData = () => (dispatch, _, { api }: Container) => {
  return dispatch(
    loadingWrapper(
      'getGraphData',
      api
        .getBrokerGraph()
        .then((data) => {
          dispatch(setManagedGraphState('Load managed graph data', data));
        })
        .catch((err) => dispatch(handleError(err)))
    )
  );
};

export type IManagedGraphInterval = 'hour' | 'day' | 'month';

export const updateManagedGraph = (interval: IManagedGraphInterval): IThunkMethod => (
  dispatch,
  getState
) => {
  const state = getState();
  const config = getManagedConfig(state);
  const data = getManagedGraph(state);

  config.supported_crypto.forEach((coin) => {
    config.supported_fiat.forEach((currency) => {
      const currentBalance = Quantity(
        getConversionRate(getRates(state), coin as IInstrument, currency as IInstrument) *
          getBalances(state)[coin].available
      )
        .truncateForInstrument(currency)
        .toNumber();

      const rec = data[coin].find((r) => r.interval === interval && r.currency === currency);
      if (currentBalance < rec.low) {
        rec.low = currentBalance;
      }

      if (currentBalance > rec.high) {
        rec.high = currentBalance;
      }

      rec.points.push(currentBalance);
      rec.points = rec.points.slice(1);
    });
  });

  dispatch(setManagedGraphState('Update managed graph data', data));
};

export const loadManagedCoinsMarketData = () => (dispatch, _, { api }: Container) => {
  return api
    .getBrokerMarketData()
    .then((data) => {
      dispatch(
        commit(`Get managed coins market data`, {
          transactions: {
            managedCoinsMarketData: val(data) as any,
          },
        })
      );
    })
    .catch((err) => dispatch(handleError(err)));
};

export const loadManagedUserBuyOrders = () => (dispatch, _, { api }: Container) => {
  return dispatch(
    loadingWrapper(
      'getUserManagedBuyOrders',
      api
        .getBrokerOrdersBuy({
          sort_direction: 'desc',
          sort_field: ['created_at', 'id'],
        })
        .then((orders) => {
          dispatch(
            commit(`Get managed buy orders`, {
              transactions: {
                managedBuyOrders: val(orders) as IManagedBuyOrders,
              },
            })
          );
        })
        .catch((err) => dispatch(handleError(err)))
    )
  );
};

export const updateManagedBuyOrder = (orderId, payload: IApiManagedBuyOrderRequestPayload) => (
  dispatch,
  _,
  { api }: Container
) => {
  return api
    .putBrokerOrdersBuy(orderId, payload)
    .then((config) => {
      dispatch(loadManagedUserBuyOrders());
    })
    .catch((err) => dispatch(handleError(err)));
};

export const requestManagedBuyOrder = (payload: IApiManagedBuyOrderRequestPayload) => (
  dispatch,
  _,
  { api }: Container
) => {
  return api
    .postBrokerOrdersBuy(payload)
    .then((config) => {
      dispatch(loadManagedUserBuyOrders());
    })
    .catch((err) => dispatch(handleError(err)));
};

export const cancelManagedBuyOrder = (orderId) => (dispatch, _, { api }: Container) => {
  return api
    .putBrokerOrdersBuyCancel(orderId)
    .then((config) => {
      dispatch(loadManagedUserBuyOrders());
    })
    .catch((err) => dispatch(handleError(err)));
};

export const loadManagedUserSellOrders = () => (dispatch, _, { api }: Container) => {
  return dispatch(
    loadingWrapper(
      'getUserManagedSellOrders',
      api
        .getBrokerOrdersSell({
          sort_direction: 'desc',
          sort_field: ['created_at', 'id'],
        })
        .then((orders) => {
          dispatch(
            commit(`Get managed sell orders`, {
              transactions: {
                managedSellOrders: val(orders) as IManagedSellOrders,
              },
            })
          );
        })
        .catch((err) => dispatch(handleError(err)))
    )
  );
};

export const updateManagedSellOrder = (orderId, payload: IApiManagedSellOrderRequestPayload) => (
  dispatch,
  _,
  { api }: Container
) => {
  return api
    .putBrokerOrdersSell(orderId, payload)
    .then((config) => {
      dispatch(loadManagedUserSellOrders());
    })
    .catch((err) => dispatch(handleError(err)));
};

export const requestManagedSellOrder = (payload: IApiManagedSellOrderRequestPayload) => (
  dispatch,
  _,
  { api }: Container
) => {
  return api
    .postBrokerOrdersSell(payload)
    .then((config) => {
      dispatch(loadManagedUserSellOrders());
    })
    .catch((err) => dispatch(handleError(err)));
};

export const cancelManagedSellOrder = (orderId) => (dispatch, _, { api }: Container) => {
  return api
    .putBrokerOrdersSellCancel(orderId)
    .then((config) => {
      dispatch(loadManagedUserSellOrders());
    })
    .catch((err) => dispatch(handleError(err)));
};

export const downloadReport = (orderId) => (_, __, { api, http }: Container) => {
  return api.getBrokerPrepareReport(orderId).then((download) => {
    window.location.href = http.generateUrl(api.getBrokerDownloadReportSpec(download.id));
  });
};

type IBuyOrderStatus =
  | 'waitingForPayment'
  | 'paymentReceived'
  | 'executingOrder'
  | 'fulfilled'
  | 'refunded'
  | 'cancelled'
  | 'suspended';

export const BUY_ORDER_STATUS: { [key in IBuyOrderStatus]: IBuyOrderStatus } = {
  waitingForPayment: 'waitingForPayment',
  paymentReceived: 'paymentReceived',
  executingOrder: 'executingOrder',
  fulfilled: 'fulfilled',
  refunded: 'refunded',
  cancelled: 'cancelled',
  suspended: 'suspended',
};

export const getManagedBuyOrderStatus = (order: IApiCensoredManagedBuyOrder): IBuyOrderStatus => {
  if (order.cancelled_at) {
    return BUY_ORDER_STATUS.cancelled;
  }
  if (order.completed_at) {
    if (order.failure_reason) {
      return BUY_ORDER_STATUS.refunded;
    }
    return BUY_ORDER_STATUS.fulfilled;
  }
  if (order.taken_at && !order.suspended_at) {
    return BUY_ORDER_STATUS.executingOrder;
  }
  if (order.suspended_at) {
    return BUY_ORDER_STATUS.suspended;
  }
  if (order.payment_received_at) {
    return BUY_ORDER_STATUS.paymentReceived;
  }
  return BUY_ORDER_STATUS.waitingForPayment;
};

export const notifyBuyOrderUpdate = (order: IApiCensoredManagedBuyOrder) => (
  dispatch,
  _,
  { i18n }: Container
) => {
  const status = getManagedBuyOrderStatus(order);

  dispatch(
    notifyInfo(
      getSimpleNotificationData(
        i18n.t('managed.order.buyOrderUpdateNotification', {
          amount: order.quantity,
          currency: order.currency,
          status: i18n.t(`managed.order.status.${status}` as ITranslations),
        })
      )
    )
  );
};

export const updateManagedBuyOrderInState = (order: IApiCensoredManagedBuyOrder): IThunkMethod => (
  dispatch,
  getState
) => {
  const oldOrders = getManagedBuyOrders(getState());
  const [updatedOrders] = updatePaginatedResult<IApiCensoredManagedBuyOrder>(
    oldOrders,
    order,
    'id'
  );

  dispatch(notifyBuyOrderUpdate(order));

  return dispatch(
    commit(`Managed buy order has been updated`, {
      transactions: {
        managedBuyOrders: val<IPaginated<IApiCensoredManagedBuyOrder>>(updatedOrders),
      },
    })
  );
};

type ISellOrderStatus =
  | 'pending'
  | 'executingOrder'
  | 'fulfilled'
  | 'refunded'
  | 'cancelled'
  | 'suspended'
  | 'failed';

export const SELL_ORDER_STATUS: { [key in ISellOrderStatus]: ISellOrderStatus } = {
  pending: 'pending',
  executingOrder: 'executingOrder',
  fulfilled: 'fulfilled',
  refunded: 'refunded',
  cancelled: 'cancelled',
  suspended: 'suspended',
  failed: 'failed',
};

export const getManagedSellOrderStatus = (
  order: IApiCensoredManagedSellOrder
): ISellOrderStatus => {
  if (order.cancelled_at) {
    return SELL_ORDER_STATUS.cancelled;
  }

  if (order.completed_at) {
    if (order.failure_reason) {
      return SELL_ORDER_STATUS.failed;
    }

    return SELL_ORDER_STATUS.fulfilled;
  }

  if (order.refunded_at) {
    return SELL_ORDER_STATUS.refunded;
  }

  if (order.taken_at && !order.suspended_at) {
    return SELL_ORDER_STATUS.executingOrder;
  }

  if (order.suspended_at) {
    return SELL_ORDER_STATUS.suspended;
  }

  return SELL_ORDER_STATUS.pending;
};

export const notifySellOrderUpdate = (order: IApiCensoredManagedSellOrder) => (
  dispatch,
  _,
  { i18n }: Container
) => {
  const status = getManagedSellOrderStatus(order);

  dispatch(
    notifyInfo(
      getSimpleNotificationData(
        i18n.t('managed.order.sellOrderUpdateNotification', {
          amount: order.quantity,
          coin: order.instrument,
          status,
        })
      )
    )
  );
};

export const updateManagedSellOrderInState = (
  order: IApiCensoredManagedSellOrder
): IThunkMethod => (dispatch, getState) => {
  const oldOrders = getManagedSellOrders(getState());
  const [updatedOrders] = updatePaginatedResult<IApiCensoredManagedSellOrder>(
    oldOrders,
    order,
    'id'
  );

  dispatch(notifySellOrderUpdate(order));

  return dispatch(
    commit(`Managed sell order has been updated`, {
      transactions: {
        managedSellOrders: val<IPaginated<IApiCensoredManagedSellOrder>>(updatedOrders),
      },
    })
  );
};
