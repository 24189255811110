import { priceSummary24hTransformation } from '../lib/api_response_transformations';
import Container from '../lib/container';
import { handleError } from '../lib/errors';
import lodash from '../lib/lodash';
import Quantity from '../lib/quantity';
import { commit, getNextPageCriteria, val } from '../lib/redux_helpers';
import { IState, IStateGetter, IThunkDispatch, IThunkMethod } from '../lib/store';
import { isUserLoggedIn } from '../selectors/auth';
import { getCurrentPairPath, isExchangeInitiated } from '../selectors/exchange';
import { getActiveOrdersPaginationState } from '../selectors/orders';
import { IApiPriceHistory, IApiPriceHistorySummary, IPair } from '../types/backend_definitions';
import { IInstrumentPair, pairInfo } from '../types/instruments';
import { IInstrumentsConfig, IPairsConfig } from '../types/pairs_instruments_config';
import { ITrade } from '../types/trades';
import { Omit } from '../types/typescript_helpers';
import { IBuySellWidgetStateValues } from './buy_sell';
import { updateMarketDepth } from './orderbook';
import { commitActiveOrdersForAllInstruments } from './orders/active_instruments_orders';
import { submitMarketTrades } from './orders/market_trades';
import { submitActiveUserOrdersForInstrumentPair } from './orders/orders';
import { submitUserTradesForCurrentInstrumentPair } from './orders/user_trades';
import { loadingWrapper } from './request_active';
import { goToExchange } from './routing';

export type IPriceSummary24hPair = Omit<IApiPriceHistory, 'id' | 'timestamp'>;
export type IPriceSummary24h = { [pair in IPair]?: IPriceSummary24hPair };

export interface IExchangeState {
  exchangeInitiated: boolean;
  nextPair: IPair;
  currentPair: IInstrumentPair;
  pairsConfig: IPairsConfig;
  instrumentsConfig: IInstrumentsConfig;
  priceSummary24h: IPriceSummary24h;
}

export const EXCHANGE_STATE: IExchangeState = {
  exchangeInitiated: false,
  nextPair: null,
  currentPair: null,
  pairsConfig: {},
  instrumentsConfig: null,
  priceSummary24h: null,
};

const setExchangeInitiated = () =>
  commit('Set exchange initiated', {
    exchangeInitiated: val<IState['exchangeInitiated']>(true),
  });

const setNextExchangePair = (pair: IPair) =>
  commit('Set next exchange pair', { nextPair: val<IState['nextPair']>(pair) });

const setCurrentExchangePair = (pairInfo: IInstrumentPair): IThunkMethod => (
  dispatch,
  getState
) => {
  if (pairInfo.path === getCurrentPairPath(getState())) {
    // We are already there
    return;
  }

  dispatch(
    commit(`Set exchange current pair to ${pairInfo.path}`, {
      currentPair: val<IState['currentPair']>(pairInfo),
      buySellWidget: {
        buy: val<Pick<IBuySellWidgetStateValues, 'quantity' | 'price'>>({
          quantity: 0,
          price: 0,
        }),
        sell: val<Pick<IBuySellWidgetStateValues, 'quantity' | 'price'>>({
          quantity: 0,
          price: 0,
        }),
      },
    })
  );
};

export const setPairsConfig = (pairsConfig: IPairsConfig): IThunkMethod => (dispatch) => {
  return dispatch(
    commit('Set pairs config', {
      pairsConfig: val<IState['pairsConfig']>(pairsConfig),
    })
  );
};

export const setInstrumentsConfig = (instrumentsConfig: IInstrumentsConfig): IThunkMethod => (
  dispatch
) => {
  return dispatch(
    commit('Set instruments config', {
      instrumentsConfig: val<IState['instrumentsConfig']>(instrumentsConfig),
    })
  );
};

export const initializeExchangeStateBasedOnEnv = (): IThunkMethod => (dispatch, getState) => {
  return dispatch(
    commit(`Initialize exchange state based on env.`, {
      currentPair: val<IState['currentPair']>(null),
      instrumentsConfig: val<IState['instrumentsConfig']>(
        getState().env.instruments.list.reduce((configObj, instrument) => {
          configObj[instrument] = {
            instrument,
            user_id: 0,
            disable_deposits: false,
            deposit_fee: '0',
            disable_withdrawals: false,
            withdrawal_fee: '0',
            min_withdrawal: '0',
          };
          return configObj;
        }, {})
      ),
      pairsConfig: val<IState['pairsConfig']>(
        getState().env.pairs.list.reduce((configObj, pair) => {
          configObj[pair] = {
            pair,
            user_id: 0,
            disable_orders: false,
            trade_fee: 0,
            min_buy_order: '0',
            min_sell_order: '0',
          };
          return configObj;
        }, {})
      ),
      priceSummary24h: val<IState['priceSummary24h']>(
        priceSummary24hTransformation(
          {
            by_pair: {},
          } as any,
          getState().env.pairs.list
        )
      ),
    })
  );
};

export const setPriceSummary24hFromApiData = (summary: IApiPriceHistorySummary): IThunkMethod => (
  dispatch,
  getState
) => {
  return dispatch(
    commit(`Set new 24h price summary from API provided data`, {
      priceSummary24h: val<IState['priceSummary24h']>(
        priceSummary24hTransformation(summary, getState().env.pairs.list)
      ),
    })
  );
};

let pendingTradesByPair = {};

const processPriceSummaryTradesThrottled = lodash.throttle(
  (dispatch: IThunkDispatch, getState: IStateGetter) => {
    const pendingTradesList = Object.values(pendingTradesByPair);
    const lastPriceSummary = { ...getState().priceSummary24h };
    if (!lastPriceSummary || !pendingTradesList.length) {
      return;
    }
    const newState = Object.keys(pendingTradesByPair).reduce((response, pair) => {
      for (const trade of pendingTradesByPair[pair]) {
        const pairSummary = { ...response[trade.pair] };
        if (!pairSummary) {
          continue;
        }
        pairSummary.close = trade.price;
        pairSummary.high = Math.max(trade.price, pairSummary.high);
        pairSummary.low = Math.min(trade.price, pairSummary.low);
        pairSummary.volume = Quantity(pairSummary.volume)
          .add(Quantity(trade.quantity).multiply(trade.price))
          .toString();
        pairSummary.count++;
        response[trade.pair] = pairSummary;
      }

      return response;
    }, lastPriceSummary);

    pendingTradesByPair = {};

    dispatch(
      commit(`Update 24h summary from ${pendingTradesList.length} trades`, {
        priceSummary24h: val(newState),
      })
    );
  },
  500
);

export const updatePriceSummary24hFromMarketTrade = (trade: ITrade): IThunkMethod => (
  dispatch,
  getState
) => {
  if (!pendingTradesByPair[trade.pair]) {
    pendingTradesByPair[trade.pair] = [];
  }
  pendingTradesByPair[trade.pair].push(trade);
  processPriceSummaryTradesThrottled(dispatch, getState);
};

export const loadExchangeData = (pairPath: IPair): IThunkMethod => (
  dispatch,
  getState,
  { api }
) => {
  // load exchange data for anonymous user
  if (!isUserLoggedIn(getState())) {
    return api.getWebuiGlobalExchangeData(pairPath).then((globalExchangeData) => {
      if (pairPath === getState().nextPair) {
        // set global pairs config (used to calculate fee for anon users)
        dispatch(setPairsConfig(globalExchangeData.global_pair_policy));

        // set last trades for current instrument pair
        dispatch(submitMarketTrades(globalExchangeData.market_trades));

        // set orderbook
        dispatch(updateMarketDepth(globalExchangeData.market_depth));
      }
    });
  }

  // load exchange data for logged in user
  const paginationState = getActiveOrdersPaginationState(getState());
  const nextPageCriteria = getNextPageCriteria(paginationState, 25, true);

  return dispatch(
    loadingWrapper(
      'getWebuiUserExchangeData',
      api.getWebuiUserExchangeData(pairPath, nextPageCriteria).then((userExchangeData) => {
        if (pairPath === getState().nextPair) {
          // set user pairs config (for fee calculation)
          dispatch(setPairsConfig(userExchangeData.user_pair_policy));

          // set last trades for current instrument pair
          dispatch(submitMarketTrades(userExchangeData.market_trades));

          // set orderbook
          dispatch(updateMarketDepth(userExchangeData.market_depth));

          // set user last trades for current instrument pair
          dispatch(
            submitUserTradesForCurrentInstrumentPair(pairPath, userExchangeData.user_trades)
          );

          // set orders for all instrument pairs
          dispatch(commitActiveOrdersForAllInstruments(userExchangeData.exchange_overview.pairs));

          // set user's orders for current instrument pair
          dispatch(
            submitActiveUserOrdersForInstrumentPair(pairPath, userExchangeData.user_orders, true)
          );
        }
      })
    )
  );
};

export const loadExchange = (pairPath: IPair) => (
  dispatch,
  getState: IStateGetter,
  { socketManager }: Container
) => {
  let pair: IInstrumentPair;

  try {
    pair = pairInfo(pairPath);
  } catch (err) {
    return dispatch(handleError(err));
  }

  if (!getState().env.pairs.list.includes(pairPath)) {
    // inactive market
    // we wanna redirect to default market pair
    return dispatch(goToExchange());
  }

  // set next pair
  dispatch(setNextExchangePair(pairPath));

  // initiate exchange for the first time
  if (!isExchangeInitiated(getState())) {
    // save information about new pair in store. Also reset fields that are related to the old store
    dispatch(setCurrentExchangePair(pair));

    dispatch(setExchangeInitiated());
  }

  // loads all the data for the current instrument pair needed for the exchange
  dispatch(loadExchangeData(pairPath))
    .then(() => {
      if (isExchangeInitiated(getState()) && pairPath === getState().nextPair) {
        // save information about new pair in store. Also reset fields that are related to the old store
        dispatch(setCurrentExchangePair(pair));
      }

      // set sockets sector to the current instrument pair
      socketManager.setSector('pair', pairPath);
    })
    .catch((error) => dispatch(handleError(error)));
};

export const unloadExchange = (): IThunkMethod => (_, __, { socketManager }) => {
  socketManager.setSector('pair', null);
};
