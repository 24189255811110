import React from 'react';
import { Helmet } from 'react-helmet';
import { ConnectedProps, connect } from 'react-redux';
import {
  RouteChildrenProps,
  RouteComponentProps,
  RouteProps,
  StaticContext,
  matchPath,
} from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import { doFindStaticRedirectRoute } from '../../actions/routing';
import {
  ICustomHistory,
  ICustomLocation,
  ICustomLocationDescriptor,
  ICustomLocationState,
} from '../../lib/history';
import { I18n } from '../../lib/i18n';
import { Logger } from '../../lib/logger';
import { inject } from '../../lib/react_container';
import R, { IRouteSpec, IRouteSpecTyped, getRouteEnablementProps } from '../../lib/routes';
import { IState } from '../../lib/store';
import { isUserLoggedIn } from '../../selectors/auth';
import CustomRouteWrapper from './CustomRouteWrapper';

const pathProp = (route: IRouteSpec) => {
  return route.dynamic ? `*${route.path}` : route.path;
};

type ICustomHrefWithParams = string | ICustomLocationDescriptor | IRouteSpec;

interface ICustomRouteProps<TParams, TRouteParams extends object> {
  route: IRouteSpecTyped<TParams, TRouteParams>;
  component?: React.ComponentType<any>;
  layout?: React.ComponentType<{ route: IRouteSpec }>;
  render?: (
    props: RouteComponentProps<TParams, StaticContext, ICustomLocationState>
  ) => React.ReactNode;
  redirect?:
    | (<TParams>(props: RouteChildrenProps<TParams, ICustomLocationState>) => ICustomHrefWithParams)
    | ICustomHrefWithParams;
}

interface ICustomRouteGenericProps
  extends ICustomRouteProps<any, any>,
    ConnectedProps<typeof connector> {
  logger: Logger;
}

class CustomRouteGeneric extends React.PureComponent<ICustomRouteGenericProps> {
  private log: Logger;

  constructor(props: ICustomRouteGenericProps) {
    super(props);

    this.log = props.logger.prefixed('CustomRoute');
  }

  renderAuthRedirect(history: ICustomHistory) {
    // We have entered a secured route without being logged in. We want to be bounced back to either the last public route
    // in the history stack, or the homepage

    const staticRoute = doFindStaticRedirectRoute(
      this.props.activeStaticRoute,
      history,
      (route) => !route.dynamic && !route.secured
    );

    // But also display a login popup
    const redirectTo = R.LOGIN.prefixed(staticRoute).to();

    return <Redirect to={redirectTo} />;
  }

  renderAnonymousRedirect(history: ICustomHistory) {
    // We have entered an anonymous route while being logged in. We want to be bounced back to either the last non-anonymous route
    // in the history stack, or the homepage

    const redirectToRoute = doFindStaticRedirectRoute(
      this.props.activeStaticRoute,
      history,
      (route) => !route.dynamic && !route.anonymous
    );

    return <Redirect to={redirectToRoute} />;
  }

  renderCustomRedirect(routeProps: RouteChildrenProps<any, ICustomLocationState>) {
    let redirectTo = this.props.redirect;
    if (typeof redirectTo === 'function') {
      // Have custom provided func determine where
      redirectTo = redirectTo(routeProps);
    }
    if ((redirectTo as IRouteSpec).to) {
      // Presume we were given a RouteSpec. Generate the location from params.
      const params = (routeProps.match && routeProps.match.params) || {};
      redirectTo = (redirectTo as IRouteSpec).to(params);
    }

    // We should now have either ICustomLocationDescriptor or an url string
    return <Redirect to={redirectTo as any} />;
  }

  staticRouteRenderFunc = (routeProps: RouteChildrenProps) => {
    const route = this.props.route;

    this.log.shouldVerbose &&
      this.log.verbose(
        `${route.describe()} activated${
          routeProps.match && routeProps.match.params
            ? ` with ${JSON.stringify(routeProps.match.params)}`
            : ''
        }`
      );

    if (route.secured && !this.props.loggedIn && this.props.activeStaticRoute) {
      return this.renderAuthRedirect(routeProps.history as ICustomHistory);
    }

    if (route.anonymous && this.props.loggedIn && this.props.activeStaticRoute) {
      return this.renderAnonymousRedirect(routeProps.history as ICustomHistory);
    }

    if (this.props.redirect) {
      return this.renderCustomRedirect(routeProps);
    }

    return (
      <I18n>
        {(t) => (
          <>
            {/* Define global SEO here */}
            <Helmet>
              <meta name="keywords" content={t('seo.keywords')} />
            </Helmet>
            <CustomRouteWrapper
              component={this.props.component}
              layout={this.props.layout}
              render={this.props.render}
              route={this.props.route}
              routeProps={routeProps}
            />
          </>
        )}
      </I18n>
    );
  };

  render() {
    const { route, routeEnablementProps } = this.props;
    // TODO: We are passing empty params here. So this will only work correctly for param-less routes. Don't like.
    if (!route.isEnabled(routeEnablementProps, {})) {
      // Do not render disabled routes
      return null;
    }

    return <Route path={pathProp(route)} exact={route.exact} render={this.staticRouteRenderFunc} />;
  }
}

const connector = connect((state: IState) => ({
  routeEnablementProps: getRouteEnablementProps(state),
  loggedIn: isUserLoggedIn(state),
  activeStaticRoute: state.activeStaticRoute,
}));

const CustomRouteConnected = connector(inject('logger')(CustomRouteGeneric));

// NOTE: I had to add this wrapper so we could keep the generic param. I couldn't figure out the other way.
//       Revisit if we ever get better at typescript.
export default class CustomRoute<TParams, TRouteParams extends object> extends React.PureComponent<
  ICustomRouteProps<TParams, TRouteParams>
> {
  render() {
    return (<CustomRouteConnected {...this.props} />) as any;
  }
}

// *********************************************************************************************************************

/**
 * This is basically copy-paste of react router's Switch
 * https://github.com/ReactTraining/react-router/blob/v4.3.1/packages/react-router/modules/Switch.js
 * Except it also supports our CustomRoute. Unfortunately, they are sniffing child component props
 * and this was the only way to make it work with also having the API we want.
 */
export class CustomSwitch extends React.Component<{ location?: ICustomLocation }> {
  // NOTE: This seems to be required in order to use legacy context system
  static contextTypes = {
    router: () => null,
  };

  render() {
    const { route } = this.context.router;
    const { children } = this.props;
    const location = this.props.location || route.location;

    let match;
    let child;
    React.Children.forEach(children, (element) => {
      if (match == null && React.isValidElement(element)) {
        const elementProps = element.props as any;
        let matchPathProps: RouteProps;
        if (elementProps.route) {
          // Our CustomRoute
          const routeSpec: IRouteSpec = elementProps.route;
          matchPathProps = {
            path: pathProp(routeSpec),
            exact: routeSpec.exact,
          };
        } else {
          // Normal route
          const { path: pathProp, exact, strict, sensitive, from } = element.props as any;
          const path = pathProp || from;
          matchPathProps = { path, exact, strict, sensitive };
        }

        child = element;
        match = matchPath(location.pathname, matchPathProps, route.match);
      }
    });

    if (!child) {
      return null;
    }

    return match ? React.cloneElement(child, { location, computedMatch: match }) : null;
  }
}
