import * as React from 'react';

import styled, { IThemeColorsUnion } from '../../lib/styled_components';
import { shadeColor } from '../../lib/util';
import { IOrderSide } from '../../types/backend_definitions';

const $PrettyDecimals = styled.span<{ bold?: boolean; wordBreak?: boolean }>`
  ${(p) => p.bold && `font-weight: bold;`}
  ${(p) => p.wordBreak && `word-break: break-all;`}
`;

export const $LowContrast = styled('span')<{ color?: IThemeColorsUnion }>`
  color: ${(p) =>
    shadeColor(p.color ? `${p.theme.colors[p.color]}` : `${p.theme.base.color}`, -0.3)};
`;

export const $MedContrast = styled('span')<{ color?: IThemeColorsUnion }>`
  color: ${(p) =>
    shadeColor(p.color ? `${p.theme.colors[p.color]}` : `${p.theme.base.color}`, -0.15)};
`;

export const $HighContrast = styled('span')<{ color?: IThemeColorsUnion }>`
  color: ${(p) => shadeColor(p.color ? `${p.theme.colors[p.color]}` : `${p.theme.base.color}`, 0)};
`;

interface IPrettyDecimals {
  value: number | string;
  color?: IThemeColorsUnion;
  bold?: boolean;
  wordBreak?: boolean;
  title?: string;
}

const CHAR_CODE_0 = 48;
const CHAR_CODE_DOT = 46;

export const getDecimalsSplit = (value) => {
  const length = value.length;
  let zeroDecimalsIndex = null;
  let dotIndex = length;
  let hasDot = false;
  for (let i = length - 1; i >= 0; i--) {
    const cc = value.charCodeAt(i);
    if (cc !== CHAR_CODE_0 && zeroDecimalsIndex === null) {
      // No more trailing zeroes
      zeroDecimalsIndex = i + 1;
    }
    if (cc === CHAR_CODE_DOT) {
      dotIndex = i;
      hasDot = true;
    }
  }
  if (zeroDecimalsIndex === null) {
    // There are no trailing 0-s
    zeroDecimalsIndex = length;
  }

  return {
    whole: hasDot ? value.slice(0, Math.min(zeroDecimalsIndex, dotIndex)) : value,
    decimals: value.slice(dotIndex, zeroDecimalsIndex),
    zeroes: hasDot ? value.slice(zeroDecimalsIndex) : '',
  };
};

const PrettyDecimals = ({ value, color, bold, wordBreak, title }: IPrettyDecimals) => {
  if (value === null || value === '' || value === undefined) {
    // Invisible output
    return null;
  }

  const split = getDecimalsSplit(String(value));

  // return colorized version of value
  return (
    <$PrettyDecimals bold={bold} wordBreak={wordBreak} title={title}>
      <$HighContrast color={color}>{split.whole}</$HighContrast>
      {split.decimals && <$MedContrast color={color}>{split.decimals}</$MedContrast>}
      {split.zeroes && <$LowContrast color={color}>{split.zeroes}</$LowContrast>}
    </$PrettyDecimals>
  );
};

export default PrettyDecimals;

interface ISidedPrettyDecimalsProps {
  side: IOrderSide;
}

export const SidedPrettyDecimals = (({ side, ...passProps }) => {
  const color = side === 'buy' ? 'buy' : 'sell';
  return <PrettyDecimals color={color} bold {...passProps} />;
}) as React.FunctionComponent<IPrettyDecimals & ISidedPrettyDecimalsProps>;

export const RedLightPrettyDecimals = ((props) => (
  <PrettyDecimals color="sell" {...props} bold />
)) as React.FunctionComponent<IPrettyDecimals>;

export const GreenLightPrettyDecimals = ((props) => (
  <PrettyDecimals color="buy" {...props} bold />
)) as React.FunctionComponent<IPrettyDecimals>;
