import 'react-toastify/dist/ReactToastify.css';

import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Route, RouteComponentProps, withRouter } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';

import { IToastNotification, initializeAppForTheFirstTime } from '../actions/app';
import { inject } from '../lib/react_container';
import R from '../lib/routes';
import { SentryIntegration } from '../lib/sentry_integration';
import { IState } from '../lib/store';
import styled, { ThemeProvider } from '../lib/styled_components';
import { getLastNotification } from '../selectors/app';
import { getCurrentPairPath } from '../selectors/exchange';
import GlobalStyles from '../styles/global_styles';
import { TOAST_AUTOCLOSE_TIMEOUT } from '../types/constants';
import EmailVerificationExecuteScreen from './components/auth/EmailVerificationExecuteScreen';
import LoginScreen from './components/auth/LoginScreen';
import PasswordResetExecuteScreen from './components/auth/PasswordResetExecuteScreen';
import PasswordResetRequestScreen from './components/auth/PasswordResetRequestScreen';
import RegisterScreen from './components/auth/RegisterScreen';
import SessionIdleTimeoutCountdownScreen from './components/auth/session/SessionIdleTimeoutCountdownScreen';
import SessionTerminatedScreen from './components/auth/session/SessionTerminatedScreen';
import CardPaymentDeclinedScreen from './components/buy_with_card/CardPaymentDeclinedScreen';
import CardPaymentSubmittedScreen from './components/buy_with_card/CardPaymentSubmittedScreen';
import CandleChartOverlay from './components/exchange/CandleChartOverlay';
import ManagedAgreementPage from './components/managed/ManagedAgreementPage';
import PhoneVerificationModal from './components/settings/kyc/PhoneVerificationModal';
import WithdrawalConfirmationScreen from './components/transactions/WithdrawalConfirmationScreen';
import CustomRoute, { CustomSwitch } from './layout/CustomRoute';
import DynamicRouteModal from './layout/DynamicRouteModal';
import MainLayout from './layout/main_layout/MainLayout';
import AppErrorPage from './pages/AppErrorPage';
import BalancesPage from './pages/BalancesPage';
import BuyWithCardPage from './pages/BuyWithCardPage';
import DevPage from './pages/DevPage';
import ExchangePage from './pages/ExchangePage';
import MaintenancePage from './pages/MaintenancePage';
import ManagedPage from './pages/ManagedPage';
import MarketsPage from './pages/MarketsPage';
import NotFoundPage from './pages/NotFoundPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ReportsPage from './pages/ReportsPage';
import SettingsPage from './pages/SettingsPage';
import TosPage from './pages/TosPage';
import DepositPage from './pages/transactions/DepositPage';
import WithdrawPage from './pages/transactions/WithdrawPage';
import Logo from './widgets/Logo';
import { PushNotification } from './widgets/Notification';

const $LoadingWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

interface IAppProps extends ConnectedProps<typeof connector>, RouteComponentProps {
  sentryIntegration: SentryIntegration;
}

interface IAppState {
  hasError: boolean;
}

class App extends React.PureComponent<IAppProps, IAppState> {
  private lastNotification: IToastNotification = null;
  private maximumNotifications = 4;
  private activeToastsIds = [];
  private defaultNotificationConfig = {
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: TOAST_AUTOCLOSE_TIMEOUT,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {
    this.props.initializeAppForTheFirstTime();
  }

  componentDidUpdate(prevProps: IAppProps, prevState: IAppState, snapshot: any): void {
    if (
      this.lastNotification !== this.props.lastNotification &&
      this.props.lastNotification !== undefined
    ) {
      this.lastNotification = this.props.lastNotification;

      if (
        this.lastNotification.toastId &&
        this.activeToastsIds.includes(this.lastNotification.toastId)
      ) {
        // We already have this id shown. Update timer, so that it stays on the screen.
        toast.update(this.lastNotification.toastId, {
          autoClose: TOAST_AUTOCLOSE_TIMEOUT,
        });

        // TODO: It would be cool if the toast flashed here, but I couldn't get it to work
        return;
      }

      const toastId = toast(<PushNotification notification={this.lastNotification} />, {
        ...this.defaultNotificationConfig,
        ...this.lastNotification,
        // Add optional className for toast buy and sell types
        className:
          this.lastNotification.type === 'buy'
            ? 'buy'
            : this.lastNotification.type === 'sell'
            ? 'sell'
            : '',
        type:
          this.lastNotification.type === 'buy'
            ? 'success'
            : this.lastNotification.type === 'sell'
            ? 'error'
            : this.lastNotification.type,
        onClose: () => {
          this.activeToastsIds = this.activeToastsIds.filter((id) => id !== toastId);
        },
        onOpen: () => {
          if (!this.activeToastsIds.includes(toastId)) {
            this.activeToastsIds.push(toastId);
          }
          if (this.activeToastsIds.length > this.maximumNotifications) {
            const oldestId = this.activeToastsIds.shift();
            toast.dismiss(oldestId);
          }
        },
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    // Display fallback UI
    this.setState({ hasError: true });

    window.consoleError(error);
    if (errorInfo && errorInfo.componentStack) {
      window.consoleError('Component stack', errorInfo.componentStack);
    }

    // NOTE: SentryIntegration will have already sent the error based on console integration,
    //       but this will provide better stack and info.
    this.props.sentryIntegration.report('App', error, errorInfo);
  }

  renderStaticRoutes() {
    const exchangeLocation = R.EXCHANGE.to({
      pair: this.props.currentPairPath || this.props.defaultPair,
    });

    return (
      <CustomSwitch>
        <CustomRoute route={R.HOME} redirect={exchangeLocation} />
        <CustomRoute route={R.EXCHANGE_HOME} redirect={exchangeLocation} />
        <CustomRoute route={R.EXCHANGE} component={ExchangePage} />

        <CustomRoute route={R.LOGIN.prefixed('/')} redirect={R.LOGIN.prefixed(exchangeLocation)} />
        <CustomRoute
          route={R.REGISTER.prefixed('/')}
          redirect={R.REGISTER.prefixed(exchangeLocation)}
        />
        <CustomRoute
          route={R.PASSWORD_RESET_REQUEST.prefixed('/')}
          redirect={R.PASSWORD_RESET_REQUEST.prefixed(exchangeLocation)}
        />
        <CustomRoute
          route={R.PASSWORD_RESET_EXECUTE.prefixed('/')}
          redirect={R.PASSWORD_RESET_EXECUTE.prefixed(exchangeLocation)}
        />
        <CustomRoute
          route={R.EMAIL_VERIFICATION_EXECUTE.prefixed('/')}
          redirect={R.EMAIL_VERIFICATION_EXECUTE.prefixed(exchangeLocation)}
        />

        <CustomRoute route={R.MANAGED_HOME} component={ManagedPage} />
        <CustomRoute route={R.MANAGED} component={ManagedPage} />

        <CustomRoute route={R.MARKETS} component={MarketsPage} />

        <CustomRoute route={R.DEPOSIT} component={DepositPage} />
        <CustomRoute route={R.WITHDRAW} component={WithdrawPage} />
        <CustomRoute route={R.BALANCES} component={BalancesPage} />

        <CustomRoute
          route={R.WITHDRAWAL_CONFIRMATION.prefixed('/')}
          redirect={R.WITHDRAWAL_CONFIRMATION.prefixed(exchangeLocation)}
        />

        <CustomRoute route={R.BUY_WITH_CARD} component={BuyWithCardPage} />

        <CustomRoute route={R.REPORTS_HOME} redirect={R.REPORTS.to({ page: 'open-orders' })} />
        <CustomRoute route={R.REPORTS} component={ReportsPage} />

        <CustomRoute route={R.SETTINGS_HOME} redirect={R.SETTINGS.to({ page: 'account' })} />
        <CustomRoute route={R.SETTINGS} component={SettingsPage} />

        <CustomRoute route={R.DEV} component={DevPage} />

        <Route component={NotFoundPage} />
      </CustomSwitch>
    );
  }

  /**
   * Dynamic routes are grafted on top of static routes. Eg. /exchange/SFX_BTC becomes /exchange/SFX_BTC/login
   * This is used for overlays (DynamicRouteModal-s) that can be shown over any route.
   * They need to be rendered in their own Switch, so they can appear on top of
   */
  renderDynamicRoutes() {
    return (
      <CustomSwitch>
        <CustomRoute route={R.LOGIN} component={LoginScreen} layout={DynamicRouteModal} />
        <CustomRoute route={R.REGISTER} component={RegisterScreen} layout={DynamicRouteModal} />
        <CustomRoute
          route={R.EMAIL_VERIFICATION_EXECUTE}
          component={EmailVerificationExecuteScreen}
          layout={DynamicRouteModal}
        />
        <CustomRoute
          route={R.PASSWORD_RESET_REQUEST}
          component={PasswordResetRequestScreen}
          layout={DynamicRouteModal}
        />
        <CustomRoute
          route={R.PASSWORD_RESET_EXECUTE}
          component={PasswordResetExecuteScreen}
          layout={DynamicRouteModal}
        />
        <CustomRoute
          route={R.WITHDRAWAL_CONFIRMATION}
          component={WithdrawalConfirmationScreen}
          layout={DynamicRouteModal}
        />
        <CustomRoute
          route={R.CARD_PAYMENT_SUBMITTED}
          component={CardPaymentSubmittedScreen}
          layout={DynamicRouteModal}
        />
        <CustomRoute
          route={R.CARD_PAYMENT_DECLINED}
          component={CardPaymentDeclinedScreen}
          layout={DynamicRouteModal}
        />
        <CustomRoute
          route={R.SESSION_TERMINATED}
          component={SessionTerminatedScreen}
          layout={DynamicRouteModal}
        />
      </CustomSwitch>
    );
  }

  render() {
    if (this.props.initialLoading) {
      // Render loading UI, this will only be shown during initial app loading
      return (
        <$LoadingWrapper>
          <Logo width={255} height={102} margin="0" />
        </$LoadingWrapper>
      );
    }

    if (this.state.hasError) {
      // Render custom fallback UI
      return <AppErrorPage />;
    }

    // Render routes
    // ThemeProvider is needed to be initialized here due to 'http2'
    return (
      <ThemeProvider theme={this.props.theme}>
        <>
          <CustomSwitch>
            {/* NOTE: We render these routes here because we want them to appear as full-screen, without rendering any of the other cruft underneath */}
            <CustomRoute route={R.TERMS_OF_USE} component={TosPage} />
            <CustomRoute route={R.PRIVACY_POLICY} component={PrivacyPolicyPage} />
            <CustomRoute route={R.MANAGED_AGREEMENT} component={ManagedAgreementPage} />
            <CustomRoute route={R.MAINTENANCE} component={MaintenancePage} />

            <Route
              render={() => (
                <MainLayout>
                  <CandleChartOverlay />
                  {this.renderStaticRoutes()}
                  {this.renderDynamicRoutes()}
                </MainLayout>
              )}
            />
          </CustomSwitch>

          <GlobalStyles />
          <ToastContainer style={{ zIndex: 10000 }} className="notification-container" />
          <SessionIdleTimeoutCountdownScreen />
          <PhoneVerificationModal />
        </>
      </ThemeProvider>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    initialLoading: state.app.initialLoading,
    currentPairPath: getCurrentPairPath(state),
    theme: state.app.theme,
    lastNotification: getLastNotification(state),
    defaultPair: state.env.pairs.defaultPair,
  }),
  {
    initializeAppForTheFirstTime,
  }
);
export default withRouter(connector(inject('sentryIntegration')(App)));
