import styled from '../../lib/styled_components';

export const $LeftHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${(p) => p.theme.widgets.header.wrappers.left.height}px;
  background: ${(p) => p.theme.layout.settings.leftHeaderWrapper.background};
  border-top: ${(p) => p.theme.layout.settings.leftHeaderWrapper.borderTop};

  .svg-icon-color-stroke {
    stroke: ${(p) => p.theme.colors.info} !important;
  }
  h3 {
    color: ${(p) => p.theme.colors.info} !important;
  }

  padding-left: 12px;
  @media screen and ${(p) => p.theme.device.mobile} {
    padding-left: 20px;
  }
`;

export const $HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 58px;
  background: ${(p) => p.theme.layout.settings.rightHeaderWrapper.background};
  border-top: ${(p) => p.theme.layout.settings.rightHeaderWrapper.borderTop};

  @media screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    padding: 0 10px;
  }
`;
