import { getConversionRate } from '../selectors/rates';
import {
  IApiEstimatorData,
  IApiMarketDepthLevel,
  IApiRates,
  IMarketInstrument,
  IOrderSide,
} from '../types/backend_definitions';
import { ALL_INSTRUMENTS, IInstrumentPair } from '../types/instruments';
import { IManagedFiatCurrency } from '../types/managed';
import Quantity from './quantity_';

const getTotalWithFeeApplied = (
  fee: number,
  side: IOrderSide,
  amount: Quantity,
  price: number
): [Quantity, Quantity] => {
  // NOTE: we do not use baseGain/baseLoss because it truncates quantity
  // we need original quantity for back calculations
  const feeQty = amount
    .multiply(Quantity(price).multiply(side === 'buy' ? 1 + fee : 1 - fee))
    .sub(amount.multiply(Quantity(price)));
  const total = amount.multiply(price).add(feeQty);
  return [total, feeQty];
};

export const getBuySellTotal = (
  levels: IApiMarketDepthLevel[],
  side: IOrderSide,
  amount: Quantity,
  price: number,
  fee: number = 0
): [Quantity, Quantity] => {
  if (price === 0 || !amount || amount.eq(0)) {
    return [Quantity(0), null];
  }

  if (!levels || !levels.length) {
    // base case
    return getTotalWithFeeApplied(fee, side, amount, price);
  }

  const isSell = side === 'sell';
  const targetPrice = price;
  const firstPrice = levels[0].price;

  if (targetPrice) {
    const overshadowFirst = isSell
      ? Quantity(firstPrice).lt(targetPrice)
      : Quantity(firstPrice).gt(targetPrice);

    if (overshadowFirst) {
      // base case is flat calc: total = amount * price
      return getTotalWithFeeApplied(fee, side, amount, targetPrice);
    }
  }

  let cumTotal = Quantity(0);
  let cumFee = Quantity(0);
  let cumQty = Quantity(0);
  let lastPrice = null;
  for (const { total, price, quantity } of levels) {
    const isOverSpecifiedAmount = cumQty.add(quantity).gt(amount);
    let isOverTargetPrice = false;
    if (targetPrice) {
      isOverTargetPrice = isSell
        ? Quantity(price).lt(targetPrice)
        : Quantity(price).gt(targetPrice);
    }
    if (isOverTargetPrice || isOverSpecifiedAmount) {
      // we finish at this price level
      const remaining = amount.sub(cumQty);
      if (remaining.gt(0)) {
        cumQty = cumQty.add(remaining);
        const [curLevelTotalWithFee, curLevelFee] = getTotalWithFeeApplied(
          fee,
          side,
          remaining,
          isOverTargetPrice ? targetPrice : price
        );
        cumTotal = cumTotal.add(curLevelTotalWithFee);
        cumFee = cumFee.add(curLevelFee);
      }
      lastPrice = price;
      break;
    }

    const [lvlTotalWithFee, lvlFee] = getTotalWithFeeApplied(fee, side, Quantity(quantity), price);
    cumTotal = cumTotal.add(lvlTotalWithFee);
    cumFee = cumFee.add(lvlFee);
    cumQty = cumQty.add(quantity);
    lastPrice = price;
  }

  const remaining = amount.sub(cumQty);
  if (remaining.gt(0)) {
    // we have cleared out the order book before filling the requested quantity
    // fill the remaining with target price or last price in case of market order
    const [remainingTotalWithFee, remainingFee] = getTotalWithFeeApplied(
      fee,
      side,
      remaining,
      targetPrice || lastPrice
    );
    cumTotal = cumTotal.add(remainingTotalWithFee);
    cumFee = cumFee.add(remainingFee);
  }

  return [cumTotal, cumFee];
};

const getAmountFromTotalWithFee = (
  fee: number,
  side: IOrderSide,
  price: number,
  total: Quantity
): [Quantity, Quantity] => {
  const feeQty = total
    .divide(Quantity(price).multiply(side === 'buy' ? 1 + fee : 1 - fee))
    .sub(total.divide(Quantity(price)));
  const amount = total.divide(Quantity(price)).add(feeQty);
  return [amount, feeQty];
};

export const getBuySellAmount = (
  levels: IApiMarketDepthLevel[],
  side: IOrderSide,
  price: number,
  total: Quantity,
  fee: number = 0
): [Quantity, Quantity] => {
  if (price === 0 || !total || total.eq(0)) {
    return [Quantity(0), null];
  }

  if (!levels || !levels.length) {
    return getAmountFromTotalWithFee(fee, side, price, total);
  }

  const isSell = side === 'sell';
  const targetPrice = price;
  const targetTotal = total;
  let cumTotal = Quantity(0);
  let cumFee = Quantity(0);
  let cumQty = Quantity(0);
  let lastPrice = null;
  for (const { total, price, quantity } of levels) {
    const [totalWithFee, feeQty] = getTotalWithFeeApplied(fee, side, Quantity(quantity), price);
    const isOverSpecifiedTotal = targetTotal && cumTotal.add(totalWithFee).gt(targetTotal);
    let isOverTargetPrice = false;
    if (targetPrice) {
      isOverTargetPrice = isSell
        ? Quantity(price).lt(targetPrice)
        : Quantity(price).gt(targetPrice);
    }

    if (isOverTargetPrice || isOverSpecifiedTotal) {
      const remainingTotal = targetTotal.sub(cumTotal);
      if (remainingTotal.gt(0)) {
        const [remainingQty] = getAmountFromTotalWithFee(
          fee,
          side,
          isOverTargetPrice ? targetPrice : price,
          remainingTotal
        );
        const [_, remainingFee] = getTotalWithFeeApplied(
          fee,
          side,
          remainingQty,
          isOverTargetPrice ? targetPrice : price
        );
        cumQty = cumQty.add(remainingQty);
        cumTotal = cumTotal.add(remainingTotal);
        cumFee = cumFee.add(remainingFee);
      }
      break;
    }

    cumTotal = cumTotal.add(totalWithFee);
    cumFee = cumFee.add(feeQty);
    cumQty = cumQty.add(quantity);
    lastPrice = price;
  }

  const remainingTotal = targetTotal.sub(cumTotal);
  if (remainingTotal.gt(0)) {
    const [leftQty] = getAmountFromTotalWithFee(
      fee,
      side,
      targetPrice || lastPrice,
      remainingTotal
    );
    cumQty = cumQty.add(leftQty);
    const [_, remainingFee] = getTotalWithFeeApplied(fee, side, leftQty, targetPrice || lastPrice);
    cumFee = cumFee.add(remainingFee);
  }

  return [cumQty, cumFee];
};

const getManagedBuyOrderEstimatedCryptoGains = (
  data: IApiEstimatorData,
  rates: IApiRates,
  fiatInstrument: IManagedFiatCurrency,
  fiatQuantity,
  cryptoInstrument: IMarketInstrument
) => {
  if (!data || !data[cryptoInstrument] || !data[cryptoInstrument].market_depth) {
    return null;
  }

  const { market_depth, partner_provided, max_price } = data[cryptoInstrument];
  const levels = market_depth.sell;

  if (!levels || !levels.length) {
    // order book is empty
    return null;
  }

  const rate = getConversionRate(
    rates,
    fiatInstrument,
    partner_provided ? ALL_INSTRUMENTS.USD.symbol : ALL_INSTRUMENTS.BTC.symbol
  );
  if (!rate) {
    // we cannot convert external to glue instrument
    return null;
  }

  const totalFunds = fiatQuantity * rate;

  let result = 0;
  let spentFunds = 0;
  for (const [price, qty] of levels) {
    const total = price * qty;
    const availableFunds = totalFunds - spentFunds;

    if (max_price && price > max_price) {
      // price has reached limit
      // fill the rest with limit price
      result += Quantity(availableFunds / max_price)
        .truncateForInstrument(cryptoInstrument)
        .toNumber();
      spentFunds += availableFunds;
      break;
    }

    if (availableFunds < total) {
      result += Quantity((availableFunds / total) * qty)
        .truncateForInstrument(cryptoInstrument)
        .toNumber();
      spentFunds += availableFunds;
      break;
    }

    result += Quantity(qty).truncateForInstrument(cryptoInstrument).toNumber();
    spentFunds += total;
  }

  const leftFunds = totalFunds - spentFunds;
  if (leftFunds > 0) {
    // no volume left and not all funds spent
    // fill the rest on limit price
    result += leftFunds / max_price;
  }

  return result;
};

export default getManagedBuyOrderEstimatedCryptoGains;
