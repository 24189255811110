import * as React from 'react';

import { IActiveRequestsKeys } from '../../actions/request_active';
import styled from '../../lib/styled_components';
import { IThemeVariant } from '../../types/theme';
import { $Button, IButtonProps } from './buttons';
import LoadingAnimation from './LoadingAnimation';

const $LoaderButton = styled($Button)<{ loading?: boolean } & IButtonProps>`
  & > :first-child {
    opacity: ${(p) => (p.loading ? '0' : '1')};
    transition: opacity 0.25s;
  }
  & > :last-child {
    position: absolute;
    opacity: ${(p) => (p.loading ? '1' : '0')};
    transition: opacity 0.25s;
  }
`;

const $LoaderButtonContentWrapper = styled.div``;

interface ILoaderButtonProps extends IButtonProps {
  loading?: boolean;
  minimumLoadingAnimationDuration?: number;
  animationDuration?: number;
  variant?: IThemeVariant;
}

export const LoaderButton: React.FunctionComponent<ILoaderButtonProps> = ({
  loading,
  children,
  minimumLoadingAnimationDuration,
  animationDuration,
  variant,
  ...buttonProps
}) => {
  return (
    <$LoaderButton
      {...(buttonProps as any)}
      loading={loading}
      inert={loading ? true : buttonProps.inert}
      display={'flex'}
    >
      <$LoaderButtonContentWrapper>{children}</$LoaderButtonContentWrapper>
      <LoadingAnimation
        minimumLoadingAnimationDuration={minimumLoadingAnimationDuration}
        animationDuration={animationDuration}
        variant={variant === 'light' ? 'normal' : 'inverse'}
      />
    </$LoaderButton>
  );
};
