import * as React from 'react';

import styled, { IThemeColorsUnion } from '../../lib/styled_components';
import { IOrderSide } from '../../types/backend_definitions';

const $TradeSideIndicator = styled.div`
  width: 1em;
  height: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const $CircleOutline = styled.div<{
  color: IThemeColorsUnion;
  size: string;
}>`
  width: ${(p) => p.size};
  height: ${(p) => p.size};
  border: 2px solid ${(p) => p.theme.colors[p.color]};
  border-radius: 50%;
`;

const $FilledCircle = styled.div<{
  color: IThemeColorsUnion;
  size: string;
  faded?: boolean;
}>`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: ${(p) => p.size};
  height: ${(p) => p.size};
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors[p.color]};
  position: absolute;
  opacity: ${(p) => (p.faded ? '0.7' : '')};
`;

interface ITradeSideIndicatorProps {
  tradeSide: IOrderSide;
  userSide?: IOrderSide | null;
}

const TradeSideIndicator = ({ tradeSide, userSide }: ITradeSideIndicatorProps) => {
  const userHasParticipatedInTrade = !!userSide;
  const color: IThemeColorsUnion = tradeSide; // 'buy' or 'sell'

  if (userHasParticipatedInTrade) {
    // User has participated in trade, show a dot surrounded by circle
    return (
      <$TradeSideIndicator>
        <$CircleOutline size={'10px'} color={color} />
        <$FilledCircle size={'3px'} color={color} />
      </$TradeSideIndicator>
    );
  }

  // This is someone else's trade or just an order, show a single dot
  return (
    <$TradeSideIndicator>
      <$FilledCircle size={'60%'} color={color} faded={true} />
    </$TradeSideIndicator>
  );
};

export default TradeSideIndicator;
