import {
  Action,
  Store as ReduxStore,
  applyMiddleware,
  createStore as createStoreNative,
} from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import thunk from 'redux-thunk/lib';

import { APIKEYS_STATE, IApiKeysState } from '../actions/api_keys';
import { APP_STATE, IAppState } from '../actions/app';
import { AUTH_STATE, IAuthState } from '../actions/auth/auth';
import { BALANCES_STATE, IBalancesState } from '../actions/balances';
import { COMPONENTS_STATE, IComponentsState } from '../actions/buy_sell';
import { DOCUMENT_STATE, IDocumentState } from '../actions/document';
import { EXCHANGE_STATE, IExchangeState } from '../actions/exchange';
import { IOrderbookState, ORDERBOOK_STATE } from '../actions/orderbook';
import {
  ACTIVE_COIN_ORDERS_STATE,
  IActiveInstrumentOrdersState,
} from '../actions/orders/active_instruments_orders';
import { IMarketTradesState, MARKET_TRADES_STATE } from '../actions/orders/market_trades';
import { IOrdersState, ORDERS_STATE } from '../actions/orders/orders';
import { IUserTradesState, USER_TRADES_STATE } from '../actions/orders/user_trades';
import { IRatesState, RATES_STATE } from '../actions/rates';
import { IRequestActiveState, REQUEST_ACTIVE_STATE } from '../actions/request_active';
import { IRoutingState, ROUTING_STATE } from '../actions/routing';
import { GRAPH_STATE, IGraphState } from '../actions/transactions/managed';
import { ITransactionsState, TRANSACTIONS_STATE } from '../actions/transactions/transactions';
import { ACCOUNT_ALERT_STATE, IAccountAlertState } from '../bl/account_alert';
import { EMAIL_VERIFICATION_STATE, IEmailVerificationState } from '../bl/email_verification';
import { ENV_STATE, IEnv, IEnvState, setEnvState } from '../bl/env';
import {
  IPhoneVerificationModalState,
  PHONE_VERIFICATION_MODAL_STATE,
} from '../bl/phone_verification';
import { IUserKeepAliveState, USER_KEEP_ALIVE_STATE } from '../bl/session_idle_timeout';
import { IUserActivityState, USER_ACTIVITY_STATE } from '../bl/user_activity_tracking';
import { DeepReadonly } from '../types/typescript_helpers';
import Container from './container';
import lodash from './lodash';

/**
 * Combined state from all actions
 */
export interface IState
  extends DeepReadonly<IEnvState>,
    DeepReadonly<IAccountAlertState>,
    DeepReadonly<IActiveInstrumentOrdersState>,
    DeepReadonly<IApiKeysState>,
    DeepReadonly<IAppState>,
    DeepReadonly<IAuthState>,
    DeepReadonly<IBalancesState>,
    DeepReadonly<IComponentsState>,
    DeepReadonly<IDocumentState>,
    DeepReadonly<IEmailVerificationState>,
    DeepReadonly<IExchangeState>,
    DeepReadonly<IGraphState>,
    DeepReadonly<IMarketTradesState>,
    DeepReadonly<IOrderbookState>,
    DeepReadonly<IOrdersState>,
    DeepReadonly<IRatesState>,
    DeepReadonly<IRequestActiveState>,
    DeepReadonly<IRoutingState>,
    DeepReadonly<ITransactionsState>,
    DeepReadonly<IUserActivityState>,
    DeepReadonly<IUserKeepAliveState>,
    DeepReadonly<IUserTradesState>,
    DeepReadonly<IPhoneVerificationModalState> {}

export type IStateGetter = () => IState;
export type IThunkDispatch = ThunkDispatch<IState, Container, Action>;
export type IThunkMethod<TReturnType = void> = (
  dispatch: IThunkDispatch,
  getState: IStateGetter,
  container: Container
) => TReturnType;

/**
 * Generate initial state
 */
export function initialState(): IState {
  return lodash.merge(
    {},
    ACCOUNT_ALERT_STATE,
    ACTIVE_COIN_ORDERS_STATE,
    APIKEYS_STATE,
    APP_STATE,
    AUTH_STATE,
    BALANCES_STATE,
    COMPONENTS_STATE,
    DOCUMENT_STATE,
    EMAIL_VERIFICATION_STATE,
    EXCHANGE_STATE,
    GRAPH_STATE,
    MARKET_TRADES_STATE,
    ORDERBOOK_STATE,
    ORDERS_STATE,
    RATES_STATE,
    REQUEST_ACTIVE_STATE,
    ROUTING_STATE,
    TRANSACTIONS_STATE,
    USER_TRADES_STATE,
    USER_ACTIVITY_STATE,
    USER_KEEP_ALIVE_STATE,
    ENV_STATE,
    PHONE_VERIFICATION_MODAL_STATE
  );
}

export interface IReduxAction {
  type: string;
}

export function reducer(state: IState | undefined, action: IReduxAction | any) {
  if (state === undefined) {
    // Setup the initial state
    state = initialState();
  } else {
    // Shallow copy previous state
    state = { ...state };
  }

  for (const key in action) {
    if (key !== 'type' && action.hasOwnProperty(key)) {
      if (key === '') {
        // The caller is saying basically "replace the entire state". So let's do it.
        state = action[key];
      } else {
        const path = key.split('.');
        deepSet(state, path, action[key]);
      }
    }
  }

  // Deeply mutated state at this point, where all the nested objects should be recreated
  return state;
}

/**
 * Deeply sets value at path. Path should be an array of keys.
 * The algorithm will shallow-copy all objects and arrays on the way down the "path".
 * @example
 *     deepSet({a: {b: {c: 1}}}, ['a', 'b', 'c'], 2)
 * This will change 1 to 2, but will also shallow copy all the objects on the way there.
 * Set undefined at key to delete the path entirely.
 */
export function deepSet(target: object, path: string[], value: any, pathIndex = 0): void {
  const key = path[pathIndex];
  if (pathIndex >= path.length - 1) {
    // There is no more path to follow. Set the value here
    if (value !== undefined) {
      target[key] = value;
    } else {
      delete target[key];
    }
    return;
  }

  // Continue going down the line
  if (lodash.isArray(target[key])) {
    target[key] = [...target[key]];
  } else if (lodash.isPlainObject(target[key])) {
    target[key] = { ...target[key] };
  } else if (lodash.isPlainObject(target) && target[key] === undefined) {
    // Carve the path by creating POJO-s, where there are none available
    target[key] = {};
  } else {
    // So the target is like a null or a class instance or something. We can't clone it.
    // We will log an error, but otherwise make no changes
    window.consoleError(
      `Trying to write to ${path.join('.')}, but target doesn't have ` +
        `a suitable receptor at ${key} (we ended search at: ${JSON.stringify(target)})`,
      target
    );
    return;
  }

  return deepSet(target[key], path, value, pathIndex + 1);
}

export function createStore(env: IEnv, middleware: any[], container: Container) {
  middleware = middleware || [];
  middleware.unshift(thunk.withExtraArgument(container));

  const store = createStoreNative(reducer, applyMiddleware(...middleware));

  // Load env into state, so other services can use it from there
  store.dispatch(setEnvState(env));

  return store;
}

export type Store = ReduxStore<IState>;
