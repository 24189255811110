import * as React from 'react';
import { KeyboardEvent } from 'react';

import Quantity from '../../lib/quantity';
import { Omit } from '../../types/typescript_helpers';
import { $Input, IInputProps } from './Input';

interface IProps extends IInputProps, Omit<React.InputHTMLAttributes<HTMLInputElement>, 'height'> {}

const CHAR_CODE_0 = 48;
const CHAR_CODE_9 = 57;
const CHAR_CODE_MINUS = 45;
const CHAR_CODE_DOT = 46;

class NumberInput extends React.PureComponent<IProps, { minQuantity?: Quantity }> {
  private element: React.RefObject<HTMLInputElement> = React.createRef();
  private animationFrameHandle: number = -1;

  constructor(props) {
    super(props);

    this.state = {
      minQuantity: Quantity.castOr(props.min, null),
    };
  }

  componentWillUpdate(
    nextProps: Readonly<IProps>,
    nextState: Readonly<{}>,
    nextContext: any
  ): void {
    this.setState({
      minQuantity: Quantity.castOr(nextProps.min, null),
    });
  }

  copyToClipboard = () => {
    this.element.current.select();
    document.execCommand('copy');
  };

  applyDelta(delta: number) {
    if (!this.element.current) {
      return;
    }

    const value = this.element.current.value;
    if (!value) {
      return;
    }

    const valueQty = Quantity.castOr(value, null);
    if (!valueQty) {
      return;
    }

    // Find the position of decimal dot
    let dotIndex = value.indexOf('.');
    if (dotIndex < 0) {
      // Whole number, dot index should actually be beyond the end
      dotIndex = value.length;
    }

    // Find position where to insert the new character
    let insertIndex = this.element.current.selectionStart;
    if (insertIndex === dotIndex + 1 && insertIndex !== value.length) {
      // If cursor at dot, insert ahead
      insertIndex--;
    }

    // We now want to generate a delta number based on dot index and insertion index
    //  134|64. -> (4, 7) -> +3 -> 100
    //  16.127|44 -> (7, 3) -> -4 -> 0.001

    const deltaQuantity = Quantity(10)
      .pow(dotIndex >= insertIndex ? dotIndex - insertIndex : dotIndex - insertIndex + 1)
      .multiply(delta);

    let newValue = valueQty.add(deltaQuantity).toString();

    if (newValue.length > value.length) {
      // If the value has grown, make sure our cursor tracks it to the right
      insertIndex++;
    } else if (newValue.length < value.length) {
      if (insertIndex > dotIndex) {
        const newDotIndex = newValue.indexOf('.');
        if (newDotIndex === -1 && dotIndex !== value.length) {
          // We have lost the ".". Restore it.
          newValue += '.';
        }

        let targetLength = value.length;
        if (value[0] === '-' && newValue[0] !== '-') {
          // We lost the -, so target length should be 1 less
          targetLength--;
        }

        while (newValue.length < targetLength) {
          newValue += '0';
        }
      }
    }

    window.cancelAnimationFrame(this.animationFrameHandle);
    this.animationFrameHandle = window.requestAnimationFrame(() => {
      this.element.current.value = newValue;
      this.element.current.selectionStart = insertIndex;
      this.element.current.selectionEnd = insertIndex;

      // Trigger onChange event
      const valueTracker = (this.element.current as any)._valueTracker;
      if (valueTracker) {
        valueTracker.setValue(value);
      }
      this.element.current.dispatchEvent(new Event('change', { bubbles: true }));
    });
  }

  handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'ArrowUp') {
      this.applyDelta(1);
      e.preventDefault();
    }
    if (e.key === 'ArrowDown') {
      this.applyDelta(-1);
      e.preventDefault();
    }
  };

  handleChange = (e) => {
    const value = e.target.value as string;
    const length = value.length;

    // Coerce value to look like a number
    const validChars = [];
    let seenDot = false;
    let seenDigit = false;
    let invalid = false;

    // Presume the change we make is removing an invalid character,
    // so we will try to move cursor left, to make it appear as it hasn't moved
    // NOTE: A bit hacky, but seems to work for our use case. It would fall apart if we changed multiple characters in onChange.
    let cursorDelta = -1;

    for (let i = 0; i < length; i++) {
      const cc = value.charCodeAt(i);
      if (cc === CHAR_CODE_MINUS) {
        // "-" is only valid at first spot
        if (i > 0) {
          invalid = true;
        } else {
          validChars.push(cc);
        }
      } else if (cc === CHAR_CODE_DOT) {
        if (seenDot) {
          // "." can only appear once
          invalid = true;
        } else if (!seenDigit && i > 0) {
          // "." can't come directly after -
          invalid = true;
        } else {
          if (i === 0) {
            // If dot is the first character, insert zero ahead
            validChars.push(CHAR_CODE_0);
            cursorDelta += 2;
            invalid = true;
          }
          validChars.push(cc);
          seenDot = true;
        }
      } else if (cc >= CHAR_CODE_0 && cc <= CHAR_CODE_9) {
        // digits are valid
        validChars.push(cc);
        seenDigit = true;
      } else {
        // other chars are invalid
        invalid = true;
      }
    }

    let mutatedValue;

    if (invalid) {
      // If invalid, correct the value directly in DOM
      mutatedValue = String.fromCharCode(...validChars);
    }

    if (this.state.minQuantity) {
      // Try to preform coercion
      if (Quantity.castOr(mutatedValue || value).lt(this.state.minQuantity)) {
        mutatedValue = this.state.minQuantity.toString();
      }
    }

    if (mutatedValue) {
      // Presume the change was adding an invalid character, so we will try to move cursor left, to make it appear as it hasn't moved
      const cursorPos = e.target.selectionStart + cursorDelta;

      e.target.value = mutatedValue;

      e.target.selectionStart = cursorPos;
      e.target.selectionEnd = cursorPos;
    }

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  render() {
    const { ...passProps } = this.props;

    return (
      <$Input
        ref={this.element}
        autoComplete="off"
        {...(passProps as any)}
        type="text"
        onKeyDown={this.handleKeyDown}
        onChange={this.handleChange}
      />
    );
  }
}

export default NumberInput;
