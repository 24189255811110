import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { IState } from '../../lib/store';
import { IWhitelabel } from '../../types/constants';
import ChyngexTOS from '../components/legal/ChyngexTOS';
import XcalibraTOS from '../components/legal/XcalibraTOS';

interface ITosPageProps extends ConnectedProps<typeof connector> {}
interface ITosPageState {}

class TosPage extends React.PureComponent<ITosPageProps, ITosPageState> {
  renderPage() {
    const TOSPages: { [key in IWhitelabel]: any } = {
      xcalibra: XcalibraTOS,
      chyngex: ChyngexTOS,
    };

    const Component = TOSPages[this.props.whitelabel];
    return <Component />;
  }

  render() {
    return this.renderPage();
  }
}

const connector = connect((state: IState) => ({
  whitelabel: state.env.whitelabel.name,
}));

export default connector(TosPage);
