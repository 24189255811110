import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { loadExchange, unloadExchange } from '../../actions/exchange';
import { IState } from '../../lib/store';
import { formatNumber } from '../../lib/util';
import { isUserLoggedIn } from '../../selectors/auth';
import { getCurrentPair, getLastPriceForCurrentPair } from '../../selectors/exchange';
import { IPair } from '../../types/backend_definitions';
import { II18nextT } from '../../types/i18n';
import { PRICE_DIGITS } from '../../types/instruments';
import { ITranslations } from '../../types/translations';
import ActiveInstrumentHorizontal from '../components/exchange/ActiveInstrumentHorizontal';
import ActiveInstrumentLeft from '../components/exchange/ActiveInstrumentLeft';
import ActiveInstrumentRight from '../components/exchange/ActiveInstrumentRight';
import BuySell from '../components/exchange/BuySell';
import ExchangeGrid from '../components/exchange/ExchangeGrid';
import LastTradesSwitcher from '../components/exchange/LastTradesSwitcher';
import OrderBook from '../components/exchange/OrderBook';
import TrollBox from '../components/exchange/TrollBox';
import UserActiveOrders from '../components/exchange/UserActiveOrders';
import PageSeoWrapper from '../widgets/PageSeoWrapper';

const instrumentImages = require.context('../../media/og_images/', true);

interface IOwnProps extends RouteComponentProps<{ pair?: IPair }> {}

interface IExchangePageProps extends IOwnProps, ConnectedProps<typeof connector> {}

interface IExchangePageState {}

class ExchangePage extends React.PureComponent<IExchangePageProps, IExchangePageState> {
  static contextType: any = I18nContext;

  componentDidMount() {
    this.props.loadExchange(this.props.pair);
  }

  componentWillUnmount() {
    this.props.unloadExchange();
  }

  componentDidUpdate(
    prevProps: Readonly<IExchangePageProps>,
    prevState: Readonly<IExchangePageState>,
    snapshot?: any
  ): void {
    if (
      this.props.match.params.pair !== prevProps.match.params.pair ||
      this.props.isLoggedIn !== prevProps.isLoggedIn
    ) {
      this.props.loadExchange(this.props.pair);
    }
  }

  setDocumentSEO(price, pair) {
    const { t }: II18nextT = this.context;
    const titlePrefix = price ? `${formatNumber(price, PRICE_DIGITS)} - ${pair}` : pair;
    const title = t([
      ('seo.title.exchange/' + pair) as ITranslations,
      'seo.title.exchange/SFX_BTC',
    ]);
    const description = t([
      ('seo.description.exchange/' + pair) as ITranslations,
      'seo.description.exchange/SFX_BTC',
    ]);
    const imageUrl = this.props.currentPair
      ? instrumentImages(`./${this.props.currentPair.quote.symbol.toLowerCase()}.svg`)
      : null;

    return {
      titlePrefix,
      title,
      description,
      imageUrl,
    };
  }

  render() {
    const { t }: II18nextT = this.context;

    if (!this.props.pair) {
      // Render nothing until we get the pair
      return null;
    }

    return (
      <PageSeoWrapper {...this.setDocumentSEO(this.props.lastPrice, this.props.pair)}>
        <ExchangeGrid
          activeInstrumentHorizontal={<ActiveInstrumentHorizontal />}
          lastTradesSwitcher={<LastTradesSwitcher />}
          activeInstrumentLeft={<ActiveInstrumentLeft />}
          buy={<BuySell side="buy" />}
          sell={<BuySell side="sell" />}
          activeInstrumentRight={<ActiveInstrumentRight />}
          userActiveOrders={<UserActiveOrders />}
          orderBookSell={<OrderBook side="sell" />}
          orderBookBuy={<OrderBook side="buy" />}
          trollBox={<TrollBox />}
        />
      </PageSeoWrapper>
    );
  }
}

const connector = connect(
  (state: IState, props: IOwnProps) => ({
    isLoggedIn: isUserLoggedIn(state),
    pair: props.match.params.pair.toUpperCase() as IPair,
    currentPair: getCurrentPair(state),
    lastPrice: getLastPriceForCurrentPair(state),
  }),
  {
    loadExchange,
    unloadExchange,
  }
);

export default withRouter(connector(ExchangePage));
