import { depositAddressesResponseTransformation } from '../../lib/api_response_transformations';
import Container from '../../lib/container';
import { handleError } from '../../lib/errors';
import {
  commit,
  getNextPageCriteria,
  mergePaginatedResults,
  updatePaginatedResult,
  val,
} from '../../lib/redux_helpers';
import { IState, IThunkMethod } from '../../lib/store';
import { getDepositsForInstrument } from '../../selectors/transactions';
import { IPaginated } from '../../types/api';
import { IApiDepositsForCustomerCriteria } from '../../types/backend_definitions';
import { IMarketInstrument } from '../../types/backend_definitions';
import { ALL_INSTRUMENTS_SYMBOLS, instrumentInfo } from '../../types/instruments';
import { IDeposit } from '../../types/transactions';
import { getSimpleNotificationData, notifyError, notifyInfo, notifySuccess } from '../app';
import { loadBalances } from '../balances';
import { loadingWrapper } from '../request_active';
import { IPendingDeposits, TRANSACTIONS_STATE } from './transactions';

const DEFAULT_PAGE_SIZE = 20;

export const updateDeposit = (deposit: IDeposit, completed = false): IThunkMethod => (
  dispatch,
  getState,
  { i18n }
) => {
  const oldDeposits = getDepositsForInstrument(getState(), deposit.instrument);
  const [updatedDeposits, isNewRecord] = updatePaginatedResult<IDeposit>(
    oldDeposits,
    deposit,
    'id'
  );

  const depositNotifyData = {
    ...deposit,
    instrumentName: instrumentInfo(deposit.instrument).name,
  };

  if (isNewRecord) {
    dispatch(
      notifyInfo(
        getSimpleNotificationData(
          i18n.t('transactions:deposits.notifications.depositInitialized', depositNotifyData)
        )
      )
    );

    return dispatch(
      commit(`Add new deposit for ${deposit.instrument}`, {
        transactions: {
          deposits: { [deposit.instrument]: val<IPaginated<IDeposit>>(updatedDeposits) },
        },
      })
    );
  }

  if (completed) {
    if (deposit.failure_code) {
      dispatch(
        notifyError(
          getSimpleNotificationData(
            i18n.t('transactions:deposits.notifications.depositFailed', depositNotifyData)
          )
        )
      );
    } else {
      dispatch(
        notifySuccess(
          getSimpleNotificationData(
            i18n.t('transactions:deposits.notifications.depositCompleted', depositNotifyData)
          )
        )
      );
      dispatch(loadBalances());
    }
  }

  return dispatch(
    commit(completed ? `Update completed deposit` : `Update existing deposit`, {
      transactions: {
        deposits: { [deposit.instrument]: val<IPaginated<IDeposit>>(updatedDeposits) },
      },
    })
  );
};

export const resetUserDeposits = () => (dispatch) => {
  dispatch(
    commit(`Reset user deposits state`, {
      transactions: {
        deposits: val<IState['transactions']['deposits']>(TRANSACTIONS_STATE.transactions.deposits),
      },
    })
  );
};

export const loadDepositsForInstrument = (
  instrument,
  firstPage = false
): IThunkMethod<Promise<void>> => (dispatch, getState, { api }) => {
  const oldDeposits = getDepositsForInstrument(getState(), instrument);
  const nextPageCriteria = getNextPageCriteria(oldDeposits, DEFAULT_PAGE_SIZE, firstPage);

  return dispatch(
    loadingWrapper(
      'loadDepositsForInstrument',
      api
        .getDeposits({
          // NOTE: If instrument !== 'all' we are loading data for specific instrument, otherwise we load all data,
          instruments: [instrument !== 'all' ? instrument : ''],
          sort_direction: 'desc',
          sort_field: ['created_at', 'id'],
          ...nextPageCriteria,
        } as IApiDepositsForCustomerCriteria)
        .then((newDeposits) => {
          const mergedDeposits = mergePaginatedResults<IDeposit, IDeposit>(
            newDeposits,
            oldDeposits,
            'id'
          );

          dispatch(
            commit(`Get user deposits for instrument "${instrument}"`, {
              transactions: {
                deposits: { [instrument]: val<IPaginated<IDeposit>>(mergedDeposits) },
              },
            })
          );
        })
        .catch((err) => dispatch(handleError(err)))
    )
  );
};

export const loadPendingDepositsForAllInstruments = () => (dispatch, _, { api }: Container) => {
  return api
    .getDeposits({ completed: false })
    .then((incomingDeposits) => {
      const deposits: IPendingDeposits = ALL_INSTRUMENTS_SYMBOLS.reduce((acc, cur) => {
        acc[cur] = 0;
        return acc;
      }, {});

      ALL_INSTRUMENTS_SYMBOLS.forEach((instrument) => {
        deposits[instrument] = incomingDeposits.items
          .filter((tx) => tx.instrument === instrument)
          // TODO: Use Quantity / BigNumber
          .reduce((a, c) => {
            a += parseFloat(c.quantity);
            return a;
          }, 0);
      });

      return dispatch(
        commit(`Get user deposits for all instruments`, {
          transactions: {
            pendingDeposits: val<IState['transactions']['pendingDeposits']>(deposits),
          },
        })
      );
    })
    .catch((err) => dispatch(handleError(err)));
};

export const loadDepositAddress = (instrument: IMarketInstrument) => (
  dispatch,
  _,
  { api, matomo }: Container
) => {
  matomo.sendEvent('Transfers', 'reveal_deposit_address', instrument as string);
  return api
    .putUsersAddressesIssue({ instrument })
    .then((response) => {
      dispatch(
        commit(`Get deposit address for ${instrument}`, {
          transactions: {
            depositAddresses: {
              [instrument]: val(response.address),
            },
          },
        })
      );
    })
    .catch((err) => dispatch(handleError(err)));
};

export const loadAllDepositAddress = () => (dispatch, _, { api }: Container) => {
  return api
    .getUsersAddresses()
    .then((response) => {
      dispatch(
        commit(`Get deposit addresses for all instruments`, {
          transactions: {
            depositAddresses: val<IState['transactions']['depositAddresses']>(
              depositAddressesResponseTransformation(response)
            ),
          },
        })
      );
    })
    .catch((err) => dispatch(handleError(err)));
};
