import * as React from 'react';
import { Link } from 'react-router-dom';

import R from '../../../lib/routes';
import styled from '../../../lib/styled_components';
import $Link from '../../widgets/Link';
import Logo from '../../widgets/Logo';
import MobileMenu from './MobileMenu';
import NavigationMenu from './NavigationMenu';
import UserMenu from './UserMenu';

const $TopWidgets = styled.div`
  height: 100%;
  width: 100%;
  background: ${(p) => p.theme.layout.header.background};
`;

const $Container = styled.nav`
  display: flex;
  justify-content: space-between;
  align-content: center;

  height: 100%;

  max-width: calc(${(p) => p.theme.layout.maxWidth}px - ${(p) => p.theme.layout.sidebarWidth}px);
  padding-right: ${(p) => p.theme.layout.mainGap}px;

  @media ${(p) => p.theme.device.mobile} {
    padding-left: ${(p) => p.theme.layout.mainGap}px;
  }
`;

const $Wrapper = styled.div`
  height: 100%;
`;

const $NavigationMenuWrapper = styled($Wrapper)`
  flex-grow: 1;
  display: flex;
  justify-content: left;
`;

export default () => (
  <$TopWidgets>
    <$Container>
      <$Wrapper className="show-on-mobile">
        <$Link as={Link} to={R.HOME}>
          <Logo margin="0 auto" />
        </$Link>
      </$Wrapper>

      <$NavigationMenuWrapper>
        <NavigationMenu />
      </$NavigationMenuWrapper>

      <MobileMenu />

      <$Wrapper className="hide-on-mobile">
        <UserMenu />
      </$Wrapper>
    </$Container>
  </$TopWidgets>
);
