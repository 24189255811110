import Container from '../../lib/container';
import { handleError } from '../../lib/errors';
import lodash from '../../lib/lodash';
import { commit, val } from '../../lib/redux_helpers';
import { IState, IThunkMethod } from '../../lib/store';
import { getSessions } from '../../selectors/auth';
import { ISessionInfo } from '../../types/auth';
import { getSimpleNotificationData, notifySuccess } from '../app';
import { loadingWrapper } from '../request_active';
import { logout } from './logout';

const setActiveSessions = (sessions: ISessionInfo[]) =>
  commit('List of active sessions is updated', {
    sessions: val<IState['sessions']>(lodash.orderBy(sessions, 'seen_at', ['desc'])),
  });

export const loadActiveSessions = (userUpdateInitiated: boolean) => (
  dispatch,
  _,
  { api, i18n }: Container
) => {
  return dispatch(
    loadingWrapper(
      'getActiveSessions',
      api
        .getAuthSessions()
        .then((sessions) => {
          // notify user about terminated sessions
          userUpdateInitiated &&
            dispatch(
              notifySuccess(
                getSimpleNotificationData(i18n.t('settings:security.activeSessionsUpdated'))
              )
            );
          return dispatch(setActiveSessions(sessions));
        })
        .catch((err) => {
          dispatch(handleError(err));
        })
    )
  );
};

const deleteSessions = (sessionsToDelete: string[]): IThunkMethod => (
  dispatch,
  getState,
  { api, localStorage, i18n }
) => {
  return dispatch(
    loadingWrapper(
      'deleteSessions',
      api
        .deleteAuthSessions({ access_tokens: sessionsToDelete })
        .then((deletedSessions) => {
          const storeSessions = getSessions(getState());

          // log out current user if active session is picked for removal
          if (deletedSessions[localStorage.accessToken]) {
            return dispatch(logout());
          }

          // filter out deleted sessions from store
          const newSessionList = storeSessions.filter(
            (storeSession) => !deletedSessions[storeSession.access_token]
          );

          // notify user about terminated sessions
          dispatch(
            notifySuccess(
              getSimpleNotificationData(
                sessionsToDelete.length > 1
                  ? i18n.t('settings:security.activeSessionsDeletedMany')
                  : i18n.t('settings:security.activeSessionsDeletedOne')
              )
            )
          );

          return dispatch(setActiveSessions(newSessionList));
        })
        .catch((err) => {
          dispatch(handleError(err));
        })
    )
  );
};

export const deleteAllButCurrentSession = (): IThunkMethod => (
  dispatch,
  getState,
  { localStorage }
) => {
  const sessions = getSessions(getState());

  const sessionsToDelete = sessions
    .filter((session) => session.access_token !== localStorage.accessToken)
    .map((session) => session.access_token);

  return dispatch(deleteSessions(sessionsToDelete));
};

export const deleteSession = (session: string) => deleteSessions([session]);
