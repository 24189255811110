import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { setBuySellValues } from '../../../actions/buy_sell';
import { IState } from '../../../lib/store';
import styled, { IThemeColorsUnion } from '../../../lib/styled_components';
import { eventMuter, formatNumberToFixed, formatPercentage, hex2rgba } from '../../../lib/util';
import {
  calculateVerticalCandleRect,
  getChangePercentForPriceSummaryPair,
  getCurrentPair,
  getDirectionColor,
  getPriceSummary24hForCurrentPair,
} from '../../../selectors/exchange';
import { II18nextT } from '../../../types/i18n';
import { PRICE_DIGITS } from '../../../types/instruments';
import { ITheme } from '../../../types/theme';
import Arrow from '../../widgets/Arrow';
import PrettyDecimals from '../../widgets/PrettyDecimals';

const ARROW_SIZE = '5px';
const INFO_BOX_PADDING = '0.2rem';
const labelSize = (theme: ITheme) => theme.fontSize.smaller;
const priceSize = (theme: ITheme) => theme.fontSize.larger;

/**
 * Generates the calc for info box midpoint
 */
const infoBoxMiddleCalc = (theme: ITheme) => {
  return `(${labelSize(theme)} + ${priceSize(theme)} + ${INFO_BOX_PADDING}) / 2`;
};

const $ActiveInstrumentRight = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: stretch;
  overflow: hidden;
`;

const $Content = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  // Give it just a little bit space, but not too much to cause numbers to break
  // Ideally, we would center this horizontally, but I am afraid it might start jumping left-right
  // if prices start changing
  padding-left: 0.5rem;

  // Align this with ActiveInstrumentLeft
  padding-top: 1rem;
  padding-bottom: 0.5rem;
`;

const $Candle = styled.div`
  width: 7px;
  position: relative;
  top: calc(${(p) => infoBoxMiddleCalc(p.theme)});
  height: calc(100% - ${(p) => infoBoxMiddleCalc(p.theme)} * 2);
`;

const $CandleHighLow = styled.div<{ color: IThemeColorsUnion }>`
  width: 1px;
  left: 3px;
  background: ${(p) => p.theme.colors[p.color]};
  height: 100%;
  position: absolute;
`;

const $CandleOpenClose = styled.div<{ color: IThemeColorsUnion; height: string; top: string }>`
  width: 7px;
  height: ${(p) => p.height};
  top: ${(p) => p.top};
  position: absolute;
  background: ${(p) => p.theme.colors[p.color]};
`;

const $Info = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: 0.5rem;
  justify-content: space-between;
`;

const $InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > :first-child {
    width: ${ARROW_SIZE};
    flex-grow: 0;
    margin-right: 0.5rem;
  }
  color: ${(p) => p.theme.colors.white};
`;

const $EllipsisWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 13rem;

  @media screen and ${(p) => p.theme.device.laptop} {
    width: 12rem;
  }
`;

const $Label = styled.h5`
  margin: 0 !important;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.grayLighter};
  font-size: ${(p) => labelSize(p.theme)};
`;
const $Price = styled.a`
  display: inline-block;
  margin: ${INFO_BOX_PADDING} 0 0 0 !important;
  text-transform: uppercase;
  font-size: ${(p) => priceSize(p.theme)} !important;
  font-weight: bold;
  text-decoration: none;

  :hover {
    background-color: ${(p) => hex2rgba(p.theme.colors.brandPrimary, 0.5)};
  }
  :active {
    background-color: ${(p) => p.theme.colors.brandPrimary};
  }
`;
const $Percentage = styled.h5<{ color: IThemeColorsUnion }>`
  margin: ${INFO_BOX_PADDING} 0 0 0 !important;
  font-size: ${(p) => p.theme.fontSize.base} !important;
  color: ${(p) => p.theme.colors[p.color]};
`;

const InfoItem: React.FunctionComponent<{
  label: string;
  color?: IThemeColorsUnion;
  noArrow?: boolean;
}> = ({ label, color = 'white', noArrow = false, children }) => {
  return (
    <$InfoItem>
      <div>{!noArrow && <Arrow direction="left" color={color} size={ARROW_SIZE} />}</div>
      <$EllipsisWrapper>
        <$Label>{label}</$Label>
        {children}
      </$EllipsisWrapper>
    </$InfoItem>
  );
};

interface IActiveInstrumentRightProps extends ConnectedProps<typeof connector> {}

class ActiveInstrumentRight extends React.PureComponent<IActiveInstrumentRightProps> {
  static contextType: any = I18nContext;
  setPrice = (price) => {
    this.props.setBuySellValues({ buy: { price }, sell: { price } });
  };

  render() {
    const { pair, summary24h } = this.props;
    const { t }: II18nextT = this.context;

    if (!pair || !summary24h) {
      return null;
    }

    const directionColor = getDirectionColor(summary24h);
    const candleRect = calculateVerticalCandleRect(summary24h);

    return (
      <$ActiveInstrumentRight>
        <$Content>
          <$Candle>
            <$CandleHighLow color={directionColor} />
            <$CandleOpenClose color={directionColor} {...candleRect} />
          </$Candle>

          <$Info>
            <InfoItem label={t('activeInstrument.high24h')}>
              <$Price
                title={`${formatNumberToFixed(summary24h.high, PRICE_DIGITS)}`}
                href="#"
                onClick={eventMuter(() => this.setPrice(summary24h.high))}
              >
                <PrettyDecimals value={formatNumberToFixed(summary24h.high, PRICE_DIGITS)} />
              </$Price>
            </InfoItem>

            <InfoItem label={t('activeInstrument.currentPrice')} color={directionColor} noArrow>
              <$Price
                title={`${formatNumberToFixed(summary24h.close, PRICE_DIGITS)}`}
                href="#"
                onClick={eventMuter(() => this.setPrice(summary24h.close))}
              >
                <PrettyDecimals
                  value={formatNumberToFixed(summary24h.close, PRICE_DIGITS)}
                  color={directionColor}
                />
              </$Price>
              <$Percentage color={directionColor}>
                {formatPercentage(getChangePercentForPriceSummaryPair(summary24h), 2)}
              </$Percentage>
            </InfoItem>

            <InfoItem label={t('activeInstrument.low24h')}>
              <$Price
                title={`${formatNumberToFixed(summary24h.low, PRICE_DIGITS)}`}
                href="#"
                onClick={eventMuter(() => this.setPrice(summary24h.low))}
              >
                <PrettyDecimals value={formatNumberToFixed(summary24h.low, PRICE_DIGITS)} />
              </$Price>
            </InfoItem>
          </$Info>
        </$Content>
      </$ActiveInstrumentRight>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    pair: getCurrentPair(state),
    summary24h: getPriceSummary24hForCurrentPair(state),
  }),
  {
    setBuySellValues,
  }
);

export default connector(ActiveInstrumentRight);
