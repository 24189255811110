import { tradeResponseTransformation } from '../../lib/api_response_transformations';
import lodash from '../../lib/lodash';
import { commit, val } from '../../lib/redux_helpers';
import { IState, IStateGetter, IThunkMethod } from '../../lib/store';
import { flattenMilliseconds } from '../../lib/util';
import { getCurrentPairPath } from '../../selectors/exchange';
import { IApiPublicTradeInfo } from '../../types/backend_definitions';
import { LIST_SIZE_LIMIT } from '../../types/constants';
import { ITrade } from '../../types/trades';

// flatten timestamp for sorting by price trades processed at the same second
const tradeTimestampTransformation = (trade: ITrade): ITrade => ({
  ...trade,
  timestamp: flattenMilliseconds(trade.timestamp),
});

export interface IMarketTradesState {
  marketTradesCurrentInstrumentPair: { [key: string]: ITrade };
}

export const MARKET_TRADES_STATE: IMarketTradesState = {
  marketTradesCurrentInstrumentPair: {},
};

let pendingTrades: ITrade[] = [];
const processMarketTradesThrottled = lodash.throttle((dispatch, getState: IStateGetter) => {
  if (!pendingTrades.length) {
    // Nothing to do
    return;
  }

  const state = getState();
  const storeTradesLookup = { ...state.marketTradesCurrentInstrumentPair };
  const pendingTradesLookup = {};

  const currentPairPath = getCurrentPairPath(state);
  for (const trade of pendingTrades) {
    if (trade.pair === currentPairPath) {
      const includedTrade: ITrade = storeTradesLookup[trade.signature];
      const pendingTrade = pendingTradesLookup[trade.signature];

      // handle if trade is already processed
      if ((pendingTrade && pendingTrade.user_side) || (includedTrade && includedTrade.user_side)) {
        // user trade has precedence over public trade
        continue;
      }

      if (includedTrade) {
        // if public trade exists remove it from store
        delete storeTradesLookup[trade.signature];
      }

      pendingTradesLookup[trade.signature] = trade;
    }
  }

  pendingTrades = [];
  const pendingTradesList = (Object.values(pendingTradesLookup) as ITrade[]).map(
    tradeTimestampTransformation
  );

  if (!pendingTradesList.length) {
    return;
  }

  // last 100 trades, sorted
  const mergedTradesSortedList: ITrade[] = lodash
    .orderBy(
      [...pendingTradesList, ...Object.values(storeTradesLookup)],
      ['timestamp', 'price'],
      ['desc', 'desc']
    )
    .slice(0, LIST_SIZE_LIMIT);

  const tradesLookup = mergedTradesSortedList.reduce((lookup, trade) => {
    lookup[trade.signature] = trade;
    return lookup;
  }, {});

  dispatch(
    commit(
      `Update market trades with ${pendingTradesList.length} new market trades that came through socket (throttled)`,
      {
        marketTradesCurrentInstrumentPair: val<IState['marketTradesCurrentInstrumentPair']>(
          tradesLookup
        ),
      }
    )
  );
}, 500);

export const processNewMarketTrade = (newTrade: ITrade): IThunkMethod => (dispatch, getState) => {
  pendingTrades.unshift(newTrade);
  processMarketTradesThrottled(dispatch, getState);
};

export const submitMarketTrades = (marketTrades: IApiPublicTradeInfo[]) => (dispatch) => {
  const marketTradesFormatted = lodash.orderBy(
    marketTrades.map((trade) => tradeTimestampTransformation(tradeResponseTransformation(trade))),
    ['timestamp', 'price'],
    ['desc', 'desc']
  );

  const tradesLookup = marketTradesFormatted.reduce((lookup, trade) => {
    lookup[trade.signature] = trade;

    return lookup;
  }, {});

  return dispatch(
    commit(`Update market trades for current instrument pair`, {
      marketTradesCurrentInstrumentPair: val<IState['marketTradesCurrentInstrumentPair']>(
        tradesLookup
      ),
    })
  );
};
