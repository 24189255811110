import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import Select from 'react-select';
import { FormatOptionLabelMeta } from 'react-select/lib/Select';

import { COUNTRIES, ICountries, IUSStates, US_STATES } from '../../lib/countries';
import { I18n } from '../../lib/i18n';
import { IState } from '../../lib/store';
import styled, { ThemeContext } from '../../lib/styled_components';
import { hex2rgba } from '../../lib/util';
import { getCountryBlacklist } from '../../selectors/auth';
import { ICountryCode, IUSStateCode } from '../../types/backend_definitions';
import { CURRENCY_TO_COUNTRY_ISO } from '../../types/constants';
import { ITheme } from '../../types/theme';
import { DeepReadonly } from '../../types/typescript_helpers';
import CountryFlag from './CountryFlag';
import CountryLabel from './CountryLabel';
import GenericTooltip from './tooltips/GenericTooltip';

// *********************************************************************************************************************

export interface ISelectOption<T> {
  value: T;
  label: string;
}

type SelectVariant = 'dark' | 'transparent' | 'managed';

interface IResponsiveSelectWrapper {
  iphoneWidth?: string;
  defaultWidth?: string;
}

const $ResponsiveSelectWrapper = styled.div<IResponsiveSelectWrapper>`
  @media screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    > div {
      > div {
        width: ${(p) =>
          p.defaultWidth ? p.defaultWidth : p.theme.widgets.select.defaultNormalWidth};
        @media ${(p) => p.theme.device.mobile_IphonePlus} {
          width: ${(p) =>
            p.iphoneWidth ? p.iphoneWidth : p.theme.widgets.select.defaultIphoneWidth};
        }
      }
    }
  }
`;

export class ResponsiveSelectWrapper extends React.Component<IResponsiveSelectWrapper> {
  static defaultProps: IResponsiveSelectWrapper = {
    iphoneWidth: '6rem',
  };
  render() {
    return <$ResponsiveSelectWrapper {...this.props} />;
  }
}

function generateStyle(
  theme: ITheme,
  variant: SelectVariant,
  styleProps: {
    width?: string;
    centered?: boolean;
    hasError?: boolean;
    borderless?: boolean;
    disabled?: boolean;
  } = {}
) {
  const themeVariants: { [key in SelectVariant]: any } = {
    managed: {
      background: `rgba(255,255,255,0.1)`,
      height: '45px',
      borderRadius: '3px',
      border: theme.widgets.input.dark.border,
      borderFocusColor: theme.widgets.input.dark.borderFocus,
      color: theme.widgets.input.dark.placeholderColor,
      fontSize: theme.widgets.input.dark.fontSize,
      dropdownIndicator: {
        background: `linear-gradient(to left top, rgb(18, 193, 236), rgb(0, 167, 197), rgb(37, 219, 184)), linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1))`,
      },
      option: {
        background: theme.widgets.input.dark.optionHover,
        backgroundFocus: theme.colors.brandPrimary,
        backgroundSelected: theme.colors.brandPrimaryLight,
        backgroundFocusSelected: theme.widgets.input.dark.optionActiveHover,
        backgroundDisabled: theme.widgets.input.dark.backgroundDisabled,
      },
    },
    dark: {
      background: theme.widgets.input.dark.background,
      border: theme.widgets.input.dark.border,
      borderFocusColor: theme.widgets.input.dark.borderFocus,
      color: theme.widgets.input.dark.placeholderColor,
      fontSize: theme.widgets.input.dark.fontSize,
      dropdownIndicator: {
        background: `linear-gradient(to left top, rgb(18, 193, 236), rgb(0, 167, 197), rgb(37, 219, 184)), linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1))`,
      },
      option: {
        background: theme.widgets.input.dark.optionHover,
        backgroundFocus: theme.colors.brandPrimary,
        backgroundSelected: theme.colors.brandPrimaryLight,
        backgroundFocusSelected: theme.widgets.input.dark.optionActiveHover,
        backgroundDisabled: theme.widgets.input.dark.backgroundDisabled,
      },
    },
    transparent: {
      background: 'transparent',
      border: styleProps.borderless ? 'none' : theme.widgets.input.transparent.border,
      borderFocusColor: 'none',
      color: 'white',
      fontSize: theme.widgets.input.light.fontSize,
      textAlign: 'left',
      height: '100%',
      dropdownIndicator: {
        background: 'none',
        top: '0',
      },
      option: {
        background: theme.colors.headerColor,
        backgroundFocus: theme.colors.sidebarColor,
        backgroundSelected: theme.widgets.input.dark.optionActiveHover,
        backgroundFocusSelected: theme.widgets.input.dark.optionActiveHover,
        backgroundDisabled: theme.widgets.input.transparent.backgroundDisabled,
      },
    },
  };
  const centeredValue = styleProps.centered && { paddingLeft: '20px', textAlign: 'center' };
  const variantTheme = themeVariants[variant];

  return {
    control: (base, { isFocused }) => ({
      ...base,
      width: styleProps.width,
      fontSize: variantTheme.fontSize,
      height: variantTheme.height || '35px',
      minHeight: '35px',
      background: styleProps.disabled
        ? hex2rgba(variantTheme.background, 0.5)
        : variantTheme.background,
      border: variantTheme.border,
      outline: styleProps.hasError && `1px solid ${theme.colors.error}`,
      borderRadius: variantTheme.borderRadius || 0,
      boxShadow: null,
      '&:hover': {
        borderColor: isFocused && variantTheme.borderFocusColor,
      },
      textAlign: variantTheme.textAlign || null,
      cursor: variantTheme.cursor || null,
      filter: styleProps.disabled ? 'saturate(0.8)' : undefined,
    }),
    option: (base, { isFocused, isSelected, isDisabled }) => ({
      ...base,
      background:
        (isDisabled && variantTheme.option.backgroundDisabled) ||
        (isSelected && isFocused && variantTheme.option.backgroundFocusSelected) ||
        (isSelected && variantTheme.option.backgroundSelected) ||
        (isFocused && variantTheme.option.backgroundFocus) ||
        variantTheme.option.background,
      color: 'white',
      '&:active': {
        background: isDisabled ? variantTheme.option.backgroundDisabled : theme.colors.brandPrimary,
      },
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    }),
    placeholder: (base) => ({
      ...base,
      ...centeredValue,
      opacity: 0.7,
      width: 'calc(100% - 36px)',
      color: styleProps.disabled ? hex2rgba(variantTheme.color, 0.5) : variantTheme.color,
    }),
    input: (base) => ({
      ...base,
      ...centeredValue,
      width: 'calc(100% - 36px)',
      color: styleProps.disabled ? hex2rgba(variantTheme.color, 0.5) : variantTheme.color,
    }),
    singleValue: (base) => ({
      ...base,
      ...centeredValue,
      width: 'calc(100% - 36px)',
      color: styleProps.disabled ? hex2rgba(variantTheme.color, 0.5) : variantTheme.color,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: variantTheme.color,
      position: 'absolute',
      right: '-1px',
      top: '-1px',
      height: '106%',
      '&:hover': {
        color: 'white',
      },
      alignItems: 'center',
      opacity: styleProps.disabled ? 0.5 : 1,
      ...variantTheme.dropdownIndicator,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      fontSize: theme.fontSize.base,
      maxHeight: '175px',
      // kill the white space on first and last option
      padding: 0,
      // font color
      color: theme.colors.grayDarker,
    }),
  };
}

// *********************************************************************************************************************

interface IInputSelectProps {
  onChange: (any) => void;
  value: Object;
  options: Object[];
  variant: SelectVariant;
  width?: string;
  hasError?: boolean;
}

export default class InputSelect extends React.PureComponent<IInputSelectProps, {}> {
  static contextType: any = ThemeContext;

  render() {
    const { value, variant, options, onChange, width, hasError } = this.props;
    return (
      <Select
        styles={generateStyle(this.context, variant, { width, hasError })}
        {...{ value, options, onChange }}
        placeholder={''}
      />
    );
  }
}

// *********************************************************************************************************************

interface ISimpleSelectProps {
  inputId?: string;
  onChange: (any) => void;
  value: string;
  variant: SelectVariant;
  options: Object[];
  width?: string;
  hasError?: boolean;
  centered?: boolean;
  borderless?: boolean;
  disabled?: boolean;
  formatOptionLabel?: <T>(option: T, labelMeta: FormatOptionLabelMeta<T>) => React.ReactNode;
}

export class SimpleSelect extends React.PureComponent<ISimpleSelectProps, {}> {
  static contextType: any = ThemeContext;
  render() {
    const {
      inputId,
      value,
      variant,
      options,
      width,
      onChange,
      formatOptionLabel,
      centered,
      hasError,
      borderless,
      disabled,
    } = this.props;

    return (
      <Select
        inputId={inputId}
        classNamePrefix="Select"
        isSearchable={false}
        styles={generateStyle(this.context, variant, {
          width,
          centered,
          hasError,
          borderless,
          disabled,
        })}
        {...{ value, options, onChange, formatOptionLabel }}
        placeholder={''}
        isDisabled={disabled}
      />
    );
  }
}

// *********************************************************************************************************************

export interface IOptionProps {
  label: string;
  value: string;
  isDisabled?: boolean;
  disabledOptionMessage: string;
}
interface ISimplexSelectProps {
  inputId?: string;
  onChange: (any) => void;
  value: IOptionProps;
  variant: SelectVariant;
  width?: string;
  options: IOptionProps[];
  for: 'instrument' | 'currency';
  borderless?: boolean;
}

export class SimplexSelect extends React.PureComponent<ISimplexSelectProps, {}> {
  static contextType: any = ThemeContext;

  getOptionLabel = (option: IOptionProps) => (
    <I18n>
      {(t) => (
        <GenericTooltip placement="bottom" overlay={option.disabledOptionMessage} variant="error">
          <span
            className="text-nowrap"
            style={{ fontSize: '1.2rem', display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            {this.props.for === 'currency' && (
              <CountryFlag isoCode={CURRENCY_TO_COUNTRY_ISO[option.value]} />
            )}
            &nbsp;
            <span className="ml-1"> {option.label} </span>
          </span>
        </GenericTooltip>
      )}
    </I18n>
  );

  render() {
    const { inputId, value, variant, options, onChange, width, borderless } = this.props;

    return (
      <Select
        inputId={inputId}
        isSearchable={false}
        // @ts-ignore
        getOptionLabel={this.getOptionLabel}
        styles={generateStyle(this.context, variant, { width: width || '90px', borderless })}
        {...{ value, options, onChange }}
        placeholder={''}
      />
    );
  }
}

// *********************************************************************************************************************

interface ICountrySelectComponentProps extends ConnectedProps<typeof connector> {
  inputId?: string;
  isoCode: string;
  variant: SelectVariant;
  placeholder?: string;
  background?: string;
  border?: string;
  centered?: boolean;
  disabled?: boolean;
  onChange: (any) => void;
}

class CountrySelectComponent extends React.PureComponent<ICountrySelectComponentProps> {
  static contextType: any = ThemeContext;

  getCountryLabel = (option) => <CountryLabel label={option.label} isoCode={option.value} />;

  filterOption = (option, filter) => {
    filter = filter.toLowerCase();
    return (
      option &&
      (option.value.toLowerCase() === filter ||
        option.data.label.toLowerCase().indexOf(filter) >= 0)
    );
  };

  private optionsSelector = (countryBlacklist: DeepReadonly<ICountryCode[]>) => {
    const result: ICountries[] = [];
    for (const key in COUNTRIES) {
      if (COUNTRIES.hasOwnProperty(key)) {
        if (!countryBlacklist || !countryBlacklist.includes(key as ICountryCode)) {
          result.push(COUNTRIES[key]);
        }
      }
    }
    return result;
  };

  render() {
    const {
      inputId,
      isoCode,
      variant,
      onChange,
      placeholder,
      centered,
      countryBlacklist,
    } = this.props;

    const disabled = !countryBlacklist || this.props.disabled;
    const options = this.optionsSelector(countryBlacklist);

    return (
      <Select
        isDisabled={disabled}
        value={COUNTRIES[isoCode]}
        options={options}
        // @ts-ignore
        getOptionLabel={this.getCountryLabel}
        {...{ inputId, onChange, placeholder }}
        filterOption={this.filterOption}
        styles={generateStyle(this.context, variant, { centered, disabled })}
      />
    );
  }
}

const connector = connect((state: IState) => ({
  countryBlacklist: getCountryBlacklist(state),
}));

export const CountrySelect = connector(CountrySelectComponent);

interface IStateSelectComponentProps extends ConnectedProps<typeof stateConnector> {
  inputId?: string;
  isoCode: string;
  variant: SelectVariant;
  placeholder?: string;
  background?: string;
  border?: string;
  centered?: boolean;
  onChange: (any) => void;
  disabled?: boolean;
}

class StateSelectComponent extends React.PureComponent<IStateSelectComponentProps> {
  static contextType: any = ThemeContext;

  getCountryLabel = (option) => <CountryLabel label={option.label} isoCode={option.value} />;

  filterOption = (option, filter) => {
    filter = filter.toLowerCase();
    return (
      option &&
      (option.value.toLowerCase() === filter ||
        option.data.label.toLowerCase().indexOf(filter) >= 0)
    );
  };

  private optionsSelector = (stateBlacklist: DeepReadonly<IUSStateCode[]>) => {
    const result: IUSStates[] = [];
    for (const key in US_STATES) {
      if (US_STATES.hasOwnProperty(key)) {
        if (!stateBlacklist || !stateBlacklist.includes(key as IUSStateCode)) {
          result.push(US_STATES[key]);
        }
      }
    }
    return result;
  };

  render() {
    const {
      inputId,
      isoCode,
      variant,
      onChange,
      placeholder,
      centered,
      stateBlacklist,
      disabled: disabledProp,
    } = this.props;
    const options = this.optionsSelector(stateBlacklist);

    const disabled = disabledProp || !stateBlacklist;

    return (
      <Select
        isDisabled={disabled}
        value={US_STATES[isoCode]}
        options={options}
        {...{ inputId, onChange, placeholder }}
        filterOption={this.filterOption}
        styles={generateStyle(this.context, variant, { centered, disabled })}
      />
    );
  }
}

const stateConnector = connect((state: IState) => ({
  stateBlacklist: [],
}));

export const StateSelect = stateConnector(StateSelectComponent);
