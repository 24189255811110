import moment from 'moment';
import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { getQuote, requestPayment } from '../../../actions/transactions/card_payments';
import { handleError } from '../../../lib/errors';
import lodash from '../../../lib/lodash';
import Quantity from '../../../lib/quantity';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { formatNumberToFixed } from '../../../lib/util';
import simplexImage from '../../../media/simplex.png';
import { RefreshIcon, WarningIcon } from '../../../media/svg_icons';
import supportedCardsImage from '../../../media/visa-master.png';
import {
  getSystemTimeDrift,
  getSystemTimeError,
  systemTimeDriftToUTCMoment,
} from '../../../selectors/app';
import { isUserLoggedIn } from '../../../selectors/auth';
import { getCardPaymentConfig } from '../../../selectors/card_payments';
import { getConversionRate, getRates } from '../../../selectors/rates';
import {
  ICardPaymentCryptoInstrument,
  ICardPaymentFiatInstrument,
} from '../../../types/backend_definitions';
import { TimeUnits } from '../../../types/constants';
import { II18nextT } from '../../../types/i18n';
import { ALL_INSTRUMENTS } from '../../../types/instruments';
import { ITranslations } from '../../../types/translations';
import { $GreenLightButton } from '../../widgets/buttons';
import { $Icon } from '../../widgets/Icon';
import $Label from '../../widgets/Label';
import Modal from '../../widgets/Modal';
import NumberInput from '../../widgets/NumberInput';
import { SimplexSelect } from '../../widgets/Select';

const $BuyWithCardModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;

  @media screen and ${(p) => p.theme.device.mobile} {
    max-width: 350px;
    margin: 0 auto;
    width: 100%;
  }
`;

const $BuyWithCardWrapper = styled.div<{ boxShadow?: boolean }>`
  width: 350px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  box-shadow: ${(p) => p.boxShadow && p.theme.layout.overlayBoxShadow};
  background: ${(p) => p.boxShadow && p.theme.components.cardPayment.background};
  border-radius: ${(p) => p.boxShadow && '6px'};

  @media screen and ${(p) => p.theme.device.mobile} {
    max-width: 350px;
    width: 100%;
  }
`;

const $ContinueButton = styled($GreenLightButton)`
  height: 35px;
  font-size: 1.2rem;
  margin: 0 auto;
  margin-top: 2.5rem;
  width: 140px;
`;

const $RedirectNotice = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

const $PartnerReference = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
  padding: 1.6rem 2rem;
  background-color: #1336671f;
`;

const $AcceptedCardsWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 20px;
    margin-left: 0.5rem;
  }
`;

const $PoweredByWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 25px;
    margin-left: 0.5rem;
  }
`;

const $Placeholder = styled.div`
  margin-top: 0.6rem;
  padding-left: 1px;
  min-height: 1.1rem;
`;

const $Countdown = styled.span`
  position: absolute;
  right: 0;
  color: ${(p) => p.theme.components.cardPayment.inactiveCurrencyColor};
  text-transform: lowercase;

  svg {
    margin-right: 0.5rem;
  }
`;

const $FieldAmountLimits = styled('div')<{ active: boolean }>`
  color: ${(p) => (p.active ? p.theme.colors.white : p.theme.colors.grayLightest)};
`;

const $BaseInput = styled.div`
  background: ${(p) => p.theme.components.withdraw.inputWrapperBackground};
  border-radius: ${(p) => p.theme.components.withdraw.borderRadius};
  padding: 10px 60px 10px 12px;
`;

const $CryptoInputWrapper = styled($BaseInput)<{ updating: boolean; validationError: boolean }>`
  background-color: ${(p) =>
    (p.updating && p.theme.components.cardPayment.inputFieldUpdateBackground) ||
    (p.validationError && p.theme.components.cardPayment.invalidInputFieldBackground) ||
    ''};
  transition: background-color 0.5s;
`;

const $CryptoWrapper = styled.div`
  position: relative;
  margin-top: 10px;
`;

const $FiatWrapper = styled.div`
  position: relative;
  margin-top: 10px;
`;

const $FiatInputWrapper = styled($BaseInput)<{ updating: boolean; validationError?: boolean }>`
  padding-right: 100px;
  background-color: ${(p) =>
    (p.updating && p.theme.components.cardPayment.inputFieldUpdateBackground) ||
    (p.validationError && p.theme.components.cardPayment.invalidInputFieldBackground) ||
    ''};
  transition: background-color 1s;
`;

const $InstrumentSwitcher = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
`;

const $FormLabel = styled($Label)`
  position: relative;
  margin-top: 2.5rem;
  font-size: ${(p) => p.theme.fontSize.larger};
  font-weight: bold;
  letter-spacing: 1px;
`;

const $CryptoInstrument = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50px;
  top: 0;
  right: 0;
  font-size: 1.2rem;
`;

const $CheckClockIconWrapper = styled.div`
  display: inline;
  margin-right: 4px;
`;

interface IBuyWithCardFields {
  cryptoAmount: string;
  fiatAmount: string;
}
export type IBuyWithCardFormField = keyof IBuyWithCardFields;

export const BUY_WITH_CARD_FIELD_NAME: { [key in IBuyWithCardFormField]: key } = {
  cryptoAmount: 'cryptoAmount',
  fiatAmount: 'fiatAmount',
};
const OPPOSITE_FORM_FIELDS: { [key in IBuyWithCardFormField]: IBuyWithCardFormField } = {
  cryptoAmount: 'fiatAmount',
  fiatAmount: 'cryptoAmount',
};

export const BUY_WITH_CARD_INITIAL_VALUE: {
  cryptoInstrument: ICardPaymentCryptoInstrument;
  fiatAmount: string;
  fiatInstrument: ICardPaymentFiatInstrument;
} = {
  cryptoInstrument: 'BTC',
  fiatInstrument: 'USD',
  fiatAmount: '125',
};

type ILimits = { [key in IBuyWithCardFormField]: { min: number; max: number } };
interface ICorrector {
  isCryptoAmount: boolean;
  isMin: boolean;
  newLimit: number;
}

interface IBuyWithCardProps extends ConnectedProps<typeof connector> {
  ref?: any;
  cryptoInstrument?: ICardPaymentCryptoInstrument;
  showModal?: boolean;
}

export interface IBuyWithCardState extends IBuyWithCardFields {
  cryptoInstrument: ICardPaymentCryptoInstrument;
  fiatInstrument: ICardPaymentFiatInstrument;
  updating: IBuyWithCardFormField;
  countdown: number;
  quote_id: string;
  nextUpdateField: IBuyWithCardFormField;
  submitting: boolean;
  validationErrors: { [key in IBuyWithCardFormField | 'quote']: boolean };
  limits: ILimits;
  hasPartnerError: boolean;
}

class BuyWithCard extends React.PureComponent<IBuyWithCardProps, IBuyWithCardState> {
  static contextType: any = I18nContext;
  private modalRef: React.RefObject<any> = React.createRef();
  private countdownIntervalId = null;
  private updateFiatAmountThrottled = null;
  private updateCryptoAmountThrottled = null;
  private isCryptoAmountLastTouched = null;
  private initialized = false;
  mounted: boolean = false;

  defaultState: IBuyWithCardState = {
    cryptoInstrument: BUY_WITH_CARD_INITIAL_VALUE.cryptoInstrument,
    fiatInstrument: BUY_WITH_CARD_INITIAL_VALUE.fiatInstrument,
    cryptoAmount: '',
    fiatAmount: BUY_WITH_CARD_INITIAL_VALUE.fiatAmount,
    updating: null,
    countdown: null,
    quote_id: null,
    nextUpdateField: null,
    submitting: false,
    validationErrors: {
      cryptoAmount: false,
      fiatAmount: false,
      quote: false,
    },
    hasPartnerError: false,
    limits: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.defaultState,
    };

    this.updateFiatAmountThrottled = lodash.debounce(this.updateFiatAmount, 750);
    this.updateCryptoAmountThrottled = lodash.debounce(this.updateCryptoAmount, 750);
  }

  componentDidMount() {
    this.mounted = true;

    if (
      !this.props.showModal &&
      this.props.cardPaymentConfig.userConfig.payment_limit.minimal &&
      this.props.cardPaymentConfig.userConfig.payment_limit.maximal
    ) {
      this.initialize();
    }
  }

  componentDidUpdate(prevProps: IBuyWithCardProps): void {
    if (
      !prevProps.cardPaymentConfig.userConfig.payment_limit.minimal &&
      this.props.cardPaymentConfig.userConfig.payment_limit.minimal &&
      !prevProps.cardPaymentConfig.userConfig.payment_limit.maximal &&
      this.props.cardPaymentConfig.userConfig.payment_limit.maximal
    ) {
      if (!this.initialized && !this.props.showModal) {
        this.initialize();
      }
    }
  }

  componentWillUnmount = () => {
    this.mounted = false;
    this.countdownIntervalId && clearInterval(this.countdownIntervalId);
  };

  formatLimit = (num, instrumentObject): any =>
    Number(formatNumberToFixed(num, instrumentObject.digits));

  initializeLimits(
    fiatInstrument?: ICardPaymentFiatInstrument,
    cryptoInstrument?: ICardPaymentCryptoInstrument
  ): ILimits {
    const { cardPaymentConfig, rates } = this.props;
    if (!cardPaymentConfig || !cardPaymentConfig.userConfig) {
      return null;
    }

    const { instrument, minimal, maximal } = cardPaymentConfig.userConfig.payment_limit;

    const cryptoIns = cryptoInstrument || this.state.cryptoInstrument;
    const fiatIns = fiatInstrument || this.state.fiatInstrument;

    const cryptoRate = getConversionRate(rates, cryptoIns, instrument);
    const fiatRate = getConversionRate(rates, fiatIns, instrument);

    const calculateLimit = (
      mul: number,
      rate: number,
      instrument: ICardPaymentCryptoInstrument | ICardPaymentFiatInstrument,
      limitDeviationInPercent = 0
    ) => {
      const maxDeviation = 20;
      if (
        Math.abs(limitDeviationInPercent) < 0 ||
        Math.abs(limitDeviationInPercent) > maxDeviation
      ) {
        throw new Error(
          `Please check limitDeviationInPercent. Must be a whole number between 0 and +/-${maxDeviation}!`
        );
      }
      const limit = mul * (1 / rate);
      const limitRange = limit + (limitDeviationInPercent / 100) * limit;

      return this.formatLimit(limitRange, ALL_INSTRUMENTS[instrument]);
    };

    return {
      cryptoAmount: {
        min: calculateLimit(minimal, cryptoRate, cryptoIns, 0.5),
        max: calculateLimit(maximal, cryptoRate, cryptoIns),
      },
      fiatAmount: {
        min: calculateLimit(minimal, fiatRate, fiatIns, 0.5),
        max: calculateLimit(maximal, fiatRate, fiatIns),
      },
    };
  }

  correctLimits(corrector?: ICorrector): ILimits {
    const { isCryptoAmount, isMin, newLimit } = corrector;
    const instrumentObject =
      ALL_INSTRUMENTS[isCryptoAmount ? this.state.cryptoInstrument : this.state.fiatInstrument];

    const correctLimit = (oldLimit: number, update?: boolean): number => {
      if (update) {
        return this.formatLimit(newLimit, instrumentObject);
      }
      return oldLimit;
    };

    return {
      cryptoAmount: {
        min: correctLimit(this.state.limits.cryptoAmount.min, isCryptoAmount && isMin),
        max: correctLimit(this.state.limits.cryptoAmount.max, isCryptoAmount && !isMin),
      },
      fiatAmount: {
        min: correctLimit(this.state.limits.fiatAmount.min, !isCryptoAmount && isMin),
        max: correctLimit(this.state.limits.fiatAmount.max, !isCryptoAmount && !isMin),
      },
    };
  }

  validateField(field: IBuyWithCardFormField, value: string) {
    // If we change fiatAmount input => update cryptoAmount input and vice versa
    this.isCryptoAmountLastTouched = field === BUY_WITH_CARD_FIELD_NAME.cryptoAmount;
    const onValidInput = this.isCryptoAmountLastTouched
      ? this.updateFiatAmountThrottled
      : this.updateCryptoAmountThrottled;

    // Get limits for last touched
    const { min, max } = this.state.limits[
      this.isCryptoAmountLastTouched
        ? BUY_WITH_CARD_FIELD_NAME.cryptoAmount
        : BUY_WITH_CARD_FIELD_NAME.fiatAmount
    ];
    const coercedValue = Quantity.castOr(value || 0);
    const validationError = !coercedValue.gte(min) || !coercedValue.lte(max);
    this.clearCountdown();

    const payload: Partial<IBuyWithCardState> = {
      [field]: value,
      updating: validationError ? null : this.state.updating,
      nextUpdateField: validationError ? null : OPPOSITE_FORM_FIELDS[field],
      validationErrors: {
        ...this.state.validationErrors,
        quote: false,
        [field]: validationError,
      },
      hasPartnerError: false,
    };

    this.setState(payload as IBuyWithCardState, () => {
      if (!validationError) {
        return onValidInput();
      }
    });
  }

  handleUserInput = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.validateField(name as IBuyWithCardFormField, value);
  };

  /**
   * How long till next fiatAmount update (in seconds)
   */
  calculateCountdownTime = (validUntil: string): number => {
    const now = this.props.systemTimeError
      ? new Date().valueOf()
      : systemTimeDriftToUTCMoment(this.props.systemTimeDrift).valueOf();
    const result = Math.floor((moment.utc(validUntil).valueOf() - now) / TimeUnits.second) - 1;
    return result;
  };

  clearCountdown = () => {
    if (this.countdownIntervalId) {
      clearInterval(this.countdownIntervalId);
    }
    this.setState({ countdown: null });
  };

  startCountdown = () => {
    // Clear previous countdown
    if (this.countdownIntervalId) {
      clearInterval(this.countdownIntervalId);
    }
    this.countdownIntervalId = setInterval(() => {
      if (this.state.countdown - 1 === 0) {
        this.clearCountdown();
        return this.isCryptoAmountLastTouched ? this.updateFiatAmount() : this.updateCryptoAmount();
      }
      this.setState({
        countdown: this.state.countdown - 1,
      });
    }, TimeUnits.second);
  };

  canUpdateField = (field: IBuyWithCardFormField) => {
    return (
      this.state.cryptoInstrument &&
      this.state.fiatInstrument &&
      !(
        // opposite field has validation error
        (
          this.state.validationErrors[OPPOSITE_FORM_FIELDS[field]] ||
          // opposite field has scheduled update with priority
          (this.state.nextUpdateField && this.state.nextUpdateField !== field)
        )
      )
    );
  };

  handleExpiredQuote = () => {
    return this.setState(
      {
        validationErrors: { ...this.defaultState.validationErrors, quote: true },
        countdown: 20,
      },
      () => this.startCountdown()
    );
  };

  handlePartnerError = (err) => {
    this.setState({ hasPartnerError: true, countdown: 20 }, () => this.startCountdown());

    return this.props.handleError(err);
  };

  updateField = (field: IBuyWithCardFormField) => {
    if (!this.canUpdateField(field)) {
      return;
    }

    this.clearCountdown();

    return this.setState(
      {
        updating: field,
        nextUpdateField: null,
        validationErrors: { ...this.defaultState.validationErrors },
        quote_id: null,
        hasPartnerError: false,
      },
      () => {
        this.isCryptoAmountLastTouched = field === BUY_WITH_CARD_FIELD_NAME.fiatAmount;

        const requested_instrument = this.isCryptoAmountLastTouched
          ? this.state.cryptoInstrument
          : this.state.fiatInstrument;
        const touchedField = this.isCryptoAmountLastTouched
          ? BUY_WITH_CARD_FIELD_NAME.cryptoAmount
          : BUY_WITH_CARD_FIELD_NAME.fiatAmount;
        const quantity = Number(this.state[OPPOSITE_FORM_FIELDS[field]]);
        const data = {
          instrument: this.state.cryptoInstrument,
          fiat_instrument: this.state.fiatInstrument,
          requested_instrument,
          quantity,
        };
        const corrector: ICorrector = {
          isCryptoAmount: this.isCryptoAmountLastTouched,
          isMin: null,
          newLimit: quantity,
        };
        const refetchWithRetryLimit = () => {
          let maxRetries = 12;
          return (oldData, multiplier, isMin) => {
            maxRetries -= 1;

            if (maxRetries > 0) {
              const newQuantity = oldData.quantity + multiplier * oldData.quantity;
              const newData = { ...oldData, quantity: newQuantity };
              updateOnLimitsError = true;
              corrector.isMin = isMin;
              corrector.newLimit = newQuantity;
              return request(newData);
            }
            return null;
          };
        };
        const refetch = refetchWithRetryLimit();
        let updateOnLimitsError = false;

        const request = (data) => {
          return this.props
            .getQuote(data)
            .then((quote) => {
              if (!this.mounted || !this.initialized || !this.state.limits) {
                return null;
              }

              if (!quote) {
                return this.setState({ updating: null });
              }

              if (updateOnLimitsError) {
                // Correct limits on limits error
                updateOnLimitsError = false;
                this.setState({ limits: this.correctLimits(corrector) }, () => {
                  const instrumentObject = ALL_INSTRUMENTS[requested_instrument];

                  this.validateField(
                    touchedField,
                    this.formatLimit(corrector.newLimit, instrumentObject)
                  );
                });
              }

              // Avoid card payment update requests if response fiatInstrument and selected fiatInstrument don't match
              if (quote.fiat_instrument !== this.state.fiatInstrument) {
                return null;
              }
              const countdown = this.calculateCountdownTime(quote.valid_until);
              if (countdown <= 0) {
                return this.handleExpiredQuote();
              }

              const oppositeFieldLimits = this.state.limits[
                this.isCryptoAmountLastTouched
                  ? BUY_WITH_CARD_FIELD_NAME.fiatAmount
                  : BUY_WITH_CARD_FIELD_NAME.cryptoAmount
              ];
              const oppositeFieldQuantity = this.isCryptoAmountLastTouched
                ? quote.fiat_quantity
                : quote.quantity;
              const { min, max } = oppositeFieldLimits;
              const isOppositeFieldQuantityOutsideLimits =
                oppositeFieldQuantity < min || oppositeFieldQuantity > max;

              this.mounted &&
                this.setState(
                  (state) =>
                    ({
                      [field]: this.isCryptoAmountLastTouched
                        ? quote.fiat_quantity
                        : quote.quantity,
                      updating: null,
                      countdown,
                      quote_id: quote.quote_id,
                      // Correct opposite field limits if quantity is outside limits
                      limits: isOppositeFieldQuantityOutsideLimits
                        ? this.correctLimits({
                            isCryptoAmount: !this.isCryptoAmountLastTouched,
                            isMin: oppositeFieldQuantity < min,
                            newLimit: oppositeFieldQuantity,
                          })
                        : state.limits,
                    } as any),
                  () => this.startCountdown()
                );
            })
            .catch((err) => {
              if (err.name === 'SimplexAmountTooLowError') {
                return refetch(data, 0.02, true);
              }
              if (err.name === 'SimplexAmountTooHighError') {
                return refetch(data, -0.02, false);
              }

              return this.handlePartnerError(err);
            });
        };

        request(data);
      }
    );
  };

  updateFiatAmount = () => this.updateField(BUY_WITH_CARD_FIELD_NAME.fiatAmount);
  updateCryptoAmount = () => this.updateField(BUY_WITH_CARD_FIELD_NAME.cryptoAmount);

  get isCardPaymentEnabled(): boolean {
    const { cardPaymentConfig } = this.props;

    if (
      !this.props.cryptoInstrument ||
      !this.props.loggedIn ||
      !cardPaymentConfig ||
      cardPaymentConfig.userConfig.disable_service ||
      !cardPaymentConfig.userConfig.loaded
    ) {
      return false;
    }

    return true;
  }

  initialize = (instrument?: ICardPaymentCryptoInstrument) => {
    const cryptoInstrument = instrument || this.props.cryptoInstrument;
    const limits = this.initializeLimits(null, cryptoInstrument);
    if (!limits) {
      return;
    }

    this.initialized = true;
    this.setState(
      {
        cryptoInstrument,
        limits,
      },
      () => {
        if (this.props.showModal) {
          this.modalRef.current.show();
        }
        this.updateCryptoAmount();
      }
    );
  };

  initializeWithInstrument(instrument: ICardPaymentCryptoInstrument) {
    this.initialize(instrument);
  }

  changeFiatInstrument = (fiatInstrument) => {
    if (!this.state.cryptoAmount) {
      return;
    }
    this.clearCountdown();

    this.setState(
      {
        fiatInstrument,
        limits: this.initializeLimits(fiatInstrument, null),
      },
      () => {
        this.validateField(BUY_WITH_CARD_FIELD_NAME.fiatAmount, this.state.fiatAmount);
      }
    );
  };

  changeCryptoInstrument = (cryptoInstrument) => {
    if (this.state.updating) {
      return;
    }

    this.clearCountdown();
    this.setState(
      {
        cryptoInstrument,
        limits: this.initializeLimits(null, cryptoInstrument),
      },
      () => {
        this.updateCryptoAmount();
      }
    );
  };

  get isReady() {
    return (
      this.state.cryptoInstrument &&
      this.state.fiatInstrument &&
      this.state.cryptoAmount &&
      this.state.fiatAmount &&
      this.state.quote_id &&
      !this.state.submitting &&
      !this.state.nextUpdateField &&
      !Object.values(this.state.validationErrors).some((fieldError) => fieldError)
    );
  }

  resetState = () => {
    this.clearCountdown();
    this.setState({ ...this.defaultState });
  };

  handleSubmit = () => {
    if (!this.isReady) {
      return null;
    }

    this.setState({ submitting: true }, () => {
      this.clearCountdown();
      return this.props
        .requestPayment({
          quote_id: this.state.quote_id,
        })
        .then((payment) => {
          // quote_id must be unique to payment request
          this.mounted && this.setState({ quote_id: null });

          if (!payment) {
            return;
          }

          const form = document.createElement('form');
          form.id = 'payment_form';
          form.action = payment.payment_post_url;
          form.method = 'POST';
          form.target = '_self';
          form.innerHTML = `
            <input type='hidden' name='version' value='1'>
            <input type='hidden' name='partner' value='${payment.client_id}'>
            <input type='hidden' name='payment_flow_type' value='wallet'>
            <input type='hidden' name='return_url_success' value='${payment.return_url_success}'>
            <input type='hidden' name='return_url_fail' value='${payment.return_url_fail}'>
            <input type='hidden' name='quote_id' value='${payment.quote_id}'>
            <input type='hidden' name='payment_id' value='${payment.payment_id}'>
            <input type='hidden' name='user_id' value='${payment.user_id}'>
            <input type='hidden' name='destination_wallet[address]' value='${payment.target_address}'>
            <input type='hidden' name='destination_wallet[currency]' value='${payment.instrument}'>
            <input type='hidden' name='fiat_total_amount[cryptoAmount]' value='${payment.fiat_quantity}'>
            <input type='hidden' name='fiat_total_amount[currency]' value='${payment.fiat_instrument}'>
            <input type='hidden' name='digital_total_amount[cryptoAmount]' value='${payment.quantity}'>
            <input type='hidden' name='digital_total_amount[currency]' value='${payment.instrument}'>
            `;

          document.body.append(form);
          form.submit();
        })
        .finally(() => {
          return (
            this.mounted &&
            this.setState({ submitting: false }, () => {
              return this.updateCryptoAmount();
            })
          );
        });
    });
  };

  renderFieldAmountLimits = (fieldName: IBuyWithCardFormField) => {
    const { t }: II18nextT = this.context;

    if (!this.state.limits || !this.state.cryptoInstrument) {
      return null;
    }
    const isCryptoAmountField = fieldName === BUY_WITH_CARD_FIELD_NAME.cryptoAmount;

    const instrumentObject =
      ALL_INSTRUMENTS[
        isCryptoAmountField ? this.state.cryptoInstrument : this.state.fiatInstrument
      ];

    if (this.state.updating === fieldName) {
      if (this.state.validationErrors.quote) {
        return (
          <$Placeholder>
            <$FieldAmountLimits active={true}>
              {t('transactions:cardPayments.invalidQuoteErrorMessage', {
                countdown: this.state.countdown,
              })}
              <br />
              <$CheckClockIconWrapper>
                <$Icon src={WarningIcon} size={9} />
              </$CheckClockIconWrapper>
              {t('transactions:cardPayments.invalidQuoteErrorCheckClockMessage')}
            </$FieldAmountLimits>
          </$Placeholder>
        );
      }
      if (this.state.hasPartnerError) {
        return (
          <$Placeholder>
            <$FieldAmountLimits active={true}>
              <$CheckClockIconWrapper>
                <$Icon src={WarningIcon} size={9} />
              </$CheckClockIconWrapper>
              {t('transactions:cardPayments.outOfServiceMessage', {
                countdown: this.state.countdown,
              })}
            </$FieldAmountLimits>
          </$Placeholder>
        );
      }
      return (
        <$Placeholder>
          <$FieldAmountLimits active={this.state.validationErrors[fieldName]}>
            {t('transactions:cardPayments.loadingQuote', {
              instrument: instrumentObject.symbol,
            })}
          </$FieldAmountLimits>
        </$Placeholder>
      );
    }

    const { min, max } = this.state.limits[
      isCryptoAmountField
        ? BUY_WITH_CARD_FIELD_NAME.cryptoAmount
        : BUY_WITH_CARD_FIELD_NAME.fiatAmount
    ];

    return (
      <$Placeholder>
        <$FieldAmountLimits active={this.state.validationErrors[fieldName]}>
          {t('transactions:cardPayments.fieldInputQuantityRangeValidation', {
            min: this.formatLimit(min, instrumentObject),
            max: this.formatLimit(max, instrumentObject),
            instrument: instrumentObject.symbol,
          })}
        </$FieldAmountLimits>
      </$Placeholder>
    );
  };
  get fiatInstrumentOptions() {
    return this.props.cardPaymentConfig.instrumentConfig.supported_fiat.reduce(
      (res, instrument) => {
        res[instrument] = { value: instrument, label: instrument };
        return res;
      },
      {}
    );
  }

  get cryptoInstrumentOptions() {
    const { t }: II18nextT = this.context;

    return this.props.cardPaymentConfig.instrumentConfig.supported_crypto.reduce(
      (res, instrument) => {
        const instrumentDisabledReason = this.props.cardPaymentConfig.userConfig
          .disable_instruments[instrument];

        res[instrument] = {
          value: instrument,
          label: instrument,
          isDisabled: !!instrumentDisabledReason,
          // If disabled instrument is disabled for a reason, show disabled instrument message
          disabledOptionMessage:
            typeof instrumentDisabledReason === 'string'
              ? t([
                  `backend:messages.${instrumentDisabledReason}` as ITranslations,
                  instrumentDisabledReason as ITranslations,
                ])
              : // If disabled instrument is disabled without a reason, show generic message
              typeof instrumentDisabledReason === 'boolean'
              ? t('buyWithCardPage.serviceUnavailableForInstrumentGenericMessage', { instrument })
              : '',
        };
        return res;
      },
      {}
    );
  }

  renderCryptoAmountField = () => {
    const { t }: II18nextT = this.context;
    const showCountdown = this.isReady && this.state.countdown && !this.isCryptoAmountLastTouched;
    return (
      <>
        <$FormLabel htmlFor={BUY_WITH_CARD_FIELD_NAME.cryptoAmount}>
          {t('transactions:cardPayments.youWillReceiveFieldLabel')}
          {showCountdown && (
            <$Countdown>
              <$Icon src={RefreshIcon} size={13} />
              {moment
                .utc(moment.duration(this.state.countdown, 'seconds').asMilliseconds())
                .format(this.state.countdown < 60 ? 's[s]' : 'h[h] m[m] s[s]')}
            </$Countdown>
          )}
        </$FormLabel>
        <$CryptoWrapper>
          <$CryptoInputWrapper
            updating={this.state.updating === BUY_WITH_CARD_FIELD_NAME.cryptoAmount}
            validationError={this.state.validationErrors.cryptoAmount}
          >
            <NumberInput
              id={BUY_WITH_CARD_FIELD_NAME.cryptoAmount}
              border="none"
              min="0"
              name={BUY_WITH_CARD_FIELD_NAME.cryptoAmount}
              value={this.state.cryptoAmount}
              onChange={this.handleUserInput}
            />
          </$CryptoInputWrapper>
          {this.props.showModal ? (
            <$CryptoInstrument>{this.state.cryptoInstrument}</$CryptoInstrument>
          ) : (
            <$InstrumentSwitcher>
              {this.cryptoInstrumentOptions && (
                <SimplexSelect
                  variant="transparent"
                  options={Object.values(this.cryptoInstrumentOptions)}
                  value={this.cryptoInstrumentOptions[this.state.cryptoInstrument]}
                  onChange={({ value }) => this.changeCryptoInstrument(value)}
                  for="instrument"
                />
              )}
            </$InstrumentSwitcher>
          )}
        </$CryptoWrapper>
        {this.renderFieldAmountLimits(BUY_WITH_CARD_FIELD_NAME.cryptoAmount)}
      </>
    );
  };

  renderFiatAmountField = () => {
    const { t }: II18nextT = this.context;
    const showCountdown = this.isReady && this.state.countdown && this.isCryptoAmountLastTouched;

    return (
      <>
        <$FormLabel htmlFor={`buy-${BUY_WITH_CARD_FIELD_NAME.fiatAmount}`} style={{ marginTop: 0 }}>
          {t('transactions:cardPayments.youWillPayFieldLabel')}
          {showCountdown && (
            <$Countdown>
              <$Icon src={RefreshIcon} size={13} />
              {moment
                .utc(moment.duration(this.state.countdown, 'seconds').asMilliseconds())
                .format(this.state.countdown < 60 ? 's[s]' : 'm[m] s[s]')}
            </$Countdown>
          )}
        </$FormLabel>
        <$FiatWrapper>
          <$FiatInputWrapper
            updating={this.state.updating === BUY_WITH_CARD_FIELD_NAME.fiatAmount}
            validationError={this.state.validationErrors.fiatAmount}
          >
            <NumberInput
              id={`buy-${BUY_WITH_CARD_FIELD_NAME.fiatAmount}`}
              border="none"
              min="0"
              type="number"
              name={BUY_WITH_CARD_FIELD_NAME.fiatAmount}
              value={this.state.fiatAmount}
              onChange={this.handleUserInput}
            />
          </$FiatInputWrapper>
          <$InstrumentSwitcher>
            {this.fiatInstrumentOptions && (
              <SimplexSelect
                variant="transparent"
                options={Object.values(this.fiatInstrumentOptions)}
                value={this.fiatInstrumentOptions[this.state.fiatInstrument]}
                onChange={({ value }) => {
                  this.changeFiatInstrument(value);
                }}
                for="currency"
              />
            )}
          </$InstrumentSwitcher>
        </$FiatWrapper>
        {this.renderFieldAmountLimits(BUY_WITH_CARD_FIELD_NAME.fiatAmount)}
      </>
    );
  };

  renderPartnerReference() {
    const { t }: II18nextT = this.context;
    return (
      <$PartnerReference>
        <$AcceptedCardsWrapper>
          {t('transactions:cardPayments.acceptedCardsNotice')} <img src={supportedCardsImage} />
        </$AcceptedCardsWrapper>
        <$PoweredByWrapper>
          {t('transactions:cardPayments.poweredBy')} <img src={simplexImage} />
        </$PoweredByWrapper>
      </$PartnerReference>
    );
  }

  handleClose = () => {
    this.resetState();
  };

  public show = () => {
    this.modalRef.current.show();
  };

  renderBuyWithCard() {
    const { t }: II18nextT = this.context;

    const content = (
      <$BuyWithCardWrapper boxShadow={!this.props.showModal}>
        <$BuyWithCardModalWrapper>
          {this.renderFiatAmountField()}
          {this.renderCryptoAmountField()}
          <$ContinueButton onClick={this.handleSubmit} disabled={!this.isReady}>
            {t('button.continue')}
          </$ContinueButton>
          <$RedirectNotice>{t('transactions:cardPayments.redirectNotice')}</$RedirectNotice>
        </$BuyWithCardModalWrapper>
        {this.renderPartnerReference()}
      </$BuyWithCardWrapper>
    );

    if (this.props.showModal) {
      return (
        <Modal
          ref={this.modalRef}
          title={`${t('buy')} ${this.state.cryptoInstrument}`}
          titleUppercase={true}
          onClose={this.handleClose}
        >
          {content}
        </Modal>
      );
    }

    return content;
  }

  render() {
    if (!this.state.cryptoInstrument && !this.isCardPaymentEnabled) {
      return null;
    }

    return this.renderBuyWithCard();
  }
}
const connector = connect(
  (state: IState) => ({
    systemTimeDrift: getSystemTimeDrift(state),
    systemTimeError: getSystemTimeError(state),
    cardPaymentConfig: getCardPaymentConfig(state),
    rates: getRates(state),
    loggedIn: isUserLoggedIn(state),
  }),
  { getQuote, requestPayment, handleError },
  null,
  { forwardRef: true }
);

export default connector(BuyWithCard);
