import Tooltip from 'rc-tooltip';
import * as React from 'react';

import styled from '../../../lib/styled_components';
import { ITooltipProps, ITooltipWrapperProps } from './tooltip_types';

const $SpanWrapper = styled.span<ITooltipWrapperProps>`
  display: inline-block;
  > button:disabled,
  > fieldset:disabled,
  > input:disabled,
  > optgroup:disabled,
  > option:disabled,
  > select:disabled,
  > textarea:disabled {
    pointer-events: none;
  }

  //************ Tooltip wrapper props styles ********************************************

  // Show disabled cursor if tooltip is displayed when element should be disabled
  cursor: ${(p) => p.disabled && 'not-allowed'};
`;

export type IGenericTooltipVariant = 'normal' | 'error';

interface IGenericTooltipProps extends ITooltipProps {
  variant?: IGenericTooltipVariant;
}

const GenericTooltip: any = (props) => {
  const { wrapperProps, variant, ...rest } = props;

  if (!props.overlay) {
    return props.children;
  }

  const tooltipProps: ITooltipProps = {
    mouseLeaveDelay: 0,
    placement: 'bottom',
    trigger: ['hover', 'focus'],
    ...rest,
    overlayClassName: (rest.overlayClassName || '') + (' GenericTooltip-' + (variant || 'normal')),
  };

  if (wrapperProps && wrapperProps.disabled) {
    return (
      <Tooltip overlay={null} {...tooltipProps}>
        <$SpanWrapper {...wrapperProps}>{props.children}</$SpanWrapper>
      </Tooltip>
    );
  }

  return <Tooltip overlay={null} {...tooltipProps} />;
};

export default GenericTooltip as React.FunctionComponent<IGenericTooltipProps>;
