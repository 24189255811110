import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { IThemeColorsUnion } from '../../../lib/styled_components';
import { IOrderSide } from '../../../types/backend_definitions';

const $WidgetTitle = styled.div<{
  flipOnLargeScreens?: boolean;
  side: 'buy' | 'sell';
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
  align-items: baseline;

  h4 {
    color: ${(p) => p.theme.colors.info};
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: ${(p) => p.theme.fontSize.large};
    text-transform: uppercase;
  }

  @media ${(p) => p.theme.device.biggerThanMobile} {
    ${(p) => (p.flipOnLargeScreens ? `flex-direction: row-reverse` : '')}
  }

  @media only screen and ${(p) => p.theme.device.mobile_SamsungGalaxyTab2} {
    flex-direction: ${(p) => (p.side === 'sell' ? 'row-reverse' : 'row')};
    padding-right: ${(p) => (p.side === 'buy' ? '5px' : '0')};
    padding-left: ${(p) => (p.side === 'sell' ? '7px' : '0')};
    h4 {
      margin-bottom: 1px !important;
      margin-top: 2px !important;
    }
  }
  @media only screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    h4 {
      line-height: ${(p) =>
        p.theme.components.orderBook.components.widgetTitle.iphonePlus.fontSize} !important;
      font-size: ${(p) =>
        p.theme.components.orderBook.components.widgetTitle.iphonePlus.fontSize} !important;
    }
  }
`;

interface IWidgetTitleProps extends ConnectedProps<typeof connector> {
  title: string;
  color?: IThemeColorsUnion;
  flipOnLargeScreens?: boolean;
  opposite?: any;
  side: IOrderSide;
}

const WidgetTitle: React.FunctionComponent<IWidgetTitleProps> = ({
  title,
  color,
  flipOnLargeScreens = false,
  opposite,
  side,
  theme,
}) => {
  const style = color ? { color: theme.colors[color] } : undefined;

  return (
    <$WidgetTitle side={side} flipOnLargeScreens={flipOnLargeScreens}>
      <h4 style={style}>{title}</h4>
      {opposite}
    </$WidgetTitle>
  );
};

const connector = connect((state: IState) => ({
  theme: state.app.theme,
}));

export default connector(WidgetTitle);
