import { createSelector } from 'reselect';

import { IState } from '../lib/store';
import { groupTradesByDate } from '../lib/util';
import { IPaginated } from '../types/api';
import { IUser } from '../types/auth';
import { IApiPublicTradeInfo } from '../types/backend_definitions';
import { ITrade } from '../types/trades';
import { ITradeUserRole } from '../types/trades';
import { DeepReadonly, Omit } from '../types/typescript_helpers';
import { ITradeScrollableTableRow } from '../views/components/reports/TradeHistory';
import { IDateGroup } from '../views/widgets/DateSplitScrollBox';
import { IHorizontalScrollTableData } from '../views/widgets/HorizontalScrollTable';
import { getUser } from './auth';

// DateSplit interfaces and helpers
export interface IMarketTradesData {
  tradesGroupsByDate: IDateGroup[];
  tradesLength: number;
}

// IHorizontalScrollTableData interfaces and helpers
export interface ITradesPaginationState extends Omit<IPaginated<ITrade>, 'items'> {
  items: IHorizontalScrollTableData<string, ITradeScrollableTableRow>;
}

// Helper functions
const createTradesHorizontalScrollTableData = (
  data: DeepReadonly<IPaginated<ITrade>>
): ITradesPaginationState => {
  const { items, ...rest } = data;
  return items.reduce(
    (modifiedContent: ITradesPaginationState, trade: ITrade): ITradesPaginationState => {
      const { timestamp: fixed, ...other } = trade;
      modifiedContent.items.fixed.push(fixed);
      modifiedContent.items.scrollable.push(other);
      return modifiedContent;
    },
    { ...rest, items: { fixed: [], scrollable: [], length: data.items.length } }
  );
};

// Selectors
export const getMarketTradesForCurrentInstrumentPair = (state: IState): IMarketTradesData => {
  const trades: ITrade[] = Object.values(state.marketTradesCurrentInstrumentPair);
  const tradesGroupsByDate = groupTradesByDate(trades);
  return { tradesGroupsByDate, tradesLength: trades.length };
};

export const getUserTradesForCurrentInstrumentPair: IMarketTradesData | any = createSelector(
  (state: IState) => state.userTradesCurrentInstrumentPair,
  getUser,
  (userTrades, user) => {
    if (!user) {
      return { tradesGroupsByDate: [], tradesLength: 0 };
    }
    const tradesGroupsByDate: IDateGroup[] = groupTradesByDate(userTrades);
    return { tradesGroupsByDate, tradesLength: userTrades ? userTrades.length : 0 };
  }
);

export const getUserTrades = (state: IState) => state.userTrades;

export const getTradesPaginationState = (state: IState): ITradesPaginationState =>
  createTradesHorizontalScrollTableData(state.userTrades);
export const getTradeUserRole = (trade: IApiPublicTradeInfo | ITrade): ITradeUserRole => {
  if (!trade.user_side) {
    return 'uninvolved';
  }

  return trade.user_side === trade.side ? 'taker' : 'maker';
};
export const getTradeDisplaySide = (trade: IApiPublicTradeInfo | ITrade) =>
  trade.user_side || trade.side;
