import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { createSelector } from 'reselect';

import R, { IReportsPage, IRouteEnablementProps, getRouteEnablementProps } from '../../lib/routes';
import { IState } from '../../lib/store';
import {
  BanknoteWithArrowsIcon,
  CSVIcon,
  LockIcon,
  OppositeArrowsIcon,
} from '../../media/svg_icons';
import { II18nextT } from '../../types/i18n';
import CardPayments from '../components/reports/CardPayments';
import Deposits from '../components/reports/Deposits';
import OpenOrders from '../components/reports/OpenOrders';
import SecurityEvents from '../components/reports/SecurityEvents';
import TradeHistory from '../components/reports/TradeHistory';
import Withdrawals from '../components/reports/Withdrawals';
import SideMenuLayout, { ISideMenuItem } from '../layout/SideMenuLayout';
import PageSeoWrapper from '../widgets/PageSeoWrapper';
import NotFoundPage from './NotFoundPage';

const REPORT_COMPONENTS: { [page in IReportsPage]: React.ComponentType<any> } = {
  'open-orders': OpenOrders,
  'trade-history': TradeHistory,
  'card-payments': CardPayments,
  deposits: Deposits,
  withdrawals: Withdrawals,
  'security-events': SecurityEvents,
};

type ReportsMenuItems = { [key in IReportsPage]: ISideMenuItem };

const makeReportsMenuItems = (props: IRouteEnablementProps): ReportsMenuItems => {
  const itemsTemplate: Array<ISideMenuItem & { page: IReportsPage }> = [
    {
      page: 'open-orders',
      label: 'reportsPage.openOrders',
      icon: OppositeArrowsIcon,
    },
    {
      page: 'trade-history',
      label: 'reportsPage.tradeHistory',
      icon: BanknoteWithArrowsIcon,
    },
    {
      page: 'card-payments',
      label: 'reportsPage.cardPayments',
      icon: BanknoteWithArrowsIcon,
    },
    {
      page: 'deposits',
      label: 'reportsPage.deposits',
      icon: BanknoteWithArrowsIcon,
    },
    {
      page: 'withdrawals',
      label: 'reportsPage.withdrawals',
      icon: CSVIcon,
    },
    {
      page: 'security-events',
      label: 'reportsPage.securityEvents',
      icon: LockIcon,
    },
  ];

  return itemsTemplate
    .filter((item) => {
      return R.REPORTS.isEnabled(props, {
        page: item.page,
      });
    })
    .reduce((lookup, item) => {
      lookup[item.page] = {
        ...item,
        route: item.route || R.REPORTS.to({ page: item.page }),
      };
      return lookup;
    }, {} as ReportsMenuItems);
};

interface IReportsPageProps
  extends ConnectedProps<typeof connector>,
    RouteComponentProps<{ page: IReportsPage }> {}

class ReportsPage extends React.PureComponent<IReportsPageProps> {
  static contextType: any = I18nContext;

  reportsMenuItems = createSelector(
    (routeEnablementProps: IRouteEnablementProps) => routeEnablementProps,
    makeReportsMenuItems
  );

  render() {
    const { t }: II18nextT = this.context;

    const page = (this.props.match.params && this.props.match.params.page) || null;

    const Component = REPORT_COMPONENTS[page];
    if (!Component) {
      return <NotFoundPage />;
    }

    const items = this.reportsMenuItems(this.props.routeEnablementProps);
    const sideMenuRightMaxWidth = this.props.theme.layout.sideMenu.reportPageMaxWidths[page];

    return (
      <PageSeoWrapper pageTitle={t(items[page].label)}>
        <SideMenuLayout
          pages={items}
          sideMenuRightMaxWidth={sideMenuRightMaxWidth}
          headerIcon={OppositeArrowsIcon}
          headerText={'pages.reports'}
          currentPage={items[page]}
          selectedRoute={R.REPORTS.to({ page })}
        >
          <Component />
        </SideMenuLayout>
      </PageSeoWrapper>
    );
  }
}

const connector = connect((state: IState) => ({
  theme: state.app.theme,
  routeEnablementProps: getRouteEnablementProps(state),
}));

export default withRouter(connector(ReportsPage));
