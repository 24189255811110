import lodash from '../../lib/lodash';
import { handleError } from '../../lib/errors';
import { commit, val } from '../../lib/redux_helpers';
import { IStateGetter, IThunkMethod } from '../../lib/store';
import { IApiCancelOrderReport, IPair } from '../../types/backend_definitions';
import { loadingWrapper } from '../request_active';
import {
  decrementBuyOrdersForInstrumentPair,
  decrementSellOrdersForInstrumentPair,
} from './active_instruments_orders';

const setOrderCancellation = (orderId: string, forCancellation: boolean): IThunkMethod => (
  dispatch,
  getState
) => {
  if (getState().ordersById[orderId]) {
    dispatch(
      commit(`${forCancellation ? 'Start' : 'Finish'} order ${orderId} cancellation`, {
        ordersById: {
          [orderId]: {
            forCancellation: val<boolean>(forCancellation),
          },
        },
      })
    );
  }
};

let ordersToRemove = [];

export const removeOrderThrottled = lodash.throttle((dispatch, getState: IStateGetter) => {
  if (!ordersToRemove.length) {
    return;
  }

  const state = getState();

  const ordersById = { ...state.ordersById };

  for (const orderId of ordersToRemove) {
    const orderState = ordersById[orderId];

    if (!orderState) {
      continue;
    }

    delete ordersById[orderId];

    if (orderState.order.side === 'buy') {
      dispatch(decrementBuyOrdersForInstrumentPair(orderState.order.pair as IPair));
    } else {
      dispatch(decrementSellOrdersForInstrumentPair(orderState.order.pair as IPair));
    }
  }

  dispatch(
    commit(`Remove orders after throttling`, {
      ordersById: val(ordersById),
    })
  );

  ordersToRemove = [];
}, 500);

export const removeOrder = (orderId: string): IThunkMethod => (dispatch, getState) => {
  ordersToRemove.push(orderId);
  return removeOrderThrottled(dispatch, getState);
};

export const cancelOrder = (instrumentPair: IPair, orderId: string): IThunkMethod => (
  dispatch,
  _,
  { api, logger, matomo }
) => {
  dispatch(setOrderCancellation(orderId, true));

  dispatch(
    loadingWrapper(
      'cancelOrderFromListOfAllOrders',
      api.deleteExchangeOrder(instrumentPair, orderId)
    )
  ).then(
    (report: IApiCancelOrderReport) => {
      dispatch(removeOrder(orderId));
      matomo.sendEvent(
        'Trading',
        'cancel_order',
        report.cancelled_order.pair,
        Number(report.cancelled_order.quantity)
      );
    },
    (err) => {
      dispatch(setOrderCancellation(orderId, false));

      dispatch(handleError(err));
    }
  );
};
