import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { I18n, intentionallyUntranslated } from '../../../../lib/i18n';
import { IState } from '../../../../lib/store';
import styled from '../../../../lib/styled_components';
import { CheckIcon } from '../../../../media/svg_icons';
import { getKYCLevel } from '../../../../selectors/auth';
import { getManagedConfig } from '../../../../selectors/transactions';
import { ITheme } from '../../../../types/theme';
import { ITranslations } from '../../../../types/translations';
import KYCLevelIndicatorIcon from '../../../widgets/indicator_icons/KYCLevelIndicatorIcon';
import GenericTooltip from '../../../widgets/tooltips/GenericTooltip';
import ManagedCountries from '../../managed/ManagedCountries';

type IKycStatusStage = 'completed' | 'next' | 'future';

// *********************************************************************************************************************

const $LimitTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: 1rem;

  thead {
    background-color: ${(p) => p.theme.widgets.kycStatus.activeColor};
  }

  th {
    text-transform: uppercase;
    padding-top: 0.2rem;
    vertical-align: middle;
  }

  td {
    text-align: center;
    padding-top: 0.3rem;
    font-size: ${(p) => p.theme.fontSize.large};
    font-weight: bold;
    vertical-align: middle;
    text-transform: uppercase;
  }
`;

const KycLimitsTable: React.FunctionComponent<{
  daily?: number | string;
  monthly?: number | string;
  ifNotSpecifiedDefaultTo: 'unlimited' | 'none';
}> = ({ daily = null, monthly = null, ifNotSpecifiedDefaultTo }) => {
  return (
    <I18n>
      {(t) => (
        <$LimitTable>
          <colgroup>
            <col style={{ width: '50%' }} />
            <col style={{ width: '50%' }} />
          </colgroup>
          <thead>
            <tr>
              <th>{t('settings:kyc.status.daily')}</th>
              <th>{t('settings:kyc.status.monthly')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {!daily && !monthly ? (
                <td colSpan={2}>
                  {t(('settings:kyc.status.' + ifNotSpecifiedDefaultTo) as ITranslations)}
                </td>
              ) : (
                <>
                  <td>
                    {daily
                      ? `$${daily}`
                      : t(('settings:kyc.status.' + ifNotSpecifiedDefaultTo) as ITranslations)}
                  </td>
                  <td>
                    {monthly
                      ? `$${monthly}`
                      : t(('settings:kyc.status.' + ifNotSpecifiedDefaultTo) as ITranslations)}
                  </td>
                </>
              )}
            </tr>
          </tbody>
        </$LimitTable>
      )}
    </I18n>
  );
};

// *********************************************************************************************************************

const $KycStatus = styled.div<{ active: boolean }>`
  min-width: 200px;
  height: fit-content;
  background: ${(p) => p.theme.components.kycPanel.background};
  border: ${(p) => (p.active ? p.theme.components.kycPanel.border : null)};

  @media screen and ${(p) => p.theme.components.kyc.mobileSizeMediaQuery} {
    width: 100%;
    max-width: initial;
    margin-bottom: 1rem;

    display: grid;
    grid-template-columns: 230px 1fr;
  }

  // TODO: Agh
  @media screen and ${(p) => p.theme.device.mobile_SamsungGalaxyTab2} {
    display: block;
  }
`;

const $StatusHeader = styled.div<{ stage: IKycStatusStage }>`
  height: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bold;
  background: ${(p) =>
    p.stage === 'completed'
      ? p.theme.widgets.kycStatus.completedColor
      : p.stage === 'next'
      ? p.theme.widgets.kycStatus.activeColor
      : null};

  @media screen and ${(p) => p.theme.components.kyc.mobileSizeMediaQuery} {
    grid-row: 1;
    grid-column: 1/3;
  }
`;

const $StatusTitle = styled('div')`
  position: relative;
  padding: 1.5rem 1rem 2rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :first-child {
    width: 5rem;
    margin-right: 1rem;
  }

  h2 {
    margin: 0;
    font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }
  h4 {
    font-weight: normal;
    text-transform: uppercase;
    margin: 0;
  }
`;

const $StatusFeatures = styled.div<{ items: number }>`
  min-height: ${(p) => p.items * 2}rem;

  @media screen and ${(p) => p.theme.components.kyc.mobileSizeMediaQuery} {
    grid-column: 1;
    min-height: 0;
  }
`;

const $StatusFeature = styled('div')<{ icon?: string }>`
  padding: 0 1rem;
  height: 2rem;
  white-space: nowrap;

  > svg:first-child {
    width: 17px;
    height: 17px;
    vertical-align: middle;
    margin-right: 0.5em;
  }
`;

const $ComingSoon = styled.div`
  padding: 2rem;
  font-size: ${(p) => p.theme.fontSize.largest};
  opacity: 0.8;
  text-align: center;
`;

const $StatusLimits = styled.div`
  @media screen and ${(p) => p.theme.components.kyc.mobileSizeMediaQuery} {
    grid-column: 2;
    grid-row: 2/4;
    padding-right: 20px;
  }

  // TODO: Agh
  @media screen and ${(p) => p.theme.device.mobile_SamsungGalaxyTab2} {
    padding: 0;
  }
`;

const $LimitHeader = styled.h4`
  margin-top: 1.3rem;
  margin-bottom: 0.7rem;
  font-size: ${(p) => p.theme.fontSize.large};
  text-align: center;
  font-weight: normal;
  opacity: 0.8;
`;

const $ManagedAsterisk = styled.em`
  font-weight: bold;
  margin-left: 0.2rem;
  cursor: help;
`;

const StatusFeature: React.FC<{ theme: ITheme }> = ({ children, theme }) => {
  return (
    <$StatusFeature>
      <CheckIcon color={(theme as ITheme).widgets.kycStatus.checkColor} />
      {children}
    </$StatusFeature>
  );
};

interface IKycStatusProps extends ConnectedProps<typeof connector> {
  level: 0 | 1 | 2;
}

const KycStatus: React.FC<IKycStatusProps> = ({
  level,
  currentLevel,
  theme,
  kycLevelRequested,
  maxWithdrawalPerKYCLevel,
  managedCountryWhitelist,
}) => {
  const withdrawalLimit = maxWithdrawalPerKYCLevel[level];

  const stage: IKycStatusStage =
    currentLevel >= level ? 'completed' : level - currentLevel === 1 ? 'next' : 'future';
  const isInProgress = stage === 'next' && level === kycLevelRequested;
  const canTradeAtThisLevel = level > 0;

  return (
    <I18n>
      {(t) => (
        <$KycStatus active={stage === 'next'}>
          <$StatusHeader stage={stage}>
            {stage === 'completed'
              ? t('settings:kyc.status.header.completed')
              : isInProgress
              ? t('settings:kyc.status.header.inProgress')
              : stage === 'next'
              ? t('settings:kyc.status.header.next')
              : ''}
          </$StatusHeader>
          <$StatusTitle>
            <KYCLevelIndicatorIcon
              level={level}
              indication={isInProgress ? 'enabling' : stage !== 'completed' ? 'disabled' : null}
            />
            <div>
              <h2>{t(`settings:kyc.status.level${level}` as ITranslations)}</h2>
              {level > 0 && <h4>{t(`settings:kyc.status.subtitle`)}</h4>}
            </div>
          </$StatusTitle>
          <$StatusFeatures items={4}>
            {level > 0 ? (
              <>
                <StatusFeature key={1} theme={theme}>
                  {t('settings:kyc.status.features.fullPlatformAccess')}
                </StatusFeature>
                <StatusFeature key={2} theme={theme}>
                  {t('settings:kyc.status.features.depositAndWithdrawCrypto')}
                </StatusFeature>
                <StatusFeature key={3} theme={theme}>
                  {t('settings:kyc.status.features.buyWithCard')}
                </StatusFeature>
                {level >= 2 && (
                  <StatusFeature key={5} theme={theme}>
                    <span>{t('settings:kyc.status.features.managedAccess')}</span>
                    <GenericTooltip
                      trigger={['focus']}
                      overlay={
                        <div>
                          <p>{t('settings:kyc.status.features.managedAccessAsterisk')}</p>
                          <ManagedCountries columns={3} />
                        </div>
                      }
                    >
                      <$ManagedAsterisk tabIndex={0}>*</$ManagedAsterisk>
                    </GenericTooltip>
                  </StatusFeature>
                )}
              </>
            ) : (
              <StatusFeature key={1} theme={theme}>
                {t('settings:kyc.status.features.anonymousPlatformAccess')}
              </StatusFeature>
            )}
          </$StatusFeatures>
          <$StatusLimits>
            <div>
              <$LimitHeader>{t('settings:kyc.status.depositLimits')}</$LimitHeader>
              <KycLimitsTable
                ifNotSpecifiedDefaultTo={canTradeAtThisLevel ? 'unlimited' : 'none'}
              />
            </div>
            <div>
              <$LimitHeader>{t('settings:kyc.status.withdrawalLimits')}</$LimitHeader>
              <KycLimitsTable
                daily={canTradeAtThisLevel ? withdrawalLimit : 0}
                ifNotSpecifiedDefaultTo={canTradeAtThisLevel ? 'unlimited' : 'none'}
              />
            </div>
          </$StatusLimits>
        </$KycStatus>
      )}
    </I18n>
  );
};

const connector = connect((state: IState) => ({
  theme: state.app.theme,
  currentLevel: getKYCLevel(state),
  kycLevelRequested: state.customer && state.customer.kyc_level_requested,
  maxWithdrawalPerKYCLevel: state.env.maxWithdrawalPerKYCLevel,
  managedCountryWhitelist: state.env.managed.countryWhitelist,
}));

export default connector(KycStatus);
