import * as React from 'react';

import { instrumentInfo } from '../../types/instruments';
import InstrumentIcon, { IIconTypes } from './InstrumentIcon';

export const $Icon = ({
  src,
  size = 32,
  instrumentIconType = 'color',
}: {
  src: any;
  size?: number | string;
  instrumentIconType?: IIconTypes;
}) => {
  const WHsize = typeof size === 'number' ? `${size}px` : size;

  // if src is function it is SVG element
  return typeof src === 'function' ? (
    src({ width: WHsize, height: WHsize })
  ) : // check if src is symbol of instrument
  instrumentInfo(src) ? (
    <InstrumentIcon name={src} size={WHsize} type={instrumentIconType as any} />
  ) : (
    // assume src is reference to image
    <img src={src} style={{ width: WHsize, height: WHsize }} alt="Instrument icon" />
  );
};
