import { createSelector } from 'reselect';

import { IOrderState, IOrdersStateById } from '../actions/orders/orders';
import lodash from '../lib/lodash';
import { IState } from '../lib/store';
import { groupOrdersByDate } from '../lib/util';
import { IInstrumentPair } from '../types/instruments';
import { IOrder } from '../types/orders';
import { Omit } from '../types/typescript_helpers';
import { IDateGroup } from '../views/widgets/DateSplitScrollBox';
import { IHorizontalScrollTableData } from '../views/widgets/HorizontalScrollTable';
import { getCurrentPair } from './exchange';

// DateSplit interfaces and helpers
export interface ISortedOrdersData {
  items: IDateGroup[];
  count: number;
}

// HorizontalScrollTable interfaces and helpers
export interface IOrderScrollable extends Omit<IOrder, 'timestamp'> {}
export interface IOrderScrollableTableRow {
  order: IOrderScrollable;
  forCancellation?: Boolean;
}
export interface IOrdersHorizontalScrollTableData
  extends IHorizontalScrollTableData<string, IOrderScrollableTableRow> {}

// Helper functions
const createOrdersHorizontalScrollTableData = (
  data: IOrderState[]
): IOrdersHorizontalScrollTableData => {
  return data.reduce(
    (
      modifiedContent: IOrdersHorizontalScrollTableData,
      ordersState: IOrderState
    ): IOrdersHorizontalScrollTableData => {
      const { timestamp: fixed, ...order } = ordersState.order;

      modifiedContent.fixed.push(fixed);
      modifiedContent.scrollable.push({ order, forCancellation: ordersState.forCancellation });
      return modifiedContent;
    },
    { fixed: [], scrollable: [], length: data.length }
  );
};

// Selectors
// Used on the exchange page
export const getSortedOrdersListForCurrentInstrumentPair = createSelector(
  [(state: IState) => state.ordersById, (state: IState) => getCurrentPair(state)],
  (byId: IOrdersStateById, currentPair: IInstrumentPair): ISortedOrdersData => {
    if (!currentPair) {
      return { items: [], count: 0 };
    }

    const filtered = lodash.filter(
      byId,
      (orderState, _) => orderState.order.pair === currentPair.path
    );
    const descOrders = lodash.orderBy(filtered, 'order.timestamp', ['desc']);

    return { items: groupOrdersByDate(descOrders), count: descOrders.length };
  }
);

// Orders page
export const getSortedActiveOrderList = createSelector(
  [(state: IState) => state.ordersById],
  (byId: IOrdersStateById) =>
    createOrdersHorizontalScrollTableData(lodash.orderBy(byId, 'order.timestamp', ['desc']))
);

export const getActiveOrdersPaginationState = createSelector(
  [(state: IState) => state.ordersPagination],
  (paginationState) => paginationState
);
