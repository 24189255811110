import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import {
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  widget,
} from '../../../charting_library/charting_library';

import { CandleChartAdapter } from '../../../lib/candle_chart_adapter';
import { Logger } from '../../../lib/logger';
import { inject } from '../../../lib/react_container';
import { IState } from '../../../lib/store';
import styled, { ThemeContext } from '../../../lib/styled_components';
import { getCurrentPairPath } from '../../../selectors/exchange';
import { IPair } from '../../../types/backend_definitions';
import { ResolutionString } from '../../../charting_library/datafeed-api';

export const $Chart = styled.div`
  height: 100%;
`;

interface IDefaultProps
  extends Readonly<
    Partial<{
      interval: ChartingLibraryWidgetOptions['interval'];
      timeframe: ChartingLibraryWidgetOptions['timeframe'];
      timezone: ChartingLibraryWidgetOptions['timezone'];
      libraryPath: ChartingLibraryWidgetOptions['library_path'];
      fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
      autosize: ChartingLibraryWidgetOptions['autosize'];
      studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
      containerId: ChartingLibraryWidgetOptions['container_id'];
    }>
  > {}
interface IChartContainerProps extends ConnectedProps<typeof connector>, IDefaultProps {
  candleChartAdapter: CandleChartAdapter;
  logger: Logger;
}

export interface IChartContainerState {}

class CandleChart extends React.PureComponent<IChartContainerProps, IChartContainerState> {
  static contextType = ThemeContext;

  static defaultProps: IDefaultProps = {
    interval: '60' as ResolutionString,
    timeframe: '1m',
    timezone: 'Etc/UTC',
    containerId: 'tv_chart_container',
    libraryPath: '/charting_library/',
    fullscreen: false,
    autosize: true,
    studiesOverrides: { 'volume.precision': 8 },
  };

  private logger: Logger;
  private tvWidget: IChartingLibraryWidget | null = null;
  private updatePromise: Promise<any> = null;
  private tvWidgetSymbol: IPair;

  private chartContainerRef: React.RefObject<HTMLDivElement> = React.createRef();
  private mounted: boolean;

  constructor(props: IChartContainerProps) {
    super(props);

    this.logger = props.logger.prefixed('CandleChart');
  }

  componentDidMount() {
    this.mounted = true;
    this.updateChart();
  }

  componentDidUpdate(
    prevProps: Readonly<IChartContainerProps>,
    prevState: Readonly<IChartContainerState>,
    snapshot?: any
  ): void {
    this.updateChart();
  }

  componentWillReceiveProps(nextProps) {
    this.updateChart();
  }

  componentWillUnmount(): void {
    this.mounted = false;
    this.updateChart();
  }

  execUpdate = (fn: () => void) => {
    this.updatePromise = Promise.resolve()
      .then(() => fn())
      // Delay callback due to finicky chart  internals
      .then(() => new Promise((resolve) => setTimeout(resolve, 1)))
      .finally(() => {
        this.updatePromise = null;
      })
      .then(() => this.updateChart());
  };

  updateChart(): void {
    if (this.updatePromise) {
      this.logger.verbose(`Delay update until previous update ends`);
      this.updatePromise.then(() => this.updateChart());
      return;
    }

    if (!this.mounted) {
      this.logger.verbose(`Clean up chart due to unmounted state`);
      if (this.tvWidget) {
        return this.execUpdate(() => this.destroyChart());
      }
      return;
    }

    if (!this.props.symbol) {
      this.logger.verbose(`No symbol available, abort update`);
      return;
    }

    if (!this.tvWidget) {
      this.logger.verbose(`Initialize chart with ${this.props.symbol}`);
      return this.execUpdate(() => this.initializeChart(this.props.symbol));
    }

    if (this.props.symbol !== this.tvWidgetSymbol) {
      this.logger.verbose(
        `Change chart symbol from ${this.tvWidgetSymbol} to ${this.props.symbol}`
      );
      return this.execUpdate(() => this.changeChartSymbol(this.props.symbol));
    }

    // No changes needed
  }

  changeChartSymbol(symbol: IPair): boolean {
    if (
      !this.tvWidget ||
      !this.tvWidgetSymbol ||
      !symbol ||
      symbol === this.tvWidgetSymbol ||
      !this.mounted
    ) {
      return false;
    }

    this.tvWidget.setSymbol(symbol, this.tvWidget.chart().resolution(), () => {
      // NOTE: If price feed returns a negative (either when loading symbol info or bars), this will not be called.
      // Better ignore.
    });
    this.tvWidgetSymbol = symbol;
    return true;
  }

  initializeChart(symbol: IPair): Promise<boolean> {
    if (this.tvWidget) {
      // Already initialized, we should never get here
      return Promise.resolve(false);
    }

    this.tvWidgetSymbol = null;

    return this.props.candleChartAdapter.getInitialSavedState().then((savedState) => {
      const widgetOptions: ChartingLibraryWidgetOptions = {
        symbol,
        saved_data: savedState,
        interval: this.props.interval,
        datafeed: this.props.candleChartAdapter,
        timeframe: this.props.timeframe,
        container_id: this.props.containerId as ChartingLibraryWidgetOptions['container_id'],
        library_path: this.props.libraryPath as string,
        timezone: this.props.timezone,
        toolbar_bg: this.context.components.graph.toolbarBg,
        theme: 'Dark',
        locale: 'en',
        disabled_features: [
          'use_localstorage_for_settings',
          'header_symbol_search',
          'compare_symbol',
          'header_compare',
          'control_bar',
          'header_undo_redo',
          'volume_force_overlay',
        ],
        enabled_features: ['hide_last_na_study_output'],
        fullscreen: this.props.fullscreen,
        autosize: this.props.autosize,
        studies_overrides: this.props.studiesOverrides,
        loading_screen: {
          backgroundColor: this.context.components.graph.loadingScreen.backgroundColor,
        },
        overrides: {
          'paneProperties.background': this.context.components.graph.paneProperties.background,
          'paneProperties.vertGridProperties.color': this.context.components.graph.paneProperties
            .vertGridProperties.color,
          'paneProperties.horzGridProperties.color': this.context.components.graph.paneProperties
            .horzGridProperties.color,
          'paneProperties.crossHairProperties.color': this.context.components.graph.paneProperties
            .crossHairProperties.color,
          'paneProperties.topMargin': this.context.components.graph.paneProperties.topMargin,

          // candle body color
          'mainSeriesProperties.candleStyle.upColor': this.context.components.graph
            .mainSeriesProperties.candleStyle.upColor,
          'mainSeriesProperties.candleStyle.downColor': this.context.components.graph
            .mainSeriesProperties.candleStyle.downColor,

          // candle up/down lines
          'mainSeriesProperties.candleStyle.wickUpColor': this.context.components.graph
            .mainSeriesProperties.candleStyle.wickUpColor,
          'mainSeriesProperties.candleStyle.wickDownColor': this.context.components.graph
            .mainSeriesProperties.candleStyle.wickDownColor,
          // candle borders
          'mainSeriesProperties.candleStyle.borderColor': this.context.components.graph
            .mainSeriesProperties.candleStyle.borderColor,
          'mainSeriesProperties.candleStyle.borderUpColor': this.context.components.graph
            .mainSeriesProperties.candleStyle.borderUpColor,
          'mainSeriesProperties.candleStyle.borderDownColor': this.context.components.graph
            .mainSeriesProperties.candleStyle.borderDownColor,
          // text color
          'scalesProperties.backgroundColor': this.context.components.graph.scalesProperties
            .backgroundColor,
          'scalesProperties.lineColor': this.context.components.graph.scalesProperties.lineColor,
          'scalesProperties.textColor': this.context.components.graph.scalesProperties.textColor,
          volumePaneSize: 'medium',
        },
        custom_css_url: '../charting-library-theme.css',
      };

      const tvWidget = new widget(widgetOptions);

      // Activate our adapters for this widget
      this.props.candleChartAdapter.notifyWidgetLoading(tvWidget);

      return new Promise((resolve) => {
        tvWidget.onChartReady(() => {
          if (!this.mounted) {
            // Widget has unmounted in the meantime. Cleanup
            this.props.candleChartAdapter.notifyWidgetLoading(null);
            try {
              this.tvWidget.remove();
            } catch (_) {}
            return resolve(false);
          }

          // Make global alterations based on
          this.tvWidget = tvWidget;
          this.tvWidgetSymbol = symbol;

          // Prevent chart from being tabbed into
          if (this.chartContainerRef.current && this.chartContainerRef.current.children.length) {
            this.chartContainerRef.current.children[0].setAttribute('tabindex', '-1');
          }

          this.props.candleChartAdapter.notifyWidgetReady(tvWidget);

          return resolve(true);
        });
      });
    });
  }

  destroyChart = (): Promise<boolean> => {
    if (!this.tvWidget) {
      return Promise.resolve(false);
    }

    const tvWidget = this.tvWidget;

    return this.props.candleChartAdapter.unloadWidget(tvWidget).then(
      () => {
        this.tvWidget = null;
        this.tvWidgetSymbol = null;

        try {
          tvWidget.remove();
        } catch (err) {
          // NOTE: Chart has a tendency to bork if being removed when already off the page. Ignore.
          this.logger.warn(`Error raised while destroying chart`, err);
          return false;
        }

        return true;
      },
      (err) => {
        this.logger.error(err);
        return false;
      }
    );
  };

  render() {
    return <$Chart id={this.props.containerId} ref={this.chartContainerRef} />;
  }
}

const connector = connect((state: IState) => ({
  symbol: getCurrentPairPath(state),
}));

export default connector(inject('candleChartAdapter', 'logger')(CandleChart));
