import React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { IState } from '../../../lib/store';
import monitorWithdrawalsByIpIcon from '../../../media/monitor-withdrawals-by-ip.png';
import { LifeVestIcon } from '../../../media/svg_icons';
import { II18nextT } from '../../../types/i18n';
import { ITranslations } from '../../../types/translations';
import { HideMobile } from '../../utility_components';
import { $InfoButton } from '../../widgets/buttons';
import Header from '../../widgets/Header';
import $Link from '../../widgets/Link';
import { $HeaderWrapper } from '../../widgets/PageHeaderWrappers';
import { $SettingsWrapper, SettingsSectionGeneric } from './settings_components';

interface ISupportSettingsProps extends ConnectedProps<typeof connector> {}

interface ISupportSettingsState {}

interface ISupportObject {
  icon: string;
  label: ITranslations;
  description: ITranslations;
  cta?: ITranslations;
  link: string;
  enabled?: boolean;
}
interface ISupportObjects {
  supportTeam: ISupportObject;
  knowledgeBase: ISupportObject;
}

type ISupportObjectKey = keyof ISupportObjects;

class SupportSettings extends React.PureComponent<ISupportSettingsProps, ISupportSettingsState> {
  static contextType: any = I18nContext;
  supportObjects: ISupportObjects = null;

  constructor(props) {
    super(props);

    this.supportObjects = {
      knowledgeBase: {
        icon: monitorWithdrawalsByIpIcon,
        label: 'settings:support.knowledgeBaseTitle',
        description: 'settings:support.knowledgeBaseDescription',
        cta: 'settings:support.knowledgeBaseCTA',
        link: this.props.env.links.knowledgeBase,
        enabled: this.props.env.whitelabel.name === 'xcalibra',
      },
      supportTeam: {
        icon: monitorWithdrawalsByIpIcon,
        label: 'settings:support.supportTeamTitle',
        description: 'settings:support.supportTeamDescription',
        cta: 'settings:support.supportTeamCTA',
        link: this.props.env.links.support,
        enabled: true,
      },
    };
  }

  render() {
    const { t }: II18nextT = this.context;
    return (
      <>
        <HideMobile>
          <$HeaderWrapper>
            <Header title={t('settings:sections.support')} icon={LifeVestIcon} variant="dark" />
          </$HeaderWrapper>
        </HideMobile>
        <$SettingsWrapper>
          {Object.keys(this.supportObjects).map((key) => {
            const supportObj = this.supportObjects[key as ISupportObjectKey];
            if (!supportObj.enabled) {
              return null;
            }
            return (
              <SettingsSectionGeneric
                key={key}
                icon={supportObj.icon}
                label={t(supportObj.label)}
                description={t(supportObj.description)}
                rightContent={
                  <$Link href={supportObj.link} target="_blank">
                    <$InfoButton>{t(supportObj.cta)}</$InfoButton>
                  </$Link>
                }
              />
            );
          })}
        </$SettingsWrapper>
      </>
    );
  }
}

const connector = connect(
  (state: IState) => ({
    env: state.env,
  }),
  null
);

export default connector(SupportSettings);
