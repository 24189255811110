import * as React from 'react';

const ChyngexTOS: React.FunctionComponent<{}> = () => {
  return (
    <div
      style={{
        maxWidth: 700,
        margin: '100px auto',
        fontSize: '1.2rem',
        textAlign: 'left',
      }}
    >
      <h1>Terms of use</h1>
      <p>
        Thank you for using the ChyngeX platform and services we make available to you (collectively
        the “Service”). The Service allows you to, among others, deposit, hold, withdraw, and
        exchange cryptocurrencies listed on our platform as well as track some cryptocurrency
        market, interact with other people through forum. The entity providing the Service is Chynge
        Singapore Pte Ltd, a company incorporated and operating under the laws of Singapore
        (referred to as “Chynge”, “we”, “us”, or “our”). References to our “Affiliates” in these
        terms means the other companies within our corporate group as well as Xcalibra Pte. Ltd.
        Your use of the Service is subject to these terms, which you should read carefully and make
        sure you understand it. If you do not understand these Terms or do not accept any part of
        it, then you may not use the Service. Our Privacy Policy explains how we treat your personal
        data and protect your privacy when you use the Service. You may use the Service if you are
        at least 18 years old. ChyngeX provides an online digital asset trading platform to trade
        digital payment tokens (“DPT”). You may conduct trading on our platform with other users.
        ChyngeX is not a buyer or a seller in the trades performed through the platform we provide.
        You must open an account and upload DPT prior to commencement of trading. You may request
        the withdrawal of your DPT, subject to the limitations as stated in the Terms. ChyngeX has
        the right to know the real transaction background and purpose of the users who use the
        product or service. You are obliged to provide the real, comprehensive, accurate information
        required by ChyngeX. Trading of DPT involves significant risk. The risk of loss in trading
        or holding digital assets can be substantial. You should therefore carefully consider
        whether trading in DPT is suitable for you in light of your financial condition. Our service
        is not available to residents or nationals of the following countries: Iran, Cuba, China,
        Venezuela, South Sudan, North Korea, Syria, Libya, Yemen, Zimbabwe and Azerbaijan. The
        change to the aforementioned list of the countries or nationalities does not constitute a
        material change of these Terms and does not require any notification. You are responsible
        for providing accurate information about your country of residence and nationality. You
        understand that providing false information about your country of residence or nationality
        may be an offence and that ChyngeX may be legally obliged to submit information about such
        offence to law enforcement agents or authorities.
      </p>

      <h4>Your Account</h4>

      <p>
        In order to use the Service, you must first register by providing your name, e-mail and
        password, and agree to these Terms. Depending on legal requirements we may ask that you
        provide additional documentation confirming your identity, your place of residence, and your
        source of funds before we allow you to trade or upon reaching certain thresholds of revenue.
        You agree to not enable anyone to use your account. You are solely responsible for
        protecting and safeguarding any keys, certificates, passwords, access codes, user IDs that
        have been provided to you or that are generated in connection with your use of the Service.
        If you lose your password, you may not be able to access your account. You agree to notify{' '}
        us immediately of any attempt of or unauthorized use of your account.
      </p>

      <h4>Permissions and Restrictions</h4>

      <p>
        You may access and use the Service as made available to you, as long as you comply with
        these Terms and the law. You must not: access, reproduce, download, distribute, transmit,
        broadcast, display, sell, license, alter, modify or otherwise use any part of the Service
        except as specifically permitted by the Service;
      </p>
      <ul>
        <li>
          circumvent, disable, fraudulently engage, or otherwise interfere with the service (or
          attempt to do any of these things), including security-related features or features;
        </li>
        <li>
          access the Service using any automated means (such as robots, botnets or scrapers) except
          as permitted by applicable law;
        </li>
        <li>
          collect or use any information that might identify a person (for example, harvesting
          usernames), unless permitted by that person or allowed under section (3) above;
        </li>
      </ul>

      <p>
        Any rights not expressly granted to you in these Terms remain the rights of ChyngeX or the
        respective rights holders. This means, for example, that using the Service does not give you
        ownership of any intellectual property rights exhibited in the platform you access.
      </p>

      <h4>Changes to the Service</h4>

      <p>
        ChyngeX is constantly improving the Service. We may also need to alter or discontinue the
        Service, or any part of it, in order to make performance or security improvements, change
        functionality and features, make changes to comply with law, or prevent illegal activities
        on or abuse of our systems. Whenever reasonably possible, we will provide notice when we
        discontinue or make material changes to our Service that will have an adverse impact on the
        use of our Service. However, there will be times when we need to make such changes without
        notice, such as where we need to take action to improve the security and operability of our
        Service, prevent abuse, or meet our legal requirements.
      </p>

      <h4>Account Suspension &amp; Termination</h4>
      <p>
        You may stop using the Service and terminate your account at any time. ChyngeX is allowed to
        suspend or terminate your access and/or your ChyngeX account if you materially or repeatedly
        breach these Terms or we are legally required to do so or we reasonably believe there has
        been conduct that creates liability or harm to any user, other third party, ChyngeX or our
        Affiliates. We will notify you with the reason for termination or suspension unless we
        reasonably believe that doing so would violate the law or cause harm to any user, other
        third party, ChyngeX or our Affiliates. If your ChyngeX account is terminated or access to
        the Service restricted, you may continue using certain aspects of the Service (such as
        viewing only) without an account, and this Agreement will continue to apply to such use.
      </p>

      <h4>Software</h4>

      <p>
        When the Service requires or includes downloadable software we give you a personal,
        worldwide, royalty-free, non-assignable and non-exclusive licence to use the software
        provided to you as part of the Service. This licence is for the sole purpose of enabling you
        to use the Service as provided by us, in the manner permitted by these Terms. You are not
        allowed to copy, modify, distribute, sell, or lease any part of the software, or to
        reverse-engineer or attempt to extract the source code of that software. Some software used
        in our Service may be offered under an open source licence with some provisions in an open
        source licence that expressly override some of these Terms.
      </p>

      <h4>Warranty</h4>

      <p>
        We provide the Service with reasonable care and skill. We do not make any specific promises
        about the Service.
      </p>

      <h4>Disclaimer</h4>

      <p>
        By law, consumers have certain rights that cannot be excluded or altered by a contract.
        Nothing in these Terms affects those rights you may have as a consumer.
      </p>

      <h4>Limitation of Liability</h4>

      <p>
        Nothing in these Terms is intended to exclude or limit any party’s liability for (a) death
        or personal injury; (b) fraud; (c) fraudulent misrepresentation; or (d) any liability that
        cannot be excluded or limited by law. To the extent permitted by applicable law, ChyngeX and
        its Affiliates will not accept responsibility for:
      </p>

      <ul>
        <li>losses that were not caused by us or our Affiliates’ breach of these Terms; or</li>
        <li>
          any loss or damage that was not, at the time that you created ChyngeX account a reasonably
          foreseeable consequence of us or our Affiliates breaching these Terms.
        </li>
      </ul>
      <p>
        ChyngeX and its Affiliates’ total liability for any claims arising from or relating to the
        Service is limited to SGD1,000.
      </p>

      <h4>Third-Party Links</h4>

      <p>
        The Service may contain links to third-party websites and online services that are not owned
        or controlled by us. ChyngeX has no control over, and assumes no responsibility for such
        websites and online services.
      </p>

      <h4>Modifying this Agreement</h4>

      <p>
        We may modify these Terms and will provide reasonable advance notice of any material
        modifications to these Terms and the opportunity to review and accept any changes. The
        changes addressing newly available features of the Service or changes made for legal reasons
        may be effective immediately. Any changes will only apply going forward. If you do not agree
        to the modified Terms, you should discontinue your use of the Service. If it turns out that
        a particular term of this Agreement is not enforceable for any reason, this will not affect
        any other terms. We may transfer all or part of these Terms to an Affiliate or to a third
        party.
      </p>

      <h4>Waiver</h4>

      <p>
        If you do not comply with these Terms and we do not take action immediately, this doesn’t
        mean that we are giving up any rights that we may have (such as the right to take action in
        the future).
      </p>

      <h4>Governing Law</h4>

      <p>
        These Terms, and your relationship with ChyngeX under these Terms, will be governed by the
        laws of the Republic of Singapore, and legal proceedings may be brought in the courts in
        Singapore.
      </p>

      <ul>
        <li>
          You will indemnify ChyngeX , its Affiliates, directors, officers, employees, and users
          against all liabilities, damages, losses, costs, fees (including legal fees), and expenses
          relating to any allegation or third-party legal proceeding to the extent arising out of or
          related to: (a) your access to and use of the Service; (b) your breach of these Terms; or
          (c) your breach of any third-party right, including any intellectual property,
          confidentiality, or privacy right;
        </li>
        <li>
          These Terms are governed by the laws of the Republic of Singapore, and you and ChyngeX
          submit to the exclusive jurisdiction of the Singapore courts in relation to any dispute
          (contractual or non-contractual) concerning these terms, but we will still be allowed to
          apply for injunctive remedies (or other equivalent types of urgent legal remedy) in any
          jurisdiction;
        </li>
        <li>
          You and ChyngeX agree that written notice of any legal claim arising out of or related to
          the Service must be provided to the other party, and the claim filed within one year from
          the date of the event or occurrence from which the claim arose.
        </li>
      </ul>
    </div>
  );
};

export default ChyngexTOS;
