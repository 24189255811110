import { ICurrency } from '../types/backend_definitions';

export type ICurrencyDisplay = 'symbol' | 'code' | 'name';

/**
 * Returns an array of browser locales with the option to return only the lanugage code
 * @param options
 */
export function getBrowserLocales(options = { languageOnly: false }) {
  const browserLocales =
    navigator.languages && navigator.languages.length
      ? navigator.languages
      : navigator.language
      ? [navigator.language]
      : undefined;

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales.map((locale) => {
    return options.languageOnly ? locale.split(/-\|_/)[0] : locale;
  });
}

interface ILocalizeNumberStringOptions {
  currency?: ICurrency;
  currencyDisplay?: ICurrencyDisplay;
  decimals?: number;
}

/**
 * Convert a number like value to a locale string representation of it
 * @param {number|string} value
 * @param {ILocalizeNumberStringOptions} options
 */
export function localizeNumberString(
  value: number | string,
  options: ILocalizeNumberStringOptions = { decimals: 2 }
): string {
  if (typeof value === 'string') {
    value = Number(value);
  }

  const locales = getBrowserLocales();

  const _options = {
    style: options.currency ? 'currency' : 'decimal',
    currency: options.currency,
    currencyDisplay: options.currencyDisplay,
    minimumFractionDigits: options.decimals,
    maximumFractionDigits: options.decimals,
  };

  let localized;
  if (Intl && Intl.NumberFormat) {
    localized = new Intl.NumberFormat(locales, _options).format(value);
  } else {
    localized = value.toLocaleString(locales, _options);
  }

  return localized;
}

export const localizeIntegerString = (value, options = {}) =>
  localizeNumberString(value, { ...options, decimals: 0 });
