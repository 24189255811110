import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/types';

import { NON_HTTP_ERROR_CODE } from './http';
import { IStateGetter } from './store';

interface ISentry {
  withScope: typeof Sentry.withScope;
  init: typeof Sentry.init;
  captureException: typeof Sentry.captureException;
  captureMessage: typeof Sentry.captureMessage;
}

export class SentryIntegration {
  public sentry: ISentry;

  constructor(private getState: IStateGetter) {}

  init() {
    const { env } = this.getState();
    if (!this.sentry && env.sentryDSN) {
      // Init real sentry if error server is configured
      this.sentry = Sentry;
      this.sentry.init({
        dsn: env.sentryDSN,
        release: env.buildNumber,
      });
    }
  }

  report(subsystem: string, err: string | Error | any, additionalData?: Error | any) {
    if (!this.sentry) {
      // Nothing to do
      return;
    }

    // Try to discover an error object, if given
    let errorOb;
    if (err instanceof Error) {
      errorOb = err;
    } else if (additionalData instanceof Error) {
      errorOb = additionalData;
      // Consume additional data
      additionalData = undefined;
    }

    const code = (errorOb && errorOb.code) || 0;

    const severity =
      code >= 400 && code < 500
        ? // These are validation errors and such
          Severity.Info
        : code > 0 && code !== NON_HTTP_ERROR_CODE
        ? // These are server errors produced by backend. They can be warnings here
          Severity.Warning
        : // Everything else is error
          Severity.Error;

    this.sentry.withScope((scope) => {
      scope.setLevel(severity);

      // Set subsystem
      scope.setExtra('subsystem', subsystem);

      // Add details from any additional passed object
      if (typeof additionalData === 'object' && !(additionalData instanceof Error)) {
        for (const key in additionalData) {
          if (Object.prototype.hasOwnProperty.call(additionalData, key)) {
            scope.setExtra(key, additionalData[key]);
          }
        }
      }

      const state = this.getState();
      if (state.user && state.session) {
        // Include auth information
        scope.setUser({
          id: state.user.id,
          email: state.user.email,
        });
        // TODO Create a session identifier that can be used for talking about sessions, but can't be used to impersonate a session.
        // scope.setExtra('session', authData.session.access_token);
      }

      if (errorOb) {
        // Report as error object
        this.sentry.captureException(errorOb);
      } else {
        // Report as message
        const message = (err && err.message) || (err && String(err)) || 'Error';
        this.sentry.captureMessage(err, severity);
      }
    });
  }
}
