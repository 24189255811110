import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { isUserLoggedIn } from '../../../selectors/auth';
import { II18nextT } from '../../../types/i18n';
import { $ToggleMenu, $ToggleMenuButton } from '../../widgets/ToggleMenu';
import LastTrades from './LastTrades';

const $MenuWrapper = styled.div``;

const $TradesButton = styled($ToggleMenuButton).attrs((p) => ({
  hasTriangle: true,
  activeColor: p.theme.components.lastTrades.activeMenuItemColor,
}))`
  font-size: ${(p) => p.theme.fontSize.large} !important;

  @media only screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    font-size: ${(p) => p.theme.layout.exchange.sectionTitle.iphonePlus.fontSize}!important;
    line-height: ${(p) => p.theme.layout.exchange.sectionTitle.iphonePlus.fontSize}!important;
  }
`;

const $OnlyMarketTradesTitle = styled.h4`
  @media only screen and ${(p) => p.theme.device.mobile_IphonePlus} {
    font-size: ${(p) => p.theme.layout.exchange.sectionTitle.iphonePlus.fontSize};
    line-height: ${(p) => p.theme.layout.exchange.sectionTitle.iphonePlus.fontSize};
  }
`;

interface ILastTradesSwitcherProps extends ConnectedProps<typeof connector> {
  maxMessages?: number;
  disabled?: boolean;
}

interface ILastTradesSwitcherState {
  tradeType: 'user' | 'market';
}

class LastTradesSwitcher extends React.PureComponent<
  ILastTradesSwitcherProps,
  ILastTradesSwitcherState
> {
  static contextType: any = I18nContext;

  constructor(props) {
    super(props);
    this.state = { tradeType: 'market' };
  }

  render() {
    const { t }: II18nextT = this.context;

    return (
      <>
        {this.props.loggedIn ? (
          <$MenuWrapper>
            <$ToggleMenu>
              <$TradesButton
                onClick={() =>
                  this.setState({
                    tradeType: 'user',
                  })
                }
                active={this.state.tradeType === 'user'}
              >
                {t('trades.myTrades')}
              </$TradesButton>
              <$TradesButton
                onClick={() =>
                  this.setState({
                    tradeType: 'market',
                  })
                }
                active={this.state.tradeType === 'market'}
              >
                {t('trades.marketTrades')}
              </$TradesButton>
            </$ToggleMenu>
          </$MenuWrapper>
        ) : (
          <$OnlyMarketTradesTitle>{t('trades.marketTrades')}</$OnlyMarketTradesTitle>
        )}
        <LastTrades tradeType={this.state.tradeType} />
      </>
    );
  }
}

const connector = connect((state: IState) => ({
  loggedIn: isUserLoggedIn(state),
}));

export default connector(LastTradesSwitcher);
