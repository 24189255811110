import * as React from 'react';
import { I18nContext } from 'react-i18next';

import maintenanceImage from '../../media/maintenance.png';
import { II18nextT } from '../../types/i18n';
import { SimplePageLayout } from '../layout/SimplePageLayout';

interface IMaintenancePageProps {}
interface IMaintenancePageState {}

class MaintenancePage extends React.PureComponent<IMaintenancePageProps, IMaintenancePageState> {
  static contextType: any = I18nContext;

  render() {
    const { t }: II18nextT = this.context;

    return (
      <SimplePageLayout errorImageSrc={maintenanceImage} errorImageAlt="Maintenance page">
        {/* <p>
          <$ColorText color="white">lorem ipsum</$ColorText>
        </p> */}
        {/* <$InfoButton>
          <$Link as={Link} to={R.EXCHANGE_HOME}>
            GO BACK
          </$Link>
        </$InfoButton> */}
      </SimplePageLayout>
    );
  }
}

export default MaintenancePage;
