import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { CSSObject } from 'styled-components';

import { IActiveRequestsKeys } from '../../actions/request_active';
import { IState } from '../../lib/store';
import styled from '../../lib/styled_components';
import { ISecurityEventInfoPaginationState } from '../../selectors/auth';
import { ICardPaymentsPaginationState } from '../../selectors/card_payments';
import { someRequestActive } from '../../selectors/request_active';
import { ITradesPaginationState } from '../../selectors/trades';
import {
  IDepositsPaginationState,
  IWithdrawalsPaginationState,
} from '../../selectors/transactions';
import { DeepReadonly } from '../../types/typescript_helpers';
import { IUserActiveOrdersPaginationState } from '../components/exchange/UserActiveOrders';
import { IDepositPaginationState } from '../components/transactions/Deposit';
import { IWithdrawPaginationState } from '../components/transactions/Withdraw';
import LoadingAnimation, { $LoadingAnimationWrapper } from './LoadingAnimation';
import { $TableRow } from './Table';

const $TableLoadingAnimationWrapper = styled($LoadingAnimationWrapper)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const $TableActionIndicatorWrapper = styled($TableRow)<{ disabled?: boolean }>`
  height: 26px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background: ${(p) => (p.disabled ? 'transparent' : p.theme.widgets.table.backgroundHover)};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};

  &:hover {
    background: ${(p) => (p.disabled ? 'transparent' : p.theme.widgets.table.backgroundHover)};
    cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  }

  div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

type IPaginationState = DeepReadonly<
  | ICardPaymentsPaginationState
  | IUserActiveOrdersPaginationState
  | IDepositsPaginationState
  | IDepositPaginationState
  | ITradesPaginationState
  | IWithdrawalsPaginationState
  | IWithdrawPaginationState
  | ISecurityEventInfoPaginationState
>;

interface IOwnProps {
  requests: IActiveRequestsKeys[];
}

interface ITableActionIndicatorProps extends IOwnProps, ConnectedProps<typeof connector> {
  style?: CSSObject;
  paginationState: IPaginationState;
  itemsCount: number;
  actionFn: () => any;
}

interface ITableActionIndicatorState {}

class TableActionIndicator extends React.PureComponent<
  ITableActionIndicatorProps,
  ITableActionIndicatorState
> {
  static contextType: any = I18nContext;
  loadCount: number = 0;
  updateCounter: boolean = true;

  calculateCounter() {
    const unloadedRecords = this.props.paginationState.total_records - this.props.itemsCount;
    const loadCount =
      unloadedRecords > this.props.paginationState.page_size
        ? this.props.paginationState.page_size
        : unloadedRecords;
    this.loadCount = loadCount;
  }

  render() {
    // TODO: Should be deleted when pagination is fixed
    const isLastPage = this.props.paginationState.total_pages === this.props.paginationState.page;
    // Initialize counter once we recieve truthy values
    this.calculateCounter();
    // Disable TableIndicatorWrapper
    if ((!this.props.itemsCount && !this.loadCount) || !this.loadCount || isLastPage) {
      return null;
    }

    return (
      <$TableActionIndicatorWrapper
        key={'actionIndicator'}
        disabled={this.props.requestActive}
        style={this.props.style}
        onClick={() => this.props.actionFn && this.props.actionFn()}
      >
        <LoadingAnimation
          requests={this.props.requests}
          wrapperComponent={$TableLoadingAnimationWrapper}
        >
          {this.context.t('table.loadMore', { count: this.loadCount })}
        </LoadingAnimation>
      </$TableActionIndicatorWrapper>
    );
  }
}

const connector = connect((state: IState, props: IOwnProps) => ({
  requestActive: !props.requests || someRequestActive(props.requests, state),
}));

export default connector(TableActionIndicator);
