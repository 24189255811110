import { ISecurityEvents } from '../actions/auth/auth';
import { IState } from '../lib/store';
import { IPaginated } from '../types/api';
import { ISecurityEventInfo, ISession } from '../types/auth';
import { IApiPublicCustomerInfo } from '../types/backend_definitions';
import { ICountryCode, IGeoLocationPinning } from '../types/backend_definitions';
import { DeepReadonly, Omit } from '../types/typescript_helpers';
import { IHorizontalScrollTableData } from '../views/widgets/HorizontalScrollTable';

export const getSession = (state: IState): ISession => state.session;

export const getUser = (state: IState) => state.user || null;

export const getUserKeepAliveSettings = (state: IState): boolean =>
  state.user ? Boolean(state.user.enable_keep_alive) : false;
export const getUserWithdrawalConfirmationsSettings = (state: IState): boolean =>
  state.user ? Boolean(state.user.enable_withdrawal_confirmations) : false;
export const getUserOrderConfirmationsSettings = (state: IState): boolean =>
  state.user ? Boolean(state.user.enable_order_confirmations) : false;
export const getUserOrderNotificationsSettings = (state: IState): boolean =>
  state.user ? Boolean(state.user.enable_order_notifications) : false;

export const getUserTFAStatus = (state: IState): boolean =>
  state.user ? Boolean(state.user.tfa_enabled_at) : false;

export const getUserGeoLocationPinningStatus = (state: IState): IGeoLocationPinning =>
  state.user ? state.user.geo_location_pinning : 'off';

export const getUserNickname = (state: IState): string => state.user && state.user.nickname;

export const getUserEmail = (state: IState): string => state.user && state.user.email;

export const isUserEmailVerified = (state: IState): boolean =>
  state.user && !!state.user.email_verified_at;

export const isUserSuspended = (state: IState): boolean => state.user && !!state.user.suspended_at;

export const isUserLoggedIn = (state: IState): boolean => Boolean(getSession(state));

export const getTrollboxBanDurationLeftMs = (state: IState): number => {
  if (!state.user || !state.user.trollbox_banned_at) {
    return null;
  }

  if (state.user.trollbox_ban_expires_at) {
    const durationLeft = new Date(state.user.trollbox_ban_expires_at).valueOf() - Date.now();
    return durationLeft > 0 ? durationLeft : null;
  }

  // If banned permanently, return some impossible duration
  return -1;
};

export const getTrollboxBanReason = (state: IState): string =>
  state.user && state.user.trollbox_banned_at && state.user.trollbox_ban_reason;

// Sessions
export const getSessions = (state: IState) => state.sessions;

// Customer
export const getCustomer = (state: IState): IApiPublicCustomerInfo => state.customer;
export const getKYCLevel = (state: IState): number =>
  state.user ? state.user.kyc_level_granted : null;

// Registration Policy
export const getRegistrationPolicy = (state: IState) => state.registrationPolicy;

export const getCountryBlacklist = (state: IState) =>
  state.registrationPolicy && state.registrationPolicy.country_blacklist;

export const isNicknamePending = (state: IState): boolean =>
  state.user && state.user.requested_nickname && !state.user.nickname_decline_reason;

// KYC1 policy
export const getKYC1Policy = (state: IState) => state.kyc1Policy;

export const getKYC1StateWhitelist = (state: IState) => state.kyc1Policy && state.kyc1Policy.us_state_whitelist;

// Security Events
export const getSecurityEvents = (state: IState) => state.securityEvents;

export const getSecurityEventsForType = (
  state: IState,
  eventType: keyof ISecurityEvents
): DeepReadonly<IPaginated<ISecurityEventInfo>> => state.securityEvents[eventType];

export interface ISecurityEventInfoFixed extends Pick<ISecurityEventInfo, 'timestamp'> {}

export interface ISecurityEventInfoHorizontalScrollTableData
  extends IHorizontalScrollTableData<ISecurityEventInfoFixed, ISecurityEventInfo> {}
export interface ISecurityEventInfoPaginationState
  extends Omit<IPaginated<ISecurityEventInfo>, 'items'> {
  items: ISecurityEventInfoHorizontalScrollTableData;
}

// Helper functions
export const createSecurityEventsHorizontalScrollTableData = (
  data
): ISecurityEventInfoPaginationState => {
  const { items, ...rest } = data;
  return items.reduce(
    (
      modifiedContent: ISecurityEventInfoPaginationState,
      securityEvent: ISecurityEventInfo
    ): ISecurityEventInfoPaginationState => {
      const { timestamp } = securityEvent;
      const fixed = { timestamp };

      modifiedContent.items.fixed.push(fixed);
      modifiedContent.items.scrollable.push(securityEvent);
      return modifiedContent;
    },
    { ...rest, items: { fixed: [], scrollable: [], length: data.items.length } }
  );
};
