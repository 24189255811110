import { createSelector } from 'reselect';

import { Logger } from '../lib/logger';
import { commit, val } from '../lib/redux_helpers';
import { IState, IThunkMethod, Store } from '../lib/store';
import { IUser } from '../types/auth';
import { IApiKYC1Policy, IApiPublicCustomerInfo } from '../types/backend_definitions';
import { TimeUnits } from '../types/constants';
import { DeepReadonly } from '../types/typescript_helpers';

export type IAccountAlert =
  | 'suspended'
  | 'email_verification'
  | 'kyc0'
  | 'add_phone'
  | 'us_policy_forbidden'
  | 'us_policy_undefined';

const doGetAccountAlert = (
  user: DeepReadonly<IUser>,
  customer: DeepReadonly<IApiPublicCustomerInfo>,
  kyc1Policy: DeepReadonly<IApiKYC1Policy>
): IAccountAlert => {
  if (!user || !customer) {
    return null;
  }

  if (!!user.suspended_at) {
    return 'suspended';
  }

  if (!user.email_verified_at) {
    return 'email_verification';
  }

  if (customer.residence_country && customer.residence_country === 'US') {
    const allowedStates = kyc1Policy && kyc1Policy.us_state_whitelist;
    if (customer.residence_us_state === ('' as any)) {
      // should only happen when we cannot identify customer's state based on the postal code
      return 'us_policy_undefined';
    }

    if (customer.residence_us_state && allowedStates) {
      return allowedStates.includes(customer.residence_us_state) ? null : 'us_policy_forbidden';
    }
  }

  if (!(user.kyc_level_granted >= 1)) {
    return 'kyc0';
  }

  if (user.kyc_level_granted >= 1 && customer.phone_verified === false) {
    return 'add_phone';
  }

  return null;
};

export const getAccountAlert = createSelector(
  [
    (state: IState) => state.user || null,
    (state: IState) => state.customer,
    (state: IState) => state.kyc1Policy,
  ],
  doGetAccountAlert
);

interface IAccountAlertObservation {
  alert: IAccountAlert;
  timestamp: number;
}

export interface IAccountAlertState {
  accountAlertLastObservation: IAccountAlertObservation;
  accountAlertMuted: boolean;
}

export const ACCOUNT_ALERT_STATE: IAccountAlertState = {
  accountAlertLastObservation: null,
  accountAlertMuted: null,
};

export const submitAccountAlertSeen = (alert: IAccountAlert): IThunkMethod => (
  dispatch,
  getState
) => {
  const timestamp = Date.now();
  dispatch(
    commit(`Notify account alert "${alert}" was seen by user at timestamp ${timestamp}`, {
      accountAlertLastObservation: val<IAccountAlertObservation>({
        alert,
        timestamp,
      }),
    })
  );
};

const DEFAULT_MUTE_DURATION = 1 * TimeUnits.hour;
const MUTE_DURATION_PER_ALERT: { [alert in IAccountAlert]: number } = {
  // Nag users often, they need this
  email_verification: TimeUnits.minute * 15,

  // Nag users often, they need this
  kyc0: TimeUnits.minute * 15,

  // No action is expected, so we don't have to unmute ever
  suspended: null,

  // Nag every hour?
  add_phone: TimeUnits.hour,

  us_policy_forbidden: null,
  us_policy_undefined: null,
};

// *********************************************************************************************************************

/**
 * This service will listen to store changes and mute or unmute alerts.
 */
export class AccountAlertMuter {
  private log: Logger;
  private unmuteTimeout;

  /**
   * Remember which observation we've last handled. Every time this changes, we will reevaluate
   */
  private lastHandledObservation: IAccountAlertObservation = null;

  /**
   * Remember which alert we've last handled. Every time this changes, we will reevaluate
   */
  private lastHandledAlert: IAccountAlert = null;

  /**
   * Settings can supply an override number, for testing
   */
  private readonly muteDurationOverride: number;

  constructor(private store: Store, logger: Logger) {
    this.log = logger.prefixed(AccountAlertMuter);

    this.muteDurationOverride = this.store.getState().env.accountAlertMuteDurationOverride || null;

    this.store.subscribe(this.onStoreUpdate);
  }

  onStoreUpdate = () => {
    const state = this.store.getState();
    const currentAccountAlert = getAccountAlert(state);

    if (currentAccountAlert !== this.lastHandledAlert) {
      this.onAccountAlertHasChanged(currentAccountAlert);
    }
    if (
      state.accountAlertLastObservation &&
      state.accountAlertLastObservation !== this.lastHandledObservation &&
      state.accountAlertLastObservation.alert === currentAccountAlert
    ) {
      this.onUserHasSeenCurrentAlert(state.accountAlertLastObservation);
    }
  };

  onAccountAlertHasChanged = (newAccountAlert: IAccountAlert) => {
    const lastHandledAlert = this.lastHandledAlert;
    this.lastHandledAlert = newAccountAlert;

    if (this.store.getState().accountAlertMuted) {
      clearTimeout(this.unmuteTimeout);
      this.store.dispatch(
        commit(
          `Unmute account alert due to a change of alert from "${lastHandledAlert}" to "${newAccountAlert}"`,
          {
            accountAlertMuted: val(false),
          }
        )
      );
    }
  };

  onUserHasSeenCurrentAlert = (observation: IAccountAlertObservation) => {
    this.lastHandledObservation = observation;

    // Reset the unmute timer
    clearTimeout(this.unmuteTimeout);
    const muteDuration =
      this.muteDurationOverride !== null
        ? this.muteDurationOverride
        : MUTE_DURATION_PER_ALERT[observation.alert];
    if (muteDuration) {
      this.unmuteTimeout = setTimeout(this.onUnmuteTimeout, muteDuration);
    }

    // If we are not muting already, do so now
    if (!this.store.getState().accountAlertMuted) {
      this.store.dispatch(
        commit(
          `Mute account alert "${observation.alert}" ${
            muteDuration ? 'for ' + muteDuration + 'ms' : 'forever'
          }`,
          {
            accountAlertMuted: val(true),
          }
        )
      );
    }
  };

  onUnmuteTimeout = () => {
    this.store.dispatch(
      commit(`Unmute account alert "${this.lastHandledAlert}" after timeout has expired`, {
        accountAlertMuted: val(false),
      })
    );
  };
}
