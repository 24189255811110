import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getSimpleNotificationData, notifyError } from '../../../../actions/app';
import {
  handleVerificationSubmitted,
  requestKycLevel1Base,
  requestKycLevel1VQF,
} from '../../../../actions/auth/customer';
import {
  clearPhoneVerificationState,
  isPhoneVerificationModalOpen,
  openPhoneVerificationModal,
} from '../../../../bl/phone_verification';
import { COUNTRIES } from '../../../../lib/countries';
import { ITranslatedError, ValidationWrapper, clientValidator } from '../../../../lib/errors';
import { intentionallyUntranslated } from '../../../../lib/i18n';
import { Logger } from '../../../../lib/logger';
import { inject } from '../../../../lib/react_container';
import R from '../../../../lib/routes';
import { IState } from '../../../../lib/store';
import styled, { zIndex } from '../../../../lib/styled_components';
import { CloseIcon } from '../../../../media/svg_icons';
import { getCustomer, getKYC1StateWhitelist } from '../../../../selectors/auth';
import {
  IApiPublicCustomerInfo,
  IApiRequestKYC1Payload,
  IKYCTrack,
} from '../../../../types/backend_definitions';
import { II18nextT } from '../../../../types/i18n';
import { FullScreenOverlay } from '../../../layout/FullScreenOverlay';
import { $SuccessMessage } from '../../../layout/KioskLayout';
import { UserMenuButton } from '../../../layout/main_layout/UserMenuButton';
import { $InfoButton } from '../../../widgets/buttons';
import $Checkbox from '../../../widgets/Checkbox';
import { $InputDark } from '../../../widgets/Input';
import {
  $AlertWrapper,
  $Bottom,
  $KycPanelEmphasized,
  $KycPanelText,
  $KycPanelTitle,
} from '../../../widgets/KycElements';
import $Label from '../../../widgets/Label';
import $Link from '../../../widgets/Link';
import LoadingAnimation from '../../../widgets/LoadingAnimation';
import Logo from '../../../widgets/Logo';
import { CountrySelect, SimpleSelect, StateSelect } from '../../../widgets/Select';
import KYCFailureNotice from './KYCFailureNotice';

/**
 * https://github.com/Jumio/implementation-guides/blob/master/netverify/netverify-web-v4.md#eventdata-object
 */
interface INetVerifyEvent {
  authorizationToken: string;
  customerInternalReference: string;
  transactionReference: string;
  dateTime: string;
  eventType: number;
  payload: {
    metainfo: object;
    value:
      | 'loaded' // Netverify loaded in the user's browser
      | 'success' // Images were accepted for verification.
      | 'error'; // Verification could not be completed due to an error.;
  };
}

const $ObtainKycLevel1 = styled.div`
  text-align: center;
`;

const $BottomPartHolder = styled.div`
  // This is just to make sure there is always some padding towards the bottom
  min-height: 100px;
`;

const $FormWrapper = styled($KycPanelEmphasized)`
  max-width: ${(p) => p.theme.components.kyc.containerWidth};
`;

const $DobErrorWrapper = styled.div`
  width: 100%;
  margin-top: -13px;
  margin-bottom: 5px;
`;

const $FormInputWrapper = styled('div')<{ inline?: boolean }>`
  display: ${(p) => (p.inline ? 'flex' : 'block')};
  width: 100%;
  justify-content: ${(p) => p.inline && 'space-between'};
  padding: 0.8em 0;

  > label > input,
  > label > div {
    margin-top: 0.2em;
  }
`;

const $FormInput = styled($InputDark).attrs({ required: true })`
  height: 35px;
`;

const $SubLabel = styled($Label)`
  flex-grow: 1;
  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

const $UploadWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  > label {
    display: block;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }
`;

const $UploadContent = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-around;
`;

const $Hr = styled.hr`
  height: 1.5px;
  width: 100%;
  background-color: ${(p) => p.theme.components.kyc.level1.hr.backgroundColor};
  border: none;
`;

const $VQFConfirmation = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const $VerificationHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${(p) => p.theme.layout.headerHeight.default}px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > :first-child {
    width: ${(p) => p.theme.layout.sidebarWidth}px;
    text-align: center;

    svg {
      width: ${(p) => p.theme.components.kyc.iconSize};
      height: ${(p) => p.theme.components.kyc.iconSize};
    }
  }
  > :last-child {
    text-align: center;
    height: 100%;
  }
`;

const $VerificationContainer = styled.div`
  margin: 0 auto;
  height: 100%;
  max-width: ${(p) => p.theme.layout.maxWidth}px;
  position: relative;
`;

const $VerificationContent = styled.div`
  position: absolute;
  top: ${(p) => p.theme.layout.headerHeight.default}px;
  left: 0;
  right: 0;
  bottom: 0;
  background: white; // To match jumio
  > iframe {
    border: none;
  }
`;

const $WaitingAnimationWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

interface IObtainKycLevel1Props extends ConnectedProps<typeof connector> {
  kycTrack: IKYCTrack;
  granted?: boolean;
  logger: Logger;
}

interface IObtainKycLevel1State extends IApiRequestKYC1Payload {
  validationError: ITranslatedError;
  verificationUrl?: string;
  confirmedInformation?: boolean;
}

const toPayload = (src: IApiRequestKYC1Payload): IApiRequestKYC1Payload => {
  return {
    nationality: src.nationality,
    first_name: src.first_name,
    middle_name: src.middle_name,
    last_name: src.last_name,
    gender: src.gender,
    residence_address: src.residence_address,
    residence_city: src.residence_city,
    residence_postal_code: src.residence_postal_code,
    residence_country: src.residence_country || src.nationality,
    residence_us_state: src.residence_us_state || null,
    phone: src.phone,
  };
};

class ObtainKycLevel1 extends React.PureComponent<IObtainKycLevel1Props, IObtainKycLevel1State> {
  static contextType: any = I18nContext;

  get isVQF() {
    return this.props.kycTrack === 'vqf';
  }

  constructor(props: IObtainKycLevel1Props) {
    super(props);

    this.state = {
      ...toPayload(props.customer || ({} as IApiPublicCustomerInfo)),
      validationError: null,
      verificationUrl: null,
    };
  }

  componentDidMount(): void {
    window.addEventListener('message', this.onNetVerifyMessage, false);
  }

  componentDidUpdate(
    prevProps: Readonly<IObtainKycLevel1Props>,
    prevState: Readonly<IObtainKycLevel1State>,
    snapshot?: any
  ) {
    if (
      this.props.phoneVerificationState &&
      this.props.phoneVerificationState.result &&
      this.props.phoneVerificationState.purpose === 'onboarding'
    ) {
      // This is our callback. Continue the flow.
      this.props.clearPhoneVerificationState();
      this.props.requestKycLevel1Base(toPayload(this.state)).then((verificationUrl) => {
        this.setState({
          verificationUrl,
        });
      });
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('message', this.onNetVerifyMessage);
  }

  /**
   * This will check current state of verification popup and update body overflow accordingly.
   * We call it on every render, so we try to make this cheap and not do more than necessary.
   */
  updateBodyOverflow = () => {
    const overflow = document.body.style.overflow;
    if (this.state.verificationUrl && overflow !== 'hidden') {
      document.body.style.overflow = 'hidden';
    } else if (!this.state.verificationUrl && overflow !== 'auto') {
      document.body.style.overflow = 'auto';
    }
  };

  onNetVerifyMessage = (msg) => {
    if (msg.origin && msg.origin.indexOf('netverify.com') < 0) {
      // Not an event for us
      return;
    }

    let event: INetVerifyEvent;
    try {
      event = JSON.parse(msg.data);
    } catch (err) {
      this.props.logger.error(err);
      return;
    }

    if (!event.payload || !event.payload.value) {
      this.props.logger.error(`Unexpected NetVerify event content: ${msg.data}`);
      return;
    }

    switch (event.payload.value) {
      case 'error':
        const { t }: II18nextT = this.context;
        this.props.logger.error(`NetVerify iframe reported an error`, event.payload.metainfo);
        this.props.notifyError(
          getSimpleNotificationData(t('settings:kyc.obtain1.providerIFrameError'))
        );
        this.setState({
          verificationUrl: null,
        });
        return;

      case 'success':
        this.props.logger.info(
          `NetVerify iframe reported a success for verification ${event.customerInternalReference}, transaction ${event.transactionReference}`,
          event.payload.metainfo
        );
        this.props.handleVerificationSubmitted(event.customerInternalReference).finally(() => {
          this.setState({
            verificationUrl: null,
          });
        });
    }
  };

  componentWillReceiveProps(nextProps: IObtainKycLevel1Props) {
    if (this.props.customer !== nextProps.customer) {
      this.setState({
        ...toPayload(nextProps.customer),
      });
    }
  }

  handleUserInput = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    this.setState({ [name]: value } as any);
  };

  handleSelect = (name) => (option) => {
    this.setState({ [name]: option.value } as any);
  };

  handleResidenceCountrySelect = (option) => {
    const currentCountryCode = this.state.residence_country;
    const newCountryCode = option.value;
    const statePayload: Pick<IObtainKycLevel1State, 'residence_country' | 'residence_us_state'> = {
      residence_country: newCountryCode,
    };
    if (currentCountryCode === COUNTRIES.US.value && newCountryCode !== COUNTRIES.US.value) {
      statePayload.residence_us_state = null;
    }
    this.setState(statePayload);
  };

  submitRequest = () => {
    if (
      !clientValidator(this.state, this.context.t)
        .required('first_name')
        .required('last_name')
        .required('gender')
        .required('residence_city')
        .required('residence_address')
        .required('residence_postal_code')
        .required('residence_country')
        .required('phone')
        .test(
          'residence_us_state',
          !(this.state.residence_country === 'US' && !this.state.residence_us_state),
          'validation.messages.stateRequired'
        )
        .test(
          'residence_us_state',
          () => {
            if (this.state.residence_country !== 'US' || !this.state.residence_us_state) {
              return true;
            }

            if (this.props.allowedStates && this.props.allowedStates.length) {
              return this.props.allowedStates.includes(this.state.residence_us_state);
            }

            return true;
          },
          'validation.messages.stateBlacklisted'
        )
        .phoneNumber('phone')
        .test(
          'confirmedInformation',
          !this.isVQF || !!this.state.confirmedInformation,
          'validation.messages.fieldIsRequired'
        )
        .onResult((validationError) => this.setState({ validationError }))
        .isValid()
    ) {
      return;
    }

    if (this.isVQF) {
      // If we are doing VQF, submit the request now.
      this.props.requestKycLevel1VQF().then((verificationUrl) => {
        this.setState({
          verificationUrl,
        });
      });
      return;
    }

    // Otherwise, start phone number verification flow. We don't care about id, any callback will be for us
    // We will wait on the callback before submitting the actual request
    this.props.openPhoneVerificationModal('onboarding', this.state.phone, Date.now());
  };

  renderTextField = (
    field: keyof IApiRequestKYC1Payload,
    label,
    autoComplete = null,
    placeholder = null
  ) => {
    const { t }: II18nextT = this.context;
    return (
      <$FormInputWrapper>
        <$Label bold htmlFor={field}>
          {label}
        </$Label>
        <ValidationWrapper error={this.state.validationError} path={field}>
          <$FormInput
            readOnly={this.isVQF || this.props.granted}
            type="text"
            id={field}
            name={field}
            autoComplete={autoComplete}
            placeholder={placeholder}
            value={this.state[field] || ''}
            onChange={this.handleUserInput}
          />
        </ValidationWrapper>
      </$FormInputWrapper>
    );
  };

  renderCountrySelect = (name, label, value) => (
    <$FormInputWrapper>
      <$Label bold htmlFor={name}>
        {label}
      </$Label>
      <CountrySelect
        inputId={name}
        variant="dark"
        isoCode={value}
        onChange={this.handleSelect(name)}
        disabled={this.isVQF || this.props.granted}
      />
    </$FormInputWrapper>
  );

  renderResidenceCountrySelect = (name, label, value) => (
    <$FormInputWrapper>
      <$Label bold htmlFor={name}>
        {label}
      </$Label>
      <CountrySelect
        inputId={name}
        variant="dark"
        isoCode={value}
        onChange={this.handleResidenceCountrySelect}
        disabled={this.isVQF || this.props.granted}
      />
    </$FormInputWrapper>
  );

  renderRequestForm() {
    const { t }: II18nextT = this.context;

    const customer = this.props.customer;
    if (!customer) {
      // Customer not loaded yet
      return null;
    }

    const genderOptions = {
      male: { value: 'male', label: t('settings:kyc.obtain1.genders.male') },
      female: { value: 'female', label: t('settings:kyc.obtain1.genders.female') },
      other: { value: 'other', label: t('settings:kyc.obtain1.genders.other') },
    };

    const isRelevantFailure =
      (this.isVQF ? customer.kyc_level_granted_vqf : customer.kyc_level_granted) < 1;

    return (
      <>
        {customer.kyc_failure_message && isRelevantFailure && (
          <KYCFailureNotice
            title={t('settings:kyc.obtain1.rejectedHeader')}
            message={t([
              `backend:messages.${customer.kyc_failure_message}`,
              customer.kyc_failure_message,
            ])}
          />
        )}

        <$BottomPartHolder>
          <$FormWrapper>
            {!this.isVQF && <$KycPanelTitle>{t('settings:kyc.obtain1.formTitle')}</$KycPanelTitle>}
            <$KycPanelText>
              {t(
                this.props.granted
                  ? intentionallyUntranslated(
                      'Congratulations! Your identity has been verified. This is the information you have submitted.'
                    )
                  : this.isVQF
                  ? intentionallyUntranslated(
                      `Due to regulatory reasons, we must use the same data you have already submitted as part of your Xcalibra KYC. Please confirm the data is correct and click Submit to begin the process. If your personal details have changed in the mean time, please contact customer support.`
                    )
                  : 'settings:kyc.obtain1.formInstructions'
              )}
            </$KycPanelText>

            {this.renderCountrySelect(
              'nationality',
              t('settings:kyc.obtain1.requestForm.nationality'),
              this.state.nationality
            )}

            {this.renderTextField(
              'first_name',
              t('settings:kyc.obtain1.requestForm.firstName'),
              'given-name'
            )}
            {this.renderTextField('middle_name', t('settings:kyc.obtain1.requestForm.middleName'))}
            {this.renderTextField(
              'last_name',
              t('settings:kyc.obtain1.requestForm.lastName'),
              'family-name'
            )}

            <$FormInputWrapper>
              <$Label bold htmlFor={'gender'}>
                {t('settings:kyc.obtain1.requestForm.gender')}
              </$Label>
              <ValidationWrapper error={this.state.validationError} path="gender">
                <SimpleSelect
                  inputId={'gender'}
                  variant="dark"
                  width="100%"
                  value={genderOptions[this.state.gender] as any}
                  options={Object.values(genderOptions)}
                  onChange={this.handleSelect('gender')}
                  disabled={this.isVQF || this.props.granted}
                />
              </ValidationWrapper>
            </$FormInputWrapper>

            {this.renderTextField(
              'residence_address',
              t('settings:kyc.obtain1.requestForm.address'),
              'street-address'
            )}

            {this.renderTextField(
              'residence_city',
              t('settings:kyc.obtain1.requestForm.city'),
              'address-level2'
            )}

            {this.renderTextField(
              'residence_postal_code',
              t('settings:kyc.obtain1.requestForm.postalCode'),
              'postal-code'
            )}

            {this.renderResidenceCountrySelect(
              'residence_country',
              t('settings:kyc.obtain1.requestForm.residenceCountry'),
              this.state.residence_country
            )}

            {this.state.residence_country === 'US' && (
              <$FormInputWrapper>
                <$Label bold htmlFor={'stateSelect'}>
                  {t('settings:kyc.obtain1.requestForm.residenceState')}
                </$Label>
                <ValidationWrapper error={this.state.validationError} path={'residence_us_state'}>
                  <StateSelect
                    inputId="stateSelect"
                    variant="dark"
                    isoCode={this.state.residence_us_state}
                    onChange={this.handleSelect('residence_us_state')}
                    background="transparant"
                    disabled={this.isVQF || this.props.granted}
                  />
                </ValidationWrapper>
              </$FormInputWrapper>
            )}

            {this.renderTextField('phone', t('settings:kyc.obtain1.requestForm.phone'), 'tel')}

            {this.isVQF && !this.props.granted && (
              <$VQFConfirmation>
                <$Checkbox
                  name="confirmVQF"
                  checked={this.state.confirmedInformation}
                  onChange={() =>
                    this.setState({
                      confirmedInformation: !this.state.confirmedInformation,
                      validationError: this.state.validationError
                        ? // TODO: Ugh, need a different API for this, but no time ATM
                          {
                            ...this.state.validationError,
                            pathMessages: {
                              ...this.state.validationError,
                              confirmedInformation: null,
                            },
                          }
                        : null,
                    })
                  }
                  hasError={
                    this.state.validationError &&
                    !!this.state.validationError.pathMessages.confirmedInformation
                  }
                >
                  {t(intentionallyUntranslated('I confirm this information is correct'))}
                </$Checkbox>
              </$VQFConfirmation>
            )}

            {!this.props.granted && (
              <$Bottom>
                <LoadingAnimation requests={['putKycLevel1']} loading={this.props.verifyingPhone}>
                  <$InfoButton onClick={() => this.submitRequest()}>
                    {t('button.submit')}
                  </$InfoButton>
                </LoadingAnimation>
              </$Bottom>
            )}
          </$FormWrapper>
        </$BottomPartHolder>
      </>
    );
  }

  renderWaitingScreen() {
    const { t }: II18nextT = this.context;

    return (
      <$AlertWrapper>
        <$SuccessMessage>
          {t('settings:kyc.obtain1.standBy')}
          <$WaitingAnimationWrapper>
            <LoadingAnimation />
          </$WaitingAnimationWrapper>
        </$SuccessMessage>
      </$AlertWrapper>
    );
  }

  renderVerificationScreen() {
    const { t }: II18nextT = this.context;

    return (
      <FullScreenOverlay fade>
        <$VerificationContainer>
          <$VerificationHeader>
            <$Link as={Link} to={R.HOME}>
              <Logo margin="0 auto" />
            </$Link>
            <div>
              <UserMenuButton
                onClick={() => {
                  this.setState({
                    verificationUrl: null,
                  });
                }}
                tooltip={t('settings:kyc.obtain1.abortVerificationTooltip')}
              >
                <CloseIcon />
              </UserMenuButton>
            </div>
          </$VerificationHeader>
          <$VerificationContent>
            <iframe
              src={this.state.verificationUrl}
              width="100%"
              height="100%"
              allow="camera;fullscreen;accelerometer;gyroscope;magnetometer"
              allowFullScreen
            />
          </$VerificationContent>
        </$VerificationContainer>
      </FullScreenOverlay>
    );
  }

  render() {
    this.updateBodyOverflow();

    const { t }: II18nextT = this.context;
    const customer = this.props.customer;
    if (!customer) {
      // Customer not loaded yet
      return null;
    }

    if (customer.kyc_processing_started_at) {
      // We are in processing state
      return (
        <$ObtainKycLevel1>
          <$AlertWrapper>
            <$SuccessMessage>{t('settings:kyc.obtain1.processingState')}</$SuccessMessage>
          </$AlertWrapper>
        </$ObtainKycLevel1>
      );
    }

    if (customer.kyc1_verification_waiting) {
      // We are waiting on response
      return <$ObtainKycLevel1>{this.renderWaitingScreen()}</$ObtainKycLevel1>;
    }

    if (this.state.verificationUrl) {
      // Show verification iFrame
      return <$ObtainKycLevel1>{this.renderVerificationScreen()}</$ObtainKycLevel1>;
    }

    // Show the request form
    return <$ObtainKycLevel1>{this.renderRequestForm()}</$ObtainKycLevel1>;
  }
}

const connector = connect(
  (state: IState) => {
    return {
      customer: getCustomer(state),
      verifyingPhone: isPhoneVerificationModalOpen(state),
      phoneVerificationState: state.phoneVerification,
      allowedStates: getKYC1StateWhitelist(state),
    };
  },
  {
    requestKycLevel1Base,
    requestKycLevel1VQF,
    handleVerificationSubmitted,
    notifyError,
    openPhoneVerificationModal,
    clearPhoneVerificationState,
  }
);

export default connector(inject('logger')(ObtainKycLevel1));
