import * as React from 'react';

import { QuestionmarkIcon } from '../../../media/svg_icons';
import GenericTooltip from './GenericTooltip';
import { ITooltipProps } from './tooltip_types';

type IProps = ITooltipProps & {
  width?: string;
  height?: string;
  placement?: string;
  tabIndex?: number;
};

const HelpTooltip: React.FunctionComponent<IProps> = (props) => {
  const {
    width = '1.5em',
    height = '1.5em',
    placement = 'right',
    tabIndex,
    children,
    ...passProps
  } = props;
  return (
    <GenericTooltip placement={placement} overlay={children} {...passProps}>
      <QuestionmarkIcon
        width={width}
        height={height}
        style={{ cursor: 'help' }}
        tabIndex={tabIndex}
      />
    </GenericTooltip>
  );
};

export default HelpTooltip as React.FunctionComponent<IProps>;
