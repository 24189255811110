import moment from 'moment';
import * as React from 'react';
import { I18nContext } from 'react-i18next';

import { IOrderState } from '../../actions/orders/orders';
import styled from '../../lib/styled_components';
import { II18nextT } from '../../types/i18n';
import { ITrade } from '../../types/trades';
import { ITranslations } from '../../types/translations';
import ScrollBox, { IOnScrollData, IOnScrollDetails } from './ScrollBox';

export const $DateSplit = styled.div`
  color: ${(p) => p.theme.widgets.dateSplit.textColor};
  height: 2.8125rem;
  margin: 0 10px;
  overflow: hidden;
  line-height: 2.8125rem;
  text-align: center;
  display: table;
  white-space: nowrap;
  &:before {
    border-top: 1px solid ${(p) => p.theme.widgets.dateSplit.borderColor};
    content: '';
    display: table-cell;
    position: relative;
    width: 50%;
    right: 2%;
    top: 1.35rem;
  }
  &:after {
    border-top: 1px solid ${(p) => p.theme.widgets.dateSplit.borderColor};
    content: '';
    display: table-cell;
    position: relative;
    width: 50%;
    left: 2%;
    top: 1.35rem;
  }
`;

export const $HoverDate = styled('span')`
  color: ${(p) => p.theme.colors.white};
  padding: 8px 8px;
  position: absolute;
  top: 2.25rem;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, 0);
  overflow: hidden;
  text-align: center;
  border-radius: 0.7rem;
  background: ${(p) => p.theme.widgets.dateSplit.hoverDateBackground};
  pointer-events: none;
`;

export const $DateSplitGroup = styled.div``;
export const $StartOfText = styled.div`
  text-align: center;
  font-size: ${(p) => p.theme.fontSize.base};
  color: ${(p) => p.theme.colors.baseDarker};
  padding: 5px 2px;
`;

export const DAYS_IN_WEEK: string[] = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];
export const MONTHS_IN_YEAR: string[] = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];
const DATESPLIT_CLASSNAME = 'DATESPLIT_CLASSNAME';

export function formatDateString(timestamp: string): IFormattedDateStrings {
  const tradeTimestamp = moment.utc(timestamp);
  const nowTimestamp = moment.utc();
  const dayInWeekIndex: number = tradeTimestamp.day();
  const monthInYearIndex: number = tradeTimestamp.month();
  const tradeDayInMonth: number = tradeTimestamp.get('date');
  const yearOfTrade: number = tradeTimestamp.get('year');
  const differenceInDays: number = nowTimestamp.diff(tradeTimestamp, 'days');

  if (differenceInDays === 0) {
    return { short: 'today' };
  }

  if (differenceInDays === 1) {
    return { short: 'yesterday' };
  }

  return {
    long: {
      dayInWeek: DAYS_IN_WEEK[dayInWeekIndex],
      monthInYear: MONTHS_IN_YEAR[monthInYearIndex],
      dayInMonth: tradeDayInMonth,
      year: yearOfTrade,
    },
  };
}

function generateDateSplitText(t: any, group: IDateGroup): ITranslations {
  const { short, long } = formatDateString(group.date);

  let shortDate: ITranslations;
  let longDate: { dayInWeek: ITranslations; monthInYear: ITranslations };

  if (short) {
    shortDate = `momentDate.${short}` as ITranslations;
  }

  if (long) {
    longDate = {
      dayInWeek: `momentDate.daysInWeek.${long.dayInWeek}` as ITranslations,
      monthInYear: `momentDate.monthsInYear.${long.monthInYear}` as ITranslations,
    };
  }

  const dateSplitText = short
    ? t(shortDate)
    : t(longDate.dayInWeek) + ', ' + t(longDate.monthInYear) + ` ${long.dayInMonth}, ${long.year}`;

  return dateSplitText;
}

interface IFormattedDateStrings {
  short?: string;
  long?: { dayInWeek: string; monthInYear: string; dayInMonth: number; year: number };
}

interface IDateNecessities extends IFormattedDateStrings {
  shortDate: ITranslations;
  longDate: { dayInWeek: ITranslations; monthInYear: ITranslations };
}

export interface IDateGroup {
  date: string;
  trades?: ITrade[];
  orders?: IOrderState[];
}

interface IRenderScrollBoxBodyProps {
  data: IDateGroup[];
  renderRow: (item: any) => any;
}

interface IDateSplitScrollBoxProps extends IRenderScrollBoxBodyProps {
  mobileHeight?: string;
  laptopHeight?: string;
  startText?: string;
  showStartText?: boolean;
}

interface IDateSplitScrollBoxState {
  dateSplitText: string;
  showHoverDate: boolean;
  updatedHoverDate: boolean;
  dayKey: number;
}

class ScrollBoxBody extends React.PureComponent<IRenderScrollBoxBodyProps> {
  static contextType: any = I18nContext;
  render() {
    const { t }: II18nextT = this.context;
    return this.props.data.map((group: IDateGroup) => {
      const dateSplitText = generateDateSplitText(t, group);
      return (
        <$DateSplitGroup key={group.date}>
          {<$DateSplit className={DATESPLIT_CLASSNAME}>{dateSplitText}</$DateSplit>}
          {group.trades
            ? group.trades.map((item: any) => {
                return this.props.renderRow(item);
              })
            : group.orders.map((item: any) => {
                return this.props.renderRow(item);
              })}
        </$DateSplitGroup>
      );
    });
  }
}

export class DateSplitScrollBox extends React.Component<
  IDateSplitScrollBoxProps,
  IDateSplitScrollBoxState
> {
  static contextType: any = I18nContext;
  hoverDateRef: React.RefObject<HTMLDivElement> = React.createRef();
  prevDateSplitZone: number = 0;
  trackedIndex: number = 0;
  hoverDateTimeout: number;
  dataRecordsUpdated: boolean = false;
  private midnightReloadTimeout = null;

  state: IDateSplitScrollBoxState = {
    dateSplitText: '',
    showHoverDate: false,
    updatedHoverDate: false,
    dayKey: 1,
  };

  componentDidMount() {
    // On current page refresh, component will mount faster than it will fetch data,
    // but after it has fetched data, this life cycle will successfully initialize date split text
    this.tryDateSplitTextInit(this.props);

    // We want to refresh the table at midnight, so that date splits would update
    this.setupMidnightReload();
  }

  setupMidnightReload = () => {
    const midnightDate = new Date();
    midnightDate.setHours(23);
    midnightDate.setMinutes(59);
    midnightDate.setSeconds(59);
    midnightDate.setMilliseconds(999);
    const msUntilMidnight = midnightDate.valueOf() - new Date().valueOf();
    this.midnightReloadTimeout = setTimeout(() => {
      this.setState({
        dayKey: this.state.dayKey + 1,
      });
      this.setupMidnightReload();
    }, msUntilMidnight);
  };

  componentWillUnmount() {
    clearTimeout(this.hoverDateTimeout);
    clearTimeout(this.midnightReloadTimeout);
  }

  componentWillReceiveProps(nextProps: IDateSplitScrollBoxProps) {
    // Initialize date split text here, cause data fetching takes longer than component's mounting
    this.tryDateSplitTextInit(nextProps);
    if (nextProps.data !== this.props.data) {
      this.dataRecordsUpdated = true;
    }
  }

  calculateDateSplitSection = (target: any) => {
    const scrolledHeight: number = target.scrollTop;
    const dateSplitArr: NodeListOf<HTMLElement> = target.getElementsByClassName(
      DATESPLIT_CLASSNAME
    );

    if (dateSplitArr.length < 2) {
      return;
    }

    const firstDateSplit: HTMLElement = dateSplitArr[0];
    const secondDateSplit: HTMLElement = dateSplitArr[1];
    const lastDateSplit: HTMLElement = dateSplitArr[dateSplitArr.length - 1];

    const firstDateSplitZone: number =
      firstDateSplit.getBoundingClientRect().height / 2 + firstDateSplit.offsetTop;
    const secondDateSplitZone: number =
      secondDateSplit.getBoundingClientRect().height / 2 + secondDateSplit.offsetTop;
    const lastDateSplitZone: number =
      lastDateSplit.offsetTop + lastDateSplit.getBoundingClientRect().height / 2;

    if (!this.state.showHoverDate) {
      this.setState({ showHoverDate: true });
    }

    // Date split zone represents addition of half dateSplit block height and dateSplit block offset from scroll container
    for (let i = 0; i < dateSplitArr.length; i++) {
      const currentDateSplit = dateSplitArr[i];
      const currentDateSplitZone =
        currentDateSplit.getBoundingClientRect().height / 2 + currentDateSplit.offsetTop;

      // 1 CASE: calculate trackedIndex on dateSplitArr change
      if (this.dataRecordsUpdated) {
        const currentDataSplitZone =
          dateSplitArr[i].getBoundingClientRect().height / 2 + dateSplitArr[i].offsetTop;
        const nextDataSplitZone = dateSplitArr[i + 1]
          ? dateSplitArr[i + 1].getBoundingClientRect().height / 2 + dateSplitArr[i + 1].offsetTop
          : 0;

        // 1.1 CASE: set trackedIndex to index of currentDataSplitZone
        if (scrolledHeight >= currentDataSplitZone && scrolledHeight < nextDataSplitZone) {
          this.trackedIndex = i;
        }

        // 1.2 CASE: we are on the last element => set trackedIndex to index of lastDataSplitZone
        if (scrolledHeight >= currentDataSplitZone && scrolledHeight > nextDataSplitZone) {
          this.trackedIndex = dateSplitArr.length - 1;
        }
        this.dataRecordsUpdated = false;
      }

      // 2 CASE: scrolledHeight is in the firstDateSplitZone
      if (scrolledHeight <= secondDateSplitZone) {
        if (this.state.dateSplitText === currentDateSplit.innerText) {
          break;
        }
        this.trackedIndex = 0;
        this.prevDateSplitZone = firstDateSplitZone;
        this.setState({
          dateSplitText: currentDateSplit.innerText,
          updatedHoverDate: !this.state.updatedHoverDate,
        });
        break;
      }

      // 3 CASE: scrolledHeight is in the lastDateSplitZone
      if (scrolledHeight > lastDateSplitZone) {
        if (this.state.dateSplitText !== lastDateSplit.innerText) {
          this.setState({
            dateSplitText: lastDateSplit.innerText,
            updatedHoverDate: !this.state.updatedHoverDate,
          });
        }
      }

      // 4 CASE: SCROLL DOWNWARDS \/
      if (scrolledHeight >= this.prevDateSplitZone && scrolledHeight <= currentDateSplitZone) {
        this.prevDateSplitZone = currentDateSplitZone;
        this.setState({
          dateSplitText: dateSplitArr[i - 1].innerText,
          updatedHoverDate: !this.state.updatedHoverDate,
        });
        this.trackedIndex = i - 1;
        break;
      }

      // 5 CASE: SCROLL UPWARDS /\
      if (this.trackedIndex > 0) {
        const nextDataSplitZone =
          dateSplitArr[this.trackedIndex].getBoundingClientRect().height / 2 +
          dateSplitArr[this.trackedIndex].offsetTop;

        if (scrolledHeight <= nextDataSplitZone && scrolledHeight < this.prevDateSplitZone) {
          // 5.1 CASE : when scrolledHeight is lesser than secondLastDateSplitZone
          this.trackedIndex += -1;
          this.prevDateSplitZone =
            dateSplitArr[this.trackedIndex].getBoundingClientRect().height / 2 +
            dateSplitArr[this.trackedIndex].offsetTop;
          this.setState({
            dateSplitText: dateSplitArr[this.trackedIndex].innerText,
            updatedHoverDate: !this.state.updatedHoverDate,
          });
        }

        // 5.2 CASE : when scrolledHeight is in the secondLastDateSplitZone
        if (
          scrolledHeight < lastDateSplitZone &&
          this.state.dateSplitText === lastDateSplit.innerText
        ) {
          this.trackedIndex += -1;
          this.prevDateSplitZone =
            dateSplitArr[this.trackedIndex].getBoundingClientRect().height / 2 +
            dateSplitArr[this.trackedIndex].offsetTop;

          this.setState({
            dateSplitText: dateSplitArr[this.trackedIndex].innerText,
            updatedHoverDate: !this.state.updatedHoverDate,
          });
        }
      }
    }
  };

  handleHoverDateFadeOut = (target: IOnScrollDetails) => {
    if (this.hoverDateTimeout) {
      this.hoverDateRef.current.style.transition = `initial`;
      clearTimeout(this.hoverDateTimeout);
    }
    // Fade out HoverDate
    const scrolledFromTop = target.scrollTop;
    // Calculate fadeStep (from top and bottom of the container, where fadePoint[px])
    const fadePoint = 50;
    const fadeStep = 1 / fadePoint;
    const scrollLeftToBottom = target.scrollHeight - (scrolledFromTop + target.clientHeight);

    if (scrolledFromTop < fadePoint) {
      const opacity = fadeStep * scrolledFromTop;
      this.hoverDateRef.current.style.opacity = `${opacity}`;
    } else if (scrollLeftToBottom < fadePoint) {
      const opacity = fadeStep * scrollLeftToBottom;
      this.hoverDateRef.current.style.opacity = `${opacity}`;
    } else if (scrolledFromTop > 50 && scrollLeftToBottom > 50) {
      this.hoverDateRef.current.style.opacity = '1';
    }
    this.hoverDateTimeout = setTimeout(() => {
      this.hoverDateRef.current.style.transition = `0.5s linear opacity`;
      this.hoverDateRef.current.style.opacity = `0`;
    }, 1300);
  };

  onScroll = (data: IOnScrollData) => {
    // Handle hoverDate fade out
    this.handleHoverDateFadeOut(data.target);
    // Calculate date split section
    this.calculateDateSplitSection(data.target);
  };

  initDateSplitZoneText(group) {
    const { t }: II18nextT = this.context;
    const dateSplitText = generateDateSplitText(t, group);

    this.setState({
      dateSplitText,
      showHoverDate: true,
      updatedHoverDate: !this.state.updatedHoverDate,
    });
  }

  tryDateSplitTextInit(props: IDateSplitScrollBoxProps) {
    if (props.data[0] && !this.state.showHoverDate) {
      this.initDateSplitZoneText(props.data[0]);
    }
  }

  render() {
    return (
      <>
        <ScrollBox
          key={this.state.dayKey}
          mobileHeight={this.props.mobileHeight}
          laptopHeight={this.props.laptopHeight}
          onScroll={this.onScroll}
        >
          {this.props.data.length !== 0 && (
            <>
              <ScrollBoxBody data={this.props.data} renderRow={this.props.renderRow} />
              {this.props.startText && this.props.showStartText && (
                <$StartOfText>{this.props.startText}</$StartOfText>
              )}
            </>
          )}
          {this.props.children}
        </ScrollBox>
        <$HoverDate ref={this.hoverDateRef}>{this.state.dateSplitText}</$HoverDate>
      </>
    );
  }
}
