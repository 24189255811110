import styled from '../../lib/styled_components';
import fallbackIcon from '../../media/warning-white.png';
import { instrumentInfo } from '../../types/instruments';
import { Omit } from '../../types/typescript_helpers';

const images = require.context('../../media/instruments', true);

const defaults = {
  size: 32,
  type: 'icon',
};

export type IIconTypes = 'icon' | 'color' | 'black' | 'white' | 'gray';
interface IInstrumentIconProps extends Omit<React.HTMLProps<HTMLImageElement>, 'size'> {
  name: string;
  size?: number | string;
  type?: IIconTypes;
}

// gray = black with opacity...
const InstrumentIcon = styled('img').attrs((p: IInstrumentIconProps) => ({
  alt: `${p.name} icon`,
  src: instrumentInfo(p.name)
    ? images(
        `./${
          p.type ? (p.type === 'gray' ? 'black' : p.type) : defaults.type
        }/${p.name.toLowerCase()}.svg`
      )
    : fallbackIcon,
}))<IInstrumentIconProps>`
  width: ${(p) =>
    typeof p.size === 'number'
      ? `${p.size}px`
      : typeof p.size === 'string'
      ? p.size
      : `${defaults.size}px`};
  height: ${(p) =>
    typeof p.size === 'number'
      ? `${p.size}px`
      : typeof p.size === 'string'
      ? p.size
      : `${defaults.size}px`};
  filter: ${(p) => (p.type === 'gray' ? `opacity(50%)` : 'none')};
`;

export default InstrumentIcon;
