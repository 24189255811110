import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { ICustomLocationDescriptor } from '../../../lib/history';
import R from '../../../lib/routes';
import { IState } from '../../../lib/store';
import styled from '../../../lib/styled_components';
import { ArrowDownIcon, ArrowUpIcon, CoinsIcon } from '../../../media/svg_icons';
import { IInstrument } from '../../../types/backend_definitions';
import { II18nextT } from '../../../types/i18n';
import { instrumentInfo, instrumentInfosForType, pairInfo } from '../../../types/instruments';
import { HideMobile, ShowMobile } from '../../utility_components';
import { $InfoButton } from '../../widgets/buttons';
import { InstrumentMenu } from '../../widgets/DropdownMenu';
import Header from '../../widgets/Header';
import { $Icon } from '../../widgets/Icon';
import $Link from '../../widgets/Link';
import { $HeaderWrapper, $LeftHeaderWrapper } from '../../widgets/PageHeaderWrappers';

const $Wrapper = styled.div`
  display: flex;
  @media screen and ${(p) => p.theme.device.mobile} {
    flex-direction: column;
  }
`;

const $Left = styled.div`
  width: 300px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  @media screen and ${(p) => p.theme.device.mobile} {
    padding: 0;
    width: auto;
  }
`;

const $Right = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const $ContentWrapper = styled.div`
  padding: 20px;
`;

export interface ITransactionTypes {
  type: 'deposit' | 'withdraw';
}

const depositOrWithdrawLink = (
  type: 'deposit' | 'withdraw',
  instrument: IInstrument
): ICustomLocationDescriptor => {
  return (type === 'deposit' ? R.DEPOSIT : R.WITHDRAW).to({ instrument });
};

interface IOwnProps extends ITransactionTypes, RouteComponentProps<{ instrument?: IInstrument }> {}
interface ITransactionPagesLayoutProps extends IOwnProps, ConnectedProps<typeof connector> {}

interface ITransactionPagesLayoutState {
  showMobileMenu: boolean;
}

class TransactionPagesLayout extends React.PureComponent<
  ITransactionPagesLayoutProps,
  ITransactionPagesLayoutState
> {
  static contextType: any = I18nContext;

  constructor(props) {
    super(props);
    this.state = {
      showMobileMenu: false,
    };
  }

  toggleMobileMenu = () => {
    this.setState((prevState) => ({ showMobileMenu: !prevState.showMobileMenu }));
  };

  render() {
    const { instrument, type, children, instrumentsFiat, instrumentsCrypto } = this.props;
    const { t }: II18nextT = this.context;

    return (
      <$Wrapper>
        <$Left>
          <$LeftHeaderWrapper className="hide-on-mobile">
            <Header
              title={
                type === 'deposit'
                  ? t('transactionPages.depositPage')
                  : t('transactionPages.withdrawalPage')
              }
              icon={CoinsIcon}
            />
          </$LeftHeaderWrapper>

          <$Link
            as={Link}
            to={depositOrWithdrawLink(
              type === 'deposit' ? 'withdraw' : 'deposit',
              instrument.symbol
            )}
            style={{ width: '100%' }}
          >
            <$InfoButton style={{ width: '100%' }}>
              {type === 'deposit'
                ? t('transactions:switchToWithdrawals')
                : t('transactions:switchToDeposits')}
            </$InfoButton>
          </$Link>

          <ShowMobile>
            <$HeaderWrapper
              onClick={() => {
                this.toggleMobileMenu();
              }}
            >
              <Header
                title={instrument.name.toUpperCase()}
                subtitle={
                  type === 'deposit'
                    ? t('transactions:deposits.deposit')
                    : t('transactions:withdrawals.withdraw')
                }
                icon={instrument.symbol}
              />
              <$Icon src={this.state.showMobileMenu ? ArrowUpIcon : ArrowDownIcon} size={20} />
            </$HeaderWrapper>
            <InstrumentMenu
              toggleMenu={this.toggleMobileMenu}
              isOpen={this.state.showMobileMenu}
              selectedRoute={depositOrWithdrawLink(type, instrument.symbol)}
              type={type}
              instrumentsBank={instrumentsFiat}
              instrumentsCrypto={instrumentsCrypto}
            />
          </ShowMobile>

          <HideMobile>
            <InstrumentMenu
              isOpen
              selectedRoute={depositOrWithdrawLink(type, instrument.symbol)}
              type={type}
              instrumentsBank={instrumentsFiat}
              instrumentsCrypto={instrumentsCrypto}
            />
          </HideMobile>
        </$Left>
        <$Right>
          {/* pass instrument to child component */}
          {React.Children.map(children, (child) =>
            React.cloneElement(child as any, { instrument })
          )}
        </$Right>
      </$Wrapper>
    );
  }
}

const connector = connect((state: IState, props: IOwnProps) => ({
  instrument:
    props.match.params.instrument && instrumentInfo(props.match.params.instrument)
      ? instrumentInfo(props.match.params.instrument)
      : pairInfo(state.env.pairs.defaultPair).base,
  instrumentsCrypto: instrumentInfosForType('crypto')(state),
  instrumentsFiat: instrumentInfosForType('bank')(state),
}));

export default withRouter(connector(TransactionPagesLayout));
