import * as React from 'react';
import { I18nContext } from 'react-i18next';
import { Link } from 'react-router-dom';

import R from '../../lib/routes';
import notFoundImage from '../../media/404.png';
import { II18nextT } from '../../types/i18n';
import { FullScreenOverlay } from '../layout/FullScreenOverlay';
import { SimplePageLayout } from '../layout/SimplePageLayout';
import { $InfoButton } from '../widgets/buttons';
import $ColorText from '../widgets/ColorText';
import $Link from '../widgets/Link';

interface INotFoundPageProps {}
interface INotFoundPageState {}

class NotFoundPage extends React.PureComponent<INotFoundPageProps, INotFoundPageState> {
  static contextType: any = I18nContext;

  render() {
    const { t }: II18nextT = this.context;

    return (
      <FullScreenOverlay>
        <SimplePageLayout errorImageSrc={notFoundImage} errorImageAlt="404 page">
          <p>
            <$ColorText color="white">{t('notFoundPage.description')}</$ColorText>
          </p>
          <$InfoButton>
            <$Link as={Link} to={R.EXCHANGE_HOME}>
              {t('notFoundPage.goToExchange')}
            </$Link>
          </$InfoButton>
        </SimplePageLayout>
      </FullScreenOverlay>
    );
  }
}

export default NotFoundPage;
