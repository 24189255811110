import React from 'react';

import { COUNTRIES } from '../../lib/countries';
import CountryFlag from './CountryFlag';

interface ICountryLabelProps {
  isoCode: any;
  label: any;
  large?: boolean;
}
const CountryLabel: React.FunctionComponent<ICountryLabelProps> = ({ isoCode, label, large }) => {
  if (!isoCode) {
    return null;
  }

  if (label === true) {
    label = COUNTRIES[isoCode].label;
  }

  return (
    <span className="CountryLabel text-nowrap">
      <CountryFlag isoCode={isoCode} large={large} />
      {label && (
        <span className="ml-1">
          {` `} {label}
        </span>
      )}
    </span>
  );
};

export default CountryLabel;
