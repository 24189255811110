import { createSelector } from 'reselect';

import { IState } from '../lib/store';
import { IInstrument } from '../types/backend_definitions';
import { IBalance, IBalances } from '../types/balances';
import { getCurrentPair } from './exchange';

export interface IInstrumentPairAvailableBalances {
  base: number;
  quote: number;
}

export const getBalances = (state: IState): IBalances => state.balances;

export const getBalanceForInstrument = (state: IState, instrument: IInstrument): IBalance =>
  getBalances(state)[instrument];

export const getAvailableBalanceForCurrentPair = createSelector(
  getBalances,
  getCurrentPair,
  (balances, pair) => {
    if (!pair) {
      return {
        base: 0,
        quote: 0,
      };
    }

    const baseBalances = balances[pair.base.symbol];
    const quoteBalances = balances[pair.quote.symbol];

    return {
      base: baseBalances ? baseBalances.available : 0,
      quote: quoteBalances ? quoteBalances.available : 0,
    };
  }
);
