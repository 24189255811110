import Chart from 'chart.js';
import * as React from 'react';

interface IBarChartProps {
  labels: string[];
  points: number[];
}

function BarChart({ labels, points }: IBarChartProps) {
  const canvasRef = React.useRef(null);
  let chart = null;

  React.useEffect(() => {
    chart = new Chart(canvasRef.current, {
      type: 'line',
      data: {
        labels,
        datasets: [
          {
            data: points,
            pointBackgroundColor: (context) => {
              return context.dataIndex === context.dataset.data.length - 1 ? '#59d8b2' : '#ff6d96';
            },
          },
        ],
      },
      options: {
        layout: {
          padding: {
            top: 5,
          },
        },
        animation: {
          duration: 0,
        },
        tooltips: {
          displayColors: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                borderDash: [2, 5],
                zeroLineBorderDash: [2, 5],
              },
              ticks: { fontColor: '#CCCCCC' },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                display: false,
              },
            },
          ],
        },
      },
    });

    return () => {
      chart && chart.destroy && chart.destroy();
    };
  }, [points, labels]);
  return <canvas ref={canvasRef} />;
}

export default BarChart;
